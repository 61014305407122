import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-deffered-popup',
  templateUrl: './deffered-popup.component.html',
  styleUrls: ['./deffered-popup.component.scss']
})
export class DefferedPopupComponent implements OnInit {

  selectedDate: Date;

  constructor(public dialogRef: MatDialogRef<DefferedPopupComponent>) {
    this.selectedDate = new Date();
  }

  closeDialog(): void {
    this.dialogRef.close(this.selectedDate);
  }

  ngOnInit() {
  }

}
