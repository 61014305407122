import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';
import { AddBuyerDetailsComponent } from '../add-buyer-details/add-buyer-details.component';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { DataService } from 'src/app/data.service';
import { ToastrService } from 'ngx-toastr';
import { UploadFileBuyerComponent } from '../../upload-file-buyer/upload-file-buyer.component';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/auth/auth.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
@Component({
  selector: 'app-approved-buyer-list',
  templateUrl: './approved-buyer-list.component.html',
  styleUrls: ['./approved-buyer-list.component.scss']
})
export class ApprovedBuyerListComponent implements OnInit {
  displayedColumns = ['buyerName', 'country','insurer', 'status', 'source', 'tenorApprovedDays', 'requestLimit', 'approvedLimit', 'utilizedAmount', 'availableLimit', 'action'];
  dataSource = new MatTableDataSource();
  laneId: string;
  pageSize = 10;
  pageNo = 1;
  laneNo: string;
  totalRecords: string;
  selectedIndex: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchBuyerName : string;
  searchCountry: string;
  selectedInsurer: string;
  insurerList = [];
  countries = [];
  selectedStatus: string;
  selectedSource : string;
  isSalesLOSUser: any = true;
  exportDataList: any;
  exportDataSource: any[];
  constructor(
    public pagesService: PagesService,
    public router: Router,
    public authService: AuthService,
    public activateRoute: ActivatedRoute,
    public dialog: MatDialog,
    public kanbanService: KanbanService,
    public dataService: DataService,
    public tostrService: ToastrService,
    public csvExportService : CsvExportService)
    {}

  ngOnInit() {
    this.isSalesLOSUser = this.authService.isSalesLOSUser();
    if(this.isSalesLOSUser){
      this.displayedColumns = ['buyerName', 'country'];
    }
    this.getInsurerList();
    this.pagesService.isChangeColor("white_doc_cb");
    this.activateRoute.params.subscribe(param => {
      this.laneId = param.id;
      this.laneNo = param.displayId;
    })
    this.getApprovedBuyerList();
  }
  getPaginatorData(event) {
    this.pageNo = event.pageIndex + 1;
    this.getApprovedBuyerList();
  }
  get payload(){
    let pageNo = this.pageNo;
    let pageSize = this.pageSize;
    let search: any;
    if(this.searchBuyerName){
      search = {};
      search.buyerName = this.searchBuyerName;
    }
    if(this.searchCountry){
      if(!search)
        search = {};
      search.country = this.searchCountry;
    }
    if(this.selectedInsurer){
      if(!search)
        search = {};
      search.insurer = this.selectedInsurer;
    }
    if(this.selectedSource){
      if(!search)
        search = {};
      search.source = this.selectedSource;
    }
    if(this.selectedStatus){
      if(!search)
        search = {};
      search.status = this.selectedStatus;
    }

    return {pageNo, pageSize, search};
  }
  getApprovedBuyerList() {
    this.kanbanService.getApprovedBuyerList(this.payload).subscribe(res => {
      if (res['status'] == 200) {
        let approvedBuyerList = res['result']
        this.dataSource.data = approvedBuyerList.content;
        this.totalRecords = approvedBuyerList.totalElements;
      }
    })
  }
  openAddBuyerForm(formType, element?, index?) {
    if (formType != 'bulkForm') {
      this.selectedIndex = index
      const dialogRef = this.dialog.open(AddBuyerDetailsComponent, {
        disableClose: true,
        width: '30%',
        height: '100%',
        position: {
          bottom: '0px',
          right: '0px'
        },
        data: formType == 'getDetail' ? element : null
      })
      dialogRef.afterClosed().subscribe(result => {
        this.getApprovedBuyerList();
        this.selectedIndex = null;
      })
    } else {
      this.tostrService.success("Add Bulk Functionality is coming soon....")
    }
  }

  exportData(){
    this.getAllApprovedBuyerList();
  }

  getAllApprovedBuyerList(){
    let payload = {
      pageNo: 1,
      pageSize: 2000
    }
 this.kanbanService.getApprovedBuyerList(payload).subscribe(res => {
      if (res['status'] == 200) {
        let approvedBuyerList = res['result']
        this.exportDataList = approvedBuyerList.content;
        this.createCsvDocumentFile(approvedBuyerList.content);
        // this.totalRecords = approvedBuyerList.totalElements;
      }
    })
  }

  createCsvDocumentFile(tableData) {
    this.exportDataSource = [];
    tableData.forEach((element) => {
      let object = {
        "Buyer Name ": element.buyerName ? '"' + element.buyerName + '"' : 'N/A',
        "Country": element.country ? '"' + element.country + '"' : 'N/A',
      };
      this.exportDataSource.push(object);
    });
    this.csvExportService.downloadFile(this.exportDataSource, 'Approve-Buyers');
  }
  selectedRow(index) {
    this.selectedIndex = index;
  }
  menuClosed() {
    this.selectedIndex = null;
  }
  deleteBuyer(element) {
    this.kanbanService.deleteApprovedBuyer(element.id).subscribe(res => {
      if (res['success']) {
        this.getApprovedBuyerList();
        this.tostrService.success("Buyer Deleted Successfully")
      }
    })
  }
  uploadBulkBuyers() {
    const dialogRef = this.dialog.open(UploadFileBuyerComponent, {
      width: '500px',
      height: '250px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(res => {
      this.getApprovedBuyerList();
    })
  }
  exportBuyersData() {
    this.kanbanService.downloadApprovedBuyerData().subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      let fileName = resultDownload.replace(/"/g, '')
      saveAs(res.body, fileName);
      this.tostrService.success("Downloaded Successfully")
      return fileName;
    })
  }
  searchFilter(){
    this.getApprovedBuyerList();
  }
  clearFilter(){
    this.searchBuyerName = null;
    this.searchCountry = null;
    this.selectedInsurer = null;
    this.selectedSource = null;
    this.selectedStatus = null;
    this.getApprovedBuyerList();
  }
  getInsurerList() {
    this.kanbanService.getDropdowndata().subscribe(res => {
      this.insurerList = res['insurer_name'];
      this.countries = res['countryList'];
      this.filteredCountries = this.countries;
    })
  }
  getStatusClass(status){
    if(status == 'APPROVED'){
      return 'approved';
    } else if(status == 'PENDING'){
      return 'pendingAppr';
    }
  }

  searchText: string = '';
  filteredCountries: any;

  filterCountries() {
    const searchLower = this.searchCountry.toLowerCase();
    this.filteredCountries = this.countries.filter(country =>
      country.toLowerCase().includes(searchLower.toLowerCase())
    );
  }

  removeInvalid(event){
   let text = event.target.value.toLowerCase();
   let index =  this.countries.findIndex(country => {
     if (country.toLowerCase() == text.toLowerCase()){
       event.target.value = country;
       this.searchCountry = country;
     }
    return country.toLowerCase() == text.toLowerCase()
    });
   if(index == -1){
    //  this.filteredCountries = [];
     this.searchCountry = null;
   } else {
     const searchLower = text;
     this.filteredCountries = this.countries.filter(country =>
      country.toLowerCase().includes(searchLower)
    );
   }
  }
}
