import { Component, Input, OnInit } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { dashboardData } from '../../../../constants/dashboard-data';

@Component({
  selector: 'app-eligible-invoice-stats',
  templateUrl: './eligible-invoice-stats.component.html',
  styleUrls: ['./eligible-invoice-stats.component.scss']
})
export class EligibleInvoiceStatsComponent implements OnInit {

  @Input() data: any;
  invoiceData = [];
  @Input()supplierLinks = [];

  constructor(private settingService: SettingService) { }

  ngOnInit() {
  }
  
  ngOnChanges(){    
    if(this.data){
      this.invoiceData = [];
      this.data.map(data=>{
        if(data['reportType'] == dashboardData.eligibleInvoiceStats){
          this.getInvoiceData(data['result'][dashboardData.eligibleInvoiceStats], 'Eligible Invoices')
        }
        else if(data['reportType'] == dashboardData.todayRequestedStats){
          this.getInvoiceData(data['result'][dashboardData.todayRequestedStats], 'Requested Invoices');
        }
      })
    }
  }

  getInvoiceData(data, name){
    let obj = {
      name: name,
      doc_count: this.settingService.numDifferentiation(data['doc_count']),
      amount: this.settingService.numDifferentiation(data['amount'])
    }
    this.invoiceData.push(obj);
  }

}
