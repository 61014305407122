import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {PayoutsComponent} from './payouts/payouts.component';
import {PayoutReportsComponent} from './payout-reports/payout-reports.component';
import { AuthGuard } from 'src/app/auth/auth.guard';

const routes: Routes = [
  {
    path: 'payouts',
    component: PayoutsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payout-report',
    component: PayoutReportsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)]
})
export class EscrowAccountRoutingModule { }
