<div class="view" fxLayout="column" >
  <div class="docdetails" fxLayout="row" fxFlex="10">
    <a (click)="backtoDocuments()">Documents</a>
    <mat-icon>arrow_right</mat-icon>
    <h5>Purchase Order <span> {{documentList.searchKey}}</span></h5>
  </div>
  <div fxLayout="column"  class="fieldDetails">
    <div class="full-width top-align" fxLayout="row" fxFlex="20">
      <h5>Purchase Order</h5>
      <!-- <span>settled</span> -->
      <!-- <img src="/assets/images/logo.svg" /> -->
    </div>

    <div fxLayout="row" class="full-width">
      <div class="halfField add1" fxLayout="column">
        <label>To</label>
        <span><strong>{{documentList?.supplier_account_name}} </strong><br>{{documentList?.supplier_address}}</span>
       
      </div>
      <div class="halfField add1" fxLayout="column">
        <label>From</label>
        <span><strong>{{documentList?.customer_account_name}} </strong><br> {{documentList?.customer_address}}</span>
        
      </div>
      <div class="halfField" fxLayout="column">
        <label>DOC ID</label>
        <span>{{documentList?.searchKey}}</span>
      </div>
      <div class="halfField" fxLayout="column">
        <label>Issue Date</label>
        <span>{{documentList?.purchaseDate |date}}</span>
      </div>

      <div class="halfField" fxLayout="column">
        <label>Currency</label>
        <span>INR</span>
      </div>
    </div>

    <div id="table-wrapper">
      <div id="table-scroll">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product / Service</th>
              <th>HSN/SAC</th>
              <th>Qty.</th>
              <th>Rate</th>
              <th>Taxable Amount</th>
              <th>Tax(%)</th>
              <th>Tax Amount</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedRecordsArray; let i = index">
              <td>{{i+1}}</td>
              <td>{{item.name}}</td>
              <td>{{item.hsn_sac_code}}</td>
              <td>{{item.quantity}}</td>
              <td>{{item.rate | number:'1.2-2'}}</td>
              <td>{{item.amount_excluding_tax | number:'1.2-2'}}</td>
              <td>{{item.tax_rate | number:'1.2-2'}}</td>
              <td>{{item.tax_amount | number:'1.2-2' }}</td>
              <td>{{item.amount | number:'1.2-2'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="amount_details" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="50px">
      <label><strong>Total</strong></label>
      <span>{{totalAmount | currency:'INR'}}</span>
    </div>
  </div>


</div>