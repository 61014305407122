import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {PayoutCreationComponent} from './payout-creation/payout-creation.component';
import { AuthGuard } from 'src/app/auth/auth.guard';

const routes: Routes = [
  {
    path: 'payouts-creation/:id',
    component: PayoutCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payouts-creation',
    component: PayoutCreationComponent,
    canActivate: [AuthGuard]
  }
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)]
})
export class CreatePayoutRoutingModule { }
