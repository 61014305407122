import { Component, OnInit, Inject } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-add-whatsappcontact',
  templateUrl: './add-whatsappcontact.component.html',
  styleUrls: ['./add-whatsappcontact.component.scss']
})
export class AddWhatsappcontactComponent implements OnInit {

  elementData: any;
  userList = [];
  userNumber: number;
  userId: any;
  constructor(public dialogRef: MatDialogRef<AddWhatsappcontactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public settingService: SettingService) {
    this.elementData = data;
  }

  ngOnInit() {
    this.settingService.fetchUserList(this.elementData['id']).subscribe(res => {
      this.userList = res['result'];
    })
  }
  onUserChange(event) {
    let index = this.userList.findIndex( i => i['id'] == event);
    this.userId = this.userList[index]['email'];
  }
  // validateNUmber(number) {
  //   var regex = /^((\\+91-?)|0)?[0-9]{10}$/;
  //   if (regex.test(number)) {
  //     return true;
  //   }
  //   else{
  //     return false
  //   }
  // }
  type:string;
  onUserTypeChange(event){
    this.type= event

  }
  addWhatsappContact() {
    let obj = {
      businessAccountId: this.elementData['id'],
      type: this.type,
      email: this.userId
    }
    if(this.type == 'EMAIL'){
      obj['whatsapp']= true
    }
    else {
      obj['phoneNumber']= this.userNumber,
      obj['whatsapp']= false
    }
    this.settingService.addWhatsappContact(obj).subscribe(res => {
      if (res['result'] == true) {
        this.dialogRef.close();
      }
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }
}