<div class="h-32 mb-5p theme-blue-bg numbersTodaySection">
  <div class="card-header">
    <h2>Numbers Today</h2>
    <!-- <div class="chart-filter">
  
  
      <button class="chartsMoreOption-btn" mat-button [matMenuTriggerFor]="chartsMoreOption"><i class="ri-more-2-fill"></i></button>
      <mat-menu class="moreOptionMenu" #chartsMoreOption="matMenu">
        <button mat-menu-item><i class="ri-download-2-line"></i> Download</button>
      </mat-menu>
    </div> -->
  </div>
  <div class="numbersToday">
  
    <div class="graph w-100">
      <div class="custom-row">
        <div class="col-custom w-50 flex-column d-flex" *ngFor="let invoice of invoiceData">
             <div class="time-outline bg-1"></div>
             <label for="">{{invoice.name}}</label>
             <div class="d-flex">
              <span>{{invoice.doc_count}}</span>
              <span>₹{{invoice.amount}}</span>
             </div>
           
          </div>
          <!-- <div class="col-custom w-50 flex-column d-flex">
            <div class="d-flex flex-column">
              <div class="time-outline bg-1"></div>
              <label for="">Requested Invoices</label>
              <div class="d-flex">
                <span>78 k</span>
                <span>₹1.2 cr</span>
              </div>
        
            </div>
           
          </div> -->
      </div>
    </div>
  </div>
</div>