import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LenderLeadsRoutingModule } from './lender-leads-routing.module';
import { LeadsComponent } from './leads/leads.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { LeadCommentDialogComponent } from './lead-comment-dialog/lead-comment-dialog.component';

@NgModule({
  declarations: [LeadsComponent, LeadCommentDialogComponent],
  imports: [
    CommonModule,
    LenderLeadsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModulesModule
  ],
  entryComponents: [LeadCommentDialogComponent],
})
export class LenderLeadsModule { }
