import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import { saveAs } from 'file-saver';
import { RequestFinancePdfComponent } from '../request-finance-pdf/request-finance-pdf.component';
import { SendWhatsappMsgDialogComponent } from '../../suppliers/listing/send-whatsapp-msg-dialog/send-whatsapp-msg-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MarkDisbursedDialogComponent } from '../mark-disbursed-dialog/mark-disbursed-dialog.component';
@Component({
  selector: 'app-request-finance',
  templateUrl: './request-finance.component.html',
  styleUrls: ['./request-finance.component.scss']
})
export class RequestFinanceComponent implements OnInit {

  tabName: string = "Invoice Info";
  historyTabs = ['Invoice Info'];
  isBuyer: boolean;
  invId: string;
  requestInvoiceData: any;
  status: boolean = true;
  invNumber: string;
  invIds: any = [];
  isLead: boolean = false;

  constructor(public dialogRef: MatDialogRef<RequestFinanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public authService: AuthService,
    private pageService: PagesService, public dialog: MatDialog,
    private toastService: ToastrService) {
    if (data['data'] && data['multiple'] == false) {
      this.invId = data['data']['id'];
      this.invNumber = data['data']['invoiceNumber'];
      if (data['data']['status'] != "OPEN") {
        this.status = false;
      }
    }
    else {
      data['data'].map(val => {
        if (val['status'] != "OPEN") {
          this.status = false;
        }
        this.invIds.push(val['id'])
      })
    }
  }

  ngOnInit() {
    if (this.authService.isBuyer()) {
      this.isBuyer = true;
    }
    if (this.authService.isLenderLeads()) {
      this.isLead = true;
    }
    if (this.invIds.length > 0) {
      this.pageService.getMultiInvoiceFinanceDetails(this.invIds).subscribe(res => {
        if (res['status']) {
          this.requestInvoiceData = res['result'];
        }
      })
    }
    else {
      this.pageService.getInvoiceFinanceDetails(this.invId).subscribe(res => {
        if (res['status']) {
          this.requestInvoiceData = res['result'];
        }
      })
    }
  }

  getTabValue(event) {
    if (event == 0) {
      this.tabName = 'Invoice Info'
    }
    else if (event == 1) {
      this.tabName = 'History'
    }
  }

  createLoanrequest() {
    let obj = {
      "id": this.invId,
      "multiple": false
    }
    this.pageService.generateDrawdownPDF([this.invId]).subscribe((res: any) => {
      const pdfContent = window.URL.createObjectURL(res.body);

      obj['view'] = pdfContent;
      const dialogRef = this.dialog.open(RequestFinancePdfComponent, {
        disableClose: true,
        width: '800px',
        height: '610px',
        data: obj,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(false);
      });
    })
  }

  generateDrawdownPDF() {
    let invId = [];
    if (this.data['multiple'] == false) {
      invId.push(this.invId);
    }
    else {
      invId = this.invIds;
    }
    this.pageService.generateDrawdownPDF(invId).subscribe((res: any) => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      saveAs(res.body, result);
      return result.replace(/"/g, '');
    })
  }

  isMultipleSupplierSelected: boolean = false;
  sendWhatsappMessage() {
    let invoiceDetails = this.data['data'];
    for (let i = 0; i < invoiceDetails.length; i++) {
      if (invoiceDetails[0].supplierId != invoiceDetails[i].supplierId) {
        this.isMultipleSupplierSelected = true;
        this.toastService.warning("Please select Only one supplier to send request");
        return
      }
    }
    if (this.isMultipleSupplierSelected = true) {
      let obj = {
        value: 'EpInvoice',
        arrayReq: this.data['data'],
        isFromFinance: true
      }
      const dialogRef = this.dialog.open(SendWhatsappMsgDialogComponent, {
        disableClose: true,
        width: '550px',
        height: '520px',
        data: obj
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }

  }

  markDisbursed() {
    const dialogRef = this.dialog.open(MarkDisbursedDialogComponent, {
      disableClose: true,
      width: '550px',
      height: '400px',
      data: this.invId
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close(false);
    });
  }
}
