import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from '../../kanban.service';
import { LosTrackerCommentDailogComponent } from '../../los-tracker/los-tracker-comment-dailog/los-tracker-comment-dailog.component';

@Component({
  selector: 'app-credit-tracker-comment',
  templateUrl: './credit-tracker-comment.component.html',
  styleUrls: ['./credit-tracker-comment.component.scss']
})
export class CreditTrackerCommentComponent implements OnInit {
  laneData: any;
  comment: string = '';
  comments = [];
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  @ViewChild('commentField') commentField: ElementRef;
  commentControl = new FormControl('');
  showUserSuggestions = false;
  filteredUsers = [];
  assigneeList: any[];
  actionOnUser: any;
  actionDueDate: string;
  placeHolderCommentText = "Type your comment here . Use '@' and '/date' for mention Users and date selection"
  enteredCommentText: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LosTrackerCommentDailogComponent>,
    private kanbanService: KanbanService, public toastrService: ToastrService) { }

  ngOnInit() {
    this.laneData = this.data;
    this.filteredUsers = this.data.assigneeList.map(user => user.userName);
    this.assigneeList = this.filteredUsers;
    this.getCommentList();
  }

  getCommentList() {
    let obj = {
      leadTrackerId: this.laneData.rowData.id
    }
    this.kanbanService.getLeadCommentsList(obj).subscribe((res => {
      this.comments = [].concat(res['result']);
    }));
  }

  addComment() {
    if (this.commentControl.value.trim().length > 0) {
      let actionUserId = this.data.assigneeList.filter((data) => {
        return data.userName == this.actionOnUser
      })
      let obj = {
        message: this.commentControl.value,
        leadTrackerId: this.laneData.rowData.id,
        taskAssignedTo: (actionUserId && actionUserId.length) ? actionUserId[0].userName : undefined,
        commentDueDate: this.actionDueDate
      }
      this.kanbanService.losTracketComment(obj).subscribe(res => {
        this.toastrService.success("Comment Saved")
        this.getCommentList();
        this.commentControl.setValue(null);
        this.actionDueDate = null;
        this.actionOnUser = null;
      });
    }
    else {
      this.toastrService.error("Enter Comment First");
    }
  }
  getProfileName(data) {
    let matches = data.name || '--';
    let name = matches.match(/\b(\w)/g);
    return name.join('').toUpperCase();
  }
  getCommentTime(data) {
    let time = new Date(data.createdAt);
    let properDate = moment(time).format("dddd, MMMM Do YYYY, h:mm a");
    return properDate;
  }
  onKeyUp(event: KeyboardEvent) {
    const commentText = this.commentControl.value || '';
    this.enteredCommentText = this.commentControl.value || '';
    this.filteredUsers = [];

    if (commentText.includes('@')) {
      if (!this.isActionItemPresent()) {
        this.showUserSuggestions = true;
        const searchTerm = commentText.split('@').pop();
        this.filteredUsers = this.assigneeList.filter(user => user.toLowerCase().includes(searchTerm.toLowerCase()));
      }
    } else {
      this.showUserSuggestions = false;
    }

    if (commentText.includes('/date') && !this.datePicker.opened) {
      if (!this.isDueDatePesent()) {
        this.datePicker.open();
        this.datePicker._selectedChanged.subscribe((selected: Date) => {
          // Create a new date object that accounts for the timezone offset
          const tzOffset = selected.getTimezoneOffset() * 60000; // offset in milliseconds
          const localISODate = (new Date(selected.getTime() - tzOffset)).toISOString().split('T')[0];

          const indexOfDate = commentText.lastIndexOf('/date');
          this.commentControl.setValue(`${commentText.substring(0, indexOfDate)}${localISODate}${commentText.substring(indexOfDate + 5)}`);
          this.actionDueDate = localISODate;
          this.datePicker.close();
        }, (err) => { }, () => {
          this.datePicker._selectedChanged.unsubscribe();
        });
      }
    }
  }

  isActionItemPresent() {
    if (this.enteredCommentText) {
      if (this.enteredCommentText.includes(this.actionOnUser)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isDueDatePesent() {
    if (this.enteredCommentText) {
      if (this.enteredCommentText.includes(this.actionDueDate)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  selectUser(user) {
    const commentText = this.commentControl.value;
    const indexOfAt = commentText.lastIndexOf('@');
    this.commentControl.setValue(`${commentText.substring(0, indexOfAt)}@${user} `);
    this.showUserSuggestions = false;
    this.actionOnUser = user;
    this.commentField.nativeElement.focus();
  }

  formatComment(comment): string {
    const tagRegex = /@(\b\w+\b \b\w+\b)/g;
    const dateRegex = /\b\d{4}-\d{2}-\d{2}\b/g;
    let formattedDate: any;
    if (comment.commentDueDate) {
      const date = new Date(comment.commentDueDate);
      // Format the date to YYYY-MM-DD
      formattedDate = date.toISOString().split('T')[0];
    }
    if (comment.message.includes(comment.taskAssignedTo) && comment.message.includes(formattedDate)) {
      let formattedComment = comment.message
        .replace(tagRegex, `<span class="tagged-user">@$1</span>`)
        .replace(dateRegex, `<span class="date-text">$&</span>`);
      return formattedComment;
    }
    else if (comment.message.includes(comment.taskAssignedTo) && !(comment.message.includes(formattedDate))) {
      let formattedComment = comment.message
        .replace(tagRegex, `<span class="tagged-user">@$1</span>`);
      return formattedComment;
    }
    else if (!(comment.message.includes(comment.taskAssignedTo)) && comment.message.includes(formattedDate)) {
      let formattedComment = comment.message
        .replace(dateRegex, `<span class="date-text">$&</span>`);
      return formattedComment;
    }
    else {
      let formattedComment = comment.message
      return formattedComment;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
