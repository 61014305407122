import { NgModule } from "@angular/core";
import { DollarStringFormat } from "./dollarStringFormat";
import { TimeAgoPipe } from "./timeAgo.pipe";
import { RupeeStringFormatPipe } from "./rupeeStringFormat.pipe";
import { CustomDateTimePipe } from "./customDateTime.pipe";
import { DueTimePipe } from "./dueTime.pipe";
import { IndianCurrencyPipe } from './indianCurrency.pipe';

@NgModule({
  declarations: [	
    DollarStringFormat,
    TimeAgoPipe,
    RupeeStringFormatPipe,
    CustomDateTimePipe,
    DueTimePipe,
    IndianCurrencyPipe
   ],
  exports: [
    DollarStringFormat,
    TimeAgoPipe,
    RupeeStringFormatPipe,
    CustomDateTimePipe,
    DueTimePipe,
    IndianCurrencyPipe
  ],
  providers: [IndianCurrencyPipe],
})
export class PipeModule {}
