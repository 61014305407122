import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { publicDecrypt } from 'crypto';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent implements OnInit {

  supplierID: string;
  downloadType: string = 'ALL';
  isHidden : boolean = false;
  resultDownload: any;
  startDate: any;
  endDate: any;
  isStartDate: boolean = false;
  isEndDate: boolean = false;
  constructor(public dialogRef: MatDialogRef<DocumentDialogComponent>, public settingService : SettingService, public tosterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }
  changeFromDate(event) {
    this.isStartDate = false;
    let Start = event.value
    this.startDate = moment(Start).format('YYYY-MM-DD');
  }
  changeToDate(event) {
    this.isEndDate = false;
    let endValue = event.value;
    this.endDate = moment(endValue).format('YYYY-MM-DD');
  }
  addSuppliers(){
    if(this.startDate  == null && this.endDate != null){
        this.isStartDate = true;
        return false;
    } else if (this.endDate  == null && this.startDate != null) {
        this.isEndDate = true;
        return false;
    } else{
        this.isStartDate = false;
        this.isEndDate = false;
      }
    let obj = {}
    if(this.supplierID != null){
    obj['supplierId'] = this.supplierID
    }
    if(this.startDate != null && this.endDate != null ){
    obj['startDate'] = this.startDate ? this.startDate : null;
    obj['endDate'] = this.endDate ? this.endDate : null;
    }
    if(this.data && !this.data.isByMonth) {
    this.settingService.downloadSystemInvoice(obj).subscribe((res: any) => {
      this.downloadSystemInvoice(res);
      this.supplierID = null;
      this.startDate = null;
      this.endDate = null;
    });
  }
    else{
    this.settingService.downloadCIByMonth(obj).subscribe((res: any) => {
      this.downloadCIByMonth(res);
      this.startDate = null;
      this.endDate = null;
    });
  }
  }
  downloadSystemInvoice(res){
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
     let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    this.dialogRef.close()
    this.tosterService.success("Downloaded Successfully")
    return fileName;
  }
  downloadCIByMonth(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
     let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    this.dialogRef.close()
    this.tosterService.success("Downloaded Successfully")
    return fileName;
  }
  selectDownloadType(event){
    if(event == 'ALL'){
      this.supplierID = null;
      this.startDate = null;
      this.endDate = null;
      this.isHidden = false;
    } else{
      this.isHidden = true;
    }
    this.downloadType = event;
  }
}

