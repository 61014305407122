import { Component, OnInit, Inject } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-send-whatsapp-msg-dialog',
  templateUrl: './send-whatsapp-msg-dialog.component.html',
  styleUrls: ['./send-whatsapp-msg-dialog.component.scss']
})
export class SendWhatsappMsgDialogComponent implements OnInit {
  msgContent: string;
  elementData: any;
  lastMsgDate: any;
  userList = [];
  templateResponse: any;
  selectedUser: any;
  isdisable: boolean = true;
  selectdInvoiceID = [];
  setInvoiceIDArray = [];
  selectedEPReq: string;
  isFromFinance: boolean = false;
  messagetype: string;
  selectedOption: any = 'early_payment_selection1';

  constructor(public dialogRef: MatDialogRef<SendWhatsappMsgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public settingService: SettingService, public toasterService: ToastrService,) {
    this.elementData = data;
    this.selectdInvoiceID = this.elementData['arrayReq']
    if (data['isFromFinance']) {
      this.isFromFinance = true
    }
    else if (this.selectdInvoiceID != null) {
      this.selectdInvoiceID.forEach(ele => {
        this.setInvoiceIDArray.push(ele.id);
      })
    }
  }

  ngOnInit() {
    this.selectedEPReq = 'Early payment request';
  }
  userNumber: number;
  userEmail: string;
  userName: string;
  onUserListChange(event, value) {
    if (value == 'email') {
      this.userEmail = event
    }
    if (!this.isFromFinance) {
      for (let i = 0; i < this.userList.length; i++) {
        if(value == 'contact'){
          if (this.userList[i].phoneNumber == event) {
            this.userNumber = this.userList[i].phoneNumber;
            this.userName = this.userList[i].name
          }
        }
        else if(value == 'email'){
          if (this.userList[i].email == event) {
            this.userName = this.userList[i].name
          }
        }
      }
      this.fetchWhatsappMsgContent(this.selectedOption);
    }
    else {
      this.isdisable = false;
      this.setMsgData();
    }
  }

  fetchWhatsappMsgContent(msg?: string) {
    let obj = {
      anchorMasterAccoutnId: localStorage.getItem('credlixUserID'),
      name: this.userName,
      phoneNumber: this.userNumber,
      templateType: msg,
      messengerType: this.selectedType,
      email: this.userEmail
    }
    if (this.elementData['value'] == 'EpInvoice') {
      obj['businessAccountId'] = this.selectdInvoiceID[0]['supplierBusinessAccountId']
      obj['invoices'] = this.setInvoiceIDArray
    }
    else {
      obj['businessAccountId'] = this.elementData['id'];
    }
    this.settingService.getWhatsappContent(obj, this.elementData).subscribe(res => {
      this.templateResponse = res['result'];
      this.msgContent = res['result'].content;
      this.lastMsgDate = res['result'].lastMessageDate;
      this.isdisable = false;
    }, (error) => {
      if (error['status'] == 400) {
        this.isdisable = true;
      }
    })
  }

  fetchUserList() {
    this.templateResponse = null;
    this.msgContent = null;
    this.lastMsgDate = null;
    let type = 'PHONE';
    if(this.selectedType== 'email'){
      type= 'EMAIL'
    }

    if (this.elementData['value'] == 'EpInvoice') {
      this.settingService.fetchWhatsappUserList(this.selectdInvoiceID[0]['supplierBusinessAccountId'], true,type).subscribe(res => {
        this.userList = res['result'];
      })
    }
    else {
      this.settingService.fetchWhatsappUserList(this.elementData['id'], true, type).subscribe(res => {
        this.userList = res['result'];
      })
    }
  }


  sendWhatsappMessage() {
    let dynURL: any;

    if (!this.isFromFinance) {
      let obj = {
        anchorMasterAccoutnId: localStorage.getItem('credlixUserID'),
        name: this.userName,
        phoneNumber: this.userNumber,
        content: this.msgContent,
        email: this.userEmail
      }
      if (this.lastMsgDate != null) {
        obj['lastMessageDate'] = this.lastMsgDate
      }
      if (this.templateResponse.invoices != null) {
        obj['invoices'] = this.templateResponse['invoices'];
        obj['totalNoOfInvoices'] = this.templateResponse['totalNoOfInvoices'];
      }
      if (this.templateResponse.values != null) {
        obj['values'] = this.templateResponse['values'];
      }

      if (this.elementData['value'] == 'EpInvoice') {
        obj['businessAccountId'] = this.selectdInvoiceID[0]['supplierBusinessAccountId']
      }
      else {
        obj['businessAccountId'] = this.elementData['id']
      }
      if (this.selectedType == 'whatsapp') {
        obj['templateType'] = this.templateResponse['templateType'];
        dynURL = this.settingService.sendWhatsappMsg(obj)
      }
      else if (this.selectedType == 'email') {
        dynURL = this.settingService.sendEmailMsg(obj)
      }
      dynURL.subscribe(res => {
        if (res['status'] == 200) {
          this.toasterService.success("Message has been send successfully");
          this.dialogRef.close();
        }
      });
    }
    else {
      this.toasterService.success("Message has been send successfully");
      this.dialogRef.close();
    }
  }


  selectMessageOptions(event: any) {
    // let selectedoption = event.value;
    // if (selectedoption == 'GST Upload request') {
      // this.messagetype = 'GST Upload request';
      // this.fetchWhatsappMsgContent('payment_hold_request');
    // }
    // else{
    //   // this.messagetype = 'Early payment request';
    this.selectedOption = event.value;
      this.fetchWhatsappMsgContent(event.value);
    // }
  }
  closeDialog() {
    this.dialogRef.close();
  }

  setMsgData() {
    this.msgContent = "Hi *Sunita Harish Ruia*,\nCongratulations, you can get *loan* for 1 invoices.\n\n```Total value:``` *₹6,96,805.00*\n=========================\n```Amount disbursed:``` *₹6,96,805.00*\n```Interest:``` *₹7,197.14*\n========================\n```Total (you pay):``` *7,04,002.14*\n\nGet finance by: *26/04/2022*\nTap \"below\" to request.👇👇👇 | [Request Finance]";
  }
  selectedType:string;
  onMessageTypeChange(event){
    this.selectedType= event;
    this.fetchUserList()
  }
}
