import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "indianCurrency",
})
export class IndianCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return "0";
    }

    let [integerPart, decimalPart] = value.toFixed(2).split(".");

    let lastThree = integerPart.substring(integerPart.length - 3);
    let otherNumbers = integerPart.substring(0, integerPart.length - 3);
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }

    let formattedInteger =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }
}
