import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { KanbanService } from '../../kanban.service';

@Component({
  selector: 'app-funnel-comment',
  templateUrl: './funnel-comment.component.html',
  styleUrls: ['./funnel-comment.component.scss']
})
export class FunnelCommentComponent implements OnInit {
  displayedColumns:any[]=["leadId", "leadName", "leadSource", "buyerData"];
  dataSource:any[]= [];
  pageNumber: number = 0;
  pageSize : number = 10;
  totalRecords : number = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public kanbanService: KanbanService
) { }

  ngOnInit() {
    this.getSheetData(false);
  }
  getSheetData(reset?){
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = this.data;
    let obj2 = {
      pageNo: this.pageNumber,
      size: this.pageSize
    }
    Object.assign(obj,obj2);
    this.totalRecords = this.data.count;
    this.kanbanService.getFunnelSheetData(obj).subscribe({
      next:(resp:any)=>{
        this.dataSource = resp.result[0].leadTrackerDTOS;
      }
    })
  }
  losBoard(element) {
    window.open('/#/cardDetails/' + element.laneTaskId);
  }
  getMoreData(event: any){
    this.pageNumber = event.pageIndex;
    // this.pageNumber = this.pageNumber + 1;
    this.getSheetData();
  }

}