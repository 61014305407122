import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from 'src/app/settings/setting.service';
import * as _ from 'lodash';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-view-account-statement',
  templateUrl: './view-account-statement.component.html',
  styleUrls: ['./view-account-statement.component.scss']
})
export class ViewAccountStatementComponent implements OnInit {

  setbuyerID: string;
  businessName: string;
  displayedColumns = [];
  accountStatementData = [];
  headerDisplayColumns = [];
  dataSource = new MatTableDataSource(this.accountStatementData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  buyerId: string;

  fieldConfig = [
    //Invoice #
    {"key": "Invoice No","field": "invoiceNumber"},
    {"key": "Total Invoice Amount","field": "amount"},
    //Invoice Details
    {"key": "Buyer Name","field": "buyerName"},
    {"key": "Invoice Date","field": "invoiceDate"},
    {"key": "Net Invoice Payable","field": "netPayable"},
    {"key": "Credit Period","field": "creditDays"},
    //Disbursement Details
    {"key": "Disbursed Amount","field": "disbursementAmount"},
    {"key": "Disbursed Date","field": "disbursementDate"},
    {"key": "Disbursement UTR","field": "disbursementUtr"},
    //Repayment Details
    {"key": "Total Due","field": "totalDue"},
    {"key": "Due Date","field": "repaymentDate"},
    {"key": "Interest Accrued","field": "acureInterest"},
    {"key": "Repayment Received","field": "repaymentReceived"},
    {"key": "Last Repayment Date","field": "lastRepaymentDate"},
    {"key": "Interest Charged","field": "interestCharged"},
    {"key": "Balance","field": "balance"},
    {"key": "Overdue","field": "overdue"},
    {"key": "Days Past Due (DPD)","field": "daysPassed"},
]

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private settingService: SettingService, private pageService: PagesService,
    private authService: AuthService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.setbuyerID = params['id'];
      this.businessName = params['businessName'];
      this.buyerId = params['buyerId'];
    })
    this.getAccountSummary();
  }

  navigateToSupplierPage(){
    this.router.navigate(['/financeListing/' + this.setbuyerID + '/' + this.businessName]);
  }

  breadcrumNavigation() {
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

  getAccountSummary(){
    let masterAccId;
    if (this.authService.isCredlixUser()) {
     masterAccId = localStorage.getItem('credlixUserID');
    }
    this.pageNumber = this.paginator.pageIndex * this.pageSize,
    this.pageService.getAccountSummary(masterAccId, this.buyerId, this.pageNumber, this.pageSize).subscribe(res=>{
      if(res['result']){
          this.accountStatementData = res['result'];
          this.dataSource.data = this.accountStatementData;
          this.totalRecords = this.accountStatementData.length;
          this.createHeaders(this.fieldConfig);
      }
      else {
        this.dataSource.data.length = 0;
      }
      this.paginator.length = this.totalRecords;
    })
  }

    //creating columns dynamically for loan invoice table
    createHeaders = (config) => {
      this.headerDisplayColumns = [];
      this.displayedColumns = [];
      config.map((each, id) => {
        this.headerDisplayColumns.push(each.field);
        each['cell'] = (element: any) => {
          if(each.field == 'amount' || each.field == 'netPayable'
          || each.field == 'interestCharged' || each.field == 'acureInterest'
          || each.field == 'balance' || each.field == 'totalDue' || each.field == 'disbursementAmount'){
            return this.settingService.numDifferentiation(element[each.field]);
          }
          else{
            return _.get(element, each.field);
          }
        }
      })
      this.displayedColumns = config;
    }

    getTitle(element){
      if(element == null){
        return 0;
      }
      else{
        return element;
      }
    }

}
