import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-update-milestone-cnf',
  templateUrl: './update-milestone-cnf.component.html',
  styleUrls: ['./update-milestone-cnf.component.scss']
})
export class UpdateMilestoneCnfComponent implements OnInit {

  myControl = new FormControl("");
  selectedAssigneId: any;
  selectedRevivalReason: any;
  isReasonBtnVisible: any = false;
  taskRemark: any;
  assigneeList: any;
  filterAssigneList: any = [];
  filteredOptions: Observable<string[]>;
  selectedDate: any;
  textfield: any;
  actionItem: any;

  ngOnInit() {
    this.getAssigneeList();
  }

  constructor(
    private _kService: KanbanService,
    private dialogRef: MatDialogRef<UpdateMilestoneCnfComponent>,
    private toasterService: ToastrService) { }

  getAssigneeList() {
    this._kService.getKanbanUserList().subscribe((list) => {
      this.assigneeList = list["result"];
      this.assigneeList.forEach((ele) => {
        this.filterAssigneList.push(ele.userName);
      });
      this.filteredOptions = this.filterAssigneList;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value || ""),
        )
      );
    });
  }

  _filter(value: string): string[] {
    if (!this.isValidDate(value)) {
      const filterValue = value.toLowerCase();
      return this.filterAssigneList.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    }
  }

  isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
  closeDialog() {
    this.dialogRef.close();
  }
  saveBuyerDataRow() {
    if (this.selectedDate && this.selectedAssigneId && this.actionItem) {
      const dataList = {
        "actionDate": this.selectedDate,
        "pendingdOn": this.selectedAssigneId,
        "actionItem": this.actionItem
      }
      console.log(this.actionItem, this.selectedAssigneId, this.selectedDate);
      this.dialogRef.close(dataList);
    }
    else {
      this.toasterService.error("Fill all mandatory fields");
    }
  }
}
