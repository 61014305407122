import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmationModelComponent } from 'src/app/shared-files/shared-component/confirmation-model/confirmation-model.component';
import { AddSupplierDialogComponent } from './listing/add-supplier-dialog/add-supplier-dialog.component';
import { BulkactionsDialogComponent } from './listing/bulkactions-dialog/bulkactions-dialog.component';
import { ConfirmationDialogComponent } from './listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component';
import { DownloadReconcileComponent } from './listing/download-reconcile/download-reconcile.component';
import { EditdetailsDialogComponent } from './listing/editdetails-dialog/editdetails-dialog.component';
import { ListingComponent } from './listing/listing.component';

const routes: Routes = [
  {
    path: '',
    component: ListingComponent,
    children: [
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  entryComponents: [
    AddSupplierDialogComponent, ConfirmationDialogComponent, BulkactionsDialogComponent,
    DownloadReconcileComponent, EditdetailsDialogComponent, ConfirmationModelComponent
  ],

})
export class SuppliersRoutingModule { }
