<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap">
      <div class="lft">
        <!-- <button class="filter back" mat-button (click)="navigateToSupplierPage()">
          <mat-icon>arrow_back</mat-icon>
        </button> -->
      </div>
    </section>
    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">

      <div class="breadcrumb">
        <span>Leads</span>
      </div>
    </div>

    <div class="mat_table">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort style="width: 100%;padding-left: 2%;"
        [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">

        <ng-container matColumnDef="businessName">
          <th mat-header-cell *matHeaderCellDef> Business (Lead)Name </th>
          <td mat-cell *matCellDef="let element" style="color: #0787dd;">
            <span>({{element.searchKey}}) {{element.businessName}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="onBoarding">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <span class="isOnBoarding" *ngIf="element.isOnboarding == true">Onboarding On</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <span class="onBoarding" [ngClass]="setColorCode(element.status)"> {{element.status}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="entityType">
          <th mat-header-cell *matHeaderCellDef> Type Of Entity</th>
          <td mat-cell *matCellDef="let element">
            <span> {{element.typeOfEntity}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="anchorName">
          <th mat-header-cell *matHeaderCellDef> Anchor Name</th>
          <td mat-cell *matCellDef="let element">
            <span> {{element.anchorName}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location </th>
          <td mat-cell *matCellDef="let element">
            <span> {{element.location}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell *matHeaderCellDef>Last Updated </th>
          <td mat-cell *matCellDef="let element">
            <span> {{element.lastUpdated }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <i *ngIf="element.status != 'Pending'" class="ri-download-line dwnload" title="Download Leads"
              (click)="downloadLeads(element)"></i>

            <i *ngIf=" authService.isLenderLeads()" class="ri-eye-line dwnload"
              (click)="editSupplierDetails(element)"></i>

            <!-- <span class="dwnload" (click)="downloadLeads(element)"> Download</span> -->
            <mat-icon *ngIf="element.status != 'Pending' && !authService.isLenderLeads()" class="icon_details"
              (click)="editSupplierDetails(element)">edit</mat-icon>
            <mat-icon *ngIf="element.status == 'Pending' && !authService.isLenderLeads()" style="text-align: right;"
              class="icon_details" (click)="editSupplierDetails(element)">edit</mat-icon>

            <i style="text-align: left;" class="ri-message-2-line dwnload ring-container"
              (click)="openCommentDialog(element)"><span *ngIf="element['hasNewComments']" class="ringring"></span><span *ngIf="element['hasNewComments']" class="circle" style="color: #DD4501;font-size: 35px;margin-left: -4px;"></span></i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
      [pageSize]="pageSize" [pageSizeOptions]="[25,50,75,100]" (page)="getPaginatorData($event)" showFirstLastButtons>
    </mat-paginator>

  </div>
</div>