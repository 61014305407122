<div class="adres-dialog">
  <h5>Customer Billing Address</h5>
  <form [formGroup]="addressUpdateForm" (ngSubmit)="submitAddress()">
    <div class="address-update">
      <mat-form-field appearance="outline">
        <mat-label>Trade Name</mat-label>
        <input matInput formControlName="tradeName">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput formControlName="address">
      </mat-form-field>

      <mat-form-field appearance="outline" class="p-email">
        <mat-label>Pin Code</mat-label>
        <input matInput formControlName="pincode">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Area</mat-label>
        <input matInput formControlName="area">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <input matInput formControlName="state">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input matInput formControlName="country">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>GSTIN</mat-label>
        <input matInput formControlName="gstIn">
      </mat-form-field>

    </div>
    <button mat-raised-button class="btn_login">SAVE</button>
  </form>
</div>