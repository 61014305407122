import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as moment from "moment";
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { PagesService } from '../../pages/pages.service';
import { PurchaseOrderDataSource } from './purchase-order-ds';
import { SupplierInvoiceDataSource } from './supplier-invoice-ds';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
// import { MatSort, Sort } from '@angular/material/sort';


@Component({
  selector: 'app-doc-listing',
  templateUrl: './doc-listing.component.html',
  styleUrls: ['./doc-listing.component.scss']
})

export class DocListingComponent implements OnInit {
  selectedTab = "invoice";
  supplierInvoiceDataSource: SupplierInvoiceDataSource;
  purchaseOrderDataSource: PurchaseOrderDataSource;
  selectedDataSource: DataSource<any>;
  showSearchIcon: boolean = true;
  searchText: string = "";
  customDateOption = null;
  selectedDueDate = null;
  customDueDateOption = null;
  selectedIssueDate = null;
  customerLogin = false;
  dateQuery: Object = {};
  defaultSort: object = {
    "field": "invoiceDate",
    "order": "desc"
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dateRangePicker') dateRangePicker;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) purchaseSort: MatSort;

  dataSource = new MatTableDataSource();

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor(private pageService: PagesService, private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params != null && params['tab'] != null && params['tab'].trim() == "purchase") {
        this.selectedTab = "purchase_order";
      }
    });
  }

  ngOnInit() {

    if (this.authService.getIsCusotmer()) {
      this.customerLogin = true;
    }

    if (this.selectedTab == "invoice") {
      this.sort.sort({ id: 'invoiceDate', start: 'desc', disableClear: true });
      this.supplierInvoiceDataSource = new SupplierInvoiceDataSource(this.pageService, this.paginator, this.customerLogin, this.sort);
      this.selectedDataSource = this.supplierInvoiceDataSource;
      this.defaultSort = {
        "field": "invoiceDate",
        "order": "desc"
      }
    } else {
      this.sort.sort({ id: 'purchaseDate', start: 'desc', disableClear: true });
      this.purchaseOrderDataSource = new PurchaseOrderDataSource(this.pageService, this.paginator, this.customerLogin, this.sort);
      this.selectedDataSource = this.purchaseOrderDataSource;
      this.defaultSort = {
        "field": "purchaseDate",
        "order": "desc"
      }
    }
    this.selectedDataSource['addSortWithoutLoad'](this.sort);
    if (this.selectedDataSource['documentsSubject'] == null || this.selectedDataSource['documentsSubject']['value'] == null || this.selectedDataSource['documentsSubject']['value'].length < 1)
      this.selectedDataSource['loadDocuments']();

      sessionStorage.clear();
      this.pageService.getClickEvent('clicked');
  }

  ngAfterViewInit() {
    // this.selectedDataSource['sort'] = this.sort;
  }
  onSearchChange(value) {
    this.searchText = value.trim();
  }
  searchDocuments() {
    if (this.searchText.trim() == "" && !this.selectedDataSource['checkInQuery']()) {
      return;
    }
    this.searchText = this.searchText.trim();
    if (this.selectedDataSource['checkInQuery']() && this.searchText.trim() == '') {
      this.resetSearch();
      return;
    }
    let field = this.selectedTab == "invoice" ? 'invoiceNumber' : 'searchKey';
    this.selectedDataSource['matSort'] = {};
    if (this.sort != null) {
      // this.sort.sort({ id: null, start: null, disableClear: true });
    }
    this.resetPaginator();
    this.selectedDataSource['addToWhereQuery'](field, {
      "type": "search",
      "value": this.searchText.trim(),
    });
    this.selectedDataSource['loadDocuments']();
  }

  resetSearch() {
    // if (this.sort == null || this.sort['active'] == null) {
    // if (this.selectedTab == "purchase_order") {
    //   this.sort.sort({ id: 'purchaseDate', start: 'desc', disableClear: false });
    // } else if (this.selectedTab == "invoice") {
    //   this.sort.sort({ id: 'invoiceDate', start: 'desc', disableClear: false });
    // }
    // }
    // this.selectedDataSource['addSortWithoutLoad'](this.sort);

    let field = this.selectedTab == "invoice" ? 'invoiceNumber' : 'searchKey';
    this.searchText = '';
    this.selectedDataSource['removeFromWhereQuery'](field);
  }
  addDays = function (days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
  isEligible(element) {
    let dueDate = new Date(element.dueDate);
    let Today = this.addDays(6)
    return dueDate.getTime() >= Today.getTime()
  }
  isDisabled() {
    return Object.keys(this.dateQuery).length < 1;
  }
  changeDocumentType() {
    if (this.selectedTab == "invoice") {
      this.sort.sort({ id: 'invoiceDate', start: 'desc', disableClear: false });
      if (this.supplierInvoiceDataSource == null) {
        this.supplierInvoiceDataSource = new SupplierInvoiceDataSource(this.pageService, this.paginator, this.customerLogin, this.sort);
      }
      this.selectedDataSource['addSortWithoutLoad'](this.sort);
      this.selectedDataSource = this.supplierInvoiceDataSource;
      this.searchText = '';

    }
    else if (this.selectedTab == "purchase_order") {
      this.sort.sort({ id: 'purchaseDate', start: 'desc', disableClear: false });
      if (this.purchaseOrderDataSource == null) {
        this.purchaseOrderDataSource = new PurchaseOrderDataSource(this.pageService, this.paginator, this.customerLogin, this.sort);
      }
      this.selectedDataSource = this.purchaseOrderDataSource;
      this.searchText = '';
      //this.selectedDataSource['resetQuery']();
      this.defaultSort = {
        "field": "purchaseDate",
        "order": "desc"
      }
      this.selectedDataSource['addSortWithoutLoad'](this.sort);
    }
    this.selectedDueDate = null;
    this.selectedIssueDate = null;
    this.dateQuery = {};
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("invoiceDate");
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("dueDate");
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("purchaseDate");
    this.customDateOption = null;
    this.customDueDateOption = null;
    this.selectedDataSource['loadDocuments']();

  }
  resetPaginator() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }
  getPage() {

    this.selectedDataSource['loadDocuments']();
  }
  markInvoiceEPStatus(element) {
    let request = {
      'invoiceIds': [element.id],
      'status': !element.isEligibleForEP
    }
    this.pageService.updateInvoiceEPEligibility(request).subscribe((resp) => {
      this.pageService.offSpinner();
      element.isEligibleForEP = !element.isEligibleForEP;
    })
  }
  applyFilters() {
    this.resetPaginator()
    if (this.sort == null || this.sort['active'] == null) {
      if (this.selectedTab == "purchase_order") {
        this.sort.sort({ id: 'purchaseDate', start: 'desc', disableClear: false });
      } else if (this.selectedTab == "invoice") {
        this.sort.sort({ id: 'invoiceDate', start: 'desc', disableClear: false });
      }
    }
    this.selectedDataSource['addSortWithoutLoad'](this.sort);
    if (this.dateQuery != null) {
      let q = this.dateQuery;
      let keys = Object.keys(q);
      for (let k = 0; k < keys.length; k++) {
        let key = keys[k];
        this.selectedDataSource['addToWhereQuery'](key, q[key]);
      }
    }
    this.selectedDataSource['loadDocuments']();
  }
  resetFilters() {
    //  this.searchText = '';
    //  this.selectedDataSource['resetQuery']();
    this.selectedDueDate = null;
    this.selectedIssueDate = null;
    this.dateQuery = {};
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("invoiceDate");
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("dueDate");
    this.selectedDataSource['removeFromWhereQueryWithoutLoad']("purchaseDate");
    this.customDateOption = null;
    this.customDueDateOption = null;

    let field = this.selectedTab == "invoice" ? 'invoiceDate' : 'purchaseDate';
    if (this.sort != null) {
      // this.selectedDataSource['matSort'] = {};
      //this.sort.sort({ id: null, start: null, disableClear: true });
    }
    this.selectedDataSource['forceLoad']()

  }

  addToCustomDateQuery(field, event, model) {
    if (model == "customDateOption") {
      field = this.selectedTab == "invoice" ? 'invoiceDate' : 'purchaseDate';
    }
    if (this[model] == -1 && event != null && event.startDate != null && event.endDate != null) {
      // this.selectedDataSource['addToWhereQuery'](field, {
      //   "type": "range",
      //   "start_value": event.startDate.format('YYYY-MM-DD'),
      //   "end_value": event.endDate.format('YYYY-MM-DD'),
      // });
      this.dateQuery[field] = {
        "type": "range",
        "start_value": event.startDate.format('YYYY-MM-DD'),
        "end_value": event.endDate.format('YYYY-MM-DD'),
      };
    } else if (this[model] != -1) {
      this.selectedDueDate = null;
      this.selectedIssueDate = null;
      // this.selectedDataSource['addToWhereQuery'](field, {
      //   "type": "range",
      //   "custom": event,
      // });
      this.dateQuery[field] = {
        "type": "range",
        "custom": event
      };
    }
  }

  removeFromQuery() {
    this.selectedDataSource['addToQremoveFromWhereQueryuery']("purchaseDate");
  }

  getRecord(row, event) {
    if (event == "invoice") {
      this.pageService.setinvoiceRecords(row);
      this.router.navigateByUrl('/documentView/invoice/' + row.id)
    }
    else if (event == "purchase_order") {
      this.pageService.setPurchaseRecords(row);
      this.router.navigateByUrl('/documentView/supplier-po/' + row.id)
    }

  }

  sortData(event) {
    console.log("sorting", this.sort, event)
    if (event != null && event['active'] != null && this.selectedDataSource != null) {
      this.resetPaginator();
      this.selectedDataSource['addSort'](event);
    }

  }
}
