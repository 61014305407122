<div class="dialog_modal">
  <mat-icon class="iconclose" (click)="dialogRef.close(false)" fxFlex="35" fxLayoutAlign="end">close</mat-icon>
  <mat-tab-group (selectedIndexChange)="getTabValue($event)">
    <mat-tab *ngFor="let tab of historyTabs; let index = index" [label]="tab">
    </mat-tab>
  </mat-tab-group>
  <div class="dialog_content">
    <div class="info" *ngIf="tabName == 'Invoice Info'">
      <div class="top-head" *ngIf="invIds.length > 0">
        <h1>Calculation for<span>{{invIds.length}} Invoices</span></h1>
      </div>
      <div class="top-head" *ngIf="invNumber">
        <span> Invoice Number: {{invNumber}}</span>
      </div>
      <div class="scrollsection" style="height:500px; overflow:auto;border-bottom: 1px solid #ccc;">
        <div class="mid_section">
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 fxFlex="65" fxLayoutAlign="start">Invoice Date</h5>
            <h5 fxFlex="35" fxLayoutAlign="end">{{(requestInvoiceData && requestInvoiceData.invoiceDate) ?
              (requestInvoiceData.invoiceDate | date) : '-'}}</h5>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 fxFlex="65" fxLayoutAlign="start">Estimated Loan disbursal Date</h5>
            <h5 fxFlex="35" fxLayoutAlign="end">{{(requestInvoiceData && requestInvoiceData.estimatedDisbursmentDate) ?
              (requestInvoiceData.estimatedDisbursmentDate | date) : '-'}}</h5>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 fxFlex="65" fxLayoutAlign="start">Credit Period</h5>
            <h5 fxFlex="35" fxLayoutAlign="end">{{(requestInvoiceData && requestInvoiceData.creditPeriod) ?
              requestInvoiceData.creditPeriod : '-'}} days</h5>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 fxFlex="65" fxLayoutAlign="start">Estimated Repayment Date</h5>
            <h5 fxFlex="35" fxLayoutAlign="end">{{(requestInvoiceData && requestInvoiceData.repaymentDate) ?
              (requestInvoiceData.repaymentDate | date) : '-'}}</h5>
          </div>
        </div>
        <div class="content" fxLayout="column">
          <div fxLayout="column" class="sml">
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Total invoiced Amount</h5>
              <h5 fxFlex="35" fxLayoutAlign="end">₹{{(requestInvoiceData && requestInvoiceData.totalAmount) ?
                (requestInvoiceData.totalAmount | number:'1.2-2') : '-'}}</h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Amount to be disbursed</h5>
              <h5 fxFlex="35" fxLayoutAlign="end">₹{{(requestInvoiceData && requestInvoiceData.totalAmount) ?
                (requestInvoiceData.totalAmount | number:'1.2-2') : '-'}}</h5>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <h5 fxFlex="65" fxLayoutAlign="start">Interest to be paid</h5>
              <h5 fxFlex="35" fxLayoutAlign="end">₹{{(requestInvoiceData && requestInvoiceData.interest) ?
                (requestInvoiceData.interest | number:'1.2-2') : '-'}}</h5>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="5px">
            <h5 class="bold" fxFlex="65" fxLayoutAlign="start">Total Payable</h5>
            <h5 class="bold" fxFlex="35" fxLayoutAlign="end">₹{{(requestInvoiceData && requestInvoiceData.totalPayable)
              ? (requestInvoiceData.totalPayable | number:'1.2-2') : '-'}}</h5>
          </div>
          <div class="downPayment" fxLayout="row">
            <div><i class="ri-file-ppt-2-line"></i></div>
            <div>
              <h6>Drawdown Request.pdf</h6>
              <span><a (click)="generateDrawdownPDF()">Download<i class="ri-arrow-down-s-fill"></i></a></span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomarea">
        <!-- <div class="rht" *ngIf="authService.isCredlixUser() && status" fxLayoutAlign="end">
          <div class="cat-1 grouped reqbtn dropdown">
            <button class="whtsapbtn" mat-button (click)="sendWhatsappMessage()">Send Whatsapp Message</button>
          </div>
        </div> -->
        <div class="rht" *ngIf="isLead && data['data']['status']=='REQUESTED'">
          <div class="cat-1 grouped reqbtn dropdown">
            <button class="whtsapbtn" mat-button (click)="markDisbursed()">Mark Disbursed</button>
          </div>
        </div>
        <mat-dialog-actions *ngIf="isBuyer && status">
          <div>
            <button mat-button (click)="createLoanrequest()">
              Request Finance
            </button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
    <!-- <div class="historyInfo" *ngIf="tabName == 'History'">
      <section class="invoice-history">
        <strong class="head">{{getEarlyPaymentsData.invoiceNumber}}</strong>
        <div class="history-list">
          <ul>
            <li>
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Invoice Added</span>
                <span class="nrml"> {{getEarlyPaymentsData.invoiceCreatedAt | date}}</span>
              </span>
              <span class="value">Invoice was added to credlix system.</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.isEligibleForEP==true">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">
                  Approved
                </span>
              </span>
              <ul class="nest" *ngIf="getEarlyPaymentsData['approvals'] != null">
                <li *ngFor="let approval of requiredApprovals">
                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getVerifiedApproval(approval.field).field !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getVerifiedApproval(approval.field).field ==null"></i>
                  <span class="lbl">
                    <span class="bold" *ngIf="approval.field =='supplyApproval'">
                      <strong>Internal Checks -</strong> <label class="txtwrp">
                        Moglix's Internal checks like category,Invoice type </label>
                    </span> 
                    <span class="bold" *ngIf="approval.field =='financeApproval'">
                      <div *ngIf="!isAccord">
                        <strong>POD Checks -</strong> <label class="txtwrp"> Check if POD has been received </label>
                      </div>
                        <div *ngIf="isAccord">
                        <strong>Invoice Amount -</strong> <label class="txtwrp">Should be <= ₹80,000 </label>
                      </div>
                    </span>
                    <span class="nrml" *ngIf="getVerifiedApproval(approval.field).field !=null">
                      {{getVerifiedApproval(approval.field).modifiedAt |date}}</span> </span>
                </li>
                <li *ngIf="getEarlyPaymentsData.isAbfrl== true">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.bookingDate !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.bookingDate ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong>Booking Status -</strong> <label class="txtwrp"> Check if booking has been received
                      </label>
                    </span>
                    <span class="nrml" *ngIf="getEarlyPaymentsData.bookingDate !=null">
                      {{getEarlyPaymentsData.bookingDate |date}}</span>
                  </span>
                </li>
              </ul>
            </li>
            <li *ngIf="getEarlyPaymentsData.isEligibleForEP==false">
              <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.isEligibleForEP==true"></i>
              <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.isEligibleForEP==false"></i>
              <span class="lbl">
                <span class="bold">
                  Approval Pending
                </span>
              </span>
              <ul class="nest">
                <li *ngFor="let approval of requiredApprovals">
                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getVerifiedApproval(approval.field).field !=null && approval.field =='supplyApproval'"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getVerifiedApproval(approval.field).field ==null 
                  && approval.field =='supplyApproval'"></i>

                  <i class="chck-sign ri-checkbox-circle-fill"
                    *ngIf="getEarlyPaymentsData.isPODDone == true && approval.field =='financeApproval'"></i>
                  <i class="chck-sign ri-time-line" *ngIf="(getEarlyPaymentsData.isPODDone == false || getEarlyPaymentsData.isPODDone == null)
                  && approval.field =='financeApproval'"></i>
                  <span class="lbl">
                    <span class="bold" *ngIf="approval.field =='supplyApproval'">
                      <strong>Internal Checks -</strong><label class="txtwrp">Moglix's Internal checks like
                        category,Invoice type
                      </label>
                    </span>
                    <span class="bold" *ngIf="approval.field =='financeApproval'">
                      <div *ngIf="!isAccord">
                      <strong>POD Checks -</strong> <label class="txtwrp"> Check if POD has been received </label>
                    </div>
                      <div *ngIf="isAccord">
                      <strong>Invoice Amount -</strong> <label class="txtwrp">Should be <= ₹80,000 </label>
                    </div>
                    </span>
                    <span class="nrml" *ngIf="getVerifiedApproval(approval.field).field !=null">
                      {{getVerifiedApproval(approval.field).modifiedAt |date}}</span>
                  </span>
                </li>
                <li *ngIf="getEarlyPaymentsData.isAbfrl== true">
                  <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.bookingDate !=null"></i>
                  <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.bookingDate ==null"></i>
                  <span class="lbl">
                    <span class="bold">
                      <strong>Booking Status -</strong> <label class="txtwrp"> Check if booking has been received
                      </label>
                    </span>
                    <span class="nrml" *ngIf="getEarlyPaymentsData.bookingDate !=null">
                      {{getEarlyPaymentsData.bookingDate |date}}</span>
                  </span>
                </li>
              </ul>
            </li>
            <li *ngIf="getEarlyPaymentsData.isDebitNoteCreated== true">
              <i class="chck-sign ri-checkbox-circle-fill"></i>
              <span class="lbl">
                <span class="bold">Debit Note Received</span>
                <span class="nrml">{{getEarlyPaymentsData.debitNoteDate | date}}</span>
              </span>
              <span class="value">₹{{getEarlyPaymentsData.debitAmount| number:'1.2-2'}}</span>
            </li>
            <li *ngIf="getEarlyPaymentsData.onHold== true">
              <i class="chck-sign ri-time-line"></i>
              <span class="lbl">
                <span class="bold">On Hold</span>
              </span>
              <span class="value">Till {{getEarlyPaymentsData.onHoldDate | date}}</span>
            </li>

            <li *ngFor="let history of historyResp; let i = index">
              <i class="chck-sign ri-checkbox-circle-fill" *ngIf="history.status =='PAYMENT_REQUESTED' || history.status =='ELIGIBLE' "></i>
              <i class="chck-sign-delay ri-error-warning-fill" *ngIf="history.status== 'DELAYED'"></i>
              <i class="chck-sign-cancl ri-close-circle-fill" *ngIf="history.status== 'CANCELLED'"></i>
              <span  *ngIf="history.status== 'ELIGIBLE'">
                <span class="lbl" >
                  <span class="bold"> Eligible </span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">{{history.reason}}</span>
              </span>
              <span *ngIf="history.status!= 'ELIGIBLE' && (history.status == 'SUPPLY_APPROVAL_AWAITED' || history.status == 'FINANCE_APPROVAL_AWAITED')">
                <i class="chck-sign ri-time-line"></i>
                <span class="lbl" >
                  <span class="bold"> Eligible </span>
                </span>
              </span>
              <span  *ngIf="history.status== 'PAYMENT_REQUESTED'">
                <span class="lbl" >
                  <span class="bold">Early Payment Requested</span>
                  <span class="nrml"> {{history.actionDate}}</span>
                </span>
                <span class="value">{{history.reason}}</span>
              </span>

              <span style="margin-top: 5px;"  *ngIf="requestIndex  == i && history.status== 'PAYMENT_REQUESTED' && getEarlyPaymentDetails[0].source != null">
                <span class="lbl" >
                  <span *ngIf="getEarlyPaymentDetails[0].source == 'WEB'" class="value">Source : Web</span>
                  <span *ngIf="getEarlyPaymentDetails[0].source == 'WHATSAPP'" class="value">Source : WhatsApp</span>
                </span>
              </span>

              <span  *ngIf="history.status== 'DELAYED'">
              <span class="lbl" >
                <span class="bold">Early Payment Delayed</span>
                <span class="nrml"> {{history.actionDate}}</span>
              </span>
              <span class="value">Reason: {{history.reason}}</span>
              <span class="value eta">ETA: {{history.expectedPaymentDate}}</span>
            </span>
            
            <span *ngIf="history.status== 'CANCELLED'">
              <span class="lbl" >
                <span class="bold">Early Payment Cancelled</span>
                <span class="nrml"> {{history.actionDate}}</span>
              </span>
              <span class="value">Reason: {{history.reason}}</span>
            </span>
              </li>

              <li *ngIf="getEarlyPaymentsData.isEPRequested == false && getEarlyPaymentDetails[0].epStatus!='Expired'">
                <i class="chck-sign ri-time-line"></i>
                <span class="lbl">
                  <span class="bold">Request Early Payment</span>
                </span>
                <span class="value">Before {{RequestEPDate | date}}</span>
              </li>
              <li *ngIf="getEarlyPaymentsData.onHold== false && getEarlyPaymentsData.epStatus=='Expired'">
                <i class="chck-sign ri-checkbox-circle-fill"></i>
                <span class="lbl">
                  <span class="bold">Expired</span>
                  <span class="nrml">{{RequestEPDate | date}}</span>
                </span>
                <span class="value">Early payment window is expired. Payments
                  (if any) will be made by Moglix via standarad process.</span>
              </li>

            <li *ngIf="getEarlyPaymentDetails[0].epStatus!='Expired'">
              <i class="chck-sign ri-time-line"
                *ngIf="getEarlyPaymentsData.isEPPaid !=undefined && getEarlyPaymentsData.isEPPaid== false "></i>
              <i class="chck-sign ri-checkbox-circle-fill"
                *ngIf="getEarlyPaymentsData.isEPPaid !=undefined && getEarlyPaymentsData.isEPPaid== true"></i>
              <span class="lbl">
                <span class="bold">Early Payment Disbursement</span>
              </span>
              <span class="value" *ngIf="getEarlyPaymentsData.isEPRequested ==false  && (getEarlyPaymentsData.epStatus=='Eligible' || getEarlyPaymentsData.epStatus=='Supply Approval Awaited' || getEarlyPaymentsData.epStatus=='Finance Approval Awaited')">Within
                {{getEarlyPaymentsData.minDaysInterval}} Days of request</span>

              <span class="value" *ngIf="getEarlyPaymentsData.isEPRequested ==true
                && getEarlyPaymentsData.epStatus=='Requested'"> 
                Before {{getEarlyPaymentsData.expectedPaymentDate | date}}</span>

              <span class="value" *ngIf="(getEarlyPaymentsData.epStatus =='Paid' || getEarlyPaymentsData.epStatus == 'Partially Paid') 
                  && getEarlyPaymentsData['transactions'] != null">
                Settled ₹{{getEarlyPaymentsData['transactions'][0].amount}}
                <span class="nrml payDate" *ngIf="getEarlyPaymentsData.transactions !=null">
                  {{getEarlyPaymentsData['transactions'][0].paymentDate |date}}</span>
              </span>
            </li>
            <li *ngIf="getEarlyPaymentDetails[0].epStatus!='Expired'">
              <i class="chck-sign ri-time-line" *ngIf="getEarlyPaymentsData.isBalancePaid==null"></i>
             
              <i class="chck-sign ri-time-line"
                *ngIf="getEarlyPaymentsData.isBalancePaid!=null && getEarlyPaymentsData.isBalancePaid == false "></i>
            
                <i class="chck-sign ri-checkbox-circle-fill" *ngIf="getEarlyPaymentsData.isBalancePaid == true"></i>
              <span class="lbl">
                <span class="bold"> Balance Payment</span>
              <span class="nrml" *ngIf="getEarlyPaymentsData.epStatus =='Paid' && getEarlyPaymentsData['transactions'] != null">{{getEarlyPaymentsData.balanceDueDate |
                date}}</span>
              </span>
              <span class="value" *ngIf="getEarlyPaymentsData['transactions'] != null && (getEarlyPaymentsData.epStatus =='Paid' || getEarlyPaymentsData.epStatus== 'Partially Paid')"> {{getEarlyPaymentsData.balanceOnDueDate | number:'1.2-2'}}</span>
            </li>
          </ul>
        </div>
      </section>
    </div> -->

  </div>

</div>