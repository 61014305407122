import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/suppliers/listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component';
import { SettingService } from 'src/app/settings/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancel-ep-dialog',
  templateUrl: './cancel-ep-dialog.component.html',
  styleUrls: ['./cancel-ep-dialog.component.scss']
})
export class CancelEPDialogComponent implements OnInit {
  selectedInvoice: any;
  delayedReason: string;
  constructor(public dialogRef: MatDialogRef<CancelEPDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public settingService: SettingService, public toasterService: ToastrService, public dialog: MatDialog) {
    this.selectedInvoice = data;
  }

  ngOnInit() {

  }
  cancelEPRequest() {
    let confObj = {
      data: 'canceled',
      invoiceNumber: this.selectedInvoice['invoiceNumber']
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: '30%',
      height: 'auto',
      data: confObj,
    });

    let obj = {
      invoiceIds: [this.selectedInvoice['id']],
      isCancel: true,
      reason: this.delayedReason,
      anchorMasterAccountId: localStorage.getItem('credlixUserID')
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.settingService.cancelInvoiceMarked(obj).subscribe(res => {
          this.toasterService.success("Invoice marked cancelled successfully. You will get an email shortly");
          this.dialogRef.close();
        })
      }
    });
  }
  closeDialog() {
    this.dialogRef.close(true);
  }

}
