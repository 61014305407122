<div class="payout-layout">
  <div class="wrp">
    <div class="top">
      <h4 class="mb-10">{{payoutID}}</h4>
    </div>

    <div class="pyoutDetails">
        <div fxLayout="row" fxLayoutGap="1%" class="borrower" style="margin-top: 9px; margin-bottom: 8px;">
      <div fxLayout="column">
        <mat-card class="amount">
          <mat-card-content>
            <div class="bnkDetail"> Payment Reported (anchor)</div>
            <div class="bnkData">₹ {{ _.get(payoutDetails, 'totalAmount', "") |number}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="matched">
          <mat-card-content>
            <div class="bnkDetail">Matched Suppliers</div>
            <div class="bnkData"> {{ _.get(payoutDetails, 'matchedPayees', "")}} / {{ _.get(payoutDetails, 'totalPayees', "")}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="notMatched">
          <mat-card-content>
            <div class="bnkDetail">Not Matched Suppliers</div>
            <div class="bnkData">{{ _.get(payoutDetails, 'notMatchedPayees', "")}}/{{ _.get(payoutDetails, 'totalPayees', "")}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="accept">
          <mat-card-content>
            <div class="bnkDetail">Accepted Entries</div>
            <div class="bnkData"> {{ _.get(payoutDetails, 'acceptedEntries', "")}} / {{ _.get(payoutDetails, 'totalEntries', "")}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="reject">
          <mat-card-content>
            <div class="bnkDetail">Rejected Entries</div>
            <div class="bnkData"> {{ _.get(payoutDetails, 'rejectedEntries', "") }}/{{ _.get(payoutDetails, 'totalEntries', "")}}</div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
    </div>

    <div class="below-tabs">
      <mat-tab-group  class="tab-style-1" (selectedIndexChange)="selectTabIndex($event)" #tabs>
        <mat-tab label="Invoices">
          <ng-template mat-tab-label>
            <!-- <i class="ri-error-warning-fill warning-icon"></i> -->
            Invoices
          </ng-template>

          <app-invoice-reviewpayment *ngIf="tabIndex==0"></app-invoice-reviewpayment>
        </mat-tab>
        <mat-tab label="Suppliers"> 
          <ng-template mat-tab-label>
            <!-- <i class="ri-checkbox-circle-fill checked-icon"></i> -->
            Suppliers
          </ng-template>
          <app-supplier-reviewpayment *ngIf="tabIndex==1"></app-supplier-reviewpayment>
           </mat-tab>
           <mat-tab label="Payments" *ngIf="isPaymentTabIndex"> 
            <ng-template mat-tab-label>
              <!-- <i class="ri-time-fill timer-icon"></i> -->
              Payments
            </ng-template>
            <!-- <mat-tab label="Payments">  -->
            <app-payments-review *ngIf="tabIndex==2"></app-payments-review>
             </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>