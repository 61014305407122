import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'app-request-finance-pdf',
  templateUrl: './request-finance-pdf.component.html',
  styleUrls: ['./request-finance-pdf.component.scss']
})
export class RequestFinancePdfComponent implements OnInit {

  @ViewChild('pdfview') pdfview: ElementRef;
  invId: string;
  pdfContent: any;
  invIds: any = [];

  constructor(public dialogRef: MatDialogRef<RequestFinancePdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pageSerive: PagesService, private toastService: ToastrService) {
      if(!data['multiple']){
        this.invId = data['id'];
        this.pdfContent = data['view'];
      }
      else{
        this.invIds = data['id'];
        this.pdfContent = data['view'];
      }
     }

  ngOnInit() {
    this.pdfview.nativeElement.setAttribute('src', this.pdfContent + '#toolbar=0&navpanes=0&scrollbar=0&view=FitH&zoom=150');
  }

  loanRequest(){
    let obj = {};
    if(!this.data['multiple']){
      obj['id'] = this.invId;
      this.pageSerive.loanRequest(obj).subscribe(res=>{
      if(res['status']){
        this.toastService.success("Requested Successfully");
        this.dialogRef.close(false);
      }
    })
    }
    else{
      obj['invoiceIds'] = this.invIds;
      this.pageSerive.multiLoanRequest(obj).subscribe(res=>{
        if(res['status']){
          this.toastService.success("Requested Successfully");
          this.dialogRef.close(true);
        }
      })
    }
}

}
