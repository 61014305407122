<div class="popup-container">
  <div class="popup-content">
    <h2>Enter Remarks</h2>
    <textarea placeholder="Enter your remarks" [(ngModel)]="remark"></textarea>
    <div class="popup-buttons">
      <button class="cancel-button" (click)="closedialog()">Cancel</button>
      <button class="sure-button" (click)="RejectInvoice()">Reject</button>
    </div>
  </div>
</div>
