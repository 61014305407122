<div class="scroeboardWrap">
  <div class="head-box">
    <div class="main-head d-flex align-items-center js-between">
      <div class="left-head w-50">
        <h2>Buyers Scorecard List</h2>
      </div>
      <div class="right-head w-50 d-flex align-items-center justify-content-end">
        <p><em>*</em> Supplier Threshold : 25, Buyers Threshold : 42, Trade Relation Threshold : 42</p>
        <button class="closebtn" (click)="closeDialog()">Close</button>
      </div>
    </div>

    <div class="sub-head d-flex align-items-center js-between">
      <div class="left-subhead w-45">
        <h2>Supplier Data</h2>
      </div>

      <div class="right-subhead w-55 d-flex align-items-center justify-content-between">

        <div class="left-inner">
          <h2>Current Score: - <span [ngClass]="supplierCount > 25 ? 'green' : 'red'"> {{supplierCount}} </span></h2>
          <p><em>*</em> Supplier Threshold : 25, Max Score : 50 </p>
        </div>

        <div class="right-inner">
          <button class="calcScoreBtn" [disabled]="isEditable" (click)="calculateSupplierScore()">Calculate Score</button>
          <button class="saveBtn" [disabled]="isEditable" (click)="saveSupplierData()">Save</button>
        </div>
      </div>
    </div> 

    <div class="inner-wrap">
      <div class="formbox">
        <form [formGroup]="supplierForm">
          <div class="heading-with-line">
            <span> Financials </span>
          </div>
          <div class="d-flex al-center flex-wrap">

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Turnover<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="turnOver" [compareWith]="displayValueCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.financials.turnoverInCr"
                      [value]="item">{{item.displayValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Debt / EBITDA<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="debt" [compareWith]="displayValueCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.financials.debt"
                      [value]="item">{{item.displayValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>PAT %<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="pat" [compareWith]="displayValueCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.financials.pat"
                      [value]="item">{{item.displayValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Mkt. Cap<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="marketCap" [compareWith]="statusCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.financials.marketCap"
                      [value]="item">{{item.status | titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Networth<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="netWorth" [compareWith]="displayValueCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.financials.networth"
                      [value]="item">{{item.displayValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>

          <div class="d-flex al-center flex-wrap">

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Industry<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="industry" [compareWith]="nameCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.industry"
                      [value]="item" title="{{item.name}}">{{item.name | titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Restructing / NCLT<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="restructring" [compareWith]="statusCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.restructing"
                      [value]="item">{{item.status}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>

          <div class="heading-with-line">
            <span> Management Background </span>
          </div>

          <div class="d-flex al-center flex-wrap">


            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Vintage of Supplier<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="vintage" [compareWith]="displayValueCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.managementBackground.vintage"
                      [value]="item">{{item.displayValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Entity Type<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="entityType" [compareWith]="nameCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.managementBackground.entityType"
                      [value]="item">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Successsion Planning<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="relevance" [compareWith]="statusCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.managementBackground.relevanceManagement"
                      [value]="item">{{item.status}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="w-19 mb-10">
              <div class="input-group">
                <mat-label>Ownership<em>*</em></mat-label>
                <mat-form-field class="mat-select" appearance="outline">
                  <mat-select placeholder="Please Select" formControlName="sophastication" [compareWith]="statusCompare">
                    <mat-option class="mat-option" *ngFor="let item of data.supplierArrayList.managementBackground.manufacturingSophistication" [value]="item"
                      >{{item.status}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

      </div>
      <div class="main-head dark-bg">
        <div class="left-head">
          <h2>Buyers List</h2>
        </div>
      </div>
      <div class="buyerlistWrap">
        <form [formGroup]="buyerDetailsForm">
          <div class="buyerListInnerWrap" formArrayName="details"
            *ngFor="let item of buyerDetailsForm.get('details')?.controls; let ind = index;">
            <div [formGroupName]="ind">
              <mat-accordion class="icon-accordion">
                <mat-expansion-panel [expanded]="panelOpenState">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="d-flex alignDiv-item score-head w-100">
                        <div class="w-49 left-sec">
                          <h2>{{item.get('buyerName')?.value}}</h2>
                        </div>

                        <div class="w-49 right-sec">
                          <h2>Final Score: - <span [ngClass]="item.get('count')?.value > 42 ? 'green' : 'red'"> {{item.get('finalCount')?.value ? item.get('finalCount')?.value : '0'}} </span></h2>
                          <p><em>*</em> Buyers Score : {{item.get('count')?.value ? item.get('count')?.value : '0'}},<span [ngClass]="item.get('tradeRelationScore')?.value > 15 ? 'green' : 'red'"> Trade Relation Score: {{item.get('tradeRelationScore')?.value ? item.get('tradeRelationScore')?.value : '0'}} </span>, <span [ngClass]="supplierCount > 15 ? 'green' : 'red'">Supplier Score : {{supplierCount}}</span> </p>
                        </div>
                        <div class="buyer-action">
                          <button class="calcScoreBtn" [disabled]="isEditable" (click)="calculateBuyerScore(ind,$event)">Calculate Score</button>
                          <button class="saveBtn" [disabled]="isEditable" (click)="saveBuyerDetails(ind, $event)">Save</button>
                        </div>
                      </div>
                    </mat-panel-title>

                  </mat-expansion-panel-header>

                  <div class="sub-head border-bottom">
                    <div class="left-subhead">
                      <h2>Buyers Data</h2>
                    </div>
                  </div>
                  <div class="contact-section">
                    <div class="contact-row d-flex al-center">

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Geography<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="buyerGeography" [compareWith]="nameCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.geo" [value]="item"
                                >{{item.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Employee Strength<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="employeeStrength" [compareWith]="displayValueCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.employees" [value]="item"
                                >{{item.displayValue}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Vintage (Commercial perations)<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="vintage" [compareWith]="displayValueCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.vintage" [value]="item"
                                >{{item.displayValue}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Financials<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="financials" [compareWith]="statusCompare" (selectionChange)="financialChange($event.value, ind)">
                              <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.financials" [value]="item"
                                selected>{{item.status | titlecase}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="heading-with-line px-10 bg-blue" *ngIf="item.get('financials')?.value && item.get('financials')?.value['status'] == 'available'">
                    <span> Financials </span>
                  </div>
                  <div class="d-flex al-center flex-wrap px-10" *ngIf="item.get('financials')?.value && item.get('financials')?.value['status'] == 'available'">

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Turnover<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="turnOver" [compareWith]="displayValueCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.financialDetails.turnoverInMillion" [value]="item"
                              >{{item.displayValue}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Debt / EBITDA<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="debt" [compareWith]="displayValueCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.financialDetails.debt" [value]="item"
                              >{{item.displayValue}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>PAT %<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="pat" [compareWith]="displayValueCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.financialDetails.pat" [value]="item"
                              >{{item.displayValue}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Mkt. Cap<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="marketCap" [compareWith]="statusCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.financialDetails.marketCap" [value]="item"
                              >{{item.status | titlecase}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                  </div>


                  <div class="d-flex al-center flex-wrap px-10">

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Industry Margins<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="margin" [compareWith]="statusCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.industry.margin" [value]="item"
                              >{{item.status}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Industry Sector<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="sector" [compareWith]="nameCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.industry.sector" [value]="item"
                              >{{item.name | titlecase}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>


                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Bank Limits<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="bankLimits" [compareWith]="displayValueCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.bankLimitsInUSD" [value]="item"
                              >{{item.displayValue}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Management Background<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="managementBackground" [compareWith]="statusCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.management"
                              [value]="item" selected>{{item.status}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex al-center flex-wrap px-10">
                    <div class="w-19 mb-10">
                      <div class="input-group">
                        <mat-label>Insurer Name/ Rating<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="insurerRating" [compareWith]="nameCompare" (selectionChange)="changeIndustry($event.value, ind)">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.insurerRating" [value]="item"
                              >{{item.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>


                    <div class="w-19 mb-10" *ngIf="item.get('insurerRating')?.value && item.get('insurerRating')?.value['name'] == 'COFACE'">
                      <div class="input-group">
                        <mat-label>Coface<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="coface" [compareWith]="nameCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.cofaceDetails" [value]="item"
                              >{{item.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="w-19 mb-10" *ngIf="item.get('insurerRating')?.value && item.get('insurerRating')?.value['name'] == 'ALLIANZ'">
                      <div class="input-group">
                        <mat-label>Allianz<em>*</em></mat-label>
                        <mat-form-field class="mat-select" appearance="outline">
                          <mat-select placeholder="Please Select" formControlName="allianz" [compareWith]="nameCompare">
                            <mat-option class="mat-option" *ngFor="let item of data.buyerSettings.allianzDetails" [value]="item"
                              >{{item.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="sub-head border-bottom">
                    <div class="left-subhead">
                      <h2>Trade Relations</h2>
                    </div>
                  </div>

                  <div class="contact-section">
                    <div class="contact-row d-flex al-center">

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Vintage<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="vintageTrading" [compareWith]="displayValueCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.vintage" [value]="item">{{item.displayValue}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Past Dilution<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="pastDilution" [compareWith]="statusCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.pastDilution" [value]="item">{{item.status}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Dependency(as a % of TOI)<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="dependency" [compareWith]="displayValueCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.dependency" [value]="item"
                                >{{item.displayValue}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Sales Contract > 1 yr<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="salesContract" [compareWith]="statusCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.salesContractGreaterThanYear" [value]="item"
                                >{{item.status}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>

                    <div class="contact-row d-flex al-center">

                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Incoterm<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="incoterm" [compareWith]="nameCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.incoterm" [value]="item"
                                >{{item.name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="w-19 mb-10">
                        <div class="input-group">
                          <mat-label>Overdues<em>*</em></mat-label>
                          <mat-form-field class="mat-select" appearance="outline">
                            <mat-select placeholder="Please Select" formControlName="overDues" [compareWith]="displayValueCompare">
                              <mat-option class="mat-option" *ngFor="let item of data.tradeRelationSettings.overdues" [value]="item"
                                >{{item.displayValue}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>