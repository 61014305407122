import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSelect, MatOption } from '@angular/material';
import * as moment from "moment";
import { SettingService } from 'src/app/settings/setting.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as lodash from 'lodash';
import { PagesService } from 'src/app/pages/pages.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { RejectEscrowComponent } from '../reject-escrow/reject-escrow.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { saveAs } from 'file-saver';
import { SidService } from '../../sid/services/sid.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-escrow-payment',
  templateUrl: './escrow-payment.component.html',
  styleUrls: ['./escrow-payment.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class EscrowPaymentComponent implements OnInit {
  _: any = lodash;
  columnsToDisplay = ['remitterName', 'virtualAccountNumber', 'utr', 'date', 'transactionAmount', 'downloadFile', 'status'];
  columnsToDisplay2 = ['remitterName', 'supplierName', 'virtualAccountNumber', 'utr', 'senderIfsc', 'date', 'transactionAmount', 'downloadFile', 'actions']
  expandedColumnDisplay = ['invoiceNumber', 'status', 'image', 'suppID', 'isEPRequested', 'invoiceAmt', 'deductions', 'amountPaid', 'dueToSupplier', 'dueToLender',
    'dueToCredlix', 'epAmount', 'lenderName', 'paymentType', 'amountReported', 'amountInEscrow',
    'escrowUTR', 'payToLender', 'payToSupplier', 'payToCredlix'];
  dataSource = new MatTableDataSource();
  expandedDataSource = [];

  isFiltersShow: boolean = true;
  pageSize: number = 10;
  totalRecords: any;
  pageNo: number = 0;
  virtaulAccountNumber: string;
  UTRNumber: string;
  fromDate: string;
  toDate: string;
  isEPBreadcrumVisible: boolean = false;
  setfilterValue: any;
  isClearButtonEnable: boolean = false;
  isApplyButtonEnable: boolean = false;
  startDate: any;
  endDate: any;
  remitterName: string;
  supplierList: any = [];
  supplierIds = [];
  @ViewChild('matRef') matRef: MatSelect;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  expandedElement: any;
  constructor(private settingservice: SettingService, private pageService: PagesService, public router: Router,
    public authService: AuthService, public toaster: ToastrService, public dialog: MatDialog, public sidService: SidService, public pagesService: PagesService, public dataService: DataService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    const today = new Date();
  }

  ngOnInit() {
    this.settingservice.escrowFilter$.subscribe((res: any) => {
      if (res) {
        this.UTRNumber = res;
        this.getEscrowPayment(true);
      }
    })
    this.getEscrowPaymentData();
    if (!this.authService.isBorrower()) {
      this.getSupplierList();
    }
  }
  getSupplierList() {
    let reqObj = {}
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    reqObj['indexName'] = "credlix_sid_suppliers";
    this.sidService.supplierSearch(reqObj).subscribe(res => {
      this.supplierList = res['result'];
    })
  }
  getEscrowPaymentData() {
    if (this.authService.isSIDRoleType() && !this.authService.isBorrower()) {
      this.pageService.isChangeColor("white_doc_et");
      this.getEscrowPayment(true);
    }
    else {
      this.getSupplierEscrowPayment(true);
    }
  }

  uploadFile(event: any, data: any) {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      console.log('filename :', file.name);
      const uploadData = new FormData();
      uploadData.append('file', file);
      this.settingservice.uploadSupplierPaymentDocs(uploadData, data['utr']).subscribe(file => {
        if (file['result']) {
          this.toaster.success("File is Uploaded successfully");
          this.getEscrowPaymentData();
        }
        else {
          this.toaster.error(file['message']);
        }
      });
    }
  }

  getEscrowPayment(value) {
    let reqObj = {}
    if (this.paginator != null && value == false) {
      this.paginator.firstPage();
    }
    reqObj['from'] = this.paginator.pageIndex * this.pageSize;
    reqObj['size'] = this.pageSize;
    reqObj['where'] = {}
    if (this.virtaulAccountNumber) {
      reqObj['where']['virtualAccountNumber'] = {
        "type": "search",
        "value": this.virtaulAccountNumber.trim()
      }
    }
    if (this.UTRNumber) {
      reqObj['where']['utr'] = {
        "type": "search",
        "value": this.UTRNumber.trim()
      }
    }
    if (this.remitterName) {
      let remName = this.remitterName.trim()
      reqObj['where']['remitterName'] = {
        "type": "search",
        "value": remName.toUpperCase()
      }
    }
    if ((this.startDate != null && this.startDate != '') && (this.endDate != null && this.endDate != '')) {
      reqObj['where']['transactionDate'] = {
        "type": "range",
        "start_value": this.startDate,
        "end_value": this.endDate
      }
    }
    if (this.supplierIds && this.supplierIds.length > 0) {
      reqObj['where']['borrowerBusinessAccount'] = {
        "type": "search_in",
        "value": this.supplierIds
      }
    }
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    this.settingservice.getEscrowPayments(reqObj).subscribe((response: any) => {
      this.dataSource.data = response['result'];
      this.totalRecords = response['total'];
    })

  }

  getSupplierEscrowPayment(value) {
    let reqObj = {}
    if (this.paginator != null && value == false) {
      this.paginator.firstPage();
    }
    reqObj['from'] = this.paginator.pageIndex * this.pageSize
    reqObj['size'] = value.pageSize ? value.pageSize : this.pageSize;
    reqObj['where'] = {}
    if (this.virtaulAccountNumber) {
      reqObj['where']['virtualAccountNumber'] = {
        "type": "search",
        "value": this.virtaulAccountNumber.trim()
      }
    }
    if (this.UTRNumber) {
      reqObj['where']['utr'] = {
        "type": "search",
        "value": this.UTRNumber.trim()
      }
    }
    if ((this.startDate != null && this.startDate != '') && (this.endDate != null && this.endDate != '')) {
      reqObj['where']['transactionDate'] = {
        "type": "range",
        "start_value": this.startDate,
        "end_value": this.endDate
      }
    }
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    this.settingservice.getSupplierEscrowPayments(reqObj).subscribe((response: any) => {
      this.dataSource.data = response['result'];
      this.totalRecords = response['total'];
    })
  }

  getClass(val) {
    if (val == 'APPROVED') {
      return 'approve'
    }
    if (val == 'PENDING') {
      return 'ending'
    }
    if (val == 'REJECTED') {
      return 'reject'
    }
  }

  getStatus(val) {
    if (val == 'APPROVED') {
      return 'Settled'
    }
    if (val == 'PENDING') {
      return 'Pending'
    }
    if (val == 'REJECTED') {
      return 'Unsettled'
    }
  }

  openTransactionHistory(item: any) {
    const dialogRef = this.dialog.open(TransactionHistoryComponent, {
      width: '700px',
      height: '340px',
      data: item,
    });
  }
  rejectPayment(rowData: any) {
    console.log(rowData);
    const dialogRef = this.dialog.open(RejectEscrowComponent, {
      width: '500px',
      height: '350px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.remark) {
        this.settingservice.rejectPayment(rowData.utr, result.remark).subscribe((response: any) => {
          if (response.result) {
            this.toaster.success("Rejected Successfully");
            this.getEscrowPaymentData();
          }
          else {
            this.toaster.success(response.message);
          }
        });
      }
    });
  }

  acceptPayment(rowData: any) {
    console.log(rowData);
    this.settingservice.approvePayment(rowData.utr).subscribe((response: any) => {
      if (response.result) {
        this.toaster.success("Approved Successfully");
        this.getEscrowPaymentData();
      }
    })
  }

  onRowClick(element) {
    if (!this.authService.isBorrower()) {
      if (this.expandedElement == null || this.expandedElement != element) {
        this.settingservice.getEscrowDetailByID(element.id).subscribe(res => {
          this.expandedDataSource = res['result'];
          console.log("this.e", this.expandedDataSource['fileEntries'], this.expandedDataSource['fileEntries'].length)
        })
      }
      this.expandedElement = this.expandedElement === element ? null : element
    }
    this.expandedElement = this.expandedElement === element ? null : element

  }
  formatPaymentType(value) {
    return this._.startCase(value);
  }

  getPaginatorData1(event) {
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    this.getEscrowPayment(true);
  }
  changeFromDate(event) {
    let Start = event.value
    this.startDate = moment(Start).format('YYYY-MM-DD');
  }

  changeToDate(event) {
    let endValue = event.value;
    this.endDate = moment(endValue).format('YYYY-MM-DD');
  }

  resetTransactionFilters() {
    this.virtaulAccountNumber = null;
    this.UTRNumber = null;
    this.remitterName = null;
    this.startDate = null;
    this.endDate = null;
    this.supplierIds = [];
    if (this.matRef) {
      this.matRef.options.forEach((data: MatOption) => data.deselect());
    }
    this.getEscrowPayment(false);
  }

  isButtonEnable() {
    if (!this.virtaulAccountNumber) {
      return true;
    }
    else if (!this.UTRNumber) {
      return true;
    }
    else {
      return false;
    }
  }
  getFormattedDate(date) {
    return moment(date).format('YYYY/MM/DD');

  }

  downloadfile(rowData: any) {
    this.pageService.downloadEscrowDocs(rowData.utr).subscribe(res => {
      let blob = new Blob([res.body], {
        type: res.headers.get("Content-Type")
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileExtensionSplit = res.headers.get("Content-Disposition").split(".");
      const fileExtension = fileExtensionSplit[fileExtensionSplit.length - 1];
      link.download = rowData.utr + "." + fileExtension.replace("\"", "");
      link.click();
      window.URL.revokeObjectURL(link.href);
    });
  }
  downloadEP() {
    let reqObj = {}
    reqObj['where'] = {}
    if (this.virtaulAccountNumber) {
      reqObj['where']['virtualAccountNumber'] = {
        "type": "search",
        "value": this.virtaulAccountNumber.trim()
      }
    }
    if (this.UTRNumber) {
      reqObj['where']['utr'] = {
        "type": "search",
        "value": this.UTRNumber.trim()
      }
    }
    if (this.remitterName) {
      let remName = this.remitterName.trim()
      reqObj['where']['remitterName'] = {
        "type": "search",
        "value": remName.toUpperCase()
      }
    }
    if ((this.startDate != null && this.startDate != '') && (this.endDate != null && this.endDate != '')) {
      reqObj['where']['transactionDate'] = {
        "type": "range",
        "start_value": this.startDate,
        "end_value": this.endDate
      }
    }
    if (this.supplierIds && this.supplierIds.length > 0) {
      reqObj['where']['borrowerBusinessAccount'] = {
        "type": "search_in",
        "value": this.supplierIds
      }
    }
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    this.settingservice.downloadEPReport(reqObj).subscribe(res => {
      this.getFile(res);
      this.toaster.success("Downloaded Successfully");
    })
  }
  getFile(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
  onSupplierChange(value) {
    this.supplierIds = value;
  }
  ngOnDestroy() {
    this.settingservice.setEscrowFilter('');  // to clear escrowFilter$ data
  }
  setDateTime(element){
    let time = new Date(element.paymentAdviceUploadDate);
    let properDate = moment(time).format("dddd, MMMM Do YYYY, h:mm:ss a");
    return 'Uploded : ' +  properDate;
  }
}

