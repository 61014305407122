<div class="pdf-container" *ngIf="validTermsheet">
  <div>
    <iframe class="pdf-iframe" [src]="pdfUrl"></iframe>
  </div>
  <div *ngIf="source =='whatsapp' && isVisible" class="button-containerTermsheet">
    <button *ngIf="true" (click)="onCancel()">Cancel</button>
    <button (click)="onAccept()" [ngClass]="{'disabled':disableAcceptBtn}" [disabled]="disableAcceptBtn">Accept</button>
    <span *ngIf="disableAcceptBtn" class="warningMsg">
       Termsheet is already accepted
    </span>
  </div>
</div>
