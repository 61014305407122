import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-insurance-data-list',
  templateUrl: './insurance-data-list.component.html',
  styleUrls: ['./insurance-data-list.component.scss']
})
export class InsuranceDataListComponent implements OnInit {
  insurancedata = [];
  displayedColumn = ['buyer','insurer','status','rating','appliedAmount','approvedAmount','buyerAddress','insuranceApprovedDate','comment'];
  allInsuranceInfo: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
     public dialogRef: MatDialogRef<InsuranceDataListComponent>
     ) { }

  ngOnInit() {
    this.insurancedata = this.data.obj
    this.allInsuranceInfo = [];

    // Iterate through the insurancedata array
    for (const buyer of this.insurancedata) {
      // Check if the buyer has insuranceInfo
      if (buyer.insuranceInfo && Array.isArray(buyer.insuranceInfo)) {
        // Push each insurance object to the allInsuranceInfo array
        for (const insurance of buyer.insuranceInfo) {
          insurance.buyerName = buyer.buyerName;
          if(insurance.insuranceDataPresent == true){
            this.allInsuranceInfo.push(insurance);
          }
        }
      }
    }
  }
}
