import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { params } from 'src/app/param';
import { SettingService } from 'src/app/settings/setting.service';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'app-commission-invoices-details',
  templateUrl: './commission-invoices-details.component.html',
  styleUrls: ['./commission-invoices-details.component.scss']
})
export class CommissionInvoicesDetailsComponent implements OnInit {

  pageSize: number = 50;
  totalRecords: any;
  pageNo: number = 0;
  commissionId :any;
  data: any;

  // displayedColumns = ['name','commission','actualDiscount','amount','amountRequested','amountRequestedPercent','createdAt','balanceDueDate'];
  // dataSource = new MatTableDataSource();
  

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private settingservice:SettingService,private pageService:PagesService,private activatedRoute : ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this.pageService.isChangeColor("white_doc_ci");

    this.activatedRoute.params.subscribe(params => {
      console.log(params);
      this.commissionId = params['id'];
    })

  this.getCiData();

   
  }

  ciNavigation() {
    this.router.navigate(['/commission-invoices']);
  }
 

  getPaginatorData2(event){
    this.getCiData(); 
  }


  getCiData() {
    let obj = {};
    obj['from'] = this.paginator.pageIndex;
    obj['size'] = this.pageSize
    obj['id']=this.commissionId
    this.pageNo = this.paginator.pageIndex;
    
    

    this.settingservice.getCiDetails(obj).subscribe((response:any) =>{      
      this.data = response['result'];
      this.totalRecords = response['total'];
      console.log('response is',this.data);
      
    })
      
  }

}
