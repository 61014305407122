<div class="addSupp">
  <div class="header">
    <h5>Download Commission Invoice</h5>
    <span class="material-icons cursor-pointer" (click)="dialogRef.close()"> close </span>
  </div>
  <div class="fac-radio">
    <mat-radio-group aria-label="Select an option" class="radio-align" (change)="selectDownloadType($event.value)" [(ngModel)]="downloadType">
      <mat-radio-button value="ALL">ALL</mat-radio-button>
      <mat-radio-button value="SUPPLIER">Supplier</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="inputCta">
    <mat-form-field appearance="outline" *ngIf="isHidden">
      <mat-label>Supplier ID</mat-label>
      <input type="number" matInput [(ngModel)]="supplierID">
    </mat-form-field>
    <div class="secnd" >
    <div class="qwerty">
      <span class="lbl">From</span>
      <mat-form-field appearance="fill" class="date-picker-field mt-2" [ngClass]="isStartDate == true ? 'dateError' : ''">
        <input matInput [max]="this.endDate" [matDatepicker]="picker" placeholder="Select Date"
          (dateChange)="changeFromDate($event)" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="qwerty">
      <span class="lbl mb-3">To</span>
      <mat-form-field appearance="fill" class="date-picker-field mt-2" [ngClass]="isEndDate == true ? 'dateError' : ''">
        <input matInput [min]="this.startDate" [matDatepicker]="picker1" placeholder="Select Date"
          (dateChange)="changeToDate($event)" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    </div>
    <div class="buttonAlign">
      <button *ngIf="!isHidden" class="download-button" mat-raised-button (click)="addSuppliers()">Download</button>
      <button *ngIf="isHidden" class="download-button" mat-raised-button (click)="addSuppliers()" [disabled]="supplierID == null">Download</button>
    </div>
  </div>
</div>

