<div class="suplr-details">
  <h5 *ngIf="bulkAction =='rate'">Change Rate</h5>
  <h5 *ngIf="bulkAction =='eligible'">Change Eligible</h5>
  <h5 *ngIf="bulkAction =='limit'">Change Limit</h5>
  <h5 *ngIf="bulkAction =='maxDiscount'">Change Max and Min Discount Days </h5>
  <h5 *ngIf="bulkAction =='approval'">Approvals</h5>
  <h5 *ngIf="bulkAction =='financer'">Financer</h5>

  <form [formGroup]="bulkkSupplierDetailsForm" (ngSubmit)="editSupplierDetails()">
    <div class="form-details">
      <div *ngIf="bulkAction =='rate'">
        <mat-form-field appearance="outline">
          <mat-label>Rate%</mat-label>
          <input type="number" matInput formControlName="rate" appNumbervalidation>
          <mat-error *ngIf="bulkkSupplierDetailsForm.controls.rate.errors?.required">This is required.</mat-error>
        </mat-form-field>
        <span>%</span>
      </div>
      <div *ngIf="bulkAction =='eligible'">
        <mat-form-field appearance="outline">
          <mat-label>Eligible%</mat-label>
          <input type="number" matInput formControlName="eligiblePercentage" appNumbervalidation>
          <mat-error *ngIf="bulkkSupplierDetailsForm.controls.eligiblePercentage.errors?.required">This is required.
          </mat-error>

        </mat-form-field>
        <span>%</span>
      </div>
      <div *ngIf="bulkAction =='limit'">
        <mat-form-field appearance="outline">
          <mat-label>Limit</mat-label>
          <input type="number" matInput formControlName="limit" appNumbervalidation>
        </mat-form-field>
        <span>(INR)</span>
      </div>
      <mat-form-field appearance="outline" *ngIf="bulkAction =='maxDiscount'">
        <mat-label>Change Max Discount Days</mat-label>
        <input type="number" matInput formControlName="maxDaysDiscount" (keypress)="numberOnly($event)">
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="bulkAction =='maxDiscount'">
        <mat-label>Change Min Discount Days</mat-label>
        <input type="number" matInput formControlName="minDaysDiscount" (keypress)="numberOnly($event)">
        <mat-error *ngIf="bulkkSupplierDetailsForm.controls.minDaysDiscount.errors?.required">This is required.
        </mat-error>
      </mat-form-field>
      <div class="apprSection" *ngIf="bulkAction =='approval'">
        <div class="form-check" *ngFor="let apprName of approvalListArray">
          {{isChecked}}
          <input class="form-check-input" name="{{apprName.approvalName}}" type="checkbox" [(ngModel)]="apprName.isChecked"
            id="{{apprName.approvalName}}" (change)="getSelectedApproval()" formControlName="approval">
          <label class="form-check-label" for="{{apprName.approvalName}}">
            {{apprName.approvalName}}
          </label>
        </div>
      </div>
      <div class="FinanSection" *ngIf="bulkAction =='financer'"> 
        <div class="fieldValue">
          <mat-form-field appearance="fill" class="selectearlypaystatus finan">
            <mat-label>Choose Financer</mat-label>
            <mat-select (selectionChange)="OnselectFinancer($event.value,financerArray )" formControlName="ischeckFinanceName">
              <mat-option *ngFor="let type of financerArray" [value]="type.name">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="bulkkSupplierDetailsForm.controls.ischeckFinanceName.errors?.required">This is required.</mat-error>

          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="financerType!=null" class="credlixinput">
            <mat-label>Credlix Fee</mat-label>
            <input type="number" matInput [(ngModel)]="credlixFee" 
            *ngIf="(ischeckFinanceName =='Aditya Birla Finance Limited' || ischeckFinanceName== 'Cholamandalam Investment and Finance Company')" formControlName="credlixFee">
            <input matInput [(ngModel)]="credlixMoglixFee" *ngIf="ischeckFinanceName =='Moglix'" formControlName="credlixFee" readonly>
            <mat-error *ngIf="bulkkSupplierDetailsForm.controls.credlixFee.errors?.required">This is required.</mat-error>

          </mat-form-field>
          <span *ngIf="ischeckFinanceName!=null && (ischeckFinanceName =='Aditya Birla Finance Limited' || ischeckFinanceName== 'Cholamandalam Investment and Finance Company')">%</span>
        </div>
      </div>

    </div>
    
    <div class="actionbtn">
      <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
      <button mat-raised-button class="savebtn" >Save</button>
    </div>
  </form>

  
</div>