<div class="historyHeader" *ngIf="!preQualifiedData.preQualified">
  <i class="ri-close-line closeistoryButton" (click)="closeHistorytab()"></i>
  <div *ngIf="totalRecords > 0 " class="sb_table table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- tag Column -->
      <ng-container matColumnDef="laneTaskId">
        <th mat-header-cell *matHeaderCellDef> Field
          <!-- <button (click)="openDialog()" class="fiter-btn"><i class="ri-filter-2-fill"></i></button> -->
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{getHistoryField(element.oldValue)}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef> Operation </th>
        <td mat-cell *matCellDef="let element">
          <span class="table-title">{{toLowerCase(element.operation)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="oldValue">
        <th mat-header-cell *matHeaderCellDef> Old Value</th>
        <td mat-cell *matCellDef="let element">
          <span class="table-title">{{getHistoryValue(element.oldValue)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedValue">
        <th mat-header-cell *matHeaderCellDef> Updated Value</th>
        <td mat-cell *matCellDef="let element">
          <span class="table-title">{{getHistoryValue(element.updatedValue)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef> Remarks </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{element.field}}</p>
          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="when">
        <th mat-header-cell *matHeaderCellDef> When </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{element.time}}</p>
          </div>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="{ 'active-row': i === selectedTrIndex }"></tr>
    </table>
    <div class="pagination" fxLayout="row" fxLayoutAlign="end center" style="width: 100%;">
      <div class="d-flex-rw" style="width: 100%;">
        <div class="pagination-wrapper" style="width: 100%;">
          <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            class="custom-pagintor" [pageSize]="pageSize" (page)="getMoreHistory($event)" showFirstLastButtons>
            <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="historyHeader" *ngIf="preQualifiedData.preQualified">
  <!-- <h2>History</h2> -->
  <i class="ri-close-line closeistoryButton" (click)="closeHistorytab()"></i>
  <div *ngIf="preQualifiedHistory?.length > 0 " class="sb_table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> History
        </th>
        <td mat-cell *matCellDef="let element"><span class="history-status">{{element.title}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date
        </th>
        <td mat-cell *matCellDef="let element">{{element.date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef> Comment
        </th>
        <td mat-cell *matCellDef="let element"> <span *ngFor="let com of element.comments">{{com}}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
    </table>
  </div>
</div>