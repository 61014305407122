import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { PaymentVerificationDialogComponent } from 'src/app/component/escrow-account/create-payout/payout-creation/review-payout/payment-verification-dialog/payment-verification-dialog.component';
import { PagesService } from 'src/app/pages/pages.service';
import { SettingService } from 'src/app/settings/setting.service';
import { AddWhatsappcontactComponent } from './add-whatsappcontact/add-whatsappcontact.component';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { OtpLoaderComponent } from 'src/app/component/escrow-account/create-payout/payout-creation/review-payout/otp-loader/otp-loader.component';
import { ConfirmationModelComponent } from 'src/app/shared-files/shared-component/confirmation-model/confirmation-model.component';
import { importConfig } from '../../../../constants/import-config-data'
@Component({
  selector: 'app-supplier-profile',
  templateUrl: './supplier-profile.component.html',
  styleUrls: ['./supplier-profile.component.scss']
})
export class SupplierProfileComponent implements OnInit {

  // tabName: string = "Profile & Metrics";
  tabName: string = "Basic Details";
  // historyTabs = ['Profile & Metrics' ,'Discount Settings', 'Bank Account', 'Transactions'];
  historyTabs = ['Basic Details' ,'Discount Settings', 'Bank Account', 'Transactions'];
  contactList = [];
  elementDetails: any;
  selectedMetricsValue= '-1';
  metricsDetails: any;
  metricsValue: any;
  metricsRes:any;
  bankDetailsForm: FormGroup;
  bankDetailsArr = [];
  isBankEditable: boolean = false;
  saveBtnLabel: string = "Save";
  supplierDetailsForm: FormGroup;
  supplierDetails: any;
  checkedSupplierName: string
  ischecked: boolean;
  apprObj = {}
  approval = [];
  credlixFee: any;
  financerobj = {};
  financerType: string;
  credlixMoglixFee: number = 0;
  isABFL: boolean = true;
  isMOGLIX: boolean = false;
  ischeckFinanceName: string;
  financerArray = [];
  onBoardToggle: any;
  financerID: any;
  financeSelectType: string;
  approvalListArray: any = [
    { id: '1', approvalName: 'Supply Approval', field: 'supplyApproval', isChecked: false },
    { id: '2', approvalName: 'Finance Approval', field: 'financeApproval', isChecked: false },
  ];
  metricLevelArray:any=[
    { id: '1', name: 'All time', field: '-1' },
    { id: '2', name: '30 Days', field: '30' },
    { id: '3', name: '90 Days', field: '90' }
  ]
  profileSaveBtn: string = "Edit";
  bankName: string = "";
  transactionsArr = [];
  displayedColumns = [];
  dataSource = new MatTableDataSource(this.transactionsArr);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: any = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  displayedColumns1 = [];
  arrConfig = [{ 'field': 'transactionType', 'key': 'Transaction Type' }, { 'field': 'paymentStatus', 'key': 'Status' },
  { 'field': 'utr', 'key': 'UTR' }, { 'field': 'amount', 'key': 'Amount' }, { 'field': 'paymentDate', 'key': 'Payment Date' }];
  isPrimaryAcc: boolean = true;
  bankDetails: any;
  addNewAccount: boolean = false;
  isLoading: boolean = false;
  bankTab: boolean = false;
  transactionTab: boolean = false;
  supplierID: string;
  anchorDisplayName: string;
  supplierIDLabel = "Supplier";
  lenderName: string;
  lenderId: string;
  lenderDetailsForm: FormGroup;
  lenderConfig: any=[]

  constructor(private pagesService: PagesService, private router: Router, public dialog: MatDialog,
    public settingService: SettingService, public authService: AuthService, private fb: FormBuilder,
    public toasterService: ToastrService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.anchorDisplayName = getSessionStorage['accountName'];
    if (getSessionStorage['credlixAnchorList']) {
      getSessionStorage['credlixAnchorList'].map(val => {
        if (val.id == localStorage.getItem(importConfig.masterAccountID)) {
          this.anchorDisplayName = val.displayName;
        }
      })
    }
    this.updateTabsdata();
    this.activatedRoute.params.subscribe(params => {
      this.supplierID = params['id'];
    })
    if(this.anchorDisplayName == "MSAF"){
      this.createLenderFormData();
      this.getLenderDetails();
      this.pagesService.isChangeColor("finance_white");
    }
    else{
      this.pagesService.isChangeColor("EP_white");
      this.supplierDetailsForm = this.fb.group({
        rate: [{value: '', disabled: true}, Validators.required],
        eligiblePercentage: [{value: '', disabled: true}, Validators.required],
        limit: [{value: '', disabled: true}],
        maxDaysDiscount: [{value: '', disabled: true}],
        minDaysDiscount: [{value: '', disabled: true}, Validators.required],
      });
      this.bankDetailsForm = this.fb.group({
        recipientName: [{value: '', disabled: this.isBankEditable}, Validators.required],
        accountNumber: [{value: '', disabled: this.isBankEditable}, Validators.required],
        ifscCode: [{value: '', disabled: this.isBankEditable}, Validators.required],
        id: [''],
        status: [''],
        isPrimary: [{value: '', disabled: this.isBankEditable}],
        accountNoUI: [{value: '', disabled: this.isBankEditable}, Validators.required]
      })
      this.getSupplierResults(true);
    }
  }

  createLenderFormData(){
    this.lenderDetailsForm = this.fb.group({
      id: [this.lenderId],
      accountNumber: [{value: '', disabled: true}],
      dealerLimit: [{value: '', disabled: true}, Validators.required],
      processingDays: [{value: '', disabled: true}, Validators.required],
      interestRate: [{value: '', disabled: true}, Validators.required],
      overDueInterestRate: [{value: '', disabled: true}, Validators.required],
      creditDays: [{value: '', disabled: true}, Validators.required],
      gracePeriodDays: [{value: '', disabled: true}, Validators.required],
      eligibleDays: [{value: '', disabled: true}, Validators.required],
    });
  }

  getLenderDetails(){
    this.pagesService.getLenderDetails(this.supplierID).subscribe(res=>{
      if(res['status'] == 200){
        this.lenderConfig = res['result'];
        if(this.lenderConfig && this.lenderConfig[0]){
          this.lenderName = this.lenderConfig[0]['lenderName'];
          this.lenderId = this.lenderConfig[0]['id'];
          this.fillLenderFormData(this.lenderConfig[0]);
        }
        this.getWhatsappList(this.supplierID);
        this.pagesService.getBasicDetails(this.supplierID).subscribe(res=>{
          if(res['result']){
            this.elementDetails = res['result'];
          }
        });
      }
    })
  }

  fillLenderFormData(result){
    this.lenderDetailsForm.patchValue({
      id: this.lenderId,
      accountNumber: result['accountNumber'],
      dealerLimit: result['dealerLimit'],
      processingDays: result['processingDays'],
      interestRate: result['interestRate'],
      overDueInterestRate: result['overDueInterestRate'],
      creditDays: result['creditDays'],
      gracePeriodDays: result['gracePeriodDays'],
      eligibleDays: result['eligibleDays'],
    })
  }

  updateTabsdata(){
    if(this.anchorDisplayName == "MSAF"){
      this.historyTabs = [];
      this.historyTabs = ['Basic Details' ,'Lenders'];
      this.supplierIDLabel = "Lender";
    }
  }

  getSupplierResults(isFromEdit?){
    let obj = {
      "where":{"id":{"type":"search","value":this.supplierID}
    }
  }
  if(this.authService.isCredlixUser()){
    obj['masterAccountId'] = localStorage.getItem('credlixUserID')
  }
  this.isLoading = true;
    this.pagesService.getSupplierResults(obj).subscribe(res=>{
      if(res){
        this.isLoading = false;
        this.elementDetails = res['result'][0];
        this.supplierDetails = this.elementDetails['config'];
        this.getFinancerList();
        for (let i = 0; i < this.approvalListArray.length; i++) {
          this.approvalListArray[i].isChecked = this.isApprovalSelected(this.supplierDetails, this.approvalListArray[i].field)
        }
        if(isFromEdit){
          this.getWhatsappList(this.elementDetails['id']);
          // this.getMetrics();
        }
      }
    })

  }
  
  getTabValue(event) {
    if (event == 0) {
      // this.tabName = 'Profile & Metrics'
      this.tabName = 'Basic Details';
    }
    else if (event == 1 && this.anchorDisplayName != "MSAF") {
      this.tabName = 'Discount Settings';
    }
    else if (event == 1 && this.anchorDisplayName == "MSAF") {
      this.tabName = 'Lenders';
    }
    else if (event == 2) {
      this.tabName = 'Bank Account';
      if(!this.bankTab){
        this.getSupplierBankDetails();
      }
      this.bankTab = true;
    }
    else if (event == 3) {
      this.tabName = 'Transactions';
      if(!this.transactionTab){
        this.getSupplierTransactions();
      }
      this.transactionTab = true;
    }
    else if (event == 5) {
      this.tabName = 'Limits & Rates'
      
    }
  }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    if(this.anchorDisplayName == "MSAF"){
      this.router.navigate(['/channelFinance']);
    }
    else{
      this.router.navigate(['/supplierLinks']);
    }
  }

  getWhatsappList(id) {
    this.settingService.fetchWhatsappUserList(id, null , null).subscribe(respo => {
      this.contactList = respo['result'];
    })
  }

  numberOnly(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  editDetails(isSelected?) {
    if(this.profileSaveBtn == "Edit" && !isSelected){
      this.profileSaveBtn = "Save";
      this.supplierDetailsForm.enable();
      return;
    }
    if(!this.ischeckFinanceName){
      this.toasterService.error("Choose Lender");
      return;
    }
    if (!this.credlixFee && this.ischeckFinanceName != 'Moglix') {
      this.toasterService.error("credlix Fee can not be blank");
      return;
    }
    if (this.supplierDetailsForm.value.rate > 100 || this.supplierDetailsForm.value.eligiblePercentage > 100) {
      this.toasterService.error("percentage can not be more than 100%");
    }
    else if (this.supplierDetailsForm.value.maxDaysDiscount != null && this.supplierDetailsForm.value.maxDaysDiscount != 0 &&
      this.supplierDetailsForm.value.maxDaysDiscount < this.supplierDetailsForm.value.minDaysDiscount) {
      this.toasterService.error("Maximum days should not be less than minimum days");
    }
    else {
        this.getSelectedApproval();
        let obj = {
          "supplierId": this.elementDetails.id,
          "discountRatePerDay": this.supplierDetailsForm.value.rate,
          "maxDisountAllowed": this.supplierDetailsForm.value.eligiblePercentage,
          "minDaysInterval": this.supplierDetailsForm.value.minDaysDiscount,
          "approval": this.approval
        }
        if ((this.ischeckFinanceName == 'Aditya Birla Finance Limited' || this.ischeckFinanceName == 'Cholamandalam Investment and Finance Company') && this.credlixFee != null) {
          obj["financierId"] = this.financerID,
            obj["commission"] = this.credlixFee
        }
        if (this.ischeckFinanceName == 'Moglix') {
          obj["financierId"] = this.financerID,
            obj["commission"] = null
        }
        if (this.supplierDetailsForm.value.limit == null) {
          obj['limit'] = -1
        }
        else {
          obj['limit'] = this.supplierDetailsForm.value.limit
        }
        if (this.supplierDetailsForm.value.maxDaysDiscount == null || this.supplierDetailsForm.value.maxDaysDiscount == 0) {
          obj['maxDaysInterval'] = -1
        }
        else {
          obj['maxDaysInterval'] = this.supplierDetailsForm.value.maxDaysDiscount
        }

        this.pagesService.editSupplierDetails(obj).subscribe(res => {
          if (res['status'] == 200) {
            this.profileSaveBtn = "Edit";
            this.supplierDetailsForm.disable();
            this.getSupplierResults(false);
            if(!isSelected){
              this.toasterService.success("Supplier detail edit successfully");
            }
          }
        })
    }
  }

  getSelectedApproval() {
    this.approval = []
    for (let i = 0; i < this.approvalListArray.length; i++) {
      if (this.approvalListArray[i].isChecked == true) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": true
        })
      }
      else if (this.approvalListArray[i].isChecked == false) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": false
        })
      }
    }
  }

  OnselectFinancer(value, element) {
    for (let i = 0; i < element.length; i++) {
      if (element[i].name == value) {
        this.ischeckFinanceName = element[i].name
        if (value == 'Moglix') {
          this.isMOGLIX = true;
          this.isABFL = false;
          this.financerID = element[i].id;
        }
        else if (value == 'Aditya Birla Finance Limited' || value == 'Cholamandalam Investment and Finance Company') {
          this.isMOGLIX = false;
          this.isABFL = true;
          this.credlixFee = null;
          this.financerID = element[i].id;
        }
      }
    }
    this.financerType = value;
  }

  addContact() {
    const dialogRef = this.dialog.open(AddWhatsappcontactComponent, {
      width: '480px',
      data: this.elementDetails
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getWhatsappList(this.elementDetails['id']);
    })
  }

  setWhatsappNumber(element) {
    let obj = {
      whatsapp: true,
      businessAccountId: this.elementDetails['id'],
      phoneNumber: element.phoneNumber,
      userId: element.id
    }
    if(element.subUserId != null){
      obj['subUserId']= element.subUserId
    }
    
    this.settingService.setWhatsappNumber(obj).subscribe(res => {
      if (res['result'] == true) {
        this.getWhatsappList(this.elementDetails['id']);
      }
    });
  }

  getMetrics() {
    let obj ={
      businessAccountId: this.elementDetails['id'],
      timePeriod: this.selectedMetricsValue
    };
    
    this.settingService.getSupplierMetrics(obj).subscribe((list) => {
      this.metricsRes= list['result']
    });
  }

  OnselectMetrics(value) {
    this.selectedMetricsValue = value;
    this.getMetrics();
  }

  getSupplierBankDetails(){
    this.pagesService.getSupplierBankDetails(this.elementDetails['id']).subscribe(res=>{
      if(res['result'].length != 0){
        this.bankDetailsArr = res['result'];
        this.isBankEditable = true;
        this.bankDetailsForm.disable();
        let data;
        this.bankDetailsArr.map(val=>{
          if(val['isPrimary']){
            this.bankDetails = val;
            data = val;
            val['isPrimaryUI'] = true;
          }
          val['accountNoUI'] = this.getMaskedNumber(val['accountNumber'])
        })
        this.bankName = data['bankName'];
        if(data['state']){
          this.bankName = this.bankName + `, ${data['state']}`;
        }
        this.saveBtnLabel = "Edit";
        this.bankDetailsForm.patchValue({
          recipientName: data['recipientName'],
          accountNumber: data['accountNumber'],
          ifscCode: data['ifscCode'],
          id: data['id'],
          status: data['status'],
          isPrimary: data['isPrimary'],
          accountNoUI: this.getMaskedNumber(data['accountNumber'])
        })
      }
      else{
        this.bankDetailsForm.controls['isPrimary'].disable();
      }
    })
  }

  submitBankDetails(isPrimary, bankData?){
    this.bankDetailsForm.patchValue({
      accountNumber: this.bankDetailsForm.controls['accountNoUI'].value
    })
    let obj = {
      bankData: this.bankDetailsForm,
      isBankValid: true,
      elementDetails: this.elementDetails,
      isBankEditable: this.isBankEditable,
      isPrimary: isPrimary
    }
    if(isPrimary){
      obj['bankData'] = bankData;
    }
    if(this.bankDetailsForm.valid || this.bankDetailsForm['status'] == "DISABLED" || isPrimary){
      const dialogRef = this.dialog.open(OtpLoaderComponent, {
        disableClose: true,
        width: '300px',
        height: '200px',
        data: obj
      });
      dialogRef.afterClosed().subscribe(result => {
      if(result['requestId']){
          result['bankDetails']['requestId'] = result['requestId'];
          const dialogRef = this.dialog.open(PaymentVerificationDialogComponent, {
            width: '450px',
            height: '280px',
            data: result['bankDetails']
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result['disabledPrimary']){
              if(result['isPrimary']){
                this.bankDetailsForm.patchValue({
                  isPrimary: false
                })
              }
            }
            else if(result) {
              this.addNewAccount = false;
              this.saveBtnLabel = "Edit";
              this.bankDetailsForm.disable();
              this.bankDetailsArr = this.bankDetailsArr.filter(val=>{
                return val['accountNoUI'] != "--";
              })
              if(result['setPrimary']){
                this.isPrimaryAcc = true;
                this.bankDetailsArr.map(val=>{
                  if(val['accountNumber'] == result['data']['accountNumber']){
                    val['isPrimary'] = true;
                    val['isPrimaryUI'] = true;
                  }
                  else{
                    val['isPrimary'] = false;
                  }
              })
    
                  this.bankDetailsForm.patchValue({
                    recipientName: result['data']['recipientName'],
                    accountNumber: result['data']['accountNumber'],
                    ifscCode: result['data']['ifscCode'],
                    id: result['data']['id'],
                    status: result['data']['status'],
                    isPrimary: true,
                    accountNoUI: this.getMaskedNumber(result['accountNumber'])
                  })
                  this.bankName = result['data']['bankName'];
                  if(result['data']['state']){
                    this.bankName + `, ${result['data']['state']}`
                  }
                  this.toasterService.success(result['result']);
              }
              else if(this.bankDetailsArr.length != 0){
                if(result['isDeletion']){
                  this.bankDetailsArr = this.bankDetailsArr.filter(val=>{
                    return val['id'] != result['data']['id'];
                  })
                  if(this.bankDetailsArr.length != 0){
                        this.bankDetailsArr[0]['isPrimaryUI'] = true;
                        if(this.bankDetailsArr[0]['isPrimary']){
                          this.isPrimaryAcc = true;
                        }
                        this.bankDetailsForm.patchValue({
                          recipientName: this.bankDetailsArr[0]['recipientName'],
                          accountNumber: this.bankDetailsArr[0]['accountNumber'],
                          ifscCode: this.bankDetailsArr[0]['ifscCode'],
                          id: this.bankDetailsArr[0]['id'],
                          status: this.bankDetailsArr[0]['status'],
                          isPrimary: this.bankDetailsArr[0]['isPrimary'],
                        })
                  }
                  this.toasterService.success(result['result']['Reason']);
                }
                else{
                  this.isPrimaryAcc = false;
                  result['accountNoUI'] = this.getMaskedNumber(result['accountNumber']);
                  result['isPrimaryUI'] = true;
                  this.bankDetailsArr.push(result);
                  this.bankDetails = result;
                  this.bankDetailsForm.patchValue({
                    recipientName: result['recipientName'],
                    accountNumber: result['accountNumber'],
                    ifscCode: result['ifscCode'],
                    id: result['id'],
                    status: result['status'],
                    isPrimary: result['isPrimary'],
                    accountNoUI: this.getMaskedNumber(result['accountNumber'])
                  })
                  this.isBankEditable = true;
                  this.bankName = result['bankName'] ;
                  if(result['state']){
                    this.bankName = this.bankName + `, ${result['state']}`
                  }
                }
              }
              else{
                this.isBankEditable = true;
                this.bankDetailsArr.push(result);
                this.bankDetailsArr.map(val=>{
                  if(val['isPrimary'] == true){
                    this.bankDetailsForm.patchValue({
                      recipientName: val['recipientName'],
                      accountNumber: val['accountNumber'],
                      ifscCode: val['ifscCode'],
                      id: val['id'],
                      status: val['status'],
                      isPrimary: val['isPrimary'],
                      isPrimaryUI: true
                    })
                  }
                  val['accountNoUI'] = this.getMaskedNumber(val['accountNumber'])
              })
                this.bankName = result['bankName'];
                if(result['state']){
                  this.bankName = this.bankName + `, ${result['state']}`
                }
                this.toasterService.success("Supplier bank details saved successfully");
              }
            }
          })
        }
        else if(result['disabledPrimary']){
          if(result['isPrimary']){
            this.bankDetailsForm.patchValue({
              isPrimary: false
            })
          }
        }
      });
    }
  }

  getFinancerList() {
    let credlixUserID;
    if (this.authService.isCredlixUser()) {
      credlixUserID = localStorage.getItem('credlixUserID');
    }
    this.pagesService.fetchFinancer(credlixUserID).subscribe(list => {
      this.financerArray = list['result'];
      this.setSupplierDetails();

    })
  }

  setSupplierDetails() {
    this.supplierDetailsForm.patchValue({
      rate: this.supplierDetails.discountRatePerDay,
      eligiblePercentage: this.supplierDetails.maxInvoiceDiscountpercentage,
      minDaysDiscount: this.supplierDetails.minDaysInterval

    })
    if (this.supplierDetails.limit == -1) {
      this.supplierDetailsForm.patchValue({
        limit: null
      })
    }
    else {
      this.supplierDetailsForm.patchValue({
        limit: this.supplierDetails.limit
      })
    }
    if (this.supplierDetails.maxDaysInterval == -1) {
      this.supplierDetailsForm.patchValue({
        maxDaysDiscount: null
      })
    }
    else {
      this.supplierDetailsForm.patchValue({
        maxDaysDiscount: this.supplierDetails.maxDaysInterval
      })
    }
    if (this.supplierDetails.financier != null) {
      this.financeSelectType = this.supplierDetails.financier.name;
      this.ischeckFinanceName = this.supplierDetails.financier.name;
      for (let i = 0; i < this.financerArray.length; i++) {
        if (this.financeSelectType == this.financerArray[i].name) {
          this.financerID = this.financerArray[i].id
        }
      }
      if (this.financeSelectType == 'Aditya Birla Finance Limited' || this.financeSelectType == 'Cholamandalam Investment and Finance Company') {
        this.isABFL = true;
        this.isMOGLIX = false;
        this.credlixFee = this.supplierDetails.commission
      }
      else {
        this.isABFL = false;
        this.isMOGLIX = true;
        this.credlixMoglixFee = 0
      }
    }

  }

  isApprovalSelected(supplierConfig, approvalName) {
    let checked = false;
    if (supplierConfig.approvals != null) {
      let temp = supplierConfig.approvals.filter(data => {
        return data.field == approvalName
      })
      if (temp != null && temp.length > 0) {
        checked = temp[0].isActive
      }
    }
    return checked
  }

  deleteBankDetails(bankData){
      let data = {
        title: "Please Confirm",
        content: "Are you sure you want to delete the selected Account?",
        additional: "",
        class: "green"
      }
      const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      disableClose: true,
      width: '400px',
      height: '250px',
      data: data
    });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.isBankEditable = true;
          this.submitBankDetails(false)
        }
    });
  }

  addBankAccount() {
    if(!this.addNewAccount){
      let obj = {
        bankName: "New Account Details",
        isPrimaryUI: true,
        accountNoUI: "--"
      }
      this.bankDetailsArr.map(val=>{
        val['isPrimaryUI'] = false;
      })
      this.bankName = "New Account Details";
      this.bankDetailsArr.push(obj);
      this.isBankEditable = false;
      this.saveBtnLabel = "Save";
      this.addNewAccount = true;
      this.bankDetailsForm.reset();
      this.bankDetailsForm.enable();
      this.bankDetailsForm.controls['isPrimary'].disable();
    }
  }

  getSupplierTransactions(){
    let obj = {
      "where": {
        "transactionType": {
          "type": "search",
          "value": "CREDIT"
        }
      }
    }
    this.pagesService.getSuppliersTransactions(obj).subscribe(res=>{
      this.dataSource.data = res['result'];
      this.totalRecords = res['total'];
      this.createHeaders(this.arrConfig);
    })
  }

  createHeaders = (config) => {
    this.displayedColumns1 = [];
    this.displayedColumns = [];
    config.map((each) => {
      this.displayedColumns1.push(each.field);
      each['cell'] = (element: any, date: string) => {
        if(date == "paymentDate"){
          return this.datePipe.transform(_.get(element, each.field), 'dd-MM-yyyy');
        }
        else{
          return _.get(element, each.field);
        }
      }
    })
    this.displayedColumns = config;
  }

  getMaskedNumber(number){
    let UIAccNo;
    UIAccNo = number.slice(0, 3) + number.slice(3, (number.length-3)).replace(/./g, '*') + number.slice((number.length-3), number.length);
    return UIAccNo;
  }

  OnselectBankAcc(bankData){
    this.saveBtnLabel = "Edit";
    this.bankName = bankData['bankName'];
    if(bankData['isPrimary']){
      this.isPrimaryAcc = true;
    }
    else{
      this.isPrimaryAcc = false;
    }
    if(bankData['state']){
      this.bankName = this.bankName + `, ${bankData['state']}`
    }
    this.bankDetails = bankData;
    this.bankDetailsForm.patchValue({
      recipientName: bankData['recipientName'],
      accountNumber: bankData['accountNumber'],
      ifscCode: bankData['ifscCode'],
      id: bankData['id'],
      status: bankData['status'],
      isPrimary: bankData['isPrimary'],
      accountNoUI: bankData['accountNoUI']
    })
    this.bankDetailsArr.map(val=>{
      if(val['accountNumber'] == bankData['accountNumber']){
        val['isPrimaryUI'] = true;
      }
      else{
        val['isPrimaryUI'] = false;
      }
    })
    this.bankDetailsForm.disable();
  }

  // addnewbuyer(): void {
  //   const dialogRef = this.dialog.open(AddBuyerDialogComponent, {
  //     width: '660px',
  //     maxWidth:'100%',
  //     height:'200px'
  //   });
  // }

  // addnewbuyerlender(): void {
  //   const dialogRef = this.dialog.open(AddBuyerLenderDialogComponent, {
  //     width: '660px',
  //     maxWidth:'100%',
  //     height:'200px'
  //   });
  // }

  

  resetAccount(){
    this.saveBtnLabel = "Edit";
    this.isBankEditable = true;
    this.bankDetailsForm.disable();
    this.isPrimaryAcc = true;
      this.bankDetailsArr = this.bankDetailsArr.filter(val=>{
        return val['accountNoUI'] != "--";
      })
      this.bankDetailsArr.map(val=>{
        if(val['isPrimary'] == true){
          this.bankDetailsForm.patchValue({
            recipientName: val['recipientName'],
            accountNumber: val['accountNumber'],
            ifscCode: val['ifscCode'],
            id: val['id'],
            status: val['status'],
            isPrimary: val['isPrimary'],
            accountNoUI: this.getMaskedNumber(val['accountNoUI'])
          })
          val['isPrimaryUI'] = true;
          this.bankName = val['bankName'];
          if(val['state']){
            this.bankName = this.bankName + `, ${val['state']}`;
          }
        }
      })
    this.addNewAccount = false;
  }

  resetProfile(){
    if(this.anchorDisplayName == "MSAF"){
      this.lenderDetailsForm.disable();
    }
    else{
      this.supplierDetailsForm.disable();
    }
    this.profileSaveBtn = "Edit";
  }

  editLender(isSelected?){
    if(this.profileSaveBtn == "Edit" && !isSelected){
      this.profileSaveBtn = "Save";
      this.lenderDetailsForm.enable();
      return;
    }
    if(this.lenderDetailsForm.valid){
      this.pagesService.updateLenderDetails(this.lenderDetailsForm.value).subscribe(res=>{
        console.log("updated data-------->",res);
        if (res['status'] == 200) {
          this.profileSaveBtn = "Edit";
          this.fillLenderFormData(res['result']);
          this.lenderDetailsForm.disable();
          if(!isSelected){
            this.toasterService.success("Dealer details edit successfully");
          }
        }
      })
    }
  }

}
