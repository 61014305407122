<div class="container">
  <div class="heading">
    <button mat-button color="primary" (click)="collapsFilter()">
      <mat-icon>filter_list</mat-icon> Filters
    </button>

    <ng-container >
      <!-- <p><mat-slide-toggle color="primary" labelPosition="before" (change)="viewColdLeads($event)">Cold Leads</mat-slide-toggle></p> -->
      <button mat-button color="primary" (click)="downloadCSV()">
        <mat-icon>cloud_download</mat-icon>
        Export
      </button>
    </ng-container>
  </div>
  <div *ngIf="isfilterVisible" class="actionDiv">
    <div class="searchBox">
      <input type="text" class="setinpt" placeholder="Lead ID or Name" [(ngModel)]="searchText"
        (keyup.enter)="searchFilter()" (keypress)="searchFilter()" (blur)="handleBlur()">
      <div *ngIf="isSeachEnable && suggestions && suggestions.length" class="suggestions">
        <ul>
          <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
            {{ suggestion.displaySequence }}<br />
            <small>{{suggestion.displayName}}</small>
          </li>
        </ul>
      </div>
    </div>

    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>Select Region<span class="text-danger">*</span></mat-label>
        <mat-select placeholder="Enter Region" [(ngModel)]="selectedRegions" name="selectedRegions" multiple>
          <mat-option *ngFor="let lead of dataService.mexicoRegionList" [value]="lead">{{lead}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <input mat-input [matDatepicker]="picker1" (focus)="picker1.open()" class="setinpt alignInput"
        placeholder="Start Date" [(ngModel)]="startDate" readonly>
      <mat-datepicker-toggle style="display: none;" matSuffix [for]="picker1">
      </mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="searchBox">
      <input mat-input [matDatepicker]="picker2" (focus)="picker2.open()" class="setinpt alignInput"
        placeholder="End Date" [(ngModel)]="endDate" readonly>
      <mat-datepicker-toggle style="display: none;" matSuffix [for]="picker2">
      </mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </div>
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>Lead Source<span class="text-danger">*</span></mat-label>
        <mat-select placeholder="Enter Source" [(ngModel)]="selectedSources" name="selectedSources" multiple>
          <mat-option *ngFor="let lead of leadsSourceList" [value]="lead">{{lead}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>Current Milestone<span class="text-danger">*</span></mat-label>
        <mat-select placeholder="Enter Current Milestone" [(ngModel)]="selectedCurrentMilestones"
          name="selectedCurrentMilestones" multiple>
          <mat-option *ngFor="let milestone of mileStoneList" [value]="milestone.id">{{milestone.displaySequence}}.
            {{milestone.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>Transaction Type <span class="text-danger">*</span></mat-label>
        <mat-select placeholder="Select Transaction Type" [(ngModel)]="selectedTransactionTypes"
          name="selectedTransactionTypes" multiple>
          <mat-option *ngFor="let insurer of transactionTypeList" [value]="insurer">{{insurer}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>RM <span class="text-danger">*</span></mat-label>
        <mat-select class="rmInput" placeholder="RM" matInput [(ngModel)]="selectedRelationshipManagers"
          name="selectedRelationshipManagers" multiple>
          <mat-option *ngFor="let rm of rmUserList" [value]="rm.id">
            {{ rm.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="searchBox rmInput">
      <mat-form-field appearence="outline">
        <mat-select class="rmInput" placeholder="RM" matInput [matAutocomplete]="auto"
          [(ngModel)]="relationshipManager">
          <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let rm of filteredAssigneeList" [value]="rm.userName">
            {{ rm.userName }}
          </mat-option>
        </mat-select>
        </mat-autocomplete>
      </mat-form-field>

    </div> -->
    <div class="searchBox">
      <button mat-flat-button class="appbtn searchBtn" (click)="applyFilter()">Search</button>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn" (click)="clearFilter()">Clear</button>
    </div>
  </div>
  <!-- <button class="expandbtn" mat-button color="primary" (click)="expandOrCollapse()">
    <mat-icon>add_box</mat-icon>
    {{isExpanded ? "Collapse": "Expand"}}
  </button> -->
  <div style="overflow-x: auto;" *ngIf="dataSource && dataSource.length > 0; else noDataTemplate">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice w-full">
      <!-- Name Column -->
      <ng-container matColumnDef="leadId" sticky>
        <th mat-header-cell *matHeaderCellDef> Lead ID </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="superHighligth" (click)="openCardDetailsPage(element)">{{element.displaySequence}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="leadName" sticky>
        <th mat-header-cell *matHeaderCellDef>
          Lead Name
          <mat-icon *ngIf="!isLeadNameFormat" (click)="leadNameFormat()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isLeadNameFormat" (click)="leadNameFormat()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{formatLeadName(element.displayName)}}" matTooltipClass="custom-tooltip">
            <span class="truncate"> <b>{{formatLeadName(element.displayName)}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="leadSource" sticky>
        <th mat-header-cell *matHeaderCellDef> Lead Source</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.leadSource}}" matTooltipClass="custom-tooltip">
            <span class="truncate"> {{element.leadSource}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="rm" sticky>
        <th mat-header-cell *matHeaderCellDef> RM </th>
        <td mat-cell *matCellDef="let element"> {{element.relationshipManager ? element.relationshipManager.name :
          '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="milestone" sticky>
        <th mat-header-cell *matHeaderCellDef> Current Milestone
          <mat-icon *ngIf="!isCurrentMilestone" (click)="formatCurrentMileStone()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isCurrentMilestone" (click)="formatCurrentMileStone()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>
            <b>{{element.currentMilestone ? element.currentMilestone.displaySequence : ''}}.</b>
          </span>
          <span>
            {{element.currentMilestone ? element.currentMilestone.name : '-'}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="annualTurnOver">
        <th mat-header-cell *matHeaderCellDef>Rev (INR Cr)
          <mat-icon *ngIf="!isAnnualTurnOverFormat" (click)="annualTurnOverFormat()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isAnnualTurnOverFormat" (click)="annualTurnOverFormat()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>{{element.annualTurnOver ? formatNumberIndianStyle(element.annualTurnOver) : '-'}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="buyerData">
        <th mat-header-cell *matHeaderCellDef>Buyer Data</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>
              # {{element.noOfBuyers ? element.noOfBuyers: '-'}}
            </span>
            <br>
            <span>
              Limit: {{element.totalLimitApplied ? element.totalLimitApplied : '-'}}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="funding">
        <th mat-header-cell *matHeaderCellDef> Funding Req
          <mat-icon *ngIf="!isFundingRequirmentFormat" (click)="fundingRequirmentFormat()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isFundingRequirmentFormat" (click)="fundingRequirmentFormat()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.fundingRequirement && element.currency">{{getCurrencySymbol(element.currency)}}</span>
          <span>{{element.fundingRequirement ? formatNumberIndianStyle(element.fundingRequirement) : '-'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="goLiveMonth">
        <th mat-header-cell *matHeaderCellDef> Go Live Month
          <mat-icon *ngIf="!isGoLiveMonthFormat" (click)="goLiveMonthFormatFormat()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isGoLiveMonthFormat" (click)="goLiveMonthFormatFormat()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{element.goLiveMonth ? (element.goLiveMonth | date: 'MMM, yy') : '-'}}</span>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="transaction">
        <th mat-header-cell *matHeaderCellDef> Transaction Type </th>
        <td mat-cell *matCellDef="let element"> {{element.transactionType ? element.transactionType : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef> Region </th>
        <td mat-cell *matCellDef="let element"> {{element.region ? element.region: '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="ageing">
        <th mat-header-cell *matHeaderCellDef>Ageing
          <mat-icon *ngIf="!isCreatedAt" (click)="formatCreatedAt()">arrow_downward</mat-icon>
          <mat-icon *ngIf="isCreatedAt" (click)="formatCreatedAt()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.milestoneUpdatedDate ?
          dayBetween(element.milestoneUpdatedDate) : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef> Created at</th>
        <td mat-cell *matCellDef="let element"> {{element.createdAT ? (element.createdAT | date: ' dd-MMM-yy') : '-'}}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="actionOn">
        <th mat-header-cell *matHeaderCellDef>Pending on
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{element.actionPendingOn ? element.actionPendingOn : '-'}}</span>
        </td>
      </ng-container> -->


      <ng-container matColumnDef="probability">
        <th mat-header-cell *matHeaderCellDef> Probability</th>
        <td mat-cell *matCellDef="let element"> {{element.probability ? element.probability : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="reviveDate">
        <th mat-header-cell *matHeaderCellDef> Revival Date</th>
        <td mat-cell *matCellDef="let element"> {{element.revivedDate ? (element.revivedDate | date: ' dd-MMM-yy') :
          '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="revivalReason">
        <th mat-header-cell *matHeaderCellDef> Revival Reason </th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.revivalReason}}" matTooltipClass="custom-tooltip">
          <span class="truncate">{{element.revivalReason ? element.revivalReason : '-'}} </span>
          </td>
      </ng-container>

      <ng-container matColumnDef="latestComment" stickyEnd>
        <th mat-header-cell *matHeaderCellDef> Action item</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.comment}}" matTooltipClass="custom-tooltip">
          <span class="truncate">{{element.comment ? element.comment : '-'}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="ActionDueDate" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Action due date
          <mat-icon *ngIf="isActionDueDateFormat" (click)="actionDueDateFormat()">arrow_downward</mat-icon>
          <mat-icon *ngIf="!isActionDueDateFormat" (click)="actionDueDateFormat()">arrow_upward</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{element.actionDueDate ? (element.actionDueDate | date: ' dd-MMM-yy') : '-'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="comment" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Comment </th>
        <td mat-cell *matCellDef="let element">
          <div class="comment-container">
            <mat-icon class="edit-icon" (click)="opeEditCommentPopup(element)">comment</mat-icon>
            <mat-icon class="edit-icon" (click)="routeToHistoyTabOfLead(element)">history</mat-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="getRowColor(row)"></tr>
    </table>
  </div>
  <ng-template #noDataTemplate>
    <div class="no-data-found">
      No data found
    </div>
  </ng-template>
  <mat-paginator [hidden]="totalRecords == 0" [length]="totalRecords" [pageIndex]="0" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]" (page)="getPaginatorData($event)" showFirstLastButtons>
  </mat-paginator>
</div>
