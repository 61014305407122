import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatTableDataSource,
  MatPaginator,
  MatSort,
  MatSelect,
  MatOption,
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { PagesService } from "src/app/pages/pages.service";
import { AuthService } from "src/app/auth/auth.service";
import { ToastrService } from "ngx-toastr";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AddSupplierDialogComponent } from "./add-supplier-dialog/add-supplier-dialog.component";
import { EditdetailsDialogComponent } from "./editdetails-dialog/editdetails-dialog.component";
import { BulkactionsDialogComponent } from "./bulkactions-dialog/bulkactions-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import { ConfirmationDialogComponent } from "./bulkactions-dialog/confirmation-dialog/confirmation-dialog.component";
import { DownloadReconcileComponent } from "./download-reconcile/download-reconcile.component";
import { ReportDownloadInvoiceComponent } from "src/app/component/early-payments/early-payment-listing//report-download-invoice/report-download-invoice.component";
import { CustomSnackbarComponent } from "src/app/component/early-payments/early-payment-listing/custom-snackbar/custom-snackbar.component";
import * as lodash from "lodash";
import { SettingService } from "src/app/settings/setting.service";
import { SendWhatsappMsgDialogComponent } from "./send-whatsapp-msg-dialog/send-whatsapp-msg-dialog.component";
import * as moment from "moment";
import { CommentsTabComponent } from "../../credlix-board/k-board/board-lane/card-details/comments-tab/comments-tab.component";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-listing",
  templateUrl: "./listing.component.html",
  styleUrls: ["./listing.component.scss"],
})
export class ListingComponent implements OnInit, OnDestroy {
  pageSize: number = 50;
  totalRecords: number;
  pageNumber: number = 0;
  obj = {};
  supplierLinks = [];
  seachText: string;
  actionName: string;
  isButtonEnable: boolean = true;
  mark = "&#8734";
  selectedRows: any;
  isFiltersShow: boolean = true;
  sendSelectedSupplierID = [];
  BizTypeCheckedList = [];
  selectedBizTypeStatus = [];
  searchedTextData = [];
  searchValueadded: string;
  searchResultListing = [];
  suggestedSuppliers = [];
  suggestedInvoices = [];
  filteropen: boolean = false;
  showListing: boolean = false;
  showSearchIcon: boolean = true;
  showCloseIcon: boolean = false;
  searchText: any;
  searchQuery: boolean = false;
  reqobj = {};
  autoSuggestloading: boolean = false;
  previousSearchtext: string = "";
  isClearButtonEnable: boolean = true;
  isApplyButtonEnable: boolean = true;
  isCommentvisible: boolean = false;
  entityID: any;
  tooltipComments: string;
  colorCode: string;
  Comments: string;
  suplColrObj = {};
  selectedSupplierDetails: any;
  colorCheckedList = [];
  activeSort = {};
  statusCheckedList = [];
  isColorCodeVisible: boolean = false;
  _: any = lodash;
  BizTypeFilterList: any = [
    { id: "1", roleName: "ABFRL", roleStatus: "isAbfrl" },
    { id: "2", roleName: "Moglix", roleStatus: "moglix" },
    { id: "3", roleName: "Tasva", roleStatus: "isTasva" },
  ];

  colorCodeArray: any = [
    { id: "1", colorCode: "red", colorName: "Red" },
    { id: "2", colorCode: "orange", colorName: "Orange" },
    { id: "3", colorCode: "purple", colorName: "Purple" },
    { id: "4", colorCode: "green", colorName: "Green" },
    { id: "5", colorCode: "blue", colorName: "Blue" },
    { id: "6", colorCode: "no_color", colorName: "No Color" },
  ];
  displayedColumns = [
    "color",
    "select",
    "supplierID",
    "isActive",
    "biz_type",
    "rmName",
    "stage",
    "subStage",
    "eligibleInvoice",
    "requestValue",
    "holdValue",
    "limit",
    "Action",
  ];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("matRef1") matRef1: MatSelect;
  @ViewChild("matRef2") matRef2: MatSelect;
  @ViewChild("matRef3") matRef3: MatSelect;
  @ViewChild("matRef5") matRef5: MatSelect;
  @ViewChild("matRef6") matRef6: MatSelect;
  storedEligibleAmountVal: any;
  storedEligibleCount: any;
  storedRequestedAmountVal: any;
  storedRequestedCount: any;
  dataSource = new MatTableDataSource(this.supplierLinks);
  verticalPosition: MatSnackBarVerticalPosition = "top";
  isCustomer: boolean;
  paymentHoldAmountVal: any;
  paymentHoldCount: any;
  remitterName: string;
  endDate: any;
  startDate: any;
  // selectedDate: any;
  stageArray: any = [
    {
      stage: "C0-Cold",
      subStage: ["Not interested", "Inactive", "GST", "blocked by Business"],
    },
    {
      stage: "c0",
      subStage: ["Lead added in credlix", "No Rm assigned", "No WIP"],
    },
    { stage: "c1", subStage: ["Contacted", "follow-up"] },
    { stage: "c2", subStage: ["Contacted", "Pitched"] },
    { stage: "c3", subStage: ["Hot can be converted"] },
    { stage: "c4", subStage: ["Onboarded-Discounted"] },
  ];
  subStageArray: string;
  selectedSubStage: string;
  usersRMList = [];
  selectedRM: string;
  // endDateRange: any;
  // startDateRange: any;
  selectedStage: string;
  selectedRmName = new FormControl();
  filteredRMList: Observable<string[]>;
  jkFennerCheck: boolean = false;
  isRefreshButtonDisable : boolean = false;
  remainingTime: number = 0;
  private countdownInterval: any;

  constructor(
    public pagesService: PagesService,
    public router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public authService: AuthService,
    public toasterService: ToastrService,
    private settingService: SettingService,
    private activatedRoute: ActivatedRoute
  ) {
    this.selection.changed.subscribe((item) => {
      this.isButtonEnable = this.selection.selected.length == 0;
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  isEscrowPermission: boolean = false;
  isborrowrper: boolean = false;
  issendwhtsapPermis: boolean = false;
  ngOnInit() {    
      if (this.authService.isJKFennerAnchor()) {
        //change for production
        this.jkFennerCheck = true;
      } else this.jkFennerCheck = false;
    this.pagesService.isChangeColor("EP_white");
    if (this.activeSort == null || this.activeSort["field"] == null) {
      this.activeSort = {
        field: "elgInvoiceValue",
        order: "desc",
      };
      if (this.sort != null) {
        this.sort.sort({
          id: "elgInvoiceValue",
          start: "desc",
          disableClear: true,
        });
      }
    }
    this.pagesService
      .getHeaderList(localStorage.getItem("credlixUserID"))
      .subscribe((list) => {
        this.authService.storageListHeaders(list["result"]);
        if (
          list["result"]["borrowerDisplayConfig"] == null ||
          list["result"]["borrowerDisplayConfig"].length == 0
        ) {
          this.displayedColumns.splice(4, 1);
        }
      });
    if (this.authService.getIsCusotmer()) {
      this.isCustomer = true;
    }
    let filtrPermArray = [];
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    let currentUser = getSessionStorage.role.role["permissions"];
    filtrPermArray = currentUser.filter((e) => {
      if (e == "SEND_WHATSAPP_MESSAGES" || e == "ADD_BORROWER") {
        return true;
      }
    });
    for (let i = 0; i < filtrPermArray.length; i++) {
      if (filtrPermArray[i] == "ADD_BORROWER") {
        this.isborrowrper = true;
      }
      if (filtrPermArray[i] == "SEND_WHATSAPP_MESSAGES") {
        this.issendwhtsapPermis = true;
      }
      if (filtrPermArray[i] == "ESCROW_MGMT") {
        this.isEscrowPermission = true;
      }
    }
    this.getRmList();

    const savedTimestamp = localStorage.getItem('buttonDisabledTimestamp');
    if (savedTimestamp) {
      const elapsedTime = Date.now() - parseInt(savedTimestamp, 10);

      // Calculate the remaining time in seconds for a 30-minute timer (1800 seconds)
      if (elapsedTime < 900000) {
        this.startCountdown(900 - Math.floor(elapsedTime / 1000));
      }
    }
  }
  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      let paramId = params.id;
      /**
       * added reqData to handle color code fixes on loading.
       * if user apply color code and reload then this object will be sent in request
       */
      let reqData = JSON.parse(sessionStorage.getItem("requestObject"));
      if (params.id != "supplierText") {
        let searchText = false;
        this.reqobj = {
          size: this.paginator.pageSize,
          from: this.paginator.pageIndex * this.paginator.pageSize,
          where: {},
          sort: this.activeSort,
        };
        if (paramId != undefined && paramId != "") {
          if (sessionStorage.getItem("searchedText")) {
            this.reqobj["where"]["id"] = {
              type: "search",
              value: paramId,
            };
          } else {
            searchText = true;
            sessionStorage.clear();
            this.router.navigate(["/supplierLinks"]);
          }
        } else if (
          reqData != null &&
          Object.keys(reqData["where"]).length > 0
        ) {
          this.reqobj["where"] = reqData["where"];
          this.setColorCode(reqData);
        }
        if (!searchText) this.supplierFetchResult(this.reqobj);
      } else if (
        params.id == "supplierText" &&
        sessionStorage.getItem("searchedText")
      ) {
        this.reqobj = {
          size: this.paginator.pageSize,
          from: this.paginator.pageIndex * this.paginator.pageSize,
          where: {
            supplier: {
              type: "search",
              operator: "or",
              value: [
                {
                  field: "searchKey",
                  value: params.param1,
                },
                {
                  field: "businessName",
                  value: params.param1,
                },
              ],
            },
          },
          sort: this.activeSort,
        };
        this.supplierFetchResult(this.reqobj);
      } else {
        this.getSupplierLinks(false);
      }
    });
  }

  getPaginatorData() {
    this.getSupplierLinks(false);
  }
  directInvoices() {
    this.pagesService.invoicesStatus().subscribe({
      next: (resp: any) => {
        this.toasterService.success(resp.result);
      },
      error: (err: any) => {
        this.toasterService.error("Something Went Wrong");
      },
    });
  }
  directVendors() {
    this.pagesService.vendorsStatus().subscribe({
      next: (resp: any) => {
        this.toasterService.success(resp.result);
      },
      error: (err: any) => {
        this.toasterService.error("Something Went Wrong");
      },
    });
  }

  getSupplierLinks(value, param1?, columnName?, direction?) {
    if (value == true) {
      this.paginator.firstPage();
    }
    this.setBizTypeStatus();
    this.reqobj = {
      size: this.paginator.pageSize,
      from: this.paginator.pageIndex * this.paginator.pageSize,
      where: {},
      sort: this.activeSort,
    };
    if (this.entityID != undefined && this.entityID != "") {
      this.reqobj["where"]["id"] = {
        type: "search",
        value: this.entityID,
      };
    }
    if (this.selectedRM != undefined && this.selectedRM != "") {
      this.reqobj["where"]["rmName.id"] = {
        type: "search",
        value: this.selectedRM,
      };
    }
    if (this.selectedStage != undefined && this.selectedStage != "") {
      this.reqobj["where"]["stage"] = {
        type: "search",
        value: this.selectedStage,
      };
    }
    if (this.selectedSubStage != undefined && this.selectedSubStage != "") {
      this.reqobj["where"]["subStage"] = {
        type: "search",
        value: this.selectedSubStage,
      };
    }

    if (
      this.selectedBizTypeStatus != null &&
      this.selectedBizTypeStatus.length != 0
    ) {
      if (this.selectedBizTypeStatus.length < 2) {
        this.reqobj["where"]["bizType"] = {
          type: "search",
          value: this.selectedBizTypeStatus[0],
        };
      }
    }
    if (this.colorCheckedList != null && this.colorCheckedList.length != 0) {
      this.reqobj["where"]["colorCode"] = {
        type: "color_query",
        value: this.colorCheckedList,
      };
      /**
       * storing request data in sessionstorage for color code fixes on loading.
       * if user apply color code and reload then this object will be sent in request
       */
    }
    if (direction == "asc") {
      this.reqobj["where"] = {
        paymentHoldCount: {
          type: "mustNot",
          value: 0,
        },
      };
    }

    if (this.statusCheckedList != null && this.statusCheckedList.length != 0) {
      if (this.statusCheckedList.length < 2) {
        if (this.statusCheckedList[0] == "active") {
          this.reqobj["where"]["isActive"] = {
            type: "search",
            value: true,
          };
        } else if (this.statusCheckedList[0] == "inActive") {
          this.reqobj["where"]["isActive"] = {
            type: "search",
            value: false,
          };
        }
      }
    }
    if (
      this.entityID == "supplierText" &&
      sessionStorage.getItem("searchedText")
    ) {
      this.reqobj = {
        size: this.paginator.pageSize,
        from: this.paginator.pageIndex * this.paginator.pageSize,
        where: {
          supplier: {
            type: "search",
            operator: "or",
            value: [
              {
                field: "searchKey",
                value: param1,
              },
              {
                field: "businessName",
                value: param1,
              },
            ],
          },
        },
        sort: this.activeSort,
      };
    }
    let requestData = {};
    requestData["where"] = this.reqobj["where"];
    requestData["colors"] = this.colorCheckedList;
    requestData["status"] = this.statusCheckedList;
    requestData["biztype"] = this.BizTypeCheckedList;

    sessionStorage.setItem("requestObject", JSON.stringify(requestData));
    this.supplierFetchResult(this.reqobj);
  }

  supplierFetchResult(reqObj) {
    if (this.authService.isCredlixUser()) {
      reqObj["masterAccountId"] = localStorage.getItem("credlixUserID");
    }
    this.pagesService.getSupplierResults(reqObj).subscribe((list) => {
      this.pagesService.offSpinner();
      this.storedEligibleAmountVal = list["stats"].eligibleAmountVal;
      this.storedEligibleCount = list["stats"].eligibleCount;
      this.storedRequestedAmountVal = list["stats"].requestedAmountVal;
      this.storedRequestedCount = list["stats"].requestedCount;
      this.paymentHoldAmountVal = list["stats"].paymentHoldAmountVal;
      this.paymentHoldCount = list["stats"].paymentHoldCount;
      this.supplierLinks = list["result"];
      this.dataSource.data = list["result"];
      this.totalRecords = list["total"];
      this.paginator.length = this.totalRecords;
    });
  }

  generateLink(row) {
    let obj = {
      email: row.userEmail,
    };
    if (this.authService.isCredlixUser()) {
      obj["masterAccountId"] = localStorage.getItem("credlixUserID");
    }
    this.authService.forgotPassword(obj).subscribe((res) => {
      if (res["result"] == true) {
        this.pagesService.offSpinner();
        this.toasterService.success("Resend link sent to Your email");
      } else {
        this.toasterService.error("Something went wrong");
      }
    });
  }
  setBizTypeStatus() {
    this.selectedBizTypeStatus = [];
    for (let i = 0; i < this.BizTypeCheckedList.length; i++) {
      this.selectedBizTypeStatus.push(this.BizTypeCheckedList[i].roleName);
    }
  }

  isAllSelected() {
    this.selectedRows = this.selection.selected;
    if (this.selectedRows != null && this.selectedRows.length > 0) {
      this.isButtonEnable = false;
    } else if (this.selectedRows == null || this.selectedRows.length < 1) {
      this.isButtonEnable = true;
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }
  checkboxSelection(row) {
    let isChecked = !row;
    if (isChecked == true) {
      this.isButtonEnable = false;
    } else if (isChecked == false) {
      this.isButtonEnable = true;
    }
  }

  checkFilterEnableCondition() {
    if (
      this.BizTypeCheckedList.length > 0 ||
      this.statusCheckedList.length > 0 ||
      this.colorCheckedList.length > 0
    ) {
      this.isApplyButtonEnable = false;
      this.isClearButtonEnable = false;
    } else {
      this.isApplyButtonEnable = true;
      this.isClearButtonEnable = true;
    }
    if (this.reqobj["where"] != null) {
      this.isApplyButtonEnable = false;
      this.isClearButtonEnable = false;
    }
  }

  onBizTypeChange(typeList) {
    this.BizTypeCheckedList = typeList;
    this.checkFilterEnableCondition();
  }

  onColorChange(colorList) {
    this.colorCheckedList = colorList;
    this.checkFilterEnableCondition();
  }
  onStatusChange(status) {
    this.statusCheckedList = status;
    this.checkFilterEnableCondition();
  }
  filtersDisplay() {
    this.isFiltersShow = !this.isFiltersShow;
  }

  changeBulkActions(value) {
    let obj = {
      actionButton: value,
      selectedSuppliers: this.selectedRows,
    };
    this.actionName = value;

    const dialogRef = this.dialog.open(BulkactionsDialogComponent, {
      width: "350px",
      height: "285px",
      data: obj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.getSupplierLinks(false);
      }
      this.isButtonEnable = true;
      this.selection = new SelectionModel(true, []);
      this.pagesService.offSpinner();
    });
  }
  bulkSyncInvoices() {
    console.log(this.selectedRows);
    let businessArray = this.selectedRows.map(
      (x) => x.config.businessAccountId
    );
    this.syncCall(businessArray);
  }
  SyncInvoice(accntId: any) {
    this.syncCall(accntId);
  }
  syncCall(ids: any) {
    this.pagesService.bulkInvoicesSync(ids).subscribe({
      next: (resp: any) => {
        this.toasterService.success(resp.result);
      },
      error: (err: any) => this.toasterService.error("Something Went Wrong"),
    });
  }
  editSupplierStatus(element) {
    let obj = {
      supplierId: element.id,
    };
    if (element.config.isActive == true) {
      obj["isActive"] = false;
    } else if (element.config.isActive == false) {
      obj["isActive"] = true;
    }
    if (this.authService.isCredlixUser()) {
      obj["masterAccountId"] = localStorage.getItem("credlixUserID");
    }
    this.pagesService.editSupplierDetails(obj).subscribe((res) => {
      if (res["status"] == 200) {
        this.pagesService.offSpinner();
        this.activatedRoute.params.subscribe((res) => {
          if (Object.keys(res).length > 0) this.entityID = res["id"];
          this.getSupplierLinks(false);
        });
      }
    });
  }
  changeBulkStatus(value) {
    this.sendSelectedSupplierID = [];
    for (let i = 0; i < this.selectedRows.length; i++) {
      let IDs = this.selectedRows[i].id;
      this.sendSelectedSupplierID.push(IDs);
    }
    let obj = {
      supplierIds: this.sendSelectedSupplierID,
    };
    if (value == "deactivate") {
      obj["isActive"] = false;
    } else if (value == "activate") {
      obj["isActive"] = true;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      height: "250px",
      data: obj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.getSupplierLinks(false);
      }
      this.isButtonEnable = true;
      this.selection = new SelectionModel(true, []);
      this.pagesService.offSpinner();
    });
  }

  addSuppliers() {
    const dialogRef = this.dialog.open(AddSupplierDialogComponent, {
      disableClose: true,
      width: "450px",
      height: "280px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != true) {
        this.getSupplierLinks(false);
      }
    });
  }

  editSupplierDetails(element, buttonRef) {
    //sessionStorage.setItem('supplierProfile/' + element['id'], JSON.stringify(element));
    //this.router.navigateByUrl('/supplierProfile/' + element['id']);
    buttonRef._elementRef.nativeElement.style.visibility = "visible";
    const dialogRef = this.dialog.open(EditdetailsDialogComponent, {
      disableClose: true,
      // width: '500px',
      // height: '540px',
      data: element,
      panelClass: "supplier-editDialog",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isButtonEnable = true;
      buttonRef._elementRef.nativeElement.style.visibility = "inherit";
      if (result == true) {
        this.activatedRoute.params.subscribe((res) => {
          if (Object.keys(res).length > 0) this.entityID = res["id"];
          this.getSupplierLinks(false, res["param1"]);
        });
      }
    });
  }

  colorCodeSet: any;
  iscolorOptionopen = [];

  menuOpened(element, i) {
    this.iscolorOptionopen.push(i);
    this.isColorCodeVisible = true;
    this.selectedSupplierDetails = element;
    if (
      element.config.commentConfig != null &&
      element.config.commentConfig.colorCode != null
    ) {
      this.isColorCodeVisible = false;
      this.colorCode = element.config.commentConfig.colorCode;
      this.Comments = element.config.commentConfig.comment;
      this.iscolorOptionopen[i] = null;
      // this.editDetails(element);
    } else {
      this.colorCode = "red";
    }
  }

  resetComment(element, i) {
    if (this.iscolorOptionopen.length > i) {
      this.iscolorOptionopen = this.iscolorOptionopen.splice(i);
    }
    this.iscolorOptionopen[i] = null;
    this.selectedSupplierDetails = element;
    if (this.selectedSupplierDetails.config.commentConfig != null) {
      this.suplColrObj = {
        supplierId: element.id,
        comment: {
          comment: element.config.commentConfig.comment,
        },
      };
      this.pagesService
        .editSupplierDetails(this.suplColrObj)
        .subscribe((res) => {
          this.iscolorOptionopen = [];
          element.config.commentConfig = this.suplColrObj["colorCode"];
          element.config.commentConfig = this.suplColrObj["comment"];
          this.supplierLinks[i] = element;
          this.dataSource = new MatTableDataSource(this.supplierLinks);
        });
    }
  }

  menuClosed(element, i) {
    if (
      element.config.commentConfig == null ||
      element.config.commentConfig.colorCode == null
    ) {
      this.iscolorOptionopen[i] = null;
      // this.colorCode = 'red';
      this.editDetails(element, i);
    }
  }

  getColorCode(event, element, i) {
    this.colorCodeSet = event;
    this.colorCode = event;
    this.editDetails(element, i);
  }

  editDetails(element, i?) {
    let reset = false;
    if (element.config.commentConfig != null) {
      reset = true;
      this.iscolorOptionopen[i] = null;
      this.suplColrObj = {
        supplierId: element.id,
        comment: {
          colorCode: this.colorCode,
          comment: element.config.commentConfig.comment,
        },
      };
    } else {
      this.suplColrObj = {
        supplierId: element.id,
        comment: {
          colorCode: this.colorCode,
          comment: this.Comments,
        },
      };
    }
    this.pagesService.editSupplierDetails(this.suplColrObj).subscribe((res) => {
      this.iscolorOptionopen = [];
      element.config.commentConfig = this.suplColrObj["comment"];
      this.supplierLinks[i] = element;
      this.dataSource = new MatTableDataSource(this.supplierLinks);
    });
  }
  commentMenu(element) {
    if (element.config.commentConfig == null) {
      this.Comments = "";
    } else {
      this.Comments = element.config.commentConfig.comment;
    }
  }

  editCommentsdetails(element, i) {
    this.suplColrObj = {
      supplierId: element.id,
      comment: {
        comment: this.Comments,
      },
    };
    if (element.config.commentConfig != null) {
      this.suplColrObj["comment"]["colorCode"] =
        element.config.commentConfig.colorCode;
    }
    this.pagesService.editSupplierDetails(this.suplColrObj).subscribe((res) => {
      element.config.commentConfig = this.suplColrObj["comment"];
      this.supplierLinks[i] = element;
      this.dataSource = new MatTableDataSource(this.supplierLinks);
    });
  }

  resetInvoiceFilters() {
    if (this.matRef1) {
      this.matRef1.options.forEach((data: MatOption) => data.deselect());
    }
    this.matRef2.options.forEach((data: MatOption) => data.deselect());
    this.matRef3.options.forEach((data: MatOption) => data.deselect());
    this.matRef5.options.forEach((data: MatOption) => data.deselect());
    this.matRef6.options.forEach((data: MatOption) => data.deselect());
    this.isClearButtonEnable = true;
    this.isApplyButtonEnable = true;
    this.selectedRM = null;
    this.selectedRmName.setValue("");
    this.getSupplierLinks(false);
    sessionStorage.removeItem("requestObject");

    // this.selectedDate = null;
  }
  onSearchChange(value) {
    this.showListing = true;
    if (
      value.trim().length > 1 &&
      (!this.autoSuggestloading ||
        value.trim().length - this.previousSearchtext.length >= 2)
    ) {
      this.previousSearchtext = value.trim();
      this.autoSuggestloading = true;
      let obj = {
        searchTerm: this.searchText,
        type: "supplier",
      };
      this.suggestedInvoices = [];
      this.suggestedSuppliers = [];
      setTimeout(
        function () {
          this.autoSuggestloading = false;
        }.bind(this),
        500
      );
      this.pagesService.autoCompleteSearch(obj).subscribe((data) => {
        this.searchResultListing = data["result"];
        let suppliers = this.searchResultListing.filter((value) => {
          return value.documentType.toLowerCase() == "supplier";
        });
        let invoices = this.searchResultListing.filter((value) => {
          return value.documentType.toLowerCase() == "invoice";
        });
        this.suggestedSuppliers = suppliers;
        this.suggestedInvoices = invoices;
      });
    }
  }
  applySearch(list) {
    this.searchText = list.searchKey;
    if (
      list != null &&
      (list.toString().trim() == "" || list.toString().trim().length < 2)
    ) {
      return;
    }
    if (list != "" && list.hasOwnProperty("searchKey")) {
      this.entityID = list.entityId;
    } else {
      this.entityID = list;
    }
    if (this.searchText != null && this.searchText.trim() == "") {
      this.searchQuery = false;
    } else {
      this.searchQuery = true;
    }
    this.showListing = false;
    this.showCloseIcon = true;
    this.showSearchIcon = false;
    this.getSupplierLinks(true);
  }

  resetSearchText() {
    this.entityID = "";
    this.searchText = null;
    this.showCloseIcon = false;
    this.showSearchIcon = true;
    this.getSupplierLinks(false);
  }

  navigateToEarlyPayment(element) {
    let searchText = sessionStorage.getItem("searchedText");
    sessionStorage.clear();
    if (searchText) {
      sessionStorage.setItem("searchedText", searchText);
    }
    if (this.authService.isJKFennerAnchor()) {
      window.open(
        "/#/earlyPayments/" + element.searchKey + `/${element.id}`,
        "_blank"
      );
    } else {
      window.open("/#/earlyPayments/" + element.searchKey, "_blank");
    }
  }

  addSupplierComments() {
    this.isCommentvisible = true;
  }

  getTitleComments(element) {
    if (element.config.commentConfig != null) {
      this.tooltipComments = element.config.commentConfig.comment;
      return this.tooltipComments;
    }
  }

  getColorCodeCSS(element, i) {
    if (element.config.commentConfig != null) {
      if (element.config.commentConfig.colorCode == null) {
        return "clrGray";
      }
      if (element.config.commentConfig.colorCode == "red") {
        return "clrRed";
      } else if (element.config.commentConfig.colorCode == "orange") {
        return "clrOrange";
      } else if (element.config.commentConfig.colorCode == "blue") {
        return "clrBlue";
      } else if (element.config.commentConfig.colorCode == "green") {
        return "clrGreen";
      } else if (element.config.commentConfig.colorCode == "purple") {
        return "clrPurple";
      }
    }
    if (element.config.commentConfig == null) {
      if (this.iscolorOptionopen.indexOf(i) != -1) {
        return "clrRed";
      } else {
        return "clrGray";
      }
    }
  }

  downloadReconcile(element, value?) {
    let obj = {
      data: element,
    };
    if (value == "SUPPLIER_ANCHOR_WISE_LEDGER_REPORT") {
      obj["isPayout"] = true;
      obj["ledgerName"] = value;
    }
    const dialogRef = this.dialog.open(DownloadReconcileComponent, {
      data: obj,
      width: "700px",
    });
  }
  selectedCheckSort: boolean = false;
  activeItem = "Eligible Invoice- Highest First";
  selectedSortingName: string = "Eligible Invoice- Highest First";
  sortData(event, columnName, direction, sortingName) {
    this.selectedSortingName = sortingName;
    this.activeItem = sortingName;
    if (event != null) {
      this.activeSort = {};
      if (columnName == "eligibleInvoiceValue") {
        this.activeSort["field"] = "elgInvoiceValue";
        this.activeSort["order"] = direction;
      }
      if (columnName == "activeReqValue") {
        this.activeSort["field"] = "actvReqValue";
        this.activeSort["order"] = direction;
      }
      if (columnName == "paymentHoldValue") {
        this.activeSort["field"] = "paymentHoldValue";
        this.activeSort["order"] = direction;
      }
      // this.activeSort['field'] = columnName
      // this.activeSort['order'] = direction;
      if (this.paginator != null) {
        this.paginator.firstPage();
      }
      this.getSupplierLinks(true, undefined, columnName, direction);
    }
  }
  refreshInvoices(element) {
    let credlixuserID;
    if (this.authService.isCredlixUser()) {
      credlixuserID = localStorage.getItem("credlixUserID");
    }
    this.pagesService
      .supplierRefreshInvoices(element.searchKey, credlixuserID)
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.startCountdown(900);
          localStorage.setItem('buttonDisabledTimestamp', Date.now().toString());
          this.toasterService.success("Supplier Invoices Refresh Successfully");
        }
      });
  }

  sendWhatsappMessage(element) {
    const dialogRef = this.dialog.open(SendWhatsappMsgDialogComponent, {
      disableClose: true,
      width: "550px",
      height: "520px",
      data: element,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getColorOption(color) {
    if (color.colorName == "Red") {
      return "selectRed";
    }
    if (color.colorName == "Purple") {
      return "selectPurple";
    }
    if (color.colorName == "Green") {
      return "selectGreen";
    }
    if (color.colorName == "Blue") {
      return "selectBlue";
    }
    if (color.colorName == "Orange") {
      return "selectOrange";
    }
    if (color.colorName == "No Color") {
      return "selectblank";
    }
  }

  supplierReportName: string;
  downloadSupplierInvoices() {
    const dialogRef = this.dialog.open(ReportDownloadInvoiceComponent, {
      disableClose: true,
      width: "550px",
      height: "250px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != true) {
        this.supplierReportName = result;
        this.downloadSuppliers();
      }
    });
  }
  downloadSuppliers() {
    if (this.supplierReportName == undefined) {
      this.reqobj["reportName"] = "Supplier Report";
    } else {
      this.reqobj["reportName"] = this.supplierReportName;
    }
    this.pagesService
      .downloadSupplierInvoices(this.reqobj)
      .toPromise()
      .then((res) => {
        this._snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 5000,
          verticalPosition: this.verticalPosition,
          panelClass: "snack-class",
        });
      })
      .catch((error) => console.log(error));
  }

  copyEmailID(email: string) {
    // let selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = email;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    email = email ? email : "";
    window.navigator["clipboard"].writeText(email);
  }

  openSupplierDetails(row, buttonRef, status, list?) {
    if (status == "true")
      buttonRef._elementRef.nativeElement.style.visibility = "visible";
    else buttonRef._elementRef.nativeElement.style.visibility = "inherit";
  }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent("clicked");
    sessionStorage.clear();
    this.router.navigate(["/supplierLinks"]);
  }

  checkNavigation() {
    let isNavigation = sessionStorage.getItem("isNavigation");
    if (isNavigation) return true;
    else return false;
  }

  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      result = (val / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000) {
      result = (val / 1000).toFixed(2) + " K";
    }
    return result || val;
  }

  setColorCode(filterData) {
    this.isFiltersShow = false;
    this.isApplyButtonEnable = false;
    this.isClearButtonEnable = false;
    this.matRef1.options.forEach((data: MatOption) => {
      filterData["biztype"].filter((res) => {
        if (res["id"] == data["value"]["id"]) {
          data.select();
        }
      });
    });
    this.matRef2.options.forEach((data: MatOption) => {
      if (filterData["colors"].includes(data["value"])) data.select();
    });
    this.matRef3.options.forEach((data: MatOption) => {
      if (filterData["status"].includes(data["value"])) data.select();
    });
  }

  navigateToLeadsPage(data) {
    let businessID = [];
    if (data == "Add to Leads") {
      this.selectedRows.map((val) => {
        businessID.push(val["id"]);
      });
    } else {
      businessID.push(data["id"]);
    }
    this.settingService.addToLeads(businessID).subscribe((res) => {
      if (res["status"] == 200) {
        this.toasterService.success(res["result"][businessID[0]]);
        this.router.navigate(["/leads"]);
      }
    });
  }
  // datesUpdated(event) {
  //   if (event.startDate != null && event.endDate != null) {
  //     let startDateMilli = event.startDate._d
  //     this.startDateRange = moment(startDateMilli).format('YYYY-MM-DD');
  //     let endDateMilli = event.endDate._d
  //     this.endDateRange = moment(endDateMilli).format('YYYY-MM-DD');
  //   }
  // }
  getRmList() {
    this.pagesService.getRMList().subscribe((res) => {
      this.usersRMList = res["result"];
      this.getRmUserList();
    });
  }
  getRmUserList() {
    this.filteredRMList = this.selectedRmName.valueChanges.pipe(
      startWith(null),
      map((userValue: string | null) =>
        userValue ? this._filterUserList(userValue) : this.usersRMList.slice()
      )
    );
  }
  private _filterUserList(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.usersRMList.filter(
      (user) => user.userName.toLowerCase().indexOf(filterValue) >= 0
    );
  }
  onRMChange(selectedUser) {
    this.selectedRM = selectedUser.userId;
  }
  onSelectStage(value) {
    this.selectedStage = value;
    this.selectedSubStage = null;
    this.stageArray.forEach((val) => {
      if (val.stage == value) {
        this.subStageArray = val.subStage;
      }
    });
  }
  onSelectSubStage(value) {
    this.selectedSubStage = value;
  }
  openCommentTab(element) {
    const dialogRef = this.dialog.open(CommentsTabComponent, {
      disableClose: false,
      width: "30%",
      height: "100%",
      position: { bottom: "0px", right: "0px" },
      data: {
        laneTask: element,
        earlyPayment: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  startCountdown(duration: number) {
    this.isRefreshButtonDisable = true;
    this.remainingTime = duration;

    this.countdownInterval = setInterval(() => {
      this.remainingTime--;

      if (this.remainingTime <= 0) {
        this.isRefreshButtonDisable = false;
        localStorage.removeItem('buttonDisabledTimestamp');
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes + 'min'}:${secs < 10 ? '0' : ''}${secs + 'sec left'}`;
  }

  ngOnDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}
