import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/settings/setting.service';
import { saveAs } from 'file-saver';
import { importConfig } from '../../../constants/import-config-data'
@Component({
  selector: 'app-buyer-listing',
  templateUrl: './buyer-listing.component.html',
  styleUrls: ['./buyer-listing.component.scss']
})
export class BuyerListingComponent implements OnInit {

  displayedColumns = [];
  buyerInvoices = [];
  displayedColumns1 = [];
  dataSource = new MatTableDataSource(this.buyerInvoices);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: any = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  selection = new SelectionModel(true, []);
  isFiltersShow: boolean = true;
  selectedDate: any;
  customDateOption = null;
  timeSlot: number;
  startDate: any;
  endDate: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  setfilterValue: any;
  isClearButtonEnable: boolean = true;
  isApplyButtonEnable: boolean = true;
  borrowerStats: any;
  arrConfig: any = [];
  anchorDisplayName: string;
  isLead: boolean = false;
  paramType: string;
  searchTextedId:string;

  constructor(private pagesService: PagesService, private router: Router, public dialog: MatDialog,
    public authService: AuthService, private toasterService: ToastrService,
    public settingService: SettingService, private activatedRoute: ActivatedRoute) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {
    this.searchTextedId = sessionStorage.getItem('searchTextedId');
    this.activatedRoute.params.subscribe(params=>{
      if(params.param1){
        this.paramType = params.param1;
      }
    })
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.anchorDisplayName = getSessionStorage['accountName'];
    if (getSessionStorage['credlixAnchorList']) {
      getSessionStorage['credlixAnchorList'].map(val => {
        if (val.id == localStorage.getItem(importConfig.masterAccountID)) {
          this.anchorDisplayName = val.displayName;
        }
      })
    }
    if (this.authService.isLenderLeads()) {
      this.isLead = true;
    }
    if(this.paramType){
      this.getDataForglobalSearch();
    } else if(this.searchTextedId){
    this.getDataForglobalSearch(this.searchTextedId);
    }else{
      this.getPaginatorData();
    }
    this.getBorrowerStats();
  }

  getDataForglobalSearch(searchTextedId?){
    let reqObj = {
      "size": this.pageSize,
      "from": 0,
      "where": {},
      "sort": {
          "field": "lastModified",
          "order": "desc"
      },
      "indexName" : "credlix_buyers"
  }
  if(!searchTextedId){
        reqObj["where"]['buyer'] =
        {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "searchKey",
              "value": this.paramType
            },
            {
              "field": "businessName",
              "value": this.paramType
            }
          ]
        }
  }else{
    reqObj['where']['id'] = {
      type: "search",
      value: this.searchTextedId
    }
  }

  if(localStorage.getItem('credlixUserID')){
    reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
  }

  this.pagesService.getBuyersDetails(reqObj).subscribe(res=>{
    if (res['result']) {
      res['result'].map(val => {
        if (val['isActive'] == false) {
          val['isActive'] = "Inactive"
        }
        else {
          val['isActive'] = "Active"
        }
      })
      this.dataSource.data = res['result'];
      this.totalRecords = res['total'];
    }
    else {
      this.dataSource.data.length = 0;
    }
    this.paginator.length = this.totalRecords;
    this.arrConfig = res['fieldConfig'];
    this.createHeaders(this.arrConfig);
  })
  }

  getBorrowerStats() {
    this.settingService.getAllStats(this.isLead).subscribe(res => {
      this.borrowerStats = res['result'];
    })
  }

  //creating columns dynamically for buyer table
  createHeaders = (config) => {
    if (config) {
      this.displayedColumns1 = [];
      this.displayedColumns = [];
      config.map((each) => {
        this.displayedColumns1.push(each.field);
        each['cell'] = (element: any) => {
          if (each.field == 'lenders' && element[each.field]) {
            let lenderStr;
            if (element[each.field].length == 0) {
              lenderStr = '0 Lenders';
            }
            else if (element[each.field].length == 1) {
              lenderStr = element[each.field][0]['name'];
            }
            else if (element[each.field].length > 1) {
              lenderStr = element[each.field].length + ' Lenders';
            }
            return lenderStr;
          }
          else {
            return _.get(element, each.field);
          }
        }
      })
      this.displayedColumns1.push('Action');
      this.displayedColumns = config;
    }
  }

  getPaginatorData() {
    let reqObj = {
      "size": 50,
      "from": this.paginator.pageIndex,
      "where": {},
	    "sort": {
        "field": "lastModified",
        "order": "desc"
    },
	  "indexName":"credlix_buyers"
    }
    if(localStorage.getItem('credlixUserID')){
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    this.pageNumber = this.paginator.pageIndex;
    this.pagesService.getBuyersDetails(reqObj).subscribe(res => {
      if (res['result']) {
        res['result'].map(val => {
          if (val['isActive'] == false) {
            val['isActive'] = "Inactive"
          }
          else {
            val['isActive'] = "Active"
          }
        })
        this.dataSource.data = res['result'];
        this.totalRecords = res['total'];
      }
      else {
        this.dataSource.data.length = 0;
      }
      this.paginator.length = this.totalRecords;
      this.arrConfig = res['fieldConfig'];
      this.createHeaders(this.arrConfig);
    })
  }

  getBuyerInvoice(row, status) {
    if (status === "searchKey" && this.anchorDisplayName == "VEDANTA") {
      this.router.navigate(['/bankLists/' + row.id]);
    }
    else if ((status === "searchKey" && this.anchorDisplayName == "MSAF") || this.isLead) {
      this.router.navigate(['/financeListing/' + row.searchKey + '/' + row.businessName]);
    }

  }

  //setting css
  getClass(status, data) {
    if (status === "searchKey") {
      return "overtext";
    }
    else if (status === "isActive") {
      if (data[status] == "Active")
        return 'statustrue status';
      else
        return 'statusfalse status';
    }
    else {
      return '';
    }
  }

  CopyemailID(inputElement) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = inputElement;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  downloadBuyerSheet() {
    let masterAccountId;
    if (this.authService.isCredlixUser()) {
      masterAccountId = localStorage.getItem('credlixUserID');
    }
    else {
      let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
      masterAccountId = getLocalStorage['role']['masterAccount']['id'];
    }
    this.pagesService.downloadBuyerSheet(masterAccountId).subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      result = result.replace(/"/g, '')
      saveAs(res.body, result);
      return result.replace(/"/g, '');
    })
  }

  editDetails(element, buttonRef){
    this.router.navigateByUrl('/supplierProfile/' + element['id']);
  }

  // openSupplierDetails(row, buttonRef, status, list?) {
  //   if (status == 'true')
  //     buttonRef._elementRef.nativeElement.style.visibility = "visible"
  //   else
  //     buttonRef._elementRef.nativeElement.style.visibility = "inherit"
  // }

  // openRepayments(data) {
  //   this.router.navigate(['/repayments/' + data.searchKey + '/' + data['lenders'][0]['lenderId']]);
  // }

  // generateLink(row) {
  //   let obj = {
  //     email: row.userEmail
  //   }
  //   this.authService.forgotPassword(obj).subscribe(res => {
  //     if (res['result'] == true) {
  //       this.pagesService.offSpinner();
  //       this.toasterService.success("Resend link sent to Your email");
  //     }
  //     else {
  //       this.toasterService.error("Something went wrong");
  //     }
  //   })
  // }

}
