import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption, MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import * as lodash from 'lodash';

@Component({
  selector: 'app-payments-review',
  templateUrl: './payments-review.component.html',
  styleUrls: ['./payments-review.component.scss']
})
export class PaymentsReviewComponent implements OnInit {
  _: any = lodash;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  displayedColumns = ['invoiceID', 'paymentTo', 'partyType', 'status', 'utr', 'paymentDate', 'transactionAmount', 'paymentType', 'accountTo', 'downAction'];
  paymentListRes = [];
  escrowID: any;
  pageSize: number = 50;
  totalRecords: number = 0;
  pageNumber: number = 0;
  verifedRes: any;
  isAcceptPay: string;
  paymentSubscibeData: any;
  fileID: any;
  searchPaymentID: string;
  selectedPaymentStatus = [];
  selectedPartyType: any;
  setPaymentStatus;
  setPartyType;
  getTabIndex: any;
  showSearchIcon: boolean = true;
  ShowCloseIcon: boolean = false;
  storeFilterState: any;
  isApplyButtonEnable: boolean = false;
  isClearButtonEnable: boolean = false;
  isInvoiceFilterVisible: boolean = false;
  partyType: any = [
    { id: '1', name: 'Lender', value: 'lender' },
    { id: '2', name: 'Supplier', value: 'supplier' },
  ];
  paymentStatus: any = [
    { id: '1', name: 'Pending', value: 'PENDING' },
    { id: '2', name: 'Initiated', value: 'INITIATED' },
    { id: '3', name: 'Paid', value: 'PAID' },
    { id: '4', name: 'Failed', value: 'FAILED' }

  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public settingService: SettingService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.getTabIndex= localStorage.getItem('tabIndex');
    this.settingService._isFilterClickSubjectBehv.subscribe(res => {
      if(res== true){
        if(this.getTabIndex==2){
          this.isInvoiceFilterVisible= true;
        }
      }
      else{
        this.isInvoiceFilterVisible= false;
      }
    })
  }

  ngOnInit() {
    this.dataSource.data = [];
    this.activatedRoute.params.subscribe(params => {
      if (params.id != null) {
        this.fileID = params.id
        this.isAcceptPay = localStorage.getItem("acceptPaymentClicked");
        if (this.isAcceptPay == 'yes') {
          this.verifyPayment(params.id);
        }
        else {
          let getsessionRes = JSON.parse(sessionStorage.getItem("paymentRes"));
          if (getsessionRes != null || getsessionRes != undefined) {
            this.dataSource.data = getsessionRes['result'];
            this.totalRecords = getsessionRes['total'];
            this.storeFilterState = JSON.parse(sessionStorage.getItem('paymentFilterState'));
            if (this.storeFilterState != null || this.storeFilterState != undefined) {
              this.isApplyButtonEnable = false;
              this.isClearButtonEnable = false;
              if (this.storeFilterState['where'].hasOwnProperty('status')) {
                this.setPaymentStatus = this.storeFilterState['where']['status']['value']
                this.selectedPaymentStatus = this.setPaymentStatus
              }
              if (this.storeFilterState['where'].hasOwnProperty('paymentType')) {
                if (this.storeFilterState['where']['paymentType'].clause == 'in') {
                  this.setPartyType = 'lender'
                  this.selectedPartyType= this.setPartyType
                }
                else {
                  this.setPartyType = 'supplier'
                  this.selectedPartyType= this.setPartyType
                }
               
              }
              if (this.storeFilterState['where'].hasOwnProperty('documentNumber')) {
                this.searchPaymentID = this.storeFilterState['where']['documentNumber']['value']
              }
            }
          }
          else {
            this.getFilePaymentTransaction(params.id);
          }
        }
      }
    });
  }

  verifyPayment(id?) {
    let obj = {}
    if (this.escrowID == '') {
      obj['fileId'] = id
    }
    else {
      obj['fileId'] = this.escrowID
    }
    this.settingService.verifyAndAcceptPayment(id).subscribe(res => {
      if (res['status'] == 200) {
        this.verifedRes = res['result'].id
        this.getFilePaymentTransaction(res['result'].id);
        this.settingService.getPayoutElement(res['result']);
      }
    })
  }

  getPaginatorData() {
    this.getFilePaymentTransaction(true);
  }
  isLender:string;
  getFilePaymentTransaction(value?) {
    let reqObj = {
      "where": {
        "transactionFile": {
          "type": "search",
          "value": this.fileID
        }
      }
    }
    if (this.searchPaymentID != null && this.searchPaymentID.trim() != '') {
      reqObj['where']['documentNumber'] = {
        "type": "search",
        "value": this.searchPaymentID.trim()
      }
    }
    if (this.selectedPaymentStatus != null && this.selectedPaymentStatus.length > 0) {
      reqObj['where']['status'] = {
        "type": "in",
        "value": this.selectedPaymentStatus
      }
    }
    if (this.selectedPartyType != null && this.selectedPartyType.length > 0) {
      if(this.selectedPartyType== 'lender'){
        this.isLender = 'in' 
      }
      else if(this.selectedPartyType== 'supplier'){
        this.isLender= 'nin'
      }
      reqObj['where']['paymentType'] = {
        "type": "search",
        "operator": "and",
        "clause": this.isLender,
        "value":[{
          "field": 'paymentType',
          "value": "LENDER_REPAYMENT"
        }]
      }
    }
    if (this.paginator != null && value == false) {
      this.paginator.pageIndex = 0;
      reqObj['from'] = this.paginator.pageIndex * 50
    }
    else {
      reqObj['from'] = this.paginator.pageIndex * 50
    }
    reqObj['size'] = this.pageSize
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    sessionStorage.setItem("paymentFilterState", JSON.stringify(reqObj));
    this.settingService.getFilePaymentTransaction(reqObj).subscribe((res: any) => {
      this.paymentListRes = res;
      if (this.paymentListRes['result'] != null) {
        this.dataSource.data = this.paymentListRes['result'];
        this.totalRecords = res['total'];
        this.settingService.getTotalPaymentTrans(this.totalRecords)
        sessionStorage.setItem("paymentRes", JSON.stringify(res));
      }
      else{
        this.dataSource.data=[]
      }
    })
  }

  // checkFilterButtonCondition(){
  //   if(this.searchPaymentID != '' ){
  //     this.showSearchIcon= false;
  //     this.ShowCloseIcon= true;
  //   }
  //   if(this.searchPaymentID != null || this.selectedPaymentStatus.length != null || this.selectedPartyType !=0){
  //     this.isApplyButtonEnable= false;
  //     this.isClearButtonEnable= false;
  //   }
  // }
  resetSearchText(){
    this.searchPaymentID = '';
    this.ShowCloseIcon = false;
    this.showSearchIcon = true;
    this.getFilePaymentTransaction(false);
  }
  formatPaymentType(value) {
    return this._.startCase(value);
  }

  getPaymentStatus(event) {
    this.selectedPaymentStatus = event;
  }
  getPaymentPartyType(event) {
    this.selectedPartyType = event;
  }
  clearPaymentFilters(){
    this.searchPaymentID="";
    this.setPaymentStatus=''
    this.setPartyType=''
    this.selectedPartyType=''
    this.selectedPaymentStatus=[]
    this.showSearchIcon= true;
    this.ShowCloseIcon= false;
    this.getFilePaymentTransaction(false);
  }
  getAccountToolTip(element){
    let values= ` ${element.bankDetails.recipientName}
    ${element.bankDetails.accountNumber}
      ${element.bankDetails.ifscCode}
      ${element.bankDetails.bankName}
     ${element.bankDetails.branch}`;
    return values;

  }
  getStatusColor(element){
    if(element =="FAILED"){
      return 'failColor'
    }
    else if(element =="PENDING"){
      return 'pendingColor'
    }
    else if(element =="PAID"){
      return 'paidColor'
    }
    else if(element =="INITIATED"){
      return 'initiatedColor'
    }

  }
}
