<div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
  <div class="listingFilters" *ngIf="isInvoiceFilterVisible">
    <div class="search searchBoxtop cusSearch input-btn" fxLayout="row" >
      <input type="text" fxFlex placeholder="Search Invoice ID" [(ngModel)]="searchInvoiceID"
        (keyup.enter)="getInvoicesPayment(false)" (keydown.space)="$event.preventDefault();" (input)="checkInputText(searchInvoiceID)">
      <button class="searchbtn">
        <i class="ri-search-line" (click)="getInvoicesPayment()" *ngIf="showSearchIcon"></i>
        <i class="ri-close-line" *ngIf="showCloseIcon" (click)="resetSearchText(searchInvoiceID)"></i>
      </button>
    </div>
    <div class="searchBoxtop">
    <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
      <mat-label>Status</mat-label>
      <mat-select #matRef multiple (selectionChange)="getInvoiceStatus($event.value)" [(ngModel)]="setInvoiceStatus">
        <mat-option *ngFor="let type of invoiceStatus" [value]="type.value">{{type.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>

    <div class="searchBoxtop">
      <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
        <mat-label>Payment Type</mat-label>
        <mat-select #matRef1 multiple (selectionChange)="getPaymentType($event.value)" [(ngModel)]="setPaymentType">
          <mat-option *ngFor="let type of paymentType" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    <div class="leftfilteraction">
      <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable"
        (click)="getInvoicesPayment(false)">Apply</button>
      <button mat-flat-button class="resetbtn" #button [disabled]="isClearButtonEnable"
        (click)="clearInvoiceFilters()">Clear
        All</button>
    </div>
  </div>
  <div>
<div class="mat_table" fxFlex="100">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table_invoice invoice suplierTable " 
  [hidden]="!(dataSource.data?.length>0)">

  <!-- Invoice Details Column -->
  <ng-container matColumnDef="InvoiceDetails">
    <th colspan="13" mat-header-cell *matHeaderCellDef> Invoice Details</th>
  </ng-container>

  <!-- 	Escow Details Column -->
  <ng-container matColumnDef="EscowDetails">
    <th colspan="4" mat-header-cell *matHeaderCellDef> 	Escow Details </th>
  </ng-container>

  <!-- 	Outward Transaction Breakup Column -->
  <ng-container matColumnDef="OutwardTransactionBreakup">
    <th colspan="3" mat-header-cell *matHeaderCellDef> 	Outward Transaction Breakup</th>
  </ng-container>

  <ng-container matColumnDef="invoiceNumber">
    <th mat-header-cell *matHeaderCellDef> Invoice ID </th>
    <td mat-cell *matCellDef="let element" [matTooltip]="element.invoiceNumber"> {{element.invoiceNumber}}
    </td>
  </ng-container>

    <ng-container matColumnDef="suppID">
      <th mat-header-cell *matHeaderCellDef> Supplier ID </th>
      <td mat-cell *matCellDef="let element"> {{element.supplierId}}
      </td>
    </ng-container>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="outline" *ngIf="element.paymentType=='EP_PAYMENT'|| element.paymentType =='MARGIN_PAYMENT' || element.paymentType =='LENDER_REPAYMENT' " (click)="requestPayment(element)">
          help_outline</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="isEPRequested">
      <th mat-header-cell *matHeaderCellDef> EP Requested </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.isEPRequested">Yes</span>
        <span *ngIf="!element.isEPRequested">No</span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="invoiceAmt">
      <th mat-header-cell *matHeaderCellDef> Invoice Amount</th>
      <td mat-cell *matCellDef="let element"> 
       <span *ngIf="element.documentAmount != null"> {{element.documentAmount | number}}</span>
       <span *ngIf="element.documentAmount == null"> -</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="deductions">
      <th mat-header-cell *matHeaderCellDef> Deductions</th>
      <td mat-cell *matCellDef="let element"> 
       <span *ngIf="element.deductions != null"> {{element.deductions | number}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amountPaid">
      <th mat-header-cell *matHeaderCellDef> Amount Paid</th>
      <td mat-cell *matCellDef="let element"> 
       <span *ngIf="element.amountPaid != null"> {{element.amountPaid | number}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="dueToSupplier">
      <th mat-header-cell *matHeaderCellDef> Due To Supplier</th>
      <td mat-cell *matCellDef="let element"> {{element.dueToSupplier | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="dueToLender">
      <th mat-header-cell *matHeaderCellDef> Due To Lender</th>
      <td mat-cell *matCellDef="let element"> {{element.dueToLender | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="dueToCredlix">
      <th mat-header-cell *matHeaderCellDef> Due to Credlix</th>
      <td mat-cell *matCellDef="let element"> {{element.dueToCredlix | number}}
      </td>
    </ng-container>
    
    <ng-container matColumnDef="epAmount">
      <th mat-header-cell *matHeaderCellDef> EP Amount</th>
      <td mat-cell *matCellDef="let element"> {{element.epAmount | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="lenderName">
      <th mat-header-cell *matHeaderCellDef> Lender</th>
      <td mat-cell *matCellDef="let element"> {{element.lenderName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentType">
      <th mat-header-cell *matHeaderCellDef>Payment Type
      </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.paymentType=='JOURNAL_PAYMENT'">Misc. Payment</span>
        <span *ngIf="element.paymentType !='JOURNAL_PAYMENT'"> 
          {{formatPaymentType(element.paymentType | titlecase)}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amountReported">
      <th mat-header-cell *matHeaderCellDef> Amount Reported</th>
      <td mat-cell *matCellDef="let element"> {{element.amountReported | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amountInEscrow">
      <th mat-header-cell *matHeaderCellDef>  Amount In Escrow</th>
      <td mat-cell *matCellDef="let element"> {{element.entryEscrowMatch.amount | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status" >
      <th mat-header-cell *matHeaderCellDef> Status In Escrow </th>
      <td mat-cell *matCellDef="let element"> 
        <span class="status-dot" [ngClass]="getStatusColor(getRowStatus(element))"> ● </span>
        <span [matTooltip]="getTooltipElement(element)">{{getRowStatus(element)}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="escrowUTR">
      <th mat-header-cell *matHeaderCellDef>  Escrow UTR</th>
      <td mat-cell *matCellDef="let element"> 
        <span> {{_.defaultTo(element.entryEscrowMatch.matchedUtr, "-")}}</span>
      
      </td>
    </ng-container>

    <ng-container matColumnDef="payToLender">
      <th mat-header-cell *matHeaderCellDef>  Payment to Lender</th>
      <td mat-cell *matCellDef="let element"> {{element.payToLender | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="payToSupplier">
      <th mat-header-cell *matHeaderCellDef>  Payment to Supplier</th>
      <td mat-cell *matCellDef="let element"> {{element.payToSupplier | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="payToCredlix">
      <th mat-header-cell *matHeaderCellDef>  Payment to Credlix</th>
      <td mat-cell *matCellDef="let element"> {{element.payToCredlix | number}}
      </td>
    </ng-container>


    <!-- <ng-container matColumnDef="dueDate" >
      <th mat-header-cell *matHeaderCellDef> Due Date </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.dueDate != null">{{element.dueDate | date}}</span> 
        <span *ngIf="element.dueDate == null">-</span> 
      </td>
    </ng-container>
    <ng-container matColumnDef="paidEarly">
      <th mat-header-cell *matHeaderCellDef>Paid Early</th>
      <td mat-cell *matCellDef="let element"> {{element.toBePaidEarly}}
      </td>
    </ng-container> -->

    <!-- <ng-container matColumnDef="lenderDue">
      <th mat-header-cell *matHeaderCellDef>Due to lender
      </th>
      <td mat-cell *matCellDef="let element"> {{element.lenderAmount | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="supplierDue">
      <th mat-header-cell *matHeaderCellDef>Due to supplier
      </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.dueToSupplier | number}}
      </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="credlixDue">
      <th mat-header-cell *matHeaderCellDef>Due to Credlix
      </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.credlixCommission}}
      </td>
    </ng-container>
    <ng-container matColumnDef="totalDue">
      <th mat-header-cell *matHeaderCellDef>Total Due
      </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.totalDue | number}}
      </td>
    </ng-container> -->

    <!-- <ng-container matColumnDef="paymentReported">
      <th mat-header-cell *matHeaderCellDef>Payment Reported
      </th>
      <td mat-cell *matCellDef="let element"> 
      <span *ngIf="element.escrowEntry !=null"> {{((element.escrowEntry.mappedAmount + element.escrowEntry.transactionAmount) | number)}}
      </span>
      <span *ngIf="element.escrowEntry == null"> -
      </span>
      </td>
    </ng-container> -->
    
    <!-- <ng-container matColumnDef="paymentType">
      <th mat-header-cell *matHeaderCellDef>Payment Type
      </th>
      <td mat-cell *matCellDef="let element"> 
        <span *ngIf="element.paymentType=='JOURNAL_PAYMENT'">Misc. Payment</span>
        <span *ngIf="element.paymentType !='JOURNAL_PAYMENT'"> {{formatPaymentType(element.paymentType | titlecase)}}</span>
      </td>
    </ng-container> -->
    <tr  mat-header-row  *matHeaderRowDef="['InvoiceDetails', 'EscowDetails','OutwardTransactionBreakup']"
    class="example-second-header-row">
   </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  
</div>
</div>
  <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" (page)="getPaginatorData()" showFirstLastButtons>
  </mat-paginator>
  <h5 class="text-p-wrap" *ngIf="dataSource.data.length==0">No Record Found</h5>
</div>
