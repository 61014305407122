import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-early-payments-listing-doc-view',
  templateUrl: './early-payments-listing-doc-view.component.html',
  styleUrls: ['./early-payments-listing-doc-view.component.scss']
})
export class EarlyPaymentsListingDocViewComponent implements OnInit {
  selectedRecordsArray: any;
  documentList: any;
  getSessionSupplierID: any;
  getInvoiceIDByURl: any;
  isCustomer: boolean;
  tdsAmount: any;
  amountWithTDS: any;

  constructor(public pageService: PagesService, public authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private settingService: SettingService,) {
    this.activatedRoute.params.subscribe(params => {
      console.log(params);
      this.getInvoiceIDByURl = params;
    });
  }


  ngOnInit() {
    this.settingService.selectAccount.next(true);
    this.pageService.isChangeColor("EP_white");
    this.pageService.getDocumentListByURl(this.getInvoiceIDByURl).subscribe((list: any) => {
      console.log("cehck", list);
      if (list['result']['tdsAmount'] && list['result']['amountWithTDS']) {
        this.tdsAmount = list['result']['tdsAmount'];
        this.amountWithTDS = list['result']['amountWithTDS'];
      }
      this.pageService.offSpinner();
      this.documentList = list['result'];
      this.selectedRecordsArray = list['result'].items;
    })
    this.getselectedRecord();
    this.getSessionSupplierID = sessionStorage.getItem("supplierID");
    if (this.authService.getIsCusotmer()) {
      this.isCustomer = true;
    }
  }
  breadcrumNavigation() {
    this.router.navigate(['/supplierLinks']);
  }


  getselectedRecord() {
    this.pageService.subjectBehav.subscribe((data: any) => {
      this.documentList = data;
      this.selectedRecordsArray = data.items;
    })
  }


  backtoDocuments() {
    this.router.navigate(['/earlyPayments/' + this.getSessionSupplierID])
  }

}
