<div class="eximFilterDialog">
  <div class="headItem">
    <span class="text">
      <span class="material-icons"> filter_list </span>
      Filters
    </span>
    <span class="close" (click)="closeDialog()">
      <i class="ri-close-line"></i>
    </span>
  </div>
  <div class="content-div">
    <div>
      <h5>Lead Source </h5>
    </div>
    <div class="data-div">
      <div class="fst select-disbursement" style="display: flex;">
        <div class="request-date-wrapper">
          <span class="lbl mb-3">Select Source</span>
          <mat-form-field appearance="fill" class="dash-selct mt-2 disburse">
            <mat-select (selectionChange)="onSourceChange($event.value)" placeholder="Select Anchor" [(ngModel)]="selectedSource">
              <mat-option *ngFor="let source of sourceList" [value]="source">{{source.replace('_', ' ')}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="actionButton">
      <button mat-raised-button class="cnclbtn" (click)="resetFilters()">Reset</button>
      <button mat-raised-button class="savebtn" (click)="applyFilters()">Apply Filter</button>
    </div>
</div>