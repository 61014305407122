<div class="userManagementParent">

  <div class="right-outer">
    <div class="right">
      <span class="userLable"> {{boardName | titlecase}} </span>
      <span class="material-icons right_ico"> chevron_right </span>
      <span class="userLable"> Settings </span>
      <span class="material-icons right_ico"> chevron_right </span>
      <span class="userLable"> User Management </span>
    </div>

    <div class="right-2">
      <!-- <button class="btnCancel">
        Cancel
      </button> -->
      <button class="btnSave" (click)="saveMappedLane()">
        Save
      </button>
    </div>
  </div>


  <div class="userlistOuter">

    <div class="userListLeft">

      <h3 class="listHead">
        User List
      </h3>

      <ul>
        <li class="userListItem " *ngFor="let user of userList let i = index" [ngClass]="{'active': selectedItem == user.userId}"
          (click)="onListSelectionChange(user.userId)">
          <span class="listItemHead"> {{user.userName}}</span>
          <span class="material-icons right_ico"> chevron_right </span>
        </li>
      </ul>
    </div>

    <div class="userListRight">
      <div class="cardAcess">
        <h3 class="listHead">Card Access</h3>
        <mat-radio-group class="suplierRadbtn" (change)="setAccessLaneType($event.value)" [(ngModel)]="bindAccessLaneType">
        <mat-radio-button value="ALL">View all cards          
        </mat-radio-button>
        <label>Can view all cards across this board, if has at-least view access of the particular lane.</label>
        <mat-radio-button value="ASSIGNED">View only assigned/watched cards
        </mat-radio-button>
        <label>Can view only those cards for which added as assignee or a watcher, and has at-least view access of the particular lane.</label>
      </mat-radio-group>

      </div>
      <h3 class="listHead">
        Lane Access
      </h3>
      <ul>
        <li class="userListItemMainHead">
          <span class="listItemMainHead"> Lanes Name</span>
          <span class="listItemMainHead"> Permission</span>
        </li>
        <li class="userListItem" *ngFor="let lane of boardLane; let i=index">
          <span class="listItemHead"> {{lane?.laneDTO.name}}</span>
          <mat-form-field appearance="fill" class="k-user-managment">

            <mat-select [(ngModel)]="selectedValue[i]" name="permission" (selectionChange)="matchLane($event.value, i)">
              <mat-option *ngFor="let permission of permissions" [value]="permission.value">
                {{permission.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
      </ul>
    </div>


  </div>

</div>