import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, RemoveUnderscorePipe } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from '../app/appInterceptor';
import { SharedModulesModule } from '../app/shared-files/shared-modules/shared-modules.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth/auth.guard';
import { DocumentComponent } from './pages/pages-init/document/document.component';
import { HomeComponent } from './components/home/home.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LandingComponent } from './components/landing/landing.component';
import { LOCALE_ID } from '@angular/core';
import localeIN from '@angular/common/locales/en-IN'
import { registerLocaleData } from '@angular/common'
import { DocListingComponent } from './components/doc-listing/doc-listing.component';

registerLocaleData(localeIN);
import { NumbervalidationDirective } from './component/suppliers/listing/editdetails-dialog/numbervalidation.directive';
import { MAT_HAMMER_OPTIONS, MatIconModule } from '@angular/material';
import { GETCONSTANT } from './constants/getConstant';
import { GlobalFiltersComponent } from './globalFilters/global-filters/global-filters.component';
import { DirectiveModule } from './directive/directive.module';
import { EarlyPaymentsModule } from './component/early-payments/early-payments.module';
import { SuppliersModule } from './component/suppliers/suppliers.module';
import { ChannelFinancingModule } from './component/channel-financing/channel-financing.module';
import { LenderLeadsModule } from './component/lender-leads/lender-leads.module'
import { CommissionInvoicesModule } from './component/commission-invoices/commission-invoices.module';
import { EscrowAccountModule } from './component/escrow-account/escrow-account.module';
import { CreatePayoutModule } from './component/escrow-account/create-payout/create-payout.module';
import { CodeInputModule } from 'angular-code-input';
import { DashboardModule } from './component/dashboard/dashboard.module';
import { CredlixBoardModule } from './component/credlix-board/credlix-board.module'
import { AccountAuthorizationComponent, RemoveUnderscore, } from './auth/account-authorization/account-authorization/account-authorization.component';
import { SupplierDiscountDialogComponent } from './auth/supplier-discount-dialog/supplier-discount-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PipeModule } from './pipe/pipe.module';
import { ApiService } from './apiServices/api.service';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
// import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    DocumentComponent,
    HomeComponent,
    LandingComponent,
    DocListingComponent,
    AccountAuthorizationComponent,
    NumbervalidationDirective,
    SupplierDiscountDialogComponent,
    RemoveUnderscore,
    GlobalFiltersComponent, RemoveUnderscorePipe ,PageNotFoundComponent],

  imports: [
    BrowserModule,
    DirectiveModule,
    AppRoutingModule,
    PipeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModulesModule,
    FormsModule,
    ReactiveFormsModule,
    EarlyPaymentsModule,
    SuppliersModule,
    LenderLeadsModule,
    DashboardModule,
    CredlixBoardModule,
    ChannelFinancingModule,
    CommissionInvoicesModule,
    EscrowAccountModule,
    CreatePayoutModule,
    MatBadgeModule,
    MatIconModule,
    CodeInputModule,
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    }),
  ],

  entryComponents: [SupplierDiscountDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true
    // },
    {
      provide: LOCALE_ID, useValue: 'en-IN'
    },
    {
      provide: MAT_HAMMER_OPTIONS,
      useValue: {
        cssProps: {
          userSelect: true
        }
      },
    },

    [AuthGuard, GETCONSTANT, GlobalFiltersComponent, ApiService]],

  bootstrap: [AppComponent]
})
export class AppModule { }
