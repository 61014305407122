<div class="paymnt">
  <div style="font-size: 18px;">
    <span>Sending OTP</span>
  </div>

  <div *ngIf="isResend == true" style="text-align: center;margin-top: 20%;color: #1479FC;">
    <label (click)="resendOTP()" [ngClass]="isResend == true? 'enableBtn' : 'disableBtn'">
      <mat-icon style="vertical-align: middle;">refresh</mat-icon>
      Resend OTP
    </label>
  </div>

  <div style="position:absolute;bottom: 10px;right: 10px;">
    <button (click)="closeDialog()" type="button" mat-dialog-close mat-button class="cnclbtn" >Cancel</button>
  </div>
</div>

<div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>