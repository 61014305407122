import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRolePermissionDirective } from './has-role-permission.directive';
import { SpecialCharValidationDirective } from './special-char-validation.directive';

@NgModule({
  declarations: [HasRolePermissionDirective,SpecialCharValidationDirective],
  exports:[HasRolePermissionDirective, SpecialCharValidationDirective],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule { }
