<div class="leadBoard ">
  <h5>
    Create Lead
  </h5>
  <div class="form-height">
    <div class="dialogbody">
      <div class="form-row">
        <div class="roleadd">
          <mat-label class="required"><label *ngIf="selectedBoardName =='exim'">Lead/Supplier/Exporter Name</label>
            <label *ngIf="selectedBoardName =='domestic'">Customer Name</label> </mat-label>
          <mat-form-field floatLabel="never">
            <input type="text" matInput [(ngModel)]="leadName">
          </mat-form-field>
          <!-- <div *ngIf="this.selectedBoardName == 'exim'" class="toggle-container">
            <div class="toggle-group">
              <input type="radio" id="for-rent" name="INDIA" class="toggle-input" [checked]="isIndiaSelected"
                (change)="leadCountryChange('INDIA')">
              <label for="for-rent" class="toggle-label india">India</label>

              <input type="radio" id="for-sale" name="MEXICO" class="toggle-input"
                (change)="leadCountryChange('MEXICO')" [checked]="isMexicoSelected">
              <label for="for-sale" class="toggle-label">Mexico</label>
            </div>
          </div> -->
          <div *ngIf="this.selectedBoardName == 'exim'" class="radio-container">
            <div class="radio-group">
              <input type="radio" id="india" name="country" class="radio-input" [checked]="isIndiaSelected"
                (change)="leadCountryChange('INDIA')">
              <label for="india" class="radio-label">India</label>

              <input type="radio" id="mexico" name="country" class="radio-input" [checked]="isMexicoSelected"
                (change)="leadCountryChange('MEXICO')">
              <label for="mexico" class="radio-label">Mexico</label>
            </div>
          </div>



        </div>
      </div>
    </div>
    <div class="btn-footer">
      <button mat-raised-button type="button" class="btnCncl" (click)="cancelDialog()">Cancel</button>
      <button mat-raised-button type="button" class="btnSave" [disabled]="create" (click)="createNewLead()">Create</button>

    </div>
  </div>
