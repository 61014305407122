<section class="section-class">
  <div class="d-flex justify-content-between">

    <div>
      <h4>Ledger Report</h4>
      <span class="d-flex" *ngIf="!authService.isSIDRoleType() && authService.isBorrower()">Supplier Name</span>
    </div>
    <div class="d-flex align-items-center">
      <div class="formfield" *ngIf="authService.isSIDRoleType() && !authService.isBorrower()">
        <!-- <label>Select Supplier</label> -->
        <mat-form-field appearance="outline" class="mog3-matselect">
          <mat-select placeholder="Select Supplier" multiple (selectionChange)="onSupplierChange($event.value)">
            <mat-option *ngFor="let option of supplierList" [value]="option.id">
              {{ option.businessName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="applyBtn" *ngIf="authService.isSIDRoleType() && !authService.isBorrower()">
        <button mat-flat-button class="appbtn" [disabled]="selectedSupplier.length == 0" (click)="applyButton()" #button>Apply</button>
      </div>
       <div class="applyBtn">
        <button mat-flat-button class="appbtn" (click)="downloadLedgerReport()" #button>Download</button>
    </div>
    </div>
  </div>

  <div class="ledger-table-overflow">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice">
      <ng-container matColumnDef="supplierName" sticky>
        <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
        <td mat-cell *matCellDef="let element" [title]="element.supplierName">
          {{ element.supplierName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="supAddress" sticky>
        <th mat-header-cell *matHeaderCellDef>Supplier Address</th>
        <td mat-cell *matCellDef="let element" [title]="element.supplierAddress">
          {{ element.supplierAddress }}
        </td>
      </ng-container>
      <ng-container matColumnDef="buyerName" sticky>
        <th mat-header-cell *matHeaderCellDef>Buyer Name</th>
        <td mat-cell *matCellDef="let element" [title]="element.buyerName">{{ element.buyerName }}</td>
      </ng-container>
      <ng-container matColumnDef="santLimit">
        <th mat-header-cell *matHeaderCellDef>Buyer Sanctioned Limit</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.sanctionedLimit ?( element.sanctionedLimit?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="utilizedLimt">
        <th mat-header-cell *matHeaderCellDef>Utilized Limit</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{element.utilizedAmount ? (element.utilizedAmount?.toFixed(2)) : 0}}
        </td>
      </ng-container>
      <ng-container matColumnDef="avLimit">
        <th mat-header-cell *matHeaderCellDef>Available Limit</th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element.availableAmount">₹</span>{{ element.availableAmount ?  (element.availableAmount?.toFixed(2)) : 0 }}</td>
      </ng-container>
      <ng-container matColumnDef="invNo">
        <th mat-header-cell *matHeaderCellDef>Invoice No.</th>
        <td mat-cell *matCellDef="let element">{{element.invoiceNumber ? element.invoiceNumber : '--'}}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Invoice Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>{{ element.invoiceAmount ? (element.invoiceAmount?.toFixed(2)) : 0 }}</td>
      </ng-container>
      <ng-container matColumnDef="invDate">
        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceDate | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef>Due Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceDueDate | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="rateCharges">
        <th mat-header-cell *matHeaderCellDef>Rate Charges</th>
        <td mat-cell *matCellDef="let element">{{ element.rateCharges ? (element.rateCharges?.toFixed(2)) : 0 }} <span>%</span></td>
      </ng-container>
      <ng-container matColumnDef="advAmount">
        <th mat-header-cell *matHeaderCellDef>Advance Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.advanceAmount ? (element.advanceAmount?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="advPercentage">
        <th mat-header-cell *matHeaderCellDef>Advance Percentage</th>
        <td mat-cell *matCellDef="let element">
          {{ element.advancePercentage ? (element.advancePercentage?.toFixed(2)) : 0 }}<span>%</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="processingFee">
        <th mat-header-cell *matHeaderCellDef>Processing Fee</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.processingFee ? (element.processingFee?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalCharges">
        <th mat-header-cell *matHeaderCellDef>Total Custom Charges</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.totalCustomCharges ? (element.totalCustomCharges?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="netAmount">
        <th mat-header-cell *matHeaderCellDef>Net Advance Amount</th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element.netAdvanceAmount">₹</span>{{ element.netAdvanceAmount ? (element.netAdvanceAmount?.toFixed(2)) : 0 }}</td>
      </ng-container>
      <ng-container matColumnDef="disbursedAmount">
        <th mat-header-cell *matHeaderCellDef>Disbursed Amount</th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element?.disbursal?.amount">₹</span>{{ element?.disbursal?.amount ? (element?.disbursal?.amount?.toFixed(2)) : 0}}</td>
      </ng-container>
      <ng-container matColumnDef="disbursedDate">
        <th mat-header-cell *matHeaderCellDef>Disbursed Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.disbursal?.date | date }}
        </td>
      </ng-container>
      <ng-container matColumnDef="repaymentAmount">
        <th mat-header-cell *matHeaderCellDef>Repayment Amount</th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element?.repayment?.amount">₹</span>{{ element?.repayment?.amount ? (element?.repayment?.amount?.toFixed(2)) : 0 }}</td>
      </ng-container>
      <ng-container matColumnDef="repaymentDate">
        <th mat-header-cell *matHeaderCellDef>Repayment Date</th>
        <td mat-cell *matCellDef="let element">{{ element?.repayment?.date ? (element?.repayment?.date | date) : '--' }}</td>
      </ng-container>
      <ng-container matColumnDef="lenderPayment">
        <th mat-header-cell *matHeaderCellDef>Payment To Lender (UTR)</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element?.lenderPayment?.amount"><span *ngIf="element?.lenderPayment?.amount">₹</span>
          {{ element?.lenderPayment?.amount ? (element?.lenderPayment?.amount?.toFixed(2)) : '0'}}({{element?.lenderPayment?.utr}})
        </span>
        <span *ngIf="!element?.lenderPayment?.amount">--
        </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="supplierPayment">
        <th mat-header-cell *matHeaderCellDef>Payment To Supplier (UTR)</th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element?.supplierPayment?.amount">₹</span>
          <span *ngIf="element?.supplierPayment?.amount">
            {{ element?.supplierPayment?.amount ? (element?.supplierPayment?.amount?.toFixed(2)) : '0' }}({{element?.supplierPayment?.utr}})
          </span>
          <span *ngIf="!element?.supplierPayment?.amount">
           --
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="otherDeduction">
        <th mat-header-cell *matHeaderCellDef>Other Deduction</th>
        <td mat-cell *matCellDef="let element">
          {{ (calculateOthCharges(element)?.toFixed(2)) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="overDueDays">
        <th mat-header-cell *matHeaderCellDef>OverDue Days</th>
        <td mat-cell *matCellDef="let element">
          {{ element.overDueDays }}
        </td>
      </ng-container>
      <ng-container matColumnDef="normalAmount">
        <th mat-header-cell *matHeaderCellDef>Interest Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.interestAmount ? (element.interestAmount?.toFixed(2)) : '0' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="overDueAmt">
        <th mat-header-cell *matHeaderCellDef>OverDue Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.overDueAmount ? (element.overDueAmount?.toFixed(2)) : '0' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="outstandingAmount">
        <th mat-header-cell *matHeaderCellDef>Outstanding Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.outStandingAmount ? (element.outStandingAmount?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="principle">
        <th mat-header-cell *matHeaderCellDef>Principle</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.principle ? (element.principle?.toFixed(2)) : 0}}
        </td>
      </ng-container>
      <ng-container matColumnDef="payRecieved">
        <th mat-header-cell *matHeaderCellDef>Payment Recieved</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.paymentRecieved ? (element.paymentRecieved?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payDate">
        <th mat-header-cell *matHeaderCellDef>Payment Recieved Date</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.paymentRecievedDate ? (element.paymentRecievedDate?.toFixed(2)) : 0}}
        </td>
      </ng-container>
      <ng-container matColumnDef="pendingBalance">
        <th mat-header-cell *matHeaderCellDef>Pending Balance</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.pendingBalance ? (element.pendingBalance?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="normalIntAmount">
        <th mat-header-cell *matHeaderCellDef>Normal Interest Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.normalInterestAmount ? (element.normalInterestAmount?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="balanceOverDueAmt">
        <th mat-header-cell *matHeaderCellDef>OverDue Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{element.overDueAmount ? (element.overDueAmount?.toFixed(2)) : 0}}
        </td>
      </ng-container>
      <ng-container matColumnDef="balPendingPay">
        <th mat-header-cell *matHeaderCellDef>Pending Balance Payable</th>
        <td mat-cell *matCellDef="let element">
          --
        </td>
      </ng-container>
      <ng-container matColumnDef="balPendingDate">
        <th mat-header-cell *matHeaderCellDef>Pending Bal Payable Date</th>
        <td mat-cell *matCellDef="let element">
          --
        </td>
      </ng-container>
      <ng-container matColumnDef="amtRecieved">
        <th mat-header-cell *matHeaderCellDef>Total Amount Recieved</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.totalAmountRecieved ? (element.totalAmountRecieved?.toFixed(2)) : 0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="advancedAmount">
        <th mat-header-cell *matHeaderCellDef>Advanced Amount</th>
        <td mat-cell *matCellDef="let element"><span>₹</span>
          {{ element.advanceAmount ? (element.advanceAmount?.toFixed(2)) : '₹0' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="intCharged">
        <th mat-header-cell *matHeaderCellDef>Total Interest Charged</th>
        <td mat-cell *matCellDef="let element"><span >₹</span>
          {{ element.totalInterestCharged ? (element.totalInterestCharged?.toFixed(2)) : '0' }}
        </td>
      </ng-container>
      <!-- Header row first group -->
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="6" [ngClass]="getClass('buyerDetails')">
          Buyer Details
        </th>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" [ngClass]="getClass('invoice')">
          Invoice Details
        </th>
      </ng-container>

      <!-- Header row third group -->
      <ng-container matColumnDef="header-row-third-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="7" [ngClass]="getClass('advance')">
          Advance Details
        </th>
      </ng-container>

      <!-- Header row fourth group -->
      <ng-container matColumnDef="header-row-fourth-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="9" [ngClass]="getClass('buyerPayment')">
          Buyer Repayment Details
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-fifth-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="8" [ngClass]="getClass('balance')">
          Balance Repayment Details
        </th>
      </ng-container>

      <ng-container matColumnDef="header-row-sixth-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" [ngClass]="getClass('summary')">
          Summary
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="[
          'header-row-first-group',
          'header-row-second-group',
          'header-row-third-group',
          'header-row-fourth-group',
          'header-row-fifth-group',
          'header-row-sixth-group'
        ]"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" [pageSizeOptions]="[25,50,75,100]" (page)="getPaginatorData1($event)" showFirstLastButtons>
    >
  </mat-paginator>
  </div>
</section>