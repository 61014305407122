import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-los-filters',
  templateUrl: './los-filters.component.html',
  styleUrls: ['./los-filters.component.scss']
})
export class LosFiltersComponent implements OnInit {

  sourceList = ['SYSTEM','PANJIVA','MANUAL','BULK_TERMSHEET','VEDANTA','WEBSITE','SOB','SUPPLIERPORTAL']
  selectedSource : string;

  constructor(public dialogRef: MatDialogRef<LosFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public tostrService : ToastrService) { }

  ngOnInit() {
    if(this.data && this.data.leadSource && this.data.leadSource.selectedLeadSource){
      this.selectedSource = this.data.leadSource.selectedLeadSource
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }
  onSourceChange(val){
    this.selectedSource = val;
  }
  resetFilters(){
    this.selectedSource = null;
    this.dialogRef.close()
  }
  applyFilters(){
    if(this.selectedSource){
      let obj = {
        selectedLeadSource : this.selectedSource
      }
      this.dialogRef.close(obj);
    } else {
      this.tostrService.error("Please Select Source")
    }
  }

}
