import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-termsheet-approve-link',
  templateUrl: './termsheet-approve-link.component.html',
  styleUrls: ['./termsheet-approve-link.component.scss']
})
export class TermsheetApproveLinkComponent implements OnInit {

  pdfUrl: SafeResourceUrl;
  source: any;
  termsheetID: any;
  isVisible: boolean = true;
  disableAcceptBtn: boolean;
  validTermsheet: boolean = true;

  constructor(
    private router: Router,
    private kanbanService: KanbanService,
    public toasterService: ToastrService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params) {
        this.source = params['params']['source'];
        this.termsheetID = params['params']['termSheetId'];
        this.loadPdf(this.termsheetID);
        this.checkValidateOfAcceptance();
      }
    });
  }

  loadPdf(id: string) {
    this.kanbanService.loadPrepareTermsheetPDF(id).subscribe(
      (response: Blob) => {
        let reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () =>{
          var base64String = reader.result;
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(base64String.toString());
        }
      },
      (error) => {
        this.toasterService.error("Error to Load Your Termsheet.");
        this.validTermsheet = false;
      }
    );
  }

  checkValidateOfAcceptance() {
    this.kanbanService.checkTermsheetStatus(this.termsheetID,).subscribe((res: any) => {
      if (res.result) {
        this.disableAcceptBtn = true;
      }
      else {
        this.disableAcceptBtn = false;
      }
    });
  }

  // this method will trigger while user will approve the prepare term sheet document after verifiation.
  onAccept() {
    if (this.source == 'whatsapp') {
      this.kanbanService.approvePrepareTermsheetDocument(this.source, this.termsheetID,).subscribe((res: any) => {
        if (res['status'] == 200) {
          this.toasterService.success("Termsheet Approved successfully");
          if (res.result == 'Success') {
            this.isVisible = false
          } else {
            this.isVisible = true;
          }
        }
        else if (res['status'] == 400) {
          this.isVisible = true;
          this.toasterService.error("Termsheet already Approved");
        }
      });
    }
  }

  //this method will trigger while user dont want to approve the prepare term sheet docs.
  onCancel() {
    this.toasterService.success("Redirecting to HomePage");
    this.router.navigateByUrl(`/supplierLinks`);
  }
}
