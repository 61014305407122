import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SidService } from 'src/app/component/sid/services/sid.service';
import { ConfirmationModelComponent } from 'src/app/shared-files/shared-component/confirmation-model/confirmation-model.component';

@Component({
  selector: 'app-od-adjustment-dialog',
  templateUrl: './od-adjustment-dialog.component.html',
  styleUrls: ['./od-adjustment-dialog.component.scss']
})
export class OdAdjustmentDialogComponent implements OnInit {
  displayedColumns: string[] = ['select', 'invoiceNumber', 'dueDate', 'amount', 'overDueAmount', 'repaymentDate', 'adjustmentAmount', 'waiveInterest', 'repaymentSource'];
  dataSource = new MatTableDataSource<any>();
  totalOdAmount: number;
  remainingAmount: number;

  constructor(
    public dialogRef: MatDialogRef<OdAdjustmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sidService: SidService,
    public tostrService: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchODInvoices(this.data.element.supplierId);
  }

  fetchODInvoices(id) {
    this.sidService.getODInvoices(id).subscribe(res => {
      if (res['result'] && Object.keys(res['result']).length > 0) {
        let result = res['result'];
        this.dataSource.data = result.map(val => {
          return {
            invoiceId: val.invoiceId,
            select: val.select,
            invoiceNumber: val.invoiceNumber,
            dueDate: val.dueDate,
            amount: val.amount,
            overDueAmount: val.overDueAmount,
            repaymentDate: new Date(),
            adjustmentAmount: val.adjustmentAmount,
            repaymentSource: val.repaymentSource,
            waiveInterest: val.waiveInterest
          }
        });
      }
    });
  }
  changeRepaymentDate(event, element, index) {
    let obj = {
      invoiceId: element.invoiceId,
      paymentDate: new Date(event.value).getTime(),
      repaymentSource: element.repaymentSource,
      waiveInterest: element.waiveInterest,
      amount: element.amount
    }
    this.sidService.calculateRepaymentAmount(obj).subscribe(res => {
      if (res['result']) {
        this.dataSource.data[index]['overDueAmount'] = res['result'];
      }
    })
  }

  odAdjustment() {
    let data = {
      title: "Please Confirm",
      content: "Are you sure you want to do overdue Adjustment?",
      additional: "",
      class: "green"
    }
    const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      disableClose: true,
      width: '400px',
      height: '250px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitOdAdjustment();
      }
    });
  }
  submitOdAdjustment() {
    let selectedOD = []
    this.dataSource.data.forEach(val => {
      if (val['select']) {
        let overdueInvoiceSettlementInput: any = {};
        overdueInvoiceSettlementInput.amount = val['adjustmentAmount'];
        overdueInvoiceSettlementInput.amountExcludingTax = val['adjustmentAmount'];
        overdueInvoiceSettlementInput.transactionAmount = val['adjustmentAmount'];
        overdueInvoiceSettlementInput.remarks = 'REPAYMENT';
        overdueInvoiceSettlementInput.supplierId = 'SID00007';
        overdueInvoiceSettlementInput.invoiceNumber = val['invoiceNumber'];
        overdueInvoiceSettlementInput.paymentDate = val['repaymentDate'];
        overdueInvoiceSettlementInput.tax = 0;
        overdueInvoiceSettlementInput.deductions = 0;
        selectedOD.push(overdueInvoiceSettlementInput);
      }
    });
    let obj = {
      inputList: selectedOD,
      "remainingAmount": this.data.element.amount_payable - this.totalOdAmount,
      "supplierId": this.data.element.supplierId,
      "totalAdjustedAmount": this.totalOdAmount,
      "transactionFileId": this.data.escrowID
    }
    this.sidService.submitOdAdjustment(obj).subscribe(res => {
      if (res['status'] === 200) {
        this.tostrService.success(`Overdue amount adjusted succesfully`);
        this.dialogRef.close(true);
      }
    })
  }


  getTotalOdAmount(): any {
    if (this.dataSource && Array.isArray(this.dataSource.data)) {
      this.totalOdAmount = this.dataSource.data.reduce((sum, current) => {
        // Only add the adjustmentAmount if select is true
        if (current['select']) {
          return sum + current['adjustmentAmount'];
        }
        return sum;
      }, 0);
      // Showing error if totalOdAmount is greater than total Amount
      this.remainingAmount = this.data.element.amount_payable - this.totalOdAmount;
      if (this.totalOdAmount > this.data.element.amount_payable) {
        this.tostrService.error('Adjusted Amount cannot be greater than Total Available amount', 'Error');
      }
      return this.totalOdAmount;
    }
    return 0;
  }

}
