export const importConfig = {
    importConfig: "importConfig",
    borrowerConfig : "borrowerConfig",
    borrowerDisplayConfig: "borrowerDisplayConfig",
    borrowerInvoiceDisplayConfig: "borrowerInvoiceDisplayConfig",
    buyerConfig: "buyerConfig",
    buyerDisplayConfig: "buyerDisplayConfig",
    invoiceConfig: "invoiceConfig",
    invoiceDisplayConfig: "invoiceDisplayConfig",
    invoiceExportConfig: "invoiceExportConfig",
    loanInvoiceConfig: "loanInvoiceConfig",
    loanInvoiceDisplayConfig: "loanInvoiceDisplayConfig",
    masterAccountID: "credlixUserID"
}