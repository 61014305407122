<div class="content">

  <div class="d-top">
    <div class="close-icon">
      <mat-icon (click)="closeDialog()" class="closeIcn mat-icon notranslate material-icons mat-icon-no-color"
        role="img" aria-hidden="true">
        close
      </mat-icon>
    </div>
    <h5>Before you move</h5>
    <div class="d-flex align-items-center mt-10">
      <div class="badge-1">Pre-Qualified</div>
      <span class="px-10"><i class="ri-arrow-right-line"></i></span>
      <div class="badge-1"> New-Leads</div>
    </div>
  </div>

  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Select Assignee</mat-label>
    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
      [(ngModel)]="selectedAssigneId" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option value='UnAssigned'>UnAssigned</mat-option>
      <mat-option *ngFor="let option of filteredUserList | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="footer-btn">
    <button mat-raised-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
    <button mat-raised-button class="savebtn" (click)="saveBuyerDataRow()">Move</button>
  </div>
</div>