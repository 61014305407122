<div class="send-wp-msg">
  <div class="wrpr">
    <h2>Send Message</h2>
    <label>Message Type <span>*</span></label>
    <mat-form-field class="inputBox" floatLabel="never">
      <mat-label>Select Type</mat-label>
      <mat-select [(ngModel)]="selectedType" (selectionChange)="onMessageTypeChange($event.value)">
        <mat-option value="whatsapp">WhatsApp </mat-option>
        <mat-option value="email">Email </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="frm" *ngIf="selectedType == 'whatsapp'">
      <label>Contact / Phone <span>*</span></label>
      <mat-form-field class="inputBox" floatLabel="never">
        <mat-label>Select Contact</mat-label>
        <mat-select [(ngModel)]="selectedUser" (selectionChange)="onUserListChange($event.value, 'contact')">
          <mat-option *ngFor="let list of userList" [(value)]="list.phoneNumber">{{list.name}}
            {{list.phoneNumber}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="frm" *ngIf="selectedType == 'email'">
      <label>Email <span>*</span></label>
      <mat-form-field class="inputBox" floatLabel="never">
        <mat-label>Select Email</mat-label>
        <mat-select [(ngModel)]="selectedUser" (selectionChange)="onUserListChange($event.value, 'email')">
          <mat-option *ngFor="let list of userList" [(value)]="list.email">{{list.email}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <label>Message <span>*</span></label>
    <mat-form-field class="inputBox" floatLabel="never">
      <mat-label>Early payment request</mat-label>
      <mat-select [(ngModel)]="selectedEPReq" (selectionChange)="selectMessageOptions($event)">
        <mat-option *ngIf="!isFromFinance" value="early_payment_selection1">Early payment request</mat-option>
        <mat-option *ngIf="isFromFinance" value="Finance request">Finance request</mat-option>
        <mat-option *ngIf="!isFromFinance" value="payment_hold_request">GST Upload request</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="prw" *ngIf="msgContent != null">
      <h4>Preview</h4>
      <textarea class="txtara">{{msgContent}}</textarea>
    </div>
    <div class="last-msg" *ngIf="lastMsgDate != null">
      <span class="lst-msg-txt">Last Message:</span>
      <span>{{lastMsgDate}}</span>
    </div>
  </div>
  <div class="send-button">
    <button type="button" class="cncbtn" (click)="closeDialog()">Cancel</button>
    <button (click)="sendWhatsappMessage()" [disabled]="isdisable">Send Message</button>
  </div>
</div>
