<div class="costcoDialog">
  <div class="lft">
    <div class="inner">
      <section aria-label="heading">
        <div fxLayout="row" fxLayoutAlign="start center" class="card-header">
          <div style="width: 40%" fxLayout="row" fxLayoutAlign="start center">
            <img src="../../../../../assets/icons/arrow_back.svg" matTooltip="Back" matTooltipClass="new-tooltip"
              class="margin-right backIcon" (click)="showSuppliers()" />
            <div class="headLbl">
              <span>{{ laneTaskIDDetail?.displayId }}</span>
            </div>
          </div>
        </div>
      </section>

      <div fxLayout="row" class="eximInfoSummary" fxLayoutAlign="space-around" *ngIf="laneTaskIDDetail != null">
        <section class="lftSection">
          <div class="commonItems">
            <span class="titleInfo">Lead Summary</span>
            <!-- <span class="itemTxt">SID-1000000001</span> -->
            <span class="sourceInfo">
              <span class="lbl">Source :</span>
              <span class="value">{{laneTaskIDDetail?.leadSource == 'SOB' ? 'SOB' : 'System'}}</span>
            </span>
            <!-- <span class="ChannelInfo">
              <span class="lbl">Channel :</span>
              <span class="value">Credlix-Web</span>
            </span> -->
          </div>
          <div class="commonItems">
            <span class="titleInfo">Contact Details</span>
            <span class="compName">{{exporterName}}</span>
            <div class="d-flex">
              <ng-container *ngIf="laneTaskIDDetail && getLeadContactDetails(laneTaskIDDetail) as contact">
                <span class="phoneNo">{{contact.phone}},</span>
                <span class="emailTxt">{{contact.email}}</span>
              </ng-container>
            </div>

          </div>
        </section>

        <section class="rhtOtherDetails">
          <span class="headTitleTxt">Other Details</span>

          <!-- <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-icon-div">
            <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Company Name"
              matTooltipClass="new-tooltip" class="material-symbols-outlined company-icon">corporate_fare</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center start">
            <div class="row">
              <div class="headLbl px-10">
                <input class="card-head" type="text" [(ngModel)]="leadExporterName"
                  (ngModelChange)="getLeadName(leadExporterName)">
              </div>
              <div class="icn" *ngIf="isIconVisible">
                <i class="material-icons" (click)="changeLeadName(leadExporterName)">check</i>
                <i class="material-icons" (click)="resetLeadName()">close</i>
              </div>
            </div>
            <label class="icon-text-label">Company</label>
          </div>
        </div> -->
          <section class="otherDetailsContent">
            <!-- <div class="commonODInfo">
            <i class="ri-timer-2-line spIcon"></i>
            <div class="cibilScore">
              <span class="textWithIcon">
                780
                <i class="ri-check-line"></i>
              </span>
              <span class="bottomTxt">
                CIBIL Score
              </span>
            </div>
          </div> -->

            <div class="commonODInfo">
              <i class="ri-user-follow-line spIcon"></i>
              <div class="cibilScore">
                <span class="textWithIcon">
                  {{ laneTaskIDDetail.noOfBuyers }}
                </span>
                <span class="bottomTxt"> No. of Buyer </span>
              </div>
            </div>

            <div class="commonODInfo">
              <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Limit Applied"
                matTooltipClass="new-tooltip" class="material-symbols-outlined limit-icon">monetization_on</mat-icon>
              <div class="cibilScore">
                <span class="textWithIcon">
                  {{ laneTaskIDDetail.totalLimitApplied }}
                </span>
                <span class="bottomTxt"> Limit Required </span>
              </div>
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
              <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Buyers"
                matTooltipClass="new-tooltip" class="material-symbols-outlined buyer-icon">group</mat-icon>
            </div>
            <div fxLayout="column" fxLayoutAlign="center start">
              <span>{{laneTaskIDDetail.noOfBuyers}}</span>
              <label class="icon-text-label">No. Of Buyer</label>
            </div>
          </div> -->

            <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-limit-div">
              <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Limit Applied"
                matTooltipClass="new-tooltip" class="material-symbols-outlined limit-icon">monetization_on</mat-icon>
            </div>
            <div fxLayout="column" fxLayoutAlign="center start">
              <span>{{laneTaskIDDetail.totalLimitApplied}}</span>
              <label class="icon-text-label">Limit Applied For</label>
            </div>
          </div> -->
          </section>

          <!-- <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-contact-div">
            <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Conatct"
              matTooltipClass="new-tooltip" class="material-symbols-outlined contact-icon">call</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center start">
            <ng-container *ngIf="laneTaskIDDetail && getLeadContactDetails(laneTaskIDDetail) as contact">
              <span>{{contact.phone }} </span>
            </ng-container>
            <label class="icon-text-label">Contact Number</label>
          </div>
        </div> -->
        </section>
      </div>

      <section aria-label="lead-info" class="lead-info">
        <div class="txtInfo">
          <div class="cb_tabarea" style="position: relative">
            <div fxLayout="row" fxLayoutAlign="start center" class="comments-main">


              <div fxLayout="row" fxLayoutAlign="start center" style="margin-right: 1rem">
                <div fxLayout="row" fxLayoutAlign="center end" *ngIf="laneTaskIDDetail != null">
                  <div class="selectBox">
                    <mat-form-field appearance="fill" class="dash-selct">
                      <mat-select disableOptionCentering [(ngModel)]="selectedlane"
                        (selectionChange)="onLeadChange($event.value)">
                        <mat-option *ngFor="let lane of selectedBoardLane" [value]="lane.name" [ngStyle]="{
                            display: lane.selected == true ? 'none' : 'block'
                          }" [disabled]="lane.isEditable == 'VIEW' ||  (lane.name == 'underwriting' && freezedLaneMovement)" [title]="getDisabledMessage(lane)" [ngClass]="getActionClass(lane)">
                          <div class="d-flex justify-content-between transition-status">
                            <div class="d-flex align-items-center">
                              <span *ngIf="lane.forwardMove == true">
                                <i class="ri-arrow-right-line"></i></span>
                              <span *ngIf="
                                  lane.backMove == true ||
                                  this.laneTaskIDDetail.accountCreatedLane
                                ">
                                <i class="ri-arrow-left-line"></i></span>
                              <span *ngIf="lane.cold == true">
                                <i class="ri-arrow-right-line"></i>
                              </span>
                              <div class="badge-1">
                                {{ formatPaymentType(lane?.name | titlecase) }}
                              </div>
                              <label *ngIf="lane.isEditable == 'VIEW'">
                                (View Only)</label>
                            </div>
                          </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="start center" (click)="showHistory('history')"
                class="action-tab-margin">
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <i class="ri-history-line comments-icon"></i>
                  <mat-icon aria-hidden="false" aria-label="History" matTooltip="History" matTooltipClass="new-tooltip"
                    class="comments-icon"></mat-icon>

                </div>
                <div fxLayout="column" fxLayoutAlign="center start" *ngIf="laneTaskIDDetail != null">
                  <span class="comments-label"
                    *ngIf="laneTaskIDDetail.assignee == null || laneTaskIDDetail.assignee.length == 0">History
                  </span>
                  <ng-container *ngIf="laneTaskIDDetail.assignee != null && laneTaskIDDetail.assignee.length > 0">
                    <p class="comments-label"> History1</p>
                  </ng-container>
                </div>
              </div> -->

              <div fxLayout="row" fxLayoutAlign="start center" class="action-tab-margin" (click)="watcherPopup()">
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <span class="material-icons comments-icon">
                    remove_red_eye
                  </span>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="comments-label">Watcher</span>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" (click)="onSideTabToggle('users')"
                class="action-tab-margin">
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <i class="ri-user-fill comments-icon"></i>
                  <mat-icon aria-hidden="false" aria-label="Assigned To" matTooltip="Assigned To"
                    matTooltipClass="new-tooltip" class="comments-icon"></mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" *ngIf="laneTaskIDDetail != null">
                  <span class="comments-label" *ngIf="
                      laneTaskIDDetail.assignee == null ||
                      laneTaskIDDetail.assignee.length == 0
                    ">Assigned To
                  </span>
                  <ng-container *ngIf="
                      laneTaskIDDetail.assignee != null &&
                      laneTaskIDDetail.assignee.length > 0
                    ">
                    <p class="comments-label">
                      {{ laneTaskIDDetail?.assignee[0]?.user?.name }}
                    </p>
                  </ng-container>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" (click)="onSideTabToggle('documents')"
                class="action-tab-margin">
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <mat-icon aria-hidden="false" aria-label="Comments" fontIcon="home" matTooltip="Comments"
                    matTooltipClass="new-tooltip" class="material-symbols-outlined comments-icon">upload_file</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="comments-label">Documents</span>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" (click)="onSideTabToggle('comments')"
                class="action-tab-margin">
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <mat-icon aria-hidden="false" aria-label="Comments" fontIcon="home" matTooltip="Comments"
                    matTooltipClass="new-tooltip"
                    class="material-symbols-outlined comments-icon">comment_bank</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="comments-label">Comments</span>
                </div>
              </div>
            </div>

            <mat-tab-group id="first" [(selectedIndex)]="selectedIndex"
              (selectedIndexChange)="cardDetailsTabChnage($event)">
              <mat-tab id="leadTab">
                <ng-template mat-tab-label>
                  <span>Lead Details</span>
                </ng-template>
                <div class="sub-tabs">
                  <mat-tab-group [selectedIndex]="demo1TabIndex" (selectedTabChange)="tabChanged($event)"
                    animationDuration="0ms">
                    <mat-tab *ngFor="let lane of laneArrayKeys; let i = index">
                      <ng-template mat-tab-label>
                        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                          <span *ngIf="lane.keys != laneTaskIDDetail.lane.name" style="text-transform: capitalize">{{
                            getLaneName(lane.keys) }}</span>
                          <span *ngIf="lane.keys == laneTaskIDDetail.lane.name" style="
                              text-transform: capitalize;
                              color: #022b5c;
                              font-weight: bold;
                            ">{{ getLaneName(lane.keys) }}</span>

                          <img src="../../../../../assets/icons/right-tab-arrow-filled.svg"
                            matTooltip="Mark Disposition" matTooltipClass="new-tooltip"
                            (click)="downloadInvoice(element)" />
                        </div>
                      </ng-template>
                      <div class="cntnr cntnr_2" [ngClass]="{gstTabsMain : lane.keys =='Gst-Details' }">
                        <div class="tmp" *ngIf="
                            lane.keys != 'prepare-termsheet' &&
                            lane.keys != 'rpa-sent'
                          ">
                          <ng-template mat-tab-label>
                            <span>
                              {{
                              formatPaymentType(lane.keys | titlecase)
                              }}</span>
                          </ng-template>
                          <div class="cb_tabcell" [ngClass]="{isEditLaneAccess : !isEditableAccess }">
                            <div class="cb_header" *ngIf="lane.keys !='Gst-Details'">
                              <h4> {{ formatPaymentType(lane.keys | titlecase)}}</h4>
                              <div class="btnn">
                                
                                <button mat-stroked-button mat-flat-button class="dashedbtn isEditLaneAccessForSales"
                                  *ngIf="lane.keys == 'eligiblity-check'" (click)="openFilledInsureanceData()">
                                  View Insurance Status
                                </button>
                                <button *ngIf="(lane.keys == 'document-collection-submission' || lane.keys == 'underwriting')" mat-stroked-button mat-flat-button class="viewscorebtn"
                                  (click)="openScoreCard(lane)"> 
                                    View Score Card
                                </button>
                                <span
                                  *ngIf="additionalDetails && additionalDetails.currentMilestone">
                                  <button mat-stroked-button mat-flat-button type="submit"
                                    class="dashedbtn isEditLaneAccessForSales" [disabled]="lane.keys == 'cold-leads'"
                                    *ngIf="laneTaskIDDetail.lane.board.name !='domestic'"
                                    [matMenuTriggerFor]="milestone">
                                    <mat-icon>check_circle_outline</mat-icon> Current Milestone:
                                    {{additionalDetails.currentMilestone.displaySequence ?
                                    additionalDetails.currentMilestone.displaySequence : ''}}.
                                    {{additionalDetails.currentMilestone.name ? additionalDetails.currentMilestone.name
                                    : ''}}</button>
                                  <mat-menu class="milestone-mat-menu" #milestone="matMenu">
                                    <div class="options-container" *ngFor="
                                        let option of mileStoneList;
                                        let i = index
                                      ">
                                      <button mat-menu-item
                                        (click)="selectMilestone(option, additionalDetails.currentMilestone.sequenceNumber)"
                                        [disabled]="shouldMilestoneDisabled(option.sequenceNumber)">
                                        {{ option.displaySequence }}.
                                        {{ option.name }}
                                      </button>
                                    </div>
                                  </mat-menu>
                                </span>

                                <!-- && fileUploadData[0]['insuranceInfo'].length > 0" -->
                                <button mat-stroked-button mat-flat-button type="submit" class="dashedbtn"
                                  *ngIf="lane.keys == 'eligiblity-check'" [matMenuTriggerFor]="insuranceMenu"
                                  [disabled]="
                                    lane.keys != laneTaskIDDetail.lane.name
                                  ">
                                  Add Insurance
                                </button>

                                <mat-menu #insuranceMenu="matMenu">
                                  <div class="options-container">
                                    <mat-checkbox *ngFor="let option of getInsuranceList" (change)="
                                        addBulkInsuranceList($event, option)
                                      " (click)="$event.stopPropagation()">
                                      {{ option }}
                                    </mat-checkbox>
                                    <button class="btnSave" (click)="addBulkInsurance()" type="button">
                                      Apply
                                    </button>
                                  </div>
                                </mat-menu>

                                <button mat-flat-button class="sv-btn" #saveButton1
                                  (click)="submitForm(lane, lane.keys)" *ngIf="
                                    lane.keys == laneTaskIDDetail.lane.name && currentTabName != 'gst-details' && laneTaskIDDetail.lane.name != 'cold-leads'
                                  ">
                                  Save
                                </button>
                                <button mat-flat-button class="sv-btn" #saveButton1 (click)="downloadGStReport()"
                                  *ngIf="currentTabName == 'gst-details' && this.gstGenerateService.gstUrl">
                                  Download Report
                                </button>

                                <button mat-flat-button class="sv-btn DuplicateSave" #saveButton1
                                  (click)="submitForm(lane, lane.keys)" *ngIf="
                                    lane.keys == laneTaskIDDetail.lane.name && laneTaskIDDetail.lane.name != 'gst-details' && laneTaskIDDetail.lane.name != 'cold-leads'
                                  ">
                                  Save
                                </button>
                              </div>
                            </div>
                            <div class="cb_mdlbox">

                              <div style="display: contents;"
                                *ngIf="lane.keys!= 'document-collection-submission' ; else documentCollectionElse">
                                <div class="cb_row cb_nl" *ngIf="
                                  lane.keys == 'new-leads' &&
                                  laneTaskIDDetail.lane.board.name == 'exim'
                                ">
                                  <div class="cb_label">
                                    Lead/Supplier/Exporter Name<span style="color: red">*</span>
                                  </div>
                                  <div class="cb_input">
                                    <input type="text" placeholder="Enter Lead/Supplier/Exporter Name " [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " [(ngModel)]="exporterName" />
                                  </div>
                                </div>
                                <!-- <section *ngIf="lane.keys!='document-collection-submission'"> -->
                                <ng-container *ngFor="let val of lane.value">
                                  <div class="cb_row cb_nl acceptedChk_box" *ngIf="val.display == true && lane.keys !=
                                    'document-collection-submission'">
                                    <div class="cb_label" *ngIf="val.display == true && lane.keys !=
                                    'document-collection-submission'">
                                      {{ val.displayName
                                      }}<span *ngIf="val.optional == false" style="color: red">*</span>
                                    </div>

                                    <div class="cb_input">
                                      <mat-label for="filterColumn"></mat-label>
                                      <div class="cb_mdlbox" *ngIf="val['type'] == 'dataWithType' && val['display']">
                                        <div class="cb_row">
                                          <div class="input-group">
                                            <mat-form-field appearance="fill" class="mat-selectbox-1">
                                              <mat-label>Select ID Type </mat-label>
                                          ￼   <mat-select [(value)]="doctype" class="sb_expandMenu"
                                                [(ngModel)]="identificationType" [disabled]="
                                                  lane.keys !=
                                                    laneTaskIDDetail.lane.name ||
                                                  laneTaskIDDetail.lane.board
                                                    .name == 'domestic'
                                                " placeholder="Select" (selectionChange)="
                                                  changeList($event.value)
                                                ">
                                                <mat-option *ngFor="let list of gstinList" [value]="list">{{ list }}
                                                </mat-option>
                                              </mat-select>
                                            </mat-form-field>
                                            <input class="input-1 ml-10" type="text" [(ngModel)]="docNumber" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " (ngModelChange)="docNumberChanged()"
                                              placeholder="Type your {{ doctype }}" />
                                            <button class="ml-10 autofill-btn" mat-stroked-button [disabled]="
                                                docNumber == '' ||
                                                lane.keys !=
                                                  laneTaskIDDetail.lane.name
                                              " (click)="autoFilldata()">
                                              <i class="ri-magic-line"></i> Autofill
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <mat-form-field appearance="outline" class="dash-selct cb_select"
                                        *ngIf="val.type == 'dropdown' && val['display']">
                                        <mat-select placeholder="Please select" [(ngModel)]="val['dataList']"
                                          [disabled]="
                                            lane.keys != laneTaskIDDetail.lane.name
                                          " multiple *ngIf="
                                            val['field'] == 'buyer_region' ||
                                            val['field'] == 'reason_to_move'
                                          " (selectionChange)="
                                            getmultipleBuyerRegion(
                                              $event.value,
                                              val['dataList']
                                            )
                                          ">
                                          <mat-option class="cb_selectoption" *ngFor="
                                              let list of getMultipleSelectionList(
                                                val['field']
                                              )
                                            " [value]="list" title="{{ list }}">
                                            {{ list }}</mat-option>
                                        </mat-select>

                                        <mat-select placeholder="Please select" [(ngModel)]="val['value']" [disabled]="
                                            lane.keys != laneTaskIDDetail.lane.name
                                          " *ngIf="
                                            val['field'] != 'buyer_region' &&
                                            val['field'] != 'reason_to_move'
                                          " (selectionChange)="
                                            getSelectedCountry(
                                              $event.value,
                                              val['value']
                                            )
                                          " required>
                                          <mat-option class="cb_selectoption" *ngFor="
                                              let list of getDynamicDropdownList(
                                                val['field']
                                              )
                                            " [value]="list">
                                            {{ list }}</mat-option>
                                        </mat-select>
                                      </mat-form-field>

                                      <div class="selected-chips-list" *ngIf="
                                          lane.keys == 'new-leads' &&
                                          val['field'] == 'buyer_region'
                                        ">
                                        <div class="chp" *ngFor="
                                            let region of buyerRegionList;
                                            let i = index
                                          ">
                                          <div class="selected-chip">
                                            {{ region }}
                                            <i class="ri-close-line" (click)="deleteBuyerRegion(region, i)"></i>
                                          </div>
                                        </div>
                                      </div>

                                      <input type="text" placeholder="Enter {{ val.displayName }}" *ngIf="
                                          (val.type === 'email' ||
                                            val.type === 'string') &&
                                          val['field'] != 'identification_number' &&
                                          val.display == true
                                        " (mouseleave)="readonlyAttrLeadName = true"
                                        (click)="readonlyAttrLeadName = false" [disabled]="
                                          lane.keys != laneTaskIDDetail.lane.name
                                        " [(ngModel)]="val['value']" (focusout)="
                                          checkDataTypeValidation(
                                            val.type,
                                            val['value']
                                          )
                                        " [readonly]="
                                          isPDC == true
                                            ? val.displayName == 'PDC No'
                                            : val.displayName == 'UDC No'
                                        " />

                                      <input type="number" class="w-65" placeholder="Enter {{ val.displayName }}" *ngIf="
                                          (val.type === 'long' ||
                                            val.type == 'phone' ||
                                            val.type === 'double' ||
                                            val.type === 'int') &&
                                          val.display == true
                                        " (mouseleave)="readonlyAttrLeadName = true"
                                        (click)="readonlyAttrLeadName = false" [disabled]="
                                          lane.keys != laneTaskIDDetail.lane.name
                                        " [(ngModel)]="val['value']" (keydown)="onKeyDownInput($event, val.type)" />

                                      <input type="text" matInput *ngIf="
                                          val.type == 'dropdown' &&
                                          val['value'] == 'Others'
                                        " [(ngModel)]="
                                          val['additionalData'][val.field]
                                        " placeholder="Enter Business Model" />

                                      <mat-form-field appearance="fill" class="date-picker-field w-65"
                                        *ngIf="val.type == 'date' && val['display']">
                                        <input matInput [matDatepicker]="picker" placeholder="Select Start Date"
                                          [max]="todayDate" [(ngModel)]="val['value']" [disabled]="
                                            lane.keys != laneTaskIDDetail.lane.name
                                          " (dateChange)="
                                            changeConfigDate($event, val['value'])
                                          " readonly />
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                      </mat-form-field>

                                      <div class="cb_input cstmField" *ngIf="
                                          val.type == 'amountWithCurrency' &&
                                          val.field == 'monthly_shipments_amount'
                                        ">
                                        <div class="amnt">
                                          <label>Currency</label>
                                          <!-- <select class="inptbx" name="shipmentCurrency" [(ngModel)]="shipmentCurrency"
                                            [disabled]="lane.keys != laneTaskIDDetail.lane.name">
                                            <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                              {{curr}}
                                            </option>
                                          </select> -->
                                          <mat-form-field class="shipAmntSelect border-none" [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            ">
                                            <input autocomplete="off" type="text" id="currency" #currency matInput
                                              [(ngModel)]="shipmentCurrency" [matAutocomplete]="priceAdjust"
                                              (keyup)="chnageCurrency($event)" (blur)="
                                                validateCurrencyOfInvoice($event)
                                              " (click)="$event.target.select()" />
                                            <i class="material-icons dropdown-ico pointer-none">
                                              arrow_drop_down
                                            </i>
                                            <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                            <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                                validateCurrencyOfInvoice($event)
                                              ">
                                              <mat-option class="found_optn" [value]="curr"
                                                *ngFor="let curr of getCurrencyList">
                                                {{ curr }}
                                              </mat-option>
                                              <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                            </mat-autocomplete>
                                          </mat-form-field>
                                        </div>
                                        <div class="amnt">
                                          <label>Amount</label>
                                          <input type="text" class="inptbx" placeholder="Enter Monthly shipment amount"
                                            [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " appAmountFormat [value]="formattedAmount"
                                            (input)="changeInput($event)" />
                                        </div>
                                        <div *ngIf="shipmentAmount > 1000000" class="amnt">
                                          <label>Reason<span style="color: red">*</span></label>
                                          <input type="text" class="inptbx" placeholder="Enter Justification"
                                            [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " [(ngModel)]="millionReason" />
                                          <p class="shimentReason">
                                            Shipment Amount is Above 1 Million
                                          </p>
                                        </div>
                                      </div>

                                      <div class="cb_input cstmField" *ngIf="
                                          val.type == 'amountWithCurrency' &&
                                          val.field == 'setup_fee'
                                        ">
                                        <div class="amnt">
                                          <label>Amount</label>
                                          <input type="text" class="inptbx" placeholder="Enter Set Up Fee amount"
                                            [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " [value]="formattedSetUpFeeAmount" (input)="chnageSetUpFee($event)"
                                            appAmountFormat />
                                        </div>
                                        <div class="amnt">
                                          <label>Currency</label>
                                          <!-- <select class="inptbx" name="setUpFeeCurrency" [(ngModel)]="setUpFeeCurrency"
                                            [disabled]="lane.keys != laneTaskIDDetail.lane.name">
                                            <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                              {{curr}}
                                            </option>
                                          </select> -->
                                          <mat-form-field class="selectDropdwnList" [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            ">
                                            <input class="inptbx" autocomplete="off" type="text" id="currency" #currency
                                              matInput [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " [(ngModel)]="setUpFeeCurrency" [matAutocomplete]="priceAdjust"
                                              (keyup)="chnageCurrency($event)" (blur)="
                                                validateCurrencyOfInvoice($event)
                                              " (click)="$event.target.select()" />
                                            <i class="material-icons dropdown-ico pointer-none">
                                              arrow_drop_down
                                            </i>
                                            <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                            <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                                validateCurrencyOfInvoice($event)
                                              ">
                                              <mat-option class="found_optn" [value]="curr"
                                                *ngFor="let curr of getCurrencyList">
                                                {{ curr }}
                                              </mat-option>
                                              <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                            </mat-autocomplete>
                                          </mat-form-field>
                                        </div>
                                      </div>

                                      <div class="cb_input cstmField" *ngIf="
                                          val.type == 'amountWithCurrency' &&
                                          val.field == 'remittance_charge'
                                        ">
                                        <div class="amnt">
                                          <label>Amount</label>
                                          <input type="text" class="inptbx" placeholder="Enter Remittance Charge amount"
                                            [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " [value]="formattedRemittanceAmount"
                                            (input)="chnageRemittanceAmount($event)" appAmountFormat />
                                        </div>
                                        <div class="amnt">
                                          <label>Currency</label>
                                          <!-- <select class="inptbx" name="remittanceCurrency" [(ngModel)]="remittanceCurrency"
                                            [disabled]="lane.keys != laneTaskIDDetail.lane.name">
                                            <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                              {{curr}}
                                            </option>
                                          </select> -->
                                          <mat-form-field class="selectDropdwnList">
                                            <input [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " class="inptbx" autocomplete="off" type="text" id="currency" #currency
                                              matInput [(ngModel)]="remittanceCurrency" [matAutocomplete]="priceAdjust"
                                              (keyup)="chnageCurrency($event)" (blur)="
                                                validateCurrencyOfInvoice($event)
                                              " (click)="$event.target.select()" />
                                            <i class="material-icons dropdown-ico pointer-none">
                                              arrow_drop_down
                                            </i>
                                            <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                            <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                                validateCurrencyOfInvoice($event)
                                              ">
                                              <mat-option class="found_optn" [value]="curr"
                                                *ngFor="let curr of getCurrencyList">
                                                {{ curr }}
                                              </mat-option>
                                              <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                            </mat-autocomplete>
                                          </mat-form-field>
                                        </div>
                                      </div>

                                      <mat-checkbox class="w-65 chkAccept" *ngIf="val.type == 'boolean'" [disabled]="
                                          lane.keys != laneTaskIDDetail.lane.name
                                        " (change)="
                                          onConfigCheckboxChange(val['value'])
                                        " [(ngModel)]="val['value']"></mat-checkbox>
                                      <!-- Single Upload -->
                                      <section aria-label="documents" class="cb_fileinput" *ngIf="val.type == 'file'">
                                        <div class="file-upload-section">
                                          <div class="d-flex flex-column w-100">
                                            <div class="file-uploading-status d-flex justify-content-between">
                                              <span class="file-name" *ngIf="
                                                  val.type == 'file' &&
                                                  val.value != ''
                                                ">
                                                {{ val?.additionalData?.fileName }}
                                                <a [ngClass]="
                                                    lane.keys !=
                                                    laneTaskIDDetail.lane.name
                                                      ? 'isEditable'
                                                      : ''
                                                  ">
                                                  <i class="ri-download-line" *ngIf="
                                                      val.type == 'file' &&
                                                      val.value != '' &&
                                                      lane.keys ==
                                                        'document-collection-submission'
                                                    " (click)="
                                                      downloadSingleDocument(
                                                        val.value
                                                      )
                                                    "></i>
                                                  <i class="ri-close-line" *ngIf="
                                                      val.type == 'file' &&
                                                      val.value != ''
                                                    " (click)="
                                                      deleteTaskDocuments(
                                                        val.value,
                                                        val.field,
                                                        '',
                                                        lane.keys
                                                      )
                                                    "></i>
                                                </a>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div style="display: flex">
                                          <div draggable="true" class="dragArea config" *ngIf="
                                              val.value == '' && val.type == 'file'
                                            " ngFileDragDrop (click)="fileInput.click()" (fileDropped)="
                                              onConfigFileChanged(
                                                $event,
                                                val.field,
                                                'config',
                                                lane.keys
                                              )
                                            ">
                                            <span class="lbl">
                                              <input style="display: none" type="file" [disabled]="
                                                  lane.keys !=
                                                  laneTaskIDDetail.lane.name
                                                " (change)="
                                                  onConfigFileChanged(
                                                    $event,
                                                    val.field,
                                                    'config',
                                                    lane.keys
                                                  )
                                                " #fileInput />
                                              <span (click)="fileInput.value = ''"><a>Bulk Buyer Upload </a></span>
                                            </span>
                                          </div>
                                          <div *ngIf="
                                              val.value == '' && val.type == 'file'
                                            " style="margin: 7px 0 0 8px">
                                            <i class="ri-download-line brwIcn" (click)="downloadEximBuyerTemplate()"
                                              *ngIf="val.field == 'buyer_data' && laneTaskIDDetail.lane.board.name == 'exim'"></i>
                                            <i class="ri-download-line brwIcn" (click)="downloadBuyerTemplate()"
                                              *ngIf="val.field == 'buyer_data' && laneTaskIDDetail.lane.board.name == 'domestic'"></i>
                                          </div>
                                        </div>
                                      </section>
                                      <!-- Multi-Upload file -->
                                      <section aria-label="documents" class="cb_fileinput"
                                        *ngIf="val.type == 'multi_upload'">
                                        <div class="document-chip-section d-flex flex-wrap">
                                          <div class="document-chip" *ngFor="let file of val.values">
                                            <span (click)="
                                                downloadSingleDocument(
                                                  file.documentId
                                                )
                                              " [matTooltip]="file.path">{{ file.path }}
                                            </span>
                                            <a [ngClass]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                                  ? 'isEditable'
                                                  : ''
                                              "><i class="ri-close-line" (click)="
                                                  deleteTaskDocuments(
                                                    file.documentId,
                                                    val.field,
                                                    'multi_upload',
                                                    lane.keys
                                                  )
                                                "></i></a>
                                          </div>
                                        </div>
                                        <div draggable="true" class="dragArea config" ngFileDragDrop (fileDropped)="
                                            onConfigFileChanged(
                                              $event,
                                              val.field,
                                              'multi_upload',
                                              lane.keys
                                            )
                                          " (click)="fileInput.click()">
                                          <span class="lbl">
                                            <input style="display: none" type="file" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " (change)="
                                                onConfigFileChanged(
                                                  $event,
                                                  val.field,
                                                  'multi_upload',
                                                  lane.keys
                                                )
                                              " #fileInput />
                                            <span (click)="fileInput.click()"><a>Browse </a></span>
                                          </span>
                                        </div>
                                      </section>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <!-- </section> -->
                              <!-- add one drive folder here -->
                              <ng-template #documentCollectionElse>
                                <app-los-one-drive></app-los-one-drive>
                              </ng-template>
                            </div>

                            <!-- New added fields here-->
                            <div *ngIf="lane.keys == 'new-leads' && laneTaskIDDetail.lane.board.name !='domestic'">
                              <form [formGroup]="additionalDetilasForm" class="flexbox-wrap">
                                <div class="flex-div flex-additionalDiv w-full marginTop">
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Funding Requirement
                                    </div>
                                    <div class="FundingRequirment">
                                      <div class="cb_input currency">
                                        <mat-form-field appearance="fill" class="milestonedrp">
                                          <mat-select formControlName="currency">
                                            <mat-option value="USD">USD</mat-option>
                                            <mat-option value="EURO">EURO</mat-option>
                                            <mat-option value="GBP">GBP</mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                      <div class="cb_input fundingInp">
                                        <input type="number" placeholder="Please Enter Funding Requirment Amount"
                                          formControlName="fundingRequirement" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Go Live Month
                                    </div>
                                    <div class="dtpcr">
                                      <!-- <input type="date" placeholder="Please Enter email id"
                                        formControlName='goLiveMonth'
                                        [disabled]="lane.keys != laneTaskIDDetail.lane.name" /> -->
                                      <mat-form-field id="dtpcrField" appearance="fill">
                                        <input matInput [matDatepicker]="dp" placeholder="Select go live Month and Year"
                                          [value]="displayMonthYear" (focus)="dp.open()" formControlName="goLiveMonth"
                                          [disabled]="lane.keys != laneTaskIDDetail.lane.name"
                                          (keydown)="$event.preventDefault()" (paste)="$event.preventDefault()" />
                                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                        <mat-datepicker #dp startView="multi-year" (yearSelected)="
                                            chosenYearHandler($event)
                                          " (monthSelected)="
                                            chosenMonthHandler($event, dp)
                                          " panelClass="example-month-picker" [startAt]="todayDate">
                                        </mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                                <div class="flex-div flex-additionalDiv w-full">
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Transaction Type
                                    </div>
                                    <div class="cb_input">
                                      <mat-form-field appearance="fill" class="milestonedrp">
                                        <mat-label>Select Transaction Type</mat-label>
                                        <mat-select formControlName="transactionType" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          ">
                                          <mat-option *ngFor="
                                              let insurer of transactionTypeList
                                            " [value]="insurer">
                                            {{ insurer }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Probability
                                    </div>
                                    <div class="cb_input">
                                      <mat-form-field appearance="fill" class="milestonedrp">
                                        <mat-label>Select Probability</mat-label>
                                        <mat-select formControlName="probability" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          ">
                                          <mat-option value="Low">Low</mat-option>
                                          <mat-option value="Medium">Medium</mat-option>
                                          <mat-option value="High">High</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>

                                <div class="flex-div flex-additionalDiv w-full">
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Relationship Manager
                                    </div>
                                    <div class="cb_input">
                                      <mat-form-field appearance="fill" class="milestonedrp">
                                        <mat-label>Relationship Manager</mat-label>
                                        <mat-select formControlName="relationshipManagerId" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          ">
                                          <mat-option *ngFor="let rm of rmUserList" [value]="rm.name">
                                            {{ rm.name }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Lead Source
                                    </div>
                                    <div class="cb_input">
                                      <mat-form-field appearance="fill" class="milestonedrp">
                                        <mat-label>Select Lead Source</mat-label>
                                        <mat-select formControlName="leadSource" [disabled]="(lane.keys != laneTaskIDDetail.lane.name) || isLeadFromSobSource">
                                          <mat-option *ngFor="let lead of leadsSourceList" [value]="lead">
                                            {{ lead }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                                <div class="flex-div flex-additionalDiv w-full">
                                  <div class="w-50 custom-fields">
                                    <div class="cb_label cbAdditinalLabel required">
                                      Region
                                    </div>
                                    <div class="cb_input">
                                      <mat-form-field appearance="fill" class="milestonedrp">
                                        <mat-label>Select Region</mat-label>
                                        <mat-select *ngIf="laneTaskIDDetail.businessCountry != 'MEXICO'"
                                          formControlName="region" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          ">
                                          <mat-option *ngFor="
                                              let region of dataService.regionList
                                            " [value]="region">
                                            {{ region }}
                                          </mat-option>
                                        </mat-select>
                                        <mat-select *ngIf="laneTaskIDDetail.businessCountry == 'MEXICO'"
                                          formControlName="region" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          ">
                                          <mat-option *ngFor="
                                              let region of dataService.mexicoRegionList
                                            " [value]="region">
                                            {{ region }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <div *ngFor="let val of lane.value">
                              <div class="contact-section" *ngIf="
                                  lane.keys == 'new-leads' &&
                                  val.field == 'contact_details'
                                ">
                                <form [formGroup]="contactForm" (ngSubmit)="doSubmit()">
                                  <div class="contact-form" formArrayName="items" *ngFor="
                                      let field of contactForm.get('items')
                                        ?.controls;
                                      let ind = index
                                    ">
                                    <div [formGroupName]="ind">
                                      <div class="contact-header">
                                        <button class="delete-btn" mat-icon-button aria-label="delete" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " (click)="
                                            deleteContactDetails(field, ind)
                                          ">
                                          <i class="ri-delete-bin-line"></i>
                                        </button>
                                      </div>
                                      <div class="flex-div">
                                        <div class="w-50">
                                          <div class="cb_label required">
                                            Contact Name
                                          </div>
                                          <div class="cb_input">
                                            <input placeholder="Please Enter contact name" formControlName="name"
                                              [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " />
                                          </div>
                                        </div>
                                        <div class="w-50">
                                          <div class="cb_label required">
                                            Email
                                          </div>
                                          <div class="cb_input">
                                            <input type="email" placeholder="Please Enter email id"
                                              formControlName="email" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " />
                                          </div>
                                        </div>
                                        <div class="w-50">
                                          <div class="cb_label required">
                                            Phone number
                                          </div>
                                          <div class="cb_input">
                                            <input type="number" placeholder="Please Enter phone number"
                                              formControlName="phone" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " />
                                          </div>
                                        </div>
                                        <div class="pb-0 w-50">
                                          <div class="cb_label required">
                                            Designation
                                          </div>
                                          <div class="cb_input">
                                            <input type="text" placeholder="Please Enter designation"
                                              formControlName="designation" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " />
                                          </div>
                                        </div>
                                        <div class="pb-0 w-50">
                                          <div class="cb_label required">
                                            Set Primary
                                          </div>
                                          <div class="cb_input">
                                            <mat-checkbox formControlName="isPrimary"
                                              (change)="primarySet($event,ind)"></mat-checkbox>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <button mat-stroked-button class="cb_addmorebtn mb-15" (click)="addContactInfo()"
                                  [disabled]="
                                    lane.keys != laneTaskIDDetail.lane.name
                                  ">
                                  + Add Contact
                                </button>
                              </div>
                            </div>

                            <div class="cb_mdlbox chckBox_align" *ngIf="lane['keys'] == 'underwriting'">
                              <div class="cb_row cb_nl c">
                                <div class="cb_label">IS UDC</div>
                                <input type="checkbox" [(ngModel)]="isPDC" (change)="isPdcChecked($event, lane)" />
                              </div>
                            </div>
                          </div>
                          <div class="cb_tabcell" *ngIf="lane.keys == 'pre-risk'">
                            <div class="flex-div">
                              <div class="w-50 drppadding">
                                <div class="cb_label trackerlabel required">
                                  Credit Analyst
                                </div>
                                <div class="cb_input">
                                  <select class="mb-10 leadDrp custom-select" [(ngModel)]="creditAnayst" [disabled]="
                                  lane.keys != laneTaskIDDetail.lane.name">
                                    <option [value]="analyst.id" *ngFor="let analyst of creditAnalystList">
                                      {{ analyst.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="w-50 drppadding2">
                                <div class="cb_label trackerlabel required">
                                  Lead Underwriter
                                </div>
                                <div class="cb_input">
                                  <select class="mb-10 leadDrp custom-select" [(ngModel)]="leadUnderwriter" [disabled]="
                                  lane.keys != laneTaskIDDetail.lane.name">
                                    <option [value]="analyst.id" *ngFor="let analyst of creditAnalystList">
                                      {{ analyst.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div class="tmp" *ngIf="lane.keys == 'prepare-termsheet'">
                          <ng-template mat-tab-label>
                            <span> Prepare Term Sheet</span>
                          </ng-template>
                          <div class="cb_tabcell">
                            <div class="cb_header">
                              <h4>Prepare-Termsheet</h4>
                              <div>
                                <span class="toggle-div" *ngIf="
                                    laneTaskIDDetail.lane.board.name == 'exim'
                                  ">
                                  <img class="whatsappIcon" src="/assets/icons/whatsapp.svg" />
                                  <mat-slide-toggle class="toggleBtn" [(ngModel)]="whatsappActive"
                                    (click)="$event.stopPropagation()" (change)="onStatusChange($event)">
                                  </mat-slide-toggle>
                                </span>
                                <span *ngIf="
                                    additionalDetails &&
                                    additionalDetails.currentMilestone
                                  ">
                                  <button mat-stroked-button mat-flat-button type="submit"
                                    class="dashedbtn isEditLaneAccessForSales" *ngIf="
                                      laneTaskIDDetail.lane.board.name !=
                                      'domestic'
                                    " [matMenuTriggerFor]="milestone">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    Current Milestone:
                                    {{
                                    additionalDetails.currentMilestone
                                    .displaySequence
                                    }}.
                                    {{
                                    additionalDetails.currentMilestone.name
                                    }}
                                  </button>

                                  <mat-menu #milestone="matMenu">
                                    <div class="options-container" *ngFor="
                                        let option of mileStoneList;
                                        let i = index
                                      ">
                                      <button mat-menu-item (click)="selectMilestone(option, i)" [disabled]="
                                          shouldMilestoneDisabled(
                                       option.sequenceNumber ,additionalDetails.currentMilestone.sequenceNumber)
                                        ">
                                        {{ option.displaySequence }}.
                                        {{ option.name }}
                                      </button>
                                    </div>
                                  </mat-menu>
                                </span>

                                <button mat-stroked-button type="submit" class="dashedbtn"
                                  (click)="submitPrepareTermSheet('pdf')" style="margin-right: 10px">
                                  Generate Term Sheet
                                </button>
                                <button #saveButton2 mat-flat-button class="sv-btn" type="submit"
                                  (click)="saveTermSheetData()">
                                  Save
                                </button>
                                <button #saveButton2 mat-flat-button class="sv-btn DuplicateSave" type="submit"
                                  (click)="saveTermSheetData()" *ngIf="currentTabName != 'gst-details'">
                                  Save
                                </button>
                              </div>
                            </div>
                            <div class="dcmts">
                              <div class="document-chip-section d-flex flex-wrap"
                                *ngFor="let file of termSheetDocument">
                                <div class="document-chip" *ngIf="
                                    (file.documents && file.documents.id) ||
                                    (file.buyerDataList &&
                                      file.buyerDataList.length > 0)
                                  ">
                                  <span>{{
                                    file.documents
                                    ? file.documents.path
                                    : file.buyerDataList
                                    ? file.buyerDataList[0].buyerName
                                    : "-"
                                    }}</span>
                                  <!-- <a class="download-btn-1" (click)="downloadSingleDocument(file.documents.id)"><i -->
                                  <a class="download-btn-1" (click)="
                                      downloadUIGeneratedSingleDocument(file)
                                    "><i class="ri-download-line"></i></a>
                                  <a><i class="ri-close-line" (click)="deletePrepareTermDocs(file.id)"></i></a>
                                </div>
                              </div>
                            </div>
                            <div class="tm_mdlbox">
                              <div class="inner-tm-row">
                                <div class="cb_row">
                                  <div class="cb_label">
                                    <span *ngIf="
                                        laneTaskIDDetail.lane.board.name ==
                                        'exim'
                                      ">Lead/</span>Supplier Name
                                    <span style="color: red">*</span>
                                  </div>
                                  <div class="cb_input">
                                    <input type="text" placeholder="Enter Lead/Supplier Name"
                                      [(ngModel)]="leadNameSuplier" [disabled]="
                                        lane.keys != laneTaskIDDetail.lane.name
                                      " (focusout)="
                                        checkStringNull(
                                          leadNameSuplier,
                                          'supplierName'
                                        )
                                      " />
                                  </div>
                                </div>
                                <div class="cb_row">
                                  <div class="cb_label">
                                    <span *ngIf="
                                        laneTaskIDDetail.lane.board.name ==
                                        'exim'
                                      ">Lead/</span>Supplier Address
                                    <span style="color: red">*</span>
                                  </div>
                                  <div class="cb_input">
                                    <input type="text" placeholder="Enter Lead/Supplier Address"
                                      [(ngModel)]="leadSupplierAddress" [disabled]="
                                        lane.keys != laneTaskIDDetail.lane.name
                                      " (focusout)="
                                        checkStringNull(
                                          leadSupplierAddress,
                                          'supplierAddress'
                                        )
                                      " />
                                  </div>
                                </div>
                              </div>
                              <form [formGroup]="termSheetForm" (ngSubmit)="onSubmit()">
                                <div class="inner-tm-row">
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Funded Amount(Eligible%)
                                    </div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Enter Funded Amount" [readonly]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " formControlName="fundedAmount" [(ngModel)]="fundedAmount" />
                                    </div>
                                    <mat-error class="eror" *ngIf="
                                        termSheetForm.controls.fundedAmount
                                          .errors?.pattern
                                      ">
                                      only numeric value allowed</mat-error>
                                  </div>
                                  <div class="cb_row" *ngIf="
                                      laneTaskIDDetail.lane.board.name == 'exim'
                                    ">
                                    <div class="cb_label">
                                      Factoring Fee %
                                      <span style="color: red">*</span>
                                    </div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Please Enter Factoring Fee"
                                        formControlName="factoringFee" [(ngModel)]="factoringFee" [readonly]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " />
                                    </div>
                                    <mat-error class="eror" *ngIf="
                                        termSheetForm.controls.factoringFee
                                          .errors?.pattern
                                      ">
                                      only numeric value allowed</mat-error>
                                  </div>
                                </div>
                              </form>
                              <div class="cb_custombox cb_twocols" *ngIf="
                                  laneTaskIDDetail.lane.board.name == 'exim'
                                ">
                                <div class="cb_label">
                                  Discount Rate %<span style="color: red">*</span>
                                </div>
                                <div class="discountRateAndUplaod">
                                  <table class="w-50_new ml-n15" [ngClass]="{
                                      isEditLaneAccess: !isEditableAccess
                                    }">
                                    <thead *ngIf="
                                        bankRateSetting != null &&
                                        bankRateSetting.length > 0
                                      ">
                                      <tr>
                                        <td class="tdBorderEmail w-10 p-0">
                                          <label>Percentage(p.a)<span style="color: red">*</span></label>
                                        </td>
                                        <td class="tdBorderEmail w-10 p-0">
                                          <label>Currency<span style="color: red">*</span></label>
                                        </td>
                                        <td class="tdBorderEmail w-20 p-0">
                                          <label></label>
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="
                                          let dynamic of bankRateSetting;
                                          let i = index
                                        ">
                                        <td>
                                          <input class="perc-input" name="{{ dynamic.percentage }}"
                                            [(ngModel)]="dynamic.percentage" type="number" [disabled]="
                                              lane.keys != laneTaskIDDetail.lane.name"
                                              (focusout)="checkNull(
                                                bankRateSetting,
                                                'bankRateSettingSection',
                                                'percentage',
                                                true
                                              )" (change)="discountRateChange($event,i, bankRateSetting)"/>
                                        </td>
                                        <td>
                                          <!-- <select class="mb-10" name="{{dynamic.currency}}" [(ngModel)]="dynamic.currency"
                                            (change)="checkNull(bankRateSetting,'bankRateSettingSection',null, true)"
                                            style="width: 100px !important;"
                                            [disabled]="lane.keys != laneTaskIDDetail.lane.name">
                                            <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                              {{curr}}
                                            </option>
                                          </select> -->
                                          <mat-form-field class="selectDropdwnList small-width">
                                            <input autocomplete="off" type="text" [disabled]="
                                                lane.keys !=
                                                laneTaskIDDetail.lane.name
                                              " id="currency" #currency matInput (change)="
                                                checkNull(
                                                  bankRateSetting,
                                                  'bankRateSettingSection',
                                                  null,
                                                  true
                                                )
                                              " [(ngModel)]="dynamic.currency" [matAutocomplete]="priceAdjust"
                                              (keyup)="chnageCurrency($event)" (blur)="
                                                validateCurrencyOfInvoice($event)
                                              " (click)="$event.target.select()" />
                                            <i class="material-icons dropdown-ico pointer-none">
                                              arrow_drop_down
                                            </i>
                                            <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                            <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                                validateCurrencyOfInvoice($event)
                                              ">
                                              <mat-option class="found_optn" [value]="curr" *ngFor="
                                                  let curr of getCurrencyList
                                                ">
                                                {{ curr }}
                                              </mat-option>
                                              <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                            </mat-autocomplete>
                                          </mat-form-field>
                                        </td>
                                        <td>
                                          <a class="delete-icon-btn" [ngClass]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                                ? 'isEditable'
                                                : ''
                                            ">
                                            <i class="ri-close-line" (click)="
                                                deleteSheetRow(
                                                  i,
                                                  'discountRateSetting'
                                                )
                                              "></i></a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="btn-td">
                                          <a class="add-btn" (click)="addDiscountRateSetting()" [ngClass]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                                ? 'isEditable'
                                                : ''
                                            ">
                                            <mat-icon class="addIcon" title="Add Discount Rate Setting">
                                              add</mat-icon>
                                            Discount Rate %
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!-- Enaled it for Prepare termsheet Los chnages related to ROi Upload  -->
                                   <ng-container *ngIf="currentMilestoneName == 'ROI Approval' && isDiscountRateLessFromStandard  && !this.roiuploadFileID" class="file-uploaded-info">
                                     <div class="upload-container-discountRate">
                                         <label class="roiLabel">Upload ROI Approval</label>
                                         <input accept=".jpg, .jpeg, .png, .gif, .pdf" class="inpdddut" name="arquivo" id="arquivo" type="file" (change)="uploadROIDocument($event,'BANKING_STATEMENT')">
                                         <button [disabled]="lane.keys != laneTaskIDDetail.lane.name" (click)="uploadDiscountrateProofFile($event)"
                                         class="uploadbtn">Upload</button>
                                     </div>
                                   </ng-container>
                                  <div *ngIf="this.roiuploadFileID" class="file-uploaded-info">
                                    <p class="cb_label">Roi Approval File</p>
                                      <div class="roiInpWithCross">
                                        <button class="roidownloadBtn" (click)="downloadRoiUploadedFile(true)">
                                          {{roiFilename}}<mat-icon class="removeRoiFile">cloud_download</mat-icon></button>
                                          <span *ngIf="currentMilestoneName == 'ROI Approval'" class="removeRoi">
                                            <mat-icon  matTooltip="Remove" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          " (click)="enableReuploadRoi()">close</mat-icon>
                                          </span>
                                      </div>
                                </div>
                                </div>
                              </div>
                              <div class="cb_custombox cb_twocols" *ngIf="
                                  laneTaskIDDetail.lane.board.name == 'exim'
                                ">
                                <div class="cb_label">Bank Charges</div>
                                <table class="w-50_new ml-n15" [ngClass]="{
                                    isEditLaneAccess: !isEditableAccess
                                  }">
                                  <thead *ngIf="
                                      bankChargeArray != null &&
                                      bankChargeArray.length > 0
                                    ">
                                    <tr>
                                      <td class="tdBorderEmail w-40 p-0">
                                        <label>Amount<span style="color: red">*</span></label>
                                      </td>
                                      <td class="tdBorderEmail w-10 p-0">
                                        <label>Currency<span style="color: red">*</span></label>
                                      </td>
                                      <td class="tdBorderEmail w-20 p-0">
                                        <label></label>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="
                                        let dynamic of bankChargeArray;
                                        let i = index
                                      ">
                                      <td>
                                        <input class="mb-10 perc-input" name="{{ dynamic.amount }}" [(ngModel)]="
                                            dynamic.formttedBankChnarge
                                          " type="text" appAmountFormat (focusout)="
                                            checkNull(
                                              bankChargeArray,
                                              'bankChargesSection',
                                              null,
                                              false
                                            )
                                          " [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " />
                                      </td>
                                      <td>
                                        <!-- <select class="mb-10" name="{{dynamic.currency}}" [(ngModel)]="dynamic.currency"
                                          [disabled]="lane.keys != laneTaskIDDetail.lane.name"
                                          style="width: 100px !important;"
                                          (change)="checkNull(bankChargeArray,'bankChargesSection',null, true)">
                                          <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                            {{curr}}
                                          </option>
                                        </select> -->
                                        <mat-form-field class="mb-10 selectDropdwnList small-width">
                                          <input class="inptbx" autocomplete="off" type="text" id="currency" #currency
                                            matInput [(ngModel)]="dynamic.currency" [matAutocomplete]="priceAdjust"
                                            (keyup)="chnageCurrency($event)" (blur)="
                                              validateCurrencyOfInvoice($event)
                                            " (click)="$event.target.select()" [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " />
                                          <i class="material-icons dropdown-ico pointer-none">
                                            arrow_drop_down
                                          </i>
                                          <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                          <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                              validateCurrencyOfInvoice($event)
                                            ">
                                            <mat-option class="found_optn" [value]="curr" *ngFor="
                                                let curr of getCurrencyList
                                              ">
                                              {{ curr }}
                                            </mat-option>
                                            <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </td>
                                      <td>
                                        <a class="delete-icon-btn" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <i class="ri-close-line" (click)="
                                              deleteSheetRow(i, 'bankCharges')
                                            "></i></a>
                                        <!-- <i class="ri-close-line" (click)="deleteSheetRow(i, 'bankCharges')"></i> -->
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a class="add-btn" (click)="addBankCharges()" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <mat-icon class="addIcon" title="Add Bank Charges">add </mat-icon>Add Bank
                                          Charges
                                        </a>

                                        <!-- <mat-icon (click)="addBankCharges()" title="Add Bank Charges" class="addIcon">add</mat-icon> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <hr />
                              <div class="cb_custombox cb_tworows">
                                <h4 class="cb_label">Overdue Fees</h4>
                                <div class="inner-tm-row">
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Grace period (in days)
                                    </div>
                                    <div class="cb_input">
                                      <input type="number" placeholder="Enter Grace Period" [(ngModel)]="gracePeriod"
                                        [readonly]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " />
                                    </div>
                                    <mat-error class="eror" *ngIf="
                                        termSheetForm.controls.gracePeriod
                                          .errors?.pattern
                                      ">
                                      only numeric value allowed</mat-error>
                                  </div>
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Charges Beyond Grace period (% p.a)
                                    </div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Enter Charge Beyond Grace Period"
                                        [(ngModel)]="chargesBeyndGracePeriod" [readonly]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " appDeimalNumber />
                                    </div>
                                    <mat-error class="eror" *ngIf="
                                        termSheetForm.controls
                                          .chargesBeyndGracePeriod.errors
                                          ?.pattern
                                      ">
                                      only numeric value allowed</mat-error>
                                  </div>
                                </div>
                                <div class="cb_row" *ngIf="
                                    chargesBeyndGracePeriod > 4 &&
                                    laneTaskIDDetail.lane.board.name ==
                                      'domestic'
                                  ">
                                  <div class="cb_label">
                                    Discussed with Credit Team?
                                  </div>
                                  <div class="cb_input">
                                    <mat-checkbox [disabled]="
                                        lane.keys != laneTaskIDDetail.lane.name
                                      " (change)="
                                        onConfigCheckboxChange(
                                          isDiscussedWithCreditTeam
                                        )
                                      " formControlName="isDiscussedWithCreditTeam"
                                      [(ngModel)]="isDiscussedWithCreditTeam">
                                    </mat-checkbox>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="cb_custombox cb_twocols">
                                <div class="cb_label">
                                  Facility Fee <span style="color: red">*</span>
                                </div>
                                <table class="w-65 ml-n15" [ngClass]="{
                                    isEditLaneAccess: !isEditableAccess
                                  }">
                                  <thead *ngIf="
                                      faciliyFeeAmount != null &&
                                      faciliyFeeAmount.length > 0
                                    ">
                                    <tr>
                                      <td class="tdBorderEmail w-32 p-0">
                                        <label></label>
                                      </td>
                                      <td class="tdBorderEmail w-32 p-0">
                                        <label></label>
                                      </td>
                                      <td class="tdBorderEmail w-10 p-0">
                                        <label></label>
                                      </td>
                                      <td class="w-20"></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="
                                        let dynamic of faciliyFeeAmount;
                                        let i = index
                                      ">
                                      <td>
                                        <input class="mb-10" name="{{ dynamic.amount_percentage }}"
                                          placeholder="%percentage" [(ngModel)]="
                                            dynamic.amount_percentage
                                          " type="number" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " style="width: 100%" (focusout)="
                                            checkNull(
                                              faciliyFeeAmount,
                                              'faciliyFeeAmountSection',
                                              'amount_percentage',
                                              true
                                            )
                                          " />
                                      </td>
                                      <td>
                                        <input class="mb-10" name="{{ dynamic.amount }}" placeholder="Amount(Optional)"
                                          [(ngModel)]="dynamic.amount" type="text" appAmountFormat [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " style="width: 150px" />
                                      </td>
                                      <td>
                                        <!-- <select class="mb-10" name="{{dynamic.currency}}" [(ngModel)]="dynamic.currency"
                                          [disabled]="lane.keys != laneTaskIDDetail.lane.name || laneTaskIDDetail.lane.board.name =='domestic'"
                                          style="width: 100px !important;"
                                          (change)="checkNull(faciliyFeeAmount,'faciliyFeeAmountSection', null, true)">
                                          <option [value]="curr" *ngFor="let curr of getCurrencyList">
                                            {{curr}}
                                          </option>
                                        </select> -->
                                        <mat-form-field class="selectDropdwnList">
                                          <input [disabled]="
                                              lane.keys !=
                                              laneTaskIDDetail.lane.name
                                            " autocomplete="off" type="text" id="currency" #currency matInput
                                            [disabled]="
                                              lane.keys !=
                                                laneTaskIDDetail.lane.name ||
                                              laneTaskIDDetail.lane.board
                                                .name == 'domestic'
                                            " [(ngModel)]="dynamic.currency" [matAutocomplete]="priceAdjust"
                                            (keyup)="chnageCurrency($event)" placeholder="Currency" (blur)="
                                              validateCurrencyOfInvoice($event)
                                            " (click)="$event.target.select()" />
                                          <i class="material-icons dropdown-ico pointer-none">
                                            arrow_drop_down
                                          </i>
                                          <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                          <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                              validateCurrencyOfInvoice($event)
                                            ">
                                            <mat-option class="found_optn" [value]="curr" *ngFor="
                                                let curr of getCurrencyList
                                              ">
                                              {{ curr }}
                                            </mat-option>
                                            <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </td>
                                      <td>
                                        <a class="delete-icon-btn" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <i class="ri-close-line" (click)="
                                              deleteSheetRow(
                                                i,
                                                'faciliyFeeAmount'
                                              )
                                            "></i></a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a class="add-btn" (click)="addFacilityFeeAmount()" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <mat-icon class="addIcon" title="Add Discount Rate Setting">
                                            add</mat-icon>
                                          Facility Fee
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="cb_custombox cb_twocols" *ngIf="
                                  laneTaskIDDetail.lane.board.name == 'exim'
                                ">
                                <div class="cb_label">
                                  Discount Rate Setting
                                </div>
                                <table class="w-65 ml-n15" [ngClass]="{
                                    isEditLaneAccess: !isEditableAccess
                                  }">
                                  <thead *ngIf="
                                      discountRateArray != null &&
                                      discountRateArray.length > 0
                                    ">
                                    <tr>
                                      <td class="tdBorderEmail w-36 p-0">
                                        <label>Payment Terms(days)<span style="color: red">*</span></label>
                                      </td>
                                      <td class="tdBorderEmail w-36 p-0">
                                        <label>Minimum Days Charged<span style="color: red">*</span></label>
                                      </td>
                                      <td class="tdBorderEmail w-20 p-0">
                                        <label></label>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="
                                        let dynamic of discountRateArray;
                                        let i = index
                                      ">
                                      <td>
                                        <input class="mb-10" name="{{ dynamic.paymentTerms }}"
                                          [(ngModel)]="dynamic.paymentTerms" type="number" [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " (focusout)="
                                            checkNull(
                                              discountRateArray,
                                              'discountRateSection',
                                              'paymentTerms',
                                              true
                                            )
                                          " />
                                      </td>
                                      <td>
                                        <input class="mb-10" name="{{
                                            dynamic.minimumDaysCharged
                                          }}" [(ngModel)]="
                                            dynamic.minimumDaysCharged
                                          " type="number" (focusout)="
                                            checkNull(
                                              discountRateArray,
                                              'discountRateSection',
                                              'minimumDaysCharged',
                                              true
                                            )
                                          " [disabled]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                          " />
                                      </td>
                                      <td>
                                        <a class="delete-icon-btn" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <i class="ri-close-line" (click)="
                                              deleteSheetRow(i, 'discountRate')
                                            "></i></a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a class="add-btn" (click)="addDiscountRatePercentage()" [ngClass]="
                                            lane.keys !=
                                            laneTaskIDDetail.lane.name
                                              ? 'isEditable'
                                              : ''
                                          ">
                                          <mat-icon class="addIcon" title="Add Discount Rate Per">
                                            add</mat-icon>
                                          Discount Rate Setting
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tmp" *ngIf="lane.keys == 'rpa-sent'">
                          <ng-template mat-tab-label>
                            <span>RPA Sent</span>
                          </ng-template>
                          <div class="cb_tabcell">
                            <div class="cb_header">
                              <h4>Rpa-Sent</h4>
                              <div>
                                <span *ngIf="
                                    additionalDetails &&
                                    additionalDetails.currentMilestone
                                  ">
                                  <button mat-stroked-button mat-flat-button type="submit"
                                    class="dashedbtn isEditLaneAccessForSales" *ngIf="
                                      laneTaskIDDetail.lane.board.name !=
                                      'domestic'
                                    " [matMenuTriggerFor]="milestone">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    Current Milestone:
                                    {{
                                    additionalDetails.currentMilestone
                                    .displaySequence
                                    }}.
                                    {{
                                    additionalDetails.currentMilestone.name
                                    }}
                                  </button>

                                  <mat-menu #milestone="matMenu">
                                    <div class="options-container" *ngFor="let option of mileStoneList;let i = index">
                                      <button mat-menu-item (click)="selectMilestone(option,i)" [disabled]="shouldMilestoneDisabled(
                                          option.sequenceNumber ,additionalDetails.currentMilestone.sequenceNumber)">
                                        {{option.displaySequence}}. {{ option.name }}
                                      </button>
                                    </div>
                                  </mat-menu>
                                </span>
                                <!-- <button mat-stroked-button class="dashedbtn" (click)="downloadRPASheet()">Generate
                                  RPA</button> -->
                                <button mat-flat-button class="sv-btn" #saveButton3
                                  (click)="submitRPASheet()">Save</button>
                                <!-- <button *ngIf="currentTabName != 'gst-details'" mat-flat-button
                                  class="sv-btn DuplicateSave" #saveButton3 (click)="submitRPASheet()">
                                  Save
                                </button> -->
                              </div>
                            </div>
                            <!-- files on top as a card -->
                            <!-- <div class="dcmts">
                              <div class="document-chip-section d-flex flex-wrap" *ngFor="let file of RPADocumentList">
                                <div class="document-chip" *ngIf="file.path != null">
                                  <span [matTooltip]="file.path">{{
                                    file.path
                                    }}</span>
                                  <a class="download-btn-1" (click)="downloadRPASheet(file.id)"><i
                                      class="ri-download-line"></i></a>
                                </div>
                              </div>
                            </div> -->
                            <div class="cb_mdlbox">
                              <div class="headerBox fullWidth_element">
                                <div class="namePhone">
                                  <div class="cb_row">
                                    <div class="cb_label">Name</div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Enter Name" (mouseleave)="
                                          readonlyAttrLeadName = true
                                        " (click)="readonlyAttrLeadName = false" [(ngModel)]="loginUserName"
                                        [disabled]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " />
                                    </div>
                                  </div>
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Phone No<span style="color: red">*</span>
                                    </div>
                                    <div class="cb_input">
                                      <input type="number" placeholder="Enter Phone Number" (mouseleave)="
                                          readonlyAttrLeadName = true
                                        " (click)="readonlyAttrLeadName = false" [(ngModel)]="loginPhoneNumber"
                                        [disabled]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name" (keypress)="validatePhoneNumber()"
                                        (paste)="$event.preventDefault()" />
                                    </div>
                                  </div>
                                </div>
                                <div class="emailDesignation">
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Login Email-ID<span style="color: red">*</span>
                                    </div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Enter Email id" (mouseleave)="
                                          readonlyAttrLeadName = true
                                        " (click)="readonlyAttrLeadName = false" [(ngModel)]="loginUserEmail"
                                        [disabled]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " />
                                    </div>
                                  </div>
                                  <div class="cb_row">
                                    <div class="cb_label">
                                      Designation<span style="color: red">*</span>
                                    </div>
                                    <div class="cb_input">
                                      <input type="text" placeholder="Enter Designation" (mouseleave)="
                                          readonlyAttrLeadName = true
                                        " (click)="readonlyAttrLeadName = false" [(ngModel)]="loginDesignation"
                                        [disabled]="
                                          lane.keys !=
                                          laneTaskIDDetail.lane.name
                                        " />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cb_row">
                                <div class="cb_label">
                                  Business State<span style="color: red">*</span>
                                </div>
                                <div class="cb_input">
                                  <input type="text" placeholder="Enter Business State"
                                    (mouseleave)="readonlyAttrLeadName = true" (click)="readonlyAttrLeadName = false"
                                    [(ngModel)]="businessState" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </div>
                              </div>
                              <div class="cb_row">
                                <div class="cb_label">
                                  Business Type<span style="color: red">*</span>
                                </div>
                                <div class="cb_input">
                                  <input type="text" placeholder="Enter Business Type"
                                    (mouseleave)="readonlyAttrLeadName = true" (click)="readonlyAttrLeadName = false"
                                    [(ngModel)]="businessType" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </div>
                              </div>
                              <div class="cb_row">
                                <div class="cb_label">
                                  Discounting Fee<span style="color: red">*</span>
                                </div>
                                <div class="cb_input">
                                  <input type="number" placeholder="Enter Discounting Fees"
                                    (mouseleave)="readonlyAttrLeadName = true" (click)="readonlyAttrLeadName = false"
                                    [(ngModel)]="discountingFee" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </div>
                              </div>
                              <div class="cb_row">
                                <div class="cb_label">
                                  Funding Rate<span style="color: red">*</span>
                                </div>
                                <div class="cb_input">
                                  <input type="number" placeholder="Enter Funding Rate"
                                    (mouseleave)="readonlyAttrLeadName = true" (click)="readonlyAttrLeadName = false"
                                    [(ngModel)]="fundingRate" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </div>
                              </div>
                              <!-- rpa new field -->
                              <div class="cb_row">
                                <div class="cb_label">RPA Approved Amount<span style="color: red;">*</span></div>
                                <div class="cb_input"><input type="number" placeholder="Enter RPA Approved Amount"
                                    (mouseleave)="readonlyAttrLeadName=true" (click)="readonlyAttrLeadName=false;"
                                    [(ngModel)]="rpaApprovedAmount"
                                    [disabled]="lane.keys != laneTaskIDDetail.lane.name" />
                                </div>
                              </div>

                              <div class="cb_row">
                                <div class="cb_label">
                                  Agreement Effective Date<span style="color: red">*</span>
                                </div>
                                <div class="cb_input cb_date">
                                  <mat-form-field appearance="fill" class="date-picker-field w-65">
                                    <input matInput [matDatepicker]="effectivePicker"
                                      placeholder="Select Effective Date" (dateChange)="
                                        changeRpaDate($event, 'effectiveDate')
                                      " readonly [(ngModel)]="aggrementEffectiveDate" />
                                    <mat-datepicker-toggle matSuffix [for]="effectivePicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #effectivePicker>
                                    </mat-datepicker>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="cb_row cb_lastrow signingDateElement">
                                <div class="cb_label">
                                  Agreement Signing Date<span style="color: red">*</span>
                                </div>
                                <div class="cb_input cb_date">
                                  <mat-form-field appearance="fill" class="date-picker-field w-65">
                                    <input matInput [matDatepicker]="signingPicker" placeholder="Select Signing Date"
                                      (dateChange)="
                                        changeRpaDate($event, 'signingDate')
                                      " [(ngModel)]="aggrementSigningDate" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="signingPicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #signingPicker></mat-datepicker>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="cb_row signingDateElement">
                                <div class="cb_label">Upload Final RPA Doc<span style="color: red;">*</span></div>

                                <div class="document-chip-section d-flex flex-wrap" *ngIf="rpaManualDoc">
                                  <div class="document-chip">
                                    <span [matTooltip]="rpaManualDoc?.path">{{rpaManualDoc?.path}}</span>
                                    <a class="download-btn-1" (click)="downloadRpaDocument(rpaManualDoc.id)"><i
                                        class="ri-download-line"></i></a>
                                    <a><i class="ri-close-line" (click)="deleteRpaDocument(rpaManualDoc.id)"></i></a>
                                  </div>
                                </div>
                                <div draggable="true" class="dragArea config" ngFileDragDrop *ngIf="!rpaManualDoc"
                                  (fileDropped)="manualRPASheet($event,(lane.keys != laneTaskIDDetail.lane.name))"
                                  (click)="fileInput.click()">
                                  <span class="lbl">
                                    <input style="display: none" type="file"
                                      (change)="manualRPASheet($event,(lane.keys != laneTaskIDDetail.lane.name))"
                                      #fileInput [disabled]="lane.keys != laneTaskIDDetail.lane.name">
                                    <span (click)="fileInput.click()"><a>Browse
                                      </a></span>
                                  </span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="docFile" *ngIf="
                            fileUploadData != null &&
                            fileUploadData.length != 0 &&
                            laneTaskIDDetail.lane.board.name == 'exim'
                            && this.currentTabName != 'gst-details'">
                          <table [ngClass]="{ isEditLaneAccess: !isEditableAccess }">
                            <thead>
                              <tr>
                                <th>
                                  Apply For Insurance<span style="color: red">*</span>
                                </th>
                                <th>
                                  Buyer Legal Name<span style="color: red">*</span>
                                </th>
                                <th>
                                  Buyer Legal Address<span style="color: red">*</span>
                                </th>
                                <th>
                                  Nature of Business(Product/Service)<span style="color: red">*</span>
                                </th>
                                <th>
                                  Currency of Invoice<span style="color: red">*</span>
                                </th>
                                <th>
                                  Limit Required<span style="color: red">*</span>
                                </th>
                                <th>
                                  Tenor requested/ Net Payments terms(should be
                                  as per PO)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  Tenor from<span style="color: red">*</span>
                                </th>

                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Reason For decline<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Comment
                                </th>
                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Limit Recommended
                                </th>
                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Max Limit<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Number of days<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Starting from : Invoice/BL<span style="color: red">*</span>
                                </th>

                                <th *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance'
                                  ">
                                  Ledger with the buyers for last 12 months<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance'
                                  ">
                                  1 Set of complete shipping documents
                                  (PO,Invoice, SB, Quality Check,BL)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Total Limit Amount<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  Tenor (In Days)<span style="color: red">*</span>
                                </th>
                                <th>
                                  Buyer Geography<span style="color: red">*</span>
                                </th>
                                <th>Rating</th>

                                <ng-container *ngIf="
                                    fileUploadData != null &&
                                    fileUploadData.length > 0 &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'new-leads'
                                  ">
                                  <ng-container *ngFor="
                                      let header of insuranceProviders;
                                      let p = index
                                    ">
                                    <th>
                                      <span>Insurer ({{ p + 1 }}) -
                                        {{ header }}</span>
                                      <mat-icon *ngIf="lane.keys == 'eligiblity-check'" [matMenuTriggerFor]="menu1"
                                        class="moreicon">more_vert</mat-icon>
                                      <mat-menu #menu1="matMenu">
                                        <label (click)="deleteInsuranceDetails(p)" style="padding: 8px">Delete</label>
                                      </mat-menu>
                                    </th>
                                  </ng-container>
                                </ng-container>

                                <!-- <ng-container *ngIf="anyBuyerHasInsurerInfo === true"> -->
                                <th *ngIf="
                                    lane.keys == 'new-leads' ||
                                    lane.keys == 'pre-risk' ||
                                    lane.keys == 'account-created-sales'
                                  ">
                                  Insurer Name
                                </th>
                                <!-- </ng-container>                         -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="
                                  let fileData of fileUploadData;
                                  let i = index
                                ">
                                <td>
                                  <mat-checkbox (change)="(fileData.isSelected)" [(ngModel)]="fileData.isSelected"
                                    [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name ||
                                      lane.keys == 'eligiblity-check'
                                    " *ngIf="
                                      fileData.isSelected ||
                                      !fileData.showSelected
                                    ">
                                  </mat-checkbox>
                                  <mat-checkbox (change)="(fileData.isSelected)" [(ngModel)]="fileData.isSelected"
                                    [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " *ngIf="
                                      !fileData.isSelected &&
                                      fileData.showSelected
                                    ">
                                  </mat-checkbox>
                                  <button class="delete-btn" mat-icon-button aria-label="delete"
                                    (click)="deleteBuyerData(fileData.id, i)" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " *ngIf="
                                      laneTaskIDDetail.lane.name ==
                                        'account-created-sales' ||
                                      laneTaskIDDetail.lane.name == 'new-leads'
                                    ">
                                    <i class="ri-delete-bin-line buyrIcon"></i>
                                  </button>
                                  <mat-icon class="buyrIcon" *ngIf="
                                      laneTaskIDDetail.lane.name ==
                                        'account-created-sales' ||
                                      laneTaskIDDetail.lane.name == 'new-leads'
                                    " (click)="copyBuyerDataRow(fileData)">content_copy</mat-icon>
                                </td>

                                <td>
                                  <input [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name ||
                                      laneTaskIDDetail.lane.name == 'e-sign' ||
                                      laneTaskIDDetail.lane.name ==
                                        'cold-leads' ||
                                      laneTaskIDDetail.lane.name ==
                                        'insurance' ||
                                      laneTaskIDDetail.lane.name ==
                                        'buyer-acceptance'
                                    " [(ngModel)]="fileData.buyerName" />
                                </td>
                                <td>
                                  <input [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name ||
                                      laneTaskIDDetail.lane.name == 'e-sign' ||
                                      laneTaskIDDetail.lane.name ==
                                        'cold-leads' ||
                                      laneTaskIDDetail.lane.name ==
                                        'insurance' ||
                                      laneTaskIDDetail.lane.name ==
                                        'buyer-acceptance'
                                    " [(ngModel)]="fileData.buyerAddress" />
                                </td>

                                <td>
                                  <select class="mb-10" [(ngModel)]="fileData.natureOfBusiness" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'account-created-sales' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    ">
                                    <option value="PRODUCT">Product</option>
                                    <option value="SERVICE">Service</option>
                                  </select>
                                </td>
                                <td>
                                  <mat-form-field class="mb-10 selectDropdwnList">
                                    <input class="pad-r-25" autocomplete="off" type="text" [disabled]="
                                        laneTaskIDDetail.lane.name !=
                                          'account-created-sales' &&
                                        laneTaskIDDetail.lane.name !=
                                          'new-leads'
                                      " id="currencyOfInvoice" #currencyOfInvoice matInput
                                      [(ngModel)]="fileData.currencyOfInvoice" [matAutocomplete]="priceAdjust"
                                      (keyup)="chnageCurrency($event)" (blur)="validateCurrencyOfInvoice($event)"
                                      (click)="$event.target.select()" />
                                    <i class="material-icons dropdown-ico pointer-none">
                                      arrow_drop_down
                                    </i>
                                    <!-- (optionSelected)="currencyOfInvoiceSelected($event)" -->
                                    <mat-autocomplete #priceAdjust="matAutocomplete" (optionSelected)="
                                        validateCurrencyOfInvoice($event)
                                      ">
                                      <mat-option class="found_optn" [value]="curr"
                                        *ngFor="let curr of getCurrencyList">
                                        {{ curr }}
                                      </mat-option>
                                      <!-- <mat-option class="found_noptn">No Data Found</mat-option> -->
                                    </mat-autocomplete>
                                  </mat-form-field>
                                </td>
                                <td>
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'account-created-sales' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " [value]="
                                      fileData.formattedLimitRequired
                                        ? fileData.formattedLimitRequired
                                        : ''
                                    " (input)="
                                      chnageLimitrequiredInput(
                                        $event,
                                        fileData,
                                        i
                                      )
                                    " appAmountFormat />
                                </td>
                                <td>
                                  <input type="number" placeholder="In days" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'account-created-sales' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " [(ngModel)]="fileData.tenorRequested" />
                                </td>

                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  <select class="mb-10" [(ngModel)]="fileData.tenorFrom" [disabled]="
                                      laneTaskIDDetail.lane.name != 'pre-risk'
                                    ">
                                    <option value="InvoiceDate">
                                      Invoice Date
                                    </option>
                                    <option value="BL Date">BL Date</option>
                                    <option value="FCR Date">FCR Date</option>
                                    <option value="Cargo Handover Date">
                                      Cargo Handover Date
                                    </option>
                                    <option value="Buyer Acceptance Date">
                                      Buyer Acceptance Date
                                    </option>
                                    <option value="POD Date">POD Date</option>
                                  </select>
                                </td>
                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <select class="mb-10" [(ngModel)]="fileData.reasonForDecline" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'pre-risk' ||
                                      fileData.isSelected == true
                                    ">
                                    <option value="smallBuyer">
                                      Small Buyer
                                    </option>
                                    <option value="negativeIndustry">
                                      Negative Industry
                                    </option>
                                    <option value="negativeCountry">
                                      Negative Country
                                    </option>
                                    <option value="poorFinancials">
                                      Poor Financials
                                    </option>
                                    <option value="pastDefault">
                                      Past Default
                                    </option>
                                  </select>
                                </td>

                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name != 'pre-risk'
                                    " [(ngModel)]="fileData.declineReasonComment" />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'pre-risk' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " appAmountFormat (input)="
                                      chnageRecommendedInput(
                                        $event,
                                        fileData,
                                        i
                                      )
                                    " [value]="
                                      fileData.formattedLimitRecommended
                                        ? fileData.formattedLimitRecommended
                                        : ''
                                    " />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'eligiblity-check' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " appAmountFormat (input)="
                                      chnageMax_limitInput($event, fileData, i)
                                    " [value]="
                                      fileData.formattedMax_limit
                                        ? fileData.formattedMax_limit
                                        : ''
                                    " />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="number" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'pre-risk' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " [(ngModel)]="fileData.number_of_days" />
                                </td>
                                <!-- [disabled]="laneTaskIDDetail.lane.name != 'new-leads' && laneTaskIDDetail.lane.name != 'pre-risk'" -->
                                <td *ngIf="
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <select class="mb-10" [(ngModel)]="fileData.starting_from_invoice" [disabled]="true">
                                    <option value="InvoiceDate">
                                      Invoice Date
                                    </option>
                                    <option value="BLDate">BL Date</option>
                                  </select>
                                </td>

                                <td *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance'
                                  ">
                                  <section aria-label="documents" class="cb_fileinput">
                                    <div class="document-chip-section d-flex flex-wrap" *ngIf="
                                        fileData['ledgerDocs'] &&
                                        fileData['ledgerDocs'].length > 0
                                      ">
                                      <div class="document-chip" *ngFor="
                                          let docs of fileData['ledgerDocs'];
                                          let i = index
                                        ">
                                        <span [matTooltip]="docs.path">{{
                                          docs?.path
                                          }}</span>
                                        <a *ngIf="
                                            laneTaskIDDetail.lane.name ==
                                            'document-collection-submission'
                                          " class="download-btn-1" (click)="
                                            downloadSingleDocument(docs.id)
                                          "><i class="ri-download-line"></i></a>
                                        <a *ngIf="
                                            laneTaskIDDetail.lane.name ==
                                            'document-collection-submission'
                                          "><i class="ri-close-line" (click)="
                                              deleteDocument(
                                                fileData.id,
                                                docs.id
                                              )
                                            "></i></a>
                                      </div>
                                    </div>

                                    <div draggable="true" class="dragArea config" ngFileDragDrop (fileDropped)="
                                        fileUploadByBuyerId(
                                          $event,
                                          fileData,
                                          'ledger-document'
                                        )
                                      ">
                                      <span class="lbl" (click)="fileInput.click()">
                                        <input style="display: none" type="file" [disabled]="
                                            laneTaskIDDetail.lane.name !=
                                            'document-collection-submission'
                                          " (change)="
                                            fileUploadByBuyerId(
                                              $event,
                                              fileData,
                                              'ledger-document'
                                            )
                                          " #fileInput />
                                        <span (click)="fileInput.click()"><a>Browse </a></span>
                                      </span>
                                    </div>
                                  </section>
                                </td>
                                <td *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance'
                                  ">
                                  <section aria-label="documents" class="cb_fileinput">
                                    <div class="document-chip-section d-flex flex-wrap" *ngIf="
                                        fileData['documents'] &&
                                        fileData['documents'].length > 0
                                      ">
                                      <div class="document-chip" *ngFor="
                                          let docs of fileData['documents'];
                                          let i = index
                                        ">
                                        <span>{{ docs?.path }}</span>
                                        <a *ngIf="
                                            laneTaskIDDetail.lane.name ==
                                            'document-collection-submission'
                                          " class="download-btn-1" (click)="
                                            downloadSingleDocument(docs.id)
                                          "><i class="ri-download-line"></i></a>
                                        <a *ngIf="
                                            laneTaskIDDetail.lane.name ==
                                            'document-collection-submission'
                                          "><i class="ri-close-line" (click)="
                                              deleteDocument(
                                                fileData.id,
                                                docs.id
                                              )
                                            "></i></a>
                                      </div>
                                    </div>

                                    <div draggable="true" class="dragArea config" ngFileDragDrop (fileDropped)="
                                        fileUploadByBuyerId(
                                          $event,
                                          fileData,
                                          ''
                                        )
                                      " (click)="fileInput.click()">
                                      <span class="lbl">
                                        <input style="display: none" type="file" [disabled]="
                                            laneTaskIDDetail.lane.name !=
                                            'document-collection-submission'
                                          " (change)="
                                            fileUploadByBuyerId(
                                              $event,
                                              fileData,
                                              ''
                                            )
                                          " #fileInput />
                                        <span (click)="fileInput.click()"><a>Browse </a></span>
                                      </span>
                                    </div>
                                  </section>
                                </td>
                                <td *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="text" appAmountFormat (input)="
                                      chnageformattedTotalLimitAmountInput(
                                        $event,
                                        fileData,
                                        i
                                      )
                                    " [value]="
                                      fileData.formattedTotalLimitAmount
                                        ? fileData.formattedTotalLimitAmount
                                        : ''
                                    " />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'pre-risk' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'eligiblity-check'
                                  ">
                                  <input type="number" [(ngModel)]="fileData.tenor" />
                                </td>
                                <td>
                                  <select class="mb-10" [(ngModel)]="fileData.buyerGeography" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                        'account-created-sales' &&
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    ">
                                    <option [value]="curr" (chnage)="
                                        buyerGeographyChnage(
                                          fileData.buyerGeography
                                        )
                                      " *ngFor="let curr of buerGeography">
                                      {{ curr }}
                                    </option>
                                  </select>
                                </td>

                                <td>
                                  <input type="text" class="mb-10" [(ngModel)]="
                                      countryRatingList[fileData.buyerGeography]
                                    " [disabled]="true" />
                                </td>

                                <!-- <ng-container *ngFor="let insurer of insurerHeaders" > -->
                                <ng-container *ngFor="
                                    let file of fileData['insuranceInfo'];
                                    let k = index
                                  ">
                                  <td *ngIf="
                                      lane.keys != 'pre-risk' &&
                                      lane.keys != 'account-created-sales' &&
                                      lane.keys != 'new-leads'
                                    ">
                                    <mat-radio-group [disabled]="
                                        laneTaskIDDetail.lane.name !=
                                        'eligiblity-check'
                                      " class="radiogrp" (change)="radioChange($event)"
                                      [(ngModel)]="file.insuranceDataPresent" [checked]="file.insuranceDataPresent">
                                      <mat-radio-button class="filldata isEditLaneAccessForSales" [value]="true"
                                        (click)="
                                          openInsuredComapnyDataPopup(
                                            file,
                                            k,
                                            i
                                          )
                                        ">
                                        <p *ngIf="
                                            laneTaskIDDetail.lane.name ==
                                            'eligiblity-check'
                                          " class="fillInsurance">
                                          Add details
                                        </p>
                                        <p *ngIf="
                                            laneTaskIDDetail.lane.name !=
                                            'eligiblity-check'
                                          " class="fillInsurance">
                                          View Data
                                        </p>
                                      </mat-radio-button>
                                      <mat-radio-button class="radio2ndOption" [value]="false"
                                        [checked]="!file.insuranceDataPresent">
                                        N/A
                                      </mat-radio-button>
                                    </mat-radio-group>
                                  </td>
                                </ng-container>
                                <!-- </ng-container>         -->

                                <ng-container *ngFor="
                                    let file of fileUploadData[i][
                                      'insuranceInfo'
                                    ]
                                  ">
                                  <td *ngIf="
                                      (lane.keys == 'new-leads' ||
                                        lane.keys == 'pre-risk' ||
                                        lane.keys == 'account-created-sales') &&
                                      file.ignoreBeforeEligibility != true
                                    ">
                                    <input type="text" class="mb-10" [disabled]="disableInsurerInputBox"
                                      [(ngModel)]="file['insuranceProvider']" />
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="docFile" *ngIf="
                            fileUploadData != null &&
                            fileUploadData.length != 0 &&
                            laneTaskIDDetail.lane.board.name == 'domestic' &&
                            tabName != 'e-sign' &&
                            tabName != 'buyer-acceptance' &&
                            tabName != 'cold-leads'
                          ">
                          <table [ngClass]="{ isEditLaneAccess: !isEditableAccess }">
                            <thead>
                              <tr>
                                <th>Selected Buyer</th>
                                <th>
                                  Buyer Name<span style="color: red">*</span>
                                </th>
                                <th>Buyer Address</th>
                                <th>
                                  Buyer Rating <span style="color: red">*</span>
                                </th>
                                <th>
                                  Buyer TurnOver<span style="color: red">*</span>
                                </th>
                                <th *ngIf="lane.keys != 'new-leads'">
                                  Limit Requested<span style="color: red">*</span>
                                </th>
                                <th *ngIf="lane.keys != 'new-leads'">
                                  Tenor(days) requested<span style="color: red">*</span>
                                </th>
                                <th *ngIf="lane.keys != 'new-leads'">
                                  Value of Purchase Orders(next 6 months)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="lane.keys != 'new-leads'">
                                  Buyer Industry
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  Tenor(days) Issued<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  Discount Rate(%age)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  Min Period(editable days)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  Limit Issued(for buyer)<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  Funded Amount
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  Total Limit Approved<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  Total Limit Issued<span style="color: red">*</span>
                                </th>
                                <th *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  Total Limit Requested<span style="color: red">*</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="
                                  let fileData of fileUploadData;
                                  let i = index
                                ">
                                <td>
                                  <mat-checkbox (change)="(fileData.isSelected)" [(ngModel)]="fileData.isSelected"
                                    [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " *ngIf="
                                      fileData.isSelected ||
                                      !fileData.showSelected
                                    ">
                                  </mat-checkbox>
                                  <mat-checkbox (change)="(fileData.isSelected)" [checked]="fileData.showSelected"
                                    [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " *ngIf="
                                      !fileData.isSelected &&
                                      fileData.showSelected
                                    ">
                                  </mat-checkbox>
                                  <button class="delete-btn" mat-icon-button aria-label="delete"
                                    (click)="deleteBuyerData(fileData.id, i)" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " *ngIf="
                                      laneTaskIDDetail.lane.name ==
                                        'account-created-sales' ||
                                      laneTaskIDDetail.lane.name == 'new-leads'
                                    ">
                                    <i class="ri-delete-bin-line buyrIcon"></i>
                                  </button>
                                  <mat-icon class="buyrIcon" *ngIf="
                                      laneTaskIDDetail.lane.name ==
                                        'account-created-sales' ||
                                      laneTaskIDDetail.lane.name == 'new-leads'
                                    " (click)="copyBuyerDataRow(fileData)">
                                    content_copy</mat-icon>
                                </td>

                                <td>
                                  <input [(ngModel)]="fileData.buyerName" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </td>
                                <td>
                                  <input [(ngModel)]="fileData.buyerAddress" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </td>
                                <td>
                                  <input [(ngModel)]="fileData.buyerRating" [disabled]="
                                      lane.keys != laneTaskIDDetail.lane.name
                                    " />
                                </td>
                                <td>
                                  <input type="text" [(ngModel)]="fileData.buyerTurnover" appDeimalNumber [disabled]="
                                      laneTaskIDDetail.lane.name != 'new-leads'
                                    " />
                                </td>
                                <td *ngIf="lane.keys != 'new-leads'">
                                  <input [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'account-created-sales'
                                    " [(ngModel)]="fileData.requestedLimit" appDeimalNumber />
                                </td>
                                <td *ngIf="lane.keys != 'new-leads'">
                                  <input type="number" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'account-created-sales'
                                    " [(ngModel)]="fileData.tenorRequested" />
                                </td>

                                <td *ngIf="lane.keys != 'new-leads'">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'account-created-sales'
                                    " [(ngModel)]="fileData.valueOfPurchaseOrders" appDeimalNumber />
                                </td>

                                <td *ngIf="lane.keys != 'new-leads'">
                                  <select class="mb-10" [(ngModel)]="fileData.buyerIndustry" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'account-created-sales'
                                    ">
                                    <option [value]="curr" *ngFor="let curr of getIndustries">
                                      {{ curr }}
                                    </option>
                                  </select>
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  <input type="number" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'prepare-termsheet'
                                    " [(ngModel)]="fileData.tenorDaysIssued" />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'prepare-termsheet'
                                    " [(ngModel)]="fileData.discountRate" appDeimalNumber />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  <input type="number" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'prepare-termsheet'
                                    " [(ngModel)]="fileData.minimumTenor" />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'prepare-termsheet'
                                    " [(ngModel)]="fileData.limitIssued" appDeimalNumber />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'underwriting-stage-1'
                                    " [(ngModel)]="fileData.fundedAmount" appDeimalNumber />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'underwriting-stage-1'
                                    " [(ngModel)]="fileData.tenorDaysApproved" appDeimalNumber />
                                </td>

                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'underwriting-stage-1'
                                    " [(ngModel)]="fileData.totalLimitIssued" appDeimalNumber />
                                </td>
                                <td *ngIf="
                                    lane.keys != 'new-leads' &&
                                    lane.keys != 'account-created-sales' &&
                                    lane.keys != 'prepare-termsheet' &&
                                    lane.keys != 'term-sheet-acceptance' &&
                                    lane.keys != 'document-collection'
                                  ">
                                  <input type="text" [disabled]="
                                      laneTaskIDDetail.lane.name !=
                                      'underwriting-stage-1'
                                    " [(ngModel)]="fileData.totalLimitRequested" appDeimalNumber />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <!-- GST Details Tab Section -->
                        <ng-container *ngIf="lane.keys == 'gst-details' && currentTabName == 'gst-details'">
                          <app-gst-auto-generate [gstNumber]="fetchGstNumber()" [laneDetails]="laneTaskIDDetail">
                          </app-gst-auto-generate>
                        </ng-container>

                        <button type="button" mat-stroked-button class="cb_addmorebtn"
                          *ngIf="(lane.keys == laneTaskIDDetail.lane.name && (laneTaskIDDetail.lane.name =='account-created-sales' && laneTaskIDDetail.lane.board.name =='exim')  || (laneTaskIDDetail.lane.name == 'new-leads' && laneTaskIDDetail.lane.board.name =='domestic') )"
                          (click)="addBuyerDataRow()"> +
                          Add Row</button>
                      </div>
                    </mat-tab>
                  </mat-tab-group>

                </div>
              </mat-tab>
              <!-- history Tab -->
              <mat-tab id="second">
                <ng-template mat-tab-label>
                  <span>History</span>
                </ng-template>
                <section class="eximHistoryTabWrap">
                  <section class="srchEnginelistWrapper">
                    <div class="innerTableContainer">
                      <table mat-table [dataSource]="historyDataSource">
                        <ng-container matColumnDef="field">
                          <th mat-header-cell *matHeaderCellDef>Field</th>
                          <td mat-cell *matCellDef="let element">
                            {{ getHistoryField(element.oldValue) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="operation">
                          <th mat-header-cell *matHeaderCellDef>Operation</th>
                          <td mat-cell *matCellDef="let element">
                            {{ toLowerCase(element.operation) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="oldValue">
                          <th mat-header-cell *matHeaderCellDef>Old Value</th>
                          <td mat-cell *matCellDef="let element">
                            {{ getHistoryValue(element.oldValue) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="updatedValue">
                          <th mat-header-cell *matHeaderCellDef>
                            Updated value
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ getHistoryValue(element.updatedValue) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="remarks">
                          <th mat-header-cell *matHeaderCellDef>Remarks</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.field }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="actionBy">
                          <th mat-header-cell *matHeaderCellDef>Action By</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.user ? element.user.name : "-" }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="when">
                          <th mat-header-cell *matHeaderCellDef>When</th>
                          <td mat-cell *matCellDef="let element">{{element.time ? setTime(element.time) : ''}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                      </table>
                    </div>
                  </section>

                  <div class="pagination" fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
                    <div class="d-flex-rw" style="width: 100%">
                      <div class="pagination-wrapper" style="width: 100%">
                        <mat-paginator [hidden]="!dataSource" [pageIndex]="pageNumber" class="custom-pagintor"
                          [pageSize]="pageSize" showFirstLastButtons (page)="getMoreHistory($event)"
                          [length]="totalRecords">
                        </mat-paginator>
                      </div>
                    </div>
                  </div>
                </section>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </section>
    </div>
    <hr class="style-two" />
  </div>
</div>
<!---
  Termsheet Genration HTML Code *ngIf="isVisible"
  --->
<div *ngIf="isVisible">
  <div id="contentToConvert">
    <ng-container>

      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Termsheet-pdf</title>
      </head>

      <body style="margin: 0; padding: 0">
        <table cellspacing="0px" cellpadding="15px" style="
            font-family: Arial, Helvetica, sans-serif;
            max-width: 100%;
            width: 100%;
            border-collapse: collapse;
          ">
          <tr>
            <td style="
                width: 100%;
                box-sizing: border-box;
                padding: 10px 40px 0px 40px;
              ">
              <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
            </td>
          </tr>
        </table>
        <div style="padding: 0 40px; height: 413mm">
          <table cellspacing="0px" cellpadding="15px" style="
              font-family: Arial, Helvetica, sans-serif;
              max-width: 100%;
              width: 100%;
              border-collapse: collapse;
            ">
            <tr>
              <td style="width: 100%; box-sizing: border-box; padding: 0px">
                <table cellspacing="0px" cellpadding="0px" style="width: 100%; max-width: 100%">
                  <tr>
                    <td style="
                        width: 50%;
                        box-sizing: border-box;
                        color: #000000;
                        font-size: 24px;

                        line-height: 44px;
                        font-weight: 600;
                        text-align: left;
                      ">
                      <p>Date:{{ termsheetData.laneTask.termSheetGenerationDate | date : " dd-MMM-yy" }}</p>
                    </td>
                    <td style="
                        width: 50%;
                        box-sizing: border-box;
                        color: #000000;
                        font-size: 24px;
                        line-height: 44px;
                        padding-right: 20px;
                        font-weight: 600;
                        text-align: end;
                      ">
                      Ref. No. TS-{{termsheetData?.laneTask?.displayId?.split('-')[1]}}/{{ formatYear() }}/{{
                      termsheetData.sequenceNumber || ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;
                          margin-top: 30px;
                          margin: 0 0 5px;
                        ">
                        To,
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;
                          margin-top: 30px;
                          margin: 0 0 5px;
                        ">
                        {{ termsheetData.laneTask.name || "-" }},
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;
                          margin-top: 30px;
                          margin: 0 0 5px;
                        ">
                        {{ termsheetData.laneTask.data[1].value || "-" }},
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;
                          margin: 0 0 40px;
                        ">
                        {{ getLeadAddress(termsheetData.laneTask.data) }}
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;
                          font-weight: 600;
                          margin: 0 0 20px;
                        ">
                        Sub: {{ termsheetData.laneTask.name || "" }}
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 32px;

                          margin: 0 0 5px;
                        ">
                        Dear Sir(s),
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;

                          margin: 0 0 20px;
                        ">
                        Mogli Labs Asia Private Limited (‘Credlix’) is pleased
                        to share proposed terms towards providing an Exports
                        Factoring Facility to
                        {{
                        termsheetData.laneTask.name
                        ? termsheetData.laneTask.name
                        : "N/A"
                        }}. (Here inafter referred as “{{laneSortName}}” or “the Company”),
                        as outlined below. Kindly note that this proposal is not
                        a commitment, but represents our interest in providing
                        the financing as outlined below:
                      </p>
                    </td>
                  </tr>
                </table>
                <table cellspacing="0px" cellpadding="0px" style="width: 100%; max-width: 100%; margin-bottom: 0px">
                  <tr>
                    <td style="vertical-align: top; width: 33.33%">
                      <div style="
                          padding: 20px;
                          margin: 10px;
                          margin-left: 0;
                          box-sizing: border-box;
                          height: 190px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: bold;
                            margin: 0 0 10px;
                          ">
                          Exporter
                        </p>
                        <p style="
                            font-size: 24px;
                            margin: 10px 0 20px;
                            line-height: 31px;
                            color: #000000;
                          ">
                          {{
                          termsheetData.laneTask.name
                          ? termsheetData.laneTask.name
                          : "-"
                          }}
                        </p>
                      </div>
                    </td>
                    <td style="vertical-align: top; width: 33.33%">
                      <div style="
                          background-color: #f7fbfe;
                          padding: 20px;
                          margin: 10px;
                          box-sizing: border-box;
                          height: 190px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: bold;
                            margin: 0 0 10px;
                          ">
                          Factor
                        </p>
                        <p style="
                            font-size: 24px;
                            margin: 10px 0 20px;
                            line-height: 30px;
                            color: #000000;
                          ">
                          Mogli Labs Asia Private Limited,Singapore (referred as
                          “Credlix”)
                        </p>
                      </div>
                    </td>
                    <td style="vertical-align: top; width: 33.33%">
                      <div style="
                          box-sizing: border-box;
                          background-color: #f7fbfe;
                          padding: 20px;
                          margin: 10px;
                          height: 190px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                          margin-right: 0%;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: 600;
                            margin: 0 0 10px;
                          ">
                          Financing Rate
                          <br />
                        </p>

                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            margin: 0%;
                          ">
                          upto

                          <span style="
                              font-size: 48px;
                              line-height: 36px;
                              color: #076ac9;
                              font-weight: 700;
                              margin: 10px 0 20px;

                              vertical-align: middle;
                            ">
                            90%
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
                <table cellspacing="0px" cellpadding="0px" style="width: 100%; max-width: 100%; margin-bottom: 10px">
                  <tr>
                    <td style="vertical-align: top; width: 33.33%">
                      <div style="
                          background-color: #f7fbfe;
                          padding: 20px;
                          margin: 10px;
                          margin-left: 0;
                          box-sizing: border-box;
                          height: 300px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: bold;
                            margin: 0 0 10px;
                          ">
                          Facility Set-up Fee (Annually)
                        </p>
                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            margin: 0%;
                          ">
                          <span style="
                              color: #076ac9;
                              font-size: 48px;
                              line-height: 36px;
                              font-weight: bold;
                            ">
                            {{
                            termsheetData && termsheetData.facilityFeeAmount
                            ? termsheetData.facilityFeeAmount[0]
                            .amount_percentage
                            : "-"
                            }}%
                          </span>
                          of the Facility amount. Payable upon first
                          disbursement under the facility.
                        </p>
                        <p style="
                            font-size: 25px;
                            line-height: 34px;
                            color: #727272;
                            margin: 0%;
                          ">
                          <!-- Detail information on next page. -->
                        </p>
                      </div>
                    </td>
                    <td style="
                        box-sizing: border-box;
                        width: 33.33%;
                        vertical-align: top;
                      ">
                      <div style="
                          background-color: #f7fbfe;
                          padding: 20px;
                          margin: 10px;
                          box-sizing: border-box;
                          height: 300px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: bold;
                            margin: 0 0 10px;
                          ">
                          Discount Rate
                        </p>
                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            margin: 0%;
                          ">
                          <span style="
                              color: #076ac9;
                              font-size: 48px;
                              line-height: 36px;
                              font-weight: 600;
                            ">
                            {{
                            termsheetData.discountRateSetting
                            ? termsheetData.discountRateSetting[0]
                            .percentage
                            : "-"
                            }}% p.a
                          </span>
                          to be levied on “Funded Amount” from Value Date of
                          Transfer.
                        </p>
                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            margin: 0%;
                          ">
                          This will be deducted upfront on advance date
                        </p>
                      </div>
                    </td>
                    <td style="
                        box-sizing: border-box;
                        width: 33.33%;
                        vertical-align: top;
                      ">
                      <div style="
                          background-color: #f7fbfe;
                          padding: 20px;
                          margin: 10px;
                          margin-right: 0;
                          box-sizing: border-box;
                          height: 300px;
                          border: 1px solid #7e7e7e;
                          border-radius: 8px;
                        ">
                        <p style="
                            font-size: 28px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: 600;
                            margin: 0 0 10px;
                          ">
                          Factoring Fee
                        </p>
                        <p style="
                            font-size: 48px;
                            line-height: 36px;
                            color: #076ac9;
                            font-weight: 600;
                            margin-bottom: 10px;
                            margin-top: 0;
                          ">
                          {{
                          termsheetData.factoringFee == "0"
                          ? "Waived"
                          : "$" + termsheetData.factoringFee
                          }}
                        </p>
                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            font-weight: 600;
                            margin-bottom: 20px;
                            margin-top: 0;
                          ">
                          Bank Charges
                        </p>
                        <p style="
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;
                            margin: 0;
                          ">
                          <span style="
                              color: #076ac9;
                              font-size: 48px;
                              line-height: 36px;
                              font-weight: 600;
                            ">
                            {{ termsheetData.bankCharges[0].currency }}
                            {{ termsheetData.bankCharges[0].amount }}</span>
                          per remittance
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <!-- 1 buyer scenario -->
          <div *ngIf="termsheetData.buyerDataList.length == 1" style="padding: 0px">
            <table cellspacing="0px" cellpadding="0px" style="
                width: 100%;
                border: 1px solid #7e7e7e;
                background-color: #f7fbfe;
                border-radius: 8px;
              ">
              <tr *ngFor="let buyerData of termsheetData.buyerDataList">
                <td colspan="4" style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    padding: 20px;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  Buyer Name: <strong> {{ buyerData.buyerName || "-" }}</strong>
                </td>
              </tr>
              <tr *ngFor="let buyerData of termsheetData.buyerDataList">
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    padding: 20px;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  <strong> Buyer Address </strong><br />
                  {{ buyerData.buyerAddress || "-" }}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    padding: 20px;
                    color: black;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  <strong> Total Limit Amount </strong><br />
                  <span style="
                      color: #076ac9;
                      font-weight: 600;
                      font-size: 48px;
                      line-height: 50px;
                    ">
                    {{
                    dataService.getCurrencySign(buyerData.currencyOfInvoice)
                    }}
                    {{ buyerData.totalLimitAmount }}</span>
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    vertical-align: top;
                    padding: 20px;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  <strong> Payment Term</strong><br />
                  {{ buyerData.tenorFrom || "-" }}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    vertical-align: top;
                    color: black;
                    padding: 20px;
                  ">
                  <strong> Max. Tenor </strong><br />
                  Up to
                  <span style="color: #076ac9; font-weight: 600">
                    {{ buyerData.tenor || "" }} Days</span>
                  <span> from </span> {{buyerData.tenorFrom}}.
                </td>
              </tr>

              <tr *ngFor="let buyerData of termsheetData.buyerDataList">
                <td colspan="4" style="
                    box-sizing: border-box;
                    font-size: 24px;
                    padding: 20px;
                    line-height: 36px;
                    color: black;
                    border-top: 1px solid #7e7e7e;
                  ">
                  Funded Amount: This should be upto
                  <strong> 90% </strong>of Invoice amount (eligible account
                  receivable post advance, net of destination and other charges,
                  if any). Balance amount to be paid net of discount
                  charges/interest, upon realization of the receivable from the
                  respective Buyer.
                </td>
              </tr>
            </table>
          </div>

          <!-- new table -->
          <div *ngIf="termsheetData.buyerDataList.length > 1" style="padding: 0px">
            <table cellspacing="0px" cellpadding="0px" style="
                width: 100%;
                border: 1px solid #7e7e7e;
                background-color: #f7fbfe;
                border-radius: 8px;
              ">
              <tr>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    font-weight: 600;
                    padding: 20px;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  Sr No
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    padding: 20px;
                    color: black;
                    font-weight: 600;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  Currency
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    vertical-align: top;
                    padding: 20px;
                    font-weight: 600;

                    border-bottom: 1px solid #7e7e7e;
                  ">
                  Total Limit
                </td>
              </tr>
              <tr *ngIf="currenyHistory[0] && currenyHistory[0].value !=0">
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;

                    padding: 20px;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[0].srNo }}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    padding: 20px;
                    color: black;

                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[0].currency }}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    vertical-align: top;
                    padding: 20px;

                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[0].value }}
                </td>
              </tr>
              <tr *ngIf="currenyHistory[1] && currenyHistory[1].value!= 0">
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;

                    padding: 20px;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{currenyHistory[1].srNo}}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    padding: 20px;
                    color: black;

                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[1].currency }}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    vertical-align: top;
                    padding: 20px;

                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[1].value }}
                </td>
              </tr>
              <tr *ngIf="currenyHistory[2] && currenyHistory[2].value != 0">
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;

                    padding: 20px;
                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{currenyHistory[2].srNo}}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    padding: 20px;
                    color: black;

                    vertical-align: top;
                    border-right: 1px solid #7e7e7e;
                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{currenyHistory[2].currency}}
                </td>
                <td style="
                    box-sizing: border-box;
                    font-size: 24px;
                    line-height: 36px;
                    color: black;
                    vertical-align: top;
                    padding: 20px;

                    border-bottom: 1px solid #7e7e7e;
                  ">
                  {{ currenyHistory[2].value }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="padding: 0 40px">
          <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
            <tr>
              <td style="width: 60%; box-sizing: border-box; text-align: left">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Mogli Labs Asia Private Limited
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                </p>
              </td>
              <td style="width: 30%; box-sizing: border-box; text-align: right">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                    margin-top: 10px;
                  ">
                  Registration No.: 201941936Z
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.credlix.com</a>
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://business.moglix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.business.moglix.com</a>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <!-- Buyer Table Divition -->
        <div *ngIf="
            termsheetData.buyerDataList.length > 1 &&
            termsheetData.buyerDataList.length <= 4
          ">
          <table cellspacing="0px" cellpadding="15px" style="
              font-family: Arial, Helvetica, sans-serif;
              max-width: 100%;
              width: 100%;
              border-collapse: collapse;
            ">
            <tr>
              <td style="
                  width: 100%;
                  box-sizing: border-box;
                  padding: 30px 40px 60px 40px;
                ">
                <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
              </td>
            </tr>
          </table>

          <div style="padding: 0 40px; height: 400mm">
            <!-- 1-4 buyer sceanrio -->
            <table cellspacing="0px" cellpadding="0px" style="
                width: 100%;
                border: 1px solid black;
                border-radius: 8px;
                background-color: #f7fbfe;
              ">
              <div *ngFor="let buyerData of termsheetData.buyerDataList">
                <tr>
                  <td colspan="4" style="
                      box-sizing: border-box;
                      font-size: 24px;
                      line-height: 36px;
                      color: black;
                      padding: 20px;
                      border-bottom: 1px solid black;
                    ">
                    Buyer Name:
                    <strong>{{ buyerData.buyerName || "-" }}</strong>
                  </td>
                </tr>
                <tr>
                  <td style="
                      box-sizing: border-box;
                      font-size: 24px;
                      line-height: 36px;
                      color: black;
                      padding: 20px;
                      vertical-align: top;
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                    ">
                    <strong> Buyer Address </strong><br />
                    {{ buyerData.buyerAddress || "-" }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      font-size: 24px;
                      line-height: 36px;
                      vertical-align: top;
                      padding: 20px;
                      color: black;
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                    ">
                    <strong> Total Limit Amount </strong><br />
                    <span style="color: #076ac9; font-weight: 600; font-size: 48px">
                      {{
                      dataService.getCurrencySign(buyerData.currencyOfInvoice)
                      }}
                      {{ buyerData.totalLimitAmount }}</span>
                  </td>
                  <td style="
                      box-sizing: border-box;
                      font-size: 24px;
                      line-height: 36px;
                      color: black;
                      vertical-align: top;
                      padding: 20px;
                      border-right: 1px solid black;
                      border-bottom: 1px solid black;
                    ">
                    <strong> Payment Term</strong><br />
                    {{ buyerData.tenorFrom || "-" }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      font-size: 24px;
                      line-height: 36px;
                      vertical-align: top;
                      color: black;
                      padding: 20px;
                      border-bottom: 1px solid black;
                    ">
                    <strong> Max. Tenor </strong><br />
                    Up to
                    <span style="color: #076ac9; font-weight: 600; font-size: 48px">
                      {{ buyerData.tenor || "" }} Days</span>
                    <span> from </span>{{buyerData.tenorFrom}}.
                  </td>
                </tr>
              </div>
              <tr>
                <td colspan="4" style="
                    box-sizing: border-box;
                    font-size: 24px;
                    padding: 20px;
                    line-height: 36px;
                    color: black;
                    border-top: 1px solid #7e7e7e;
                  ">
                  Funded Amount:This should be upto
                  <strong> 90% </strong>of Invoice amount (eligible account
                  receivable post advance, net of destination and other charges,
                  if any). Balance amount to be paid net of discount
                  charges/interest, upon realization of the receivable from the
                  respective Buyer.
                </td>
              </tr>
            </table>
          </div>
          <div style="padding: 0 40px">
            <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
              <tr>
                <td style="width: 60%; box-sizing: border-box; text-align: left">
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                    ">
                    Mogli Labs Asia Private Limited
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                    ">
                    Registered Office: Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                  </p>
                </td>
                <td style="width: 30%; box-sizing: border-box; text-align: right">
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                      margin-top: 10px;
                    ">
                    Registration No.: 201941936Z
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #333e78;
                      margin: 0%;
                    ">
                    <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                      www.credlix.com</a>
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #333e78;
                      margin: 0%;
                    ">
                    <a href="https://business.moglix.com/" target="_blank"
                      style="text-decoration: none; color: #333e78">
                      www.business.moglix.com</a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 10 buyer table -->
        <div *ngIf="
            termsheetData.buyerDataList.length > 4 &&
            termsheetData.buyerDataList.length <= 10
          ">
          <table cellspacing="0px" cellpadding="15px" style="
              font-family: Arial, Helvetica, sans-serif;
              max-width: 100%;
              width: 100%;
              border-collapse: collapse;
            ">
            <tr>
              <td style="
                  width: 100%;
                  box-sizing: border-box;
                  padding: 30px 40px 60px 40px;
                ">
                <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
              </td>
            </tr>
          </table>

          <div style="padding: 0 40px; height: 400mm">
            <div *ngIf="
                termsheetData.buyerDataList &&
                termsheetData.buyerDataList.length
              " style="padding: 0px">
              <table cellspacing="0px" cellpadding="0px" style="
                  width: 100%;
                  max-width: 100%;
                  border-radius: 8px;
                  border: 1px solid #ddd;
                ">
                <tr>
                  <td style="
                      box-sizing: border-box;
                      padding: 20px 10px;
                      background: #f2f2f2;
                      border-bottom: 1px solid #adadad;
                      border-right: 1px solid #adadad;

                      font-size: 14px;
                      width: 21%;
                      font-weight: 700;
                      line-height: 16px;
                      color: #000000;
                    ">
                    Buyer Name
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 20px 10px;
                      background: #f2f2f2;
                      border-bottom: 1px solid #adadad;
                      font-size: 14px;
                      border-right: 1px solid #adadad;

                      width: 23%;
                      font-weight: 700;
                      line-height: 16px;
                      color: #000000;
                    ">
                    Country
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 20px 10px;
                      background: #f2f2f2;
                      width: 18%;
                      border-bottom: 1px solid #adadad;
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 16px;
                      border-right: 1px solid #adadad;
                    ">
                    Total limit Amount
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 20px 10px;
                      background: #f2f2f2;
                      width: 20%;
                      border-bottom: 1px solid #adadad;
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 16px;
                      border-right: 1px solid #adadad;

                      color: #000000;
                    ">
                    Payment Terms
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 20px 10px;
                      background: #f2f2f2;
                      width: 18%;
                      border-bottom: 1px solid #adadad;
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 16px;
                      color: #000000;
                    ">
                    Max Tenor (In Days)
                  </td>
                </tr>
                <tr *ngFor="let buyerData of termsheetData.buyerDataList" style="background: #fff">
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-bottom: 1px solid #adadad;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{ buyerData.buyerName || "-" }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-bottom: 1px solid #adadad;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{ buyerData.buyerAddress || "-" }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-bottom: 1px solid #adadad;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{
                    dataService.getCurrencySign(buyerData.currencyOfInvoice)
                    }}
                    {{ buyerData.totalLimitAmount }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-bottom: 1px solid #adadad;
                      border-right: 1px solid #adadad;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{ buyerData.tenorFrom || "-" }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-bottom: 1px solid #adadad;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{ buyerData.tenor || "-" }}
                  </td>
                </tr>

                <tr>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    "></td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    Total
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-right: 1px solid #adadad;

                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    ">
                    {{ getTotalLimitAmout() }}
                  </td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      border-right: 1px solid #adadad;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    "></td>
                  <td style="
                      box-sizing: border-box;
                      padding: 10px;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    "></td>
                </tr>
              </table>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 34px;
                  margin: 30px 0 40px;
                ">
                Funded Amount:This should be upto
                <strong> 90% </strong>of Invoice amount (eligible account
                receivable post advance, net of destination and other charges,if
                any). Balance amount to be paid net of discount
                charges/interest, upon realization of the receivable from the
                respective Buyer.
              </p>
            </div>
          </div>
          <div style="padding: 0 40px">
            <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
              <tr>
                <td style="width: 60%; box-sizing: border-box; text-align: left">
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                    ">
                    Mogli Labs Asia Private Limited
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                    ">
                    Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                  </p>
                </td>
                <td style="width: 30%; box-sizing: border-box; text-align: right">
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      margin: 0%;
                      margin-top: 10px;
                    ">
                    Registration No.: 201941936Z
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #333e78;
                      margin: 0%;
                    ">
                    <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                      www.credlix.com</a>
                  </p>
                  <p style="
                      font-size: 16px;
                      line-height: 24px;
                      color: #333e78;
                      margin: 0%;
                    ">
                    <a href="https://business.moglix.com/" target="_blank"
                      style="text-decoration: none; color: #333e78">
                      www.business.moglix.com</a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <table cellspacing="0px" cellpadding="15px" style="
            font-family: Arial, Helvetica, sans-serif;
            max-width: 100%;
            width: 100%;
            border-collapse: collapse;
          ">
          <tr>
            <td style="
                width: 100%;
                box-sizing: border-box;
                padding: 25px 40px 20px 40px;
              ">
              <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
            </td>
          </tr>
        </table>
        <div style="padding: 0 40px; height: 403mm">
          <table cellspacing="0px" cellpadding="20px" style="
              font-family: Arial, Helvetica, sans-serif;
              max-width: 100%;
              width: 100%;
              border-collapse: collapse;
            ">
            <tr>
              <td style="width: 100%; box-sizing: border-box; padding: 0">
                <table cellspacing="0px" cellpadding="0px" style="width: 100%; max-width: 100%">
                  <tr style="margin: 20px 0 0 0">
                    <td colspan="2" style="width: 100%; margin: 20px 0 0 0">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 0px;
                          margin-bottom: 20px;
                        ">
                        Documentation
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0px;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Purchase Order from Buyer
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Receivables Purchase Agreement
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        PDCs from exporter entity (for Trade Disputes)
                        equivalent to the limit amount
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Copy of Invoices, Packing List, B/L, proof of shipment
                        and delivery of Goods
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Undertaking from the Exporter entity (for Trade
                        Disputes)
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Notice of Assignment acknowledged from Buyer(s) – format
                        to be shared by Credlix
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Buyer(s) to confirm update the bank account details as
                        notified by Credlix. All payments from the Buyer(s) to
                        be made to bank account details as notified by Credlix
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Buyer(s) to confirm that there is no set off or dispute
                        on the said Invoice(s)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 30px;
                          margin-bottom: 20px;
                        ">
                        Disbursement
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        To be done within 2 (two) business days post submission
                        of transaction and Shipment Documents to the Factor as
                        per process notified by Credlix
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 30px;
                          margin-bottom: 20px;
                        ">
                        Overdue Fees (to the Account of Exporter)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        A Grace Period of {{termsheetData.overDueFees.gracePeriod}} calendar days from invoice due
                        date. Only the Discount Rate will continue to be charged
                        during & post this grace period
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        Beyond {{termsheetData.overDueFees.gracePeriod}} th day, an additional Overdue Fees of
                        {{termsheetData.overDueFees.chargesBeyondGracePeriod}}% p.a.
                        over and above the Discount Rate will be charged till
                        the invoice is paid in full.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 15px;
                        ">
                        For all LC at Sight transactions, the due date will be
                        defined as 21 days from the date of shipment
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div style="padding: 0 40px">
          <!-- yha1 -->
          <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
            <tr>
              <td style="width: 60%; box-sizing: border-box; text-align: left">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Mogli Labs Asia Private Limited
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                </p>
              </td>
              <td style="width: 30%; box-sizing: border-box; text-align: right">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                    margin-top: 10px;
                  ">
                  Registration No.: 201941936Z
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.credlix.com</a>
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://business.moglix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.business.moglix.com</a>
                </p>
              </td>
            </tr>
          </table>
        </div>

        <table cellspacing="0px" cellpadding="15px" style="
            font-family: Arial, Helvetica, sans-serif;
            max-width: 100%;
            width: 100%;
            border-collapse: collapse;
          ">
          <tr>
            <td style="
                width: 100%;
                box-sizing: border-box;
                padding: 25px 40px 20px 40px;
              ">
              <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
            </td>
          </tr>
        </table>

        <div style="padding: 0 40px; height: 408mm">
          <table cellspacing="0px" cellpadding="20px" style="
              font-family: Arial, Helvetica, sans-serif;
              max-width: 100%;
              width: 100%;
              border-collapse: collapse;
            ">
            <tr>
              <td style="width: 100%; box-sizing: border-box; padding: 0">
                <table cellspacing="0px" cellpadding="0px" style="width: 100%; max-width: 100%">
                  <tr style="margin: 20px 0 0 0">
                    <td colspan="2" style="width: 100%; margin: 20px 0 0 0">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        Discount Rate (On Funded Amount)
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        Minimum Period for which Discounting charges to be
                        levied:
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For 30 days payment terms: Minimum 25 days charge period
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For 45 days payment terms: Minimum 30 days charge period
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For 60 days payment terms: Minimum 45 days charge period
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="
                        width: 4%;
                        box-sizing: border-box;
                        margin-top: 0;
                        vertical-align: top;
                      ">
                      <img src="./asset/image/termsheetGreenTick.png" alt="txt" style="width: 30px; margin-top: 3px" />
                    </td>
                    <td style="width: 95%; box-sizing: border-box">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For 90 days payment terms: Minimum 60 days charge period
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For USD currency: Discount rates are linked to movements
                        in The Federal Reserve (USA) and Secured Overnight
                        Financing Rate (SOFR) rate.
                      </p>

                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For EUR currency: Discount rates are linked to movements
                        in Euribor (Euro Interbank Offered Rate)
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        For GBP currency: Discount rates are linked to movements
                        in Interest Rates by Bank of England
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0px 0 10px;
                        ">
                        Collection Charge Against Non-Factored Invoices: Collection charges @0.30% on invoice value will
                        be charged along with standard remittance charges.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 10px;
                          margin-bottom: 10px;
                        ">
                        KYC
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        KYC of {{laneSortName}} and Key Management Personnel to be done.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 30px;
                          margin-bottom: 10px;
                        ">
                        Plant Visit of Exporter
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        Annual and first visit to Exporter’s manufacturing
                        facility prior to disbursement
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <!-- <div *ngIf="screenWidth < 1600 && termsheetData.buyerDataList.length>4" style="height: 30mm; width: 100%;"></div> -->

                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 20px;
                          margin-bottom: 10px;
                        ">
                        Marine Insurance
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        Exporter to obtain and share copies of Marine Insurance
                        for each shipment prior to disbursement (For Non - FOB
                        shipments & non-Ex Works shipments).
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100%">
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          font-weight: 600;
                          margin-top: 30px;
                          margin-bottom: 10px;
                        ">
                        Information
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        Exporter to share quarterly MIS providing summary of
                        overall revenue, profitability and Total Debt of the
                        Exporter prior to limit set up and once every quarter
                        thereafter.
                      </p>
                      <p style="
                          font-size: 24px;
                          color: #000000;
                          line-height: 38px;
                          margin: 0%;
                          margin-top: 0px;
                          margin-bottom: 10px;
                        ">
                        This term sheet is for discussion purpose only and includes basic outline of the terms and conditions. It is subject to change and
                        does not contain all the terms, conditions and any other provisions involved that would be more fully described in our
                        documentation which will form basis of any contract.
                        By accepting this proposal, the directors, shareholders, key management team and the Exporter itself hereby gives a consent to the Factor
                        and or its affiliates / partners to conduct due diligence, obtain credit bureau reports, etc. on the Exporter and the directors, shareholders, key management team.
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div style="padding: 0 40px">
          <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
            <tr>
              <td style="width: 60%; box-sizing: border-box; text-align: left">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Mogli Labs Asia Private Limited
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                </p>
              </td>
              <td style="width: 30%; box-sizing: border-box; text-align: right">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                    margin-top: 10px;
                  ">
                  Registration No.: 201941936Z
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.credlix.com</a>
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://business.moglix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.business.moglix.com</a>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <table cellspacing="0px" cellpadding="15px" style="
            font-family: Arial, Helvetica, sans-serif;
            max-width: 100%;
            width: 100%;
            border-collapse: collapse;
          ">
          <tr>
            <td style="
                width: 100%;
                box-sizing: border-box;
                padding: 25px 40px 20px 40px;
              ">
              <img src="./asset/image/termsheetHeader.png" alt="txt" style="width: 100%" />
            </td>
          </tr>
        </table>
        <table cellspacing="0px" cellpadding="20px" style="
        font-family: Arial, Helvetica, sans-serif;
        max-width: 100%;
        width: 100%;
        border-collapse: collapse;
      ">
          <tr>
            <td colspan="2" style="width: 100%">
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  font-weight: 600;
                  margin-top: 20px;
                  margin-bottom: 10px;
                ">
                This proposal / Term Sheet and its conditions are to be
                valid for 7 days from the date of issuance
              </p>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  font-weight: 600;
                  margin-top: 10px;
                  margin-bottom: 10px;
                ">
                AGREED AND ACCEPTED:
              </p>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  margin-top: 0px;
                  margin-bottom: 30px;
                ">
                For {{ termsheetData.laneTask.name }} ({{laneSortName}})
              </p>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  margin-top: 0px;
                  margin-bottom: 15px;
                ">
                Name : _______________________________________
              </p>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  margin-top: 0px;
                  margin-bottom: 15px;
                ">
                Signature & Stamp : _______________________________
              </p>
              <p style="
                  font-size: 24px;
                  color: #000000;
                  line-height: 38px;
                  margin: 0%;
                  margin-top: 0px;
                  margin-bottom: 50px;
                ">
                Dated : _______________________________________
              </p>
            </td>
          </tr>
        </table>
        <div style="padding: 1140px 40px 0px">
          <table cellspacing="0px" cellpadding="0px" style="width: 100%; border-top: 2px solid black">
            <tr>
              <td style="width: 60%; box-sizing: border-box; text-align: left">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Mogli Labs Asia Private Limited
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                  ">
                  Registered Office: 11 Collyer Quay #17-00, The Arcade, Singapore 049317
                </p>
              </td>
              <td style="width: 30%; box-sizing: border-box; text-align: right">
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    margin: 0%;
                    margin-top: 10px;
                  ">
                  Registration No.: 201941936Z
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://www.credlix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.credlix.com</a>
                </p>
                <p style="
                    font-size: 16px;
                    line-height: 24px;
                    color: #333e78;
                    margin: 0%;
                  ">
                  <a href="https://business.moglix.com/" target="_blank" style="text-decoration: none; color: #333e78">
                    www.business.moglix.com</a>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </ng-container>
  </div>
</div>
