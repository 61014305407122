import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-uploadfile-payout',
  templateUrl: './uploadfile-payout.component.html',
  styleUrls: ['./uploadfile-payout.component.scss']
})
export class UploadfilePayoutComponent implements OnInit {
  file: any;
  public fieldID;
  fileResponse: boolean = false;
  PaymentDate: any;
  disabledFlag: boolean = true;
  escrowStatsCal: any;
  progressPer: number = 0;
  @Output() valueChange = new EventEmitter();
  interval: any;
  isProgressVisible: boolean = false;
  uploadedFileRes = [];
  isFileUploadedSuccess: boolean = false;
  isStatusDraft: boolean = false;
  UrlParam: any;
  uploadFileError: boolean = false;
  anchorName:string;
  constructor(public settingService: SettingService,
    public toasterService: ToastrService, public authService: AuthService, public router: Router, private activatedRoute: ActivatedRoute, public pagesService : PagesService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
    let credlixUserId = (localStorage.getItem("credlixUserID"))

    if(getLocalStorage.credlixAnchorList != null){
      for(let i=0; i< getLocalStorage.credlixAnchorList.length ;i++){
        if(getLocalStorage.credlixAnchorList[i].id == credlixUserId){
          this.anchorName= getLocalStorage.credlixAnchorList[i].displayName
        }       
      }
    }
     }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params != null && params.index == 0) {
        this.fieldID= params.id
        this.UrlParam = params
        this.isStatusDraft = true;
        this.settingService.getTransactionEntries(params.id).subscribe(res => {
          this.isFileUploadedSuccess = true;
          this.uploadedFileRes = res['result'];
          this.file = res['result'];
        })
      }
    });
    this.fetchEscrowStats();
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
    this.disabledFlag = false;
    this.isStatusDraft = true;
  }

  fileUpload(file?) {
    this.isProgressVisible = true;
    this.disabledFlag= true;

    if (file) {
      this.file = file;
      this.file.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    if (this.authService.isCredlixUser()) {
      uploadData.append('masterAccountId', localStorage.getItem('credlixUserID'));
    }
    this.settingService.uploadAnchorFile(uploadData).subscribe(file => {
      if (file['status'] == 200) {
        this.isFileUploadedSuccess = true;
        this.isProgressVisible = false;
        this.uploadedFileRes = file['result'];
        this.fieldID = file['result'].id;
        this.valueChange.emit(this.fieldID);
        localStorage.setItem("escrowFieldID", this.fieldID);
        sessionStorage.setItem("payoutId", file['result']['uniqueDisplayId']);
        this.settingService.getEscrowFieldId(this.fieldID);
        this.toasterService.success("Successfully uploaded");
        
      }
    }, (error) => {
      if(error['status']== 400){
        this.isProgressVisible= false;
        this.uploadFileError= true;
      }
    });
  }
  incr: number = 1;
  chunksFileLoaded(id?) {
    this.settingService.scheduleJobUploadFile(id).subscribe(res => {
      if (res['result']['status'] == 'IN PROCESS') {
        this.progressPer = Math.round((100 / res['result'].totalRecords) * res['result'].processedRecords);
        setTimeout(() => {
          this.chunksFileLoaded(id);
        }, 2000);
      }
      else {
        this.progressPer = 100;
        this.fieldID = res['result'].fileId;
        this.valueChange.emit(this.fieldID);
        localStorage.setItem("escrowFieldID", this.fieldID);
        this.settingService.getEscrowFieldId(this.fieldID);
        this.toasterService.success("Successfully uploaded");
        return;
      }
    })
  }

  refreshEscrowStats() {
    this.fetchEscrowStats();
  }

  fetchEscrowStats() {
    this.settingService.getEscrowSystemStats().subscribe(res => {
      this.escrowStatsCal = res['result'];
    })
  }
  clearStack(event) {
    event.target.value = null;
  }
  close() {
    if (this.disabledFlag && !this.uploadFileError) {
      this.settingService.rejectUploadedFile(this.fieldID).subscribe(res => {
        if (res['status'] == 200) {
          this.isStatusDraft = false;
          this.file = null;
          this.fileResponse = false;
          this.disabledFlag = true;
          this.isFileUploadedSuccess = false;
          this.isProgressVisible = false;
          this.toasterService.error("File has been Rejected");
        }
      })
    }
    else {
      this.file = null;
      this.fileResponse = false;
      this.disabledFlag = true;
      this.isStatusDraft = false;
      this.isFileUploadedSuccess = false;
    }
  }
  getPaymentDate(event) {
    let Start = event.value
    this.PaymentDate = moment(Start).format('YYYY-MM-DD');
    if (this.PaymentDate != '') {
      this.disabledFlag = false;
    }
  }

  templateDownloadCSV() {
     let arrObj = ['invoiceNumber','supplierId','amountExcludingTax','amount','deductions','utr','remarks','tax','transactionAmount','paymentDate']
    let obj = {
      "headerList" : arrObj
    }
    this.pagesService.templateDownload(obj).subscribe(res => {
      if (res) {
        this.toasterService.success("Template Download Successfully");
        var contentDispositionHeader = res.headers.get('content-disposition');
        let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        resultDownload= resultDownload.replace(/"/g, '');
        saveAs(res.body, resultDownload);
        return resultDownload.replace(/"/g, '');
      }
    });
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      if (files[0] != undefined) {
        this.file = files[0]
        //this.fileUpload(files[0]);
        this.disabledFlag = false;
        this.isStatusDraft = true;
      }
    }
  }
}
