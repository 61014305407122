import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatBottomSheet, MatSnackBar, MatOption, MatPaginator, MatSelect, MatSnackBarVerticalPosition, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import { SelectionModel } from '@angular/cdk/collections';
import { saveAs } from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import { CustomSnackbarComponent } from '../early-payments/early-payment-listing/custom-snackbar/custom-snackbar.component';
import { SidService } from '../sid/services/sid.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
@Component({
  selector: 'app-anchor-discount-dn',
  templateUrl: './anchor-discount-dn.component.html',
  styleUrls: ['./anchor-discount-dn.component.scss']
})
export class AnchorDiscountDnComponent implements OnInit {
  selectedTrIndex: number = -1;
  dnListing: any = [];
  isLoading: boolean = true;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  totalRecords: number;
  pageSize: number = 25;
  pageNumber: number = 0;
  supplierSearchKey: string;
  buyerSearchKey: string;
  statusCheckedList: any = [];
  activeSort = {};
  showCloseIcon: boolean = false;
  supplierIDModel: string;
  bulkAction: string;
  filteractive: boolean = false;
  autoSuggestloading: boolean = false
  setSupplierName: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matRef1') matRef1: MatSelect;
  @ViewChild('matRef4') matRef4: MatSelect;
  supplierName: string;
  supplierReportName: string;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  activeItem = 'Eligible Invoice- Highest First';
  selectedSortingName: string = 'Eligible Invoice- Highest First';
  selectedDraftInvoiceId = [];
  stroke = "0.00,100";

  selectedRadioCard: string = 'All Invoices';
  invoicesStats: any = {};
  appliedFiltersList = [];
  userType = 'invoices';
  urlParam: any;
  tableInvoiceDateModal;
  displayedColumns1 = ['debitNoteNumber', 'invoiceNumber', 'supplierId', 'supplierName', 'customerName',
    'amount', 'debitNoteDate', 'pathToPdf'];
  displayedColumns = ['debitNoteNumber', 'invoiceNumber', 'supplierId', 'supplierName', 'customerName',
    'amount', 'debitNoteDate', 'pathToPdf'];
  supplierId: string;
  selectedId: string;
  resultDownload;
  constructor(
    private router: Router,
    public datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    public authService: AuthService,
    public bottomSheet: MatBottomSheet,
    public toasterService: ToastrService,
    private sidService: SidService,
    private pageSerive: PagesService,
    public pagesService: PagesService,
    private _snackBar: MatSnackBar) {
    this.activatedRoute.params.subscribe(params => {
      this.urlParam = params
      console.log(params)
      this.userType = params.type;
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {

    let reqObj = this.createRequestObj();
    this.getinvoiceListing(reqObj);
  }

  downloadDebitNote() {
    let reqObj = this.createRequestObj();
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }

    this.pageSerive.downloadDiscountDebitNotes(reqObj)
      .toPromise().then(event => {
        this._snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 5000,
          verticalPosition: this.verticalPosition,
          panelClass: 'snack-class'
        });

      });
  }
  getNext(event) {
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    let reqObj = this.createRequestObj();
    this.getinvoiceListing(reqObj);
  }
  createRequestObj() {
    if (this.activeSort == null || this.activeSort['field'] == null) {
      this.activeSort = {
        "field": "updatedAt",
        "order": "depageIndexsc"
      };

      if (this.sort != null) {
        this.sort.sort({ id: "updatedAt", start: "desc", disableClear: true });
      }
    }

    let reqObj = {
      "from": this.paginator && this.paginator['pageIndex'] ? this.paginator['pageIndex'] * this.pageSize : 0,
      "size": this.pageSize,
      "where": {},
      "sort": {
        "field": "updatedAt",
        "order": "desc"
      }
    }

    if (this.statusCheckedList.length > 0) {
      reqObj['where']['status'] = {
        "type": "search_in",
        "value": this.statusCheckedList
      }
    }

    if (this.supplierSearchKey) {
      reqObj['where']['supplierId'] = {
        "type": "search",
        "value": this.supplierSearchKey
      }
    }

    if (this.buyerSearchKey) {
      reqObj['where']['customerAccount.searchKey'] = {
        "type": "search",
        "value": this.buyerSearchKey
      }
    }
    if (this.storedSearchID) {
      reqObj['where'] = {
        "supplierAccount.$id": {
          "type": "search",
          "value": this.storedSearchID
        }
      }
    }

    return reqObj;
  }

  getinvoiceListing(reqObj?) {

    if (reqObj && (this.storedSearchID != undefined && this.storedSearchID != '')) {
      reqObj['where'] = {
        "supplierAccount.$id": {
          "type": "search",
          "value": this.storedSearchID
        }
      }
    }
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }

    this.pageSerive.getDiscountDebitNotes(reqObj).toPromise().then(res => {
      this.dataSource.data = []
      this.totalRecords = 0;
      this.isLoading = false;
      if (res['result'] && res['result'].length > 0) {
        this.totalRecords = res['total'];
        this.dataSource.data = res['result'];
      }

    })
  }
  viewDiscountDN(element) {
    window.open(this.addhttp(element.pathToPdf), "_blank");
  }
  addhttp(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url;
  }
  downloadDN(element) {

    saveAs(this.addhttp(element.pathToPdf), element.debitNoteNumber + ".pdf");
  }
  searchResultListing = [];
  suggestedSuppliers = []
  onSearchSupplierID(id) {
    this.showCloseIcon = true;
    let obj = {
      "size": 40,
      "from": 0,
      "searchTerm": id
    }
    if (this.authService.isCredlixUser()) {
      obj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    this.pagesService.autoCompleteSearch(obj).subscribe(data => {
      this.searchResultListing = data['result'];
      let suppliers = this.searchResultListing.filter(value => {
        return value.documentType.toLowerCase() == 'supplier'
      })

      this.suggestedSuppliers = suppliers
      // sessionStorage.setItem("searchedText",this.searchText)
    })
  }
  storedSearchID: any;
  activeSupplierId: string;
  navigateToSearchedSupplier(list) {
    this.activeSupplierId = list.businessName
    if (list != null) {
      this.storedSearchID = list.id
      let reqObj = {}
      reqObj['where'] = {
        "supplierAccount.$id": {
          "type": "search",
          "value": list.id
        }
      }
      this.getinvoiceListing(reqObj)
    }
  }
  resetSearchText() {
    this.supplierIDModel = null;
    this.suggestedSuppliers = []
    this.storedSearchID = '';
    this.showCloseIcon = false;
    let reqObj = this.createRequestObj();
    this.getinvoiceListing(reqObj)
  }
  syncDebitNote() {
    if (this.selectedId) {
      let obj = {
        supplierId: this.selectedId,
        pageNo: 0,
        pageSize: 600
      }
      this.pagesService.syncDebitNotes(obj).subscribe(res => {
          this.toasterService.success("Debit Note Sync Started")
      })
    } else{
      this.toasterService.error("Please enter supplier id to sync")
    }
  }
  clearSync(){
    this.selectedId = null;
  }
}
