<!-- filters -->
<form [formGroup]="filter" (ngSubmit)="continue()">
  <div class="actionDiv">
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>Select Region<span class="text-danger">*</span></mat-label>
        <mat-select placeholder="Enter Region" formControlName="region" name="selectedRegions" multiple>
          <mat-option *ngFor="let lead of dataService.regionList" [value]="lead">{{lead}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <mat-form-field appearance="outline">
        <mat-label>RM <span class="text-danger">*</span></mat-label>
        <mat-select class="rmInput" formControlName="rm" matInput placeholder="selectedRelationshipManagers"
          name="selectedRelationshipManagers" multiple>
          <mat-option *ngFor="let rm of rmUserList" [value]="rm.id">
            {{ rm.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="searchBox">
      <input mat-input [matDatepicker]="picker1" (focus)="picker1.open()" class="setinpt alignInput"
        placeholder="Start Date" formControlName="startDate" readonly [(ngModel)]="toDate">
      <mat-datepicker-toggle style="display: none;" matSuffix [for]="picker1">
      </mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div class="searchBox">
      <input mat-input [matDatepicker]="picker2" (focus)="picker2.open()" class="setinpt alignInput"
        placeholder="End Date" formControlName="endDate" [min]="toDate" readonly>
      <mat-datepicker-toggle style="display: none;" matSuffix [for]="picker2">
      </mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </div>
    
    <div class="searchBox">
      <button mat-flat-button class="appbtn searchBtn" type="submit">Search</button>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn" (click)="clear()" type="button">Clear</button>
    </div>
  </div>
</form>

<!-- heading -->
<div class="heading">
  <p>Funnel Overview</p>
</div>

<!-- table data -->
<div class="table-data">
  <ng-container  *ngFor="let item of funnelData; let i = index">
  <div class="lead-data w-20">
    <div class="sales-data">
      <h4>Overall Funnel - {{funnelMonth(item.endDate)}}</h4>
      <p (click)="openDialogue(item.startDate,item.endDate,1,item.dashboardData['Sales Initiated'])">Sales Inititated
        <span>{{item.dashboardData['Sales Initiated']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,2,item.dashboardData['Insurance Applied'])">Insurance Applied
        <span>{{item.dashboardData['Insurance Applied']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,3,item.dashboardData['ROI Approval'])">ROI Approval
        <span>{{item.dashboardData['ROI Approval']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,4,item.dashboardData['TS Issued'])">TS Issued
        <span>{{item.dashboardData['TS Issued']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,5,item.dashboardData['TS Signed'])">TS Signed
        <span>{{item.dashboardData['TS Signed']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,6,item.dashboardData['In Underwriting'])">In Underwriting
        <span>{{item.dashboardData['In Underwriting']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,7,item.dashboardData['RPA Signed'])">RPA Signed
        <span>{{item.dashboardData['RPA Signed']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,8,item.dashboardData['Live'])">Live
        <span>{{item.dashboardData['Live']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
      <p (click)="openDialogue(item.startDate,item.endDate,9,item.dashboardData['Dropped'])">Dropped
        <span>{{item.dashboardData['Dropped']}}</span>
        <mat-icon>navigate_next</mat-icon>
      </p>
    </div>
  </div>
  <div class="graph-data w-30">
    <div class="graph-table-data">
      <h4>Overall Funnel - {{funnelMonth(item.endDate)}}</h4>
      <div class="all-funnel">
      <div class="funnel-row1">
        <span>
          <p>{{item.dashboardData['Sales Initiated']}}</p>
        </span>
      </div>
      <div class="funnel-row2">
        <span>
          <p>
            {{item.dashboardData['Insurance Applied']}}
          </p>
        </span>
      </div>
      <div class="funnel-row3">
        <span>
          <p>
            {{item.dashboardData['ROI Approval']}}
          </p>
        </span>
      </div>
      <div class="funnel-row4">
        <span>
          <p>{{item.dashboardData['TS Issued']}}</p>
        </span>
      </div>
      <div class="funnel-row5">
        <span>
          <p>
            {{item.dashboardData['TS Signed']}}
          </p>
        </span>
      </div>
      <div class="funnel-row6">
        <span> 
          <p>
            {{item.dashboardData['In Underwriting']}}
          </p>
        </span>
      </div>
      <div class="funnel-row7">
        <span>
          <p>
            {{item.dashboardData['RPA Signed']}}
          </p>
        </span>
      </div>
      <div class="funnel-row8">
        <span>
          <p>
            {{item.dashboardData['Live']}}
          </p>
        </span>
      </div>
    </div>
    </div>
  </div>
</ng-container>
</div>