import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[hasPermission]'
})
export class HasRolePermissionDirective {
  private currentUser;
  private permissions = [];


  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    
  ) {

  }

  ngOnInit() {
  let getSessionStorage=JSON.parse(localStorage.getItem("USERINFO"))
      this.currentUser= getSessionStorage.role.role['permissions']
      this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
        this.viewContainer.createEmbeddedView(this.templateRef)      
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;
    if (this.currentUser && this.currentUser) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUser.find(x => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
      }
    }
    return hasPermission;
  }
}
