import { Injectable } from '@angular/core';
import { params } from '../param';
import { urls } from '../url';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from '.././../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  isLeadsPage: boolean = false;
  constructor(private http: HttpClient) {
  }
  readonly BaseUrl = environment.BASEURL;
  readonly escrowUrl = environment.ESCROWURL;

  isLoading = new Subject<boolean>();
  escrowFilter = new BehaviorSubject<string>('');
  escrowFilter$ = this.escrowFilter.asObservable();
  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }
  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }
  selectAccount = new Subject();
  pageNotFoundPage = new Subject();

  _countSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _matcountSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _payoutElementSubjectBehv: BehaviorSubject<any> = new BehaviorSubject({});

  _EscrowFieldID: BehaviorSubject<any> = new BehaviorSubject('');

  _btnClickSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _invoicePaymentSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _supplierPaymentSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _paymentReviewSubjectBehv: BehaviorSubject<any> = new BehaviorSubject([]);
  _isFilterClickSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');
  _reviewTabIndexSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');
  _getTotalPayment: BehaviorSubject<any> = new BehaviorSubject('');


  isFilterClicked(event) {
    this._isFilterClickSubjectBehv.next(event);
  }


  getBtnClickEvent(event) {
    this._btnClickSubjectBehv.next(event);
  }

  getTotalPaymentTrans(event) {
    this._getTotalPayment.next(event);
  }

  getSupplierPayment(event) {
    this._supplierPaymentSubjectBehv.next(event);
  }
  getPaymentReview(event) {
    this._paymentReviewSubjectBehv.next(event);
  }

  getReviewTabIndex(event) {
    this._reviewTabIndexSubjectBehv.next(event);
  }
  getInvoicePayment(event) {
    this._invoicePaymentSubjectBehv.next(event);
  }

  getStepperCount(event) {
    this._countSubjectBehv.next(event);
  }
  getMatStepperCount(event) {
    this._matcountSubjectBehv.next(event);
  }
  getPayoutElement(event) {
    this._payoutElementSubjectBehv.next(event);
  }

  getEscrowFieldId(event) {
    this._EscrowFieldID.next(event);
  }

  _GSTINSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');

  _GSTINOnBoardData: BehaviorSubject<any> = new BehaviorSubject({});

  setBusinessAcountId(event) {
    this._AccountIdSubjectBehv.next(event);
  }

  _AccountIdSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');


  storeValidGSTIN(event) {
    this._GSTINSubjectBehv.next(event);
  }

  storeOnBoardData(event) {
    this._GSTINOnBoardData.next(event);
  }
  fetchAllRoles() {
    let url = this.BaseUrl + urls.getAllRoles;
    return this.http.get(url);
  }
  fetchAllPermissions() {
    let url = this.BaseUrl + urls.getAllPermissions;
    return this.http.get(url);
  }
  fetchAllPlants() {
    let url = this.BaseUrl + urls.getAllPlants
    return this.http.get(url);
  }
  addUser(obj) {
    let url = this.BaseUrl + urls.adduser
    return this.http.post(url, obj);
  }

  getUserDetails(obj) {
    let url = this.BaseUrl + urls.userDetails + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj.roleId)
      url = url + '&roleId=' + obj.roleId;
    return this.http.get(url);
  }

  assignRoles(obj) {
    let url = this.BaseUrl + urls.userAssignRoles
    return this.http.post(url, obj);
  }

  getStates() {
    let url = this.BaseUrl + urls.getAllStates
    return this.http.get(url);
  }

  addNewPlant(obj) {
    let url = this.BaseUrl + urls.getAllPlants
    return this.http.post(url, obj);
  }

  deleteRole(obj) {
    let url = this.BaseUrl + urls.deleteUserRole + "/" + obj.roleID
    return this.http.delete(url);
  }
  deleteReassignedRole(obj) {
    let url = this.BaseUrl + urls.deleteUserRole + "/" + obj.roleID + '?replacementRoleId=' + obj.replacementRoleId
    return this.http.delete(url);
  }
  createUserRole(obj) {
    let url = this.BaseUrl + urls.createUserRole
    return this.http.post(url, obj);
  }

  bulkUserUploaded(obj) {
    let url = this.BaseUrl + urls.bulkUserAdded
    return this.http.post(url, obj);
  }
  changeUserStatus(obj) {
    let url = this.BaseUrl + urls.userStatusUpdate + '?email=' + obj.email + '&status=' + obj.status + '&roleId=' + obj.customUserRoleId
    return this.http.get(url);
  }
  fetchProfileDetails() {
    let url = this.BaseUrl + urls.getUserProfile
    return this.http.get(url);
  }
  rejectPayment(utr,remarks){
    let url = this.BaseUrl + urls.rejectEscrowPayment + '?remarks=' + remarks + '&utr=' + utr
    return this.http.post(url,{});
  }
  approvePayment(utr){
    let url = this.BaseUrl + urls.approveEscrowPayment + '?utr=' + utr
    return this.http.post(url,{});
  }
  addSubUserDetails(obj) {
    let url = this.BaseUrl + urls.getUserProfile;
    return this.http.post(url, obj);
  }
  deleteSubUserDetails(id) {
    let url = this.BaseUrl + urls.getUserProfile + "/" + id;
    return this.http.delete(url);
  }

  fetchUserListAutosuggest(obj) {
    let url = this.BaseUrl + urls.userAutosuggest + '?searchKey=' + obj.searchKey
    return this.http.get(url);
  }

  isRoleAssigned(obj) {
    let url = this.BaseUrl + urls.checkRoleID + "/" + obj.roleID
    return this.http.get(url);

  }

  // allRoles: any;
  // getAllRoles() {
  //   this.fetchAllRoles().subscribe(list => {
  //     console.log(list);
  //     this.allRoles = list['result']

  //   });
  // }


  fetchCompanyDetails(gstin) {
    let url = this.BaseUrl + urls.companyDetails + "/" + gstin
    return this.http.get(url);
  }

  saveCompany(obj) {
    let url = this.BaseUrl + urls.companyDetails
    return this.http.post(url, obj);
  }
  saveDirectorDetails(obj) {
    let url = this.BaseUrl + urls.directorDetails
    return this.http.post(url, obj);
  }
  fetchDirectorDetails(gstin, id?) {
    let url = this.BaseUrl + urls.directorDetails + "/" + gstin
    if (id)
      url = url + "?businessAccountId=" + id;
    return this.http.get(url);
  }
  uploadDocuments(obj, file) {
    let url = this.BaseUrl + urls.uploadDocuments + "/" + obj.gstin + "/" + obj.type;
    if (obj.businessAccountId)
      url = url + "?businessAccountId=" + obj.businessAccountId;
    if (obj.docName)
      url = url + "&docName=" + obj.docName;
    return this.http.post(url, file);
  }
  fetchverifyDetails(gstin) {
    let url = this.BaseUrl + urls.verifyDetails + "/" + gstin
    return this.http.get(url);
  }
  checkGSTINExist(id?) {
    let url = this.BaseUrl + urls.checkOnBoardGSTIN;
    if (id) {
      url = url + "?businessAccountId=" + id;
    }
    return this.http.get(url);
  }

  submitOnBoardDetails(obj) {
    let url = this.BaseUrl + urls.submitDetails + "/" + obj.gstin
    if (obj.businessAccountId)
      url = url + "?businessAccountId=" + obj.businessAccountId;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "&anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(url, {});
  }
  uploadDocumentsDownload(obj) {
    let url = this.BaseUrl + urls.downloadDocuments + '?docType=' + obj.docType + '&gstin=' + obj.gstin;
    if (obj.businessAccountId)
      url = url + "&businessAccountId=" + obj.businessAccountId;
    if (obj.docName)
      url = url + "&docName=" + obj.docName;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  onBoardingOn(obj) {
    let url = this.BaseUrl + urls.onBoarding;
    return this.http.put(url, obj);
  }

  verifyInfo(id) {
    let url = this.BaseUrl + urls.verifyInfo + "?businessAccountId=" + id;
    return this.http.put(url, {});
  }

  downloadZip(id) {
    let url = this.BaseUrl + urls.downloadZip + "?businessAccountId=" + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  deleteDocuments(id, businessAccID?) {
    let url = this.BaseUrl + urls.deleteDocument + "?documentId=" + id;
    if (businessAccID)
      url = url + "&businessAccountId=" + businessAccID;
    return this.http.put(url, {});
  }

  addToLeads(businessIDs) {
    let url = this.BaseUrl + urls.addToLead + "?isLead=" + true;
    return this.http.put(url, businessIDs);
  }

  getWhatsappContent(obj, element) {
    console.log(element)
    if (element.value == 'EpInvoice') {
      let url = this.BaseUrl + urls.whatsappInvoiceMessageContent
      return this.http.post(url, obj);
    }

    else {
      let url = this.BaseUrl + urls.whatsappMessageContent
      return this.http.post(url, obj);

    }
  }

  fetchWhatsappUserList(id, value, type) {

    let url = '';
    if (type != null) {
      url =  '&type=' + type;
    }
    if (value != null) {
      url =  url + '&isWhatsapp=' + value
    }
    url = this.BaseUrl + urls.whatsappSubUserList + "?businessAccountId=" + id  + url;

    return this.http.get(url);
  }

  fetchUserList(id) {
    let url = this.BaseUrl + urls.getUserList + "?businessAccountId=" + id;
    return this.http.get(url);
  }

  addWhatsappContact(obj) {
    let url = this.BaseUrl + urls.getUserProfile;
    return this.http.post(url, obj);
  }

  setWhatsappNumber(obj) {
    let url = this.BaseUrl + urls.markWhatsappNumber;
    return this.http.put(url, obj);
  }
  sendWhatsappMsg(obj) {
    let url = this.BaseUrl + urls.sendWhatsappMsg;
    return this.http.post(url, obj);
  }
  sendEmailMsg(obj) {
    let url = this.BaseUrl + urls.sendEmailMsg;
    return this.http.post(url, obj);
  }
  checkWhatsappUserExist() {
    let url = this.BaseUrl + urls.checkWhatsapplogged;
    return this.http.get(url);
  }

  getCiData(obj) {
    let url = this.BaseUrl + urls.getCiData + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  getCiDetails(obj) {
    let url = this.BaseUrl + urls.getCiData + "/" + obj.id + '?pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  invoiceMarkDelayed(obj) {
    let url = this.BaseUrl + urls.EPMarkDelayed;
    return this.http.put(url, obj);
  }
  cancelInvoiceMarked(obj) {
    let url = this.BaseUrl + urls.cancelEPInvoice;
    return this.http.put(url, obj);
  }

  getEscrowPayments(obj) {
    let url = this.BaseUrl + urls.getEscrowPayments;
    return this.http.post(url, obj);
  }

  getSupplierEscrowPayments(obj) {
    let url = this.BaseUrl + urls.getSupplierEscrowPayments;
    return this.http.post(url, obj);
  }

  uploadSupplierPaymentDocs(obj, utrNumber){
    let URl = this.BaseUrl + urls.uploadSupplierPaymentFile + utrNumber;
    return this.http.post(URl, obj);
  }

  getEscrowDetailByID(id) {
    let URl = this.BaseUrl + urls.getEscrowDetail + "?escrowId=" + id
    return this.http.get(URl);
  }
  //For Borrower
  getAllStats(isLead) {
    let url = this.BaseUrl + urls.getAllBorrowerStats;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID');
    }
    if (isLead) {
      url = url + "?anchorMasterAccountId=" + "6228a749a2cee5063fd7573c";
    }
    return this.http.get(url);
  }

  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      result = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000) {
      result = (val / 1000).toFixed(2) + ' K';
    }
    return result || val;
  }
  discountPopup(obj) {
    let URl = this.BaseUrl + urls.discountPopup
    return this.http.post(URl, obj)
  }

  getSupplierMetrics(obj) {
    let url = this.BaseUrl + urls.getMetrics + "?businessAccountId=" + obj.businessAccountId + "&timePeriod=" + obj.timePeriod;
    return this.http.get(url);
  }

  //escrow Account
  getEscrowPayouts(obj) {
    let URl = this.BaseUrl + urls.getPayouts

    // if (localStorage.getItem('credlixUserID')) {
    //   URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID');
    // }
    return this.http.post(URl, obj);
  }
  uploadAnchorFile(obj) {
    let URl = this.BaseUrl + urls.uploadPayoutFile
    return this.http.post(URl, obj);
  }
  scheduleJobUploadFile(id) {
    let url = this.BaseUrl + urls.uploadedJobSchedule + "?uniqueId=" + id
    return this.http.get(url);

  }
  getInvoicesPayout(obj) {
    //  let fileId= localStorage.getItem('escrowFieldID');
    let URl = this.BaseUrl + urls.invoicesPayout;
    return this.http.post(URl, obj)
  }
  getSupplierPayout(obj) {
    let URl = this.BaseUrl + urls.supplierPayouts + "?fileId=" + obj.escrowId + '&pageNo=' + obj.from + '&pageSize=' + obj.size;
    if (obj.status != undefined) {
      URl = URl + '&status=' + obj.status
    }
    if (obj.id != undefined) {
      URl = URl + '&supplierId=' + obj.id
    }
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl)
  }
  verifyAndAcceptPayment(obj) {
    let URl = this.BaseUrl + urls.verifyTransactions;
    return this.http.post(URl, obj);
  }

  getFilePaymentTransaction(obj) {
    let URl = this.BaseUrl + urls.fileTransaction;
    return this.http.post(URl, obj)
  }

  getOTPPayment(obj) {
    let URl = this.BaseUrl + urls.getEscrowOTP + "?fileId=" + obj.fileId
    return this.http.get(URl, obj)
  }
  sendEscrowPaymentWithOTP(obj) {
    let URl = this.BaseUrl + urls.sendPaymentOTP;
    return this.http.post(URl, obj);
  }

  unMapPayoutTransactions(obj) {
    let URl = this.BaseUrl + urls.unmapTransaction
    return this.http.post(URl, obj)
  }
  mapPayoutTransactions(obj) {
    let URl = this.BaseUrl + urls.mapTransaction
    return this.http.post(URl, obj)
  }

  reviewPayoutTransaction(id, obj) {
    let URl = this.BaseUrl + urls.reviewPayoutFile + "?fileId=" + id
    return this.http.post(URl, obj)
  }

  getEscrowSystemStats() {
    let URl = this.BaseUrl + urls.escrowSystemStats
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl);
  }

  downloadPayoutFile(id) {
    let URl = this.BaseUrl + urls.downloadUploadedFile + "?fileId=" + id
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getTransactionEntries(id) {
    let URl = this.BaseUrl + urls.getPayouts + "/" + id
    return this.http.get(URl)
  }

  rejectUploadedFile(obj) {
    let URl = this.BaseUrl + urls.rejectUploadedFile
    return this.http.post(URl, obj)
  }

  downloadPayoutSheet(obj) {
    let URl = this.BaseUrl + urls.downloadPayoutSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  downloadPayoutTransactionSheet(id, reportType, obj) {
    let URl = this.BaseUrl + urls.downloadPayoutTransactionSheet + "?fileId=" + id + "&reportType=" + reportType
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getPayoutBalance() {
    let URl = this.BaseUrl + urls.payoutBalance
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl)
  }

  downloadBalanceSheet(obj) {
    let URl = this.BaseUrl + urls.balanceSheetDownload
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' })

  }
  downloadSupplierInvoices() {
    let URl = this.BaseUrl + urls.supplierInvoicesSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  downloadUnMappedBalance() {
    let URl = this.BaseUrl + urls.unmappedBalanceSheet
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "?anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  manualcreditForm(obj) {
    let URl = this.escrowUrl + urls.manualCreditForm
    return this.http.post(URl, obj)
  }
  getPayoutReport() {
    let URl = this.BaseUrl + urls.payoutReport
    return this.http.get(URl)
  }

  multipleAccount(obj) {
    let URl = this.BaseUrl + urls.multipleAccount
    return this.http.post(URl, obj)
  }
  setBydefaultuser(id) {
    let URl = this.BaseUrl + urls.bydefaultUserRole + "?roleId=" + id
    return this.http.get(URl)
  }
  addGstDetails(obj) {
    let url = this.BaseUrl + urls.getGstPdf + "?gstin=" + obj.gstin;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  getEmailTemplateValue(id) {
    let URl = this.BaseUrl + urls.requestEPtemplateValues + "?notificationId=" + id
    return this.http.get(URl)
  }

  OTPVerificationEP(obj) {
    let URl = this.BaseUrl + urls.requestEPOTPVerify
    return this.http.post(URl, obj)
  }
  downloadSystemInvoice(obj){
    let URL = this.BaseUrl + urls.downloadSystemInvoice
    if(obj.supplierId != null && ((obj.startDate != undefined || obj.startDate != null) && (obj.endDate != undefined || obj.endDate != null))){
     URL = URL + "?supplierId=" + obj.supplierId + "&startDate=" + obj.startDate + "&endDate=" + obj.endDate
    } else if(obj.supplierId == null && ((obj.startDate != undefined || obj.startDate != null) && (obj.endDate != undefined || obj.endDate != null))){
      URL = URL + "?startDate=" + obj.startDate + "&endDate=" + obj.endDate
    } else if(obj.supplierId != null && obj.startDate == null && obj.endDate == null){
      URL = URL + "?supplierId=" + obj.supplierId
    }
    return this.http.post(URL, null, { observe: 'response', responseType: 'blob' as 'json' })
  }
  downloadCIByMonth(obj){
    let URL = this.BaseUrl + urls.downloadSystemInvoiceByMonth
    if(((obj.startDate != undefined || obj.startDate != null) && (obj.endDate != undefined || obj.endDate != null))){
     URL = URL + "?startDate=" + obj.startDate + "&endDate=" + obj.endDate
    }
    return this.http.post(URL, null, { observe: 'response', responseType: 'blob' as 'json' })
  }
  updateVanNumber(obj) {
    let url = this.BaseUrl + urls.updateVan + "?oldVan=" + obj.oldVan + "&van=" + obj.newVan;
    return this.http.put(url, {});
  }
  getCiDatabyId(id) {
    let url = this.BaseUrl + urls.getCiById + '/' + id
    return this.http.get(url);
  }
  getLedgerReport(obj){
    let params = new HttpParams();
    obj.ids.forEach(id =>{
      params = params.append("supplierId", id);
    });
    params = params.append("pageNo",obj.from);
    params = params.append("pageSize",obj.size);
    let url = this.BaseUrl + urls.ledgerReport;
    return this.http.get(url, {params});
  }
  downloadLedgerReport(ids){
    let params = new HttpParams();
    ids.forEach(id =>{
      params = params.append("supplierId", id);
    });
    let url = this.BaseUrl + urls.downloadLedgerReport;
    return this.http.get(url, {params, observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadEPReport(obj){
    let url = this.BaseUrl + urls.downloadEPReport;
    return this.http.post(url, obj, {observe: 'response', responseType: 'blob' as 'json' });
  }
  setDate(date:Date){
    let d=date;
    d.setHours(d.getHours() + 5);
    d.setMinutes(d.getMinutes() + 30);
    return new Date(d)
  }
  setEscrowFilter(data) {
    this.escrowFilter.next(data)
  }
}
