import { Component, OnInit, Input, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { MatTableDataSource } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import * as lodash from 'lodash';
import * as moment from "moment";
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';



@Component({
  selector: 'app-lane-task-users-tab',
  templateUrl: './lane-task-users-tab.component.html',
  styleUrls: ['./lane-task-users-tab.component.scss']
})
export class LaneTaskUsersTabComponent implements OnInit {
  @Input() laneTaskDetailsArgs;
  @ViewChild('userListInput') userListInput: ElementRef<HTMLInputElement>;
  selectedUserListOptions = [];
  laneTaskIDDetail: any;
  userProfileName: string;
  deleteAssignList = [];
  options = [];
  allUserList = [];
  filteredUserList: Observable<string[]>;
  filteredWatcherList: Observable<string[]>;
  assigneeInputCtrl = new FormControl();
  userCtrl = new FormControl();
  newAssinee: any;
  myControl = new FormControl();
  selectedNewAssignee: string;

  constructor(private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<LaneTaskUsersTabComponent>, public preSalesService : PresaleService) {
    if(data.laneTask){
      this.laneTaskIDDetail = data.laneTask;
    }

  }

  ngOnInit() {
    if (this.laneTaskIDDetail != null) {
      this.loadAssignee();
    }
    this.getUserList();
  }


  getAssigneeName(name) {
    var matches = name.match(/\b(\w)/g);
    if (matches.length > 2) {
      matches.splice(2, matches.length)
    }
    this.userProfileName = matches.join('').toUpperCase()
    return this.userProfileName;
  }

  loadAssignee() {
    if (this.laneTaskIDDetail.assignee != null && this.laneTaskIDDetail.assignee.length > 0) {
      for (let i = 0; i < this.laneTaskIDDetail.assignee.length; i++) {
        if (this.laneTaskIDDetail.assignee[i].user) {
          this.selectedUserListOptions.push(this.laneTaskIDDetail.assignee[i].user['name']);
        }
      }
    }
  }
  getUserList() {
    if(this.data.preQualified){
      this._kService.getPreQualifiedUserList().subscribe(list =>{
        if (list['status'] == 200) {
          this.options = list['result'];
          this.getUserListResponse();
        }
      })
    } else {
      this._kService.getKanbanUserList().subscribe(list => {
        if (list['status'] == 200) {
          this.options = list['result'];
          this.getUserListResponse();
        }
      })
    }
  }
  getUserListResponse(){
    this.options.forEach(ele => {
    this.allUserList.push(ele.userName)
      })
    this.filteredUserList = this.myControl.valueChanges.pipe(
          startWith(null),
          map((userValue: string | null) => userValue ? this._filterUserList(userValue) : this.allUserList.slice()));

    this.filteredWatcherList = this.userCtrl.valueChanges.pipe(
          startWith(null),
          map((fruit: string | null) => fruit ? this._filterabc(fruit) : this.allUserList.slice()));
  }
  private _filterUserList(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allUserList.filter(user => user.toLowerCase().indexOf(filterValue) >= 0);
  }
  private _filterabc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserList.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  deleteSelectedAssignee(AssignName) {
    if (this.selectedUserListOptions.includes(AssignName)) {
      for (let i = 0; i < this.options.length; i++) {
        if (AssignName == this.options[i].userName) {
          this.deleteAssignList.push(this.options[i].userId);
          const index = this.selectedUserListOptions.indexOf(AssignName);
          if (index >= 0) {
            this.selectedUserListOptions.splice(index, 1);
          }
        }
      }
    }
    let obj = {
      laneTaskId: this.laneTaskIDDetail.id,
      assigneeIds: this.deleteAssignList
    }
    // this._kService.removeAssignee(obj).subscribe(res => {
    //   if (res['status'] == 200) {
    //     //this.getLaneTaskHistory()

    //   }myControl = new FormControl();
    // })
  }
  selectedUserList(event: MatAutocompleteSelectedEvent) {
    let newValue = event.option.viewValue;
    let isValPresent = this.allUserList.findIndex(x => { return x == event.option.viewValue });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(0, (event.option.viewValue.length - 15));
      newValue = subStr;
    }
    if (this.selectedUserListOptions.includes(newValue)) {
      //this.selectedUserListOptions = [this.selectedUserListOptions.filter(tag => tag !== newValue)];
    } else {
      this.selectedUserListOptions.push(newValue);
    }
    this.userListInput.nativeElement.value = '';
    this.assigneeInputCtrl.setValue(null);


    // this.assignMake(newValue);
  }

  selectedUser(event: MatAutocompleteSelectedEvent) {
    // let selectval = event.option.viewValue;
    this.selectedNewAssignee = event.option.viewValue;
  }
  openAssigneeAuto(trigger: MatAutocompleteTrigger) {
    // trigger.openPanel();
    // this.userListInput.nativeElement.focus();
  }

  addAssigneeInput(event) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.newAssinee = value;
    }
    console.log(this.newAssinee);

    if (input) {
      input.value = '';
    }
  }
  chaneAssigne() {
    let obj = []
    for (let i = 0; i < this.options.length; i++) {
      if (this.selectedNewAssignee == this.options[i].userName) {
        obj.push({
          "remarks": "test",
          "userId": this.options[i].userId
        })
        if(this.data.preQualified && !this.data.isBulk){
          let userObj = {
            userName : this.options[i].userName
          }
          this.preSalesService.changePreQualifiedAssignee(this.options[i].userId,this.laneTaskIDDetail.id).subscribe(res =>{
            if(res['result'] && res['status'] == 200){
              this.dialogRef.close(userObj);
            }
          })
        } else if(this.data.preQualified && this.data.isBulk) {
          let obj = {
            userId : this.options[i].userId
          }
            this.dialogRef.close(obj);
        } else {
          this._kService.addAssigneeToLaneTask([obj[0]], this.laneTaskIDDetail.id).subscribe(res => {
            this.dialogRef.close();
          });
        }
      }
    }
  }
  closeAssigneePopup() {
    this.dialogRef.close(true)
  }

}
