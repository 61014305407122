import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption, MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material';
import { environment } from '.././../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SettingService } from 'src/app/settings/setting.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DaterangepickerDirective, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { DownloadReconcileComponent } from '../../suppliers/listing/download-reconcile/download-reconcile.component';
import * as lodash from 'lodash';
import {ManualCreditformDialogComponent} from './manual-creditform-dialog/manual-creditform-dialog.component';
@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.scss']
})
export class PayoutsComponent implements OnInit {
  displayedColumns = ['payoutID', 'status', 'createdDate',
    'totalPayoutAmt', 'createdBy', 'fileName', 'download', 'lastAction'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  readonly BaseUrl = environment.BASEURL;
  payoutRes = [];
  _: any = lodash;
  pageSize: number = 25;
  totalRecords: number;
  pageNumber: number = 0;
  selectedCreatedDate: any;
  payoutFilterStatus = [];
  searchPayoutID: string = ''
  isFiltersShow: boolean = true;
  isApplyButtonEnable: boolean = true;
  payoutShowSearchIcon: boolean = true;
  payoutShowCloseIcon: boolean = false;
  searchFileName: string = ''
  fileShowSearchIcon: boolean = true;
  fileShowCloseIcon: boolean = false;
  searchCreatedBy: string = ''
  createdbyShowSearchIcon: boolean = true;
  createdbyShowCloseIcon: boolean = false;
  activeSort = {}
  sessionPayoutFilter: any;
  setPayoutStatus = [];
  anchorName: string;
  payoutBankBalance: any;
  payoutStatus: any = [
    { id: '1', name: 'Draft', value: 'DRAFT' },
    { id: '2', name: 'In Review', value: 'AWAITING_APPROVAL' },
    { id: '3', name: 'Payment Created', value: 'SUCCESS' },
    { id: '4', name: 'Payment Initiated', value: 'PAYMENT_INITIATED' },
    { id: '5', name: 'Partially Settled', value: 'PARTIALLY_SETTLED' },
    { id: '6', name: 'Paid', value: 'PAID' },
    { id: '7', name: 'Failed', value: 'FAILED' }
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matRef') matRef: MatSelect;
  @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;

  constructor(private http: HttpClient, public router: Router, public dialog: MatDialog, public toasterService: ToastrService, public settingService: SettingService,
    public authService: AuthService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    if (window.history.state.navigationId == 1) {
      localStorage.removeItem("payoutFilterState");
    }

    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"))
    let credlixUserId = (localStorage.getItem("credlixUserID"))

    if(getLocalStorage.credlixAnchorList != null){
      for(let i=0; i< getLocalStorage.credlixAnchorList.length ;i++){
        if(getLocalStorage.credlixAnchorList[i].id == credlixUserId){
          this.anchorName= getLocalStorage.credlixAnchorList[i].displayName
        }       
      }
    }
  }

  ngOnInit() {
    this.sessionPayoutFilter = JSON.parse(localStorage.getItem('payoutFilterState'))
    if (this.sessionPayoutFilter != null || this.sessionPayoutFilter != undefined) {
      this.isApplyButtonEnable = false;
      if (Object.keys(this.sessionPayoutFilter['where']).length != 0) {
        this.isFiltersShow = false;
      }
      if (this.sessionPayoutFilter['where'].hasOwnProperty('status')) {
        this.setPayoutStatus = (this.sessionPayoutFilter['where']['status']['value'])
        this.payoutFilterStatus = this.setPayoutStatus

      }
      if (this.sessionPayoutFilter['where'].hasOwnProperty('filename')) {
        this.searchFileName = this.sessionPayoutFilter['where']['filename']['value']
      }
      if (this.sessionPayoutFilter['where'].hasOwnProperty('uniqueDisplayId')) {
        this.searchPayoutID = this.sessionPayoutFilter['where']['uniqueDisplayId']['value']
      }
      if (this.sessionPayoutFilter['where'].hasOwnProperty('createdBy')) {
        this.searchCreatedBy = this.sessionPayoutFilter['where']['createdBy']['value']
      }
      if (this.sessionPayoutFilter['where'].hasOwnProperty('createdAt')) {
        let getStartDate = this.sessionPayoutFilter['where']['createdAt']['start_value']
        let getEndDate = this.sessionPayoutFilter['where']['createdAt']['end_value']
        this.selectedCreatedDate = getStartDate + " " + getEndDate
        this.startDate = getStartDate
        this.endDate = getEndDate
      }

    }
    if (this.activeSort == null || this.activeSort['field'] == null) {
      this.activeSort = {
        "field": "createdAt",
        "order": "desc"
      };
    }
    this.getPayoutBankBalance();
    this.fetchPayoutListing();
  }

  reqObj = {};
  fetchPayoutListing(value?, filename?) {
    this.checkSearchIconVisible(filename);
    this.checkFilterButtonCondition();
    
    if (this.paginator != null && value == false) {
      this.paginator.pageIndex = 0;
      this.reqObj['from'] = this.paginator.pageIndex * this.pageSize
    }
    else {
      this.reqObj['from'] = this.paginator.pageIndex * this.pageSize
    }
    this.reqObj['size'] = this.pageSize
    this.reqObj['where'] = {}
    if(this.activeSort != null){
    this.reqObj['sort'] = this.activeSort
    }else{
      this.reqObj['sort'] = {
        "field": "createdAt",
        "order": "desc"
      }
    }


    if (this.payoutFilterStatus != null && this.payoutFilterStatus.length > 0) {
      this.reqObj['where']['status'] = {
        "type": "in",
        "value": this.payoutFilterStatus
      }
    }
    if ((this.startDate != null && this.startDate != '') && (this.endDate != null && this.endDate != '')) {
      this.reqObj['where']['createdAt'] = {
        "type": "range",
        "start_value": this.startDate,
        "end_value": this.endDate
      }
    }
    if (this.searchPayoutID != null && this.searchPayoutID.trim() != '') {
      this.reqObj['where']['uniqueDisplayId'] = {
        "type": "search",
        "value": this.searchPayoutID
      }
    }
    if (this.searchFileName != null && this.searchFileName.trim() != '') {
      this.reqObj['where']['filename'] = {
        "type": "like",
        "value": this.searchFileName.trim()
      }
    }
    if (this.searchCreatedBy != null && this.searchCreatedBy.trim() != '') {
      this.reqObj['where']['createdBy'] = {
        "type": "like",
        "value": this.searchCreatedBy.toLowerCase()
      }
    }
    if (this.authService.isCredlixUser()) {
      this.reqObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    localStorage.setItem("payoutFilterState", JSON.stringify(this.reqObj));

    this.settingService.getEscrowPayouts(this.reqObj).subscribe(res => {
      this.payoutRes = res['result'];
      this.dataSource.data = this.payoutRes;
      this.totalRecords = res['total'];
    })

  }
  getPaginatorData(event) {
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    this.fetchPayoutListing(true);
  }

  getPayoutBankBalance() {
    this.settingService.getPayoutBalance().subscribe(res => {
      if (res['status'] == 200) {
        this.payoutBankBalance = res['result']
      }

    })
  }
  setStatusDot(value) {
    if (value == 'PAID') {
      return 'paidColor'
    }
    else if (value == 'PARTIALLY_SETTLED') {
      return 'partailyColor'
    }
    else if (value == 'AWAITING_APPROVAL') {
      return 'pendingColor'
    }
    else if (value == 'DRAFT') {
      return 'draftColor'
    }
    else if (value == 'APPROVED') {
      return 'approveColor'
    }
    else if (value == 'PAYMENT_INITIATED') {
      return 'initiateColor'
    }
    else if(value =='FAILED'){
      return 'failColor'
    }
    else if (value == 'REJECTED') {
      return 'rejectColor'
    }
  }
  createNewPayout() {
    sessionStorage.removeItem('supplierRes');
    sessionStorage.removeItem('invoiceRes');
    sessionStorage.removeItem('paymentRes');
    this.router.navigate(['/payouts-creation']);
  }

  getPayoutDetails(element, event) {
    sessionStorage.removeItem('supplierRes');
    sessionStorage.removeItem('invoiceRes');
    sessionStorage.removeItem('paymentRes');
    this.settingService.isFilterClicked(false);
    sessionStorage.removeItem("payoutId");
    localStorage.removeItem('tabIndex');
    localStorage.removeItem("Payment");
    if (element.status == 'PAYMENT_INITIATED' || element.status == 'APPROVED' || element.status == 'PAID' || element.status == 'PARTIALLY_SETTLED' || element.status == 'FAILED') {
      this.router.navigate(['/payouts-creation/', {
        id: element.id, index: 2
      }]);
    }
    else if (element.status == 'AWAITING_APPROVAL') {
      this.router.navigate(['/payouts-creation/', {
        id: element.id, index: 1
      }]);
    }
    else if (element.status == 'DRAFT') {
      this.router.navigate(['/payouts-creation/', {
        id: element.id, index: 0
      }]);
    }
    sessionStorage.setItem("payoutId", element.uniqueDisplayId);

    this.settingService.getPayoutElement(element);
    localStorage.removeItem("acceptPaymentClicked");

  }

  startDate: any;
  endDate: any;

  getCreatedDate(event) {
    if (event.startDate != null && event.endDate != null) {
      if (event.startDate._d == 'Invalid Date' || event.endDate._d == 'Invalid Date') {
        this.selectedCreatedDate = "";
        this.startDate = '';
        this.endDate = '';
        this.toasterService.error("Please select End Date");
      }
      else {
        let startDateMillisec = event.startDate._d
        this.startDate = moment(startDateMillisec).format('YYYY-MM-DD');
        let EndDateMillisec = event.endDate._d
        this.endDate = moment(EndDateMillisec).format('YYYY-MM-DD');
      }
    }
    this.checkFilterButtonCondition();
  }

  payoutTransactionAction(ele, value) {
    let obj = {
      fileId: ele.id
    }
    if (value == 'map') {
      this.settingService.mapPayoutTransactions(ele.id).subscribe(res => {
        if (res['result'] == true) {
          this.fetchPayoutListing();
        }
      })
    }
    else if (value == 'unmap') {
      this.settingService.unMapPayoutTransactions(ele.id).subscribe(res => {
        if (res['result'] == true) {
          this.fetchPayoutListing();
        }

      })

    }
  }
  checkInputText(text, name) {
    if (name == 'payout') {
      if (text == '') {
        this.payoutShowCloseIcon = false;
        this.payoutShowSearchIcon = true;
      }
      else {
        this.searchPayoutID = text
      }
    }
    else if (name == 'file') {
      if (text == '') {
        this.fileShowCloseIcon = false;
        this.fileShowSearchIcon = true;
      }
      else {
        this.searchFileName = text
      }
    }
    else if (name == 'created') {
      if (text == '') {
        this.createdbyShowCloseIcon = false;
        this.createdbyShowSearchIcon = true;
      }
      else {
        this.searchCreatedBy = text
      }
    }
    this.checkFilterButtonCondition();

  }
  openDatepicker() {
    this.pickerDirective.open();

  }

  getPayoutStatus(value) {
    this.payoutFilterStatus = value;
    this.checkFilterButtonCondition();
  }
  rejectTransactionFile(element) {
    this.settingService.rejectUploadedFile(element.id).subscribe(res => {
      if (res['status'] == 200) {
        this.fetchPayoutListing();
        this.toasterService.error("File has been Rejected");
      }
    })
  }

  clearPayoutFilters() {
    if (this.matRef) {
      this.matRef.options.forEach((data: MatOption) => data.deselect());
    }
    this.payoutFilterStatus = [];
    this.startDate = '';
    this.endDate = '';
    this.selectedCreatedDate = ''
    this.searchPayoutID = ''
    this.searchFileName = '';
    this.searchCreatedBy = '';
    this.fileShowCloseIcon = false;
    this.fileShowSearchIcon = true;
    this.payoutShowCloseIcon = false;
    this.payoutShowSearchIcon = true;
    this.createdbyShowCloseIcon = false;
    this.createdbyShowSearchIcon = true;
    if (this.paginator != null) {
      this.paginator.firstPage();
    }
    this.fetchPayoutListing();
    this.checkFilterButtonCondition();
  }

  filtersDisplay() {
    this.isFiltersShow = !this.isFiltersShow;
    this.settingService.isFilterClicked(this.isFiltersShow);
  }
  checkSearchIconVisible(value) {
    if (value == 'fileName') {
      this.fileShowCloseIcon = true;
      this.fileShowSearchIcon = false;
    }
    else if (value == 'payoutId') {
      this.payoutShowCloseIcon = true;
      this.payoutShowSearchIcon = false;
    }
    else if (value == 'createdBy') {
      this.createdbyShowCloseIcon = true;
      this.createdbyShowSearchIcon = false;
    }
  }

  resetSearchText(value) {
    if (value == 'fileName') {
      this.searchFileName = '';
      this.fileShowCloseIcon = false;
      this.fileShowSearchIcon = true;
    }
    else if (value == 'payoutId') {
      this.searchPayoutID = '';
      this.payoutShowCloseIcon = false;
      this.payoutShowSearchIcon = true;
    }
    else if (value == 'createdBy') {
      this.searchCreatedBy = '';
      this.createdbyShowCloseIcon = false;
      this.createdbyShowSearchIcon = true;
    }
    if (this.paginator != null) {
      this.paginator.firstPage();
    }
    this.checkFilterButtonCondition();
    this.fetchPayoutListing();
  }

  checkFilterButtonCondition() {
    if (this.searchFileName != '' || this.searchCreatedBy != '' || this.searchPayoutID != ''
      || this.payoutFilterStatus.length > 0 || (this.startDate != undefined && this.startDate != '') || (this.endDate != '' && this.endDate != undefined)) {
      this.isApplyButtonEnable = false;
    }
    else if (this.searchFileName == '' || this.searchCreatedBy == '' || this.searchPayoutID == ''
      || this.payoutFilterStatus.length == 0 || (this.startDate == undefined && this.startDate == '') || (this.endDate == '' && this.endDate == undefined)) {
      this.isApplyButtonEnable = true;
    }
  }

  sortData(event) {
    if (event != null) {
      this.activeSort = {}
      if(event.direction != null && event.direction.trim() != ''){
        this.activeSort['field'] = event.active;
        this.activeSort['order'] = event.direction;
      } else {
        this.activeSort['field'] = "createdAt";
        this.activeSort['order'] = "desc";
      }
      if (this.paginator != null) {
        this.paginator.pageIndex = 0;
      }
      this.fetchPayoutListing();
    }
  }

  formatPaymentType(value) {
    if (value == 'Awaiting_approval') {
      return 'In Review'
    }
    else if (value == 'Approved') {
      return 'Payment Created'
    }
    else if(value=='Rejected'){
      return 'Discarded'
    }
    return this._.startCase(value);
  }

  result;
  downloadPayoutFile(id) {
    this.settingService.downloadPayoutFile(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);    
    })
  }


  resultDownload;
  downloadSheet() {
    this.settingService.downloadPayoutSheet(this.reqObj).subscribe((res: any) => {
      this.downloadSheetCommonMethod(res);    
    })
  }

  downloadLedger(name) {
    let obj = {
      ledgerName: name,
      isPayout: true
    }
    const dialogRef = this.dialog.open(DownloadReconcileComponent, {
      data: obj,
      width: '500px',
    });

  }

  isMapped: boolean = false;
  downloadBalanceSheet(value) {
    if (value == 'mapped') {
      this.isMapped = true
    }
    else if (value == 'unMapped') {
      this.isMapped = false
    }
    let obj = {
      "where": {
        "fileStatus": {
          "type": "nin",
          "value": ["REJECTED"]
        },
        "statusQuery": {
          "type": "or",
          "queries": [
            {
              "field": "entryEscrowMatch.matched",
              "value": this.isMapped
            }
          ]
        }
      }
    }
    obj['masterAccountId'] = localStorage.getItem('credlixUserID');
    this.settingService.downloadBalanceSheet(obj).subscribe((res: any) => {
      this.downloadSheetCommonMethod(res);   
    })

  }

  downloadSupplierLedger(){
    this.settingService.downloadSupplierInvoices().subscribe((res: any) => {
      this.downloadSheetCommonMethod(res);    
    })
  }
  downloadUnMappedSheet(){
    this.settingService.downloadUnMappedBalance().subscribe((res: any) => {
      this.downloadSheetCommonMethod(res);    
    })
  }


  downloadSheetCommonMethod(res){
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
     let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }

  manualCreditForm(){
    const dialogRef = this.dialog.open(ManualCreditformDialogComponent, {
      data: '',
      width: '500px',
    });
  }
}
