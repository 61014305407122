export const dashboardData = {
    allInvoiceStats: 'allInvoiceStats',
    disbursedConversionFunnel: 'disbursedConversionFunnel',
    todayRequestedStats: 'todayRequestedStats',
    allConversionFunnel: 'allConversionFunnel',
    requestedConversionFunnel: 'requestedConversionFunnel',
    epRequestedInvoiceStats: 'epRequestedInvoiceStats',
    eligibleInvoiceStats: 'eligibleInvoiceStats',
    epDisbursedInvoiceStats: 'epDisbursedInvoiceStats',
    requestedBySource: 'requestedBySource',
    conversionFunnel: 'conversionFunnel'
}