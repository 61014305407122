import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';

@Component({
  selector: 'app-lead-on-qualifier',
  templateUrl: './lead-on-qualifier.component.html',
  styleUrls: ['./lead-on-qualifier.component.scss']
})
export class LeadOnQualifierComponent implements OnInit {
  businessType: string = "Exim"
  displayedColumns:any[]=['leadId','leadName','lastContactedDate','nextContactDate'];
  dataSource = new MatTableDataSource;

  constructor(public preSaleService : PresaleService,private router: Router,public dialogRef: MatDialogRef<LeadOnQualifierComponent>,public toasterService: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.businessType = data.businessType;
  }

  ngOnInit() {
    this.preSaleService.todayLead(this.businessType).subscribe({
      next:(resp:any)=>{
        this.dataSource = resp.result;
        if(resp.result.length == 0 ){
          // this.toasterService.error('There are no available leads to contact today.');
          this.dialogRef.close();
        }
      },
      error:(err:any)=>{
        this.toasterService.error('Something Went Wrong');
        this.dialogRef.close();
      }
    })
  }
  openLead(id){
    this.router.navigateByUrl(`/pre-qualified/${id}`);
    this.dialogRef.close();
  }

}
