export const environment = {
	BASEURL: 'https://apiuat.credlixlab.com/',
	credlixURL: 'https://uat.credlixlab.com',
	ESCROWURL: 'https://payuat.credlixlab.com/',
	EXIMAUTOSALES: 'https://exim-auto-sale.credlixlab.com/',
	MOGLIXKANBAN: 'https://moglix-kanban.credlixlab.com/',
	UploadMappingURL: 'https://credlix-data.integration.credlixlab.com/',
	CREDLIXKANBAN: 'https://uat-credlix-kanban.credlixlab.com/',
	ONBOARDDATA: 'https://credlix-onboard-data.credlixlab.com/',
	EsSEARCH: 'https://uat-es-index-service.credlixlab.com/',
	CREDLIXANCHOR: 'https://credlix-anchor-uat.credlixlab.com/',
	bussinessBaseUrl: 'https://credlix-business-backend.credlixlab.com/',
	CREDLIX_REPORT_URL: 'https://credlixreport-uat.credlixlab.com/',
	JK_FENNER_ANCHOR_ID: '65fbdd852d8bec3eaec21fb9',
	CAPTCH_SITE_KEY: '6LdgoIUqAAAAANPSQNdncrDo9dmX5tziDfWGy04N',
	production: false,
}
