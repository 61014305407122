<div class="addSupp" *ngIf="isfileUploadVisible">
  <div class="header">
    <h5>Import single supplier</h5>
    <span class="material-icons" (click)="closeDialog()"> close </span>
  </div>
  <section class="inputCta">
    <mat-form-field appearance="outline">
      <mat-label>Supplier ID</mat-label>
      <input type="number" matInput [(ngModel)]="supplierID">
    </mat-form-field>
    <button mat-raised-button (click)="addSuppliers(supplierID)">Import</button>
  </section>
  <div class="toggleText">
    <mat-checkbox [(ngModel)]="isEmailChecked" (change)="sendWelcomeEmail($event.checked)">
    </mat-checkbox>
    <span class="lbl">Send welcome email.</span>
  </div>
  <div class="ctaContent">
    <label>Import multiple suppliers</label>
    <button (click)="importMultipleSupplier()"> Upload CSV</button>
  </div>  
</div>


<div class="importSupplierWrap" *ngIf="isSelectedFileVisible">
  <div class="top">
    <h4> 
      <span class="lft">Import single supplier</span> 
      <span class="middle" style="cursor: pointer;" (click)="addsuplier()">Add Supplier ID</span>      
    </h4>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="dragSection">
    <h4>Import multiple suppliers</h4>
    <div class="dragArea" *ngIf="!file?.name">
      <span class="lbl">
        <input style="display: none" type="file" accept=".csv" [multiple]="false"
          (click)="clearStack($event)" (change)="onFileChanged($event)" #fileInput>
        <div><img style="height: 20px;margin-bottom: 10px;margin-left: 50%;" src="../../../../assets/images/excel.png">
        </div>
        <span (click)="fileInput.click()"><a>Click to Select</a></span>/ Drag the file here.
      </span>
    </div>
    <div class="file-upload" style="height: 90px; top: 5px;position: relative;" *ngIf="file?.name">
      <label style="display: block;color: #777777;
      font-size: 11px;">File:</label>
    <span class="filenamewithclose" ><span
      style=" position: relative;font-size: 13px;
      color: black;
      font-weight: 500;">Supplier List - {{file?.name}}</span>
    <i class="material-icons" (click)="close()"
      style="position: relative;
      top: 4px; cursor: pointer; font-size: 17px; left: 4px;">
      close
    </i></span>
  </div>
    <div class="buttonSection">
      <button class="dwldTemplate" (click)="templateDownloadCSV()" *ngIf="!file?.name">
        <i class="ri-download-line"></i>
        <a>Download Template</a>
      </button>  
      <div class="toggleText" *ngIf="file?.name">
          <mat-checkbox [(ngModel)]="isEmailChecked" (change)="sendWelcomeEmail($event.checked)">
            </mat-checkbox>
            <span class="lbl">&nbsp; &nbsp;Send welcome email</span>
      </div>
      <button class="mat-raised-button" (click)="fileUpload()" *ngIf="file?.name">Import</button>
    </div>    
  </div>  
</div> 





































<!-- <div class="addSupp">
  <h5>Add Supplier</h5>

  <mat-form-field appearance="outline">
    <mat-label>Supplier ID</mat-label>
    <input type="number" matInput [(ngModel)]="supplierID">
  </mat-form-field>
</div>
<div mat-dialog-actions class="btnOk">
  <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
  <button mat-raised-button (click)="addSuppliers(supplierID)">Ok</button>
</div> -->