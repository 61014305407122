import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { PagesService } from '../../pages/pages.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

enum PurchaseOrder {

}
export class PurchaseOrderDataSource implements DataSource<PurchaseOrder> {
  pageService: PagesService;
  paginator: MatPaginator;
  whereQuery: Object = {};
  isCustomer: Boolean;
  total: number = 0;
  from: number = 0;
  size: number = 10;
  matSort: object = {};
  sort: MatSort;
  public documentsSubject = new BehaviorSubject<PurchaseOrder[]>([]);
  public COLUMNS = ['check', 'documentType', 'searchKey', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'purchaseDate'];
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading = this.loadingSubject.asObservable();
  constructor(pageService: PagesService, paginator: MatPaginator, isCustomer: Boolean, matSort: MatSort) {
    this.pageService = pageService;
    this.paginator = paginator;
    this.sort = matSort;
    this.isCustomer = isCustomer;
  }

  connect(collectionViewer: CollectionViewer): Observable<PurchaseOrder[]> {
    return this.documentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.documentsSubject.complete();
    this.loadingSubject.complete();
  }
  checkInQuery(field) {
    return this.whereQuery[field] == null;
  }

  addToWhereQuery(field, value) {
    this.whereQuery[field] = value;
  }
  removeFromWhereQuery(field) {
    delete this.whereQuery[field];
    this.paginator.firstPage();
    this.loadDocuments();
  }
  removeFromWhereQueryWithoutLoad(field) {
    delete this.whereQuery[field];

  }
  forceLoad() {
    if (this.paginator && this.paginator.firstPage)
      this.paginator.firstPage();
    this.loadDocuments();
  }

  resetQuery() {
    this.whereQuery = {};
    this.paginator.firstPage();
    this.loadDocuments();
  }
  addSort(activeSort) {
    if (activeSort != null) {
      let field = activeSort.active
      let order = activeSort.direction
      this.matSort = {}
      this.matSort['field'] = field
      this.matSort['order'] = order
      // if (this.paginator && this.paginator.firstPage)
      //   this.paginator.firstPage();
      this.loadDocuments()
    }
  }

  addSortWithoutLoad(activeSort) {
    if (activeSort != null) {
      let field = activeSort.active
      let order = activeSort.direction
      this.matSort = {}
      this.matSort['field'] = field
      this.matSort['order'] = order
      // if (this.paginator && this.paginator.firstPage)
      //   this.paginator.firstPage();
    }

  }
  resetSort() {
    this.matSort = {}
  }
  loadDocuments() {
    this.loadingSubject.next(true);
    let query = {
      "size": this.paginator && this.paginator.pageSize != null ? this.paginator.pageSize : this.size,
      "from": this.paginator && this.paginator.pageSize != null ? this.paginator.pageIndex * this.paginator.pageSize : this.from,
      "where": this.whereQuery,
      "filter": {},
      "sort": this.matSort != null && this.matSort['order'] != null && this.matSort['order'].trim() != '' && this.matSort['field'] != null && this.matSort['field'].trim() != '' ? this.matSort : {},
      "accessAs": this.isCustomer == true ? 'customerGSTN' : 'supplierGSTN'
    }
    this.pageService.getPurchaseOrders(query).subscribe(list => {
      this.documentsSubject.next(list['result'])
      this.pageService.offSpinner();
      this.loadingSubject.next(false);
      this.total = list['total'];
    });
  }
}