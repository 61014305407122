import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { error } from 'protractor';
import { catchError } from 'rxjs/operators';
import { PagesService } from 'src/app/pages/pages.service';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-otp-loader',
  templateUrl: './otp-loader.component.html',
  styleUrls: ['./otp-loader.component.scss']
})
export class OtpLoaderComponent implements OnInit {

  isBankValid: boolean = false;
  bankData: any;
  sendBtnLabel: string = "Send Payments";
  isBankEditable: boolean = false;
  businessAccountId: string;
  isPrimary: boolean = false;
  isResend: boolean = false;
  isLoading: boolean = false;
  isEscrowAccount: boolean = false;

  constructor(public dialogRef: MatDialogRef<OtpLoaderComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private pageService: PagesService, private settingService: SettingService,) {
      if (data['value'] == 'escrow-account') {
        this.isEscrowAccount = true;
      }
      else {
        this.isEscrowAccount = false;
      }
    if (data['isBankValid']) {
      this.bankData = data;
      this.isBankValid = data['isBankValid'];
      this.businessAccountId = data['elementDetails']['id'];
      this.sendBtnLabel = "Confirm";
      this.isPrimary = data['isPrimary'];
      this.isBankEditable = data['isBankEditable'];
    }
  }

  ngOnInit() {
    if (this.isEscrowAccount == false) {
       this.sendOTP();
    }
    else {
      this.getEscrowPaymentOTP();
    }
  }
  requestIDRes: string='';
  getEscrowPaymentOTP() {
    let obj = {
      fileId: this.data.id
    }
    this.settingService.getOTPPayment(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.requestIDRes = res['result']['requestId'];
        let obj={
          requestId: res['result']['requestId']
        }
        this.isLoading = false;
        this.dialogRef.close(obj);
      }
    })
  }

  sendOTP() {
    let obj = {
      "businessAccountId": this.businessAccountId,
      "otpRequestType": "BOTH",
      "otpType": "BANK_ACCOUNT_ADDITION"
    }
    if (this.isPrimary) {
      obj.otpType = "BANK_ACCOUNT_SET_PRIMARY"
    }
    else if (this.isBankEditable) {
      obj.otpType = "BANK_ACCOUNT_UPDATE"
    }
    this.isLoading = true;
    this.pageService.getOTPRequest(obj).subscribe(res => {
      if (res['status'] == 200) {
        let obj = {
          requestId: res['result']['requestId'],
          bankDetails: this.bankData
        }
        this.isLoading = false;
        this.dialogRef.close(obj);
      }
    },
    error =>{
      this.isLoading = false;
      this.isResend = true;
    })
    setTimeout(() => {
      this.isResend = true;
    }, 30000);
  }

  resendOTP() {
    if (this.isResend) {
      this.sendOTP();
      this.isResend = false;
    }
  }

  closeDialog(){
    let obj = {
      disabledPrimary: true,
      isPrimary: this.isPrimary
    }
    this.dialogRef.close(obj);
  }

}
