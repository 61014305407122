import { Component, OnInit, ViewChild } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { SettingService } from 'src/app/settings/setting.service';
import { dashboardData } from '../../../constants/dashboard-data';
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';
import { EarlyPaymentTrendComponent } from './early-payment-trend/early-payment-trend.component';
import { EpConversionFunnelComponent } from './ep-conversion-funnel/ep-conversion-funnel.component';
import { EpSourceRequestStatsComponent } from './ep-source-request-stats/ep-source-request-stats.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateOkrComponent } from './create-okr/create-okr.component';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  reqobj = {};
  searchText: string = "";
  supplierLinks = [];
  lendersData = [];
  graphData: any;
  chartData: any;
  chartTabs: any = [];
  durationArr: string[] = ['Last 6 Months', 'Last 3 Months', 'Last 30 Days', 'Custom'];
  invoiceDuration: string = 'Last 6 Months'

  selectedDate: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  startDate: any;
  endDate: any;
  showMenu: boolean[] = [];
  @ViewChild('menuTrigger') supplierTrigger;
  @ViewChild('menuLenderTrigger') lenderTrigger;
  @ViewChild(EarlyPaymentTrendComponent) epTrend: EarlyPaymentTrendComponent;
  reportType: any = ['allInvoiceStats', 'eligibleInvoiceStats', 'todayRequestedStats'];
  eligibleInvoiceStats: any = [];
  conversionFunnel: any = [];
  requestedBySource: any = [];
  loading: boolean = false;
  pageSize = 10;
  pageFrom = 0;
  @ViewChild(EpConversionFunnelComponent) funnelComponent: EpConversionFunnelComponent;
  @ViewChild(EpSourceRequestStatsComponent) requestStatsComponent: EpSourceRequestStatsComponent;

  //for duplicate OKR
  isDuplicate: boolean = false;

  constructor(private pageService: PagesService, private authService: AuthService, public _dashboardService: DashboardService,
    private settingService: SettingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.pageService.isChangeColor("white_dashboard");
    this.getSupplierList();
    // this.getAllOKRList()
  }

  getDashboardData(reqObj) {
    for (let i = 0; i < this.reportType.length; i++) {
      reqObj['reportType'] = this.reportType[i];
      this.pageService.getAnchorDashboard(reqObj).subscribe(res => {
        if (res['status'] == 200) {
          this.chartTabs = [];
          if (this.reportType[i] == dashboardData.allInvoiceStats) {
            this.graphData = res['result'];
            this.chartData = this.graphData[dashboardData.allInvoiceStats];
            setTimeout(() => {
              this.getInvoiceTabsData(this.graphData[dashboardData.allInvoiceStats], 'Total Invoice');
              this.getInvoiceTabsData(this.graphData[dashboardData.epRequestedInvoiceStats], 'Requested EP');
              this.getInvoiceTabsData(this.graphData[dashboardData.epDisbursedInvoiceStats], 'Disbursed EP');
            }, 100);
          }
          else if (this.reportType[i] == dashboardData.eligibleInvoiceStats) {
            let obj = {
              'reportType': dashboardData.eligibleInvoiceStats,
              'result': res['result']
            }
            this.eligibleInvoiceStats.push(obj);
          }
          else if (this.reportType[i] == dashboardData.todayRequestedStats) {
            let obj = {
              'reportType': dashboardData.todayRequestedStats,
              'result': res['result']
            }
            this.eligibleInvoiceStats.push(obj);
          }
        }
      })
    }
  }

  ngAfterViewInit() {
    this.reqobj = {
      "size": 10,
      "from": 0,
      "where": {},
      "sort": {}
    }
    this.supplierFetchResult(this.reqobj);
    this.fetchLenderLeads();
  }

  supplierFetchResult(reqObj) {
    if (this.authService.isCredlixUser()) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    this.pageService.getSupplierResults(reqObj).subscribe(list => {
      this.supplierLinks = list['result'];
    })
  }

  fetchLenderLeads() {
    this.pageService.fetchFinancer(localStorage.getItem('credlixUserID')).subscribe(list => {
      if (list['result']) {
        this.lendersData = list['result'];
        this.lendersData.map(val => {
          val['isChecked'] = false;
        })
      }
    })
  }

  onSearchChange(value) {
    if (value.trim().length > 1) {
      let obj = {
        "size": 10,
        "from": 0,
        "searchTerm": value
      }
      if (this.authService.isCredlixUser()) {
        obj['masterAccountId'] = localStorage.getItem('credlixUserID');
      }
      this.pageService.autoCompleteSearch(obj).subscribe(data => {
        if (data['result']) {
          this.supplierLinks = data['result'].filter(value => {
            return value.documentType.toLowerCase() == 'supplier'
          })
          this.supplierLinks.map(val => {
            val['isChecked'] = false;
          })
        }
      })
    }
    else if (value.length == 0) {
      this.reqobj = {
        "size": 10,
        "from": 0,
        "where": {},
        "sort": {}
      }
      this.supplierFetchResult(this.reqobj);
    }
    // }
  }

  getInvoiceTabsData(data, name) {
    let obj = {
      name: name,
      docCount: this.settingService.numDifferentiation(data['doc_count']),
      amount: this.settingService.numDifferentiation(data['amount']),
      precent: data['changePercent']
    }
    this.chartTabs.push(obj);
  }

  changeTab(data) {
    if (data == 0) {
      this.chartData = this.graphData[dashboardData.allInvoiceStats];
    }
    if (data == 1) {
      this.chartData = this.graphData[dashboardData.epRequestedInvoiceStats];
    }
    if (data == 2) {
      this.chartData = this.graphData[dashboardData.epDisbursedInvoiceStats];
    }
  }

  durationChange(event, val, reportType) {
    this.invoiceDuration = val;
    if (val == "Custom") {
      event.stopPropagation();
    }
    else {
      let reqObj = this.getRequestData();
      reqObj['reportType'] = reportType;
      // this.loading = true;
      this.pageService.getAnchorDashboard(reqObj).subscribe(res => {
        if (res['status'] == 200) {
          this.chartTabs = [];
          // this.loading = false;
          if(reportType == dashboardData.allInvoiceStats){
            this.graphData = res['result'];
            this.chartData = this.graphData[dashboardData.allInvoiceStats];
            this.getInvoiceTabsData(this.graphData[dashboardData.allInvoiceStats], 'Total Invoice');
            this.getInvoiceTabsData(this.graphData[dashboardData.epRequestedInvoiceStats], 'Requested EP');
            this.getInvoiceTabsData(this.graphData[dashboardData.epDisbursedInvoiceStats], 'Disbursed EP');
          }
        }
      })
    }
  }

  datesUpdated(event) {
    if (event.startDate != null && event.endDate != null) {
      this.startDateMillisec = event.startDate._d
      this.startDate = moment(this.startDateMillisec).format('YYYY-MM-DD');
      this.EndDateMillisec = event.endDate._d
      this.endDate = moment(this.EndDateMillisec).format('YYYY-MM-DD');
      this.getSupplierList();
      event.preventDefault();
    }
  }

  getSupplierList() {
    let reqObj = this.getRequestData();
    this.getDashboardData(reqObj);
    this.funnelComponent.getGraphData();
    this.requestStatsComponent.getGraphData();
  }

  downloadCSV() {
    this.epTrend.downloadImg();
  }

  getRequestData() {
    let supplierIds = [];
    let lenderIds = [];
    let invoiceData = {};
    this.supplierLinks.map(val => {
      if (val['isChecked']) {
        supplierIds.push(val['searchkey'])
      }
    })
    this.lendersData.map(val => {
      if (val['isChecked']) {
        lenderIds.push(val['id'])
      }
    })
    if (this.invoiceDuration == 'Custom') {
      invoiceData['type'] = 'range';
      invoiceData['start_value'] = this.startDate;
      invoiceData['end_value'] = this.endDate;
    }
    else {
      invoiceData['type'] = 'range';
      invoiceData['range_type'] = 'last';
      if (this.invoiceDuration == 'Last 6 Months')
        invoiceData['custom'] = '180';
      else if (this.invoiceDuration == 'Last 3 Months')
        invoiceData['custom'] = '90';
      else
        invoiceData['custom'] = '30';
    }
    let reqObj =
    {
      "where": {
        "invoiceDate": invoiceData
      }
    }
    if (supplierIds.length > 0) {
      let obj = {
        "type": "in",
        "value": supplierIds
      }
      reqObj['where']['supplierId'] = obj;
    }
    if (lenderIds.length > 0) {
      let obj = {
        "type": "in",
        "value": lenderIds
      }
      reqObj['where']['lenderId'] = obj;
    }
    return reqObj;
  }
  createOkr(val) {
    if(val == true){
      this.isDuplicate = false;
    }
    else{
      this.isDuplicate = true;
    }
    let obj={
      isDuplicate: this.isDuplicate
    }
    const dialogRef = this.dialog.open(CreateOkrComponent, {
      backdropClass: 'create-okr-dialog-backdrop-class',
      panelClass: 'create-okr-custom-dialog-panel-class',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getAllOKRList();

      }

    });

  }
  allOKRData = [];
  getAllOKRList(isScrollEvent?) {
    let obj = {}
    obj = {
      "where": {
        "isDeleted": {
          "type": "search",
          "value": false
        }
      },
      "sort": {
        "field": "createdAt",
        "order": "desc"
      },

    }
    if (isScrollEvent) {
      obj['from'] = this.allOKRData.length,
        obj['size'] = this.pageSize
    }
    else {
      obj['from'] = this.pageFrom,
        obj['size'] = this.pageSize
    }
    this._dashboardService.getAllOkrsList(obj).subscribe(res => {
      if(isScrollEvent){
        this.allOKRData = this.allOKRData.concat(res['result']);
      }
      else{
        this.allOKRData = res['result'];
      }
    })
  }
  dashboardTabIndex: number = 0;
  getDashboardTabsEvent(event) {
    console.log(event.index);
    this.dashboardTabIndex = event.index;
    if(this.dashboardTabIndex==1){
      this.getAllOKRList();
    }


  }
  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      result = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000) {
      result = (val / 1000).toFixed(2) + ' K';
    }
    return result || val;
  }

  firstDate: any;
  secondDate: any;
  new_date:any;
  diffInDays: number;

  calculateDays(list){
    this.firstDate = moment(new Date())
    this.firstDate.subtract(1, 'days');
    this.secondDate = moment(list.endDate);
    this.diffInDays = Math.abs((this.firstDate).diff(this.secondDate, 'days'));
    if(this.secondDate >= this.firstDate){
      return this.diffInDays + " " + 'days left'
    }
  }
  statusCheck(endDate){
    let todayDate = moment(new Date())
    let lastDate = moment(endDate).add(1,'d');
    if (todayDate > lastDate){
      return "Expired";
    }
  }
  okrType: any;
  updateOKRGoal(list, val) {
    if(val == false){
      this.isDuplicate = true;
      this.okrType = 'duplicate'
    }
    else{
      this.isDuplicate = false;
      this.okrType = 'update'
    }

    let obj = {
      type: this.okrType,
      data: list,
      isDuplicate: this.isDuplicate
    }
    const dialogRef = this.dialog.open(CreateOkrComponent, {
      backdropClass: 'create-okr-dialog-backdrop-class',
      panelClass: 'create-okr-custom-dialog-panel-class',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getAllOKRList();
      }

    });
  }
  onScrollDown(event) {
    this.getAllOKRList('scrollDown')

  }
  toggleCard(index){
    this.showMenu[index] = !this.showMenu[index];
  }
}
