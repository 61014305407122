import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelFinancingRoutingModule } from './channel-financing-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { BuyerListingComponent } from './buyer-listing/buyer-listing.component';
import { LoanInvoicingComponent } from './loan-invoicing/loan-invoicing.component';
import { RepaymentsComponent } from './repayments/repayments.component';
import { BankListingComponent } from './bank-listing/bank-listing.component';
import { SharedComponentModule } from 'src/app/shared-files/shared-component/shared-component.module';
import { RequestFinanceComponent } from './request-finance/request-finance.component';
import { FinanceInvoiceListingComponent } from './finance-invoice-listing/finance-invoice-listing.component';
import { RequestFinancePdfComponent } from './request-finance-pdf/request-finance-pdf.component';
import { MarkDisbursedDialogComponent } from './mark-disbursed-dialog/mark-disbursed-dialog.component';
import { ViewAccountStatementComponent } from './view-account-statement/view-account-statement.component';

@NgModule({
  declarations: [BuyerListingComponent, LoanInvoicingComponent, RepaymentsComponent, BankListingComponent, RequestFinanceComponent, FinanceInvoiceListingComponent, RequestFinancePdfComponent, MarkDisbursedDialogComponent, ViewAccountStatementComponent],
  imports: [
    CommonModule,
    SharedModulesModule,
    ChannelFinancingRoutingModule,
    FormsModule,
    DirectiveModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SharedComponentModule
  ],
  entryComponents: [
    RequestFinanceComponent, RequestFinancePdfComponent, MarkDisbursedDialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChannelFinancingModule { }
