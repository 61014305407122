import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CommonSupportService {

  constructor(public toasterService: ToastrService) { }
  getFileExtension(contentDispositionHeader: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, '');
      const extension = filename.split('.').pop();
      return extension.toLowerCase();
    }
    return '';
  }

  result: any;
  openDocumentNewTab(res:any){
    if (res) {
      const contentType = res.headers.get('content-type');
      const contentDispositionHeader = res.headers.get('content-disposition');
      const fileExtension = this.getFileExtension(contentDispositionHeader);
      const fileBlob = new Blob([res.body], { type: contentType });
      const fileUrl = URL.createObjectURL(fileBlob);
      if (fileExtension === 'pdf') {
        // For PDF files, open in a new browser tab
        window.open(fileUrl, '_blank');
      }
      else if (fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg') {
        // Show JPEG or PNG image in a new tab
        const imgWindow = window.open('', '_blank');
        imgWindow.document.write('<html><body><img src="' + fileUrl + '" /></body></html>');
        imgWindow.document.close();
      }
      else if (fileExtension === 'txt' || fileExtension === '"text/csv') {
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileContent = reader.result;
          const txtWindow = window.open('', '_blank');
          txtWindow.document.write('<html><body><pre>' + fileContent + '</pre></body></html>');
          txtWindow.document.close();
        };
        reader.readAsText(fileBlob);

      }
      else {
        this.result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        this.result = this.result.replace(/"/g, '');
        saveAs(res.body, this.result);
        this.toasterService.success('Success', 'Downloaded Successfully')
        return this.result.replace(/"/g, '');
      }
    }
    else {
      let msg = res['message'] || 'Some Error occured while fetching documents!'
      this.toasterService.error(msg);
    }
  }
}
