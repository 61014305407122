<div class="heading">
  <h3>Leads To Be Contacted Today</h3>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div class="table">
  <table mat-table [dataSource]="dataSource">      
    <!-- sources Column -->
    <ng-container matColumnDef="leadId">
      <th mat-header-cell *matHeaderCellDef>Lead ID</th>
      <td mat-cell *matCellDef="let element" class="pointer" (click)="openLead(element.id)"> {{element.searchKey}} </td>
    </ng-container>
  
    <!-- allocated Column -->
    <ng-container matColumnDef="leadName">
      <th mat-header-cell *matHeaderCellDef> Lead Name </th>
      <td mat-cell *matCellDef="let element"> {{element.exporterName}} </td>
    </ng-container>
  
    <!-- outstanding Column -->
    <ng-container matColumnDef="lastContactedDate">
      <th mat-header-cell *matHeaderCellDef> Last Contacted Date </th>
      <td mat-cell *matCellDef="let element"> {{element.lastContactedDate | date}} </td>
    </ng-container>

    <ng-container matColumnDef="nextContactDate">
      <th mat-header-cell *matHeaderCellDef> Next Contact Date </th>
      <td mat-cell *matCellDef="let element"> {{element.nextContactDate | date}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>