<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap" *ngIf="authService.isAnchor() || authService.isCredlixUser()">
      <div class="lft">
        <button class="filter back" mat-button (click)="navigateToSupplierPage()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </section>
    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">
      <div class="breadcrumb" *ngIf="authService.isAnchor() || authService.isCredlixUser()">
        <i class="ri-speed-fill first"></i>
        <span (click)="breadcrumNavigation()" class="hyperlinktxt">Borrowres</span>
        <div class="chek" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;" ></i>
          <span>{{(borrowerList && borrowerList.businessName)? borrowerList.businessName : ""}} ({{(borrowerList && borrowerList.searchKey) ? borrowerList.searchKey : ''}})</span>
        </div>

      </div>
      <div class="breadcrumb" *ngIf="authService.isBuyer()">
        <span>{{(borrowerList && borrowerList.businessName)? borrowerList.businessName : ""}} ({{(borrowerList && borrowerList.searchKey) ? borrowerList.searchKey : ''}})</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="1%" class="borrower">
      <div fxLayout="column">
        <mat-card class="borrow">
          <mat-card-content>
            <div class="bnkData">{{(borrowerList && settingService.numDifferentiation(borrowerList.limit)) ? settingService.numDifferentiation(borrowerList.limit) : ''}}</div>
            <div class="bnkDetail">Limit sanctioned</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="lender">
          <mat-card-content>
            <div class="bnkData">{{(borrowerList && settingService.numDifferentiation(borrowerList.utilizedLimit)) ? settingService.numDifferentiation(borrowerList.utilizedLimit) : ''}}</div>
            <div class="bnkDetail">Limit Utilized</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="limit">
          <mat-card-content>
            <div class="bnkData">{{(borrowerList && settingService.numDifferentiation(borrowerList.unUtilizedLimit)) ? settingService.numDifferentiation(borrowerList.unUtilizedLimit) : ''}}</div>
            <div class="bnkDetail">Limit Available</div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div fxLayout="column">
        <mat-card class="disbursais">
          <mat-card-content>
            <div class="bnkData">34</div>
            <div class="bnkDetail">Disbursals</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="overdue">
          <mat-card-content>
            <div class="bnkData">2</div>
            <div class="bnkDetail">Overdues</div>
          </mat-card-content>
        </mat-card>
      </div> -->
    </div>    

    <div style="padding: 2% 15px 0px 10px;">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort class="mat_table"
      style="width: 100%;padding-left: 2%;" [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">
      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
        <th mat-header-cell *matHeaderCellDef style="width: 200px;"> {{disCol.key}} </th>
        <td mat-cell *matCellDef="let element" (click)="getBuyerInvoice(element, disCol.field)">
          <div [ngClass]="getClass(disCol.field, element)">
            {{ disCol.cell(element) }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;"></th>
        <td mat-cell *matCellDef="let element">
          <div class="iconsaction">
            <button mat-icon-button [matMenuTriggerFor]="list" #acceptButton
            (click)="openSupplierDetails(element, acceptButton, 'true')">
              <mat-icon class="icon_details">more_vert</mat-icon>
              <mat-menu #list="matMenu" (closed)="openSupplierDetails(element, acceptButton, 'false')">
                <button mat-menu-item (click)="generateLink(element)">Generate Password Link</button>
                <button mat-menu-item (click)="openRepayments(element)">Repayments</button>
              </mat-menu>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
    </table>
    </div>
    <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" showFirstLastButtons>
  </mat-paginator>
  </div>
</div>