import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';

@Component({
  selector: 'app-watcher-popup',
  templateUrl: './watcher-popup.component.html',
  styleUrls: ['./watcher-popup.component.scss']
})
export class WatcherPopupComponent implements OnInit {
  watcherName: any;
  allTags: any;
  laneTaskDetails: any;
  options: any;
  selectedWatcher: any = [];
  optionsSelected: any;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  constructor(
    public _kService: KanbanService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<WatcherPopupComponent>, public toaster: ToastrService, public preSaleSarvice : PresaleService
  ) {
    console.log('routed data is', this.data);
  }

  ngOnInit() {
    if(this.data.prequalified){
      this.getPrequalfiedUsers()
    } else{
    this.getAllTags();
    this.getUserList();
    this.laneTaskDetails = this.data.laneTaskDetails;
    this.selectedWatcher = this.data ? this.data.laneTaskDetails.watchers : [];
    }
  }
  userlist = []
  getUserList() {
    this._kService.getKanbanUserList().subscribe(list => {
      if (list['status'] == 200) {
        this.options = list['result'];
        this.filteredOptions = this.options;
        this.selectedWatcher = this.data ? this.data.laneTaskDetails.watchers : [];
      }
    });
  }
  getAllTags() {
    this._kService.getAllTags(this.data.currentBoardID).subscribe((res: any) => {
      this.allTags = res['result'];
    });
  }

  assignWatchers() {
    let req = [];
    let error = false;
    let selectedId = this.getSelectedWatcherDetails(this.optionsSelected)
    if (this.selectedWatcher && this.selectedWatcher.length > 0) {
      for (let i = 0; i < this.selectedWatcher.length; i++) {
        if (this.selectedWatcher[i].user) {
          if (this.selectedWatcher[i].user.name == this.optionsSelected) {
            this.toaster.error('selected watcher is already exists');
            error = true;
            return;
          }
        }
        else {
          if (this.selectedWatcher[i].userName == this.optionsSelected) {
            this.toaster.error('selected watcher is already exists');
            error = true;
            return;
          }
        }
      }
    }
    if (!error) {
      
      if(this.data.preQualified){
        let obj = {
          "notificationTypes": [
            "EMAIL"
          ],
          "userId": selectedId.userId,
          "userName": selectedId.userName
        }
        this.addPreQualWatchers(obj)
      } else {
        req.push({
          "notificationTypes": [
            "EMAIL"
          ],
          "userId": selectedId.userId
        });
        this.addWatchersInLaneTask(req);
      }
    }
  }
  addWatchersInLaneTask(req){
    this._kService.addWatchersToLaneTask(req, this.laneTaskDetails.id).subscribe(res => {
      if (res['status'] == 200) {
        if (!this.selectedWatcher) {
          this.selectedWatcher = [];
        }
        this.selectedWatcher.push(this.getSelectedWatcherDetails(this.optionsSelected));
        this.watcherName = null;
        this.filteredOptions = this.options;
      }
    });
  }
  addPreQualWatchers(obj){
    this.preSaleSarvice.addWatchersToPreQualified(obj,this.data.laneTaskDetails.id).subscribe(res =>{
      if(res['status'] = 200) {
        if (!this.selectedWatcher) {
          this.selectedWatcher = [];
        }
        this.selectedWatcher.push(this.getSelectedWatcherDetails(this.optionsSelected));
        this.watcherName = null;
        this.filteredOptions = this.options;
      }
    })
  }
  filteredOptions:any; // Initially, the filtered options are the same as the full options
  filterOptions(searchText: string) {
    this.filteredOptions = this.options.filter(option =>
      option.userName.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  openDropdown(event: Event) {
    this.autocomplete.openPanel();
    event.stopPropagation();
  }

  getSelectedWatcherDetails(optionsSelected: any, id?) {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].userName == optionsSelected) {
        if (!id) {
          return this.options[i];
        }
        else {
          return this.options[i].user.id;
        }
      }
    }
  }

  selectedWatchersList(event: any) {
    let newValue = event.option.viewValue;
    this.optionsSelected = newValue;
  }

  deleteWatchers(element: any) {
    let obj = {
      laneTaskId: this.data.laneTaskDetails.id,
      watcherIds: element.user ? element.user.id : element.id
    }
    if(this.data.preQualified){
      this.preSaleSarvice.removePreQualifiedWatchers(obj).subscribe(res =>{
        if (res['status'] == 200) {
          const index = this.selectedWatcher.indexOf(element);
          if (index >= 0) {
            this.selectedWatcher.splice(index, 1);
          }
        }
      })
    } else {
      this._kService.removeWatchers(obj).subscribe(res => {
        if (res['status'] == 200) {
          const index = this.selectedWatcher.indexOf(element);
          if (index >= 0) {
            this.selectedWatcher.splice(index, 1);
          }
        }
      });
    }
  }
  dailogClose() {
    this.dialogRef.close('refresh');
  }
  getPrequalfiedUsers(){
    this._kService.getPreQualifiedUserList().subscribe(res =>{
      if (res['status'] == 200) {
        this.options = res['result'];
        this.filteredOptions = this.options;
        this.selectedWatcher = this.data ? this.data.laneTaskDetails.watchers : [];
      }
    })
  }
  
}
