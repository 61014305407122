import { Component, OnInit } from '@angular/core';
import { UploadFileService } from './one-drive-services/upload-file.service';
import { takeLast } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { CommonSupportService } from './one-drive-services/common-support.service';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-los-one-drive',
  templateUrl: './los-one-drive.component.html',
  styleUrls: ['./los-one-drive.component.scss']
})
export class LosOneDriveComponent implements OnInit {

  wrongFilesList = [];
  filesList = [];
  uploadedFiles: any[] = [];
  laneTaskId:any;

  uploadedFolders:any;
  isFetching:boolean=false;
  constructor(private uploadFileService: UploadFileService,
    private commonService: CommonSupportService,
    private toasterService: ToastrService,
    public openApi: KanbanService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.laneTaskId = params['id'];
    })
    this.getDownloadedDocuments(this.laneTaskId);
  }

  getDownloadedDocuments(laneTaskId:any){
    this.isFetching=true;
    this.openApi.getDocuments(laneTaskId).subscribe({
      next:(resp:any)=>{
        this.uploadedFolders = resp.result;
      },
      error:(err:any)=> this.isFetching=false,
      complete:()=>this.isFetching=false
    })
  }
  async uploadFiles(files: FileList | null, folderName: string) {
    if (!files) return;
    this.wrongFilesList = [];
    let _tempWrongFilesList = [];
    let _tempFilesList = [];
    const folderHolder: any = {};
    this.uploadedFiles = [];
    for (let file of Array.from(files)) {
      const path: string = file['webkitRelativePath'];
      const pathPieces = path.split('/');

      if (!pathPieces.reverse()[0].startsWith('.')) {
        const currentFolder: string = pathPieces[1];
        //console.log('Checking for folder ', currentFolder, 'in', folderHolder);
        if (folderHolder[currentFolder]) {
          const b = await this.uploadFileService.uploadFile(folderHolder[currentFolder], file).pipe(takeLast(1)).toPromise();
          this.uploadedFiles.push(
            // pathPieces[0]
            file
          );
        } else {
          const folderId = await this.uploadFileService.createFolder(currentFolder).pipe(takeLast(1)).toPromise();
          folderHolder[currentFolder] = folderId;

          const p = await this.uploadFileService.uploadFile(folderHolder[currentFolder], file).pipe(takeLast(1)).toPromise();
          this.uploadedFiles.push(
            // pathPieces[0]
            file
          );
        }
      }
    }
    let formData = new FormData();
    this.uploadedFiles.forEach(x=>{
      formData.append("files",x);
    });
    formData.append("folderName",folderName);
    formData.append("laneTaskId",this.laneTaskId);
    this.isFetching = true;
    this.openApi.uploadFolder(formData).subscribe({
      next:(resp:any)=>{
        this.toasterService.success('All Files Uploaded successfully!');
      },
      error:(err:any)=>{
        this.toasterService.error(err.message);
        this.isFetching = false;
      },
      complete:()=>{
        this.isFetching = false;
        this.getDownloadedDocuments(this.laneTaskId);
      }
    })
  }
  uploadSingleFolderDocument(event:any,folderName){
    this.isFetching = true;
    let fileName = event.srcElement.files[0].name;
    let remarks = folderName + '_' +(new Date()).getTime() ;
    let file = event.srcElement.files[0];
    let formData = new FormData();
    formData.append('file',file);
    if (file && fileName) {
      this.openApi.uploadSingleDocument(this.laneTaskId, remarks,formData).subscribe({
        next: (resp: any) => {
          if (resp.status == '200') {
            this.toasterService.success(
              "Document Uploaded Successfully"
            );
          } else {
            this.isFetching = false;
            this.toasterService.error("Something Went Wrong!");
          }
        },
        error: (err: any) => {
          this.isFetching = false;
          this.toasterService.error(err.message);
        },
        complete: () => {
          this.isFetching = false;
          this.getDownloadedDocuments(this.laneTaskId);
        },
      });
    } else {
      return false;
    }
  }
  deleteFile(documentId: any){
    this.isFetching = true;
    this.openApi.deleteFolderFile(documentId).subscribe({
      next:(resp:any)=>{
        this.toasterService.success('Document Deleted successfully!');
      },
      error:(err:any)=>{
        this.isFetching = false;
        this.toasterService.error(err.message);
      },
      complete:()=>{
        this.isFetching = false;
        this.getDownloadedDocuments(this.laneTaskId);
      }
    });
  }
  showFile(id: any) {
    this.openApi.showFile(id).subscribe({
      next: (res: any) => {
        const contentType = res.headers.get("content-type");
        const contentDispositionHeader = res.headers.get("content-disposition");
        const fileExtension = this.commonService.getFileExtension(
          contentDispositionHeader
        );
        const fileBlob = new Blob([res.body], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);
        if (fileExtension === "pdf") {
          // For PDF files, open in a new browser tab
          window.open(fileUrl, "_blank");
        } else if (
          fileExtension === "jpeg" ||
          fileExtension === "png" ||
          fileExtension === "jpg"
        ) {
          // Show JPEG or PNG image in a new tab
          const imgWindow = window.open("", "_blank");
          imgWindow.document.write(
            '<html><body><img src="' + fileUrl + '" /></body></html>'
          );
          imgWindow.document.close();
        } else if (fileExtension === "txt" || fileExtension === '"text/csv') {
          const reader = new FileReader();
          reader.onloadend = () => {
            const fileContent = reader.result;
            const txtWindow = window.open("", "_blank");
            txtWindow.document.write(
              "<html><body><pre>" + fileContent + "</pre></body></html>"
            );
            txtWindow.document.close();
          };
          reader.readAsText(fileBlob);
        } else {
          let result = contentDispositionHeader
            .split(";")[1]
            .trim()
            .split("=")[1];
          result = result.replace(/"/g, "");
          saveAs(res.body, result);
          this.toasterService.success('Downloaded Successfully');
          return result.replace(/"/g, "");
        }
      },
    });
  }
}

export class FileItem {
  name: string;
  size: number;
  type: string;
  wrongSize = false;
  wrongFormat = false;
}

