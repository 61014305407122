<div class="importSupplierWrap">
  <div class="top">
    <h4>
      <span class="lft">Upload File</span>
    </h4>
    <mat-icon (click)="closedialog()" style="cursor: pointer;">close</mat-icon>
  </div>
  <div class="dragSection">
    <div draggable="true" class="dragArea" *ngIf="!file?.name" ngFileDragDrop (fileDropped)="onFileChanged($event)">
      <span class="lbl">
        <input style="display: none" type="file" [multiple]="false"
          (click)="clearStack($event)" (change)="onFileChanged($event)" #fileInput>
        <div><img style="height: 20px;margin-bottom: 10px;margin-left: 50%;" src="../../../../assets/images/excel.png">
        </div>
        <span (click)="fileInput.click()"><a> Drag Or Click here to Select </a></span>
      </span>
    </div>
    <div class="file-upload" style="height: 90px; top: 5px;position: relative;" *ngIf="file?.name">
      <label style="display: block;color: #777777;
    font-size: 11px;">File:</label>
      <span class="filenamewithclose"><span style=" position: relative;font-size: 13px;
    color: black;
    font-weight: 500;">Upload File– {{file?.name}}</span>
        <i class="material-icons" (click)="close()" style="position: relative;
    top: 4px; cursor: pointer; font-size: 17px; left: 4px;">
          close
        </i></span>
    </div>
    <div class="buttonSection">
      <!-- <button class="dwldTemplate" (click)="templateDownloadCSV()">
        <i class="ri-download-line"></i>
        <a>Download Template (CSV)</a>
      </button> -->
      <button class="mat-button" (click)="fileUpload()" *ngIf="file?.name">Import</button>
    </div>
  </div>
</div>