import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { PagesService } from '../../pages/pages.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
enum SupplierInvoice {

}
export class SupplierInvoiceDataSource implements DataSource<SupplierInvoice> {
  pageService: PagesService;
  paginator: MatPaginator;
  whereQuery: Object = {};
  public total: number = 0;
  from: number = 0;
  size: number = 10;
  isCustomer: Boolean;
  matSort: object = {};
  sort: MatSort;
  public documentsSubject = new BehaviorSubject<SupplierInvoice[]>([]);
  public COLUMNS = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate'];
  public SUPPLIER_COLUMNS = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate'];
  public CUSTOMER_COLUMNS = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate'];

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading = this.loadingSubject.asObservable();
  constructor(pageService: PagesService, paginator: MatPaginator, isCustomer: Boolean, matSort: MatSort) {
    this.pageService = pageService;
    this.paginator = paginator;
    this.sort = matSort;
    this.isCustomer = isCustomer;
    if (this.isCustomer == true) {
      this.COLUMNS = this.CUSTOMER_COLUMNS
    }
    else {
      this.COLUMNS = this.SUPPLIER_COLUMNS
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<SupplierInvoice[]> {
    return this.documentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.documentsSubject.complete();
    this.loadingSubject.complete();
  }
  checkInQuery(field) {
    return this.whereQuery[field] == null;
  }
  addToWhereQuery(field, value) {
    this.whereQuery[field] = value;
  }
  removeFromWhereQuery(field) {
    delete this.whereQuery[field];
    if (this.paginator && this.paginator.firstPage)
      this.paginator.firstPage();
    this.loadDocuments();
  }

  removeFromWhereQueryWithoutLoad(field) {
    delete this.whereQuery[field];

  }
  forceLoad() {
    if (this.paginator && this.paginator.firstPage)
      this.paginator.firstPage();
    this.loadDocuments();
  }
  resetQuery() {
    this.whereQuery = {};
    if (this.paginator && this.paginator.firstPage)
      this.paginator.firstPage();
    this.loadDocuments();
  }

  addSort(activeSort) {
    if (activeSort != null) {
      let field = activeSort.active
      let order = activeSort.direction
      this.matSort = {}
      this.matSort['field'] = field
      this.matSort['order'] = order
      // if (this.paginator && this.paginator.firstPage)
      //   this.paginator.firstPage();
      this.loadDocuments()
    }
  }
  addSortWithoutLoad(activeSort) {
    if (activeSort != null) {
      let field = activeSort.active
      let order = activeSort.direction
      this.matSort = {}
      this.matSort['field'] = field
      this.matSort['order'] = order
      // if (this.paginator && this.paginator.firstPage)
      //   this.paginator.firstPage();
    }

  }

  resetSort() {
    this.matSort = {}
  }
  loadDocuments() {
    this.loadingSubject.next(true);
    let query = {
      "size": this.paginator && this.paginator.pageSize != null ? this.paginator.pageSize : this.size,
      "from": this.paginator && this.paginator.pageSize != null ? this.paginator.pageIndex * this.paginator.pageSize : this.from,
      "where": this.whereQuery,
      "filter": {},
      "sort": this.matSort != null && this.matSort['order'] != null && this.matSort['order'].trim() != '' && this.matSort['field'] != null &&this.matSort['field'].trim() != '' ? this.matSort : {},
      'isCustomer': this.isCustomer == true ? true : false
    }
    this.pageService.getSuppliersInvoices(query).subscribe(list => {
      this.documentsSubject.next(list['result'])
      this.pageService.offSpinner();
      this.total = list['total'];
      this.loadingSubject.next(false);
      // this.dataSource.data = list['result'];
      // this.supplierInvoiceList = list['result'];
      // this.totalRecords = list['total'];
      // this.paginator.length = this.totalRecords
    })
  }

}