<div class="payout-layout">
  <div class="wrp">
    <div class="top">
      <h4>Create Payout</h4>
    </div>
    <div class="d-flex saperation-border">
      <div class="left-sec">
        <div class="left-p">
          <div class="calcArea left-top d-flex align-items-start justify-content-between">
            <div class="d-flex flex-direction-column ">
              <label>{{anchorName | titlecase}}  Escrow Balance</label>
              <span>₹{{escrowStatsCal?.escrowSystemBalance | number}}</span>
            </div>
            <div class="d-flex align-items-center">
              <a class="blue-anchor" (click)="refreshEscrowStats()"><i class="ri-refresh-line"></i> Refresh Escrow</a>
            </div>
          </div>
          <div class="calcArea">
            <label>Invoice Total Due (unpaid)</label>
            <span>₹ {{escrowStatsCal?.dueDateAmountTotal | number}}</span>
          </div>
          <div class="calcArea">
            <label>Invoices Due (unpaid)</label>
            <span>{{escrowStatsCal?.dueDateInvoiceCount}}</span>
          </div>
          <div class="calcArea">
            <label>Suppliers to be paid (as per due date)</label>
            <span>{{escrowStatsCal?.suppliersToBePaid}}</span>
          </div>
          <div class="calcArea">
            <label>Suppliers to be paid (as per funds received)</label>
            <span>{{escrowStatsCal?.escrowSupplierToBePaid}}</span>
          </div>
        </div>

      </div>
      <div class="right-sec d-flex flex-direction-column ">
        <div class="right-top">
          <h3>Data from anchor</h3>
          <label (click)="templateDownloadCSV()"><i class="material-icons create-btn">
              save_alt</i> Download Template</label>
        </div>
        <div draggable="true" class="dragArea" *ngIf="!file?.name && !isStatusDraft">
          <span class="lbl">
            <input style="display: none" type="file" [multiple]="false" (click)="clearStack($event)"
              (change)="onFileChanged($event)" #fileInput>
            <div class="d-flex justify-content-center"><img style="height: 20px;margin-bottom: 10px;"
                src="../../../../assets/images/excel.png">
            </div>
            <span (click)="fileInput.click()"><a>Click to Select </a>/Drag a File</span>
          </span>
        </div>
        <div class="d-flex align-items-center mt-20" *ngIf="file?.name && isStatusDraft">
          <div class="file-upload">
            <div class="filenamewithclose">
              <!-- <span style=" position: relative;font-size: 13px; color: black; font-weight: 500;">{{file?.name}}</span> -->
              <span class="file-name-section">{{file?.name}}</span>
              <button (click)="close()" mat-icon-button>
                <i class="material-icons">
                  highlight_off
                </i>
              </button>
            </div>
          </div>

          <button mat-button (click)="fileUpload()" class="btnploading" [disabled]="disabledFlag">Upload and
            Process</button>
        </div>
        <div style="position: relative; width: 40%;margin-top: 20px;" *ngIf="isProgressVisible">

          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>


        <div class="d-flex">
          <ul class="file-info-section" *ngIf="isFileUploadedSuccess">
            <li>
              <div class="list-heading">
                <span>Payment Reported</span>
                <strong>₹{{uploadedFileRes?.totalAmount | number}} </strong>
              </div>
            </li>
            <li>
              <div class="list-heading">
                <span>Invoices Reported</span>
                <strong>{{uploadedFileRes?.totalEntries}}</strong>
              </div>
            </li>
            <li>
              <div class="list-heading">
                <span>Suppliers Paid</span>
                <strong> {{uploadedFileRes?.totalPayees}}</strong>
              </div>

            </li>
          </ul>
          <!-- saperate status list -->
          <ul class="file-info-section" *ngIf="isFileUploadedSuccess">
            <li>
              <div class="list-status-section" *ngIf="uploadedFileRes?.matched == true">
                <div class="list-status match">
                  <i class="ri-checkbox-circle-fill"></i>
                  <span>Match</span>
                </div>
                <span>Payment Sent is received in escrow.</span>
              </div>
            </li>
            <li>
              <div class="list-status-section" *ngIf="uploadedFileRes?.matched == false">
                <div class="list-status mismatch">
                  <i class="ri-error-warning-fill"></i>
                  <span>Mismatch</span>
                </div>
                <span>Payment reported is not received in escrow.</span>
              </div>
            </li>
            <li>
              <div class="list-status-section" *ngIf="uploadedFileRes?.matchedWithWarning == true">
                <div class="list-status warning">
                  <i class="ri-error-warning-fill"></i>
                  <span>Warning</span>
                </div>
                <span>Not all due invoices are being paid.</span>
              </div>
            </li>
            <li style="margin-left: 45px;">
              <div class="list-heading">
                <span>Accepted Entries</span>
                <strong>{{uploadedFileRes?.acceptedEntries}} </strong>
              </div>
              
            </li>
            <li style="margin-left: 45px;">
              <div class="list-heading">
                <span>Matched Suppliers</span>
                <strong>{{uploadedFileRes?.matchedPayees}}  </strong>
              </div>
            </li>
          </ul>
          <ul class="file-info-section details" *ngIf="isFileUploadedSuccess">
            <li>
              <div class="list-heading">
                <span>Rejected Entries</span>
                <strong>{{uploadedFileRes?.rejectedEntries}} </strong>
              </div>
            </li>
            <li>
              <div class="list-heading">
                <span>Not Matched Suppliers</span>
                <strong>{{uploadedFileRes?.notMatchedPayees}} </strong>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>