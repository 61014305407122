import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  error: any = {
    id: 1,
    statusCode: 403,
    message: "Page Not Found",
    warning: "Forbidden"
   }; 
  errorList: any[] = [{
    id: 1,
    statusCode: 403,
    message: "Page Not Found",
    warning: "Forbidden"
  },
  {
    id: 2,
    statusCode: 404,
      message: "Page Not Found",
      warning: null
}
];
  constructor(
    private router: Router, 
    private settingservice: SettingService,
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit() {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.error = this.errorList.filter(error => error.id == params.id)
    //   console.log(params);
    // });
    // this.router.url.includes('settings/manualUpload') 
    if (this.router.url.includes('page-not-found')) {
       this.error = this.errorList[1];
      }
    else if (this.router.url.includes('forbidden')){
        this.error = this.errorList[0];
      }
    this.settingservice.pageNotFoundPage.next(false);
  }
  goToHome() {
    this.router.navigate(['/']);
  }

}
