import { Injectable } from '@angular/core';
import { params } from '../../param';
import { urls } from 'src/app/url';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InvoiceCreationService {


  constructor(private http: HttpClient) { }
  readonly BaseUrl = environment.BASEURL;

  getCustomDetails() {
    let url = this.BaseUrl + urls.getAllCustomer;
    return this.http.get(url);
  }
  getPaymentTerms() {
    let url = this.BaseUrl + urls.getPaymentTerms;
    return this.http.get(url);
  }
  getStates() {
    let url = this.BaseUrl + urls.states;
    return this.http.get(url);
  }
  getcountryList() {
    let url = this.BaseUrl + urls.country;
    return this.http.get(url);
  }
  saveInvoice(obj) {
    let url = this.BaseUrl + urls.invoiceCreation;
    return this.http.post(url, obj);
  }

  upateAddress(obj) {
    let url = this.BaseUrl + urls.updateAddress;
    return this.http.post(url, obj);
  }

}
