import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as Highcharts from 'highcharts';
import { EPTrendGraph } from '../ep-graph-data'
import { SettingService } from 'src/app/settings/setting.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let download = require('highcharts/modules/exporting');

download(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: 'app-early-payment-trend',
  templateUrl: './early-payment-trend.component.html',
  styleUrls: ['./early-payment-trend.component.scss']
})
export class EarlyPaymentTrendComponent implements OnInit {

  @Input() data: any;
  @Input() invoiceDuration: string;
  loading: boolean = false;
  datepipe: DatePipe = new DatePipe('en-US');
  epTrendGraph:any= EPTrendGraph;
  charts: any;

  constructor(private settingService: SettingService) {}

  ngOnInit() {
  }
  
  ngOnChanges() {
    console.log("this is isDurationChange in child component------>",this.invoiceDuration)

    this.getGraphLineData(this.data);
    this.epTrendGraph['tooltip'] = {
      formatter: function () {
        console.log(this);
        let val = Math.abs(this.y);
        if (val >= 10000000) {
          return this.x + '<br>' + '<b>' + this.point.z + '</b>' + " | " + '<b>' + '₹' + (val / 10000000).toFixed(2) + 'Cr' + '</b>';
        } else if (val >= 100000) {
          return this.x + '<br>' + '<b>' + this.point.z + '</b>' + " | " + '<b>' + '₹' + (val / 100000).toFixed(2) + 'Lac' + '</b>';
        } else if (val >= 1000) {
          return this.x + '<br>' + '<b>' + this.point.z + '</b>' + " | " + '<b>' + '₹' + (val / 1000).toFixed(2) + 'K' + '</b>';
        }
      },
    }
    this.charts = Highcharts.chart('EPTrend',this.epTrendGraph);
  }
  
  getGraphLineData(data){
    let graphArr = [];
    if(data){
      data['buckets'].map(val=>{
        let obj = {
          y: val['amount'],
          z: this.settingService.numDifferentiation(data['doc_count']),
        }
        graphArr.push(obj);
      })
    }
    this.epTrendGraph['series'][0]['data'] = graphArr;
    this.epTrendGraph['xAxis']['categories'] = this.dateConvertor(this.data);
    }

    dateConvertor(data){
    let date = [];
    if(data){
      data['buckets'].map(val=>{
        date.push(this.datepipe.transform(val['name'], 'MMM yy'));
      })
    }
    return date;

  }

  downloadImg(){
    this.charts.exportChart({type:"image/jpeg"});
  }
}
