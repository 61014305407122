<div class="costcoDialog">
  <div class="lft">
    <div class="inner">
      <section aria-label="heading">
        <div fxLayout="row" style="width: 100%;">
          <div style="width: 20%; margin: 1rem;">
            <p class="headLbl">{{laneTaskIDDetail?.displayId}}</p>
            <p class="nameLbl">
              <span class="table-sub-heading">Created :</span> {{laneTaskIDDetail?.createdAt | date}}
            </p>
            <p class="nameLbl">
              <span class="table-sub-heading">Last Modified : </span> {{laneTaskIDDetail?.updatedAt | date}}
            </p>
            <div fxLayout="row" fxLayoutAlign="start center" class="action-tab-margin">
              <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                <mat-icon aria-hidden="false" aria-label="Assigned To" fontIcon="home" matTooltip="Assigned To"
                  matTooltipClass="new-tooltip" class=" comments-icon">person</mat-icon>

              </div>
              <div fxLayout="column" fxLayoutAlign="center start" *ngIf="laneTaskIDDetail != null">
                <!-- <span class="comments-label" *ngIf="laneTaskIDDetail.assignee == null || laneTaskIDDetail.assignee.length == 0">Assigned To </span> -->
                <ng-container *ngIf="laneTaskIDDetail.assignee != null && laneTaskIDDetail.assignee.length > 0">
                  <p class="comments-label"> {{laneTaskIDDetail.assignee[0].user.name}}</p>
                </ng-container>
              </div>
            </div>
          </div>
          <div style="width: 75%;" fxLayout="row" fxLayoutAlign="end center" *ngIf="laneTaskIDDetail != null">
            <div fxLayout="row" fxLayoutAlign="start center" class="comp-info">
              <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-icon-div">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Company Name"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined company-icon">corporate_fare</mat-icon>

              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <span class="icon-head-lbl ">{{laneTaskIDDetail?.name}}</span>
                <label class="icon-text-label">Company</label>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="comp-info">
              <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-buyer-div">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Buyers"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined buyer-icon">group</mat-icon>

              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <span class="icon-head-lbl ">{{laneTaskIDDetail.buyersCount}}</span>
                <label class="icon-text-label">No. Of Buyer</label>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="comp-info">
              <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-limit-div">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Limit Applied"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined limit-icon">monetization_on</mat-icon>

              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <span class="icon-head-lbl ">{{laneTaskIDDetail.buyersRequestedLimit}}</span>
                <label class="icon-text-label">Limit Applied For</label>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="comp-info">
              <div fxLayout="row" fxLayoutAlign="center center" class="margin-right  company-contact-div">
                <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Conatct"
                  matTooltipClass="new-tooltip" class="material-symbols-outlined contact-icon">call</mat-icon>

              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <ng-container *ngIf="laneTaskIDDetail && getLeadContactDetails(laneTaskIDDetail) as contact">
                  <span class="icon-head-lbl ">{{contact.phone }} </span>
                </ng-container>
                <label class="icon-text-label">Contact Number</label>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>

            <div class="view-tabs">
              <mat-tab-group 
                animationDuration="0ms">
                <mat-tab *ngFor="let lane of laneArrayKeys; let i=index">
                  <ng-template mat-tab-label>
                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; ">
                      <div>
                        <span
                          style="text-transform: capitalize; color: #022B5C; font-weight: bold;">{{getLaneName(lane)}}</span>
                      </div>
                      <div style="position: absolute;
                      right: 0;">
                        <img src="../../../../../assets/icons/right-tab-arrow-filled.svg" matTooltip="Mark Disposition"
                          matTooltipClass="new-tooltip" (click)="downloadInvoice(element)" />
                      </div>
                    </div>
                    
                  </ng-template>

                  <div class="cntnr" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" style="width: 100%;">
                      <mat-grid-list [cols]="6" rowHeight="fit" gutterSize="0.1rem" style="width: 100%; height: 50vh;">
                        <mat-grid-tile class="lane-grid" *ngFor="let dashItem of getFieldsToDisplay(laneWiseData[lane]); let j = index" >
                          <div fxLayout="column" style="height: 100%;">
                            <p class="lane-field">{{dashItem.displayName}}</p>
                            <p class="lane-value">{{dashItem.value || "N/A"}}</p>
                          </div>
                        </mat-grid-tile>
                      </mat-grid-list>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
      </section>
    </div>
  </div>
</div>