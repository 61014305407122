import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  selectedRecordsArray: any;
  documentList: any
  getInvoiceIDByURl: any
  constructor(public pageService: PagesService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.getInvoiceIDByURl = params;
    });
  }


  ngOnInit() {
    this.pageService.getDocumentListByURl(this.getInvoiceIDByURl).subscribe((list: any) => {
      this.pageService.offSpinner();
      this.documentList = list['result'];
      this.selectedRecordsArray = list['result'].items;
    })
    this.getselectedRecord();
  }



  getselectedRecord() {
    this.pageService.subjectBehav.subscribe((data: any) => {
      this.documentList = data;
      this.selectedRecordsArray = data.items;
    })
  }

  backtoDocuments() {
    this.router.navigate(['/home']);
  }
}
