import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-podocument-view',
  templateUrl: './podocument-view.component.html',
  styleUrls: ['./podocument-view.component.scss']
})
export class PODocumentViewComponent implements OnInit {
  selectedRecordsArray: any;
  documentList: any
  getInvoiceIDByURl: any
  totalAmount: number = 0;
  constructor(public pageService: PagesService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.getInvoiceIDByURl = params;
      console.log("paarama", params);
    });
  }


  ngOnInit() {
    this.pageService.getPoDocumentListByURl(this.getInvoiceIDByURl).subscribe((list: any) => {
      this.pageService.offSpinner();
      console.log("po listss", list)

      this.documentList = list['result'];
      this.selectedRecordsArray = list['result'].items;
      if (this.selectedRecordsArray != null) {
        for (let i = 0; i < this.selectedRecordsArray.length; i++) {
          this.totalAmount = this.totalAmount + this.selectedRecordsArray[i].amount
        }
      }

    })
    this.getselectedRecord();
  }


  getselectedRecord() {
    this.pageService.subjectBehavPO.subscribe((data: any) => {
      this.documentList = data;
      this.selectedRecordsArray = data.items;
    })
  }
  backtoDocuments() {
    this.router.navigate(['/home'], { queryParams: { tab: "purchase" } })
  }
}
