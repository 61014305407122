<div class="cls">
  <img (click)="closeDisountDialog()" src="/assets/images/popupClose.svg" />
</div>
<div class="dislogo">
  <img src="/assets/images/discount_logo.svg" />
</div>
<div class="cta">
  <span class="rits">Get </span>
  <span class="disc">Upto 60% Funding</span>
  <br>
  <span class="sign">on ABFRL Purchase Orders</span>
  <br>
  <span class="rmrk">Disbursed within 5 Days</span>
</div>
<mat-dialog-actions>
  <button mat-button class="lfth" (click)="checkSupplierDiscount(false)">Rejected</button>
  <button mat-button class="rght" (click)="checkSupplierDiscount(true)">Intrested</button>
</mat-dialog-actions>

