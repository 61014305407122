export const EPTrendGraph: any = {
  chart: {
    height: 300,
    type: "line",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    labels: {
      formatter: function () {
        let val = Math.abs(this.value);
        if (val >= 10000000) {
          return "₹" + (val / 10000000).toFixed(2) + "Cr";
        } else if (val >= 100000) {
          return "₹" + (val / 100000).toFixed(2) + "Lac";
        } else if (val >= 1000) {
          return "₹" + (val / 1000).toFixed(2) + "K";
        }
      },
    },
    title: {
      enabled: false,
    },
  },
  xAxis: {
    categories: [],
  },

  legend: {
    enabled: false,
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: "",
      data: [],
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            enabled: false,
          },
        },
      },
    ],
  },
  //   exporting: {
  //     buttons: {
  //       contextButton: {
  //         }
  //     }
  // }
};

export const conversionFunel: any = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: ["Invoices", "Requested EP", "Disbursed"],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    tickInterval: 1000,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="padding:0"><b>{point.y} | ₹{point.z}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

export const requestSource: any = {
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [""],
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: "transparent",
  },
  yAxis: {
    min: 0,
    labels: {
      enabled: false,
    },
    gridLineColor: "transparent",
  },
  legend: {
    reversed: true,
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
  },
  series: [
    {
      name: "WHATSAPP",
      color: "#73816F",
      data: [
        {
          y: 0,
          color: "#73816F",
        },
      ],
    },
    {
      name: "WEB",
      color: "#50AAF7",
      data: [
        {
          y: 0,
          color: "#50AAF7",
        },
      ],
    },
  ],
};

export const processingAmount: any = {
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [""],
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: "transparent",
  },
  yAxis: {
    title: {
      enabled: false,
    },
    min: 0,
    labels: {
      enabled: false,
    },
    gridLineColor: "transparent",
  },
  legend: {
    reversed: true,
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
  },
  series: [
    {
      name: "Payment",
      color: "#FECD2F",
      data: [
        {
          y: 80,
          color: "#FECD2F",
        },
      ],
    },
    {
      name: "Delayed",
      color: "#70C360",
      data: [
        {
          y: 20,
          color: "#70C360",
        },
      ],
    },
  ],
};

export const funnelFuel: any = {
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title:{
      text:""
    },
    min: 0,
    visible: true,
    tickInterval: null,
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="padding:0"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
  },
  colors:['#408bd9'],
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

export const funnelFuel2: any = {
  chart: {
    type: "bar",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title:{
      text:""
    },
    min: 0,
    visible: true,
    tickInterval: null,
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="padding:0"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
  },
  colors:['#408bd9'],
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};
