import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import { SettingService } from 'src/app/settings/setting.service';
import { importConfig } from '../../../constants/import-config-data'
import { RequestFinanceComponent } from '../request-finance/request-finance.component';
@Component({
  selector: 'app-bank-listing',
  templateUrl: './bank-listing.component.html',
  styleUrls: ['./bank-listing.component.scss']
})
export class BankListingComponent implements OnInit {

  displayedColumns = [];
  displayedColumns1 = [];
  borrowerList: any;
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: any = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  searchID: string;

  constructor(private pagesService: PagesService, private activatedRoute: ActivatedRoute,
    private router: Router, public settingService: SettingService, public authService: AuthService,
    private toasterService: ToastrService) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }

  ngOnInit() {
    this.pagesService.isChangeColor("finance_white");
    this.activatedRoute.params.subscribe(params => {
      this.searchID = params['id'];
    })
    this.getDataForVedanata();
  }

  getDataForVedanata(){
    let arrConfig = [{ 'field': 'name', 'key': 'Lender Name' }, { 'field': 'accountNo', 'key': 'Account Number' },
    { 'field': 'isActive', 'key': '' }, { 'field': 'utilizedLimit', 'key': 'Limit Utilized' },
    { 'field': 'overDueAmountWithInCure', 'key': 'Overdue Amt' }, { 'field': 'overDueDaysWithInCure', 'key': 'Overdue Days' }];

    this.pagesService.getBuyerStats(this.searchID).subscribe(res => {
      if (res['result']) {
        this.borrowerList = res['result'];
        this.borrowerList['lenders'].map(val=>{
          if(val['isActive'] ==  false){
            val['isActive'] = "Inactive"
          }
          else{
            val['isActive'] = "Active"
          }
        })
        this.dataSource.data = this.borrowerList['lenders'];
        this.totalRecords = this.borrowerList['lenders'].length;
      }
      else {
        this.dataSource.data.length = 0;
      }
      this.paginator.length = this.totalRecords;
      let headerData = arrConfig;
      this.createHeaders(headerData);
    })
  }

  //creating columns dynamically for buyer table
  createHeaders = (config) => {
    this.displayedColumns1 = [];
    this.displayedColumns = [];
    config.map((each) => {
      this.displayedColumns1.push(each.field);
      each['cell'] = (element: any) => {
        return _.get(element, each.field);
      }
    })
    this.displayedColumns1.push('Action');
    this.displayedColumns = config;
  }

  getBuyerInvoice(row, status) {
    if (status === "name") {
      this.router.navigate(['/channelFinanceInvoice/' + this.borrowerList['searchKey'] + "/" + this.borrowerList['businessName'] + "/" + row.lenderId + '/' + this.searchID]);
    }
  }

  //setting css
  getClass(status, data) {
    if (status === "name" || status === "invoiceNumber") {
      return "overtext";
    }
    else if(status === "isActive") {
      if(data[status] == "Active")
        return 'statustrue status';
      else  
        return 'statusfalse status';   
    }
    else {
      return '';
    }
  }

  openSupplierDetails(row, buttonRef, status, list?) {
    if (status == 'true')
      buttonRef._elementRef.nativeElement.style.visibility = "visible"
    else
      buttonRef._elementRef.nativeElement.style.visibility = "inherit"
  }

  openRepayments(data) {
    this.router.navigate(['/repayments/' + this.borrowerList['searchKey'] + '/' + data['lenderId'] + '/' + this.searchID]);
  }

  generateLink(row) {
    let obj = {
      email: row.userEmail
    }
    this.authService.forgotPassword(obj).subscribe(res => {
      if (res['result'] == true) {
        this.pagesService.offSpinner();
        this.toasterService.success("Resend link sent to Your email");
      }
      else {
        this.toasterService.error("Something went wrong");
      }
    })
  }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

  breadcrumNavigation() {
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

}
