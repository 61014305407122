import { Injectable } from '@angular/core';
import { catchError, map} from 'rxjs/operators';
import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class ApiService {
  dataSessionTimeOut: boolean;

  constructor(
    public httpClient: HttpClient,private toasterService: ToastrService
  ) {}

  getTimeZone(): any {
    return new Date().getTimezoneOffset();
  }
  getTimeZoneArea(): any {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getToken(): string {
    if (this.getCookie('token')) {
      return this.getCookie('token');
    } else {
      return '';
    }
  }


  callRestful(type: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string, options?: { params?: {}; body?: {} }) {
    const httpHeaders = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('zone', this.getTimeZone())
        .set('timezoneId', this.getTimeZoneArea())
        .set('token', this.getToken())
    };
    switch (type) {
      case 'GET':
        return this.httpClient.get<any>(url, httpHeaders).pipe(
          map(res => res),
          catchError(error => this.handleError(error, type, url))
        );
      case 'POST':
        return this.httpClient.post<any>(url, options.body, httpHeaders).pipe(
          map(res => res),
          catchError(error => this.handleError(error, type, url))
        );

      case 'PUT':
        return this.httpClient.put<any>(url, options.body, httpHeaders);

        case 'DELETE':
        return this.httpClient.delete<any>(url, httpHeaders);

      default:
        return null;
    }
  }

  callRestfulWithFormData(method, url: string, formDataArray: Array<any>): any {
    const fd = new FormData();
    formDataArray.forEach(element => {
      fd.append('file', element);
    });

    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('zone', this.getTimeZone());
    xhr.setRequestHeader('timezoneId', this.getTimeZoneArea());
    xhr.setRequestHeader('token', this.getToken());
    xhr.send(fd);
    return xhr;
  }

  callRestfulWithAdditionalForm(method, url: string, fd?: FormData): any {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('zone', this.getTimeZone());
    xhr.setRequestHeader('timezoneId', this.getTimeZoneArea());
    xhr.setRequestHeader('token', this.getToken());
    fd ? xhr.send(fd) : xhr.send();
    // xhr.send(fd);
    return xhr;
  }

  private handleError(error: Response | any, type ?: string, url ?: string, isNotError?: boolean ) {
    console.log('error got from api', error);
    if (!isNotError && !error.ok) {
      if (parseInt(error.status) === 302) {
        if (navigator.onLine) {
          this.dataSessionTimeOut = true;
          this.toasterService.error(error+'Session timed out. Please refresh the page.');
        }
      }
      if (parseInt(error.status) === 500) {
        this.toasterService.error(error+'Something wrong with the application');
      }
      if (parseInt(error.status) === 0) {
        if (navigator.onLine) {
          this.dataSessionTimeOut = true;
        }
      }
    }
    let errMsg: string;
    // TODO error message on screen
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }

  public getCookie(name: string) {
    return localStorage.getItem(name);
  }

  public deleteCookie(name: any) {
    localStorage.removeItem(name);
  }

  public setCookie(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  public getSessionValue(name: string) {
    return sessionStorage.getItem(name);
  }

  public deleteSessionValue(name: any) {
    sessionStorage.removeItem(name);
  }

  public setSessionValue(name: string, value: any) {
    sessionStorage.setItem(name, value);
  }
}
