<div class="dialog-transaction">
  <i style="size:30px;float: right;"class="ri-close-line" (click)="dialogRef.close()"></i>
  <div>
    <h2>History</h2>
  </div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef>User Name </th>
    <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td [ngClass]="getClass(element.status)" mat-cell *matCellDef="let element"> {{getStatus(element.status)}} </td>
  </ng-container>


  <ng-container matColumnDef="remark">
    <th mat-header-cell *matHeaderCellDef> Remark </th>
    <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef> Created At</th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
