<section class="main-wrap">
  <div class="head-sec d-flex al-center js-between">
    <div class="left">
      <h2>Under Writing Document Collection</h2>
    </div>

    <div class="right d-flex al-center">
      <button type="button" (click)="redirectGst()" class="btn-outline-primary save-cta" mat-stroked-button color="black"><i class="ri-eye-fill"></i>GST Report</button>
      <button type="button" (click)="downloadAllFiles()" class="btn-outline-primary download-cta" mat-stroked-button color="black"><i class="ri-download-2-fill"></i>Download Uploaded File</button>
    </div>
  </div>

  <div class="inner-wrap">
    <form id="newLeads">

    <div class="addSupplier-wrap">

      <div class="supplierTable">
        <div class="mat-table-overflow default-table-height" *ngIf="false">
          <table
            mat-table
            class="mat-table-theme mat-table-bordered"
            [dataSource]="dataSource1">

            <ng-container matColumnDef="Documents" sticky>
              <th mat-header-cell *matHeaderCellDef class="sticky-left">
                <span class="pr-20">Documents </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index" class="sticky-left">

                <span class="link"><i class="ri-image-line"></i> {{ element.Documents }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">Status </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                <span class="pending" *ngIf ="element.Status == 'Pending'"> {{ element.Status }} </span>
                <span class="aprrove" *ngIf ="element.Status == 'Approve'"> {{ element.Status }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Remarks">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">Remarks </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                {{ element.Remarks }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Action" sticky>
              <th mat-header-cell *matHeaderCellDef class="sticky-right">
                <span>Action </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index" class="sticky-right">

                <div class="d-flex al-center flex-nowrap cta-box">
                  <button class="accept-cta" type="button">Accept </button>
                  <button class="reject-cta" type="button"> Reject</button>
                  <button class="download-cta" (click)="openDialog()"> Add Remarks</button>
                </div>
              </td>
            </ng-container>


            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns1; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayColumns1"
            ></tr>
          </table>
        </div>

        <div class="mat-table-overflow default-table-height">
          <table
            mat-table
            class="mat-table-theme mat-table-bordered"
            [dataSource]="dataSource2">

            <ng-container matColumnDef="Documents" sticky>
              <th mat-header-cell *matHeaderCellDef class="sticky-left">
                <span class="pr-20">Documents </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index" class="sticky-left">
                
                <span class="link" *ngIf="checkArray(element.value)"><i class="ri-image-line"></i> {{ element.name }} </span> 
                <!-- <span class="link" *ngIf="checkArray(element.value)" > + {{ element.value.length }} Files</span>  -->
                <span class="link" *ngIf="checkArray(element.value) && element.value[0].documentId; else elseBlock" (click)="openAllFilesPopUp(element.value[0].name)" > + {{ element.value.length }} Files</span> 
                <ng-template #elseBlock>
                  <span class="link" *ngIf="checkArray(element.value)">No Files Uploaded</span> 
                </ng-template>
                <span class="link" *ngIf="!checkArray(element.value)" (click)="downloadFile(element.value.documentId)"><i class="ri-image-line"></i> {{ element.name }} </span> 

              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">Status </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">
                  <span class="pending" *ngIf ="element.value[0].status == 'PENDING' || !element.value[0].status"> {{ element.value[0].status? element.value[0].status: 'PENDING' }} </span>
                  <span class="aprrove" *ngIf ="element.value[0].status == 'APPROVED'"> {{ element.value[0].status }} </span>
                  <span class="approve" *ngIf ="element.value[0].status == 'SUBMITTED'"> {{ element.value[0].status }} </span>
                  <span class="rejected" *ngIf ="element.value[0].status == 'REJECTED'"> {{ element.value[0].status }} </span>
                  <span class="aprrove" *ngIf ="element.value[0].status == 'WAIVERED'"> {{ element.value[0].status }} </span>
                  <span class="rejected" *ngIf ="element.value[0].status == 'DEFFERED'"> {{ element.value[0].status }} </span><br>
                  <span style="color: black;"><b>{{element.value[0].deferredDate ? (element.value[0].deferredDate  | date: 'dd MMMM yyyy')  : null}}</b></span>
                </ng-container>
                <ng-container *ngIf="!checkArray(element.value) && element && element.value">
                  <span class="pending" *ngIf ="element.value.status == 'PENDING'"> {{ element.value.status }} </span>
                  <span class="aprrove" *ngIf ="element.value.status == 'APPROVED'"> {{ element.value.status }} </span>
                  <span class="approve" *ngIf ="element.value.status == 'SUBMITTED'"> {{ element.value.status }} </span>
                  <span class="rejected" *ngIf ="element.value.status == 'REJECTED'"> {{ element.value.status }} </span>
                  <span class="aprrove" *ngIf ="element.value.status == 'WAIVERED'"> {{ element.value.status }} </span>
                  <span class="rejected" *ngIf ="element.value.status == 'DEFFERED'">
                    {{ element.value.status }}</span><br>
                    <span style="color: black;"><b>{{element.value.deferredDate ? (element.value.deferredDate  | date: 'dd MMMM yyyy')  : null}}</b></span>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="Remarks">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">Under Writting Remarks </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index">
                <!-- <span> {{ element.Remarks }} </span> <span class="view-more">View More</span>  -->
                 <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">{{element.value[0].remarks}}</ng-container>
                 <ng-container *ngIf="!checkArray(element.value) && element && element.value">{{element.value.remarks}}</ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="Action" sticky>
              <th mat-header-cell *matHeaderCellDef class="sticky-right">
                <span>Action </span>
              </th>
              <td mat-cell *matCellDef="let element;let i = index" class="sticky-right">

                <div class="d-flex al-center flex-nowrap cta-box" *ngIf="hasAccess">
                  <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">
                    <ng-container *ngIf="element.value[0].status == 'PENDING' || !element.value[0].status || element.value[0].status == 'WAIVERED' || element.value[0].status == 'DEFFERED'">
                      <button class="accept-cta" (click)="changeStatus(element.value[0].name,'WAIVERED')" type="button">Waiver </button>
                      <button class="reject-cta" (click)="openDefferedDialouge(element.value[0].name,'DEFFERED')" type="button"> Deferred</button>
                    </ng-container>
                  <ng-container *ngIf="element.value[0].status == 'REJECTED'">
                  <!-- <ng-container > -->

                      <button (click)="triggerFolderUpload()" class="download-cta"><i class="ri-upload-2-fill"></i> Reupload</button>
                      <input type="file" id="folderInput" (change)="handleFolderUpload($event,element.value[0].name)" webkitdirectory multiple hidden>
                  </ng-container>

                  </ng-container>
                  <ng-container *ngIf="!checkArray(element.value) && element && element.value">
                    <ng-container *ngIf="element.value.status == 'PENDING' || element.value.status == 'WAIVERED' || element.value.status == 'DEFFERED'">
                      <!-- <ng-container > -->
                      <button class="accept-cta" (click)="changeStatus(element.value.name,'WAIVERED')" type="button">Waiver </button>
                      <button class="reject-cta" (click)="openDefferedDialouge(element.value.name,'DEFFERED')" type="button"> Deferred</button>
                    </ng-container>
                    <ng-container *ngIf="element.value.status == 'REJECTED'">
                      <button (click)="changeFile(element.value.id,element.value.name)" class="download-cta"><i class="ri-upload-2-fill"></i> Reupload</button>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>


            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns1; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayColumns1"
            ></tr>
          </table>
        </div>

      </div>

    </div>

  </form>
  </div>
</section>
