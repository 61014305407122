import { Component, OnInit, Inject } from '@angular/core';
import * as moment from "moment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-download-reconcile',
  templateUrl: './download-reconcile.component.html',
  styleUrls: ['./download-reconcile.component.scss']
})
export class DownloadReconcileComponent implements OnInit {

  startDate: any;
  endDate: any;
  intervalTime: any;
  supplierId: any;
  ledgerName: string;
  reportName: any;
  isReportDownload: boolean = false;
  constructor(public dialogRef: MatDialogRef<DownloadReconcileComponent>, public pagesService: PagesService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data['data']) {
      this.supplierId = data['data'].searchKey;
    }
    if (data.isPayout == true) {
      this.ledgerName = data.ledgerName
    }
    if (data.reportName) {
      this.reportName = data['reportName']
      this.isReportDownload = true;
    }
  }

  ngOnInit() {
  }
  onPeriodChange(event) {
    this.intervalTime = event;
  }
  changeFromDate(event) {
    let Start = event.value
    this.startDate = moment(Start).format('YYYY-MM-DD');
  }

  changeToDate(event) {
    let endValue = event.value;
    this.endDate = moment(endValue).format('YYYY-MM-DD');
  }
  downloadReconcileSheet() {
    let obj = {
      supplierId: this.supplierId,
    }
    if (this.intervalTime == 'custom') {
      obj['type'] = "custom"
      obj['startDate'] = this.startDate,
        obj['endDate'] = this.endDate
    }
    else {
      obj['interval'] = this.intervalTime
      obj['type'] = "interval"
    }
    if (this.data.isPayout == true) {
      this.downloadPayoutLedger(obj)
    }
    else if(this.isReportDownload == true){
      this.downloadPaymentReport(obj)
    }
    else {
      this.pagesService.reconcileSheet(obj).subscribe(res => {
        this.downloadCSV("reconcileSheet.csv", res);
      })
    }
  }
  resultDownload;
  downloadPayoutLedger(obj) {
    if (this.data.isPayout == true && this.supplierId != null) {
      obj['supplierId'] = this.supplierId
    }
    obj['reportName'] = this.ledgerName
    obj['timePeriod'] = this.intervalTime
    this.pagesService.downloadLedgerReport(obj).subscribe((res: any) => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      saveAs(res.body, this.resultDownload);
      return this.resultDownload.replace(/"/g, '');

    })

  }


  downloadPaymentReport(obj) {
    obj['timePeriod'] = this.intervalTime
    obj['reportType'] = this.reportName
    if (this.reportName == 'ANCHOR_TREASURY_DISB_CALC' || this.reportName == 'ANCHOR_TREASURY_DISB_RECON') {
      this.pagesService.downloadAnchorPaymentReport(obj).subscribe(res => {
        var contentDispositionHeader = res.headers.get('content-disposition');
        this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        saveAs(res.body, this.resultDownload);
        return this.resultDownload.replace(/"/g, '');
      })
    }
    else if(this.reportName != 'ANCHOR_TREASURY_DISB_CALC' || this.reportName != 'ANCHOR_TREASURY_DISB_RECON'){
      obj['financierId']='608017c56a9b1d03aa0afe64'
      this.pagesService.downloadLenderPaymentReport(obj).subscribe(res=>{
        var contentDispositionHeader = res.headers.get('content-disposition');
        this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        saveAs(res.body, this.resultDownload);
        return this.resultDownload.replace(/"/g, '');

      })

    }
  }

  downloadCSV(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }
  }

  closePaymentsDialog() {
    this.dialogRef.close(true);
  }
  displaySheetName() {
    if (this.ledgerName == 'CREDLIX_LEDGER_REPORT') {
      return 'Credlix Ledger'
    }
    else if (this.ledgerName == 'ANCHOR_WISE_LEDGER_REPORT') {
      return 'Anchore Wise Ledger'
    }
    else if (this.ledgerName == 'SUPPLIER_ANCHOR_WISE_LEDGER_REPORT') {
      return 'Supplier Anchor Ledger'
    }
    else {
      return 'Payments Data'
    }
  }
}
