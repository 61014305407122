<div class="manualForm">
  <h4>Manual Credit Form</h4>
  <form [formGroup]="manualCreditForm" (ngSubmit)="submitDetails()">
    <div class="form-details">
      <div fxLayout="row">
        <mat-form-field appearance="outline">
          <mat-label>Client Code</mat-label>
          <input matInput formControlName="client Code">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Virtual Account Number</mat-label>
          <input matInput formControlName="Virtual Account Number">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="itemlimit" appearance="outline">
          <mat-label>Transaction Amount</mat-label>
          <input type="number" matInput formControlName="Transaction Amount">
        </mat-form-field>
        <mat-form-field class="itemdscnt" appearance="outline">
          <mat-label>Mode</mat-label>
          <mat-select formControlName="Mode">
            <mat-option *ngFor="let mode of modeList" [value]="mode.value">
              {{mode.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="itemdscnt" appearance="outline">
          <mat-label>UTR</mat-label>
          <input matInput formControlName="UTR">
        </mat-form-field>
        <mat-form-field class="itemdscnt" appearance="outline">
          <mat-label>Remitter Name</mat-label>
          <input matInput formControlName="Remitter Name">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field class="itemdscnt" appearance="outline">
          <mat-label>Remitter Account Number</mat-label>
          <input matInput formControlName="Remitter Account Number">
        </mat-form-field>
        <mat-form-field class="itemdscnt" appearance="outline">
          <mat-label>Sender IFSC</mat-label>
          <input matInput formControlName="Sender IFSC">
        </mat-form-field>
      </div>
      <mat-form-field class="itemdscnt" appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="getDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <button mat-raised-button class="savebtn">Submit</button>

  </form>
</div>