<div>
 
  <div style="position: relative;" >
   
    <div fxLayout="column" fxLayoutAlign="center center">
    
      <div fxLayout="column"  style="width: 100%; padding: 2%;" >
        <div fxLayout="row"  style="display: flex;justify-content: space-between;">
          <div>
            <label>Discount Debit Notes </label>
          </div>
          <div style="display: flex;justify-content: space-between; align-items: center;">
            <div class="seachBox mr-15">
              <div>
                <mat-form-field appearance="outline" class="mog3_matinput">
                  <input type="number" placeholder="Enter Supplier ID" aria-label="Number" matInput [(ngModel)]="selectedId"><span class="cursor-pointer" *ngIf="selectedId" (click)="clearSync()"><i class="ri-close-line"></i></span>
                </mat-form-field>
              </div>
            </div>
            <div class="mr-15">
              <button mat-flat-button class="syncUp" (click)="syncDebitNote()"><i class="ri-refresh-line"> Sync </i></button>
            </div>
            <div class="mr-15">
              <button type="button" class="downloadButton" matTooltip="Download" (click)="downloadDebitNote()" >
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </div>
        </div>
          <div class="sb_table"  >
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
            <!-- tag Column -->
            <ng-container matColumnDef="debitNoteNumber">
              <th mat-header-cell *matHeaderCellDef> Debit Note  Number </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <a class="table-link" (click)="openCardDetails(element)">{{element.debitNoteNumber}}</a>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="invoiceNumber">
              <th mat-header-cell *matHeaderCellDef> Invoice Number </th>
              <td mat-cell *matCellDef="let element" style=" width: 20% !important;">

                <div fxLayout="column" >
               <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayout="column" fxLayoutAlign="center start">
                    <span class="table-title">{{element.invoiceNumber || "N/A"}}</span>
                  </div>
              </div>
              </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierId">
              <th mat-header-cell *matHeaderCellDef>Supplier Id 
                <span class="arrowDown">
                  <i class="ri-arrow-down-s-fill" title="Search Supplier ID" [matMenuTriggerFor]="menu"></i>
                </span>
                <mat-menu #menu="matMenu" class="searchListItem">
                 <input type="text" class="inptBox" placeholder="Search Supplier ID" [(ngModel)]="supplierIDModel"
                  (click)="$event.stopPropagation();" (input)="onSearchSupplierID(supplierIDModel)">
                 <i class="ri-close-line iconClose" *ngIf="showCloseIcon" (click)="resetSearchText()"></i>
                 <button mat-menu-item *ngFor="let list of suggestedSuppliers" [ngClass]="{ activeID: activeSupplierId == list.businessName}"
                  (click)="navigateToSearchedSupplier(list)">
                   {{list.businessName}} - {{list.searchkey}}</button>
                </mat-menu>
             
              </th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxLayout="column" fxLayoutAlign="center start">
                          <span>{{element.supplierId}}</span>
                      </div>
                  </div>
                 
              </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierName">
              <th mat-header-cell *matHeaderCellDef> Supplier Name</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="table-title">{{element.supplierName || "N/A"}}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef> Customer Name</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="table-title">{{element.customerName || "N/A"}}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="table-title">{{element.amount || "N/A"}}</span>
                </div>
              </td>
            </ng-container>
           
            <ng-container matColumnDef="debitNoteDate">
              <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="center start">
                  <span class="table-title">{{element.debitNoteDate | date}} </span>
        
                </div>
              </td>
            </ng-container>  
           
            <ng-container matColumnDef="pathToPdf">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                <div class="actions-list">
                    <mat-icon class="material-symbols-outlined" (click)="viewDiscountDN(element)">visibility</mat-icon>
                    <!-- <mat-icon  class="material-symbols-outlined" (click)="downloadDN(element)">download</mat-icon> -->
                </div>
              </td>

            </ng-container>


            <!-- <ng-container matColumnDef="more">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element"> <i class="ri-more-2-fill"></i></td>
                </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
             
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">
            
            </tr>
           
          </table>
          <div class="noRecords" *ngIf="dataSource.data.length == 0">No Records Found</div>

          </div>
          <mat-paginator [(length)]="totalRecords" (page)="getNext($event)" [pageSizeOptions]="[25,50,75,100]" [pageIndex]="pageNumber" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
      </div>
     
    </div>