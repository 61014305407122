import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ReportDownloadInvoiceComponent } from '../../early-payments/early-payment-listing/report-download-invoice/report-download-invoice.component';
import * as lodash from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-repayments',
  templateUrl: './repayments.component.html',
  styleUrls: ['./repayments.component.scss']
})
export class RepaymentsComponent implements OnInit {

  isFiltersShow: boolean = true;
  selectedDate: any;
  customDateOption = null;
  timeSlot: number;
  startDate: any;
  endDate: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  setfilterValue: any;
  isClearButtonEnable: boolean = true;
  isApplyButtonEnable: boolean = true;
  bankRepaymentData = [];
  isBuyer: boolean = false;   
  totalRecords: number = 0;
  pageSize: number = 25;
  pageNumber: number = 0;   
  setbuyerID: string;     
  date: any;    
  displayedColumns = ['lenderName','expiryDate','amountRequested','overdueDaysBeyondCure','overdue'];
  dataSource = new MatTableDataSource(this.bankRepaymentData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  _: any = lodash;
  lenderId: string;
  searchID: string;

  constructor(public dialog: MatDialog, private pagesService: PagesService, private router: Router,
              private authService: AuthService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.pagesService.isChangeColor("finance_white");
    this.date = new Date();
    this.date  = moment(this.date).format("MMM-YYYY");
    if(this.authService.isBuyer()){
      this.isBuyer = true;
    }
    this.activatedRoute.params.subscribe(params => {
      this.setbuyerID = params['searchKey'];
      this.lenderId = params['lenderId'];
      this.searchID = params['id'];
    })
    this.pagesService.getRepayments(this.setbuyerID, this.pageNumber, this.pageSize, this.lenderId).subscribe(res => {
      if(res['result']){
        this.dataSource.data = res['result'];
        this.totalRecords = res['size'];
      }
    })
  }

  supplierReportName: string;
  downloadSupplierInvoices() {
    const dialogRef = this.dialog.open(ReportDownloadInvoiceComponent, {
      disableClose: true,
      width: '550px',
      height: '250px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        this.supplierReportName = result;
        // this.downloadSuppliers();
      }
    });
  }

  filtersDisplay() {
    this.isFiltersShow = !this.isFiltersShow;
  }

  // getCustomSelectedDate(item) {
  //   this.timeSlot = item;
  //   this.setfilterValue = null;
  //   if (item == -1) {
  //     this.startDate = "No Date Selected";
  //     this.endDate = "";
  //     this.setfilterValue = "No Date Selected";
  //   }
  //   else {
  //     this.setfilterValue = "Last" + " " + item + " " + "Days";
  //   }
  //   this.selectedDate = null;
  //   if (this.timeSlot != null) {
  //     this.isClearButtonEnable = false;
  //     this.isApplyButtonEnable = false;
  //   }
  // }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    this.router.navigate(['/bankLists/' + this.searchID]);
  }

  getPaginatorData(event) {
    this.pagesService.getRepayments(this.setbuyerID, this.pageNumber, this.pageSize, this.lenderId).subscribe(res => {
      if(res['result']){
        this.dataSource.data = res['result'];
        this.totalRecords = res['size'];
      }
    })
  }

}