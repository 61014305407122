import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CommissionInvoicesDetailsComponent } from './commission-invoices/commission-invoices-details/commission-invoices-details.component';

const routes: Routes = [
  {path:'commission-invoices-details/:id', component: CommissionInvoicesDetailsComponent,
  
},
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommissionInvoicesRoutingModule { }
