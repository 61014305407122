import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-remarks-dailog',
  templateUrl: './remarks-dailog.component.html',
  styleUrls: ['./remarks-dailog.component.scss']
})
export class RemarksDailogComponent {

  constructor(public dialogRef: MatDialogRef<RemarksDailogComponent>) { }

  closeDialog() {
    this.dialogRef.close();
  }

}
