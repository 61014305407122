import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '../shared-modules/custom-material/custom-material.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SpecialCharValidationDirective } from 'src/app/directive/special-char-validation.directive';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomMaterialModule,
    MatBottomSheetModule
  ],
  exports: [
    CustomMaterialModule,
    MatBottomSheetModule
  ],
})
export class SharedModulesModule { }
