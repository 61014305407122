import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { BankListingComponent } from './bank-listing/bank-listing.component';
import { BuyerListingComponent } from './buyer-listing/buyer-listing.component';
import { FinanceInvoiceListingComponent } from './finance-invoice-listing/finance-invoice-listing.component';
import { LoanInvoicingComponent } from './loan-invoicing/loan-invoicing.component';
import { RepaymentsComponent } from './repayments/repayments.component';
import { ViewAccountStatementComponent } from './view-account-statement/view-account-statement.component';

const routes: Routes = [
  {
    path: 'channelFinance',
    component: BuyerListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'channelFinanceInvoice',
    component: LoanInvoicingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'channelFinanceInvoice/:searchKey/:businessName/:lenderId/:id',
    component: LoanInvoicingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'repayments',
    component: RepaymentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'repayments/:searchKey/:lenderId/:id',
    component: RepaymentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bankLists',
    component: BankListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bankLists/:id',
    component: BankListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'financeListing',
    component: FinanceInvoiceListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'financeListing/:id/:businessName',
    component: FinanceInvoiceListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'viewStatement/:id/:businessName/:buyerId',
    component: ViewAccountStatementComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
})
export class ChannelFinancingRoutingModule { }
