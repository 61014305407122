import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cams',
  templateUrl: './cams.component.html',
  styleUrls: ['./cams.component.scss']
})
export class CAMSComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
