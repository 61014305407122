import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import { DownloadReconcileComponent } from '../../suppliers/listing/download-reconcile/download-reconcile.component';

// const ELEMENT_DATA= [
//   {name: 'Anchor Treasury Disb calc', value: 'ANCHOR_TREASURY_DISB_CALC'},
//   {name: 'Anchor Treasury Disb Recon', value: 'ANCHOR_TREASURY_DISB_RECON'},
//   {name: 'Lender Disbursement Anchor Calc Report', value: 'LENDER_DISBURSEMENT_ANCHOR_CALC_REPORT'},
//   {name: 'Lender Disbursement Lender Calc Report', value: 'LENDER_DISBURSEMENT_LENDER_CALC_REPORT'},
//   {name: 'Lender Disbursement Anchor Recon Report', value: 'LENDER_DISBURSEMENT_ANCHOR_RECON_REPORT'},
//   {name: 'Lender Disbursement Lender Recon Report', value: 'LENDER_DISBURSEMENT_LENDER_RECON_REPORT'},
// ];

@Component({
  selector: 'app-payout-reports',
  templateUrl: './payout-reports.component.html',
  styleUrls: ['./payout-reports.component.scss']
})
export class PayoutReportsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'action'];
  payoutList = [];
  dataSource = new MatTableDataSource(this.payoutList);;
  constructor(public dialog: MatDialog, private settingService: SettingService) { }

  ngOnInit() {
    this.fetchPayoutReport();
  }

  fetchPayoutReport() {
    this.settingService.getPayoutReport().subscribe((res: any) => {
      this.payoutList = (res.result);
      this.dataSource.data = Object.values(this.payoutList);
    })
  }

  downloadPayoutReport(report) {
    for( let key in this.payoutList)
     if(this.payoutList.hasOwnProperty(report)){
       var sendValue= this.payoutList[key];
     }
    
    let obj = {
      //reportName: values,
    }
    const dialogRef = this.dialog.open(DownloadReconcileComponent, {
      data: obj,
      width: '500px',
    });
  }

}
