<div fxFlexFill>
  <section class="ctaStripWrap">
    <div class="lft" >
      <button class="filter back" mat-button (click)="navigateToPayoutPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button class="filter fltbtn" mat-button [ngClass]="{'open':ispayoutFiltersShow}" (click)="filtersDisplay()" *ngIf="urlPaarams.index != null" >
        <span class="material-icons">
          filter_list
        </span>
        <span> Filter</span>
      </button>
      <button class="download" mat-button matTooltip="Download Payouts Transactions" *ngIf="(matStepperCount==1 || matStepperCount==2 )" (click)="downloadTransactionSheet()">
        <i class="ri-download-line"></i>
      </button>
    </div>
    <div class="rht">
      <button class="save-btn" mat-button [disabled]="isFileUploaded" *ngIf="matStepperCount==0"> 
        <i class="ri-save-2-line"></i>
        </button>
   
      <button class="dropdown bulkbtn" mat-button [disabled]="isFileUploaded" *ngIf="matStepperCount==0" (click)="reviewPayment(stepper, 'review')">Review
        <mat-icon class="iconRht"> keyboard_arrow_right</mat-icon>  </button>
        <button class="dropdown bulkbtn" mat-button *ngIf="matStepperCount==1" (click)="reviewPayment(stepper, 'accept')">Accept and Prepare Payments
      </button>
      <button class="dropdown bulkbtn" mat-button *ngIf="matStepperCount==2 && !isPaymentInitiate" (click)="verifyPaymentOTP()"> Send Payment
      </button>
    </div>
  </section>
  <mat-horizontal-stepper #stepper (selectionChange)="selectionChange($event)" class="escrowMatStepper">
    <ng-template matStepperIcon="edit" let-index="index">
      {{(index+1) * 1}}
    </ng-template>
    <mat-step [completed]="isFirstStepDone">
      <ng-template matStepLabel>Upload File</ng-template>
      <app-uploadfile-payout (valueChange)='displayCounter($event)' *ngIf="matStepperCount==0"></app-uploadfile-payout>
    </mat-step>
    <mat-step [completed]="isSecondStepDone">
      <ng-template matStepLabel>Review</ng-template>
      <app-review-payout  *ngIf="matStepperCount==1"></app-review-payout>
    </mat-step>
    <mat-step [completed]="completedFinal" [state]="statefor">
      <ng-template matStepLabel>Send Payment</ng-template>
      <app-review-payout *ngIf="matStepperCount==2"></app-review-payout>
    </mat-step>
  </mat-horizontal-stepper>
