<div class="scrlbl-area">
  <section class="ctaStripWrap">
    <div class="lft">
      <!-- <button class="filter fltbtn" mat-button [ngClass]="{'open':!isFiltersShow}" (click)="filtersDisplay()">
        <span class="material-icons">
          filter_list
        </span>
        <span> Filter</span>
      </button> -->
    </div>
  </section>
  <div class="Headcls">
    <h4>Escrow Transaction</h4>
    <div class="filters_ET" *ngIf="!authService.isBorrower()">
      <div>
        <mat-form-field appearance="outline" class="mog3-matselect">
          <mat-select #matRef placeholder="Select Supplier" multiple (selectionChange)="onSupplierChange($event.value)">
            <mat-option *ngFor="let option of supplierList" [value]="option.id">
              {{ option.businessName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <input type="text" class="setinpt" placeholder="Enter VAN" (keyup.enter)="getEscrowPayment(false)"
          [(ngModel)]="virtaulAccountNumber">
      </div>
      <div>
        <input type="text" class="setinpt" placeholder="Enter UTR" (keyup.enter)="getEscrowPayment(false)"
          [(ngModel)]="UTRNumber">
      </div>
      <div>
        <input type="text" class="setinpt" placeholder="Remitter Name" (keyup.enter)="getEscrowPayment(false)"
          [(ngModel)]="remitterName">
      </div>
      <div class="scnd">
        <span class="lbl">From :</span>
        <mat-form-field appearance="outline" class="mog3-matselect">
          <input matInput [(ngModel)]="startDate" [matDatepicker]="picker" placeholder="Select Start Date"
            (dateChange)="changeFromDate($event)" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span class="lbl">To :</span>
        <mat-form-field appearance="outline" class="mog3-matselect">
          <input matInput [(ngModel)]="endDate" [matDatepicker]="picker1" [min]="startDate" placeholder="Select End Date"
            (dateChange)="changeToDate($event)" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="leftfilteraction">
        <button mat-flat-button class="appbtn" #button (click)="getEscrowPayment(false)">Apply</button>
        <button mat-flat-button class="resetbtn" #button (click)="resetTransactionFilters()">Clear
          All</button>
      </div>
    </div>
    <div fxLayout="row"  style="display: flex;justify-content: space-between; float: right;" *ngIf="authService.isSIDUSER()">
      <div class="dwnBtn">
        <mat-icon matTooltip="Download" matTooltipClass="new-tooltip" (click)="downloadEP()">download
        </mat-icon>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 table_invoice">

      <ng-container matColumnDef="remitterName">
        <th mat-header-cell *matHeaderCellDef>Remmitter Name</th>
        <td mat-cell *matCellDef="let element"> {{element.remitterName}} </td>
      </ng-container>

      <ng-container matColumnDef="supplierName">
        <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{element.supplierName}}"
        matTooltipClass="new-tooltip" (click)="onRowClick(element)"> {{element.supplierName}} </td>
      </ng-container>

      <ng-container matColumnDef="remitterAccountNumber">
        <th mat-header-cell *matHeaderCellDef>Remmitter Account Number</th>
        <td mat-cell *matCellDef="let element"> {{element.remitterAccountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="virtualAccountNumber">
        <th mat-header-cell *matHeaderCellDef>Virtual Account Number</th>
        <td mat-cell *matCellDef="let element"> {{element.virtualAccountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="utr">
        <th mat-header-cell *matHeaderCellDef>UTR</th>
        <td mat-cell *matCellDef="let element">{{element.utr}}
        </td>
      </ng-container>

      <ng-container *ngIf="!authService.isBorrower()" matColumnDef="senderIfsc">
        <th mat-header-cell *matHeaderCellDef>Sender IFSC</th>
        <td mat-cell *matCellDef="let element">{{element.senderIfsc}}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element"> {{element.date}}</td>
      </ng-container>

      <ng-container *ngIf="!authService.isBorrower()" matColumnDef="downloadFile">
        <th mat-header-cell *matHeaderCellDef>Payment Advice</th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="element.paymentAdviceUploaded" [matTooltip]="setDateTime(element)" class="ri-download-line downloadIcon" (click)="downloadfile(element)"></i>
          <p *ngIf="!element.paymentAdviceUploaded">--</p>
        </td>
      </ng-container>

      <ng-container *ngIf="authService.isBorrower()" matColumnDef="downloadFile">
        <th mat-header-cell *matHeaderCellDef>Payment Advice</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="!element.paymentAdviceUploaded || element.paymentAdviceStatus == 'REJECTED'">
            <label class="custom-file-upload">
              <input type="file" (change)="uploadFile($event, element)">
              <i class="ri-upload-line"></i> Upload
            </label>
          </div>
          <div *ngIf="element.paymentAdviceUploaded && element.paymentAdviceStatus != 'REJECTED'">
            <p>Uploaded</p>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="authService.isBorrower()" matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="getClass(element.paymentAdviceStatus)" class="statusDiv" *ngIf="(element.paymentAdviceUploaded && element.paymentAdviceStatus) || element.paymentAdviceStatus == 'REJECTED'">
            <p>{{getStatus(element.paymentAdviceStatus)}}</p>
            <mat-icon *ngIf="(element.paymentAdviceUploaded && element.paymentAdviceStatus) || element.paymentAdviceStatus == 'REJECTED'" class="icon-d" (click)="openTransactionHistory(element)">update</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="!authService.isBorrower()" matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header"> Actions </th>
        <td mat-cell *matCellDef="let item" class="actions-cell">
          <button *ngIf="item.paymentAdviceUploaded && !item.actionPerformed" mat-icon-button class="accept-button"
            (click)="acceptPayment(item)">
            <mat-icon>check</mat-icon>
          </button>
          <button *ngIf="item.paymentAdviceUploaded && !item.actionPerformed" mat-icon-button class="reject-button"
            (click)="rejectPayment(item)">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="status-div">
            <p [ngClass]="getClass(item.paymentAdviceStatus)" class="status" *ngIf="(item.paymentAdviceUploaded && item.actionPerformed && item.paymentAdviceStatus) || item.paymentAdviceStatus == 'REJECTED'">
              {{getStatus(item.paymentAdviceStatus)}}</p>
              <mat-icon *ngIf="(item.paymentAdviceUploaded && item.paymentAdviceStatus) || item.paymentAdviceStatus == 'REJECTED'" class="icon-c" (click)="openTransactionHistory(item)">update</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionAmount">
        <th mat-header-cell *matHeaderCellDef>Transaction Amount</th>
        <td mat-cell *matCellDef="let element"> {{element.transactionAmount | number :'1.0-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element"
          [attr.colspan]="!authService.isBorrower() ? columnsToDisplay2.length : columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="scroll">
              <table class="expandedTable">
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Supplier ID</th>
                    <th>EP REQUESTED </th>
                    <th>Invoice Amount</th>
                    <th>Deductions</th>
                    <th>Amount Paid</th>
                    <th>EP Amount </th>
                    <th>Payment Type </th>
                    <th>Amount Reported </th>
                    <th>Amount In Escrow</th>
                    <th>Payment to Lender </th>
                    <th>Payment to Supplier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of expandedDataSource?.fileEntries">
                    <td title={{item.invoiceNumber}}>{{item.invoiceNumber}}</td>
                    <td><span>{{item.supplierId}}</span></td>
                    <td> <span *ngIf="item.isEPRequested">YES</span>
                      <span *ngIf="!item.isEPRequested">NO</span>
                    </td>
                    <td> <span> {{item.documentAmount | number}}</span>
                    </td>
                    <td><span *ngIf="item.deductions != null"> {{item.deductions | number}}</span>
                    </td>
                    <td> <span *ngIf="item.amountPaid != null"> {{item.amountPaid | number}}</span>
                    </td>
                    <td> <span>{{item.epAmount | number}}</span></td>
                    <td> <span *ngIf="item.paymentType=='JOURNAL_PAYMENT'">Misc. Payment</span>
                      <span *ngIf="item.paymentType !='JOURNAL_PAYMENT'">
                        {{formatPaymentType(item.paymentType | titlecase)}} </span>
                    </td>
                    <td><span> {{item.amountReported | number}}</span></td>
                    <td> <span>{{item.entryEscrowMatch.amount | number}}</span></td>
                    <td> <span>{{item.payToLender | number}}</span></td>
                    <td> <span>{{item.payToSupplier | number}}</span></td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row  *matHeaderRowDef="!authService.isBorrower() ? columnsToDisplay2 : columnsToDisplay; sticky: true"></tr>


      <tr mat-row *matRowDef="let element; columns: !authService.isBorrower() ? columnsToDisplay2 : columnsToDisplay;"
        class="example-element-row" [class.example-expanded-row]="expandedElement === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNo" [pageSizeOptions]="[10,25,50,75,100]" [pageSize]="pageSize"
      (page)=" authService.isBorrower() ? getSupplierEscrowPayment($event) : getPaginatorData1($event)"
      showFirstLastButtons>
    </mat-paginator>



  </div>
