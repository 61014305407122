import { Component, OnInit, Input , Inject, ElementRef, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { KanbanService } from '../../../kanban.service';
import { Value } from 'sass';

@Component({
  selector: 'app-view-lane',
  templateUrl: './view-lane.component.html',
  styleUrls: ['./view-lane.component.scss']
})
export class ViewLaneComponent implements OnInit {
  @Input() laneTaskDetailsArgs;
  laneTaskIDDetail : any;
  laneArrayKeys = [];
  laneWiseData = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  public _kService: KanbanService) { }

  ngOnInit() {
    this.laneTaskIDDetail = this.data.laneTask;
    if (this.laneTaskIDDetail) {
      this.getLaneDetailsById();
    }
  }
  getLaneDetailsById() {
    this._kService.detailsByLaneId(this.laneTaskIDDetail.id).subscribe(res => {
      console.log("Lane Wise Data " + res)
      if (res != null && res['result'] != null) {
        this.laneWiseData = res['result']['laneWiseData']
        if (this.laneWiseData  != null) {
          let tmpKeys = Object.keys(this.laneWiseData);
          let tmpKeys2 =[];
          let tmp = [];
          for(let i=0; i < tmpKeys.length;i++) {
            let tmpvar = this.laneWiseData[tmpKeys[i]];
            if (tmpvar == null || tmpvar.length < 1) {
             // continue;
            }
            tmpKeys2.push(tmpKeys[i]);
          }
          this.laneArrayKeys = tmpKeys2;
        }
      }
    });
  }
  getLaneName (name) {
    return name.replaceAll("-", " ").toLowerCase();
  }
  validFieldTypes = [ "string"];
  getFieldsToDisplay  = (data) => {
    if (!data || data.length < 1) {
      return [];
    }
    let result = [];
    for(let i=0; i < data.length; i++) {
      if (data[i]['display'] == true && data[i]['type'] == "string") {
        result.push(data[i])
      } else if (data[i]['display'] == true && (data[i]['type'] == "email" || data[i]['type'] == "phone" || 
      data[i]['type'] == "amountWithCurrency" || data[i]['type'] == "boolean" )) {
        result.push(data[i])
      } else if (data[i]['display'] == true && data[i]['type'] == "dropdown") {
        let tmp = data[i];
        let val = "N/A";
        if (tmp['dataList'] != null && tmp['dataList'].length >0)  {
          val = tmp['dataList'].join(" and ");
        }
        tmp['value'] = val; 
        result.push(tmp);
      }  else if (data[i]['display'] == true && (data[i]['type'] == "file" || data[i]['type'] == "file")) {
        let tmp = data[i];
        let val = "N/A";
        if (tmp['value'] != null && tmp['value'].trim() != '')  {
          val = "Available";
        }
        tmp['value'] = val; 
        result.push(tmp);
      }
    }
   // console.log("returning result " + result)
    return result;
  } 
  getLeadContactDetails = (lead) => {
    var contact = {
      "email" : null,
      "phone" : null
    };
    if (lead['data'] != null && lead['data'].length > 0) {
      lead['data'].find(element => {
        if (element['field'] == "contact_details") {
          if (element['operationsData'] != null && element['operationsData']['CONTACT_DETAILS'] != null) {
            let contactDetails = element['operationsData']['CONTACT_DETAILS'];
            if (contactDetails != null && contactDetails.length > 0) {
              for(let k=0;k < contactDetails.length ; k++) {
                let ele = contactDetails[k];
                if (contact['email'] != null && contact['phone'] != null) {
                  break;
                }
                if (ele['phone'] != null && contact['phone'] == null) {
                  contact['phone'] = ele['phone'];
                }
                if (ele['email'] != null && contact['email'] == null) {
                  contact['email'] = ele['email'];
                }
              }
            }
          }
        } 
      });
    }
    return contact;
  }
}
