<div class="created-sales">
  <div class="header-div">
    <a>
      <span>
        <i class="ri-arrow-left-line" (click)="navigateBackToLeads()"></i>
      </span>
      {{leadDetails?.searchKey}}
    </a>
    <div class="user-name">
      <p>{{leadDetails?.exporterName}}</p>
    </div>
  </div>
  <div class="lead-summary-main">
    <div class="lead-summary-box">
      <div>
        <p class="base-header">Lead Summary</p>
        <span class="mr-5 leadKey">{{leadDetails?.searchKey}}</span>
        <p>Source : <span class="mr-5 leadKey">{{leadDetails?.leadSource}}</span></p>
        <p>Business Unit : <span class="mr-5 leadKey">{{leadDetails?.businessType}}</span></p>
      </div>
      <div>
        <p class="base-header">Contact Details</p>
        <p><i class="ri-user-fill"></i>&nbsp;{{leadDetails?.spoc1Name || '--'}}</p>
        <p><i class="ri-phone-fill"></i>&nbsp;{{leadDetails?.spoc1PhoneNumber || '--'}}</p>
        <p><i class="ri-mail-fill"></i>&nbsp;{{leadDetails?.spoc1Email  || '--'}}</p>
      </div>
      <div>
        <p class="base-header margin-20">Other Details</p>
        <div class="inner-icon-box">
          <div class="lead-score commonODInfo">
            <span class="icon-span"><i class="ri-dashboard-3-line spIcon"></i></span>
            <p><strong>{{leadDetails?.leadScore || 0}}</strong><span><i class="ri-checkbox-circle-fill"></i></span><br/>Lead Score</p>
          </div>
          <div class="buyer-no commonODInfo">
            <span class="icon-span"><i class="ri-user-follow-line spIcon"></i></span>
            <p><strong>{{leadDetails?.buyerDataList?.length || 0}}</strong><br/>No. of Buyer</p>
          </div>
          <div class="dollar commonODInfo">
            <span class="icon-span"><i class="ri-money-dollar-circle-fill spIcon"></i></span>
            <p><strong> {{leadDetails?.annualTurnoverValue || 0}}</strong><br/>Annual Turnover</p>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-btn" *ngIf="leadDetails?.status == 'MOVE_TO_LOS'">
      <button class="navigateBtn" (click)="navigateToNewLead()">Go to New Lead</button>
    </div>
  </div>
<div class="dialog-row">
  <div class="inner-dialog-div">
    <div>
      <mat-form-field appearance="outline" class="border-blue">
        <mat-select placeholder="Select" [(ngModel)]="statusStage">
          <mat-option value="Pre Qualified">Pre Qualified</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    <div class="main-icon-box cursor-pointer" (click)="openWhatsApp()">
      <i class="ri-file-fill"></i>
      <span>Template</span>
    </div>
    <div class="main-icon-box cursor-pointer" (click)="openWatchersTab()">
      <i class="ri-eye-fill"></i>
      <span>Watcher</span>
    </div>
    <div class="main-icon-box cursor-pointer" (click)="onSideTabToggle('users')">
      <i class="ri-user-shared-fill"></i>
      <span *ngIf="leadDetails?.assigneeDTO">{{leadDetails?.assigneeDTO?.userName}}</span>
      <span *ngIf="!leadDetails?.assigneeDTO">Set Assignee</span>
    </div>
    <div class="main-icon-box cursor-pointer" (click)="onSideTabToggle('documents')">
      <i class="ri-file-upload-fill"></i>
      <span>Document Upload</span>
    </div>
  </div>
</div>
  <div class="tab-div">
    <mat-tab-group>
      <mat-tab label="Lead Details"> 
        <div class="sub-tabs">
          <app-qualified-tabing [boardId]="boardId"></app-qualified-tabing>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>