<div class="dialog_modal">
  <div>
    <mat-icon class="iconclose" (click)="dialogRef.close(false)" >close</mat-icon>
  </div>
  <form [formGroup]="markdisbursedForm" (ngSubmit)="onSubmit()" class="moglix-form">
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Amount</mat-label>
        <input matInput id="amount" type="number" formControlName="amount" placeholder="Amount*">
      </mat-form-field>
      <mat-form-field appearance="fill" class="example-form-field">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="datepicker" (dateChange)="changeFromDatePicker($event, 'invoice')"
         formControlName="paymentDate" placeholder="Select Date" readonly>
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>UTR</mat-label>
        <input matInput id="utr" type="text" formControlName="utr" placeholder="UTR">
      </mat-form-field>
    </div>

  
    <div fxLayout="row" fxLayoutGap="10%">
        <div>
          <button mat-button type="submit" class="send-btn" [ngClass]="!markdisbursedForm.valid?'btn-disabled':'sub-btn'">OK
          </button>
        </div>
        <div>
          <button mat-button type="button" class="reset-btn" (click)="resetForm()"
            [ngClass]="!markdisbursedForm.valid?'btn-disabled':'sub-btn'">Reset
          </button>
        </div>
    </div>
  </form>
</div>