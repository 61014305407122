import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material'
import { KanbanService } from '../../../kanban.service'
import { ToastrService } from 'ngx-toastr'
import { SelectionModel } from '@angular/cdk/collections'

@Component({
	selector: 'app-sent-for-consent-popup',
	templateUrl: './sent-for-consent-popup.component.html',
	styleUrls: ['./sent-for-consent-popup.component.scss'],
})
export class SentForConsentPopupComponent implements OnInit {
	directorTable: any[] = []
	dataSource = new MatTableDataSource(this.directorTable)
	displayedColumns: any[] = ['checkbox', 'name', 'email', 'dse']
	selection = new SelectionModel<any>(true, [])
	constructor(public dialogRef: MatDialogRef<SentForConsentPopupComponent>, @Inject(MAT_DIALOG_DATA) public data, public kService: KanbanService, public toasterService: ToastrService) {}

	ngOnInit() {
		this.directorTable = this.data.directorIds;
		this.directorTable.push(this.data.primaryConact)
		this.dataSource.data = this.directorTable;
		this.dataSource.data.forEach(element=>{
			if(element.wheatherDscRegistered == 'Yes'){
				this.selection.select(element);
			}
		})
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length
		return numSelected === numRows
	}

	/** Whether part of the rows are selected. */
	isSomeSelected() {
		const numSelected = this.selection.selected.length;
		return numSelected > 0 && numSelected < this.dataSource.data.length
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	toggleAllRows(event: any) {
		if (event.checked) {
			this.dataSource.data.forEach((element) => {
				if(!element.isPrimary)
				this.selection.select(element)
			})
		} else {
			this.selection.selected.forEach(x=>{
				if(!x.isPrimary)
				this.selection.deselect(x)
			})
		}
	}

	/** Toggle a specific row */
	toggleRow(row: any) {
		this.selection.toggle(row)
	}
	sendForConsent() {
		let payload = []
		if (this.selection.selected.length > 0) {
			this.selection.selected.forEach((x) => {
				if(!x.isPrimary)
				payload.push(x.id)
			})
		}
		this.kService.sentForConsent(this.data.laneTaskId,payload).subscribe({
			next:(resp:any)=>{
				if(resp.success){
					this.toasterService.success("Please Check Your Mail To Approve!");
					this.dialogRef.close();
				}else{
					this.toasterService.error(resp.message);
				}
			}
		})
	}
}
