import { Component, OnInit, ViewChild } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import { saveAs } from 'file-saver';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditdetailsDialogComponent } from '../../suppliers/listing/editdetails-dialog/editdetails-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { LeadCommentDialogComponent } from '../lead-comment-dialog/lead-comment-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {
  displayedColumns = ['businessName', 'onBoarding', 'status', 'anchorName', 'entityType', 'location', 'lastUpdated', 'download'];
  leadInvoices = [];
  totalRecords: any = 0;
  pageSize: number = 25;
  pageNumber: number = 0;
  getLocalStorage
  dataSource = new MatTableDataSource(this.leadInvoices);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  masterAccountID: any;
  constructor(private pagesService: PagesService, public dialog: MatDialog,
    private settingService: SettingService,public router: Router, public authService: AuthService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
     }

  ngOnInit() {
    this.getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));

    this.settingService.isLeadsPage = true;
    if (this.authService.isLenderLeads()) {
      this.getLenderAnchor();
    }
    else {
      this.masterAccountID = localStorage.getItem('credlixUserID');
      this.fetchLenderLeads();
    }
  }
  getLenderAnchor() {
    this.pagesService.fetchLenderList().subscribe(res => {
      this.masterAccountID = res['result'][0]['id'];
      let obj = {
        masterAccountId: this.masterAccountID,
        size: this.pageSize,
        from: this.paginator.pageIndex,
      }
      this.pagesService.getLenderLeadsList(obj).subscribe(res => {
        if (res['result']) {
          this.dataSource.data = res['result'];
          this.totalRecords = res['total'];
        }
        else {
          this.dataSource.data.length = 0;
        }
        this.paginator.length = this.totalRecords;
      });
    })
  }

  getPaginatorData(event) {
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    this.fetchLenderLeads()
  }
  fetchLenderLeads() {
    let obj = {
      masterAccountId: this.masterAccountID,
      size: this.pageSize,
      from: this.paginator.pageIndex * this.pageSize,
      where: {
        isLead: {
          type: 'search',
          value: true
        }
      }
    }
    this.pagesService.getLenderList(obj).subscribe(res => {
      if (res['result']) {
        this.dataSource.data = res['result'];
        this.totalRecords = res['total'];
      }
      else {
        this.dataSource.data.length = 0;
      }
      this.paginator.length = this.totalRecords;
    });
  }

  editSupplierDetails(element) {
    element['islenderLogin'] = true;
    if (this.getLocalStorage.role.role.roleType == 'LENDER') {
      this.pagesService.isDisable = true;
    }
    else {
      this.pagesService.isDisable = false;
    }
    const dialogRef = this.dialog.open(EditdetailsDialogComponent, {
      disableClose: true,
      data: element,
      panelClass: 'supplier-editDialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!this.authService.isLenderLeads()) {
        if (result == true) {
          this.fetchLenderLeads();
        }
      }
    });
  }

  result;
  downloadLeads(element) {
    let obj = {
      id: element.id
    }
    this.settingService.downloadZip(obj.id).subscribe(res => {
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      saveAs(res.body, this.result);
      return this.result.replace(/"/g, '');
    })
  }
  setColorCode(value) {
    if (value == 'Pending') {
      return 'pendingColor'
    }
    else if (value == 'Submitted') {
      return 'submtdColor'
    }
    else if (value == 'Doc uploaded') {
      return 'uploadColor'
    }
  }

  ngOnDestroy() {
    this.settingService.isLeadsPage = false;
  }

  openCommentDialog(element) {
    const dialogRef = this.dialog.open(LeadCommentDialogComponent, {
      disableClose: true,
      width: '500px',
      height: '100%',
      data:element,
      panelClass: "leadComment"

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        window.location.reload();
      }
    });
  }

}
