import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-supplier-discount-dialog',
  templateUrl: './supplier-discount-dialog.component.html',
  styleUrls: ['./supplier-discount-dialog.component.scss']
})
export class SupplierDiscountDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SupplierDiscountDialogComponent>, public dialog: MatDialog, private settingservice: SettingService) { }

  ngOnInit() {
  }

  checkSupplierDiscount(value) {
    let obj = {}
    obj['interested'] = value;
    obj['partyName'] = "ABFRL";
    this.settingservice.discountPopup(obj).subscribe(res => {
        this.dialogRef.close(true);
      
    })
  }
  closeDisountDialog() {
    this.dialogRef.close(true);
  }
}