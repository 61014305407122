import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingComponent } from './listing/listing.component';
import { SuppliersRoutingModule } from './suppliers-routing.module'
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { SharedComponentModule } from 'src/app/shared-files/shared-component/shared-component.module';
import { AddSupplierDialogComponent } from './listing/add-supplier-dialog/add-supplier-dialog.component';
import { BulkactionsDialogComponent } from './listing/bulkactions-dialog/bulkactions-dialog.component';
import { DownloadReconcileComponent } from './listing/download-reconcile/download-reconcile.component';
import { EditdetailsDialogComponent } from './listing/editdetails-dialog/editdetails-dialog.component';
import { ConfirmationDialogComponent } from './listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component';
import { AddWhatsappcontactComponent } from './listing/supplier-profile/add-whatsappcontact/add-whatsappcontact.component';
import { SendWhatsappMsgDialogComponent } from './listing/send-whatsapp-msg-dialog/send-whatsapp-msg-dialog.component';
import { SupplierProfileComponent } from './listing/supplier-profile/supplier-profile.component';
import { RejectEscrowComponent } from '../escrow-payment/reject-escrow/reject-escrow.component';
import { TransactionHistoryComponent } from '../escrow-payment/transaction-history/transaction-history.component';

@NgModule({
  declarations:
    [
      ListingComponent,
      AddSupplierDialogComponent,
      BulkactionsDialogComponent,
      DownloadReconcileComponent,
      EditdetailsDialogComponent,
      ConfirmationDialogComponent,
      AddWhatsappcontactComponent,
      SendWhatsappMsgDialogComponent,
      SupplierProfileComponent,
      RejectEscrowComponent,
      TransactionHistoryComponent
    ],
  imports: [
    CommonModule,
    SuppliersRoutingModule,
    SharedModulesModule,
    FormsModule,
    DirectiveModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SharedComponentModule],
  entryComponents: [
    AddSupplierDialogComponent,
    ConfirmationDialogComponent,
    AddWhatsappcontactComponent,
    BulkactionsDialogComponent,
    RejectEscrowComponent,
    TransactionHistoryComponent,
    DownloadReconcileComponent,
    EditdetailsDialogComponent,
    SendWhatsappMsgDialogComponent,
    SupplierProfileComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthGuard]
})
export class SuppliersModule { }
