import { Component, Input, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/settings/setting.service';
import * as lodash from 'lodash';

@Component({
  selector: 'app-review-payout',
  templateUrl: './review-payout.component.html',
  styleUrls: ['./review-payout.component.scss']
})
export class ReviewPayoutComponent implements OnInit {
  _: any = lodash;
  matStepperCount: number;
  tabIndex: any;
  payoutID: any;
  fileID:any;
  isPaymentTabIndex: boolean = false;
  @ViewChild('tabs') tabGroup: MatTabGroup
  constructor(private settingService: SettingService, private activatedRoute: ActivatedRoute) {
    this.payoutID = sessionStorage.getItem('payoutId');
  }
  @HostListener('window:beforeunload') goToPage() {
    sessionStorage.removeItem("invoiceRes");
    sessionStorage.removeItem("supplierRes");
    sessionStorage.removeItem("paymentRes");
  }

  ngAfterViewInit() {
    this.settingService.getReviewTabIndex(this.tabIndex);
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.fileID= params.id;
      if(params.index==1){
        localStorage.setItem("tabIndex", '0');
      }
      else if(params.index==2){
        localStorage.setItem("tabIndex", '2');
      }      
      this.settingService._payoutElementSubjectBehv.subscribe(res => {
        if (Object.keys(res).length) {
          if ( res.status == 'PAYMENT_INITIATED' || res.status == 'APPROVED' || res.status == 'PAID' || res.status== 'PARTIALLY_SETTLED' || res.status == 'FAILED') {
            this.isPaymentTabIndex = true
            this.tabGroup.selectedIndex = 2;
            this.tabIndex = 2;
          }
          else if (res.status == 'AWAITING_APPROVAL') {
            this.isPaymentTabIndex = false;
            this.tabGroup.selectedIndex = 0;
            this.tabIndex = 0;
          }
        }
        else if (!Object.keys(res).length) {
          if (params.index == 1) {
            this.isPaymentTabIndex = false;
            this.tabGroup.selectedIndex = 0;
            this.tabIndex = 0;
          }
          else if (params.index == 2) {
            this.isPaymentTabIndex = true
            this.tabGroup.selectedIndex = 2;
            this.tabIndex = 2;
          }
        }
      });
    });
    this.getPayoutDetails();
  }
  payoutDetails:any;
  getPayoutDetails(){
    if(this.fileID == undefined){
      this.fileID= localStorage.getItem('escrowFieldID')
    }
    this.settingService.getTransactionEntries(this.fileID).subscribe(res => {
      this.payoutDetails= res['result'];
    })

  }
  checkBackClicked(value) {
    console.log("check", value);
  }

  selectTabIndex(event) {
    this.tabIndex = event;
    this.settingService.getReviewTabIndex(this.tabIndex);
    localStorage.setItem("tabIndex", (this.tabIndex));

  }
}
