import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';
import { WatcherPopupComponent } from '../watcher-popup/watcher-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CommentsTabComponent } from '../comments-tab/comments-tab.component';
import { LaneTaskDocumentsTabComponent } from '../lane-task-documents-tab/lane-task-documents-tab.component';
import { LaneTaskUsersTabComponent } from '../lane-task-users-tab/lane-task-users-tab.component';
import { WhatsappTemplateComponent } from '../whatsapp-template/whatsapp-template.component';
import { HistoryTabComponent } from '../history-tab/history-tab.component';
import { PagesService } from 'src/app/pages/pages.service';


@Component({
  selector: 'app-pre-qualified-details',
  templateUrl: './pre-qualified-details.component.html',
  styleUrls: ['./pre-qualified-details.component.scss']
})
export class PreQualifiedDetailsComponent implements OnInit {
  boardId: string;
  leadDetails:any;
  statusStage: string = "Pre Qualified"

  constructor(public activateRoute : ActivatedRoute,public preSaleService : PresaleService, public router : Router, public dialog: MatDialog, public pagesService : PagesService) { }

  ngOnInit() {
    this.pagesService.isChangeColor("white_doc_cb");
    this.activateRoute.params.subscribe(param => {
      this.boardId = param.id;
    })
    this.getLeadDetails(this.boardId);
  }
  getLeadDetails(id){
    this.preSaleService.leadDetailSearchById(id).subscribe(res =>{
        this.leadDetails = res['result'];
    })
  }
  navigateBackToLeads(){
    this.router.navigate(['/credlixboard']);
  }
  openWatchersTab(){
    const dialogRef = this.dialog.open(WatcherPopupComponent, {
      disableClose: false,
      panelClass: 'watcherPopupWrap',
      data: {
        "preQualified": true,
        "laneTaskDetails" : this.leadDetails
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getLeadDetails(this.boardId);
    });
  }
  onSideTabToggle = (type) => {
    let component = null;
    if(type == "users") {
      component = LaneTaskUsersTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: '30%',
        height: '100%',
        position : {bottom: '0px', right:'0px'} ,
        data: {
          laneTask: this.leadDetails,
          preQualified: true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.userName){
          this.leadDetails.assigneeDTO.userName = result.userName;
          this.pagesService.updateLeadDetailsData(result);
        }
      });
    } else if (type == "documents") {
      component = LaneTaskDocumentsTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: '35%',
        height: '50%',
        data: {
          laneTask: this.leadDetails,
          preQualified: true
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    } else {
      component = CommentsTabComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: '30%',
        height: '100%',
        position : {bottom: '0px', right:'0px'} ,
        data: {
          laneTask: this.leadDetails,
          preQualified: true
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }
  openWhatsApp(){
    let component = null
     component = WhatsappTemplateComponent;
      const dialogRef = this.dialog.open(component, {
        disableClose: false,
        width: '30%',
        height: '100%',
        position : {bottom: '0px', right:'0px'} ,
        data: {
          laneTask: this.leadDetails,
          preQualified: true
        }
      });
  }
  openHistoryTab(){
    let component = null
    component = HistoryTabComponent;
     const dialogRef = this.dialog.open(component, {
       disableClose: false,
       width: '80%',
        height: '70%',
       data: {
         laneTask: this.leadDetails,
         preQualified: true
       }
    });
  }
  navigateToNewLead(){
    this.router.navigate([`/cardDetails/`,this.leadDetails.laneTaskId])
  }

}
