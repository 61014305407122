import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-report-download-invoice',
  templateUrl: './report-download-invoice.component.html',
  styleUrls: ['./report-download-invoice.component.scss']
})
export class ReportDownloadInvoiceComponent implements OnInit {
  reportName: string;
  constructor(public dialogRef: MatDialogRef<ReportDownloadInvoiceComponent>,) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close(true);
  }

  saveInvoices() {
    this.dialogRef.close(this.reportName);
  }

}
