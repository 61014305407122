import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { EarlyPaymentTrendComponent } from './dashboard/early-payment-trend/early-payment-trend.component';
import { EligibleInvoiceStatsComponent } from './dashboard/eligible-invoice-stats/eligible-invoice-stats.component';
import { EpProcessingAmountStatsComponent } from './dashboard/ep-processing-amount-stats/ep-processing-amount-stats.component';
import { EpConversionFunnelComponent } from './dashboard/ep-conversion-funnel/ep-conversion-funnel.component';
import { EpSourceRequestStatsComponent } from './dashboard/ep-source-request-stats/ep-source-request-stats.component';
import { EpProcessingTimeStatsComponent } from './dashboard/ep-processing-time-stats/ep-processing-time-stats.component';
import { ProgressSpinnerComponent } from './dashboard/progress-spinner/progress-spinner.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { CreateOkrComponent } from './dashboard/create-okr/create-okr.component';
import {MatDialogModule} from '@angular/material/dialog';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [DashboardComponent, EarlyPaymentTrendComponent, EligibleInvoiceStatsComponent, EpProcessingAmountStatsComponent, EpConversionFunnelComponent, EpSourceRequestStatsComponent, EpProcessingTimeStatsComponent, ProgressSpinnerComponent, CreateOkrComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
     ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    InfiniteScrollModule,
    MatCardModule,
    MatRadioModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,
    SharedModulesModule,
    MatProgressBarModule,
    MatDialogModule
  ],
  entryComponents:[
    CreateOkrComponent
  ]
})
export class DashboardModule { }
