import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KanbanService } from '../../../kanban.service';
import { saveAs } from "file-saver";
import { ToastrService } from 'ngx-toastr';
import { CommonSupportService } from '../card-details/los-one-drive/one-drive-services/common-support.service';

@Component({
  selector: 'app-file-dailog',
  templateUrl: './file-dailog.component.html',
  styleUrls: ['./file-dailog.component.scss']
})
export class FileDailogComponent implements OnInit{
  fileData:any[];
  hasAccess:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<FileDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,public kService: KanbanService,public toasterService: ToastrService, private commonSupport : CommonSupportService
  ) {}
  ngOnInit(): void {
    this.fetchData();
  }
  fetchData(){    
    this.kService.showAllFiles(this.data.dataType,this.data.laneTaskId,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.fileData = resp.result
        // this.kService.hasAccess.subscribe(access=>{
        //   this.hasAccess = access
        // })
        if(this.data.editable){
          this.hasAccess = true;
        }
      }
    })
  }
  removeFile(id: number) {
    this.kService.deleteFile(this.data.dataType,id,this.data.laneTaskId,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.toasterService.success("File Deleted Sucessfully!");
      },
      complete:()=>this.fetchData()
    })
  }

  changeFile(id: number) {
    // Trigger file input click to change the file
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile,id);
    };
    fileInput.click();
  }
  reUpload(file,id){
    this.kService.reUploadFile(this.data.laneTaskId,this.data.dataType,file,id,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.toasterService.success("File Uploded SuccessFull!");
      },
      complete:()=>this.fetchData()
    })
  }
  downloadFile(id){
    this.kService.downloadSingleUploadDocument(id).subscribe({
      next:(resp:any)=>{
        //changed from doenload to open in new tab
        // this.downloadSheetCommonMethod(resp); 
        this.viewFileInNewTab(resp);
      }
    })
  }
  resultDownload;
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }

  viewFileInNewTab(resp){
    this.commonSupport.openDocumentNewTab(resp);
  }
}
