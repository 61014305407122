import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CommissionInvoicesRoutingModule } from './commission-invoices-routing.module';
import { CommissionInvoicesComponent } from './commission-invoices/commission-invoices.component';
import { MatPaginatorModule, MatTableModule } from '@angular/material';
import { CommissionInvoicesDetailsComponent } from './commission-invoices/commission-invoices-details/commission-invoices-details.component';
// import {FlexLayoutModule} from "@angular/flex-layout";
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';

@NgModule({
  declarations: [CommissionInvoicesComponent, CommissionInvoicesDetailsComponent, DocumentDialogComponent],
  imports: [
    CommonModule,FormsModule,HttpClientModule,MatTableModule,MatPaginatorModule,SharedModulesModule,
    CommissionInvoicesRoutingModule
  ],
  entryComponents: [DocumentDialogComponent],
})
export class CommissionInvoicesModule { }
