import { Component, OnInit, Inject } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-send-update-whatsapp',
  templateUrl: './send-update-whatsapp.component.html',
  styleUrls: ['./send-update-whatsapp.component.scss']
})
export class SendUpdateWhatsappComponent implements OnInit {
  whatsappNumber: number;
  constructor( public settingService: SettingService, public dialogRef: MatDialogRef<SendUpdateWhatsappComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {

  }

  closeDialog() {
    this.dialogRef.close(true);

  }
  sendUpdate() {
    let obj = {
      phoneNumber: this.whatsappNumber,
      type: "PHONE",
      whatsapp: true
    }
    this.settingService.addSubUserDetails(obj).subscribe(res => {
      if(res['result']== true){
        this.dialogRef.close();
      }
    })
  }
}
