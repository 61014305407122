<div style="background-color: #F7F7F7;height: 30px;">

</div>
<div class="container">
  <div class="main">
    <section class="ctaStripWrap">
      <div class="lft">
        <button class="filter back" mat-button (click)="dialogRef.close(false)">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="cntr">
        <span style="font-weight: 500;">Upload Sheet: </span>
        <span>{{fileName}}</span>
      </div>
      <div class="rht">
        <button class="user" [style.opacity]="isBtnDisable? 0.5 : 1" mat-button (click)="uploadFile()">
          <span>Upload & Process</span>
        </button>
      </div>
    </section>
    <div class="mat-table">
      <table mat-table [dataSource]="dataSource" class="mappingTable" style="width: 100%;">
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
          <th mat-header-cell *matHeaderCellDef style="width: 200px;"> {{disCol.key}} </th>
          <td mat-cell class="new-col" *matCellDef="let element;  let i = index">
            <div *ngIf="i == 0">
              <mat-form-field appearance="outline" (click)="openPanel(headerData[colIndex]['isSelected'], colIndex)">
                <mat-select [ngClass]="headerData[colIndex]['index'] == 'ignore' ? 'redLabel' : ''" style="font-weight: 500;" disableOptionCentering (selectionChange)="changeHeader($event.value, colIndex)"
                [(ngModel)]="headerData[colIndex]['index']" placeholder="Select Head to Map">
                  <mat-option class="searchHeader">
                    <div>
                      <input [(ngModel)]="searchText[colIndex]" (keydown)="$event.stopPropagation()" (click)="$event.stopPropagation()" matInput placeholder="Search Head" autocomplete="off">
                      <mat-icon *ngIf="searchText[colIndex] == ''" class="newsearch">search</mat-icon>
                      <mat-icon (click)="resetSearchBar(colIndex)" *ngIf="searchText[colIndex] && searchText[colIndex].length > 0" (click)="$event.stopPropagation()" class="newsearch">close</mat-icon>
                    </div>
                  </mat-option>
                  <mat-option [ngClass]="header['field'] == 'ignore' ? 'redPanel' : ''" [value]="(header['index'] || header['index'] == 0) ? header['index'] : header['field']"
                  *ngFor="let header of headerDisplay[colIndex] | filter:searchText[colIndex]"
                  [disabled]="header['isDisable']">
                    <span *ngIf="header['field'] == 'ignore'"><i class="ri-close-line"></i></span>
                    {{header['displayName'] ? header['displayName'] : header['key']}}
                    <span style="color: red;" *ngIf="header['optional'] == false">*</span>
                  </mat-option>
                  <mat-option *ngIf="(headerDisplay[colIndex] | filter:searchText[colIndex]).length == 0" disabled>
                    No matches found
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="i == 1">
              <mat-form-field appearance="outline">
                <mat-select style="font-weight: 500;" disableOptionCentering (selectionChange)="changeHeaderType($event.value, colIndex, disCol.key)"
                [(ngModel)]="headerData[colIndex]['type']" placeholder="Select Type">
                  <mat-option [value]="type['val']" *ngFor="let type of headerType[colIndex]">
                    {{type['name']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="i != 0 && i != 1">
              {{ disCol.cell(element) }}
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
      </table>
    </div>
    <div class="dataPreview">
      <span>Data preview only for representation purposes.</span>
    </div>
    <div fxLayout="row" fxLayoutGap="1%" class="progressArea">
      <div *ngIf="!isBtnDisable"><mat-icon>check_circle_outline</mat-icon></div>
      <div *ngIf="isBtnDisable"><mat-icon>subdirectory_arrow_right</mat-icon></div>
      <div>
        <div *ngIf="successRateCount != null" [ngClass]="successRateCount == '100' ? 'sucess' : 'progress'">{{successRateCount}}% Mapped</div>
        <div class="mappedCol" *ngIf="progressRate != null && successRate != null ">{{progressRate}}/{{successRate}} mandatory columns mapped. <span *ngIf="successRateCount != '100'">match all mandatory columns to proceed.</span></div>
        <div style="margin-top: 5%;"><button class="user" mat-button (click)="uploadFile()" *ngIf="!isBtnDisable">
          <span>Upload & Process</span>
        </button></div>
      </div>
    </div>
  </div>
</div>