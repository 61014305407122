import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CustomSnackbarComponent } from 'src/app/component/early-payments/early-payment-listing/custom-snackbar/custom-snackbar.component';
import { ReportDownloadInvoiceComponent } from 'src/app/component/early-payments/early-payment-listing/report-download-invoice/report-download-invoice.component';
import { RequestEarlyPaymentComponent } from 'src/app/component/early-payments/early-payment-listing/request-early-payment/request-early-payment.component';
import { EarlyPaymentListingComponent } from './early-payment-listing/early-payment-listing.component';
import { EarlyPaymentsListingDocViewComponent } from './early-payment-listing/early-payments-listing-doc-view/early-payments-listing-doc-view.component';

const routes: Routes = [
  {
    path: '',
    component: EarlyPaymentListingComponent,
    children: [
    ]
  },
  {
    path: 'listing/invoice/:id',
    component: EarlyPaymentsListingDocViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'earlyPayments/:id/:supplierId',
    component: EarlyPaymentListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'earlyPayments/:id',
    component: EarlyPaymentListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'earlyPayments',
    component: EarlyPaymentListingComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
})
export class EarlyPaymentsRoutingModule { }
