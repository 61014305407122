import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import * as moment from "moment";
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-fill-insurance',
  templateUrl: './fill-insurance.component.html',
  styleUrls: ['./fill-insurance.component.scss']
})
export class FillInsuranceComponent implements OnInit {
  obj: any = {}
  statuaList = ['Applied', 'Refused', 'Approved', 'Partially Agreed'];
  isOtherInsurance: boolean = false;
  othersInsuranceList = [];
  maxApprovedDate: any = new Date();
  public onSave;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FillInsuranceComponent>,
    public kService: KanbanService,
    public toasterService: ToastrService,) { }

  ngOnInit() {
    if (!this.data.isOtherInsurance) {
      this.obj = this.data.obj;
      if (!this.obj.finalInsuranceAppliedAmount) {
        this.obj.finalInsuranceAppliedAmount = this.data.limitRequired
      }
      if (!this.obj.insuranceAppliedAmount) {
        this.obj.insuranceAppliedAmount = this.data.limitRequired
      }
      this.obj.insuranceApprovedDate = this.obj.insuranceApprovedDate ? new Date(this.data.obj.insuranceApprovedDate) : null;
      this.obj.insuranceAppliedDate = this.obj.insuranceAppliedDate ? new Date(this.data.obj.insuranceAppliedDate) : null;
      // final section
      this.obj.finalInsuranceApprovedDate = this.obj.finalInsuranceApprovedDate ? new Date(this.data.obj.finalInsuranceApprovedDate) : null;
      this.obj.finalInsuranceAppliedDate = this.obj.finalInsuranceAppliedDate ? new Date(this.data.obj.finalInsuranceAppliedDate) : null;
    } else {
      this.isOtherInsurance = this.data.isOtherInsurance;
      this.getOtherInsurance();
    }
  }

  // Function to handle input changes
  omit_special_char(event, approvedAmount, appliedAmount) {
    this.checkApproveAmount(approvedAmount, appliedAmount)
    let k = event.charCode || event.keyCode;
    // Allow digits (0-9) and backspace (8)
    if ((k >= 48 && k <= 57) || k === 8) {
      return true;
    } else {
      return false;
    }
  }

  statusBaseValidationForFinal(status, obj) {
    switch (status) {
      case 'Applied':
        {
          return true;
        }
      case 'Approved':
        {
          if (obj['finalInsuranceApprovedAmount'] && obj['finalInsuranceApprovedAmount'].toString().length > 0
            && obj['finalInsuranceApprovedDate']) {
            return true;
          }
          else {
            return false;
          }
        }
      case 'Partially Agreed':
        {
          return true;
        }
      case 'Refused':
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }


  statusBaseValidationForPrelims(status, obj) {
    switch (status) {
      case 'Applied':
        {
          return true;
        }
      case 'Approved':
        {
          if (obj['insuranceApprovedAmount'] && obj['insuranceApprovedAmount'].toString().length > 0
            && obj['insuranceApprovedDate']) {
            return true;
          }
          else {
            return false;
          }
        }
      case 'Partially Agreed':
        {
          return true;
        }
      case 'Refused':
        {
          return true;
        }
      default:
        {
          return false;
        }
    }
  }

  updateFinalInsuraceData(obj) {
    !obj.finalInsuranceStatus ? obj.finalInsuranceStatus = obj.insuranceStatus : null;
    !obj.finalInsuranceRating ? obj.finalInsuranceRating = obj.insuranceRating : null;
    !obj.finalInsuranceAppliedAmount ? obj.finalInsuranceAppliedAmount = obj.insuranceAppliedAmount : null;
    !obj.finalInsuranceAppliedDate ? obj.finalInsuranceAppliedDate = obj.insuranceAppliedDate : null;
    !obj.finalChangeInNameOfBuyer ? obj.finalChangeInNameOfBuyer = obj.changeInNameOfBuyer : null;
    !obj.finalChangeInAddressOfBuyer ? obj.finalChangeInAddressOfBuyer = obj.changeInAddressOfBuyer : null;
    !obj.finalInsuranceComment ? obj.finalInsuranceComment = obj.insuranceComment : null;
  }

  checkAppliedAndApprovedDates(appliedDate, approvedDate, section = "") {
    if (appliedDate && approvedDate && approvedDate < appliedDate) {
      this.toasterService.error(`${section}insurance approved date must be greater than or equal to ${section}insurance applied date`);
      return false;
    }
    return true;
  }

  savePrelimsData(obj: any) {
    if (!this.checkApproveAmount(obj.insuranceApprovedAmount, obj.insuranceAppliedAmount)) {
      return;
    }

    if (!this.checkAppliedAndApprovedDates(obj.insuranceAppliedDate, obj.insuranceApprovedDate)) {
      return;
    }

    this.obj['insuranceProvider'] = this.data['obj'].insuranceProvider;
    if (this.checkPrelimsValidation()) {
      // this.dialogRef.close(this.obj);
      this.updateFinalInsuraceData(obj);
      this.onSave(this.obj);
    }
    else {
      this.toasterService.error("Fill mandatory informations");
    }
  }

  checkPrelimsValidation() {
    if (this.obj['insuranceStatus'] && this.obj['insuranceAppliedAmount'] && this.obj['insuranceAppliedDate'] &&
      this.statusBaseValidationForPrelims(this.obj['insuranceStatus'], this.obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  isVisibleFinalInsuranceSection() {
    if (this.obj['insuranceStatus'] && this.obj['insuranceAppliedAmount'] && this.obj['insuranceAppliedDate'] &&
      this.statusBaseValidationForPrelims(this.obj['insuranceStatus'], this.obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  saveFinalData(obj) {
    if (!this.checkApproveAmount(obj.finalInsuranceApprovedAmount, obj.finalInsuranceAppliedAmount)) {
      return;
    }

    if (!this.checkAppliedAndApprovedDates(obj.finalInsuranceAppliedDate, obj.finalInsuranceApprovedDate, "Final ")) {
      return;
    }

    this.obj['insuranceProvider'] = this.data['obj'].insuranceProvider;
    if (this.checkFinalValidation()) {
      this.obj['finalDataPresent'] = true;
      this.onSave(this.obj);
    }
    else {
      this.toasterService.error("Fill mandatory informations");
    }
  }

  checkFinalValidation() {
    if (this.obj['finalInsuranceStatus'] && this.obj['finalInsuranceAppliedAmount'] && this.obj['finalInsuranceAppliedDate'] &&
      this.statusBaseValidationForFinal(this.obj['finalInsuranceStatus'], this.obj)) {
      return true;
    }
    else {
      return false;
    }
  }

  closeInsureancePopup() {
    this.initInsurancePopup();
    this.dialogRef.close();
  }

  addInsurance() {
    let obj = {
      name: "",
      id: null
    }
    this.othersInsuranceList.push(obj)
  }

  saveotherInsurance() {
    let obj = {
      insList: this.othersInsuranceList
    }
    this.dialogRef.close(obj);
  }

  saveInsurer(element) {
    let obj = {
      insurerName: element.insurerName
    }
    this.kService.saveOtherInsurance(obj).subscribe(res => {
      if (res['status'] == 200) {
        console.log(res['result']);
        this.getOtherInsurance();
      }
    })
  }

  getOtherInsurance() {
    this.kService.getOtherInsurance().subscribe(res => {
      if (res['status'] == 200) {
        console.log(res['result']);
        this.othersInsuranceList = res['result'];
      }
    })
  }
  removeInsurance(element, index) {
    if (element.id) {
      this.kService.deleteInsurance(element.id).subscribe(res => {
        this.othersInsuranceList.splice(index, 1);
      })
    } else {
      this.othersInsuranceList.splice(index, 1);
    }
  }

  changePreliminaryInsuranceStatus(event: any) {
    if (event.value === 'Refused') {
      this.obj['insuranceApprovedAmount'] = 0;
      this.obj['insuranceApprovedDate'] = null;
    }
    if (event.value === 'Approved') {
      this.obj['insuranceApprovedAmount'] = this.obj['insuranceAppliedAmount'];
    }
    if (event.value === 'Partially Agreed') {
      this.obj['insuranceApprovedAmount'] = null;
    }
    if (event.value === 'Applied') {
      this.obj['insuranceApprovedDate'] = null;
      this.obj['insuranceApprovedAmount'] = null;
    }
  }

  changeFinalInsuranceStatus(event: any) {
    if (event.value === 'Refused') {
      this.obj['finalInsuranceApprovedAmount'] = 0;
      this.obj['finalInsuranceApprovedDate'] = null;
    }
    if (event.value === 'Approved') {
      this.obj['finalInsuranceApprovedAmount'] = this.obj['finalInsuranceAppliedAmount'];
    }
    if (event.value === 'Partially Agreed') {
      this.obj['finalInsuranceApprovedAmount'] = null;
    }
    if (event.value === 'Applied') {
      this.obj['finalInsuranceApprovedDate'] = null;
      this.obj['finalInsuranceApprovedAmount'] = null;
    }
  }
  checkApproveAmount(approvedAmount, appliedAmount) {
    if (Number(approvedAmount) > Number(appliedAmount)) {
      this.toasterService.error('Approved amount must be less than or equal to Applied Amount');
      return false;
    }
    return true;
  }
  initInsurancePopup() {
    this.obj['insuranceProvider'] = null
    this.obj['insuranceApprovedAmount'] = null
    this.obj['insuranceStatus'] = null
    this.obj['insuranceRating'] = null
    this.obj['insuranceComment'] = null
    this.obj['insuranceAppliedAmount'] = null
    this.obj['changeInNameOfBuyer'] = null
    this.obj['changeInNameOfBuyer'] = null
  }
}
