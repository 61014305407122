<div class="not-found-page">
  <header class="header">
    <img src="/assets/images/loginlogo.svg" />
  </header>

  <main class="content">
    <h1 class="error-code">{{error.statusCode}}</h1>
    <p class="message">{{error.warning}} <span *ngIf="error.warning"> ,</span> {{error.message}}</p>
    <button class="home-button" (click)="goToHome()">Go to Home</button>
  </main>
</div>