<div class="dialog-header">
  <h3>View Comments</h3>
  <button  mat-button mat-dialog-close><i class="ri-close-fill"></i></button>
</div>

<div class="table-data">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="leadId">
      <th mat-header-cell *matHeaderCellDef> Lead ID </th>
      <td mat-cell class="first-row" *matCellDef="let element" (click)="losBoard(element)"> {{element.displaySequence}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="leadName">
      <th mat-header-cell *matHeaderCellDef> Lead Name </th>
      <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="leadSource">
      <th mat-header-cell *matHeaderCellDef> Lead Source </th>
      <td mat-cell *matCellDef="let element"> {{element.leadSource}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="buyerData">
      <th mat-header-cell *matHeaderCellDef> Buyer data </th>
      <td mat-cell *matCellDef="let element"> #{{element.noOfBuyers}} - Limit - {{element.totalLimitApplied}} </td>
    </ng-container>
  
    <tr mat-header-row class="heading" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="pagination" fxLayout="row" fxLayoutAlign="end center" style="width: 100%;">
  <div class="d-flex-rw" style="width: 100%;">
    <div class="pagination-wrapper" style="width: 100%;">
      <mat-paginator [hidden]="!dataSource.length" [length]="totalRecords" [pageIndex]="pageNumber"
        class="custom-pagintor" [pageSize]="pageSize" (page)="getMoreData($event)" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>