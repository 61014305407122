<div class="canclDialg">
  <div class="contnt-wrap">
    <h5>Cancel EP</h5>
    <label>Borrower will be intimated about the cancellation via an email.</label>
    <div class="form-g">
      <label class="txtlbl">Reason for Cancellation*</label>
    <textarea placeholder="Briefly enter the reason for cancellation." [(ngModel)]='delayedReason'></textarea>
    </div>
  </div>
  <div class="actionbtn">
    <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
    <button mat-raised-button class="savebtn" (click)="cancelEPRequest()" [disabled]="!delayedReason" >Cancel EP</button>
  </div>
</div>