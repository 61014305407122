import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  displayedColumns: string[] = ['userName','createdAt','remark', 'status'];
  dataSource: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private pageService: PagesService,public dialogRef: MatDialogRef<TransactionHistoryComponent>) { }

  ngOnInit() {
    console.log(this.data);
    this.getTrancsactionHistory();
  }
  getTrancsactionHistory() {
    this.pageService.getTransactionHistory(this.data.utr).subscribe(data => {
      console.log(data['result']);
      this.dataSource = data['result'];
    });
  }
  getStatus(val){
    if(val == 'APPROVED'){
      return 'Settled'
    }
    if(val == 'PENDING'){
      return 'Pending'
    }
    if(val == 'REJECTED'){
      return 'Unsettled'
    }
  }

  getClass(val){
    if(val == 'APPROVED'){
      return 'approve'
    }
    if(val == 'PENDING'){
      return 'pending'
    }
    if(val == 'REJECTED'){
      return 'reject'
    }
  }
}
