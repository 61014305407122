<section aria-label="tags" class="dialog-top">
  <h4 class="heading-assign">Assign To Team</h4>
  <div class="inner-div">
    <mat-form-field class="tag-autocomplete" appearance="outline">
          <mat-label>Change Assignee</mat-label>
          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" style="text-transform: capitalize;">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUser($event)">
            <mat-option *ngFor="let option of filteredUserList | async" [value]="option" style="text-transform: capitalize;">
              {{option}}
            </mat-option>
          </mat-autocomplete>
      <button mat-icon-button matSuffix style="width: 34px; height: 34px;"
        >
        <mat-icon style="width: 34px;">expand_more</mat-icon>
      </button>
    </mat-form-field>
    <div class="cta-box">
      <button class="btncancel" (click)="closeAssigneePopup()"type="button">Cancel</button>
      <button class="btnSave"(click)="chaneAssigne()"type="button">Submit</button>
    </div>
  </div>
</section>
