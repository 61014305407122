<div class="eximSettingDialog">
  <div class="headItem">
    <span class="text">
      Settings
    </span>
    <span class="close">
      <i class="ri-close-line"></i>
    </span>
  </div>
  <mat-dialog-content class="scrlContent">
    <h5>Lanes</h5>
    <ul cdkDropList (cdkDropListDropped)="drop($event)">
      <li cdkDrag *ngFor="let lane of boardLane" >
        <i class="ri-order-play-fill"></i>
        <span>{{lane.name}}</span>
      </li>
    </ul>
    <button>
      <i class="ri-add-line"></i>
      Add Lane
    </button>
  </mat-dialog-content>
</div>