<div class="content">

  <div class="d-top">
    <div class="close-icon">
      <mat-icon (click)="closeDialog()" class="closeIcn mat-icon notranslate material-icons mat-icon-no-color"
        role="img" aria-hidden="true">
        close
      </mat-icon>
    </div>
    <h5>Before you move</h5>
  </div>
  <div class="slctOptin">
  </div>

  <div>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Next action item<span style="color: red;">*</span></mat-label>
      <input type="text" aria-label="Number" matInput [formControl]="myControl" [(ngModel)]="actionItem">
    </mat-form-field>
  </div>
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Select Pending on<span style="color: red;">*</span></mat-label>
    <input type="text" aria-label="Number" matInput [formControl]="myControl" [(ngModel)]="selectedAssigneId"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


  <div>
    <mat-form-field class="example-full-width datepicker-fixes" appearance="outline">
      <mat-label>Date<span style="color: red;">*</span></mat-label>
      <input mat-input [matDatepicker]="picker1" (focus)="picker1.open()" placeholder="Date" aria-label="Number"
        matInput [formControl]="myControl" [(ngModel)]="selectedDate" readonly>
      <mat-datepicker-toggle matSuffix [for]="picker1">
      </mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>


  <div class="footer-btn">
    <button mat-raised-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
    <button mat-raised-button class="savebtn" (click)="saveBuyerDataRow()">Move</button>
  </div>


</div>