import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PagesService } from '../../pages.service';
//import { MatPaginator } from '@angular/material/paginator';
//import { MatTableDataSource,MatPaginator } from '@angular/material/table';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Range, NgxDrpOptions, PresetItem, RangeStoreService } from 'ngx-mat-daterange-picker';
import * as moment from "moment";
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  purchaseOrderList: any =[];
  supplierInvoiceList = [];
  pageSize: number = 10;
  totalRecords: number;
  pageNumber: number = 0;
  selectedStatus: string = "invoice";
  InvoiceDueDate: boolean = true;
  isCustomer: boolean = false;
  customDateOption = null;
  customDueDateOption = null;
  getRadioevent: string
  obj = {}
  setInvoiceRecords = [];
  timeSlot: number;
  issuetimeSlot: number;
  initialState: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  startIssueDate: any;
  endIssueDate: any;
  startDate: any;
  endDate: any;
  selectedIssueDate: any;
  selectedDueDate: any;
  requestBody = {}
  seachText: any;
  showSearchIcon: boolean = true;
  showCloseIcon: boolean = false;
  displayedColumns = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate'];
  defaultInvoicesColumn = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate'];
  defaultCustomerInvoicesColumn = ['check', 'documentType', 'invoiceNumber', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'invoiceDate', 'dueDate', 'epEligible'];
  PurchaseColumns = ['check', 'documentType', 'searchKey', 'status', 'supplier_account_name', 'customer_account_name', 'amount', 'purchaseDate'];
  transactionType = [{ "id": 1, "type": "Sales" }, { "id": 2, "type": "Purchases" }]
  documentType = [{ "id": 1, "type": "Purchase Order" }, { "id": 2, "type": "Goods Receipt" }, { "id": 3, "type": "Invoice" }, { "id": 4, "type": "Credit Note" }]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dateRangePicker') dateRangePicker;
  dataSource = new MatTableDataSource(this.purchaseOrderList);

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor(private router: Router, public pagesService: PagesService, private authService: AuthService) {
  }

  ngOnInit() {

    if (this.authService.getIsCusotmer()) {
      this.isCustomer = true;
      this.displayedColumns = this.defaultCustomerInvoicesColumn;
    } else {
      this.isCustomer = false;
      this.displayedColumns = this.defaultInvoicesColumn;
    }
    this.selectedStatus = "invoice";
    // this.getPurchaseOrderList();

  }

  ngAfterViewInit() {
    console.log("pagin", this.paginator);
    this.getcustomerInvoiceList();
  }

  resetSearchText() {
    this.showSearchIcon = true;
    this.showCloseIcon = false;
    this.seachText = null;
    this.applyFilters(false)

  }
  dueDatesUpdated(event) {
    if (event.startDate != null && event.endDate != null) {
      this.startDateMillisec = event.startDate._d
      this.startDate = moment(this.startDateMillisec).format('YYYY-MM-DD');
      this.EndDateMillisec = event.endDate._d
      this.endDate = moment(this.EndDateMillisec).format('YYYY-MM-DD');
    }
  }
  markInvoiceEPStatus(element) {
    let request = {
      'invoiceIds': [element.id],
      'status': !element.isEligibleForEP
    }
    this.pagesService.updateInvoiceEPEligibility(request).subscribe((resp) => {
      this.pagesService.offSpinner();
      element.isEligibleForEP = !element.isEligibleForEP;
    })
  }
  isueDatesUpdated(event) {
    if (event.startDate != null && event.endDate != null) {
      this.startDateMillisec = event.startDate._d
      this.startIssueDate = moment(this.startDateMillisec).format('YYYY-MM-DD');
      this.EndDateMillisec = event.endDate._d
      this.endIssueDate = moment(this.EndDateMillisec).format('YYYY-MM-DD');
    }
  }

  clearDueRange() {
    this.selectedDueDate = null
  }
  clearIssueRange() {
    this.selectedIssueDate = null
  }


  getPaginatorData() {
    this.applyFilters(true);
  }

  getPOlist() {
    this.obj = {
      "size": this.paginator.pageSize,
      "from": this.paginator.pageIndex * this.paginator.pageSize,
      "where": {},
      "filter": {},
      "accessAs": this.isCustomer ? 'customerGSTN' : 'supplierGSTN'
    }
    this.pagesService.getPurchaseOrders(this.obj).subscribe(list => {
      this.pagesService.offSpinner();
      this.dataSource.data = list['result'];
      this.supplierInvoiceList = list['result'];
      this.totalRecords = list['total'];
      this.paginator.length = this.totalRecords
    })
  }
  getcustomerInvoiceList() {
    this.obj = {
      "size": this.paginator.pageSize,
      "from": this.paginator.pageIndex * this.paginator.pageSize,
      "where": {},
      "filter": {},
      'isCustomer': this.isCustomer
    }
    this.pagesService.getSuppliersInvoices(this.obj).subscribe((list) => {
      this.pagesService.offSpinner();
      this.dataSource.data = list['result'];
      this.purchaseOrderList = list['result'];
      this.totalRecords = list['total'];
      this.paginator.length = this.totalRecords
    })
  }

  ChangeTransactionType(event) {
    if (this.paginator != null) {
      this.paginator.firstPage()
      this.paginator.pageSize = 10
    }
    this.getRadioevent = event;
    if (event == "purchase") {
      this.seachText = null;
      this.selectedIssueDate = null;
      this.issuetimeSlot = null;
      this.InvoiceDueDate = false;
      this.displayedColumns = this.PurchaseColumns
      this.getPOlist();
    }
    else if (event == "invoice") {
      this.seachText = null;
      this.InvoiceDueDate = true;
      if (this.isCustomer) {
        this.displayedColumns = this.defaultCustomerInvoicesColumn;
      } else {
        this.displayedColumns = this.defaultInvoicesColumn;
      }
      this.getcustomerInvoiceList()
    }
  }


  getCustomSelectedDate(item) {
    this.timeSlot = item;
    this.selectedDueDate = null;
  }

  getCustomSelectedissueDate(item) {
    this.issuetimeSlot = item;
    this.selectedIssueDate = null;
  }

  applyFilters(reset) {
    if (this.paginator != null && !reset) {
      this.paginator.firstPage();
    }
    if (this.seachText != null) {
      this.showCloseIcon = true;
      this.showSearchIcon = false;
    }
    else {
      this.showCloseIcon = false;
    }
    if (this.selectedStatus == "invoice") {
      this.requestBody = {
        "size": this.pageSize,
        "from": 0,
        "where": {
        }
      }
      if (this.issuetimeSlot != null && this.customDateOption != -1 && this.customDateOption != null) {
        this.requestBody['where']['invoiceDate'] = {
          "type": "range",
          "custom": this.issuetimeSlot
        }
      }
      if (this.timeSlot != null && this.customDueDateOption != -1 && this.customDueDateOption != null) {
        this.requestBody['where']['dueDate'] = {
          "type": "range",
          "custom": this.timeSlot
        }
      }
      if (this.startIssueDate != null && this.endIssueDate != null && this.customDateOption == -1) {
        this.requestBody['where']['invoiceDate'] = {
          "type": "range",
          "start_value": this.startIssueDate,
          "end_value": this.endIssueDate
        }
      }
      if (this.startDate != null && this.endDate != null && this.customDueDateOption == -1) {
        this.requestBody['where']['dueDate'] = {
          "type": "range",
          "start_value": this.startDate,
          "end_value": this.endDate
        }
      }
      if (this.seachText != null && this.seachText.trim() != '') {
        this.requestBody['where']['invoiceNumber'] = {
          "type": "search",
          "value": this.seachText
        }
      }
      this.requestBody['from'] = this.paginator.pageIndex * this.paginator.pageSize
      this.requestBody['size'] = this.paginator.pageSize
      this.requestBody['isCustomer'] = this.isCustomer;
      this.pagesService.getSuppliersInvoices(this.requestBody).subscribe(list => {
        this.pagesService.offSpinner();
        this.dataSource.data = list['result'];
        this.purchaseOrderList = list['result']
        this.totalRecords = list['total'];
        this.paginator.length = this.totalRecords

      })
    }
    if (this.selectedStatus == "purchase") {
      this.requestBody = {
        "size": -1,
        "from": 0,
        "where": {
        }
      }

      if (this.issuetimeSlot != null) {
        this.requestBody['where']['purchaseDate'] = {
          "type": "range",
          "custom": this.issuetimeSlot
        }
      }
      if (this.startIssueDate != null && this.endIssueDate != null) {
        this.requestBody['where']['purchaseDate'] = {
          "type": "range",
          "start_value": this.startIssueDate,
          "end_value": this.endIssueDate
        }
      }
      if (this.seachText != null && this.seachText.trim() != '') {
        this.requestBody['where']['searchKey'] = {
          "type": "search",
          "value": this.seachText
        }
      }
      this.requestBody['from'] = this.paginator.pageIndex * this.paginator.pageSize
      this.requestBody['size'] = this.paginator.pageSize
      this.requestBody['accessAs'] = this.isCustomer == true ? 'customerGSTN' : 'supplierGSTN';
      this.pagesService.getPurchaseOrders(this.requestBody).subscribe(list => {
        this.pagesService.offSpinner();
        this.dataSource.data = list['result'];
        this.supplierInvoiceList = list['result'];
        this.totalRecords = list['total'];
        this.paginator.length = this.totalRecords
      })

    }

  }
  resetFilters(event, el) {
    this.selectedDueDate = null;
    this.selectedIssueDate = null;
    event.preventDefault();
    if (this.customDateOption && this.customDateOption === el.value ||
      this.customDueDateOption && this.customDueDateOption === el.value) {
      el.checked = false;
      this.customDateOption = null;
      this.customDueDateOption = null;
    } else {
      this.customDueDateOption = el.value
      this.customDateOption = el.value
      el.checked = true;
    }
    this.paginator.firstPage();
    this.paginator.length = this.totalRecords

    if (this.selectedStatus == 'invoice') {
      if (this.seachText != null && this.seachText.trim() != '') {
        this.requestBody['where']['invoiceNumber'] = {
          "type": "search",
          "value": this.seachText
        }
        this.requestBody['isCustomer'] = this.isCustomer;
        this.pagesService.getSuppliersInvoices(this.requestBody).subscribe(list => {
          this.pagesService.offSpinner();
          this.dataSource.data = list['result'];
          this.purchaseOrderList = list['result']
          this.totalRecords = list['total'];
          this.paginator.length = this.totalRecords
        })
      }
      else if (this.seachText == null) {
        this.getcustomerInvoiceList();
      }
    }
    else if (this.selectedStatus == 'purchase') {
      if (this.seachText != null && this.seachText.trim() != '') {
        this.requestBody['where']['searchKey'] = {
          "type": "search",
          "value": this.seachText
        }
        this.requestBody['accessAs'] = this.isCustomer == true ? 'customerGSTN' : 'supplierGSTN';
        this.pagesService.getPurchaseOrders(this.requestBody).subscribe(list => {
          this.pagesService.offSpinner();
          this.dataSource.data = list['result'];
          this.supplierInvoiceList = list['result'];
          this.totalRecords = list['total'];
          this.paginator.length = this.totalRecords
        })
      }
      else if(this.seachText ==null){
        this.getPOlist();
      }

    }

  }

  getRecord(row, event) {
    if (event == "invoice") {
      this.pagesService.setinvoiceRecords(row);
      this.router.navigateByUrl('/documentView/invoice/' + row.id)
    }
    else if (event == "purchase") {
      this.pagesService.setPurchaseRecords(row);
      this.router.navigateByUrl('/documentView/supplier-po/' + row.id)
    }

  }

  addDays = function (days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  isEligible(element) {
    let dueDate = new Date(element.dueDate);
    let Today = this.addDays(6)
    return dueDate.getTime() >= Today.getTime()
  }

}
