<div class="eximFilterDialog">
  <div class="headItem">
    <span class="text">
      <span class="material-icons"> filter_list </span>
      Filters
    </span>
    <span class="close" (click)="closeDialog()">
      <i class="ri-close-line"></i>
    </span>
  </div>
  <mat-dialog-content class="scrlContent">
    <h5>Assigned On Date</h5>
    <section class="kBoardDatePicker">
      <div class="mat-div">
        <p>From Date</p>
        <mat-form-field appearance="outline">
          <input placeholder="Select Date Range" matInput [matDatepicker]="picker" id="picker" [readonly]="true" (focus)="picker.open()" (dateChange)="changeFromDate($event)" (paste)="$event.preventDefault()" (keydown)="$event.preventDefault()" [max]="todayDate">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mat-div">
        <p>To Date</p>
        <mat-form-field appearance="outline">
          <input placeholder="Select Date Range" matInput [matDatepicker]="picker1" id="picker1" [readonly]="true" (focus)="picker1.open()" (dateChange)="changeToDate($event)" (paste)="$event.preventDefault()" (keydown)="$event.preventDefault()" [max]="todayDate" [min]="startDate">
          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
    </section>
    <h5>Assigned To</h5>
    <div class="searchBox">
      <input type="text" class="srchBox" fxFlex placeholder="Search Assignee" [(ngModel)]="seachAssigneeText"
     (keydown.space)="$event.preventDefault();">
    </div>
    <ul class="checkbox-overflow-1 overflow-scroll-1" [class.show]="show">
      <li *ngFor="let list of assigneeList | filter:seachAssigneeText" >
        <mat-checkbox (change)="getSelectedAssigneeList(list)" [(ngModel)]="list.checked" [disableRipple]="true">{{list.userName}}</mat-checkbox>

      </li>
    </ul>
    <!-- <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button> -->

  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <button mat-raised-button class="cnclbtn" (click)="resetFilters()">Reset</button>
    <button mat-raised-button class="savebtn" (click)="applyFilters()">Apply Filter</button>
  </mat-dialog-actions>
</div>
