<div class="update-over-wp">
  <div class="top-bg-area">
    <div class="up-text">
      <h2>Updates Over WhatsApp</h2>
    </div>
    <div class="up-img"></div>
  </div>
  <div class="btm-details">
    <label>Enter WhatsApp Number *</label>
    <div class="inputwrap">
      <span class="">+91</span>
      <input type="text" placeholder="Search users, emails" [(ngModel)]="whatsappNumber" minlength="8" maxlength="10">  
    </div>
  </div>
  <div class="btm-btns">
    <div class="sbmt-btn">
      <button (click)="sendUpdate()">Add Contact</button>
    </div>
    <div class="cncl-btn">
      <button (click)="closeDialog()">Cancel</button>
    </div>
  </div>
</div>