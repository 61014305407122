import { Injectable, NgZone } from '@angular/core';
import { AuthService } from './auth.service';
import { PagesService } from '../pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    isShowLoader: boolean = false;
    private logoutTimer: any;
    private readonly timeout: number = 30 * 60 * 1000;
    public env = environment.credlixURL;

    constructor(private ngZone: NgZone, public tostrService: ToastrService, public pagesService: PagesService, public authService: AuthService,) {
        this.initListener();
        this.resetTimer();
    }

    initListener() {
        window.addEventListener('mousemove', () => this.resetTimer());
        window.addEventListener('keydown', () => this.resetTimer());
        window.addEventListener('click', () => this.resetTimer());
    }

    resetTimer() {
        // Clear any existing timer and restart the logout timer
        clearTimeout(this.logoutTimer);
        this.logoutTimer = setTimeout(() => {
            this.ngZone.run(() => {
                this.tostrService.warning("IDLE Screen Timeout",'Logging Out',{timeOut: 10000});
                this.logout();
            });
        }, this.timeout);
    }

    logout() {
        this.isShowLoader = true;
        this.pagesService.logOutUser().subscribe(res => {
            if (res && res['result'] && res['status'] == 200) {
                this.isShowLoader = false;
                sessionStorage.clear();
                localStorage.clear();
                this.authService.destroySession();
                window.location.href = this.env;
            } else {
                this.isShowLoader = false;
                this.tostrService.error("Something went wrong while signing out");
            }
        }, err => {
            console.error(err);
            this.isShowLoader = false;
            this.tostrService.error("Something went wrong while signing out");
        });
    }

}