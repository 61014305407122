<div class="canclDialg">
  <div class="contnt-wrap">
    <h5>Mark Delay</h5>
    <label>Borrower will be intimated about the delay via an email.</label>
    <div class="form-g dte">
      <label class="txtlbl">New Estimated Payment Date*</label>
      <mat-form-field appearance="fill">
        <mat-label>Choose Date</mat-label>
        <input matInput [matDatepicker]="picker" (dateChange)="getChangedDate($event)" [min]="disableDates" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-g">
      <label class="txtlbl">Reason for Delay*</label>
    <textarea placeholder="Briefly enter the reason for delay." [(ngModel)]='delayedReason'></textarea>
    </div>
  </div>
  <div class="actionbtn">
    <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
    <button mat-raised-button class="savebtn" (click)="markDelayReq()" [disabled]="!delayedReason || !delayedDate" >Mark Delay</button>
  </div>
</div>