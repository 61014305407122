<div class="dialog-box">
  <h1>Generate Bulk Termsheet</h1>
  <div class="btn-bulk" mat-dialog-actions>
    <button class="crossthispop" mat-button [mat-dialog-close]></button>
  </div>
  <div mat-dialog-content>
    <div class="step-1-content">
      <div class="download-form">
        <p>Download template file</p>
        <div class="download-input">
          <div class="inner-box">
            <span><i class="ri-file-zip-fill"></i></span>
            <p>Exporter Data</p>
          </div>
          <span (click)="downloadBuyerTemplate()" title="Download Supplier Template" class="dwnldIcon"><i class="ri-download-2-line"></i></span>
        </div>
      </div>
      <div class="light-grey-text">
        <span><i class="ri-information-fill"></i></span>
        <p>Download template for Exporter Data and upload the document below.</p>
      </div>
      <div class="drag-drop-box">
        <h1>Upload your Exporter Data file here</h1>
        <div class="dropzone-wrapper" *ngIf="buyerFile == null">
          <div class="dropzone-desc">
            <i class="glyphicon glyphicon-download-alt"></i>
            <p>Drag and drop files to upload</p>
            <button>Browse file to upload</button>
          </div>
          <input type="file" name="img_logo" class="dropzone" (change)="onBuyerDataFileChanged($event)" #fileInput>
        </div>  

        <div class="dropzone-wrapper" *ngIf="buyerFile != null">
          <div class="dropzone-desc" *ngIf="buyerFile?.name">
            <button>{{buyerFile?.name}}</button>
            <i class="ri-close-line closeicon" (click)="deleteBuyerFile()"></i>
          </div>
        </div>
      </div>
      <div class="dialog-btn-box">
        <button class="cancel-btn" (click)="close()">Cancel</button>
        <button class="next-btn" mat-button [disabled]="!buyerFile?.name" (click)="fileUpload()">Next</button>
      </div>
    </div>
  </div>
</div>