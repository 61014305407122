import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutsComponent } from './payouts/payouts.component';
import { EscrowAccountRoutingModule } from './escrow-account-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { PayoutReportsComponent } from './payout-reports/payout-reports.component';
import { ManualCreditformDialogComponent } from './payouts/manual-creditform-dialog/manual-creditform-dialog.component';

@NgModule({
  declarations: [PayoutsComponent, PayoutReportsComponent, ManualCreditformDialogComponent],
  imports: [
    CommonModule,
    EscrowAccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
   MatFormFieldModule,
   MatInputModule,
   SharedModulesModule
  ],
  entryComponents: [ManualCreditformDialogComponent]
})
export class EscrowAccountModule { }
