import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {LeadsComponent} from './leads/leads.component';


const routes: Routes = [
  {
    path: 'leads',
    component: LeadsComponent
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)]
})
export class LenderLeadsRoutingModule { }
