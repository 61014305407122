<div class="template-main" *ngIf="!isMessageShow">
  <div class="dialog-header">
    <p class="main-title"><span *ngIf="isMessageShow"><i class="ri-arrow-left-line"></i></span> Template</p>
    <div class="fac-radio">
      <mat-radio-group aria-label="Select an option" class="radio-align" (change)="changeTemplate($event.value)" [(ngModel)]="templateType">
        <mat-radio-button value="WhatsApp">Whats App</mat-radio-button>
        <mat-radio-button value="Email">Email</mat-radio-button>
      </mat-radio-group>
    </div>
    <button mat-button class="close-icon" [mat-dialog-close]="true">
      <i class="ri-close-circle-fill"></i>
    </button>
  </div>
  <div class="template-main-box">
    <div class="template-input" *ngFor="let temp of templateList; let i = index">
      <p>{{templateType}} Template {{i + 1}} <span class="markDefault" *ngIf="temp.default">(Default)</span></p>
      <div class="icons">
        <span *ngIf="!temp.default" class="deleteBtn cursor-pointer" (click)="deleteTemplate(temp, i)"><i
            class="ri-delete-bin-line"></i></span>
        <mat-icon [cdkCopyToClipboard]="copyValue" (click)="copyMessage(temp.message)" style="cursor: pointer;
                      font-size: 16px;
                      position: relative;
                      top: 4px;">content_copy</mat-icon>
        <!-- <span><i class="ri-file-copy-line cursor-pointer" [cdkCopyToClipboard]="copyValue" (click)="copyMessage(temp.message)"></i></span> -->
        <span (click)="openMessage(temp, i)"><i class="ri-pencil-line cursor-pointer"></i></span>
      </div>
    </div>
    <div class="btn-more">
      <button (click)="addTemplate()"><span><i class="ri-add-line"></i></span>Add More</button>
    </div>
  </div>
</div>
<div class="template-main" *ngIf="isMessageShow">
  <p class="main-title"><span (click)="backToList(templateType)"><i class="ri-arrow-left-line cursor-pointer"></i></span>{{templateType}}
    Template {{selectedIndex + 1}}</p>
  <div select="[rightPane]">
    <div class="commentsSection">
      <div class="box-border">
        <div class="commentsSection" fxLayout="row">
          <label for="w3review"> Message</label>

          <textarea id="commentTextArea" name="commentTextArea" rows="6" cols="55" [(ngModel)]="whatsAppMessage">

        </textarea>
        </div>
        <span class="markBtn"><mat-checkbox [(ngModel)]="primary">Mark as Primary Template</mat-checkbox></span>
        <div class="cta-box">
          <button class="btnSave" (click)="saveTemplate()">{{selectedTemplate?.id ? 'Update' : 'Save'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>