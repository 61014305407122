<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap" *ngIf="authService.isAnchor()">
      <div class="lft">
        <button class="download filter back" mat-button (click)="downloadBuyerSheet()">
          <i class="ri-download-line"></i>
        </button>
      </div>
    </section>
    <div style="height: 35px;margin-top: 15px;margin-left: 15px;">
      <h3>Borrower</h3>
    </div>
    <div fxLayout="row" fxLayoutGap="1%" class="borrower">
      <div fxLayout="column">
        <mat-card class="borrow">
          <mat-card-content>
            <div class="bnkData">{{(borrowerStats && borrowerStats.borrowers_count)? borrowerStats.borrowers_count : ''}}</div>
            <div class="bnkDetail">Borrowers</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="lender">
          <mat-card-content>
            <div class="bnkData">{{(borrowerStats && borrowerStats.lenders_count)? borrowerStats.lenders_count : ''}}</div>
            <div class="bnkDetail">Lenders</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="limit">
          <mat-card-content>
            <div class="bnkData">{{ (borrowerStats && borrowerStats.limit) ? settingService.numDifferentiation(borrowerStats.limit) : ''}}</div>
            <div class="bnkDetail">Limit sanctioned</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="limitUtil">
          <mat-card-content>
            <div class="bnkData">{{(borrowerStats && borrowerStats.utilizedLimit) ? settingService.numDifferentiation(borrowerStats.utilizedLimit) : ''}}</div>
            <div class="bnkDetail">Limit Utilized</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="disbursais">
          <mat-card-content>
            <div class="bnkData">{{(borrowerStats && borrowerStats.total_disbursals) ? borrowerStats.total_disbursals : ''}}</div>
            <div class="bnkDetail">Disbursals</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="overdue">
          <mat-card-content>
            <div class="bnkData">{{(borrowerStats && borrowerStats.due_disbursals) ? borrowerStats.due_disbursals : ''}}</div>
            <div class="bnkDetail">Overdues</div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>    

    <div style="padding: 2% 15px 0px 10px;">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort class="mat_table"
      style="width: 100%;padding-left: 2%;" [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">
      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
        <th mat-header-cell *matHeaderCellDef style="width: 200px;"> {{disCol.key}} </th>
        <td mat-cell *matCellDef="let element" (click)="getBuyerInvoice(element, disCol.field)">
          <div [ngClass]="getClass(disCol.field, element)">
            {{ disCol.cell(element) }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef style="width: 100px;"></th>
        <td mat-cell *matCellDef="let element">
          <div class="iconsaction">
            <button class="p-text-edit" mat-icon-button  #editButton (click)="editDetails(element, editButton)"
            *ngIf="anchorDisplayName == 'MSAF'">
              <mat-icon class="icon_details">edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
    </table>
    </div>
    <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" showFirstLastButtons (page)="getPaginatorData()">
  </mat-paginator>
  </div>
</div>