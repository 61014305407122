<section class="reconcile-modal">
  <div class="header">
    <span class="title">{{displaySheetName()}}</span>
    <span class="material-icons" (click)="closePaymentsDialog()">
      close
    </span>
  </div>
  <div class="content">
    <div class="fst">
      <span class="lbl">Period:</span>
      <mat-form-field appearance="fill" class="dash-selct">
        <mat-select (selectionChange)="onPeriodChange($event.value)" placeholder="Select Interval">
          <mat-option value=-7>Last 7 Days</mat-option>
          <mat-option value=-30>Last 30 Days</mat-option>
          <mat-option value="custom">Custom</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="scnd" *ngIf="intervalTime =='custom'">
      <span class="lbl">From:</span>
      <mat-form-field appearance="fill" class="date-picker-field">
        <input matInput [matDatepicker]="picker" placeholder="Select Start Date" (dateChange)="changeFromDate($event)" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <span class="lbl">To:</span>
      <mat-form-field appearance="fill" class="date-picker-field">
        <input matInput [matDatepicker]="picker1" placeholder="Select End Date" (dateChange)="changeToDate($event)" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="cta">
    <button type="button" (click)="downloadReconcileSheet()">Download</button>
  </div>
</section>