<div class="uppder-div">
  <div class="cardDiv">
    <h2>{{data?.obj?.insuranceProvider}} Insurance Information</h2>
    <i class="ri-close-line closeBtn" (click)="dialogRef.close(obj)"></i>
  </div>
</div>
<div class="main-card-class">
  <div>
    <h3>Preliminary Approval</h3>
    <div *ngIf="!isOtherInsurance">
      <div class="insurance-form">
        <div class="box-flex">
          <div class="formfield mb-25">
            <label>Insurance Status<span style="color: red;">*</span></label>
            <mat-form-field appearance="outline" class="mog4-matselect">
              <mat-select [(ngModel)]="obj.insuranceStatus" placeholder="Insurance Status"
                [disabled]="!data.isVisible" (selectionChange)="changePreliminaryInsuranceStatus($event)">
                <mat-option [value]="curr" *ngFor="let curr of statuaList"> {{curr}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput mt-15">
              <mat-label>Insurance Rating</mat-label>
              <input matInput placeholder="Insurance Rating" [(ngModel)]="obj.insuranceRating" name="insuranceRating"
                [disabled]="!data.isVisible">
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput mt-15" [ngClass]="{ 'disabled-field' : true}">
              <mat-label>Tenor From</mat-label>
              <input matInput placeholder="Tenor From" [(ngModel)]="data.tenorFrom" name="tenorFrom" [disabled]="true">
            </mat-form-field>
          </div>
        </div>
        <div class="box-flex mb-25">
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput" [ngClass]="{ 'disabled-field' : true}">
              <mat-label>Applied Tenor</mat-label>
              <input matInput placeholder="Applied Tenor" [(ngModel)]="data.tenorRequested" name="tenorFrom"
                [disabled]="true">
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput">
              <mat-label>Insurance Applied Amount<span style="color: red;">*</span></mat-label>
              <input matInput type="number" min="0" oninput="validity.valid||(value='');"
                oninput="validity.valid||(value='');" placeholder="Insurance Applied Amount"
                [(ngModel)]="obj.insuranceAppliedAmount" name="insuranceAppliedAmount"
                (keyup)="omit_special_char($event)" [disabled]="!data.isVisible">
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput">
              <mat-label>Insurance Applied Date<span style="color: red;">*</span></mat-label>
              <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                [(ngModel)]="obj.insuranceAppliedDate" name="insuranceAppliedDate" [disabled]="!data.isVisible"
                [matDatepicker]="picker1" (focus)="picker1.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="box-flex mb-25">
          <div
            *ngIf="obj.insuranceStatus != 'Partially Agreed' && obj.insuranceStatus != 'Applied' && obj.insuranceStatus != 'Refused'"
            class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput"
              [ngClass]="{ 'disabled-field' : (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused') }">
              <mat-label>Approved Amount<span style="color: red;">*</span></mat-label>
              <input matInput type="number" min="0" oninput="validity.valid||(value='');" placeholder="Approved Amount"
                [(ngModel)]="obj.insuranceApprovedAmount" name="approvedAmount"
                (keyup)="omit_special_char($event,obj.insuranceApprovedAmount,obj.insuranceAppliedAmount)"
                (chanage)="checkPrelimsApproveAmount($event,obj.insuranceApprovedAmount,obj.insuranceAppliedAmount)"
                [disabled]="!data.isVisible || (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')">
            </mat-form-field>
          </div>
          <div
            *ngIf="obj.insuranceStatus == 'Partially Agreed' || obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused'"
            class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput"
              [ngClass]="{ 'disabled-field' : (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')}">
              <mat-label>Approved Amount</mat-label>
              <input matInput type="number" min="0" oninput="validity.valid||(value='');" placeholder="Approved Amount"
                [(ngModel)]="obj.insuranceApprovedAmount" name="approvedAmount"
                (keyup)="omit_special_char($event,obj.insuranceApprovedAmount,obj.insuranceAppliedAmount)"
                [disabled]="!data.isVisible || (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')">
            </mat-form-field>
          </div>
          <div
            *ngIf="obj.insuranceStatus != 'Partially Agreed' && obj.insuranceStatus != 'Applied' && obj.insuranceStatus != 'Refused'"
            class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput"
              [ngClass]="{ 'disabled-field' : (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')}">
              <mat-label>Insurance Approved Date<span style="color: red;">*</span></mat-label>
              <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                [(ngModel)]="obj.insuranceApprovedDate" name="insuranceApprovedDate"
                [disabled]="!data.isVisible || (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')"
                [matDatepicker]="picker" (focus)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div
            *ngIf="obj.insuranceStatus == 'Partially Agreed' || obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused'"
            class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput"
              [ngClass]="{ 'disabled-field' : (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')}">
              <mat-label>Insurance Approved Date</mat-label>
              <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                [(ngModel)]="obj.insuranceApprovedDate" name="insuranceApprovedDate" [max]="maxApprovedDate"
                [disabled]="!data.isVisible || (obj.insuranceStatus == 'Applied' || obj.insuranceStatus == 'Refused')"
                [matDatepicker]="picker" (focus)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput">
              <mat-label>Change In Name of Buyer</mat-label>
              <input matInput placeholder="Change in Name of Buyer" [(ngModel)]="obj.changeInNameOfBuyer"
                name="changeInNameOfBuyer" [disabled]="!data.isVisible">
            </mat-form-field>
          </div>
        </div>
        <div class="box-flex mb-25">
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput">
              <mat-label>Change In Address of Buyer</mat-label>
              <input matInput placeholder="Change in Address of Buyer" [(ngModel)]="obj.changeInAddressOfBuyer"
                name="changeInAddressOfBuyer" [disabled]="!data.isVisible">
            </mat-form-field>
          </div>
          <div class="formfield">
            <mat-form-field appearance="outline" class="mog4_matinput">
              <mat-label>Insurance Comment</mat-label>
              <input matInput placeholder="Insurance Comment" [(ngModel)]="obj.insuranceComment" name="insuranceComment"
                [disabled]="!data.isVisible">
            </mat-form-field>
          </div>
        </div>
        <div class="btn-div" *ngIf="data.isVisible">
          <button class="save" type="Button" (click)="savePrelimsData(obj)">Save</button>
        </div>
      </div>
      <div *ngIf="isVisibleFinalInsuranceSection()" class="finalApprovalDiv">
        <h2>Final Approval</h2>
        <div *ngIf="!isOtherInsurance">
          <div class="insurance-form">
            <div class="box-flex">
              <div class="formfield mb-25">
                <label>Insurance Status<span style="color: red;">*</span></label>
                <mat-form-field appearance="outline" class="mog4-matselect">
                  <mat-select [(ngModel)]="obj.finalInsuranceStatus" placeholder="Insurance Status"
                    [disabled]="!data.isVisible" (selectionChange)="changeFinalInsuranceStatus($event)">
                    <mat-option [value]="curr" *ngFor="let curr of statuaList"> {{curr}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput mt-15">
                  <mat-label>Insurance Rating</mat-label>
                  <input matInput placeholder="Insurance Rating" [(ngModel)]="obj.finalInsuranceRating"
                    name="insuranceRating" [disabled]="!data.isVisible">
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput mt-15" [ngClass]="{ 'disabled-field' : true}">
                  <mat-label>Tenor From</mat-label>
                  <input matInput placeholder="Tenor From" [(ngModel)]="data.tenorFrom" name="tenorFrom"
                    [disabled]="true">
                </mat-form-field>
              </div>
            </div>
            <div class="box-flex mb-25">
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput" [ngClass]="{ 'disabled-field' : true}">
                  <mat-label>Applied Tenor</mat-label>
                  <input matInput placeholder="Applied Tenor" [(ngModel)]="data.tenorRequested" name="tenorFrom"
                    [disabled]="true">
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput">
                  <mat-label>Insurance Applied Amount<span style="color: red;">*</span></mat-label>
                  <input matInput type="number" min="0" oninput="validity.valid||(value='');"
                    placeholder="Insurance Applied Amount" [(ngModel)]="obj.finalInsuranceAppliedAmount"
                    name="insuranceAppliedAmount" (keypress)="omit_special_char($event)" [disabled]="!data.isVisible">
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput">
                  <mat-label>Insurance Applied Date<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                    [(ngModel)]="obj.finalInsuranceAppliedDate" name="finalInsuranceAppliedDate"
                    [disabled]="!data.isVisible" [matDatepicker]="picker1" (focus)="picker1.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="box-flex mb-25">
              <div
                *ngIf="obj.finalInsuranceStatus != 'Partially Agreed'  && obj.finalInsuranceStatus != 'Applied' && obj.finalInsuranceStatus != 'Refused'"
                class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput"
                  [ngClass]="{ 'disabled-field' : (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')}">
                  <mat-label>Approved Amount<span style="color: red;">*</span></mat-label>
                  <input matInput type="number" min="0" oninput="validity.valid||(value='');"
                    oninput="validity.valid||(value='');" placeholder="Approved Amount"
                    [(ngModel)]="obj.finalInsuranceApprovedAmount" name="finalApprovedAmount"
                    (keyup)="omit_special_char($event,obj.finalInsuranceApprovedAmount,obj.finalInsuranceAppliedAmount)"
                    [disabled]="!data.isVisible || (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')">
                </mat-form-field>
              </div>
              <div
                *ngIf="obj.finalInsuranceStatus == 'Partially Agreed' || obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused'"
                class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput"
                  [ngClass]="{ 'disabled-field' : (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')}">
                  <mat-label>Approved Amount</mat-label>
                  <input matInput type="number" min="0" oninput="validity.valid||(value='');"
                    oninput="validity.valid||(value='');" placeholder="Approved Amount"
                    [(ngModel)]="obj.finalInsuranceApprovedAmount" name="approvedAmount"
                    (keyup)="omit_special_char($event,obj.finalInsuranceApprovedAmount,obj.finalInsuranceAppliedAmount)"
                    [disabled]="!data.isVisible || (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')">
                </mat-form-field>
              </div>
              <div
                *ngIf="obj.finalInsuranceStatus != 'Partially Agreed'  && obj.finalInsuranceStatus != 'Applied' && obj.finalInsuranceStatus != 'Refused'"
                class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput"
                  [ngClass]="{ 'disabled-field' : (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')}">
                  <mat-label>Insurance Approved Date<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                    [(ngModel)]="obj.finalInsuranceApprovedDate" name="insuranceApprovedDate"
                    [disabled]="!data.isVisible || (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')"
                    [matDatepicker]="picker" (focus)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div
                *ngIf="obj.finalInsuranceStatus == 'Partially Agreed' || obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused'"
                class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput"
                  [ngClass]="{ 'disabled-field' : (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')}">
                  <mat-label>Insurance Approved Date</mat-label>
                  <input matInput placeholder="Insurance Approved Date" readonly id="picker"
                    [(ngModel)]="obj.finalInsuranceApprovedDate" name="insuranceApprovedDate"
                    [disabled]="!data.isVisible || (obj.finalInsuranceStatus == 'Applied' || obj.finalInsuranceStatus == 'Refused')"
                    [matDatepicker]="picker" (focus)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput">
                  <mat-label>change In Name of Buyer</mat-label>
                  <input matInput placeholder="change in Name or Address of Buyer"
                    [(ngModel)]="obj.finalChangeInNameOfBuyer" name="changeInNameOfBuyer" [disabled]="!data.isVisible">
                </mat-form-field>
              </div>
            </div>
            <div class="box-flex mb-25">
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput">
                  <mat-label>change In Address of Buyer</mat-label>
                  <input matInput placeholder="change in Name or Address of Buyer"
                    [(ngModel)]="obj.finalChangeInAddressOfBuyer" name="changeInAddressOfBuyer"
                    [disabled]="!data.isVisible">
                </mat-form-field>
              </div>
              <div class="formfield">
                <mat-form-field appearance="outline" class="mog4_matinput">
                  <mat-label>Insurance Comment</mat-label>
                  <input matInput placeholder="Insurance Comment" [(ngModel)]="obj.finalInsuranceComment"
                    name="finalinsuranceComment" [disabled]="!data.isVisible">
                </mat-form-field>
              </div>
            </div>
            <div class="btn-div" *ngIf="data.isVisible">
              <button class="save" type="Button" (click)="saveFinalData(obj)">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isOtherInsurance">
      <div class="box-align">
        <div class="otherInsurance-form">
          <div class="formfield-ins" *ngFor="let ins of othersInsuranceList; let i = index">
            <div class="content-div">
              <mat-checkbox *ngIf="ins.id" [(ngModel)]="ins.checked"></mat-checkbox>
              <mat-form-field appearance="outline" class="mog4_matinput">
                <input matInput placeholder="Insurance Name" [(ngModel)]="ins.insurerName" [disabled]="ins.id">
              </mat-form-field>
            </div>
            <div class="action-btn">
              <button class="saveIns-btn" type="button" *ngIf="!ins.id" (click)="saveInsurer(ins)">
                Save
              </button>
              <button class="remove-btn" type="button" (click)="removeInsurance(ins,i)">
                Remove
              </button>
            </div>
          </div>
        </div>
        <div class="add-ins">
          <button class="insButton" type="button" (click)="addInsurance()">+ Add Insurance</button>
        </div>
      </div>
      <div class="btn-div">
        <button class="save" type="button" (click)="saveotherInsurance()">Apply</button>
        <button type="button" (click)="closeInsureancePopup()">Cancel</button>
      </div>
    </div>
  </div>
</div>