import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import { Router } from '@angular/router';
import { DocumentDialogComponent } from '../document-dialog/document-dialog.component';

@Component({
  selector: 'app-commission-invoices',
  templateUrl: './commission-invoices.component.html',
  styleUrls: ['./commission-invoices.component.scss']
})
export class CommissionInvoicesComponent implements OnInit {

  displayedColumns = ['invoiceNumber','searchKey','businessName','date','totalValueWithoutTax','totalValue','action'];
  dataSource = new MatTableDataSource();

  pageSize: number = 25;
  totalRecords: any;
  pageNumber: number = 0;
  searchValue: string;
  

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private settingservice:SettingService, public router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.getCiData();
  }

  getPaginatorData1(event){
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    this.getCiData(); 
  }


  getCiData() {
    this.searchValue = '';
    let obj = {};
    obj['from'] = this.paginator.pageIndex
    obj['size'] = this.pageSize

    this.settingservice.getCiData(obj).subscribe((response:any) => {
      this.dataSource.data= response['result'];
      this.totalRecords = response['total'];
      })
  }
  search(id){
    this.settingservice.getCiDatabyId(id).subscribe(res =>{
      this.dataSource.data= res['result'];
      this.totalRecords = 1;
    })
  }


  navigateToCommissionDetails(element) {
    window.open('/#/commission-invoices-details/'+ element.id);
  }
  viewCommissionInvoices(element){
    window.open(element.url);
  }
  downloadCI(isByMonth: boolean){
    const dialogRef = this.dialog.open(DocumentDialogComponent, {
      disableClose: false,
      width: '600px',
      height: '290px',
      data: {
        isByMonth: isByMonth
      }
    });
    dialogRef.afterClosed().subscribe(result => {
     
    });
  }

}
