import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAmountFormat]'
})
export class AmountFormatDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input')
  onInputChange() {
    // Get the input value
    let value: string = this.el.nativeElement.value;

    // Remove non-digit characters from the input value
    value = value.replace(/\D/g, '');

    // Format the value with commas
    const formattedValue = this.formatAmount(value);

    // Update the input value
    this.el.nativeElement.value = formattedValue;

    // Emit the formatted value using a custom event
    const event = new CustomEvent('formattedValue', {
      detail: formattedValue
    });
    this.el.nativeElement.dispatchEvent(event);
  }

  private formatAmount(value: string): string {
    // Format the value with commas
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
