<div fxFlexFill>
  <section class="ctaStripWrap">
    <div class="lft">
      <!-- <button class="filter fltbtn" mat-button [ngClass]="{'open':!isFiltersShow}" (click)="filtersDisplay()">
        <span class="material-icons">
          filter_list
        </span>
        <span> Filter</span>
      </button> -->
      <!-- <button class="sort btnSort" mat-button [matMenuTriggerFor]="sortMenu">
        <i class="ri-sort-desc"></i>
        <span>Sort</span>
        <mat-menu #sortMenu="matMenu" class="sortingmenu">
          <button mat-menu-item (click)="sortData(sort, 'createdAt', 'desc', 'Created Date- Earliest First')"
            [ngClass]="{sortApplied: activeItem=='Created Date- Earliest First' }">
            <i class="ri-check-line"></i>Created Date- Earliest First</button>

          <button mat-menu-item (click)="sortData(sort, 'createdAt', 'asc', 'Created Date- Oldest First')"
            [ngClass]="{sortApplied : activeItem==='Created Date- Oldest First' }">
            <i class="ri-check-line"></i>Created Date- Oldest First</button>

          <button mat-menu-item (click)="sortData(sort,'totalAmount', 'desc', 'Total Payable Amount -
            Max Amount')" [ngClass]="{sortApplied : activeItem=== 'Total Payable Amount -
              Max Amount' }">
            <i class="ri-check-line"></i>Total Payable Amount -
            Max Amount</button>

          <button mat-menu-item (click)="sortData(sort,'totalAmount', 'asc','Total Payable Amount -
            Min Amount')" [ngClass]="{sortApplied : activeItem==='Total Payable Amount -
              Min Amount' }"> <i class="ri-check-line"></i>Total Payable Amount -
            Min Amount</button>
        </mat-menu>
      </button>
       <button class="download" mat-button matTooltip="Download Payouts" (click)="downloadSheet()">
        <i class="ri-download-line"></i>
      </button> -->
    </div>
    <div class="rht">
      <button class="sort btnSort download-btn" mat-button [matMenuTriggerFor]="downloadMenu">
        <span>Download Reports</span>
        <i class="ri-arrow-down-s-fill"></i>
        <mat-menu #downloadMenu="matMenu" class="sortingmenu">
          <button mat-menu-item (click)="downloadLedger('CREDLIX_LEDGER_REPORT')">Full Credlix Ledger</button>
          <button mat-menu-item (click)="downloadLedger('ANCHOR_WISE_LEDGER_REPORT')">{{anchorName | titlecase}}
            ledger</button>
          <button mat-menu-item (click)="downloadSupplierLedger()">Supplier Account Balances</button>
          <button mat-menu-item (click)="downloadBalanceSheet('unMapped')">Pending Invoices</button>
        </mat-menu>
      </button>
      <button class="user" mat-button (click)="createNewPayout()">New Payout</button>

      <mat-icon [matMenuTriggerFor]="menuOption" class="moreicon" style="margin-top: 6px;">more_vert</mat-icon>
      <mat-menu #menuOption="matMenu" class="menuListProfile">
        <button mat-menu-item (click)="manualCreditForm()">Manual Credit </button>
      </mat-menu>
    </div>
  </section>


  <div class="pgheader">
    <h2><span>Payouts</span></h2>
    <div fxLayout="row" fxLayoutGap="1%" class="borrower" style="margin-top: 9px;">
      <div fxLayout="column">
        <mat-card class="borrow">
          <mat-card-content>
            <div class="bnkDetail">{{anchorName | titlecase}} Escrow balance</div>
            <div class="bnkData">₹ {{ _.get(payoutBankBalance, 'balance', "") |number}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="initate">
          <mat-card-content>
            <div class="bnkDetail">Payment Initiated</div>
            <div class="bnkData">₹ {{ _.get(payoutBankBalance, 'initiatedAmount', "") |number}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="lender">
          <mat-card-content>
            <div class="bnkDetail">Balance mapped to payouts</div>
            <div class="bnkData">₹ {{ _.get(payoutBankBalance, 'escrowMongoDTO.mappedAmount', "") |number}}</div>
            <button class="balnceDownload" mat-button matTooltip="Download Payouts"
              (click)="downloadBalanceSheet('mapped')">
              <i class="ri-download-line"></i>
            </button>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout="column">
        <mat-card class="limit">
          <mat-card-content>
            <div class="bnkDetail">Unmapped balance</div>
            <div class="bnkData">₹ {{ _.get(payoutBankBalance, 'escrowMongoDTO.unmappedAmount', "") |number}}</div>
            <button class="balnceDownload" mat-button matTooltip="Download Payouts"
              (click)="downloadUnMappedSheet()">
              <i class="ri-download-line"></i>
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </div>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
    <div class="listingFilters">
      <div class="searchBoxtop">
        <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
          <mat-label>Status</mat-label>
          <mat-select #matRef multiple (selectionChange)="getPayoutStatus($event.value)" [(ngModel)]="setPayoutStatus">
            <mat-option *ngFor="let type of payoutStatus" [value]="type.value">{{type.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="datebox searchBoxtop" (click)="$event.stopPropagation();">
        <div class="dateboxinput">
          <input type="text" ngxDaterangepickerMd class="form-control" [linkedCalendars]="true"
            [(ngModel)]="selectedCreatedDate" (datesUpdated)="getCreatedDate($event)" [locale]="{format: 'DD-MM-YYYY'}"
            placeholder="Created Date" readonly>
          <button class="searchbtn">
            <i class="ri-calendar-2-fill" (click)="openDatepicker()"></i>
          </button>
        </div>
      </div>

      <div class="search searchBoxtop cusSearch input-btn" fxLayout="row">
        <input type="text" fxFlex placeholder="Search Payout ID" [(ngModel)]="searchPayoutID"
          (keyup.enter)="fetchPayoutListing(false,'payoutId')" (keydown.space)="$event.preventDefault();"
          (input)="checkInputText(searchPayoutID, 'payout')">
        <button class="searchbtn">
          <i class="ri-search-line" (click)="fetchPayoutListing(false, 'payoutId')" *ngIf="payoutShowSearchIcon"></i>
          <i class="ri-close-line" *ngIf="payoutShowCloseIcon" (click)="resetSearchText('payoutId')"></i>
        </button>
      </div>
      <div class="search searchBoxtop cusSearch input-btn" fxLayout="row">
        <input type="text" fxFlex placeholder="Search Created By" [(ngModel)]="searchCreatedBy"
          (keyup.enter)="fetchPayoutListing(false,'createdBy')" (input)="checkInputText(searchCreatedBy, 'created')">
        <button class="searchbtn">
          <i class="ri-search-line" (click)="fetchPayoutListing( false, 'createdBy')"
            *ngIf="createdbyShowSearchIcon"></i>
          <i class="ri-close-line" *ngIf="createdbyShowCloseIcon" (click)="resetSearchText('createdBy')"></i>
        </button>
      </div>
      <div class="search searchBoxtop cusSearch input-btn" fxLayout="row">
        <input type="text" fxFlex placeholder="Search File Name" [(ngModel)]="searchFileName"
          (keyup.enter)="fetchPayoutListing(false,'fileName')" [matTooltip]="searchFileName"
          (input)="checkInputText(searchFileName, 'file')">
        <button class="searchbtn">
          <i class="ri-search-line" (click)="fetchPayoutListing(false,'fileName')" *ngIf="fileShowSearchIcon"></i>
          <i class="ri-close-line" *ngIf="fileShowCloseIcon" (click)="resetSearchText('fileName')"></i>
        </button>
      </div>

      <div class="leftfilteraction">
        <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable"
          (click)="fetchPayoutListing(false)">Apply</button>
        <button mat-flat-button class="resetbtn" #button (click)="clearPayoutFilters()">Clear
          All</button>
      </div>
    </div>
    <div class="dash-content" fxFlex fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <div class="mat_table" fxFlex="100">
          <table mat-table [dataSource]="dataSource" matSort #tableSort="matSort"
          (matSortChange)="sortData($event)"
            class="mat-elevation-z8 table_invoice suplierTable fixedTablecolumn"
            [hidden]="!(dataSource.data?.length>0)">

            <ng-container matColumnDef="payoutID" sticky>
              <th mat-header-cell *matHeaderCellDef> Payout ID </th>
              <td mat-cell *matCellDef="let element">
                <span class="pyoutID"><a (click)="getPayoutDetails(element, $event)"> {{element.uniqueDisplayId}}</a>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="status" sticky>
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <span class="status-dot" [ngClass]="setStatusDot(element.status)">
                  ● </span>
                <span *ngIf="element.status !='PAID'"> {{formatPaymentType(element.status| titlecase)}}</span>
                <span *ngIf="element.status=='PAID'">Paid({{element.successPayments }}/{{element.totalPayments}})</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="escrowStatus">
              <th mat-header-cell *matHeaderCellDef class="d-none"> Escrow Status </th>
              <td mat-cell *matCellDef="let element" class="d-none">
                <span *ngIf="element.matched== true">Matched</span>
                <span *ngIf="element.matched== false">MisMatch</span>
              </td>
            </ng-container>


            <ng-container matColumnDef="createdDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt"> Created Date </th>
              <td mat-cell *matCellDef="let element">{{element.createdAt | date}}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalPayoutAmt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalAmount"> Total Payout Amt</th>
              <td mat-cell *matCellDef="let element">{{element.totalAmount | number}}
              </td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>Created By</th>
              <td mat-cell *matCellDef="let element"> {{element.uploadedBy.email}}
              </td>
            </ng-container>

            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>File Name
              </th>
              <td mat-cell class="tableCell-overflow" [matTooltip]="element.filename" *matCellDef="let element">
                {{element.filename}}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon class="actionIcon" *ngIf="element.status=='AWAITING_ESCROW_MATCH'"
                  matTooltip="Map Transaction" (click)="payoutTransactionAction(element,'map')">refresh</mat-icon>
                
                  <mat-icon class="actionIcon" *ngIf="element.status=='AWAITING_APPROVAL'" matTooltip="UnMap Transaction"
                  (click)="payoutTransactionAction(element, 'unmap')">refresh</mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let element">

                <button class="download" mat-button matTooltip="Download File" (click)="downloadPayoutFile(element.id)">
                  <i class="ri-download-line"></i>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="lastAction">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="list" [disableRipple]="true" *ngIf="element.status != 'PAYMENT_INITIATED'" >
                  <mat-icon class="icon_details">more_vert</mat-icon>
                </button>
                <mat-menu #list="matMenu">
                  <!-- <button mat-menu-item (click)="payoutTransactionAction(element,'map')" *ngIf="element.status != 'PAYMENT_INITIATED'" > <mat-icon class="actionIcon">refresh</mat-icon>Map</button>                 
                  <button mat-menu-item (click)="payoutTransactionAction(element,'unmap')" *ngIf="element.status != 'PAYMENT_INITIATED'"><mat-icon class="actionIcon revrseActionIcon">refresh</mat-icon>UnMap</button> -->
                  <button mat-menu-item (click)="rejectTransactionFile(element)" *ngIf="element.status != 'PAYMENT_INITIATED'"><mat-icon class="actionIcon">close</mat-icon>
                    Discard File</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            [pageSize]="pageSize" [pageSizeOptions]="[25,50,75,100]" (page)="getPaginatorData($event)" showFirstLastButtons>
          </mat-paginator>
          <h5 class="text-p-wrap" *ngIf="dataSource.data.length==0">No Record Found</h5>

        </div>
      </div>
    </div>
  </div>
</div>