import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption, MatSnackBar, MatSnackBarVerticalPosition, MatDialog } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import * as lodash from 'lodash';
import { OdAdjustmentDialogComponent } from '../od-adjustment-dialog/od-adjustment-dialog.component';
@Component({
  selector: 'app-supplier-reviewpayment',
  templateUrl: './supplier-reviewpayment.component.html',
  styleUrls: ['./supplier-reviewpayment.component.scss']
})
export class SupplierReviewpaymentComponent implements OnInit {
  _: any = lodash
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  displayedColumns = ['suplierID', 'status', 'paymentRecvd', 'paymentReported', 'accountTo', 'amount_payable', 'action'];
  suplierListRes = [];
  escrowID: any;
  pageSize: number = 50;
  totalRecords: number;
  pageNumber: number = 0;
  matStepperCount: number;
  supplierSubscribeData: any;
  searchSupplierID: string = '';
  showSupplierSearchIcon: boolean = true;
  ShowSupplierCloseIcon: boolean = false;
  selectedSupplierStatus: string = '';
  isApplyButtonEnable: boolean = true;
  isClearButtonEnable: boolean = true;
  getTabIndex: any;
  isInvoiceFilterVisible: boolean = false;
  setInvoiceStatus: string = ''
  storeFilterState: any;
  invoiceStatus: any = [
    { id: '1', name: 'Match', value: 'Matched', key: 'supplierEscrowMatch.matched', val: true },
    { id: '2', name: 'Not Found', value: 'NOT_FOUND', key: 'supplierEscrowMatch.found', val: false },
    { id: '3', name: 'Mismatch', value: 'MATCHEDWITHWARNING', key: 'supplierEscrowMatch.matchedWithWarning', val: true }
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matRef') matRef: MatSelect;
  routedIndex: any;
  constructor(public settingService: SettingService, private activatedRoute: ActivatedRoute, public router: Router, public dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.getTabIndex = localStorage.getItem('tabIndex');
    this.settingService._isFilterClickSubjectBehv.subscribe(res => {
      if (res == true) {
        if (this.getTabIndex == 1) {
          this.isInvoiceFilterVisible = true;
        }
      }
      else {
        this.isInvoiceFilterVisible = false;
      }
    })

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id != null) {
        this.escrowID = params.id;
        this.routedIndex = params.index;
        let getsessionRes = JSON.parse(sessionStorage.getItem("supplierRes"));
        if (getsessionRes != null || getsessionRes != undefined) {
          this.dataSource.data = getsessionRes['result'];
          this.totalRecords = getsessionRes['total'];
          this.storeFilterState = JSON.parse(sessionStorage.getItem('supplierFilterState'));
          if (this.storeFilterState != null || this.storeFilterState != undefined) {
            this.isApplyButtonEnable = false;
            this.isClearButtonEnable = false;
            if (this.storeFilterState.hasOwnProperty('status')) {
              this.setInvoiceStatus = this.storeFilterState['status']
              this.selectedSupplierStatus = this.setInvoiceStatus
            }
            if (this.storeFilterState.hasOwnProperty('id')) {
              this.searchSupplierID = this.storeFilterState['id']
            }
          }
        }
        else {
          this.fetchSupplierPayouts();
        }
      }
    });
  }

  fetchSupplierPayouts(value?) {
    if (this.searchSupplierID != "") {
      this.showSupplierSearchIcon = false;
      this.ShowSupplierCloseIcon = true;
    }
    this.checkFilterButtonCondition();

    let reqObj = {};
    if (this.paginator != null && value == false) {
      this.paginator.firstPage();
    }
    reqObj['from'] = this.paginator.pageIndex * 50
    reqObj['size'] = this.pageSize
    reqObj['escrowId'] = this.escrowID
    if (this.selectedSupplierStatus != null && this.selectedSupplierStatus != '') {
      reqObj['status'] = this.selectedSupplierStatus
    }
    if (this.searchSupplierID != null && this.searchSupplierID.trim() != '') {
      reqObj['id'] = this.searchSupplierID.trim()
    }


    sessionStorage.setItem("supplierFilterState", JSON.stringify(reqObj));
    this.settingService.getSupplierPayout(reqObj).subscribe((res: any) => {
      this.suplierListRes = res;
      if (this.suplierListRes['result'] != null) {
        this.dataSource.data = this.suplierListRes['result'];
        this.totalRecords = res['total'];
        sessionStorage.setItem("supplierRes", JSON.stringify(res));

        // this.settingService.getSupplierPayment(this.suplierListRes);
      }
    })

  }
  getPaginatorData() {
    this.fetchSupplierPayouts(true);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    // this.selection.select(this.dataSource.data);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  setStatusDot(element) {

    if (element.escrowTransaction != null && element.escrowTransaction.matched == true) {
      return 'paidColor'
    }
    else if (element.escrowTransaction != null && element.escrowTransaction.matchedWithWarning == true) {
      return 'pendingColor'
    }
    else if (element.escrowTransaction != null && element.escrowTransaction.found == false) {
      return 'notFoundColor'
    }
  }

  getTooltipElement(element) {
    if (element.escrowTransaction != null) {
      return element.escrowTransaction.message
    }

  }
  getRowStatus(element) {
    if (element.escrowTransaction != null && element.escrowTransaction.found == true && element.escrowTransaction.matched == true) {
      return 'Match'
    }
    else if (element.escrowTransaction != null && element.escrowTransaction.found == true && element.escrowTransaction.matchedWithWarning == true) {
      return 'Mismatch'
    }
    else if (element.escrowTransaction != null && element.escrowTransaction.found == false && element.escrowTransaction.matchedWithWarning == false) {
      return 'Not Found'
    }
  }
  getSupplierStatus(value) {
    this.selectedSupplierStatus = value;
    this.checkFilterButtonCondition();
  }

  resetSearchText(value) {
    this.searchSupplierID = '';
    this.ShowSupplierCloseIcon = false;
    this.showSupplierSearchIcon = true;
    this.fetchSupplierPayouts(false);
  }
  checkFilterButtonCondition() {
    if (this.searchSupplierID != '' || this.selectedSupplierStatus != '') {
      this.isApplyButtonEnable = false;
      this.isClearButtonEnable = false;
    }
    else if (this.searchSupplierID == '' || this.selectedSupplierStatus != '') {
      this.isApplyButtonEnable = true;
      this.isClearButtonEnable = true;
    }
  }
  clearSuppplierFilters() {
    this.selectedSupplierStatus = '';
    this.setInvoiceStatus = '';
    this.searchSupplierID = '';
    this.ShowSupplierCloseIcon = false;
    this.showSupplierSearchIcon = true;

    this.fetchSupplierPayouts(false);
  }

  checkInputText(value) {
    if (value == '') {
      this.ShowSupplierCloseIcon = false;
      this.showSupplierSearchIcon = true;
    }

  }
  getAccountToolTip(element) {
    let values = ` ${element.bankDetails.recipientName}
    ${element.bankDetails.accountNumber}
      ${element.bankDetails.ifscCode}
      ${element.bankDetails.bankName}
     ${element.bankDetails.branch}`;
    return values;
  }
  openOdDialog(element) {
    let escrowID = this.escrowID;
    const dialogRef = this.dialog.open(OdAdjustmentDialogComponent, {
      disableClose: true,
      width: '65%',
      height: 'auto',
      data: { element, escrowID }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
      }
    })
  }

}
