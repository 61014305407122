import { Component, Input, OnInit , Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { MatTableDataSource } from '@angular/material';
import { FilterHistoryDialogComponent } from '../filter-history-dialog/filter-history-dialog.component';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';
@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent implements OnInit {
 @Input() laneTaskDetailsArgs;

 laneTaskIDDetail : any;
 pageNumber: number = 0;
 pageSize : number = 10;
 totalLaneHistory :  number = 0;
 totalRecords : number = 0;
 fetchTaskHistory : any[] = [];
 dataSource = new MatTableDataSource(this.fetchTaskHistory);
 displayedColumns :  string[] = ["laneTaskId", "operation","oldValue" ,"updatedValue", "remarks" ,"when"];
 displayedColumns1 : ["laneTaskId","operation","oldValue" , "updatedValue", "remarks" , "when"];
 preQualifiedData: any = {};
 displayedColumns2 :  string[] = ["title", "date","comments"];
 preQualifiedHistory: any[] = [];

  constructor(private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService,  @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<HistoryTabComponent>, public preSalesService : PresaleService) { }

  ngOnInit() {
    if(this.data.preQualified){
      this.preQualifiedData = this.data;
      this.getPrequalifiedHistoryTable(this.preQualifiedData.laneTask.id);
    } else {
      console.log("Test " + this.data)
      if (this.data != null && this.data['laneTask'] != null) {
        this.laneTaskIDDetail = this.data['laneTask'];
      } else if (this.laneTaskDetailsArgs != null) {
        this.laneTaskIDDetail = this.laneTaskDetailsArgs;
      }
      if (this.laneTaskIDDetail) {
      this.getLaneTaskHistory(false);
      }
    }
  }

  getPrequalifiedHistoryTable(id){
    this.preSalesService.getPreHistory(id).subscribe(res =>{
      if(res['status'] == 200){
        this.preQualifiedHistory = res['result'];
        this.dataSource.data = this.preQualifiedHistory;
      }
    })
  }

  closeHistorytab(){
    this.dialogRef.close();
  }
  getMoreHistory(event: any){
    this.pageNumber = event.pageIndex;
    // this.pageNumber = this.pageNumber + 1;
    this.getLaneTaskHistory();
  }

    getLaneTaskHistory(reset?) {
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = {
      from: this.pageNumber,
      size: this.pageSize
    }
    this._kService.getLaneTaskHistory(this.laneTaskIDDetail.id, obj).subscribe(res => {
      this.totalRecords = res['total'];
      this.pageNumber = this.pageNumber;
      if (this.pageNumber != 0) {
        this.fetchTaskHistory = this.fetchTaskHistory.concat(res['result']);
      }
      else {
        this.fetchTaskHistory = res['result'];
      }
      this.dataSource.data = this.fetchTaskHistory;
    });
  }
  getHistoryValue(value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    // return value[keys[0]] || "  -";
    if (value[keys[0]] && value[keys[0]].hasOwnProperty('name')) {
      return value[keys[0]].name || "   -";
    }
    else if (value[keys[0]] && value[keys[0]][0] &&value[keys[0]][0].hasOwnProperty('amount')) {
      return value[keys[0]][0].amount || "   -";
    }
    else {
      return value[keys[0]] || "   -";
    }
  }

  getHistoryField (value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    return keys[0];
  }
  toLowerCase = (text) => {
    if (!text) {
      return "";
    }
    return text.toLowerCase();
  }
  openDialog() {
    const dialogRef = this.dialog.open(FilterHistoryDialogComponent,{
      width: '35%',
      height: '50%',
      panelClass:'filterhistory'
    });
  }
}
