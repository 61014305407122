<div class="download_content">
  <div class="top">
    <h4>Add to download manager</h4>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <p>Download requests of more than 100 records, are added to Download Manager. You can access your download from <i class="ri-settings-3-fill"></i> <span> > Download Manager</span>.</p>

  <div class="dwnform"><mat-label>Name</mat-label>
  <div class="namefield" floatLabel="never" appearance="outline">
    <input [(ngModel)]="reportName" placeholder="Optional">
  </div>
  </div>
  <mat-dialog-actions class="actionbtn">
    <!-- <button class="mat-raised-button" (click)="close()">Close</button> -->
    <button class="mat-flat-button" (click)="saveInvoices()">Add to Downloads</button>
  </mat-dialog-actions>
</div>
