import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';
import { PagesService } from 'src/app/pages/pages.service';
@Component({
  selector: 'app-comments-tab',
  templateUrl: './comments-tab.component.html',
  styleUrls: ['./comments-tab.component.scss'],
})

export class CommentsTabComponent implements OnInit {
  comments: any = [];
  dataSource = [];
  displayedColumns: string[] = ["id", "userName"];
  displayedColumns1: ["id", "userName"];
  laneTaskIDDetail: any;
  allUsersList: any;
  commentUserName: string;
  addTaskComment: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public _kService: KanbanService, private dailogRef: MatDialogRef<CommentsTabComponent>, public preSalesService: PresaleService, public pagesService: PagesService) { }
  commentPageNumber: number = 0;
  pageSize: number = 10;
  totalComments: any = [];
  getTaskComment(save?, load?) {
    let reqObj = {
      "from": this.commentPageNumber * this.pageSize,
      "size": 200,
      "where": { "laneTask.$id": { "type": "search", "value": this.laneTaskIDDetail.id } }
    }
    this._kService.getTaskComment(reqObj).subscribe(res => {
      let tmp = res['result'];
      tmp.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.totalComments = tmp;
    })
  }

  getCommentUserName(name) {
    if (name != null) {
      let userName = name['byUser']['name'];
      if (userName != null && userName != '') {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length)
        }
        this.commentUserName = matches.join('').toUpperCase()
        return this.commentUserName;
      }
    }
  }

  ngOnInit() {
    if (this.data != null && this.data['laneTask'] != null) {
      this.laneTaskIDDetail = this.data['laneTask'];
    }
    if (this.data != null && this.data['userList'] != null) {
      let tmp = [];
      for (let k = 0; k < this.data['userList'].length; k++) {
        let t = this.data['userList'][k];
        t['value'] = t.userName;
        tmp.push(t);
      }
      this.allUsersList = tmp;
    }

    if (!this.data.preQualified && !this.data.earlyPayment) {
      this.getTaskComment(false);
    } else if (!this.data.preQualified && this.data.earlyPayment) {
      this.getEarlyPaymentsComments();
    } else {
      this.getPreComments();
    }
  }
  getPreComments() {
    this.preSalesService.getPreQualComment(this.data.laneTask.id).subscribe(res => {
      let tmp = res['result'];
      tmp.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.totalComments = tmp;
    })
  }
  onScrollDown() {
    console.log("Scrolling");
  }

  closeCommentTab() {
    this.dailogRef.close();
  }
  saveTaskComment() {
    if (!this.data.preQualified && !this.data.earlyPayment) {
      let obj = {
        "commentId": "string",
        "laneTaskId": this.laneTaskIDDetail.id,
        "message": this.addTaskComment
      }
      this._kService.addTaskComment(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.addTaskComment = ''
          this.getTaskComment("save");
        }
      })
    } else if (!this.data.preQualified && this.data.earlyPayment) {
      let obj = {
        businessAccoutnId: this.laneTaskIDDetail.id,
        comment: this.addTaskComment
      }
      this.pagesService.setEPComment(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.addTaskComment = ''
          this.getEarlyPaymentsComments();
        }
      })
    } else {
      let obj = {
        "leadId": this.data.laneTask.id,
        "message": this.addTaskComment
      }
      this.preSalesService.addPreQualComment(obj).subscribe(res => {
        if (res['status'] == 200) {
          this.addTaskComment = ''
          this.getPreComments();
        }
      })
    }

  }
  cancelComment() {
    this.addTaskComment = ''
  }
  getEarlyPaymentsComments() {
    let obj = {
      id: this.laneTaskIDDetail.id,
      pageNo: 0,
      pageSize: 20
    }
    this.pagesService.getEPComments(obj).subscribe(res => {
      let tmp = res['result'];
      tmp.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.totalComments = tmp;
    })
  }

}
