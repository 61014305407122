  <div class="card-header">
    <h2>Conversion Funnel</h2>
    <div class="chart-filter">
      <button class="duration-filter" mat-button [matMenuTriggerFor]="durationFilter">{{invoiceDuration}}<i
        class="ri-arrow-down-s-line"></i></button>
    <mat-menu class="duration-filter-menu" #durationFilter="matMenu">
      <div>
        <mat-radio-group aria-label="Select an option" *ngFor="let timeVal of durationArr"
        [(ngModel)]="invoiceDuration">
          <ul class="menu-list">
            <li>
              <mat-radio-button [value]="timeVal" (click)="durationChange($event, timeVal, 'conversionFunnel')"
              >{{timeVal}}</mat-radio-button>
            </li>
            <mat-divider></mat-divider>
          </ul>
        </mat-radio-group>
      </div>
      <div class="datebox" *ngIf="invoiceDuration =='Custom'" (click)="$event.stopPropagation();">
        <div class="dateboxinput">
          <input type="text" ngxDaterangepickerMd class="form-control"
            [linkedCalendars]="true" [(ngModel)]="selectedDate" (datesUpdated)="datesUpdated($event)"
            [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range" readonly>
        </div>
      </div>
    </mat-menu>
        <button class="chartsMoreOption-btn" mat-button [matMenuTriggerFor]="chartsMoreOption"><i class="ri-more-2-fill"></i></button>
        <mat-menu class="moreOptionMenu" #chartsMoreOption="matMenu">
          <button (click)="downloadImg()" mat-menu-item><i class="ri-download-2-line"></i> Download</button>
        </mat-menu>
      </div>
    </div>
    <div id="conversionFunnerGraph" style="width: 100%;height: 220px;"></div>
  <div *ngIf="loading" class="overlay">
    <app-progress-spinner></app-progress-spinner>
  </div>