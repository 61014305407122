import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePayoutRoutingModule } from './create-payout-routing.module';
import { PayoutCreationComponent } from './payout-creation/payout-creation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { UploadfilePayoutComponent } from './payout-creation/uploadfile-payout/uploadfile-payout.component';
import { ReviewPayoutComponent } from './payout-creation/review-payout/review-payout.component';
import { SendPaymentPayoutComponent } from './payout-creation/send-payment-payout/send-payment-payout.component';
import { InvoiceReviewpaymentComponent } from './payout-creation/review-payout/invoice-reviewpayment/invoice-reviewpayment.component';
import { SupplierReviewpaymentComponent } from './payout-creation/review-payout/supplier-reviewpayment/supplier-reviewpayment.component';
import { PaymentsReviewComponent } from './payout-creation/review-payout/payments-review/payments-review.component';
import { PaymentVerificationDialogComponent } from './payout-creation/review-payout/payment-verification-dialog/payment-verification-dialog.component';
import { CodeInputModule } from 'angular-code-input';
import { OtpLoaderComponent } from './payout-creation/review-payout/otp-loader/otp-loader.component';
import { OdAdjustmentDialogComponent } from './payout-creation/review-payout/od-adjustment-dialog/od-adjustment-dialog.component';

@NgModule({
  declarations: [PayoutCreationComponent, UploadfilePayoutComponent, ReviewPayoutComponent, SendPaymentPayoutComponent, InvoiceReviewpaymentComponent, SupplierReviewpaymentComponent, PaymentsReviewComponent, PaymentVerificationDialogComponent, OtpLoaderComponent,OdAdjustmentDialogComponent],
  imports: [
    CommonModule,
    CreatePayoutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
   MatFormFieldModule,
   MatInputModule,
   SharedModulesModule,
   CodeInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[PaymentVerificationDialogComponent, OtpLoaderComponent,OdAdjustmentDialogComponent]
})
export class CreatePayoutModule { }
