import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollarStringFormat'
})
export class DollarStringFormat implements PipeTransform {

  transform(value: number): string {
    if (!value) return "0";
    
    const absValue = Math.abs(value);
    
    if (absValue >= 1.0e+12) {
      return Math.round(value / 1.0e+12) + "T";
    }
    if (absValue >= 1.0e+9) {
      return Math.round(value / 1.0e+9) + "B";
    }
    if (absValue >= 1.0e+6) {
      return Math.round(value / 1.0e+6) + "M";
    }
    if (absValue >= 1.0e+3) {
      return Math.round(value / 1.0e+3) + "K";
    }
    return value.toString();
  }
}
