import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PagesService } from 'src/app/pages/pages.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-loan-invoicing',
  templateUrl: './loan-invoicing.component.html',
  styleUrls: ['./loan-invoicing.component.scss']
})
export class LoanInvoicingComponent implements OnInit {

  displayedColumns = ['transactionId','transactionDate', 'amountRequested','expiryDate','balanceOutstanding','transactionParticular'];
  buyerInvoices = [];
  // displayedColumns1 = [];
  dataSource = new MatTableDataSource(this.buyerInvoices);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  selection = new SelectionModel(true, []);
  isAnchor: boolean = false;
  setbuyerID: string;
  setbuyerName:string;
  isBuyer: boolean = false;
  lenderId: string;
  searchID: string;

  constructor(private pagesService: PagesService, private activatedRoute: ActivatedRoute,
    private router: Router, public authService: AuthService) { }

  ngOnInit() {
    this.pagesService.isChangeColor("finance_white");
    this.dataSource.data.length = 0;
    this.paginator.length = 0;
    this.activatedRoute.params.subscribe(params => {
      this.setbuyerID = params['searchKey'];
      this.setbuyerName = params['businessName'];
      this.lenderId = params['lenderId'];
      this.searchID = params['id'];
    })
    if(this.authService.isAnchor()){
      this.isAnchor = true;
    }
    if(this.authService.isBuyer()){
      this.isBuyer = true;
    }
    this.getPaginatorData();
  }

  //creating columns dynamically for loan invoice table
  // createHeaders = (config) => {
  //   this.displayedColumns1 = [];
  //   this.displayedColumns = [];
  //   config.map((each) => {
  //     this.displayedColumns1.push(each.field);
  //     each['cell'] = (element: any) => {
  //       return _.get(element, each.field);
  //     }
  //   })
  //   this.displayedColumns1.unshift('select');
  //   this.displayedColumns = config;
  // }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    this.router.navigate(['/bankLists/' + this.searchID]);
  }

  breadcrumNavigation() {
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

  getPaginatorData() {
    this.pagesService.getLoanInvoiceDetails(this.setbuyerID, this.pageNumber, this.pageSize, this.lenderId).subscribe(res => {
      this.dataSource.data = res['result'];
      this.totalRecords = res['size'];
      this.paginator.length = this.totalRecords;
    })
  }
}
