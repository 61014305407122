<div fxFlexFill>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
    <div class="top" fxLayout="row" fxFlex="8" flexLayoutAlign="center center">
      <div class="search searchBoxtop" fxFlex="20" fxLayout="row">
        <input type="text" fxFlex placeholder="Search Doc ID" [(ngModel)]="seachText"
         (keyup.enter)="applyFilters(false)" (keydown.space)="$event.preventDefault();">
        <button class="searchbtn"><i class="ri-search-line" (click)="applyFilters(false)" *ngIf="showSearchIcon"></i>
          <i class="ri-close-line" *ngIf="showCloseIcon" (click)="resetSearchText()"></i></button>
      </div>

    </div>

    <div class="dash-content" fxFlex="90" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between" fxLayoutGap="12px">
        <div fxLayout="column" class="left-side" fxFlex="18" fxLayoutGap="5px">
          <mat-accordion class="mataccWrap">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Document Type
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" (change)="ChangeTransactionType($event.value)"
                  [(ngModel)]="selectedStatus">
                  <mat-radio-button color="primary" value="invoice">Invoice</mat-radio-button>
                  <mat-radio-button color="primary" value="purchase">Purchase order</mat-radio-button>
                </mat-radio-group>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccWrap" *ngIf="selectedStatus =='invoice'">
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Due Date
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" id="dueDate"
                  (change)="getCustomSelectedDate($event.value)" [(ngModel)]="customDueDateOption">
                  <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
                  <mat-radio-button color="primary" value="30">Last 30Days</mat-radio-button>
                  <mat-radio-button color="primary" value="90">Last 90Days</mat-radio-button>
                  <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="datebox" *ngIf="customDueDateOption ==-1">
                <div class="dateboxinput">
                  <input type="text" ngxDaterangepickerMd class="form-control" [linkedCalendars]="true"
                    [(ngModel)]="selectedDueDate" (datesUpdated)="dueDatesUpdated($event)"
                    [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range">
                </div>
                <!-- <span (click)="clearDueRange()"><i class="ri-close-line"></i></span> -->

              </div>


            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="mataccWrap">
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Issue Date
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" id="issueDate"
                  (change)="getCustomSelectedissueDate($event.value)" [(ngModel)]="customDateOption">
                  <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
                  <mat-radio-button color="primary" value="30">Last 30Days</mat-radio-button>
                  <mat-radio-button color="primary" value="90">Last 90Days</mat-radio-button>
                  <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="datebox" *ngIf="customDateOption ==-1">
                <div class="dateboxinput">
                  <input type="text" ngxDaterangepickerMd class="form-control" [linkedCalendars]="true"
                    [(ngModel)]="selectedIssueDate" (datesUpdated)="isueDatesUpdated($event)"
                    [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range">
                </div>
              </div>

            </mat-expansion-panel>
          </mat-accordion>
          <div class="leftfilteraction">
            <button mat-flat-button #button class="appbtn" (click)="applyFilters(false)">Apply</button>
            <button mat-flat-button #button class="resetbtn" (click)="resetFilters($event, button)">Reset</button>
          </div>
        </div>

        <div class="mat_table" fxFlex="82">

          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice"
            *ngIf="(purchaseOrderList?.length>0) || (supplierInvoiceList?.length>0)">

            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef>
                <!-- <div class="chk_type">
                  <mat-checkbox></mat-checkbox>
                </div> -->
              </th>
              <td mat-cell *matCellDef="let element"><img *ngIf="selectedStatus=='invoice'"
                  src="/assets/images/inv-icon.svg"><img *ngIf="selectedStatus=='purchase'"
                  src="/assets/images/po-icon.svg"> </td>
            </ng-container>

            <ng-container matColumnDef="documentType">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceNumber" *ngIf="selectedStatus=='invoice'">
              <th mat-header-cell *matHeaderCellDef> Doc ID </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext" title="{{element.searchKey}}"
                  (click)=getRecord(element,selectedStatus)>{{element.invoiceNumber}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="searchKey" *ngIf="selectedStatus=='purchase'">
              <th mat-header-cell *matHeaderCellDef> Doc ID </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext" title="{{element.searchKey}}"
                  (click)=getRecord(element,selectedStatus)>{{element.searchKey}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <div class="status open"><span>Open</span></div>
              </td>
            </ng-container>

            <ng-container matColumnDef="supplier_account_name">
              <th mat-header-cell *matHeaderCellDef> Sending Party </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext">{{element.supplier_account_name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="customer_account_name">
              <th mat-header-cell *matHeaderCellDef> Receiving Party </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext">{{element.customer_account_name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Amount</th>
              <td mat-cell *matCellDef="let element"> {{element.amount | currency:'INR':true}} </td>
            </ng-container>
            <ng-container matColumnDef="invoiceDate" *ngIf="selectedStatus=='invoice'">
              <th mat-header-cell *matHeaderCellDef> Issue Date </th>
              <td mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="purchaseDate" *ngIf="selectedStatus=='purchase'">
              <th mat-header-cell *matHeaderCellDef> Issue Date </th>
              <td mat-cell *matCellDef="let element"> {{element.purchaseDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="dueDate" *ngIf="InvoiceDueDate">
              <th mat-header-cell *matHeaderCellDef> Due Date </th>
              <td mat-cell *matCellDef="let element"> {{element.dueDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="epEligible" *ngIf="InvoiceDueDate && isCustomer == true">
              <th mat-header-cell *matHeaderCellDef> EP Eligible </th>
              <td mat-cell *matCellDef="let element">
                <span matTooltip="Mark as Not Eligile" *ngIf="element.isEligibleForEP && isEligible(element)">
                  <mat-icon class="outline eligible" (click)=markInvoiceEPStatus(element)>done</mat-icon>
                </span>
                <span matTooltip="Mark as Eligible" *ngIf="!element.isEligibleForEP && isEligible(element)">
                  <mat-icon class="outline noteligible" (click)=markInvoiceEPStatus(element)>done</mat-icon>
                </span>

                <span matTooltip="Due Date Expired" *ngIf="!isEligible(element)">
                  <mat-icon class="outline">done</mat-icon>
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="mat_table" *ngIf="(selectedStatus=='invoice') && (purchaseOrderList?.length==0)"
            style="text-align:center"><span>NO RECORDS
              FOUND</span></div>
          <div class="mat_table" *ngIf="(selectedStatus=='purchase') &&(supplierInvoiceList?.length==0)"
            style="text-align:center"><span>NO RECORDS
              FOUND</span></div>
          <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            [pageSize]="pageSize" [pageSizeOptions]="[10,20,40]" (page)="getPaginatorData()" showFirstLastButtons>
          </mat-paginator>

        </div>
      </div>
    </div>
  </div>
</div>