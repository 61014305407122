import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarlyPaymentListingComponent } from './early-payment-listing/early-payment-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { SharedComponentModule } from 'src/app/shared-files/shared-component/shared-component.module';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { CustomSnackbarComponent } from './early-payment-listing/custom-snackbar/custom-snackbar.component';
import { EarlyPaymentsListingDocViewComponent } from './early-payment-listing/early-payments-listing-doc-view/early-payments-listing-doc-view.component';
import { ReportDownloadInvoiceComponent } from './early-payment-listing/report-download-invoice/report-download-invoice.component';
import { RequestEarlyPaymentComponent } from './early-payment-listing/request-early-payment/request-early-payment.component';
import { PaymentRequestedComponent } from './early-payment-listing/request-early-payment/payment-requested/payment-requested.component';
import { RouterModule } from '@angular/router';
import { EarlyPaymentsRoutingModule } from './early-payments-routing.module'
import { DatePipe } from '@angular/common';
import { SendUpdateWhatsappComponent } from './early-payment-listing/send-update-whatsapp/send-update-whatsapp.component';
import { CancelEPDialogComponent } from './early-payment-listing/cancel-ep-dialog/cancel-ep-dialog.component';
import { MarkDelayedEPDialogComponent } from './early-payment-listing/mark-delayed-ep-dialog/mark-delayed-ep-dialog.component';

@NgModule({
  declarations: [EarlyPaymentListingComponent, CustomSnackbarComponent, EarlyPaymentsListingDocViewComponent, ReportDownloadInvoiceComponent, RequestEarlyPaymentComponent, PaymentRequestedComponent, SendUpdateWhatsappComponent, CancelEPDialogComponent, MarkDelayedEPDialogComponent],
  imports: [
    CommonModule,
    SharedModulesModule,
    FormsModule,
    DirectiveModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SharedComponentModule,
    RouterModule,
    EarlyPaymentsRoutingModule
  ],
  providers: [DatePipe],
  entryComponents: [
    RequestEarlyPaymentComponent, CustomSnackbarComponent, CancelEPDialogComponent,
    MarkDelayedEPDialogComponent, SendUpdateWhatsappComponent, ReportDownloadInvoiceComponent
  ],
})
export class EarlyPaymentsModule { }
