import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-send-for-consent',
  templateUrl: './send-for-consent.component.html',
  styleUrls: ['./send-for-consent.component.scss']
})
export class SendForConsentComponent implements OnInit {
  showThankYou:boolean=false;
  userName:string='';
  companyName:string='';
  constructor(private activatedRoute:ActivatedRoute,private router:Router) { }
  laneTaskId:any;
  ngOnInit() {
    this.activatedRoute.params.subscribe(resp=>{
      this.laneTaskId = resp.id;
    })
    this.activatedRoute.queryParams.subscribe((params) => {
      const ref1 = params['ref1'];
      const ref2 = params['ref2'];
      this.userName = decodeURIComponent(ref1);
      this.companyName = decodeURIComponent(ref2).replace(/=$/, '');
    });
  }
  consent(){
    this.router.navigate(['/consent-otp-verification/',this.laneTaskId])
  }
  cancel(){
    this.showThankYou = !this.showThankYou;
  }
}
