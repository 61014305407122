import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-filter-history-dialog',
  templateUrl: './filter-history-dialog.component.html',
  styleUrls: ['./filter-history-dialog.component.scss']
})
export class FilterHistoryDialogComponent implements OnInit {
  constructor( public dialogRef: MatDialogRef<FilterHistoryDialogComponent>) { }

  ngOnInit() {
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
