import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-domestic-history',
  templateUrl: './domestic-history.component.html',
  styleUrls: ['./domestic-history.component.scss']
})
export class DomesticHistoryComponent implements OnInit, OnDestroy {
  laneTaskId:any;
  pageNumber: number = 0;
  pageSize : number = 10;
  totalLaneHistory :  number = 0;
  totalRecords : number = 0;
  fetchTaskHistory : any[] = [];
 dataSource = new MatTableDataSource(this.fetchTaskHistory);
 @Input() data: Observable<any>;
 private dataSubscription: Subscription;
 public callHistory: boolean;
  displayedColumns :  string[] = ["laneTaskId", "operation","oldValue" ,"updatedValue", "remarks" ,"when"];
  constructor(public kService: KanbanService) { }

  ngOnInit() {
    this.dataSubscription = this.data.subscribe(data=>{
      if(data){
        this.laneTaskId = data.laneTaskId
        this.getLaneTaskHistory();
      }
    })
  }
  ngOnDestroy(): void {
    if(this.dataSubscription){
      this.dataSubscription.unsubscribe();
    }
  }
  getMoreHistory(event: any){
    this.pageNumber = event.pageIndex;
    // this.pageNumber = this.pageNumber + 1;
    this.getLaneTaskHistory();
  }
  getLaneTaskHistory() {
    let obj = {
      from: this.pageNumber,
      size: this.pageSize
    }
    if(this.laneTaskId && this.laneTaskId!=null && this.laneTaskId != undefined && this.laneTaskId!='' ){
    this.kService.getLaneTaskHistory(this.laneTaskId, obj).subscribe(res => {
      this.totalRecords = res['total'];
      this.pageNumber = this.pageNumber;
      if (this.pageNumber != 0) {
        this.fetchTaskHistory = this.fetchTaskHistory.concat(res['result']);
      }
      else {
        this.fetchTaskHistory = res['result'];
      }
      this.dataSource.data = this.fetchTaskHistory;
    });}
  }
  getHistoryValue(value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    // return value[keys[0]] || "  -";
    if (value[keys[0]] && value[keys[0]].hasOwnProperty('name')) {
      return value[keys[0]].name || "   -";
    }
    else if (value[keys[0]] && value[keys[0]][0] &&value[keys[0]][0].hasOwnProperty('amount')) {
      return value[keys[0]][0].amount || "   -";
    }
    else {
      return value[keys[0]] || "   -";
    }
  }
  getHistoryField (value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    return keys[0];
  }
  toLowerCase = (text) => {
    if (!text) {
      return "";
    }
    return text.toLowerCase();
  }
}
