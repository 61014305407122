import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';
import { SettingService } from 'src/app/settings/setting.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-payment-verification-dialog',
  templateUrl: './payment-verification-dialog.component.html',
  styleUrls: ['./payment-verification-dialog.component.scss']
})
export class PaymentVerificationDialogComponent implements OnInit {
  isBankValid: boolean = false;
  bankData: any;
  sendBtnLabel: string = "Send Payments";
  bankRequestId: string;
  isBankEditable: boolean = false;
  businessAccountId: string;
  otp: string;
  isCodeHidden: boolean = true;
  isConfirm: boolean = true;
  isPrimary: boolean = false;
  userEmail: string = "";
  userPhone: string = "";
  isResend: boolean = false;
  escrowID: any;
  isEscrowAccount: boolean = false;
  counter: number = 30
  timerId: any;
  loggedUserEmail: string;
  totalPayment:number;
  isSid: boolean = false;
  @ViewChild('codeInput') codeInput;

  constructor(public dialogRef: MatDialogRef<PaymentVerificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private pageService: PagesService, private settingService: SettingService, public toasterService: ToastrService, public router: Router, private activatedRoute: ActivatedRoute) {
    if (data['value'] == 'escrow-account') {
      this.isEscrowAccount = true;
      this.requestIDRes = this.data['requestID'];
    }
    else {
      this.isEscrowAccount = false;
    }
    if(this.data['isSid']){
      this.isSid = true;
    }
    if (data['isBankValid']) {
      this.isBankValid = data['isBankValid'];
      this.businessAccountId = data['elementDetails']['id'];
      this.sendBtnLabel = "Confirm";
      this.isPrimary = data['isPrimary'];
      this.bankRequestId = data['requestId']
      if (this.isPrimary) {
        this.bankData = data['bankData'];
      }
      else {
        this.bankData = data['bankData'].value;
      }
      this.isBankEditable = data['isBankEditable'];
      this.userEmail = data['elementDetails']['userEmail'];
      this.userPhone = data['elementDetails']['phoneNumber'];
    }
  }

  ngOnInit() {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if(getLocalStorage.role != null){
      this.loggedUserEmail= getLocalStorage.role.userEmail
    }

    this.settingService._getTotalPayment.subscribe(res=>{
      this.totalPayment= res;
    })
    this.setTimer();
  }

  requestIDRes: any;
  getEscrowPaymentOTP() {
    let obj = {
      fileId: this.data.id
    }
    this.settingService.getOTPPayment(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.counter = 30
        this.requestIDRes = res['result']['requestId'];
      }
    })
  }

  sendPaymentsWithOTP() {
    let obj = {
      requestId: this.requestIDRes,
      otp: this.otp
    }
    this.settingService.sendEscrowPaymentWithOTP(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.router.navigate(['/payouts']);
        this.toasterService.success("Payment Creation Successfully");
        this.dialogRef.close();
      }
    });
    // this.sendOTP();
  }


  sendOTP() {
    let obj = {
      "businessAccountId": this.businessAccountId,
      "otpRequestType": "BOTH",
      "otpType": "BANK_ACCOUNT_ADDITION"
    }
    if (this.isPrimary) {
      obj.otpType = "BANK_ACCOUNT_SET_PRIMARY"
    }
    else if (this.isBankEditable) {
      obj.otpType = "BANK_ACCOUNT_DELETE"
    }
    this.pageService.getOTPRequest(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.counter = 30
        this.bankRequestId = res['result']['requestId']
      }
    })
  }

  submitBankDetails() {
    if (this.isEscrowAccount == true) {
      this.sendPaymentsWithOTP();
    }
    else {
      let obj = {
        "accountNumber": this.bankData['accountNumber'],
        "id": this.bankData['id'],
        "businessAccountId": this.businessAccountId,
        "ifscCode": this.bankData['ifscCode'],
        "recipientName": this.bankData['recipientName'],
        "otpRequest": {
          "otp": this.otp,
          "requestId": this.bankRequestId
        }
      };
      if(this.isSid){
        obj['sid'] = this.isSid;
      }
      if (this.isPrimary) {
        this.pageService.setPrimaryBankAccount(obj).subscribe(res => {
          if (res['status'] == 200) {
            let obj = {
              'result': res['result'],
              'data': this.bankData,
              'setPrimary': this.isPrimary
            }
            this.dialogRef.close(obj);
          }
        })
      }
      else if (!this.isBankEditable) {
        this.pageService.saveSupplierBankDetails(obj).subscribe(res => {
          if (res['status'] == 200) {
            this.dialogRef.close(res['result']);
          }
        })
      }
      else {
        this.pageService.deleteBankAccount(obj).subscribe(res => {
          if (res['status'] == 200) {
            let obj = {
              'result': res['result'],
              'data': this.bankData,
              'isDeletion': true
            }
            this.dialogRef.close(obj);
          }
        },
        error =>{
          this.dialogRef.close(false);
        })
      }
    }
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.isConfirm = false;
    this.otp = code;
    if (code.length != null && code.length == 4) {
      this.isConfirm = false;
    }
    else {
      this.isConfirm = true;
    }
  }

  onCodeChanged(code: string) {
    if (code.length != 4) {
      this.isConfirm = true;
    }
  }

  hideOTP() {
    this.isCodeHidden = !this.isCodeHidden;
  }

  resendOTP() {
    if (this.isResend) {
      this.isConfirm = true;
      this.codeInput.reset();
      this.isResend = false;
      if (!this.isEscrowAccount) {
        this.sendOTP();
      }
      else {
        this.getEscrowPaymentOTP();
      }
    }
  }

  closeDialog() {
    let obj = {
      disabledPrimary: true,
      isPrimary: this.isPrimary
    }
    this.dialogRef.close(obj);
  }

  setTimer() {
    this.timerId = document.getElementById('timer');
    setInterval(() => {
      if (this.counter == 0) {
        this.isResend = true;
        this.timerId.innerHTML = '';
      } else {
        this.counter--;
        this.timerId.innerHTML = 'in ' + this.counter + ' sec';
      }
    }, 1000)
  }
}
