import { Component, OnInit } from '@angular/core';
import { KanbanService } from '../../kanban.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {LaneMoveComponent} from './lane-move/lane-move.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-lanes-list',
  templateUrl: './lanes-list.component.html',
  styleUrls: ['./lanes-list.component.scss']
})
export class LanesListComponent implements OnInit {
  boardLane=[];
  constructor(public _kService: KanbanService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getAllLane();
  }

  getAllLane(){
    let obj={
      id:'62207c49ea84b5409074e91b'
    }
    this._kService.getBoardLane(obj).subscribe(res=>{
      this.boardLane= res['result'];
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    this.boardLaneMove(event);
  }

  boardLaneMove(event){
    console.log(event);
    const dialogRef = this.dialog.open(LaneMoveComponent, {
      width: '45%',
      height: '50%'
      });
    dialogRef.afterClosed().subscribe(result => {
      moveItemInArray(this.boardLane, event.previousIndex, event.currentIndex);
    });
  }
}
