<div class="main-div">
  <div>
    <h3>OD Adjustment</h3>
  </div>
  <div class="btn-box">
    <span class="coseBtn" (click)="this.dialogRef.close(false)">
      <mat-icon>close</mat-icon>
    </span>
  </div>
  <div class="table-div">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice">

      <!-- Position Column -->
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"><input type="checkbox" [(ngModel)]="element.select" /></td>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber" sticky>
        <th mat-header-cell *matHeaderCellDef> Inv No. </th>
        <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> Due Date </th>
        <td mat-cell *matCellDef="let element"> {{element.dueDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Invoice Amount </th>
        <td mat-cell *matCellDef="let element"> ₹ {{element.amount | number}} </td>
      </ng-container>

      <ng-container matColumnDef="overDueAmount">
        <th mat-header-cell *matHeaderCellDef> OD Amount </th>
        <td mat-cell *matCellDef="let element"> ₹ {{element.overDueAmount | number}} </td>
      </ng-container>

      <ng-container matColumnDef="repaymentDate">
        <th mat-header-cell *matHeaderCellDef> Repayment Date </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <input type="text" (click)="repaymentDate.open()" (dateChange)="changeRepaymentDate($event,element,i)"
            [matDatepicker]="repaymentDate" name="repaymentDate" [(ngModel)]="element.repaymentDate" /><mat-datepicker
            #repaymentDate></mat-datepicker>
        </td>
      </ng-container>

      <ng-container matColumnDef="adjustmentAmount">
        <th mat-header-cell *matHeaderCellDef> Adjustment Amount </th>
        <td mat-cell *matCellDef="let element"> <input type="number" [(ngModel)]="element.adjustmentAmount" /></td>
      </ng-container>

      <ng-container matColumnDef="repaymentSource">
        <th mat-header-cell *matHeaderCellDef> Repayment Source </th>
        <td mat-cell *matCellDef="let element"><span>
            <select [(ngModel)]="element.repaymentSource">
              <option value="Buyer">Buyer</option>
              <option value="Supplier">Supplier</option>
            </select>
          </span></td>
      </ng-container>

      <ng-container matColumnDef="waiveInterest">
        <th mat-header-cell *matHeaderCellDef> Waive Interest </th>
        <td mat-cell *matCellDef="let element"><input type="checkbox" [(ngModel)]="element.waiveInterest" /></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
    </table>
  </div>
  <div class="total-amounts">
    <span class="total-amount-label">
      <mat-icon>account_balance</mat-icon>
      Total Available Amount: ₹ {{ data.element.amount_payable.toFixed(2) }}
    </span>

    <span class="total-od-amount-label">
      <mat-icon>swap_horiz</mat-icon>
      Total Adjustment Amount: ₹ {{ getTotalOdAmount() }}
    </span>
    <span class="remaining-amount">
      <mat-icon class="fa fa-wallet">account_balance_wallet</mat-icon>
      Remaining Amount: ₹ {{ remainingAmount.toFixed(2) }}
    </span>
  </div>

  <div class="actionButton">
    <button class="btn btn-primary" (click)="odAdjustment()">Confirm</button>
  </div>
</div>