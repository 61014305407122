import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../kanban.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { saveAs } from 'file-saver';
import { BulkGtDialogComponent } from '../k-board/bulk-gt-dialog/bulk-gt-dialog.component';

@Component({
  selector: 'app-termsheetlisting',
  templateUrl: './termsheetlisting.component.html',
  styleUrls: ['./termsheetlisting.component.scss']
})
export class TermsheetlistingComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  buyerFile:any;
  boardId:any;
  boardList=[];
  boardName:string;
  totalRecords:number=0;
  displayedColumns: string[] = ['name', 'supplier', 'buyer', 'zipFile'];

  constructor(public _kService: KanbanService, public router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute) { }
  dataSource = new MatTableDataSource();

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.boardId= params.id;
      this.getBoardList();
    });
    this.getSupplierTermsheetListing()
  }

  getBoardList() {
    this._kService.getBoardList().subscribe(res => {
      this.boardList = res['result'];
      this.boardList.map(ele=>{
        if(ele.id == this.boardId){
          this.boardName= ele.name;
        }
      })
    })
  }
  supplierRes=[];
  getSupplierTermsheetListing(event?) {
    let obj = {
      from: this.pageNumber,
      size: this.pageSize
    }
    if(event){
      obj['from']=  event.pageIndex
    }
    this._kService.getSupplierTermsheetListing(obj).subscribe((res:any) => {
      this.supplierRes= res['result'];
      console.log(this.supplierRes)
      this.dataSource.data= this.supplierRes
      this.totalRecords= res['total'];

    })
  }
  resultDownload:any;
 
  downloadfile(id){
    this._kService.downloadSingleLead(id).subscribe((res:any)=>{
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      let fileName = this.resultDownload.replace(/"/g, '')
      saveAs(res.body, fileName);
      return fileName;
    })
    
  }

  onBuyerFileChanged(event){
    this.buyerFile=  event.target.files[0];
    this.fileUpload()
  }
  fileUpload(file?) {
    if (file) {
      this.buyerFile.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.buyerFile, this.buyerFile.name);
    this._kService.uploadBuyerBulkTermsheet(uploadData,this.boardId).subscribe(file => {
       if (file['status'] == 200) {
         this.getSupplierTermsheetListing();

       }
    });
  }
  downloadZipFile(id){
    this._kService.downloadZipLead(id).subscribe(res=>{
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      let fileName = this.resultDownload.replace(/"/g, '')
      saveAs(res.body, fileName);
      return fileName;

    })
  }
  generateTermSheet(){
    const dialogRef = this.dialog.open(BulkGtDialogComponent, {
      width: '480px',
      height: '550px',
      data: this.boardId
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSupplierTermsheetListing();
    });
  }

  getPaginatorData(event){
    console.log(event);
    this.getSupplierTermsheetListing(event)

  }

}
