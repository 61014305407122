<div class="container">
  <div class="heading">
    <div>
      <h4>Approved Buyer List</h4>
    </div>
  </div>
  <div class="actionDiv" *ngIf="!isSalesLOSUser">
    <div class="searchBox">
      <input type="text" class="setinpt" placeholder="Enter Buyer Name" [(ngModel)]="searchBuyerName" (keyup.enter)="searchFilter()">
    </div>

    <div class="searchBox">
      <mat-form-field class="setinpt alignInput">
        <input
          matInput
          placeholder="Search Country"
          [(ngModel)]="searchCountry"
          [matAutocomplete]="auto"
          (keyup)="filterCountries()" 
          (blur)="removeInvalid($event)"
        />
        <mat-autocomplete #auto="matAutocomplete" class="approved-custom-autocomplete">
          <mat-option *ngFor="let country of filteredCountries" [value]="country" >
            {{ country }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    
    
    <div class="searchBox" >
      <mat-select class="setinpt alignInput" placeholder="Select Status" [(ngModel)]="selectedStatus">
        <mat-option value="PENDING">Pending</mat-option>
        <mat-option value="APPROVED">Approved</mat-option>
      </mat-select>
    </div>
    <div class="searchBox">
      <mat-select class="setinpt alignInput" placeholder="Select Source" [(ngModel)]="selectedSource">
        <mat-option value="MANUAL">Manual</mat-option>
        <mat-option value="Auto_TS">Auto Termsheet</mat-option>
        <mat-option value="LOS_TS">LOS_TS</mat-option>
      </mat-select>
    </div>
    <div class="searchBox">
      <mat-select class="setinpt alignInput" placeholder="Select Insurer" [(ngModel)]="selectedInsurer">
        <mat-option *ngFor="let insurer of insurerList" [value]="insurer">{{insurer}}</mat-option>
      </mat-select>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn searchBtn" (click)="searchFilter()">Search</button>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn" (click)="clearFilter()">Clear</button>
    </div>
  </div>
  <div class="actionButton">
    <button *ngIf="!isSalesLOSUser" mat-flat-button class="add-btn" (click)="openAddBuyerForm('newForm')">Add Buyer</button>
    <button *ngIf="!isSalesLOSUser" mat-flat-button class="add-btn" (click)="uploadBulkBuyers()">Add Bulk Buyer</button>
    <button *ngIf="!isSalesLOSUser" mat-flat-button class="add-btn" matTooltip="Export Data"
      (click)="exportBuyersData()"><mat-icon>download</mat-icon></button>
      <button *ngIf="isSalesLOSUser" mat-flat-button class="add-btn" matTooltip="Export Data"
      (click)="exportData()"><mat-icon>download</mat-icon></button>
  </div>
  <div [ngClass]="{'tablelist': isSalesLOSUser}">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice">

    <ng-container matColumnDef="buyerName">
      <th mat-header-cell *matHeaderCellDef>Buyer Name</th>
      <td mat-cell *matCellDef="let element"> <span class="suplierHighlight">
          {{element.buyerName ? element.buyerName : '--'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>Country</th>
      <td mat-cell *matCellDef="let element"> {{element.country ? (element.country) :
        '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"><span [ngClass]="element.status ? getStatusClass(element.status) : ''"> {{element.status ? (element.status | titlecase) : '--'}} </span></td>
    </ng-container>

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let element"><span>{{element.source ? ((element.source == 'MANUAL' || element.source == 'LOS_TS')? element.source: 'Auto Termsheet') : 'Auto Termsheet'}}</span></td>
    </ng-container>

    <ng-container matColumnDef="approvedLimit">
      <th mat-header-cell *matHeaderCellDef>Approved Limit</th>
      <td mat-cell *matCellDef="let element">{{element.limitApproved ? dataService.getCurrencySign(element.currency) :
        ''}} {{element.limitApproved ? element.limitApproved : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="tenorApprovedDays">
      <th mat-header-cell *matHeaderCellDef>Tenor Days</th>
      <td mat-cell *matCellDef="let element"> <span *ngIf="element.tenorApprovedDays">{{element.tenorApprovedDays}} Days
        </span></td>
    </ng-container>

    <ng-container matColumnDef="utilizedAmount">
      <th mat-header-cell *matHeaderCellDef>Utilized Amount</th>
      <td mat-cell *matCellDef="let element">{{element.utilizedAmount ? dataService.getCurrencySign(element.currency) :
        ''}} {{element.utilizedAmount ? element.utilizedAmount : '--'}}</td>
    </ng-container>

    <ng-container matColumnDef="insurer">
      <th mat-header-cell *matHeaderCellDef>Insurer</th>
      <td mat-cell *matCellDef="let element"> {{element.insurer ? element.insurer : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="requestLimit">
      <th mat-header-cell *matHeaderCellDef>Request Limit</th>
      <td mat-cell *matCellDef="let element">{{element.newRequestedLimitAmount ?
        dataService.getCurrencySign(element.currency) : ''}} {{element.newRequestedLimitAmount ?
        element.newRequestedLimitAmount : '--'}}</td>
    </ng-container>

    <ng-container matColumnDef="availableLimit">
      <th mat-header-cell *matHeaderCellDef>Available Limit</th>
      <td mat-cell *matCellDef="let element">{{dataService.getCurrencySign(element.currency)}} {{(element.limitApproved
        - element.utilizedAmount)}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element let i = index">
        <div class="eye-button" *ngIf="authService.isCompanyAdmin()">
          <!-- <button mat-icon-button (click)="openAddBuyerForm('getDetail',element)">
            <mat-icon class="icon_details">edit</mat-icon>
          </button> -->
          <button mat-icon-button [matMenuTriggerFor]="list" #acceptButton (click)="selectedRow(i)">
            <mat-icon class="icon_details">more_vert</mat-icon>
            <mat-menu #list="matMenu" (closed)="menuClosed()">
              <button mat-menu-item (click)="openAddBuyerForm('getDetail',element,i)">Edit Buyer</button>
              <button mat-menu-item (click)="deleteBuyer(element)">Delete</button>
            </mat-menu>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns let i = index;"
      [ngClass]="{'activeRow' : i === selectedIndex}"></tr>
  </table>
  <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="0" [pageSize]="pageSize"
    (page)="getPaginatorData($event)" showFirstLastButtons>
  </mat-paginator>
</div>
