import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reject-escrow',
  templateUrl: './reject-escrow.component.html',
  styleUrls: ['./reject-escrow.component.scss']
})
export class RejectEscrowComponent implements OnInit {
  remark:any ='';
  constructor(
    public dialogRef: MatDialogRef<RejectEscrowComponent>, private toster: ToastrService
  ) { }

  ngOnInit() {
  }
  closedialog(){
    this.dialogRef.close(false);
  }
  RejectInvoice(){
    if(this.remark.trim() && this.remark.trim().length > 0){
      this.dialogRef.close({remark: this.remark});
    }
    else {
      this.toster.error("Please enter remarks")
    }
  }
}
