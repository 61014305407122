import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KBoardComponent } from './k-board/k-board.component';
import { KUserManagementComponent } from './k-user-management/k-user-management.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CardDetailsComponent } from './k-board/board-lane/card-details/card-details.component';
import { TermsheetlistingComponent } from './termsheetlisting/termsheetlisting.component';
import { PreQualifiedDetailsComponent } from './k-board/board-lane/card-details/pre-qualified-details/pre-qualified-details.component';
import { LosTrackerComponent } from './los-tracker/los-tracker.component';
import { ApprovedBuyerListComponent } from './k-board/board-lane/card-details/approved-buyer-list/approved-buyer-list.component';
import { FunnnelDashboardComponent } from './k-board/funnnel-dashboard/funnnel-dashboard.component';
import { LeadDetailsComponent } from './k-board/board-lane/lead-details/lead-details.component';
import { NewLeadsComponent } from './k-board/board-lane/new-leads/new-leads.component';
import { DetailsDocumentsComponent } from './k-board/board-lane/details-documents/details-documents.component';
import { GstAnalysisComponent } from './k-board/board-lane/gst-analysis/gst-analysis.component';
import { UnderwritingDocComponent } from './k-board/board-lane/underwriting-doc/underwriting-doc.component';
import { UnderwritingStageComponent } from './k-board/board-lane/underwriting-stage/underwriting-stage.component';
import { PostSanctionDocComponent } from './k-board/board-lane/post-sanction-doc/post-sanction-doc.component';
import { DocValidationComponent } from './k-board/board-lane/doc-validation/doc-validation.component';
import { CAMSComponent } from './k-board/board-lane/cams/cams.component';
import { CreditTrackerComponent } from './credit-tracker/credit-tracker.component';
const routes: Routes = [
  {
    path: 'credlixboard',
    component: KBoardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'KboardUsermanagement/:id',
    component: KUserManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cardDetails/:id',
    component: CardDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulkListing/:id',
    component: TermsheetlistingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pre-qualified/:id',
    component: PreQualifiedDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'los-tracker',
    component: LosTrackerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'credit-tracker',
    component: CreditTrackerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'funnel-dashboard',
    component: FunnnelDashboardComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'lead-details',
    component: LeadDetailsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'new-leads/:id',
        component: NewLeadsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'application-documents-submitted/:id',
        component: DetailsDocumentsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'gstin-analysis/:id',
        component: GstAnalysisComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'underwriting/:id',
        component: UnderwritingDocComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'underwriting-stage/:id',
        component: UnderwritingStageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'post-sanction-doc',
        component: PostSanctionDocComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'document-validation',
        component: DocValidationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cams',
        component: CAMSComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
  
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]


})
export class CredlixBoardRoutingModule { }
