import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-consent-otp',
  templateUrl: './consent-otp.component.html',
  styleUrls: ['./consent-otp.component.scss']
})
export class ConsentOtpComponent implements OnInit {
  
  constructor(public toasterService: ToastrService, private activatedRoute: ActivatedRoute, 
		public kService: KanbanService) { }

  otp:any = '';
  isOtpValid:boolean = false;
  counter: number = 60; // Initialize counter to 60 seconds
  countdownInterval: any; // Store interval ID
  showThankYou:boolean=false;
  openVerifyOTP: boolean = false;
    token:any='';


  ngOnInit() {
    this.activatedRoute.params.subscribe(x=>this.token = x.id);
    this.generateOTP();  
  }
  generateOTP(){
    this.kService.generateOtp(this.token).subscribe({
      next:(resp:any)=>{
        console.log(resp);
        if(resp.success){
          this.toasterService.success("OTP Sent To Email!");
        }else{
          this.toasterService.error(resp.message);
        }
      },
      complete:()=>{
        this.counter = 60;
        this.countDown()}
    })
  }
  countDown(){
    let timer = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter <= 0) {
        clearInterval(timer);
      }
    }, 1000);//change 500 to 1000
  }

  onOtpKeyDown(digitNumber: number, event: any) {
    // if (this.sharedService.isBrowser) {
      const digitValue = event.target.value;
      const previousDigitNumber = digitNumber - 1;
 
      if (event.key === 'Backspace') {
        if (!digitValue && digitNumber > 1) {
          const previousDigitInput = <HTMLInputElement>document.getElementById(`digit-${previousDigitNumber}`);
          if (previousDigitInput) {
            previousDigitInput.value = '';
            previousDigitInput.focus();
          }
        }
      }
    
  }


  onOtpInputChange(digitNumber: number, event: any): void {
    // if (this.sharedService.isBrowser) {
      const digitValue = event.target.value;
 
      if (digitValue) {
        const nextDigitNumber = digitNumber + 1;
        const nextDigitInput = <HTMLInputElement>document.getElementById(`digit-${nextDigitNumber}`);
        if (nextDigitInput) {
          nextDigitInput.focus();
        }
      }
 
      const digit1 = (<HTMLInputElement>document.getElementById('digit-1')).value? (<HTMLInputElement>document.getElementById('digit-1')).value : '';
      const digit2 = (<HTMLInputElement>document.getElementById('digit-2')).value? (<HTMLInputElement>document.getElementById('digit-2')).value : '';
      const digit3 = (<HTMLInputElement>document.getElementById('digit-3')).value? (<HTMLInputElement>document.getElementById('digit-3')).value : '';
      const digit4 = (<HTMLInputElement>document.getElementById('digit-4')).value? (<HTMLInputElement>document.getElementById('digit-4')).value : '';
      const digit5 = (<HTMLInputElement>document.getElementById('digit-5')).value? (<HTMLInputElement>document.getElementById('digit-5')).value : '';
      const digit6 = (<HTMLInputElement>document.getElementById('digit-6')).value? (<HTMLInputElement>document.getElementById('digit-6')).value : '';
 
      this.otp = digit1 + digit2 + digit3 + digit4 + digit5 + digit6;
      this.isOtpValid = !!(digit1 && digit2 && digit3 && digit4 && digit5 && digit6);
    
  }

  resendOtp(): void {
    this.generateOTP();
  }

  continue(){
    console.log(this.otp);
    console.log(this.token);
    this.kService.verifyOtp(this.token,this.otp).subscribe({
      next:(resp:any)=>{
        if(resp.success){
          this.toasterService.success("OTP Verified Successfully!");
        this.showThankYou = !this.showThankYou;}
        else{
          this.toasterService.error(resp.message);
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

}
