<div class="comment-dialog">
  <i class="ri-close-line closeistoryButton" (click)="closeCommentTab()"></i>
  <div select="[rightPane]">
    <div class="commentsSection">
      <div class="box-border">
        <div class="commentsSection" fxLayout="row">
          <label for="w3review">Add Comment</label>
          <textarea id="commentTextArea" name="commentTextArea" rows="6" cols="55" [(ngModel)]="addTaskComment">
        </textarea>
        </div>
        <div class="cta-box">
          <button class="btnSave" (click)="saveTaskComment()"> <i class="ri-chat-1-line"></i> Comment </button>
          <button class="btnCancel" (click)="cancelComment()"> Discard</button>
        </div>
      </div>
    </div>
    <!-- comment-section -->
    <div class="comment-section" *ngFor="let commentData of totalComments">
      <div class="inner-commnent-box" *ngIf="!this.data.earlyPayment">
        <div class="div-title">
          <span class="customer-short-name">{{commentData.initial}}</span>
          <p class="table-sub-details name-full" style="text-transform: capitalize;">
            {{commentData.byUser.name}}
          </p>
        </div>
        <span class="date-comm">{{commentData.createdAt | date}}</span>
      </div>
      <p class="comment-p" *ngIf="!this.data.earlyPayment">{{commentData.message}}</p>

      <div class="inner-commnent-box" *ngIf="this.data.earlyPayment">
        <div class="div-title">
          <span class="customer-short-name">{{commentData.initial}}</span>
          <p class="table-sub-details name-full" style="text-transform: capitalize;">
            {{commentData.userName}}
          </p>
        </div>
        <span class="date-comm">{{commentData.createdAt | date}}</span>
      </div>
      <p class="comment-p" *ngIf="this.data.earlyPayment">{{commentData.comment}}</p>
    </div>

    <!-- <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;"  >
        <div fxLayout="row"  style="width: 100%;" >
          <div class="sb_table" style="width: 100%; height: 100%;" infinite-scroll
          [infiniteScrollDistance]="5" [infiniteScrollThrottle]="150"
          [scrollWindow]="false" [fromRoot]="true"  (scrolled)="onScrollDown()"  >
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-comment" >
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>All Comments </th>
              <td mat-cell *matCellDef="let element">
                <div class="table-row-content">
                  <div class="div-title">
                    <span class="customer-short-name">CS</span>
                    <p class="table-sub-details name-full" style="text-transform: capitalize;">
                      By {{element?.byUser.name}} | {{element.createdAt | date}}
                    </p>
                  </div>
                  <a class="table-link" (click)="openCardDetails(element)">{{element.message}}</a>

                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>
                <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                  <span>Sort</span>
                  <mat-icon aria-hidden="false" aria-label="Sort" fontIcon="home"
                  matTooltip="Sort By Created At" matTooltipClass="new-tooltip"
                  class="comments-icon" style="display: flex;     justify-content: center;
                  align-items: center;">arrow_downward</mat-icon>
                </div>
                </th>
              <td mat-cell *matCellDef="let element">
              </td>
            </ng-container>


            <ng-container matColumnDef="more">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element"> <i class="ri-more-2-fill"></i></td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>

          </div>
      </div>
     </div> -->
  </div>
</div>
