import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-mark-disbursed-dialog',
  templateUrl: './mark-disbursed-dialog.component.html',
  styleUrls: ['./mark-disbursed-dialog.component.scss']
})
export class MarkDisbursedDialogComponent implements OnInit {


  markdisbursedForm = new FormGroup({});


  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private pageService: PagesService, public dialog: MatDialog,
    private toastService: ToastrService, public dialogRef: MatDialogRef<MarkDisbursedDialogComponent>,) { }

  ngOnInit() {
    this.myFormData();
  }

  myFormData() {
    this.markdisbursedForm = this.fb.group({
      amount: [''],
      invoiceId: this.data,
      loanPaymentType: ['DISBURSAL'],
      paymentDate: [''],
      utr: [''],
    })
  }

  onSubmit() {
    // let reqObj = {
    //   "amount": 696805,
    //   "invoiceId": this.data,
    //   "loanPaymentType": "DISBURSAL",
    //   "paymentDate": "2022-05-23",
    //   "utr": null,
    //   "form": this.markdisbursedForm.value
    // }


    this.pageService.markDisbursed(this.markdisbursedForm.value).subscribe(res => {
      if (res['status'] == 200) {
        this.myFormData();
        this.toastService.success("Successfully disbursed");
        this.dialogRef.close();
      }
    })
  }

  resetForm() {
    this.markdisbursedForm.reset();
  }

  changeFromDatePicker(event, value) {
    let date = moment(event.value).format('YYYY-MM-DD');
    this.markdisbursedForm.controls['paymentDate'].setValue(date);
  }
}

