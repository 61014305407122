import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatPaginator, MatTableDataSource } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';
import { SidService } from '../sid/services/sid.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-ledger-report',
  templateUrl: './ledger-report.component.html',
  styleUrls: ['./ledger-report.component.scss']
})
export class LedgerReportComponent implements OnInit {

  // dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['supplierName','supAddress','buyerName','santLimit', 'utilizedLimt','avLimit','invNo','amount','invDate','dueDate','rateCharges','advAmount','advPercentage','processingFee','totalCharges','netAmount','disbursedAmount','disbursedDate','repaymentAmount','repaymentDate','lenderPayment','supplierPayment','otherDeduction','overDueDays','normalAmount','overDueAmt','outstandingAmount','principle','payRecieved','payDate','pendingBalance','normalIntAmount','balanceOverDueAmt','balPendingPay','balPendingDate','amtRecieved','advancedAmount','intCharged'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  supplierList: string[] = [];
  selectedSupplier = [];
  resultDownload;
  pageSize: number = 25;
  totalRecords: any;
  pageNumber: number = 0;

  constructor(public settingService : SettingService, public sidService : SidService, public pagesService: PagesService, public authService : AuthService , public tosterService : ToastrService) { }

  ngOnInit() {
    this.pagesService.isChangeColor("ledger-white");
    if(this.authService.isSIDRoleType() && !this.authService.isBorrower()){
      this.getSupplierList();
    }
    let userInfo = JSON.parse(localStorage.getItem('USERINFO'));
    let masterAccId = userInfo['role']['roleId']
    if(this.authService.isSIDRoleType() && this.authService.isBorrower()){
      this.selectedSupplier.push(masterAccId);
      this.getLedgerReport();
    }

  }

  getSupplierList() {
    let reqObj = {}
    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID')
    }
    reqObj['indexName'] = "credlix_sid_suppliers";
    this.sidService.supplierSearch(reqObj).subscribe(res => {
      this.supplierList = res['result'];
    })
  }
  getLedgerReport() {
    let obj = {}
    obj['ids'] = this.selectedSupplier;
    obj['from'] = this.paginator.pageIndex
    obj['size'] = this.pageSize
    if(this.selectedSupplier && this.selectedSupplier.length > 0){
    this.settingService.getLedgerReport(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.dataSource.data = res['result'];
        this.totalRecords = res['total'];
      }
    })
    }
  }
  getPaginatorData1(event) {
    this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
    this.getLedgerReport();
  }

  getClass(val){
    if(val == 'invoice'){
      return 'invoice'
    } 
    if(val == 'buyerDetails'){
      return 'buyerDetails'
    }
    if(val == 'advance'){
      return 'advance'
    }
    if(val == 'buyerPayment'){
      return 'buyerPay'
    }
    if(val == 'balance'){
      return 'balance'
    }
    if(val == 'summary'){
      return 'summary'
    }
  }
  onSupplierChange(val){
  this.selectedSupplier = val;
  }
  applyButton(){
    this.getLedgerReport();
  }
  downloadLedgerReport(){
    this.settingService.downloadLedgerReport(this.selectedSupplier).subscribe(res =>{
      this.getLedgerFile(res);
      this.tosterService.success("Downloaded Successfully");
    })
  }
  getLedgerFile(res){
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
     let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }

  calculateOthCharges(element){
    let otherDeduction = ((element.invoiceAmount) - (element.repayment ? element.repayment.amount : 0))
    return otherDeduction;
  }
  calculateOutstandingAmt(element){
    let outStandingAmt = ((element.advanceAmount) - (element.repayment ? element.repayment.amount : 0))
    return outStandingAmt;
  }
  calTotoalAmt(element){
    let totalAmtRecieved = ((element.repayment ? element.repayment.amount : 0) + (element.balanceRepayment ? element.balanceRepayment.amount : 0))
    return totalAmtRecieved;
  }

}
