import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';

@Component({
  selector: 'app-pre-qualified-lane-move',
  templateUrl: './pre-qualified-lane-move.component.html',
  styleUrls: ['./pre-qualified-lane-move.component.scss']
})
export class PreQualifiedLaneMoveComponent implements OnInit {

  selectedAssigneId: any;
  myControl = new FormControl("");
  assigneeList: any = [];
  allUserList = [];
  filteredUserList: Observable<string[]>;
  taskRemark: string;

  constructor(public _kService: KanbanService,private dialogRef: MatDialogRef<PreQualifiedLaneMoveComponent>,@Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.getAssigneeList();
  }
  getAssigneeList() {
    this._kService.getKanbanUserList().subscribe(list => {
      if (list['status'] == 200) {
        this.assigneeList = list['result'];
        this.getUserListResponse();
      }
    })
  }
  getUserListResponse() {
    this.assigneeList.forEach(ele => {
      this.allUserList.push(ele.userName)
    })
    this.filteredUserList = this.myControl.valueChanges.pipe(
      startWith(null),
      map((userValue: string | null) => userValue ? this._filterUserList(userValue) : this.allUserList.slice()));
  }
  private _filterUserList(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allUserList.filter(user => user.toLowerCase().indexOf(filterValue) >= 0);
  }
  closeDialog(){
    this.dialogRef.close(false)
  }
  saveBuyerDataRow(){
    let req = {}
    for (let i = 0; i < this.assigneeList.length; i++) {
      if (this.selectedAssigneId == this.assigneeList[i].userName) {
        req['id'] = this.assigneeList[i].userId
      }
    }
    this.dialogRef.close(req)
  }

}
