import { Component, HostBinding, OnInit, Inject } from '@angular/core';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-create-okr',
  templateUrl: './create-okr.component.html',
  styleUrls: ['./create-okr.component.scss']
})
export class CreateOkrComponent implements OnInit {
  @HostBinding('class') class = 'height100vh';
  public keyValueForm: FormGroup;
  myControl = new FormControl('');
  totalTarget = 0;
  isDisabledGoal: boolean = false;
  OKRForm: FormGroup;
  memberTarget:any;
  constructor(public dialogRef: MatDialogRef<CreateOkrComponent>, public _kService: KanbanService, public _dashboardService: DashboardService,
    public toasterService: ToastrService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.keyValueForm = this.formBuilder.group({
      details: this.formBuilder.array([])
    });
    this.OKRForm = this.formBuilder.group({
      OKRGoalName: ['', Validators.required],
      OKRGoalDescription: [''],
      OKRGoalTrack: ['',Validators.required],
      OKRStartOn: ['', Validators.required],
      OKRDueOn: ['', Validators.required]
    });
    console.log(data);
    if (data != null && data.type && data.type == 'update') {
      this.isDisabledGoal = true;
      this.setFormDataValue();
      this.setValue(data['data']['okrGoalSettings']);
    }
    else if(data != null && data.type && data.type == 'duplicate'){
      this.isDisabledGoal = false;
      this.setFormDataValue();
      this.setValue(data['data']['okrGoalSettings']);
    }
  }

  ngOnInit() {
    this.getBoardList();
    this.getUserList();
    this.getOKRGoalBasedUponCategory();
    this.calculateTotalTarget();
  }
  setValue(arrayData) {
    let detailsTempArrray = this.keyValueForm.get("details") as FormArray;
    arrayData.forEach((x) => {
      detailsTempArrray.push(this.formBuilder.group({
        keys: x.user.id,
        values: x.target
      }));
    });
    this.customInfo = this.keyValueForm.get('details') as FormArray;
  }

  setFormDataValue() {
    this.goalName = this.data['data'].okrName,
      this.goalDescription = this.data['data'].goalDescription,
      this.userGoalTrack = this.data['data'].goalName,
      this.startDate = moment(this.data['data'].startDate).format('YYYY-MM-DD');
    this.endDate = moment(this.data['data'].endDate).format('YYYY-MM-DD');
  }
  userList = [];
  boardList = [];
  boardId: any;
  getBoardList() {
    this._kService.getBoardList().subscribe(res => {
      this.boardList = res['result'];
      this.boardList.map(val => {
        if (val.type == 'EXIM') {
          this.boardId = val.id
        }
      })
    })
  }

  getUserList() {
    this._kService.getKanbanUserList().subscribe(list => {
      if (list['status'] == 200) {
        this.userList = list['result'];
      }
    })
  }

  userGoalTrack: any;
  getGoalTrack(value) {
    this.userGoalTrack = value;
    console.log(this.userGoalTrack)
  }
  startDate: any;
  endData: any;
  getStartDate(event) {
    let Start = event.value
    this.startDate = moment(Start).format('YYYY-MM-DD');
  }
  endDate: any;
  getEndDate(event) {
    let Start = event.value
    this.endDate = moment(Start).format('YYYY-MM-DD');
  }

  customInfo: any;
  addGoalMember() {
    this.customInfo = this.keyValueForm.get('details') as FormArray;
    this.customInfo.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      keys: ['', Validators.required],
      values: ['', [Validators.required, Validators.pattern('^[1-9][0-9]*$')]]
    });
  }
  goalName: any;
  goalDescription: any;
  createOkr() {
    let finalData = []
    this.keyValueForm.value['details'].forEach((i) => {
      finalData.push(`{${i['keys']}: ${i['values']}}`)
    })
    var result = this.keyValueForm.value['details'].reduce(function (map, obj) {
      map[obj.keys] = obj.values;
      return map;
    }, {});
    let obj = {
      "endDate": this.endDate,
      "goalName": this.userGoalTrack,
      "startDate": this.startDate,
      "usersAndLimits": result,
      "okrName": this.goalName,
      "goalDescription": this.goalDescription,
      "boardId": this.boardId,
    }
    let URL: any;
    if (this.data != null && this.data.type && this.data.type == 'update') {
      obj['id'] = this.data['data'].id
      URL = this._dashboardService.updateOKR(obj)
      this.deleteGoalRow('', '', true);
    }
    else {
      URL = this._dashboardService.createUserOkr(obj);
    }
    URL.subscribe(res => {
      if (res['status'] == 200) {
        if (this.data != null && this.data.type && this.data.type == 'update') {
        this.toasterService.success("OKR update successfully");
        }
        else if(this.data != null && this.data.type && this.data.type == 'duplicate'){
          this.toasterService.success("Duplicate OKR Create successfully");
        }
        else {
          this.toasterService.success("OKR Create successfully");
        }
        this.dialogRef.close(true)
      }
    })
  }
  deleteGoalMember = []
  deleteGoalRow(ind, field, value) {
    if (value == true) {
      let obj = {
        users: this.deleteGoalMember
      }
      console.log(obj)
      this._dashboardService.deleteOkRMember(this.data['data'].id, this.deleteGoalMember).subscribe(res => {
      })
    }
    else{
      this.deleteGoalMember.push(field['value'].keys)
      this.customInfo.removeAt(ind);
    }
    this.calculateTotalTarget();
  }
  getAllOKRGOals = []
  getOKRGoalBasedUponCategory() {
    this._dashboardService.getOKRGoals().subscribe(res => {
      this.getAllOKRGOals = res['result'];

    })
  }
  targetValidate(event){
    if(this.targetvalue <=0){
      this.toasterService.error("Targets should be greater then 0")
    }
  }
  targetvalue:any
  getTarget(event){
    this.targetvalue=event

  }
  isCheckDisable(value){
    if (this.data != null && this.data.type && this.data.type == 'update') {
   for (let i=0; i<this.data['data']['okrGoalSettings'].length; i++){
     if(this.data['data']['okrGoalSettings'][i].user.id == value['value'].keys){
      return true
     }
   }
  }
  }
  calculateTotalTarget() {
    let i = 0;
    if (this.keyValueForm.value['details'].length) {
      this.keyValueForm.value['details'].forEach((item) => {
        i += +item.values;
        this.totalTarget = i;
      });
    }
    else {
      this.totalTarget = 0
    }
  }
}

