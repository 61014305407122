
<div mat-dialog-content>
  <h1 class="modal-heading" mat-dialog-title *ngIf="!isDisabledGoal && !data.isDuplicate">Create OKR</h1>
  <h1 class="modal-heading" mat-dialog-title *ngIf="isDisabledGoal && !data.isDuplicate">EDIT OKR</h1>
  <h1 class="modal-heading" mat-dialog-title *ngIf="!isDisabledGoal && data.isDuplicate">DUPLICATE OKR</h1>
  <form [formGroup]="OKRForm">
  <div class="mat-field">
    <label>Goal Name*</label>
    <mat-form-field appearance="outline" class="mog3_matinput w-100">
      <input matInput type="text" placeholder="Enter Goal Name" [(ngModel)]="goalName" formControlName="OKRGoalName">
  </mat-form-field>
  </div>
  <div class="mat-field">
    <label>Goal Description </label>
    <mat-form-field appearance="outline" class="mog3_matinput w-100">
      <input matInput type="text" placeholder="Enter Goal Description" [(ngModel)]="goalDescription" formControlName="OKRGoalDescription">
  </mat-form-field>
  </div>
  <div class="mat-field">
  <label>Goal to track*</label>
  <mat-form-field appearance="outline" class="mog3-matselect w-100" >
    <mat-select placeholder="Select Goal to track" (selectionChange)="getGoalTrack($event.value)" [disabled] ='isDisabledGoal'
     [(ngModel)]="userGoalTrack" formControlName="OKRGoalTrack">
      <mat-option *ngFor="let goal of getAllOKRGOals" [value]="goal">{{goal}}</mat-option>
    </mat-select>
</mat-form-field>
</div>
<div class="custom-row" *ngIf="!data.isDuplicate">
  <div class="custom-col-6">
<div class="mat-field">
  <label>Start On*</label>
<mat-form-field appearance="fill" class="date-picker-field">
  <input matInput [matDatepicker]="picker" placeholder="Select Start Date" readonly formControlName="OKRStartOn" [(ngModel)]="startDate"
   (dateChange)="getStartDate($event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
</div>
</div>
<div class="custom-col-6">
  <div class="mat-field">
    <label>Due On*</label>
  <mat-form-field appearance="fill" class="date-picker-field ">
    <input matInput [matDatepicker]="picker1" placeholder="Select Due Date" readonly formControlName="OKRDueOn" [(ngModel)] ="endDate"
     (dateChange)="getEndDate($event)">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  </div>
  </div>
</div>
<div class="custom-row" *ngIf="data.isDuplicate">
  <div class="custom-col-6">
<div class="mat-field">
  <label>Start On*</label>
<mat-form-field appearance="fill" class="date-picker-field">
  <input matInput [matDatepicker]="picker" placeholder="Select Start Date" readonly formControlName="OKRStartOn"
   (dateChange)="getStartDate($event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
</div>
</div>
<div class="custom-col-6">
  <div class="mat-field">
    <label>Due On*</label>
  <mat-form-field appearance="fill" class="date-picker-field ">
    <input matInput [matDatepicker]="picker1" placeholder="Select Due Date" readonly formControlName="OKRDueOn"
     (dateChange)="getEndDate($event)">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  </div>
  </div>
</div>
</form>
<div class="row-height-fix">
<div class="custom-row mb-0">
  <div class="custom-col-6">
     <h2 class="modal-sub-heading"><strong> Members Responsible* </strong></h2>
    </div>
    <div class="custom-col-6 pl-0">
      <h2 class="modal-sub-heading"><strong> Target*</strong></h2>
    </div>
    </div>
    <ng-container> 
      <form [formGroup]="keyValueForm">
        <div class="form-row input-group-1 cb_customrow" formArrayName="details"
          *ngFor="let field of keyValueForm.get('details')?.controls; let ind = index;">
          <ng-container [formGroupName]="ind">
            <div class="row">
              <div class="form-group w-40 cb_cinput">
                <mat-form-field appearance="outline" class="mog3-matselect w-100">
                  <mat-select placeholder="Select Member" formControlName="keys" [disabled]="isCheckDisable(field)">
                    <mat-option *ngFor="let user of userList" [value]="user.userId">{{user.userName}}</mat-option>
                  </mat-select>
              </mat-form-field>
              </div>
              <div class="form-group w-40 cb_cinput">
                <input type="number" class="form-control customLabels" 
                (input)="calculateTotalTarget()"
                formControlName="values" value="memberTarget" (ngModelChange)="getTarget($event)" (focusout) ="targetValidate(memberTarget)"
                  placeholder="Value">
              </div>
              <mat-icon class="closeIcn" (click)="deleteGoalRow(ind, field, false)">close</mat-icon>
            </div>
          </ng-container>
        </div>
      </form>

  </ng-container>

  </div>
  <button class="add-cta" mat-button color="primary" (click)="addGoalMember()"><mat-icon aria-hidden="false" fontIcon="add_circle">add_circle</mat-icon>Add Member </button>
</div>

<div mat-dialog-actions>
  <h3>Total Target</h3>
  <h3>{{totalTarget}}</h3>
  <div>
    <button  class="cancel-btn" mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button cdkFocusInitial color="warn" (click)="createOkr()" 
    [disabled]="(!OKRForm.valid || !keyValueForm.valid || totalTarget ==0) && !isDisabledGoal">Submit</button>
  </div>

</div>