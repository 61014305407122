import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-requested',
  templateUrl: './payment-requested.component.html',
  styleUrls: ['./payment-requested.component.scss']
})
export class PaymentRequestedComponent implements OnInit {
  getUTRNumber: any;
  UTR: any;
  constructor(public dialogRef: MatDialogRef<PaymentRequestedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pagesService: PagesService, public toasterService: ToastrService) {
      console.log("payment", data);
    this.getUTRNumber = data

  }

  ngOnInit() {


  }

  addUTRNumber(value) {
    let obj = {
      id: this.getUTRNumber,
      status: 'PAID',
      utr: value
    }
    this.pagesService.customerMarkPaid(obj).subscribe(res => {
      this.dialogRef.close();
      this.pagesService.offSpinner();
     
    })

  }

}
