<h2 mat-dialog-title> Remarks() </h2>
<mat-dialog-content>
  
    <div>
        <div class="remarksWrap d-flex justify-between mb-10">
          <div class="left">
            <span>A</span>
          </div>
  
          <div class="right flex-wrap al-center">

            <div class="d-flex justify-between remark-head">
              <div class="heading">
                <p>Ashish Kumar</p>
              </div>
  
              <div class="ml-15 grey">
                <p>06 Mar 2024 at 14:30 PM</p>
              </div>

            </div>

            <div>
              <p>Bank statement of the customer is not up to date reupload </p>
            </div>
          </div>
        </div>
    </div>

    <div class="commentbox">
      <div class="d-flex">
        <input type="text" id="comment" name="comment" class="comment-box" placeholder="Add comment" required>
        <button class="add-btn"><i class="ri-send-plane-2-line"></i></button>
      </div>
    </div>
  
</mat-dialog-content>

<button mat-dialog-close class="cancel" (click)="closeDialog()"><i class="ri-close-line"></i></button>

