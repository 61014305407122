import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-sanction-doc',
  templateUrl: './post-sanction-doc.component.html',
  styleUrls: ['./post-sanction-doc.component.scss']
})
export class PostSanctionDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
