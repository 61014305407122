<div class="container">
  <h4>Commission Invoices</h4>
  <div fxLayout="row"  style="display: flex;justify-content: space-between; float: right;">
    <div class="searchBox">
      <input type="number" class="setinpt" placeholder="Search Invoice Number" [(ngModel)]="searchValue">
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn searchBtn" (click)="search(searchValue)">Search</button>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn" (click)="getCiData()">Clear</button>
    </div>
    <div class="searchBox">
      <button mat-flat-button class="appbtn" style="left: 10px;" (click)="downloadCI(true)" title="Download CI by Month">Download by Month</button>
    </div>
    <div>
      <mat-icon title="Download CI" (click)="downloadCI(false)">download</mat-icon>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table_invoice">

    <ng-container matColumnDef="invoiceNumber">
      <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
      <td mat-cell *matCellDef="let element" (click)="navigateToCommissionDetails(element)"> <span
          class="suplierHighlight">
          {{element.invoiceNumber}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="searchKey">
      <th mat-header-cell *matHeaderCellDef>Supplier ID</th>
      <td mat-cell *matCellDef="let element"> {{element.supplierAccount.searchKey}} </td>
    </ng-container>

    <ng-container matColumnDef="businessName">
      <th mat-header-cell *matHeaderCellDef>Business Name</th>
      <td mat-cell *matCellDef="let element"> {{element.supplierAccount.businessName | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="totalValueWithoutTax">
      <th mat-header-cell *matHeaderCellDef>Total Value Without Tax</th>
      <td mat-cell *matCellDef="let element">{{element.totalValueWithoutTax | number :'1.0-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="totalValue">
      <th mat-header-cell *matHeaderCellDef>Total Value</th>
      <td mat-cell *matCellDef="let element"> {{element.totalValue |number :'1.0-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> 
        <span class="eye-button">
          <i class="ri-eye-fill" title="View Commission Invoice"
                (click)="viewCommissionInvoices(element)"></i>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" [pageSizeOptions]="[25,50,75,100]" (page)="getPaginatorData1($event)" showFirstLastButtons>
    >
  </mat-paginator>

</div>