import { Component, OnInit, ViewChild } from '@angular/core';
import { CreditTrackerCommentComponent } from './credit-tracker-comment/credit-tracker-comment.component';
import * as moment from 'moment';
import { PagesService } from 'src/app/pages/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator } from '@angular/material';
import { KanbanService } from '../kanban.service';
import { DataService } from 'src/app/data.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credit-tracker',
  templateUrl: './credit-tracker.component.html',
  styleUrls: ['./credit-tracker.component.scss']
})
export class CreditTrackerComponent implements OnInit {

  displayedColumns = ['leadId', 'leadName', 'leadSource', 'buyerData', 'annualTurnOver', 'funding', 'transaction', 'region', 'rm', 'creditAnalyst', 'leadUnderwriter', 'milestone', 'ageing', 'created', 'probability', 'reviveDate', 'revivalReason', 'actionOn', 'ActionDueDate', 'latestComment', 'goLiveMonth', 'comment'];
  dataSource: any = [];
  laneId: string;
  searchText: string;
  pageSize = 50;
  pageNo = 0;
  laneNo: string;
  totalRecords: number;
  selectedIndex: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  insurerList = [];
  leadId: string;
  region: any;
  startDate: Date;
  endDate: Date;
  source: any;
  selectedSources: string[] = [];
  selectedRegions: string[] = [];
  selectedCurrentMilestones: string[] = [];
  selectedTransactionTypes: string[] = [];
  selectedRelationshipManagers: string[] = [];
  selectedLeadUnderwritting: string[] = [];
  selectedcreditAnalyst: string[] = [];
  currentMilestone: string;
  transactionType: string;
  suggestions: any;
  isSeachEnable: boolean = false;
  isCreatedAt: boolean;
  transactionTypeList: any = [
    "Post-ship",
    "Pre-ship",
    "Import Factoring",
    "Term Loan",
  ];
  mileStoneList = [];
  leadsSourceList = [
    "Inside Sales",
    "Direct Calling",
    "Marketing/Inbound",
    "Sponsored Event/Assoc",
    "Event & Exhibition",
    "CP Polytrade",
    "CP RXIL",
    "CP VTX",
    "SOB",
    "CP Banks",
    "Reference",
    "Trade reboot",
    "Others"
  ];

  relationshipManager: any;
  filteredAssigneeList: any;
  buyerDataSource: any;
  isfilterVisible: boolean = false;
  isLeadNameFormat: any = false;
  isAnnualTurnOverFormat: any = false;
  isFundingRequirmentFormat: any = false;
  isGoLiveMonthFormat: any = false;
  isActionDueDateFormat: boolean = false;
  leadFilterdObject: any = {};
  exportDataSource: any = [];
  rmUserList: any;
  assigneeList: any;
  isCurrentMilestone: boolean;
  creditUserList: any;
  constructor(public pagesService: PagesService,
    public router: Router, public activateRoute: ActivatedRoute,
    public dialog: MatDialog, public kanbanService: KanbanService,
    public dataService: DataService, public tostrService: ToastrService,
    private csvExportService: CsvExportService) { }

  ngOnInit() {
    this.getInsurerList();
    this.getLeadsList();
    this.getRMList();
    this.getAssigneeList();
    this.getMileStoneList();
    this.getCreditAnalystList();
    this.pagesService.isChangeColor("white_doc_cb");
    this.activateRoute.params.subscribe(param => {
      this.laneId = param.id;
      this.laneNo = param.displayId;
    });
  }

  getAssigneeList() {
    this.kanbanService.getUserList().subscribe(list => {
      this.assigneeList = list['result'];
    });
  }
  getMileStoneList() {
    this.kanbanService.getMileStoneListForCredit().subscribe(res => {
      this.mileStoneList = res['result'];
    })
  }

  applyFilter() {
    this.getLeadsList();
  }
  get payload() {
    let payload: any = {}
    payload.where = {}
    payload.pageNo = this.pageNo;
    payload.size = this.pageSize;
    payload.rangeCriteria = "createdAt",
      payload.autoSuggest = false;
    if (this.startDate) {
      payload.startDate = this.startDate ? new Date(this.startDate) : null
    }
    if (this.endDate) {
      payload.endDate = this.endDate ? new Date(this.endDate) : null
    }
    payload.where.value = []
    if (this.leadFilterdObject.leadFilter) {
      let obj = {
        "field": "name",
        "value": null,
        "sortBy": this.leadFilterdObject.leadFilter ? this.leadFilterdObject.leadFilter : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.selectedRegions.length > 0) {
      let obj = {
        "field": "leadRegion",
        "value": this.selectedRegions.length > 0 ? this.selectedRegions : null,
      }
      payload.where.value.push(obj);
    }
    if (this.selectedSources.length > 0) {
      let obj = {
        "field": "leadSource",
        "value": this.selectedSources.length > 0 ? this.selectedSources : null,
        "sortBy": ""
      }
      payload.where.value.push(obj);
    }
    if (this.selectedCurrentMilestones.length > 0 || this.leadFilterdObject.currentMileStone) {
      let obj = {
        "field": "currentMilestone.$id",
        "value": this.selectedCurrentMilestones.length > 0 ? this.selectedCurrentMilestones : null,
        "sortBy": this.leadFilterdObject.currentMileStone ? this.leadFilterdObject.currentMileStone : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.selectedTransactionTypes.length > 0) {
      let obj = {
        "field": "transactionType",
        "value": this.selectedTransactionTypes.length > 0 ? this.selectedTransactionTypes : null,
        "sortBy": ""
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.goLiveMonth) {
      let obj = {
        "field": "goLiveMonth",
        "value": null,
        "sortBy": this.leadFilterdObject.goLiveMonth ? this.leadFilterdObject.goLiveMonth : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.fundingRequirement) {
      let obj = {
        "field": "fundingRequire",
        "value": null,
        "sortBy": this.leadFilterdObject.fundingRequirement ? this.leadFilterdObject.fundingRequirement : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.annualTurnOver) {
      let obj = {
        "field": "annualTurnOver",
        "value": null,
        "sortBy": this.leadFilterdObject.annualTurnOver ? this.leadFilterdObject.annualTurnOver : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.selectedRelationshipManagers.length > 0) {
      let obj = {
        "field": "relationshipManager.$id",
        "value": this.selectedRelationshipManagers.length > 0 ? this.selectedRelationshipManagers : null,
        "sortBy": null
      }
      payload.where.value.push(obj);
    }
    if (this.selectedcreditAnalyst.length > 0) {
      let obj = {
        "field": "creditAnalyst.$id",
        "value": this.selectedcreditAnalyst.length > 0 ? this.selectedcreditAnalyst : null,
        "sortBy": null
      }
      payload.where.value.push(obj);
    }
    if (this.selectedLeadUnderwritting.length > 0) {
      let obj = {
        "field": "leadUnderwriter.$id",
        "value": this.selectedLeadUnderwritting.length > 0 ? this.selectedLeadUnderwritting : null,
        "sortBy": null
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.actionDueDate) {
      let obj = {
        "field": "actionDueDate",
        "value": null,
        "sortBy": this.leadFilterdObject.actionDueDate ? this.leadFilterdObject.actionDueDate : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.actionUser) {
      let obj = {
        "field": "actionUser",
        "value": null,
        "sortBy": this.leadFilterdObject.actionUser ? this.leadFilterdObject.actionUser : undefined
      }
      payload.where.value.push(obj);
    }
    if (this.leadFilterdObject.createdAt) {
      let obj = {
        "field": "milestoneUpdatedDate",
        "value": null,
        "sortBy": this.leadFilterdObject.createdAt ? this.leadFilterdObject.createdAt : undefined
      }
      payload.where.value.push(obj);
    }
    if (Object.keys(payload.where).length === 0) delete payload.where;
    return payload
  }
  getLeadsList() {
    this.kanbanService.searchLeadsForCredit(this.payload).subscribe(data => {
      if (data['status'] == 200) {
        this.dataSource = [].concat(data['result']);
        this.totalRecords = data['count'];
      }
    });
  }

  createCsvDocumentFile(tableData) {
    this.exportDataSource = [];
    tableData.forEach((element) => {
      let object = {
        "Lead Id ": element.displaySequence ? element.displaySequence : 'N/A',
        "leadName": element.displayName ? '"' + element.displayName + '"' : 'N/A',
        "leadSource": element.leadSource ? element.leadSource : 'N/A',
        "AnnualTurnover": element.annualTurnOver ? element.annualTurnOver : 'N/A',
        "FundingRequirment": element.fundingRequirement ? element.fundingRequirement : 'N/A',
        "Transaction Type": element.transactionType ? element.transactionType : 'N/A',
        "region": element.region ? element.region : 'N/A',
        "RM": element.relationshipManager.name ? element.relationshipManager.name : 'N/A',
        "currentMilestone": element.currentMilestone ? element.currentMilestone.sequenceNumber + '. ' + element.currentMilestone.name : 'N/A',
        "MilestoneAgeing": element.milestoneUpdatedDate ? this.dayBetween(element.milestoneUpdatedDate) : 'N/A',
        "createdDate": element.createdAT ? '"' + moment(element.createdAT).format('DD-MM-YYYY HH:mm:ss') + '"' : 'N/A',
        "probality": element.probability ? element.probability : 'N/A',
        "revivalDate": element.revivedDate ? '"' + moment(element.revivedDate).format('DD-MM-YYYY HH:mm:ss') + '"' : 'N/A',
        "goLive Month": element.goLiveMonth ? moment(element.goLiveMonth).format('MMM-YYYY') : 'N/A',
        "revivalReason": element.revivalReason ? element.revivalReason : 'N/A',
        "MilestoneUpdatedDate": element.milestoneUpdatedDate ? '"' + moment(element.milestoneUpdatedDate).format('DD-MM-YYYY HH:mm:ss') + '"' : 'N/A'
      };
      this.exportDataSource.push(object);
    });
    this.csvExportService.downloadFile(this.exportDataSource, 'downloaded-data');
  }


  getPaginatorData(event) {
    this.pageNo = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getLeadsList();
  }
  selectedRow(index) {
    this.selectedIndex = index;
  }
  menuClosed() {
    this.selectedIndex = null;
  }

  downloadCSV() {
    this.getAllLeadsData();
  }

  getAllLeadsData() {
    const obj =
    {
      "autoSuggest": false,
      "where": {
        "value": [
          {
            "field": "name",
            "value": null,
            "sortBy": this.leadFilterdObject.leadFilter ? this.leadFilterdObject.leadFilter : undefined
          },
          {
            "field": "leadRegion",
            "value": this.region ? this.region : null,
          },
          {
            "field": "leadSource",
            "value": this.source ? this.source : null,
            "sortBy": ""
          },
          {
            "field": "currentMilestone.$id",
            "value": this.currentMilestone ? this.currentMilestone : null,
            "sortBy": this.leadFilterdObject.currentMileStone ? this.leadFilterdObject.currentMileStone : undefined
          },
          {
            "field": "transactionType",
            "value": this.transactionType ? this.transactionType : null,
            "sortBy": ""
          },
          {
            "field": "goLiveMonth",
            "value": null,
            "sortBy": this.leadFilterdObject.goLiveMonth ? this.leadFilterdObject.goLiveMonth : undefined
          },
          {
            "field": "fundingRequire",
            "value": null,
            "sortBy": this.leadFilterdObject.fundingRequirement ? this.leadFilterdObject.fundingRequirement : undefined
          },
          {
            "field": "annualTurnOver",
            "value": null,
            "sortBy": this.leadFilterdObject.annualTurnOver ? this.leadFilterdObject.annualTurnOver : undefined
          },
          {
            "field": "relationshipManager.$id",
            "value": this.relationshipManager ? this.filteredAssigneeList.filter(assignee => assignee.userName === this.relationshipManager)
              .map(assignee => assignee.userId)[0] : null,
            "sortBy": null
          }
        ]
      },
      "startDate": this.startDate ? new Date(this.startDate) : null,
      "endDate": this.endDate ? new Date(this.endDate) : null,
      "rangeCriteria": "createdAt",
      "pageNo": 0,
      "size": 4000
    }
    this.kanbanService.searchLeadsForCredit(obj).subscribe(data => {
      if (data['status'] == 200) {
        this.createCsvDocumentFile(data['result']);
      }
    });
  }

  private searchDebounce: any = null;
  searchFilter() {
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }
    this.searchDebounce = setTimeout(() => {
      this.getSerchData();
    }, 1000);
  }

  getCurrencySymbol(currencyCode: string): string {
    const currencyMap: { [key: string]: string } = {
      INR: '₹',
      EURO: '€',
      USD: '$',
      GBP: '£',
    }
    return currencyMap[currencyCode.toUpperCase()] || '';
  }

  leadNameFormat() {
    this.isLeadNameFormat = !this.isLeadNameFormat
    this.leadFilterdObject.leadFilter = this.isLeadNameFormat ? "desc"
      : this.isLeadNameFormat == false ? "asc" : undefined;
    if (this.isLeadNameFormat) {
      this.isAnnualTurnOverFormat = undefined;
      this.isFundingRequirmentFormat = undefined;
      this.isGoLiveMonthFormat = undefined;
      this.isActionDueDateFormat = false;
      this.isCurrentMilestone = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.fundingRequirement = false;
      this.leadFilterdObject.goLiveMonth = false;
      this.leadFilterdObject.annualTurnOver = false
      this.leadFilterdObject.actionDueDate = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }
  annualTurnOverFormat() {
    this.isAnnualTurnOverFormat = !this.isAnnualTurnOverFormat;
    this.leadFilterdObject.annualTurnOver = this.isAnnualTurnOverFormat ? "desc"
      : this.isAnnualTurnOverFormat == false ? "asc" : undefined;
    if (this.isAnnualTurnOverFormat) {
      this.isLeadNameFormat = false;
      this.isFundingRequirmentFormat = false;
      this.isGoLiveMonthFormat = false;
      this.isActionDueDateFormat = false;
      this.isCurrentMilestone = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.fundingRequirement = null;
      this.leadFilterdObject.goLiveMonth = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.actionDueDate = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }
  fundingRequirmentFormat() {
    this.isFundingRequirmentFormat = !this.isFundingRequirmentFormat;
    this.leadFilterdObject.fundingRequirement = this.isFundingRequirmentFormat ? "desc"
      : this.isFundingRequirmentFormat == false ? "asc" : undefined;
    if (this.isFundingRequirmentFormat) {
      this.isLeadNameFormat = false;
      this.isGoLiveMonthFormat = false;
      this.isAnnualTurnOverFormat = false;
      this.isActionDueDateFormat = false;
      this.isCurrentMilestone = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.annualTurnOver = null;
      this.leadFilterdObject.goLiveMonth = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.actionDueDate = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }
  goLiveMonthFormatFormat() {
    this.isGoLiveMonthFormat = !this.isGoLiveMonthFormat;
    this.leadFilterdObject.goLiveMonth = this.isGoLiveMonthFormat ? "desc"
      : this.isGoLiveMonthFormat == false ? "asc" : undefined;
    if (this.isGoLiveMonthFormat) {
      this.isLeadNameFormat = false;
      this.isFundingRequirmentFormat = false;
      this.isAnnualTurnOverFormat = false;
      this.isActionDueDateFormat = false;
      this.isCurrentMilestone = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.annualTurnOver = null;
      this.leadFilterdObject.fundingRequirement = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.actionDueDate = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }

  actionDueDateFormat() {
    this.isActionDueDateFormat = !this.isActionDueDateFormat;
    this.leadFilterdObject.actionDueDate = this.isActionDueDateFormat ? "asc"
      : this.isActionDueDateFormat == false ? "desc" : undefined;
    if (this.isActionDueDateFormat) {
      this.isLeadNameFormat = false;
      this.isFundingRequirmentFormat = false;
      this.isAnnualTurnOverFormat = false;
      this.isGoLiveMonthFormat = false;
      this.isCurrentMilestone = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.annualTurnOver = null;
      this.leadFilterdObject.fundingRequirement = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.goLiveMonth = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }

  formatCurrentMileStone() {
    this.isCurrentMilestone = !this.isCurrentMilestone;
    this.leadFilterdObject.currentMileStone = this.isCurrentMilestone ? "asc"
      : this.isCurrentMilestone == false ? "desc" : undefined;
    if (this.isCurrentMilestone) {
      this.isLeadNameFormat = false;
      this.isActionDueDateFormat = false;
      this.isFundingRequirmentFormat = false;
      this.isAnnualTurnOverFormat = false;
      this.isGoLiveMonthFormat = false;
      this.isCreatedAt = false;

      this.leadFilterdObject.createdAt = null;
      this.leadFilterdObject.annualTurnOver = null;
      this.leadFilterdObject.fundingRequirement = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.goLiveMonth = null;
      this.leadFilterdObject.actionUser = null;
    }
    this.getLeadsList();
  }

  formatCreatedAt() {
    this.isCreatedAt = !this.isCreatedAt;
    this.leadFilterdObject.createdAt = this.isCreatedAt ? "asc"
      : this.isCreatedAt == false ? "desc" : undefined;
    if (this.isCreatedAt) {
      this.isLeadNameFormat = false;
      this.isActionDueDateFormat = false;
      this.isFundingRequirmentFormat = false;
      this.isAnnualTurnOverFormat = false;
      this.isGoLiveMonthFormat = false;
      this.isCurrentMilestone = false;

      this.leadFilterdObject.annualTurnOver = null;
      this.leadFilterdObject.fundingRequirement = null;
      this.leadFilterdObject.leadFilter = null;
      this.leadFilterdObject.goLiveMonth = null;
      this.leadFilterdObject.actionUser = null;
      this.leadFilterdObject.currentMileStone = null;
    }
    this.getLeadsList();
  }

  getRMList() {
    this.kanbanService.getRmUserList().subscribe((list) => {
      this.rmUserList = list["result"];
      this.filteredAssigneeList = this.rmUserList;
    });
  }

  getCreditAnalystList() {
    this.kanbanService.getRmUserListForCreditTracker().subscribe((list) => {
      this.creditUserList = list["result"];
      this.filteredAssigneeList = this.rmUserList;
    });
  }

  getSerchData() {
    const obj = {
      "where": {
        "multi_match_query": {
          "type": "multi_match_query",
          "value": [
            {
              "field": "displayName",
              "value": this.searchText,
              "type": "like"
            },
            {
              "field": "displaySequence",
              "value": this.searchText,
              "type": "like"
            }
          ]
        }
      },
      "sort": {
        "field": "updatedAt",
        "order": "desc"
      },
      "from": 0,
      "size": 6
    }
    this.kanbanService.searchLeadsForCredit(obj).subscribe(data => {
      if (data['status'] == 200) {
        this.suggestions = data['result'];
        this.isSeachEnable = true;
      }
    });
  }

  clearFilter() {
    this.paginator.firstPage();
    this.searchText = null;
    this.region = null;
    this.startDate = null;
    this.endDate = null;
    this.source = null;
    this.currentMilestone = null;
    this.transactionType = null;
    this.relationshipManager = null;
    this.clearMultipleFeildFilter();
    this.leadFilterdObject = {}
    this.getLeadsList();
  }

  clearMultipleFeildFilter() {
    this.selectedCurrentMilestones = [];
    this.selectedRegions = [];
    this.selectedSources = [];
    this.selectedTransactionTypes = [];
    this.selectedRelationshipManagers = [];
    this.selectedcreditAnalyst = [];
    this.selectedLeadUnderwritting = [];
    this.isLeadNameFormat = false;
    this.isAnnualTurnOverFormat = false;
    this.isFundingRequirmentFormat = false;
    this.isGoLiveMonthFormat = false;
    this.isActionDueDateFormat = false;
    this.isCurrentMilestone = false;
  }

  collapsFilter() {
    this.isfilterVisible = !this.isfilterVisible;
  }
  getInsurerList() {
    this.kanbanService.getDropdowndata().subscribe(res => {
      this.insurerList = res['insurer_name'];
    })
  }
  modifySpecificLead(result) {
    this.dataSource = this.dataSource.slice(0, result.index).concat(result.laneDetail, this.dataSource.slice(result.index + 1));
  }
  dayBetween(date1: any) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const date1_ms: any = new Date(date1);
    const date2_ms: any = new Date();
    const difference_ms = Math.abs(date1_ms - date2_ms);
    return Math.round(difference_ms / ONE_DAY);
  }

  selectSuggestion(data) {
    this.dataSource = [].concat(data);
    this.isSeachEnable = false;
  }
  opeEditCommentPopup(rowData) {
    let assigneeList = this.assigneeList;
    const dialogRef = this.dialog.open(CreditTrackerCommentComponent, {
      width: '30%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },
      data: { rowData, assigneeList },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getLeadsList();
    });
  }
  openCardDetailsPage(element) {
    window.open('/#/cardDetails/' + element.laneTaskId);
  }

  routeToHistoyTabOfLead(element) {
    window.open('/#/cardDetails/' + element.laneTaskId + '?tabIndex=1');
  }
  formatNumberIndianStyle(value: number | null): string {
    if (value === null || value === undefined) return '-';
    const roundedValue = Math.round(value);
    return new Intl.NumberFormat('en-IN').format(roundedValue);
  }
  handleBlur() {
    setTimeout(() => {
      this.isSeachEnable = false
    }, 1000)
  }
  getRowColor(row) {
    if (row.coldLead) {
      return 'red-row';
    } else if (row.isCreditDone) {
      return 'green-row'
    }
  }
}


export interface PeriodicElement {
  id: string;
  name: string;
  displayName: string;
  displaySequence: string;
  leadSource: string;
  createdAT: string | null;
  reviveDate: string | null;
  annualTurnOver: number | null;
  fundingRequirement: number | null;
  goLiveMonth: string | null;
  transactionType: string | null;
  region: string;
  relationshipManager: {
    createdAt: any;
    updatedAt: any;
    id: any;
    name: string;
    email: string
  };
  relationshipManagerId: string | null;
  currentMilestone: string;
  milestoneUpdatedDate: string | null;
  probability: string | null;
  revivalReason: string | null;
  revivedDate: string | null;
  comment: string | null;
}
