import { Component, OnInit, Inject } from '@angular/core';
import { KanbanService } from '../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-bulk-gt-dialog',
  templateUrl: './bulk-gt-dialog.component.html',
  styleUrls: ['./bulk-gt-dialog.component.scss']
})
export class BulkGtDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<BulkGtDialogComponent>, public _kService: KanbanService, public router: Router, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }

  ngOnInit() {
  }

  buyerFile: any;

  onBuyerDataFileChanged(event) {
    this.buyerFile = event.target.files[0];
  }
  fileUpload(file?) {
    if (file) {
      this.buyerFile.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.buyerFile, this.buyerFile.name);
    this._kService.uploadSupplierBulkTermsheet(uploadData, this.data).subscribe(file => {
      if (file['status'] == 200) {
        this.dialogRef.close()
        this.router.navigate(['/bulkListing/' + this.data]);
      }
    });
  }
  close() {
    this.dialogRef.close()
  }
  downloadBuyerTemplate() {
    this.JSONToCSVConvertor(this.templateHeaderList(), 'template', true);

  }
  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
      var row = "";
      for (var index in arrData[0]) {
        row += index + ',';
      }

      row = row.slice(0, -1);
      CSV += row + '\r\n';
    }

    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    var fileName = "MyReport_";
    fileName += ReportTitle.replace(/ /g, "_");
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.setAttribute('visibility', 'hidden');
    link.download = "template_sample" + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  templateHeaderList() {
    let responseList = [];
    let orderObject = {};
    orderObject['Lead/Supplier Name- New Lead'] = '';
    orderObject['buyer region* - new lead'] = '';
    orderObject['lead/supplier/exporter address* - new lead'] = '';
    orderObject['Monthly Shipment Amount- new Lead'] = '';
    orderObject['monthly shipment currency* - new lead'] = '';
    orderObject['no of shipment* - new lead'] = '';
    orderObject['contact name 1* - new lead'] = '';
    orderObject['contact 1 email- new lead'] = '';
    orderObject['Contact 1 Phone Number- New Lead'] = '';
    orderObject['contact 1 designation - new lead'] = '';
    orderObject['GSTIN- Account Created Sales'] = '';
    orderObject['turnover for current year (inr crs) (2022) - account created sales*'] = '';
    orderObject['Incorporated Since'] = '';
    orderObject['type of company - account created sales*'] = '';
    orderObject['signing authority*'] = '';
    orderObject['decision maker name - account created sales*'] = '';
    orderObject['decision maker phone - account created sales*'] = '';
    orderObject['set-up fee- currency - account created sales*'] = '';
    orderObject['set-up fee - amount - account created sales*'] = '';
    orderObject['remittance charge - currency - account created sales*'] = '';
    orderObject['remittance charge - amount - account created sales*'] = '';
    orderObject['funded amount(eligible%) - prepare term sheet'] = '';
    orderObject['overdue fees - grace period (in days) - prepare term sheet'] = '';
    orderObject['overdue fees - charges beyond grace period (% p.a) - prepare term sheet'] = '';
    orderObject['factoring Fee%- prepare term Sheet'] = '';
    orderObject['facility fee 1 (%) - prepare term sheet*'] = '';
    orderObject['facility fee 1 (amount) - prepare term sheet'] = '';
    orderObject['facility fee 1 (currency) - prepare term sheet'] = '';
    orderObject['discount rate 1 (%) p.a- prepare term sheet*'] = '';
    orderObject['discount rate 1 currency - prepare term sheet*'] = '';
    orderObject['discount rate setting 1 payment terms(days) - prepare term sheet*'] = '';
    orderObject['discount rate setting 1 minimum days charged - prepare term sheet*'] = '';
    orderObject['bank charges 1 amount - prepare term sheet*'] = '';
    orderObject['bank charges 1 currency - prepare term sheet'] = '';
    responseList.push(orderObject);
    return responseList;
  }
  
  deleteBuyerFile(){
    this.buyerFile= null;
   
  }

}




