<div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
  <div class="listingFilters" *ngIf="isInvoiceFilterVisible">
    <div class="search searchBoxtop cusSearch input-btn" fxLayout="row">
      <input type="text" fxFlex placeholder="Search Supplier ID" [(ngModel)]="searchSupplierID"
        (keyup.enter)="fetchSupplierPayouts(false)" (keydown.space)="$event.preventDefault();"
        (input)="checkInputText(searchSupplierID)">
      <button class="searchbtn">
        <i class="ri-search-line" (click)="fetchSupplierPayouts()" *ngIf="showSupplierSearchIcon"></i>
        <i class="ri-close-line" *ngIf="ShowSupplierCloseIcon" (click)="resetSearchText()"></i>
      </button>
    </div>
    <div class="searchBoxtop">
      <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
        <mat-label>Status</mat-label>
        <mat-select #matRef multiple (selectionChange)="getSupplierStatus($event.value)" [(ngModel)]="setInvoiceStatus">
          <mat-option *ngFor="let type of invoiceStatus" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="leftfilteraction">
      <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable"
        (click)="fetchSupplierPayouts(false)">Apply</button>
      <button mat-flat-button class="resetbtn" #button [disabled]="isClearButtonEnable"
        (click)="clearSuppplierFilters()">Clear
        All</button>
    </div>
  </div>
  <div class="mat_table" fxFlex="100">
    <table mat-table [dataSource]="dataSource" matSort
      class="mat-elevation-z8 table_invoice suplierTable fixedTablecolumn" [hidden]="!(dataSource.data?.length>0)">
      <ng-container matColumnDef="suplierID" sticky>
        <th mat-header-cell *matHeaderCellDef> Supplier ID- Name</th>
        <td mat-cell *matCellDef="let element"> {{element.supplierId}} -{{element.account.businessName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="status" sticky>
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <span class="status-dot" [ngClass]="setStatusDot(element)"> ● </span>
          <span [matTooltip]="getTooltipElement(element)">
            {{getRowStatus(element)}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentRecvd">
        <th mat-header-cell *matHeaderCellDef> Payment Received (Escrow) </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.escrowTransaction != null && element.escrowTransaction.found == true">{{
            element.escrowTransaction.amount | number}}</span>
          <span *ngIf="element.escrowTransaction != null && element.escrowTransaction.found == false">-</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentReported">
        <th mat-header-cell *matHeaderCellDef> Payment Reported (By anchor)</th>
        <td mat-cell *matCellDef="let element"> {{element.amount_deposited | number}}
        </td>
      </ng-container>
      <ng-container matColumnDef="accountTo">
        <th mat-header-cell *matHeaderCellDef> Beneficiary Account</th>
        <td mat-cell *matCellDef="let element">
          <span [matTooltip]="getAccountToolTip(element)" matTooltipClass="line-broken-tooltip"
            *ngIf="element.bankDetails !=null">{{element.bankDetails.accountNumber}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount_payable">
        <th mat-header-cell *matHeaderCellDef> Payment To Supplier</th>
        <td mat-cell *matCellDef="let element">
          <span matTooltipClass="line-broken-tooltip">{{element.amount_payable | number}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button class="OdBtn" mat-button *ngIf="element.amount_payable > 0 && routedIndex != 2" color="primary"
            (click)="openOdDialog(element)">
            Adjust OD
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
      [pageSize]="pageSize" (page)="getPaginatorData()" showFirstLastButtons>
    </mat-paginator>
    <h5 class="text-p-wrap" *ngIf="dataSource.data.length==0">No Record Found</h5>

  </div>
</div>