import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/settings/setting.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  sendDataObj = {};
  ismanualUpload: boolean = false;
  actionBtnOnEp: boolean = false;
  actionCanclbtn: boolean = false;
  manualText: string;
  saveLabel: string = "Proceed";
  expectedPymntDate: any;
  invoiceNumber: any;
  delayedDate: any;
  kanbanBoard: any;
  isKanbanLead: boolean = false;
  isBuyerData: boolean = false;
  isPreSale:boolean= false;
  isPresaleReady: boolean = false;
  myControl = new FormControl('');
  selectedUserId: any;
  filteredOptions: Observable<string[]>;
  fetchUser = [];
  userList: string[] = [];
  leadWatcherList = [];
  markDisbursed: boolean;
  
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private settingService: SettingService,
    public pagesService: PagesService, public toasterService: ToastrService, public dialog: MatDialog, private presaleService: PresaleService) {
    console.log("confirma data", data);
    this.sendDataObj = data;
  }
  ngOnInit() {
    if (this.sendDataObj['manualText']) {
      this.ismanualUpload = true;
      this.saveLabel = "Confirm";
      this.manualText = this.sendDataObj['manualText'];
    }
    if (this.sendDataObj['data'] == 'delayed') {
      this.actionBtnOnEp = true;
      this.expectedPymntDate = this.sendDataObj['expectedDate'];
      this.delayedDate = this.sendDataObj['delayRsn'];
      this.invoiceNumber = this.sendDataObj['invoiceNumber'];
    }
    if (this.sendDataObj['data'] == 'canceled') {
      this.actionCanclbtn = true;
      this.invoiceNumber = this.sendDataObj['invoiceNumber'];
    }
    if (this.sendDataObj['data'] == 'kanbanBoard') {
      this.isKanbanLead = true;
    }
    if (this.sendDataObj['data'] == 'buyerData') {
      this.isBuyerData = true;
    }
    if (this.sendDataObj['data'] == 'preSales') {
      this.isPreSale = true;
    }
    if (this.sendDataObj['data'] == 'preSales-ready') {
      this.isPresaleReady = true;
      this.getUserListBasedOnRoles();
    }
    if(this.sendDataObj == 'markDisbursed'){
      this.markDisbursed = true;
    }
  }

  coldLeadMoveReason: string='COLD_LEAD';
  selectColdLeadReason(event) {
    this.coldLeadMoveReason = event;
  }

  isValid(){
    if (this.isPresaleReady){
      if(this.myControl.value != '' && this.myControl.value != null){
        console.log("myControl",this.myControl)
        return false;
      }
      return true;
    }
  }

  saveDetails() {
    if (this.ismanualUpload || this.actionBtnOnEp || this.actionCanclbtn || this.markDisbursed) {
      this.dialogRef.close(true);
    }
    else if(this.isKanbanLead || this.isBuyerData || this.isPreSale){
      this.dialogRef.close(true);
    }
    else if (this.isPresaleReady){
      let obj = {
        leadIds : this.sendDataObj['leadIds'],
        assigneeId: this.selectedUserId,
        watcherId: this.leadWatcherList
      }
      this.presaleService.bulkLeadMoveToLOSState(obj).subscribe(res => {
        if(res['status'] == 200){
          this.toasterService.success("Lead Moved to LOS");
          this.dialogRef.close(true);
        }
      })
    }
    else {
      this.pagesService.editBulkSupplierDetails(this.sendDataObj).subscribe(res => {
        if (res['status'] == 200) {
          this.pagesService.offSpinner();
          this.dialogRef.close(true);
          this.toasterService.success("Supplier detail edit successfully");
        }
      })
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  getUserListBasedOnRoles() {
    this.presaleService.fetchKanbanUserList().subscribe(res => {
      this.fetchUser = res['result'];
      res['result'].map(ele => {
        this.userList.push(ele.userName);
      })
      this.getCall();
    })
  }
  getCall(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.userList.filter(option => option.toLowerCase().includes(filterValue));
  }
  selectedUserList(event: MatAutocompleteSelectedEvent) {
    let seluser = event.option.viewValue;
    this.fetchUser.map(ele => {
      if (seluser == ele.userName) {
        this.selectedUserId = ele.userId;
      }
    });
  }
  selectLeadWatcher(lead){
    this.leadWatcherList = lead;
    console.log("LeadWatcher List",this.leadWatcherList)
  }
}
