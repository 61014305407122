<div class="business-main">
  <div class="document">
    <!-- <h3>Upload Document</h3> -->
    <div class="docsupload-expansion">

      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
            Banking Statement
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput1 (change)="uploadFiles(fileInput1.files,'BANKING_STATEMENT')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'BANKING_STATEMENT')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.BANKING_STATEMENT && uploadedFolders.BANKING_STATEMENT.length > 0); else bankElse">
          <ul class="expend-list">
            <li class="" *ngFor="let folder of uploadedFolders.BANKING_STATEMENT">
              <p>{{folder.name}}  </p>
              <div class="upload upload-main">
                <div class="upload">
                  <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                    <i class="ri-eye-line icon-sizing"></i>
                  </span>
                  <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                    <mat-icon>delete_forever</mat-icon>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ng-template #bankElse>
          <p class="no-temp">No Documents Uploaded Yet!</p>
        </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="docsupload-expansion">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
            Buyer Ledger
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput2 (change)="uploadFiles(fileInput2.files,'BUYER_LEDGER')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'BUYER_LEDGER')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.BUYER_LEDGER && uploadedFolders.BUYER_LEDGER.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.BUYER_LEDGER">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>


    <div class="docsupload-expansion">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
              KYC -> Aadhar Card/ PAN Card
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput3 (change)="uploadFiles(fileInput3.files,'KYC')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'KYC')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.KYC && uploadedFolders.KYC.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.KYC">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="docsupload-expansion">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
              Shipping Documents
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput4 (change)="uploadFiles(fileInput4.files,'SHIPPING_DOCUMENT')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'SHIPPING_DOCUMENT')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.SHIPPING_DOCUMENT && uploadedFolders.SHIPPING_DOCUMENT.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.SHIPPING_DOCUMENT">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="docsupload-expansion">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
              Factory Pictures
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput5 (change)="uploadFiles(fileInput5.files,'FACTOR_PICTURE')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'FACTOR_PICTURE')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.FACTOR_PICTURE && uploadedFolders.FACTOR_PICTURE.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.FACTOR_PICTURE">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="docsupload-expansion">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
              Other Documents
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput6 (change)="uploadFiles(fileInput6.files,'OTHER_DOCUMENT')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'OTHER_DOCUMENT')"
                accept=".pdf,.jpg,.jpeg,.png">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.OTHER_DOCUMENT && uploadedFolders.OTHER_DOCUMENT.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.OTHER_DOCUMENT">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="docsupload-expansion" *ngIf="this.authService.isCompanyAdmin()">
      <mat-accordion>
        <mat-expansion-panel class="mb-10">
          <mat-expansion-panel-header>
            <div class="accoring-div">
              Credit Scoreboard
            <div class="upload-folder-tab">
            <label class="upload-area">
              <input id="1-basic" class="upload-input" type="file" #fileInput7 (change)="uploadFiles(fileInput7.files,'CREDIT_SCORECARD')" webkitdirectory multiple/>
              <span class="upload-button point" matTooltip="Upload Folder">
                <mat-icon>create_new_folder</mat-icon>
              </span>
            </label>
            <label class="upload-area single-file">
              <input type="file" class="upload-input" (change)="uploadSingleFolderDocument($event,'CREDIT_SCORECARD')">
              <span class="upload-button point" matTooltip="Upload File">
                <i class="ri-upload-cloud-line"></i>
              </span>
            </label>
          </div>
          </div>
          </mat-expansion-panel-header>

          <div *ngIf="(uploadedFolders && uploadedFolders.CREDIT_SCORECARD && uploadedFolders.CREDIT_SCORECARD.length > 0); else bankElse">
            <ul class="expend-list">
              <li class="" *ngFor="let folder of uploadedFolders.CREDIT_SCORECARD">
                <p>{{folder.name}}  </p>
                <div class="upload upload-main">
                  <div class="upload">
                    <span class="upload-button point view-fix" matTooltip="View File" (click)="showFile(folder.id)">
                      <i class="ri-eye-line icon-sizing"></i>
                    </span>
                    <span class="upload-button point" matTooltip="Delete File" (click)="deleteFile(folder.id)">
                      <mat-icon>delete_forever</mat-icon>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ng-template #bankElse>
            <p class="no-temp">No Documents Uploaded Yet!</p>
          </ng-template>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>


  <div class="note-doc">
    <span class="note-doc-heading">
      Documents To Upload
    </span>
    <p>1. Audited financials(including ITR) for FY21,FY22 and prov for FY23</p>
    <p>2. Bank statement of all working capital & Current accounts for last 12 months </p>
    <p>3. Ledger with all the buyer in the term sheet for last 12 months </p>
    <p>4. Stock statement for last 2 months shared with banks ageing breakup of debtor /creditors</p>
    <p>5. GST Consent based report </p>
    <p>6. Limit details (&sanctioned letter)of existing banking lines including drip , modifi</p>
    <p>7. PAN & Adhar of all partners /promoters </p>
    <p>8. Partnership deed ( in case of partnership firm)</p>
    <p>9. One set of complete shipping documents (PO, Invoice, SB ,quality check , BL)</p>
    </div>
  </div>


