<div>
  <section class="ctaStripWrap">
    <div class="lft">
      <button mat-button (click)="navigateToSupplierPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </section>
  <div class="dash-layout">
    <div *ngIf="elementDetails && anchorDisplayName != 'MSAF'">
      <div class="supplierLabel">{{elementDetails.businessName}}</div>
      <span>{{supplierIDLabel}} Id: {{elementDetails.searchkey}}</span><br>
      <span>VAN: {{elementDetails.vanNo}}</span>
    </div>
    <div *ngIf="elementDetails && anchorDisplayName == 'MSAF'">
      <div class="supplierLabel">{{lenderName}}</div>
      <span>{{supplierIDLabel}} Id: {{lenderId}}</span><br>
    </div>
    <mat-tab-group class="tab-style-1" (selectedIndexChange)="getTabValue($event)">
      <mat-tab *ngFor="let tab of historyTabs; let index = index" [label]="tab">
      </mat-tab>
    </mat-tab-group>
  </div>
  <div>
    <div fxLayout="row" fxLayoutGap="1%" class="profile " *ngIf="tabName == 'Basic Details'">
      <div fxLayout="Column">
        <!-- <mat-card class="metrics w-100">
          <div fxLayout="row " class="justify-content-between align-items-center">
            <h2 class="card-heading-1">Metrics</h2>
              <mat-select (selectionChange)="OnselectMetrics($event.value)" [(ngModel)]="selectedMetricsValue">
                <mat-option *ngFor="let value of metricLevelArray" [(value)]="value.field">
                  {{value.name}}
                </mat-option>
              </mat-select>
          </div>
          <mat-card-content>
            <div class="metricsWrp">
              <div class="detailSection justify-content-between" *ngIf="metricsRes">
                <div class="p-wrp">
                  <label>EP Disbursed</label>
                  <span *ngIf="metricsRes.disbursedAmount != null">â‚¹{{settingService.numDifferentiation(metricsRes.disbursedAmount)}}</span>
                  <span *ngIf="metricsRes.disbursedAmount == null">-</span>
                </div>
                <div class="p-wrp">
                  <label>In Progress</label>
                  <span *ngIf="metricsRes.amountInProcess != null">â‚¹{{settingService.numDifferentiation(metricsRes.amountInProcess)}}</span>
                  <span *ngIf="metricsRes.amountInProcess == null">-</span>

                </div>
                <div class="p-wrp">
                  <label>ROI(Weighted)</label>
                  <span *ngIf="metricsRes.roi != null">{{metricsRes.roi}}%</span>
                  <span *ngIf="metricsRes.roi == null">-</span>

                </div>
                <div class="p-wrp">
                  <label>Avg. Disbursement Days</label>
                  <span *ngIf="metricsRes.averageDisbursementDays !=null">{{metricsRes.averageDisbursementDays}}
                    days</span>
                  <span *ngIf="metricsRes.averageDisbursementDays ==null">-</span>
                </div>
              </div>
            </div>

          </mat-card-content>
        </mat-card> -->
        <mat-card class="basics w-100">
          <h2 class="card-heading-1">Basics</h2>
          <mat-card-content *ngIf="elementDetails" >
            <div class="card-sub-heading-1" >Billing Address</div>
            <div class="desc">{{elementDetails.billingAddress}}</div>
            <div class="card-sub-heading-1">Shipping Address</div>
            <div class="desc">{{elementDetails.shippingAddress}}</div>
            <div class="card-sub-heading-1">GSTIN</div>
            <div class="desc">{{elementDetails.gstNo}}</div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="whatsapp-details">
        <mat-card class="contacts" *ngIf="authService.isCredlixUser()">
          <div fxLayout="row" class="justify-content-between" style="margin-bottom: 30px;">
            <div class="card-heading-1" >Contacts</div>
            <div class="add-n-btn">
              <a style="font-size: 14px;" (click)="addContact()"> + Add New</a>
            </div>
          </div>
          <mat-card-content>
            <table>
              <tr *ngFor="let details of contactList; let i=index">
                <td style="padding: 10px;">{{details.name}}</td>
                <td style="padding: 10px;">{{details.phoneNumber}}</td>
                <td style="padding: 10px;" *ngIf="details.whatsapp== false" (click)="setWhatsappNumber(contactList[i])">
                  Set for WhatsApp</td>
                <td style="padding: 10px;" *ngIf="details.whatsapp== true"><img
                    src="../../../../../assets//images/whatsappicon.png" class="whtspicon"> </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="settings" *ngIf="tabName == 'Discount Settings'">
      <div fxLayout="row">
        <mat-card class="settProfile" style="padding: 16px 0 16px 0;height: auto;">
          <div fxLayout="row " class="justify-content-between px-16" >
            <div class="card-heading-1" >Lenders</div>
          </div>
          <mat-card-content>
            <mat-list role="list">
              <div class="headerLabel">
                <div *ngIf="ischeckFinanceName" class="selectedtab" >
                  <div style="font-size: 16px;">{{ischeckFinanceName}}</div>
                  <div style="font-size: 13px;font-weight: normal;">{{supplierDetails.discountRatePerDay}} % Yearly</div>
                </div>
              </div>
            </mat-list>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-style-4" >
          <div fxLayout="row " class="flex-direction-column">
            <div class="financerLabel d-flex justify-content-between">
                 <div class="card-heading-2">{{ischeckFinanceName}}</div>
                  <div>
                  <button *ngIf="profileSaveBtn == 'Save'" (click)="resetProfile()" mat-stroked-button
                  class="savebtn">Cancel</button>
                  <button class="ml-20" form="ngForm" type="submit" mat-stroked-button [ngClass]="profileSaveBtn == 'Save' ? 'savebtn' : 'editBtn'">{{profileSaveBtn}}</button>
                </div>
            </div>
            <mat-card-content>
              <mat-form-field [ngClass]="profileSaveBtn == 'Edit'?'disableLender':''" appearance="fill" class="selectearlypaystatus finan">
                <mat-label>Choose Lender</mat-label>
                <mat-select [ngClass]="profileSaveBtn == 'Edit'?'disableLender':''" [disabled]="profileSaveBtn == 'Edit'" (selectionChange)="OnselectFinancer($event.value,financerArray )"
                  [(ngModel)]="financeSelectType">
                  <mat-option *ngFor="let type of financerArray" [value]="type.name">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            
            </mat-card-content>
          </div>
          <mat-card-content>
            <form [formGroup]="supplierDetailsForm" (ngSubmit)="editDetails(false)" id="ngForm">
              <div class="form-details">
                <div fxLayout="row" fxLayoutGap="1%">
                  <div class="w-50" >
                    <mat-label class="required-asterisk">Discount Rate % (Yearly)</mat-label>
                    <mat-form-field appearance="outline" floatLabel="never">
                      <input type="number" matInput formControlName="rate" appNumbervalidation placeholder="Discount Rate">
                      <mat-error *ngIf="supplierDetailsForm.controls.rate.errors?.required">This is required.</mat-error>
                    </mat-form-field>
                    <span>%</span>
                  </div>
                  <div class="w-50" >
                    <div class="d-flex flex-column">
                      <mat-label class="required-asterisk">Eligible%</mat-label>
                      <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline" floatLabel="never">
                          <input type="number" matInput formControlName="eligiblePercentage" appNumbervalidation placeholder="Eligible">
                          <mat-error *ngIf="supplierDetailsForm.controls.eligiblePercentage.errors?.required">This is required.
                          </mat-error>
                        </mat-form-field>
                              
                      <span>%</span>
                      </div>
                   
                    </div>
                
                   
                  </div>
                </div>
                <div fxLayout="column">
                  <mat-label>Limit (INR)</mat-label>
                  <mat-form-field class="itemlimit" appearance="outline" floatLabel="never">
                    <input type="number" matInput formControlName="limit" appNumbervalidation placeholder="Limit">
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="1%">
                  <div class="w-50">
                    <mat-label class="required-asterisk">Min Discount Days*</mat-label>
                    <mat-form-field appearance="outline" floatLabel="never">
                      <input type="number" matInput formControlName="minDaysDiscount" (keypress)="numberOnly($event)" placeholder="Min Discount Days">
                      <mat-error *ngIf="supplierDetailsForm.controls.minDaysDiscount.errors?.required">This is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-50">
                    <mat-label class="required-asterisk">Max Discount Days</mat-label>
                    <mat-form-field appearance="outline" floatLabel="never">
                      <input type="number" matInput formControlName="maxDaysDiscount" (keypress)="numberOnly($event)" placeholder="Max Discount Days">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
            <div class="edit" fxLayout="column">
              <mat-label>Credlix Fee %</mat-label>
              <mat-form-field style="width: 200px;" appearance="outline" floatLabel="never">
                <input type="number" matInput [(ngModel)]="credlixFee" *ngIf="isABFL == true" [disabled]="profileSaveBtn == 'Edit'" [required]="true"
                placeholder="Credlix Fee">
                <input type="number" matInput [(ngModel)]="credlixMoglixFee" *ngIf="isMOGLIX" readonly>
              </mat-form-field>
            </div>
            <div class="apprSection">
              <label>Approvals</label>
              <div fxLayout="row" fxLayoutGap="4%">
                <div class="form-check" *ngFor="let apprName of approvalListArray">
                  {{isChecked}}
                  <input style="top: 644px;left: 504px;width: 18px;height: 18px;" class="form-check-input" 
                  name="{{apprName.approvalName}}" type="checkbox" [disabled]="profileSaveBtn == 'Edit'"
                  [(ngModel)]="apprName.isChecked" id="{{apprName.approvalName}}" (change)="getSelectedApproval()">
                  <label class="form-check-label" for="{{apprName.approvalName}}">
                    {{apprName.approvalName}}
                  </label>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>





    <div class="settings" *ngIf="tabName == 'Lenders'">
      <div fxLayout="row">
        <mat-card class="lendersLeftTab" style="padding: 16px 0 16px 0;height: auto;">
          <div fxLayout="row " class="justify-content-between px-16" >
            <div class="card-heading-1" >Lenders</div>
          </div>
          <mat-card-content>
            <mat-list role="list">
              <div class="headerLabel" *ngFor="let lender of lenderConfig">
                <div class="selectedtab" >
                  <div style="font-size: 16px;">{{lender.lenderName ? lender.lenderName : ''}}</div>
                </div>
              </div>
            </mat-list>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-style-4 lendersTab" >
          <div fxLayout="column" fxLayoutGap="5%">
            <div fxLayout="row" class="flex-direction-column">
              <div class="financerLabel d-flex justify-content-between">
                   <div class="card-heading-2">{{lenderName}}</div>
                    <div>
                    <button *ngIf="profileSaveBtn == 'Save'" (click)="resetProfile()" mat-stroked-button
                    class="savebtn">Cancel</button>
                    <button class="ml-20" form="ngForm" type="submit" mat-stroked-button
                     [ngClass]="profileSaveBtn == 'Save' ? 'savebtn' : 'editBtn'" [disabled]="lenderConfig.length == 0">{{profileSaveBtn}}</button>
                  </div>
              </div>
            </div>
            <mat-card-content>
              <form [formGroup]="lenderDetailsForm" (ngSubmit)="editLender(false)" id="ngForm">
                <div class="form-details">
                  <div fxLayout="row" fxLayoutGap="8%">
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                      <mat-label class="required-asterisk">Account Number</mat-label>
                      <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="accountNumber" appNumbervalidation placeholder="014876569">
                      </mat-form-field>
                    </div>
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                        <mat-label class="required-asterisk">Credit Period (days)</mat-label>
                          <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                            <input type="number" matInput formControlName="creditDays" appNumbervalidation placeholder="credit Period">
                            <mat-error *ngIf="lenderDetailsForm.controls.creditDays.errors?.required">This is required.
                            </mat-error>
                          </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8%">
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                      <mat-label class="required-asterisk">Dealer Limit</mat-label>
                      <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="dealerLimit" placeholder="Dealer Limit">
                      </mat-form-field>
                      <mat-error *ngIf="lenderDetailsForm.controls.dealerLimit.errors?.required">This is required.
                      </mat-error>
                    </div>
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                      <mat-label class="required-asterisk">Processing time (days)</mat-label>
                      <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="processingDays" placeholder="Processing Days">
                        <mat-error *ngIf="lenderDetailsForm.controls.processingDays.errors?.required">This is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="8%">
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                      <mat-label class="required-asterisk">Interest Rate(%PA)</mat-label>
                      <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="interestRate" placeholder="Interest Rate">
                      </mat-form-field>
                      <mat-error *ngIf="lenderDetailsForm.controls.interestRate.errors?.required">This is required.
                      </mat-error>
                    </div>
                    <div class="w-50" fxLayout="column" fxLayoutGap="1%">
                      <mat-label class="required-asterisk">Overdue Interest Rate(%PA)</mat-label>
                      <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="overDueInterestRate" placeholder="OverDue InterestRate">
                        <mat-error *ngIf="lenderDetailsForm.controls.overDueInterestRate.errors?.required">This is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="column" fxLayoutGap="1%">
                    <mat-label>Grace Period (days)</mat-label>
                    <mat-form-field style="width: 308px !important" appearance="outline" floatLabel="never">
                      <input type="number" matInput formControlName="gracePeriodDays" placeholder="Grace Period Days">
                      <mat-error *ngIf="lenderDetailsForm.controls.gracePeriodDays.errors?.required">This is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="column" fxLayoutGap="2%">
                    <mat-label>Eligible till</mat-label>
                    <div fxLayout="row" style="align-items: center;">
                      <mat-label style="margin: 0px 10px 15px 0px"> Invoice Date + </mat-label>
                      <mat-form-field style="width: 78px !important" appearance="outline" floatLabel="never">
                        <input type="number" matInput formControlName="eligibleDays">
                      </mat-form-field>
                      <mat-label style="margin: 0px 0px 15px 10px">Days</mat-label>
                    </div>
                    <mat-error *ngIf="lenderDetailsForm.controls.eligibleDays.errors?.required">This is required.
                    </mat-error>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </div>
        </mat-card>
      </div>
    </div>









    <div class="bankAcc" *ngIf="tabName == 'Bank Account'">
      <div class="whatsapp-details">
        <div fxLayout="row">
          <mat-card class="discount" style="padding: 16px 0 16px 0;">
            <div fxLayout="row" style="padding-left: 16px;border-bottom: ridge 1px;height: 40px">
              <div style="font-size: 16px;font-weight: 500">Bank Account</div>
              <mat-card-content>
                <div class="add-n-btn">
                  <a [ngClass]="addNewAccount?'disabledAddBtn':'addBtn'" *ngIf="authService.isCredlixUser()" (click)="addBankAccount()">+ Add Bank Account</a>
                </div>
              </mat-card-content>
            </div>
            <mat-card-content>
              <mat-list role="list">
                <mat-list-item (click)="OnselectBankAcc(bank)" [ngClass]="bank.isPrimaryUI == true? 'headerLabel' : 'lenderName'" style="border-bottom: ridge 1px; height: 60px;cursor: pointer;" *ngFor="let bank of bankDetailsArr" [value]="bank.id">
                  <div style="font-size: 14px;"><span *ngIf="bank.bankName">{{bank.bankName}}, {{bank.state}}</span><br>
                    <span style="font-size: 13px;">{{bank.accountNoUI}}</span>
                    <span *ngIf="bank.isPrimary == true" style="color: #1479FC;font-size: 13px;margin-left: 10px;">Primary</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="bankDetailsArr.length != 0" class="card-style-4 w-50" >
            <div fxLayout="row " class="d-flex justify-content-between w-100 align-items-center mb-40" >
              <div class="card-heading-2" >{{bankName}}</div>
              <mat-card-content>
                <div class="actionbtn" fxLayout="row" fxLayoutGap="1%">
                  <button *ngIf="saveBtnLabel == 'Save'" (click)="resetAccount()" mat-raised-button
                  class="">Cancel</button>
                  <button *ngIf="saveBtnLabel == 'Save' && authService.isCredlixUser()" form="ngBankForm" type="submit" mat-raised-button
                  class="savebtn ml-20">Save</button>
                  <button *ngIf="saveBtnLabel == 'Edit' && authService.isCredlixUser()" (click)="submitBankDetails(true, bankDetails)" mat-raised-button
                  [disabled]="isPrimaryAcc" [ngClass]="isPrimaryAcc? 'saveBtnDisable':'savebtn'">Set As Primary</button>
                <button mat-stroked-button *ngIf="saveBtnLabel == 'Edit' && authService.isCredlixUser() && !isPrimaryAcc" (click)="deleteBankDetails(bankDetails)" mat-raised-button
                  class="deleteBtn ml-20">Delete</button>
                </div>
              </mat-card-content>
            </div>
            <mat-card-content>

              <form class="bankForm" [formGroup]="bankDetailsForm" (ngSubmit)="submitBankDetails(false)" id="ngBankForm">
                <div fxLayout="column">
                  <mat-label>Recipient Name*</mat-label>
                  <mat-form-field class="itembankDetails" appearance="outline" floatLabel="never">
                    <input type="text" matInput formControlName="recipientName" placeholder="Account holderâ€™s name">
                    <mat-error *ngIf="bankDetailsForm.controls.recipientName.errors?.required">This is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="column">
                  <mat-label>Account Number*</mat-label>
                  <mat-form-field class="itembankDetails" appearance="outline" floatLabel="never">
                    <input type="text" matInput formControlName="accountNoUI" placeholder="Bank Account Number">
                    <mat-error *ngIf="bankDetailsForm.controls.accountNoUI.errors?.required">This is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="column">
                  <mat-label>IFSC*</mat-label>
                  <mat-form-field class="itembankDetails" appearance="outline" floatLabel="never">
                    <input type="text" maxlength="11" matInput formControlName="ifscCode" placeholder="Bank IFSC Code">
                    <mat-error *ngIf="bankDetailsForm.controls.ifscCode.errors?.required">This is required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="transaction" *ngIf="tabName == 'Transactions'">
      <div class="mat_table" fxFlex="82" style="padding: 20px;">
        <table mat-table matSortDisableClear [dataSource]="dataSource" matSort
          class="mat-elevation-z8">
          <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
          <th mat-header-cell *matHeaderCellDef> {{disCol.key}} </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element['transactionType'] == 'CREDIT' ? 'creditRow' : 'debitRow'">
            <span>
              {{ disCol.cell(element, disCol.field) }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
        </table>
        <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
        <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
        [pageSize]="pageSize" showFirstLastButtons (page)="getSupplierTransactions()">
      </mat-paginator>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div> -->