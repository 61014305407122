import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { urls } from "../url";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class PuppeteerService {
  bussinessBaseUrl: string = environment.bussinessBaseUrl;
  constructor(private httpClient: HttpClient) { }

  generatePdf(html: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    return this.httpClient.post(this.bussinessBaseUrl + urls.generateTSFromBussiness, { html },
      {
        headers,
        responseType: "arraybuffer",
        observe: "response",
      }
    );
  }
}
