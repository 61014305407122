import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { ToastrService } from 'ngx-toastr';
import {saveAs} from 'file-saver';
@Component({
  selector: 'app-upload-file-buyer',
  templateUrl: './upload-file-buyer.component.html',
  styleUrls: ['./upload-file-buyer.component.scss']
})
export class UploadFileBuyerComponent implements OnInit {

  file: any;
  fileName: string;

  constructor(public dialogRef: MatDialogRef<UploadFileBuyerComponent>, public kanbanService: KanbanService, public tostrService: ToastrService) { }

  ngOnInit() {
  }
  closedialog() {
    this.dialogRef.close(false);
  }
  onFileChanged(event) {
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
      this.fileName = event.target.files[0].name;
    }
  }
  uploadFile() {
    if (this.file) {
      const uploadData = new FormData();
      uploadData.append('file', this.file)
      this.kanbanService.uploadBulkBuyerFile(uploadData).subscribe(res => {
        if (res['success']) {
          this.tostrService.success("File Uploaded Successfully");
        }
      })
    } else {
      this.tostrService.error("Please select file to upload")
    }
  }
  clearStack(event) {
    event.target.value = null;
  }
  downloadTemplate(){
    this.kanbanService.downloadApprovedTemplate().subscribe(res=>{
      if(res){
        var contentDispositionHeader = res.headers.get('content-disposition');
        let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        let fileName = resultDownload.replace(/"/g, '')
        saveAs(res.body, fileName);
        this.tostrService.success("Template Downloaded Successfully")
        return fileName;
      }
    })
  }

}
