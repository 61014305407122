import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import * as moment from "moment";
import { PaymentRequestedComponent } from './payment-requested/payment-requested.component';
import { Router } from '@angular/router';
import * as lodash from 'lodash';
import { CancelEPDialogComponent } from '../cancel-ep-dialog/cancel-ep-dialog.component';
import { MarkDelayedEPDialogComponent } from '../mark-delayed-ep-dialog/mark-delayed-ep-dialog.component';
import { SendWhatsappMsgDialogComponent } from 'src/app/component/suppliers/listing/send-whatsapp-msg-dialog/send-whatsapp-msg-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-early-payment',
  templateUrl: './request-early-payment.component.html',
  styleUrls: ['./request-early-payment.component.scss']
})
export class RequestEarlyPaymentComponent implements OnInit {
  getEarlyPaymentDetails: any;
  _: any = lodash;
  setDialogData: any;
  getInvoiceID: any;
  setInvoiceIDArray = [];
  amountRequest: number = 0;
  discount: number = 0;
  totalamount: number = 0;
  totalInvoicedAmount: number = 0;
  earlyReceivable: number = 0;
  balanceReceivable: number = 0;
  isMultipleInvoice: boolean = true;
  dueDate: any;
  paymentDate: any;
  isCustomer: boolean = false;
  earlypaymentId: any;
  checkStatus: boolean;
  disableRequestButton: boolean = true;
  discountRatePercntge: number;
  leftLimit: any;
  debitNoteamount: any;
  netAmountReceive: any
  transactionsArray = []
  requiredApprovals = [];
  currentApprovals;
  debitNoteDate: any;
  showDialogTabs: boolean = true;
  isApprovedRequest: boolean = false;
  isOnHoldShow: boolean = true;
  minDaysInterval: number;
  maxDaysInterval: number;
  onHoldDate: any;
  isRequestEPShow: boolean = true;
  RequestEPDate: any;
  requestDate: any;
  approvDate: any;
  tabName: string = "InvoiceInfo";
  commissionFee: any;
  historyTabs = ['InvoiceInfo', 'History'];
  getEarlyPaymentsData: any = [];
  isMultipleSelected: boolean = false;
  isAccord: boolean = false;
  isEPActionClicked: boolean = false;
  requestIndex: number;
  setInvoiceNumber: string;
  isPayoutOption: boolean = false;

  constructor(public pagesService: PagesService, private router: Router, public toasterService: ToastrService,
    public authService: AuthService, public dialog: MatDialog, public dialogRef: MatDialogRef<RequestEarlyPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.setInvoiceNumber = data['selectedInvoiceDetails'][0].invoiceNumber
    if (data['value'] == 'payout') {
      this.isPayoutOption = true;
    }
  }
  ngOnInit() {
    this.getEarlyPaymentDetails = this.data['selectedInvoiceDetails'];
    if (this.getEarlyPaymentDetails != null) {
      this.getEarlyPaymentDetails.map(val => {
        this.setInvoiceIDArray.push(val['id']);
      })
    }
    if (this.setInvoiceIDArray.length > 1) {
      this.pagesService.getmultipleInvoiceEPDetails(this.setInvoiceIDArray).subscribe(data => {
        this.isMultipleSelected = true;
        this.getEarlyPaymentsData = data['result'];
        if (data['result'].epStatus != 'Eligible')
          this.disableRequestButton = false;
        let approvalsFilter = this.getEarlyPaymentsData['requiredApprovals'];
        if (approvalsFilter != null) {
          this.requiredApprovals = approvalsFilter.filter(approvals =>
            approvals.isActive == true
          )
        }
        this.currentApprovals = this.getEarlyPaymentsData['approvals'];
        this.dueDate = this.getEarlyPaymentsData.invoiceDueDate;
        this.maxDaysInterval = this.getEarlyPaymentsData.maxDaysInterval;
        this.minDaysInterval = this.getEarlyPaymentsData.minDaysInterval;
        let formatDate = moment(this.dueDate).subtract(this.maxDaysInterval, 'days');
        this.onHoldDate = moment(formatDate).format('ll');
        let RequestFormatDate = moment(this.dueDate).subtract(this.minDaysInterval, 'days');
        this.RequestEPDate = moment(RequestFormatDate).format('ll');
      })
    }
    else {
      this.getSingleInvoiceDetail();
    }

    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    let credlixUserID = localStorage.getItem("credlixUserID");
    if (credlixUserID) {
      getLocalStorage['credlixAnchorList'].map(val => {
        if (credlixUserID == val['id']) {
          if (val['displayName'] == 'ACCORD') {
            this.isAccord = true;
          }
        }
      })
    }
    if (getLocalStorage['accountName'] != null && getLocalStorage['accountName'] == 'ACCORD') {
      this.isAccord = true;
    }
    if (this.authService.getIsCusotmer()) {
      this.isCustomer = true;
    }

  }

  refreshInvoiceValidation(){
    this.pagesService.refreshInvoiceValidation(this.getEarlyPaymentDetails[0].id).subscribe(result => {
      this.fetchEPHistory();
    }, error => console.log(error));
  }


  getSingleInvoiceDetail() {
    this.pagesService.getInvoiceEPDetails(this.getEarlyPaymentDetails[0].id).subscribe(data => {
      this.getEarlyPaymentsData = data['result'];
      if (data['result'].epStatus != 'Eligible')
        this.disableRequestButton = false;
      let approvalsFilter = this.getEarlyPaymentsData['requiredApprovals'];
      if (approvalsFilter != null) {
        this.requiredApprovals = approvalsFilter.filter(approvals =>
          approvals.isActive == true
        )
      }
      if (this.getEarlyPaymentsData.isTasva == true) {
        let filtererRes = this.requiredApprovals.filter(item => item['field'] == 'financeApproval')
        this.requiredApprovals = filtererRes
      }

      this.currentApprovals = this.getEarlyPaymentsData['approvals'];
      this.dueDate = this.getEarlyPaymentsData.invoiceDueDate;
      this.maxDaysInterval = this.getEarlyPaymentsData.maxDaysInterval;
      this.minDaysInterval = this.getEarlyPaymentsData.minDaysInterval;
      let formatDate = moment(this.dueDate).subtract(this.maxDaysInterval, 'days');
      this.onHoldDate = moment(formatDate).format('ll');
      let RequestFormatDate = moment(this.dueDate).subtract(this.minDaysInterval, 'days');
      this.RequestEPDate = moment(RequestFormatDate).format('ll');
    })

  }

  historyResp: any;
  filterHistoryArray = [];
  lastHistoryElemnt: any;
  fetchEPHistory() {
    this.pagesService.getEPHistory(this.getEarlyPaymentDetails[0].id).subscribe(res => {
      this.historyResp = res['result'];
      console.log('history responce is : ', this.historyResp)
      this.historyResp.map((val, i) => {
        if (val['status'] == "PAYMENT_REQUESTED") {
          this.requestIndex = i;
        }
      })
      this.filterHistoryArray = this.historyResp.filter(function (ele) {
        return ele.status == 'DELAYED'
      })
      console.log(this.filterHistoryArray);
      this.lastHistoryElemnt = this.filterHistoryArray.pop();

    })
  }

  getVerifiedApproval(fieldName) {
    let resp = {}
    if (this.currentApprovals != null) {
      for (let i = 0; i < this.currentApprovals.length; i++) {
        this.approvDate = this.currentApprovals[i]
        let a = this.currentApprovals[i]
        if (a.field == fieldName) {
          resp = a;
          break
        }
      }
    }
    return resp
  }
  getTabValue(event) {
    if (event == 0) {
      this.tabName = 'InvoiceInfo'
    }
    else if (event == 1) {
      this.tabName = 'History'
      this.fetchEPHistory();
    }
  }

  createEarlyPayment() {
    let obj = {
      invoiceIds: this.setInvoiceIDArray
    }
    this.pagesService.createEarlyPayment(obj).subscribe(res => {
      this.dialogRef.close(false)
    })
  }

  downloadCSV(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }

  }
  downloadPaymentSchedule() {
    let obj = {
      invoiceIds: this.setInvoiceIDArray
    }
    this.pagesService.downloadPaymentSchedule(obj).toPromise().then(res => {
      this.pagesService.offSpinner();
      this.downloadCSV('earlyPayment.csv', res)
    }).catch(error => console.log(error))
  }

  customerMarkAsPaid() {
    const dialogRef = this.dialog.open(PaymentRequestedComponent, {
      // disableClose: true,
      width: '300px',
      height: '250px',
      data: this.earlypaymentId
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  closeDialog() {
    if (this.isEPActionClicked == true) {
      this.dialogRef.close(false)
    }
    else
      this.dialogRef.close(true);
  }

  isMultipleSelectedData(tabName) {
    if (tabName == "History" && this.isMultipleSelected) {
      return true;
    }

    return false;
  }

  cancelEPRequest() {
    const dialogRef = this.dialog.open(CancelEPDialogComponent, {
      disableClose: false,
      width: '550px',
      height: '420px',
      data: this.getEarlyPaymentsData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        this.isEPActionClicked = true;
        this.getSingleInvoiceDetail();
      }
    });
  }

  markEPDelayed() {
    const dialogRef = this.dialog.open(MarkDelayedEPDialogComponent, {
      disableClose: false,
      width: '550px',
      height: '420px',
      data: this.getEarlyPaymentsData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != true) {
        this.isEPActionClicked = true;
        this.getSingleInvoiceDetail();
        // this.applyInvoiceFilters(true);
      }
    });

  }
  isMultipleSupplierSelected: boolean = false;
  sendWhatsappMessage() {
    let invoiceDetails = this.data['selectedInvoiceDetails'];
    for (let i = 0; i < invoiceDetails.length; i++) {
      if (invoiceDetails[0].supplierId != invoiceDetails[i].supplierId) {
        this.isMultipleSupplierSelected = true;
        this.toasterService.warning("Please select Only one supplier to send request");
        return
      }
    }
    if (this.isMultipleSupplierSelected = true) {
      let obj = {
        value: 'EpInvoice',
        arrayReq: this.data['selectedInvoiceDetails']
      }
      const dialogRef = this.dialog.open(SendWhatsappMsgDialogComponent, {
        disableClose: true,
        width: '550px',
        height: '520px',
        data: obj
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }

  }
}
