import { Component, OnInit,Input ,OnChanges,SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModelComponent } from 'src/app/shared-files/shared-component/confirmation-model/confirmation-model.component';
import { KanbanService } from '../kanban.service';
import { ToastrService } from 'ngx-toastr';

interface Permission {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-k-user-management',
  templateUrl: './k-user-management.component.html',
  styleUrls: ['./k-user-management.component.scss']
})


export class KUserManagementComponent implements OnChanges {
  @Input() board_id: string;
  selectedCar: string;
  userList: any = [];
  laneId: string;
  boardLane: any = [];
  staticBoardLane: any = [];
  permissions: any = [{ name: "None", value: '' }, { name: "View", value: 'VIEW' }, { name: "Edit", value: 'EDIT' }];
  selectedValue: any = [];
  userId: string;
  boardId: any;
  accessType = [];
  selectedItem: string;
  boardName:string;
  bindAccessLaneType:string;

  constructor(private _kService: KanbanService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog, public toasterService: ToastrService) { }

  ngOnInit() {

  }
  showBoard(val) {
    if(!this.boardId && !val) {
      return;
    }
    this.boardId = val != null ? val : this.board_id;
    this._kService.getKanbanUserList().subscribe(res => {
      if (res['status'] == 200) {
        this.userList = res['result'];
        this.selectedItem = this.userList[0]['userId'];
        this.userId = this.userList[0]['userId'];
        this.getLaneTypeAccess(this.userId);
      }
      this.fetchBoardLane(this.userId);
    })
  }
  ngOnChanges(changes: SimpleChanges) {
   if (changes && changes.board_id && changes.board_id.currentValue) {
    let val = changes.board_id.currentValue;
    this.showBoard(val);
   }
  }
  fetchBoardLane(userID) {
    this._kService.getLaneWithUserAccess(this.boardId, userID).subscribe(res => {
      if (res) {
        this.accessType = [];
        this.boardLane = res['result']['levelAccess'];
        this.boardLane = this.boardLane.sort((a, b) => a.laneDTO.position - b.laneDTO.position);
        this.staticBoardLane = res['result']['levelAccess'];
        this.boardName=res['result']['levelAccess'][0]['laneDTO']['board']['name']
        this.setMappedValue(this.staticBoardLane);
      }
    })
  }

  matchLane(val, index) {
    this.boardLane.map((data, id) => {
      if (id == index) {
        this.accessType=[];
        this.accessType.push(val);
        data['accessType'] = this.accessType;
      }
    })
  }

  saveMappedLane() {
    let laneAccessDTO = [];
    this.boardLane.map(data => {
      if (data['accessType'] && data['accessType'][0]) {
        let obj = {
          laneId: data.laneDTO['id'],
          accessType: data['accessType']
        }
        laneAccessDTO.push(obj);
      }
    })
    let reqObj = {
      "boardId": this.boardId,
      "levelAccess": laneAccessDTO,
      "userId": this.userId
    }

    this._kService.saveAuthority(reqObj).subscribe(res => {
      if (res['status'] == 200) {
        this.accessType = [];
        this.toasterService.success("Changes Saved Successfully");
      }
      console.log(res);
    });
    this.setAccessLaneType(this.bindAccessLaneType);
  }


  onListSelectionChange(val) {
    if (this.accessType != null && this.accessType.length > 0) {
      let data = {
        title: "Please Confirm",
        content: "Do you want to continue?",
        additional: "Changes will be discarded when moving to other user",
        class: "green"
      }
      const dialogRef = this.dialog.open(ConfirmationModelComponent, {
        disableClose: true,
        width: '400px',
        height: '250px',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedItem = val
          this.userId = val;
          this.fetchBoardLane(this.userId)
        }
        else {
        }
      });
    }
    else{
      this.selectedItem = val
      this.userId = val;
      this.fetchBoardLane(this.userId);
      this.getLaneTypeAccess(this.userId)
    }
  }

  setMappedValue(data) {
    this.selectedValue = [];
    data.map(data => {
      if(data['accessType'].length > 0){
        this.selectedValue.push(data['accessType'][0]);
      }
      else{
        this.selectedValue.push('');
      }
    })
  }
  setAccessLaneType(value){
    let obj={
      "accessLevel": value,
		  "levelAccess": [
		    {
		      "accessType": [
			"VIEW"
		      ],
		      "boardId": this.boardId
		    }
		  ],
		  "userId": this.userId

    }
    this._kService.changeAccessLaneType(obj).subscribe(res=>{

    })

  }

  getLaneTypeAccess(userId){
    this._kService.getAccessType(userId, this.boardId).subscribe(res=>{
      if(res['result']['levelAccess'].length>0 ){
      this.bindAccessLaneType= res['result']['levelAccess'][0]['accessLevel']
      }
    })

  }

}
