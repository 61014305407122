<section>
  <div class="main-head-wrap">
    <div class="heading" fxLayout="row" fxLayoutAlign="start center">
      <img src="../../../../../assets/icons/arrow_back.svg" matTooltip="Back" matTooltipClass="new-tooltip" class="margin-right backIcon" (click)="goToBack()"/>
      <span class="headLbl">Domestic</span>
      <span class="name"> {{this.laneData?.leadOwner?.name}}</span>

    </div>
  </div>
</section>
<section class="lead-summary">
  <div fxLayout="row" class="eximInfoSummary" fxLayoutAlign="space-around">
    <div class="lftSection">
      <div class="commonItems">
        <span class="titleInfo">Lead Summary</span>
        <span class="sourceInfo">
          <span class="lbl">Source :</span>
          <span class="value">{{this.laneData?.leadSource}}</span>
        </span>
      </div>
      <div class="commonItems">
        <span class="titleInfo">Contact Details</span>
        <span class="compName">{{this.primaryContact?.name}}</span>
        <div class="d-flex">
          <ng-container>
            <span class="phoneNo">{{this.primaryContact?.phone}},</span>
          </ng-container>
          <span class="emailTxt">{{this.primaryContact?.email}}</span>
        </div>

      </div>
    </div>

    <div class="rhtOtherDetails">
      <span class="headTitleTxt">Other Details</span>
      <div class="otherDetailsContent">
        <div class="commonODInfo">
          <i class="ri-user-follow-line spIcon"></i>
          <div class="cibilScore">
            <span class="textWithIcon">
              {{buyerLength}}
            </span>
            <span class="bottomTxt"> No. of {{uploadTypeName}} </span>
          </div>
        </div>

        <div class="commonODInfo">
          <mat-icon aria-hidden="false" aria-label="Company Name" fontIcon="home" matTooltip="Limit Applied"
            matTooltipClass="new-tooltip" class="material-symbols-outlined limit-icon">monetization_on</mat-icon>
          <div class="cibilScore">
            <span class="textWithIcon">
              {{buyerLimit}}
            </span>
            <span class="bottomTxt"> Limit Required </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="tabs-filters">
  <div fxLayout="row" fxLayoutAlign="space-between" class="h-100">
    <div class="left">
      <mat-tab-group class="outline-tab" animationDuration="0ms" mat-align-tabs="start" mat-ripple-disabled="true" class="h-100"  (selectedIndexChange)="tagChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            Lead Details
          </ng-template>

          <div fxLayout="row" fxLayoutAlign="start" class="h-100">
            <div class="sidebar">
              <ul *ngFor="let lane of toatalLanesList">
                <li *ngIf="lane.position <= currentLanePosition && lane.position != 3"><a (click)="reRoute(lane.routePath)" routerLinkActive="active"><span> {{lane.laneName}}</span> <i class="ri-arrow-right-s-line"></i></a></li>
              </ul>
            </div>

            <div class="right-sec">
              <router-outlet></router-outlet>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            History
          </ng-template>
          <app-domestic-history [data]="data$"></app-domestic-history>
        </mat-tab>
      </mat-tab-group>
    </div>


    <div class="right d-flex align-items-center">
      <div class="input-group leadsField" *ngIf="showLaneChange">
        <mat-form-field class="mat-select" appearance="outline">
          <mat-select placeholder="Select value" [(ngModel)]="laneId" (selectionChange)="laneChange($event)" [disabled]="!editAccess">
            <mat-option class="mat-option" *ngFor="let item of laneList" [value]="item.laneId">{{item.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="watcherPopup()"><i class="ri-eye-line"></i>Watcher</button>
      <button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="onSideTabToggle()"><i class="ri-user-line"></i>{{laneData?.assignee[0]?.user?.name}} {{laneData?.assignee?.length-1 == 0 ? '':'+'}} {{laneData?.assignee?.length-1 == 0 ? '':laneData?.assignee?.length-1}}</button>
      <button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="comments()"><i class="ri-chat-1-line"></i>Comment</button>
    </div>
  </div>
</section>
