import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceCreationService } from '../../invoice-creation.service';
@Component({
  selector: 'app-address-update-dialog',
  templateUrl: './address-update-dialog.component.html',
  styleUrls: ['./address-update-dialog.component.scss']
})
export class AddressUpdateDialogComponent implements OnInit {
  addressUpdateForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddressUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public invoiceCreationService: InvoiceCreationService) { }

  ngOnInit() {
    this.addressUpdateForm = this.fb.group({
      tradeName: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', Validators.required],
      area: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      gstIn: ['', Validators.required]
    });
  }
  submitAddress() {
    if (this.addressUpdateForm.valid) {
      this.invoiceCreationService.upateAddress(this.addressUpdateForm.value).subscribe(res => {
        console.log("eee", res)
      })
    }
  }

}
