<div class="popup-sidebar">
  <div class="comment-input">
    <textarea [formControl]="commentControl" [placeholder]="placeHolderCommentText" #commentField
      (keyup)="onKeyUp($event)"></textarea>
    <div class="user-suggestions" *ngIf="showUserSuggestions">
      <ul>
        <li *ngFor="let user of filteredUsers" (click)="selectUser(user)">
          {{user}}
        </li>
      </ul>
    </div>
    <input [matDatepicker]="picker" class="hideInput">
    <mat-datepicker #picker></mat-datepicker>
    <div class="actionDiv">
      <div>
        <button class="comment-btn" (click)="addComment()">Comment</button>
        <button class="cancel-btn" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
  <div class="comments-list">
    <div *ngFor="let comment of comments" class="comment">
      <div class="user-profile">
        <div class="texture-profile">{{ getProfileName(comment.byUser) }}</div>
        <div class="user-info">
          <div class="user-name">{{ comment.byUser.name }}</div>
          <small class="comment-time">{{ getCommentTime(comment) }}</small>
        </div>
      </div>
      <div class="formatted-comment-text" [innerHTML]="formatComment(comment)"></div>
    </div>
  </div>
</div>