<div class="dialog-box">
  <div class="text-p">
    <h5 *ngIf="!ismanualUpload && !actionBtnOnEp && !actionCanclbtn && !isKanbanLead && !isBuyerData && !isPreSale && !isPresaleReady && !markDisbursed">This action can impact multiple suppliers. Do you wish to continue?
    </h5>
  
    <h5 class="dlytxt" *ngIf="actionCanclbtn"><strong>Invoice Number: </strong>  {{invoiceNumber}} is cancellation. Do you want to continue?</h5>
  
    <h5 *ngIf="actionBtnOnEp" class="dlytxt"> <strong>Invoice Number: </strong> {{invoiceNumber}} is delaying from {{expectedPymntDate | date}} to {{delayedDate | date}}. Do you want to continue? </h5>
    <h5 *ngIf="isKanbanLead || isBuyerData || isPreSale" class="dlytxt"> Are you sure you want to delete?? </h5>
    <h5 *ngIf="isPresaleReady">Are you sure you want to move leads to LOS?</h5>
    <div *ngIf="isPresaleReady" class="form-field">
      <div class="multi-select" >
        <div>
          <mat-label>Select Exim LOS Sales Agent</mat-label>
        </div>
          <div class="form">
            <mat-form-field class="mog3_matinput" appearance="outline">
              <input type="text" aria-label="Number" matInput [formControl]="myControl"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUserList($event)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
      </div>
  
      <div class="secondBox">
        <div>
          <mat-label class="lead-label">Select Lead Watcher</mat-label>
        </div>
          <div class="form">
            <mat-form-field appearance="fill" class="selectearlypaystatus">
              <mat-select #matRef1 multiple (selectionChange)="selectLeadWatcher($event.value)">
                <mat-option *ngFor="let type of this.fetchUser" [value]="type.userId">{{type.userName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>
    </div>
    <div class="slctOptin" *ngIf="isKanbanLead">
      <mat-radio-group class="suplierRadbtn" (change)="selectColdLeadReason($event.value)"
      [(ngModel)]="coldLeadMoveReason" *ngIf="isReasonBtnVisible">
      <mat-radio-button value="COLD_LEAD">Cold Lead</mat-radio-button>
      <mat-radio-button value="REJECTED_DUE_TO_RISK">Rejected due to Risk</mat-radio-button>
    </mat-radio-group>
    </div>
    <h5 *ngIf="ismanualUpload || markDisbursed">
      <span style="font-size: 14px;">
        {{manualText}} <br>
        Please confirm to proceed
      </span>
    </h5>
    <div class="actionbtn" [ngClass]="isPresaleReady == true ? 'button-margin' : ''">
      <button type="button" mat-dialog-close mat-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
      <button mat-raised-button class="savebtn" (click)="saveDetails()" [disabled]="isValid()">{{saveLabel}}</button>
    </div>
  </div>
</div>

