import { Component, OnInit } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gst-analysis',
  templateUrl: './gst-analysis.component.html',
  styleUrls: ['./gst-analysis.component.scss']
})
export class GstAnalysisComponent implements OnInit {
  laneTaskId:any;
  laneTaskIDDetail:any;
  companyPan: any;
  constructor(
    public toasterService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public kService: KanbanService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x.id;
      this.kService.setLaneTaskId(x.id);
    });
    this.fetchdata(this.laneTaskId);
  }
  fetchdata(x) {
    if(x !== null && x !== "undefined"){
    this.kService.fetchData(x).subscribe({
      next: (resp: any) => {
        this.kService.setLaneData(resp.result);
        this.laneTaskIDDetail = resp.result;
        this.companyPan = resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.companyPAN;
        this.kService.setGstNo(resp.result.domesticData['APPLICATION_AND_DOCUMENTS_SUBMITTED'].companyDetails.companyPAN);
      },
    });}
  }

}
