<div class="d-flex justify-content-between sendBoxHead">
	<h2>Consent Data</h2>
	<button mat-dialog-close class="cancel pointer"><i class="ri-close-line"></i></button>
</div>
<div class="sendTable mat-elevation-z8">
	<table mat-table [dataSource]="dataSource" class="w-full">
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef>Director Name</th>
			<td mat-cell *matCellDef="let element">{{ element.name }}</td>
		</ng-container>

		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef>Director Email</th>
			<td mat-cell *matCellDef="let element">{{ element.email }}</td>
		</ng-container>

		<ng-container matColumnDef="consent">
			<th mat-header-cell *matHeaderCellDef>Consent Status</th>
			<td mat-cell *matCellDef="let element">{{ element.status }}</td>
		</ng-container>

        <ng-container matColumnDef="timeStamp">
			<th mat-header-cell *matHeaderCellDef>Time Stamp</th>
			<td mat-cell *matCellDef="let element">{{ setDateTime(element) }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
	</table>
</div>
