import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceCreationComponent } from './invoice-creation/invoice-creation.component';
import { SharedModulesModule } from '../shared-modules/shared-modules.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadInvoicesComponent } from './upload-invoices/upload-invoices.component';
import { AddressUpdateDialogComponent } from './invoice-creation/address-update-dialog/address-update-dialog.component';
import { EntityCreationComponent } from './entity-creation/entity-creation.component';
import { EntityFormComponent } from './entity-creation/entity-form/entity-form.component';
import { UploadInvoiceComponent } from './entity-creation/upload-invoice/upload-invoice.component';
import { InvoicceCategoryComponent } from './entity-creation/invoicce-category/invoicce-category.component';
import { ConfirmationModelComponent } from './confirmation-model/confirmation-model.component';

@NgModule({
  declarations: [InvoiceCreationComponent, UploadInvoicesComponent, AddressUpdateDialogComponent, EntityCreationComponent, EntityFormComponent, UploadInvoiceComponent, InvoicceCategoryComponent, ConfirmationModelComponent],
  imports: [
    CommonModule,
    SharedModulesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [EntityCreationComponent],
  entryComponents: [AddressUpdateDialogComponent]

})
export class SharedComponentModule { }
