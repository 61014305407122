<div class="add-wp-contact">
  <h2 style="text-transform: capitalize;">Add Contact - {{elementData.businessName}}</h2>
  <div class="sb_dropmenu f_field">
    <label>User <span>*</span></label>
    <mat-form-field appearance="outline" class="sb_filt_fields">
      <mat-select class="sb_expandMenu" placeholder="Select" (selectionChange)="onUserChange($event.value)">
        <mat-option *ngFor="let list of userList" [value]="list.id">{{list.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="sb_dropmenu f_field">
    <label>Type <span>*</span></label>
    <mat-form-field appearance="outline" class="sb_filt_fields">
      <mat-select class="sb_expandMenu" placeholder="Select" (selectionChange)="onUserTypeChange($event.value)">
        <mat-option value="EMAIL">Email </mat-option>
        <mat-option value="PHONE">Phone </mat-option>
      </mat-select>
    </mat-form-field>  
  </div>
  <!--  -->
  <div class="f_field" *ngIf="type != null && type == 'PHONE'">
    <label>Number <span>*</span></label>
    <input type="number" placeholder="Enter 10 digits number" [(ngModel)]="userNumber">
  </div>
  <div class="f_field" *ngIf="type != null && type == 'EMAIL'">
    <label>Email <span>*</span></label>
    <input type="text" placeholder="Enter Email" [(ngModel)]="userId">
  </div>


  <div mat-dialog-actions>
    <button type="button" mat-flat-button class="cancel" (click)="closeDialog()">Cancel</button>
    <button mat-flat-button class="confirm" [disabled]="!type || !userId" (click)="addWhatsappContact()">Add Contact</button>
  </div>
</div>