<div class="view " fxLayout="column">
    <div class="docdetails" fxLayout="row" fxFlex="10">
        <span (click)="ciNavigation()" class="hver"><strong>Commission
                Invoices</strong></span>
        <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
        <span><strong>{{data.supplierAccount.businessName}} ({{data.invoiceNumber}})</strong></span>
    </div>
    <div fxLayout="column" class="fieldDetails docdetails">
        <div class="full-width top-align " fxLayout="row" fxFlex="20">
            <h5>Invoice</h5>
        </div>
        <div fxLayout="row" class="full-width">
            <div class="halfField add1" fxLayout="column">
                <label>To</label>
                <span><strong>{{data.supplierAccount.businessName | titlecase}}
                    </strong></span>
            </div>
            <div class="halfField add1" fxLayout="column">
                <label>From</label>
                <span><strong>{{data.customerAccount.businessName | titlecase}}
                    </strong></span>
            </div>
            <div class="halfField " fxLayout="column">
                <label>Invoice No.</label>
                <span>{{data.invoiceNumber}}</span>
            </div>
            <div class="halfField" fxLayout="column">
                <label>Invoice Date</label>
                <span>{{data.invoiceDate | date}}</span>
            </div>

            <div class="halfField" fxLayout="column">
                <label>Currency</label>
                <span>INR</span>
            </div>
        </div>


        <div id="table-wrapper">
            <div id="table-scroll">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Financer Name</th>
                            <th>Amount Request</th>
                            <th>Discount</th>
                            <th>Commission</th>
                            <th>Amount</th>
                            <th>Created Date</th>
                            <th>Expected Payment Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data['items']; let i = index;">
                            <td>{{i+1+(pageNo*pageSize)}}</td>
                            <td><span>{{item.financier.name}}</span></td>
                            <td> <span>{{item.amountRequested | currency:'INR'}}</span></td>
                            <td> <span>{{item.discount | currency:'INR'}} </span></td>
                            <td> <span>{{item.commission | currency:'INR'}}</span></td>
                            <td><span>{{item.amount | currency:'INR'}}</span></td>
                            <td> <span>{{item.createdAt | date}}</span></td>
                            <td> <span>{{item.expectedPaymentDate | date}}</span></td>

                        </tr>
                    </tbody>
                </table>
                <mat-paginator [length]="totalRecords" [pageIndex]="pageNo" [pageSize]="pageSize"
                    (page)="getPaginatorData2($event)" showFirstLastButtons>
                    >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>