<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap" *ngIf="isAnchor || authService.isCredlixUser()">
      <div class="lft">
        <button class="filter back" mat-button (click)="navigateToSupplierPage()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </section>
    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">
      <div class="breadcrumb" *ngIf="isAnchor || authService.isCredlixUser()">
        <i class="ri-speed-fill first"></i>
        <span (click)="breadcrumNavigation()" class="hyperlinktxt">Borrowers</span>
        <div class="chek" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;" ></i>
          <span *ngIf="setbuyerName !=undefined" (click)="navigateToSupplierPage()"
          class="hyperlinktxt">{{setbuyerName}} ({{setbuyerID}})
          </span>
          <span *ngIf="setbuyerName ==undefined">No Invoices Found for this buyer -({{setbuyerID}})
          </span>
         
        </div>
        <div *ngIf="setbuyerName !=undefined" class="chek" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;" ></i>
          <span style="color: #000;">Transactions</span>
        </div>

      </div>
      <div class="breadcrumb" *ngIf="isBuyer">
        <span *ngIf="setbuyerName !=undefined" (click)="navigateToSupplierPage()"
        class="hyperlinktxt">{{setbuyerName}} ({{setbuyerID}})
        </span>
        <div *ngIf="setbuyerName !=undefined" class="chek" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;" ></i>
          <span>Transactions</span>
        </div>
      </div>
    </div>

    <div class="mat_table">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort
      style="width: 100%;padding-left: 2%;" [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">
      <!-- <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef style="width: 50px;">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="transactionId">
        <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
        <td mat-cell *matCellDef="let element" style="color: #0787dd;">
          <span> {{element.transactionId}} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
        <td mat-cell *matCellDef="let element">
          <span> {{element.transactionDate | date}} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="amountRequested">
        <th mat-header-cell *matHeaderCellDef> Transaction Amount</th>
        <td mat-cell *matCellDef="let element">
          <span> {{element.amountRequested}} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="expiryDate">
        <th mat-header-cell *matHeaderCellDef> Due Date </th>
        <td mat-cell *matCellDef="let element">
          <span> {{element.expiryDate | date}} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="balanceOutstanding">
        <th mat-header-cell *matHeaderCellDef> Balance Outstanding </th>
        <td mat-cell *matCellDef="let element">
          <span> {{element.balanceOutstanding }} </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionParticular">
        <th mat-header-cell *matHeaderCellDef> Transaction Particular</th>
        <td mat-cell *matCellDef="let element">
          <span> {{element.transactionParticular }} </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" showFirstLastButtons (page)="getPaginatorData()">
  </mat-paginator>

  </div>
</div>