import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-supplier-dialog',
  templateUrl: './add-supplier-dialog.component.html',
  styleUrls: ['./add-supplier-dialog.component.scss']
})
export class AddSupplierDialogComponent implements OnInit {
  supplierID: any;
  isfileUploadVisible: boolean = true;
  isSelectedFileVisible: boolean = false;
  file: any;
  isEmailChecked: boolean = true;
  fileResponse: boolean = false;
  constructor(public dialogRef: MatDialogRef<AddSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public pagesService: PagesService, public toasterService: ToastrService) { }


  ngOnInit() {
  }


  addSuppliers(value) {
    if (value == null) {
      this.toasterService.error("Please add supplier ID");
    }
    else {
      let obj={
        supplierId : value,
        sendEmail:this.isEmailChecked
      }
      this.pagesService.addSuppliers(obj).subscribe(res => {
        this.dialogRef.close();
        if (res['status'] == 200) {
          this.pagesService.offSpinner();
          this.toasterService.success("Supplier Added");
        }
      })
    }
  }
  closeDialog() {
    this.dialogRef.close(true);
  }

  importMultipleSupplier() {
    this.isfileUploadVisible = false;
    this.isSelectedFileVisible = true;
  }

  onFileChanged(event) {
    this.file = event.target.files[0];
  }


  fileUpload(file?) {
    if (file) {
      this.file = file;
      this.file.name = file.name;
    }
    let sendEmail= this.isEmailChecked
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    this.pagesService.bulkUserUploaded(uploadData, sendEmail).subscribe(file => {
      if (file['status'] == 200) {
        this.toasterService.success("File has been Succesfully Uploaded.");
        this.dialogRef.close();
      }
    })
  }
  clearStack(event) {
    event.target.value = null;
  }
  close() {
    this.file = null;
    this.fileResponse = false;
  }

  templateDownloadCSV() {
    this.JSONToCSVConvertor(this.templateHeaderList(), 'template', true);
  }

  templateHeaderList() {

    let responseList = [];
    let orderObject = {};

    orderObject['Supplier ID'] = '';

    responseList.push(orderObject);

    return responseList;
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
      var row = "";
      for (var index in arrData[0]) {
        row += index + ',';
      }

      row = row.slice(0, -1);
      CSV += row + '\r\n';
    }

    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    var fileName = "MyReport_";
    fileName += ReportTitle.replace(/ /g, "_");
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.setAttribute('visibility', 'hidden');
    link.download = "template_sample" + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  addsuplier() {
    this.isfileUploadVisible = true;
    this.isSelectedFileVisible = false;
  }

  sendWelcomeEmail(event) {
    this.isEmailChecked= event;
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.fileUpload(files[0]);
    }
  }
}

