
<mat-tab-group>
  <mat-tab label="Report">
    <table mat-table [dataSource]="dataSource" class="payout_table">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Report Name </th>
        <td mat-cell *matCellDef="let element"> {{element}} </td>
      </ng-container>
    
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button class="download" mat-button matTooltip="Download File" (click)="downloadPayoutReport(element)">
          <i class="ri-download-line"></i>
        </button></td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-tab>
  <mat-tab label="Requested Downloads "> Requested Downloads </mat-tab>
</mat-tab-group>