<div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
  <div class="listingFilters" *ngIf="isInvoiceFilterVisible">
    <div class="search searchBoxtop cusSearch input-btn" fxLayout="row" >
      <input type="text" fxFlex placeholder="Search Invoice ID" [(ngModel)]="searchPaymentID"
        (keyup.enter)="getFilePaymentTransaction(false)" (keydown.space)="$event.preventDefault();">
      <button class="searchbtn">
        <i class="ri-search-line" (click)="getFilePaymentTransaction(false)" *ngIf="showSearchIcon"></i>
        <i class="ri-close-line" *ngIf="ShowCloseIcon" (click)="resetSearchText()"></i>
      </button>
    </div>
    <div class="searchBoxtop">
    <mat-form-field appearance="fill" class="selectearlypaystatus biztype">
      <mat-label>Status</mat-label>
      <mat-select #matRef multiple (selectionChange)="getPaymentStatus($event.value)" [(ngModel)]="setPaymentStatus">
        <mat-option *ngFor="let type of paymentStatus" [value]="type.value">{{type.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="searchBoxtop">
      <mat-form-field appearance="fill" class="selectearlypaystatus biztype">
        <mat-label>Party Type</mat-label>
        <mat-select #matRef  (selectionChange)="getPaymentPartyType($event.value)" [(ngModel)]="setPartyType">
          <mat-option *ngFor="let type of partyType" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    <div class="leftfilteraction">
      <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable"
        (click)="getFilePaymentTransaction(false)">Apply</button>
      <button mat-flat-button class="resetbtn" #button [disabled]="isClearButtonEnable"
        (click)="clearPaymentFilters()">Clear
        All</button>
    </div>
  </div>
<div class="mat_table" fxFlex="100">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table_invoice suplierTable"
    [hidden]="!(dataSource.data?.length>0)">

    <ng-container matColumnDef="invoiceID">
      <th mat-header-cell *matHeaderCellDef> Invoice/Doc ID</th>
      <td mat-cell *matCellDef="let element"> {{element.fileEntry.invoiceNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentTo">
      <th mat-header-cell *matHeaderCellDef> Payment To</th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{element.paymentTo}}</span>
       
      </td>
    </ng-container>

    <ng-container matColumnDef="partyType">
      <th mat-header-cell *matHeaderCellDef> Party Type</th>
      <td mat-cell *matCellDef="let element">
        <span > {{element.partyType}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status</th>
      <td mat-cell *matCellDef="let element"> 
        <span class="status-dot" [ngClass]="getStatusColor(element.status)"> ● </span>
        {{formatPaymentType(element.status| titlecase)}} </td>
    </ng-container>

    <ng-container matColumnDef="utr">
      <th mat-header-cell *matHeaderCellDef> UTR/ Status</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.status=='PAID'">{{_.defaultTo(element.utr, "-")}}</span>
        <span class="tableCell-overflow spn" *ngIf="element.status !='PAID'" [matTooltip]="element.remarks">{{element.remarks | titlecase }}</span>
        
      </td>
    </ng-container>
    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef> Payment Date</th>
      <td mat-cell *matCellDef="let element"> {{_.defaultTo(element.paymentDate, "-")}} </td>
    </ng-container>


    <ng-container matColumnDef="transactionAmount">
      <th mat-header-cell *matHeaderCellDef> Transaction Amount</th>
      <td mat-cell *matCellDef="let element"> {{element.amount | number}}
      </td>
    </ng-container>
    <ng-container matColumnDef="paymentType">
      <th mat-header-cell *matHeaderCellDef> Payment Type</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.paymentType != null && element.paymentType == 'JOURNAL_PAYMENT'"> Misc. Payment</span>
        <span *ngIf="element.paymentType != null && element.paymentType != 'JOURNAL_PAYMENT'">
          {{formatPaymentType(element.paymentType| titlecase)}} </span>

      </td>
    </ng-container>
    <ng-container matColumnDef="downAction">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button class="sort btnSort" mat-button [matMenuTriggerFor]="downloadMenu" *ngIf="element.status== 'FAILED'">
          <mat-icon class="icon_details">more_vert</mat-icon>
          <mat-menu #downloadMenu="matMenu">
            <button mat-menu-item> ReInit Transaction</button>
          </mat-menu>
        </button>

      </td>
    </ng-container>
    <ng-container matColumnDef="accountTo">
      <th mat-header-cell *matHeaderCellDef> Beneficiary Account </th>
      <td mat-cell *matCellDef="let element"> 
        <span [matTooltip]="getAccountToolTip(element)" matTooltipClass="line-broken-tooltip" *ngIf="element.bankDetails !=null">{{element.bankDetails.accountNumber}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
    [pageSize]="pageSize" (page)="getPaginatorData()" showFirstLastButtons>
  </mat-paginator>
  <h5 class="text-p-wrap" *ngIf="dataSource.data.length==0">No Record Found</h5>

</div>
</div>