import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material'
import { error } from 'console'
import moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { KanbanService } from 'src/app/component/credlix-board/kanban.service'

@Component({
	selector: 'app-show-consent-status',
	templateUrl: './show-consent-status.component.html',
	styleUrls: ['./show-consent-status.component.scss'],
})
export class ShowConsentStatusComponent implements OnInit {
	directorTable: any[] = []
	dataSource = new MatTableDataSource(this.directorTable)
	displayedColumns: any[] = ['name', 'email', 'consent', 'timeStamp']
	constructor(public dialogRef: MatDialogRef<ShowConsentStatusComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public toasterService: ToastrService, public kService: KanbanService) {}

	ngOnInit(): void {
		this.getConsentStatus(this.data)
	}

	getConsentStatus(laneTaskId: any) {
		this.kService.getStatus(laneTaskId).subscribe({
			next: (resp: any) => {
				if (resp.success) {
					this.dataSource.data = resp.result
				} else {
					this.toasterService.error(resp.message)
					this.dialogRef.close()
				}
			},
			error: (err: any) => {
				this.toasterService.error(err.message)
				this.dialogRef.close()
			},
		})
	}
	setDateTime(element: any) {
		if (element.consentDate) {
			let time = new Date(element.consentDate)
			let properDate = moment(time).format('dddd, MMMM Do YYYY, h:mm:ss a')
			return properDate
		} else {
			return ''
		}
	}
}
