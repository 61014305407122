<div style="display: flex;flex-direction: row;">
  <h2 class="label">Insurance Details</h2>
  <i class="ri-close-line closeBtn" (click)="dialogRef.close()"></i>
</div>
<div class="insurance-data">
  <table mat-table [dataSource]="allInsuranceInfo" class="mat-elevation-z8" style="width: 100%;margin: 15px 0 0 0;">

    <ng-container matColumnDef="buyer">
      <th mat-header-cell *matHeaderCellDef> Buyer Name </th>
      <td mat-cell *matCellDef="let element"> {{element?.buyerName ? element.buyerName : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="insurer">
      <th mat-header-cell *matHeaderCellDef> Insurer Name </th>
      <td mat-cell *matCellDef="let element"> {{element.insuranceProvider ? element.insuranceProvider : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status. </th>
      <td mat-cell *matCellDef="let element"> {{element.finalInsuranceStatus ? element.finalInsuranceStatus :
        element.insuranceStatus ? element.insuranceStatus : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef> Rating </th>
      <td mat-cell *matCellDef="let element"> {{element.finalInsuranceRating ? element.finalInsuranceRating :
        element.insuranceRating ? element.insuranceRating : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="appliedAmount">
      <th mat-header-cell *matHeaderCellDef> Applied Amount </th>
      <td mat-cell *matCellDef="let element"> {{element.finalInsuranceAppliedAmount ?
        element.finalInsuranceAppliedAmount : element.insuranceAppliedAmount ? element.insuranceAppliedAmount : '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="approvedAmount">
      <th mat-header-cell *matHeaderCellDef> Approved Amount</th>
      <td mat-cell *matCellDef="let element"> {{element.finalInsuranceApprovedAmount ?
        element.finalInsuranceApprovedAmount : element.insuranceApprovedAmount ? element.insuranceApprovedAmount :
        '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="buyerAddress">
      <th mat-header-cell *matHeaderCellDef>Buyer Address</th>
      <td mat-cell *matCellDef="let element"> {{element.finalChangeInAddressOfBuyer ?
        element.finalChangeInAddressOfBuyer : element.changeInAddressOfBuyer ? element.changeInAddressOfBuyer : '--'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="insuranceApprovedDate">
      <th mat-header-cell *matHeaderCellDef>Insurance Approved Date</th>
      <td mat-cell *matCellDef="let element">
        {{element.finalInsuranceApprovedDate ? (element.finalInsuranceApprovedDate | date:'dd/MM/yyyy')
        : element.insuranceApprovedDate ? (element.insuranceApprovedDate | date:'dd/MM/yyyy') : '--'}} </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Comment </th>
      <td mat-cell *matCellDef="let element"> {{element.finalInsuranceComment ? element.finalInsuranceComment :
        element.insuranceComment ? element.insuranceComment : '--'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumn" style="background-color: cornsilk;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
  </table>
</div>
