export class GETCONSTANT {
    BizTypeFilterList: any = [
        { id: '1', roleName: 'ALL', roleStatus: '' },
        { id: '2', roleName: 'ABFRL', roleStatus: 'isAbfrl' },
        { id: '3', roleName: 'Moglix', roleStatus: 'moglix' }
    ];
    approvedCheckList: any = [
        { id: '1', roleName: 'ALL', roleStatus: '' },
        { id: '2', roleName: 'Approved', roleStatus: 'isEligibleForEP' },
        { id: '3', roleName: 'Not Approved', roleStatus: 'notApproved' }
    ];
    InvoiceFilterList: any = [
        { id: '1', roleName: 'Approval Awaited', roleStatus: 'APPROVAL_AWAITED', isChecked: false },
        { id: '2', roleName: 'On Hold', roleStatus: 'ON_HOLD', isChecked: false },
        { id: '3', roleName: 'Eligible', roleStatus: 'ELIGIBLE', isChecked: false },
        { id: '4', roleName: 'Requested', roleStatus: 'PAYMENT_REQUESTED', isChecked: false },
        { id: '5', roleName: 'Partially Settled', roleStatus: 'PARTIALLY_PAID', isChecked: false },
        { id: '6', roleName: 'Fully Settled', roleStatus: 'PAID', isChecked: false },
        { id: '7', roleName: 'Expired', roleStatus: 'EXPIRED', isChecked: false },
    ];
    invoiceDateList = [
        { id: '1', roleName: 'Anytime', roleStatus: '' },
        { id: '2', roleName: 'Today', roleStatus: 'today' },
        { id: '3', roleName: 'Yesterday', roleStatus: 'yesterday' },
        { id: '4', roleName: 'Last 7 Days', roleStatus: '7' },
        { id: '5', roleName: 'Last 30 Days', roleStatus: '30' },
        { id: '6', roleName: 'Custom', roleStatus: '-1' }
    ]
    dueDateList = [
        
        { id: '1', roleName: 'Tomorrow', roleStatus: 'tommorow' },
        { id: '2', roleName: 'Next 7 Days', roleStatus: '7' },
        { id: '3', roleName: 'Next 30 Days', roleStatus: '30' },
        { id: '4', roleName: 'Custom', roleStatus: '-1' }
    ];
    approvedList = [
        { id: '1', roleName: 'Today', roleStatus: 'today'},
        { id: '2', roleName: 'Yesterday', roleStatus: 'yesterday'},
        { id: '3', roleName: 'Last 7 Days', roleStatus: '7' },
        { id: '4', roleName: 'Custom', roleStatus: '-1' },

    ];
    expiringIn = [
        { id: '1', roleName: 'Anytime', roleStatus: '' },
        // { id: '2', roleName: 'Today', roleStatus: 'today' },
        { id: '2', roleName: 'Tomorrow', roleStatus: 'tommorow' },
        { id: '3', roleName: 'Next 7 Days', roleStatus: '7' },
        { id: '4', roleName: 'Custom', roleStatus: '-1' }
    ]

    addedCredlixList = [
        { id: '1', roleName: 'Anytime', roleStatus: '' },
        { id: '2', roleName: 'Today', roleStatus: 'today' },
        { id: '3', roleName: 'Yesterday', roleStatus: 'yesterday' },
        { id: '4', roleName: 'Last 7 Days', roleStatus: '7' },
        { id: '5', roleName: 'Custom', roleStatus: '-1' }
    ]

    requestList = [
        { id: '1', roleName: 'Anytime', roleStatus: '' },
        { id: '2', roleName: 'Today', roleStatus: 'today' },
        { id: '3', roleName: 'Yesterday', roleStatus: 'yesterday' },
        { id: '4', roleName: 'Last 7 Days', roleStatus: '7' },
        { id: '5', roleName: 'Custom', roleStatus: '-1' }
    ]
}