import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { requestSource } from '../ep-graph-data'
import { dashboardData } from '../../../../constants/dashboard-data';
import { PagesService } from 'src/app/pages/pages.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let download = require('highcharts/modules/exporting');

download(Highcharts);
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: 'app-ep-source-request-stats',
  templateUrl: './ep-source-request-stats.component.html',
  styleUrls: ['./ep-source-request-stats.component.scss']
})
export class EpSourceRequestStatsComponent implements OnInit {
  @Input() data: any;
  requestSource:any = requestSource;
  loading: boolean = false;
  invoiceDuration: string = 'Last 6 Months';
  selectedDate: any;
  startDateMillisec: any;
  EndDateMillisec: any;
  startDate: any;
  endDate: any;
  charts: any;
  durationArr: string[] = ['Last 6 Months','Last 3 Months', 'Last 30 Days', 'Last 7 Days', 'Custom'];
  @Input()supplierLinks = [];
  @Input()lendersData = [];

  constructor(private pageService: PagesService) { }

  ngOnInit() {
  }

  downloadImg(){
    this.charts.exportChart({type:"image/jpeg"});
  }

  durationChange(event, val, reportType) {
    this.invoiceDuration = val;
    if (val == "Custom") {
      event.stopPropagation();
    }
    else{
      this.getGraphData();
    }
  }

  datesUpdated(event) {
    if (event.startDate != null && event.endDate != null) {
      this.startDateMillisec = event.startDate._d
      this.startDate = moment(this.startDateMillisec).format('YYYY-MM-DD');
      this.EndDateMillisec = event.endDate._d
      this.endDate = moment(this.EndDateMillisec).format('YYYY-MM-DD');
      this.getGraphData();
      event.preventDefault();
    }
  }

  getRequestData(){
    let supplierIds = [];
    let lenderIds = [];
    let invoiceData = {};
    this.supplierLinks.map(val=>{
      if(val['isChecked']){
        supplierIds.push(val['searchkey'])
      }
    })
    this.lendersData.map(val=>{
      if(val['isChecked']){
        lenderIds.push(val['id'])
      }
    })
    if(this.invoiceDuration == 'Custom'){
      invoiceData['type'] = 'range';
      invoiceData['start_value'] = this.startDate;
      invoiceData['end_value'] = this.endDate;
    }
    else{
      invoiceData['type'] = 'range';
      invoiceData['range_type'] = 'last';
      if(this.invoiceDuration == 'Last 6 Months')
        invoiceData['custom'] = '180';
      else if(this.invoiceDuration == 'Last 3 Months')
        invoiceData['custom'] = '90';
      else if(this.invoiceDuration == 'Last 30 Days')
        invoiceData['custom'] = '30';
      else
        invoiceData['custom'] = '7';
    }
    let reqObj =
    {
      "where": {
        "invoiceDate": invoiceData
      }
    }
    if(supplierIds.length > 0){
      let obj = {
        "type": "in",
        "value": supplierIds
      }
      reqObj['where']['supplierId'] = obj;
    }
    if(lenderIds.length > 0){
      let obj = {
        "type": "in",
        "value": lenderIds
      }
      reqObj['where']['lenderId'] = obj;
    }
    return reqObj;
  }

  getGraphData(){
    let reqObj = this.getRequestData();
    reqObj['reportType'] = "requestedBySource";
    this.requestSource = requestSource;
    this.pageService.getAnchorDashboard(reqObj).subscribe(res => {
      if (res['status'] == 200) {
        for(let i=0; i<res['result'][dashboardData.requestedBySource].length; i++){
          let amount = res['result'][dashboardData.requestedBySource][i]['doc_count']
          if(res['result'][dashboardData.requestedBySource][i]['name'] == 'WHATSAPP'){
            this.requestSource['series'][0]['data'][0]['y'] = amount;
          }
          else if(res['result'][dashboardData.requestedBySource][i]['name'] == 'WEB'){
            this.requestSource['series'][1]['data'][0]['y'] = amount;
          }
        }
        this.charts = Highcharts.chart('requestSourceGraph',this.requestSource);
      }
    })
  }
}
