<div class="mainContent">
  <div class="headerContent">
      <span class="header">Upload File</span>
      <mat-icon class="dialogClose" (click)="closedialog()">close</mat-icon>
  </div>
  <div class="uploadContentDiv">
      <span (click)="fileInput.click()" class="uploadContent"> <input style="display: none" type="file" (change)="onFileChanged($event)" [multiple]="false"
          (click)="clearStack($event)" #fileInput accept=".xlsx,.xls">
          <div><img class="alignImage" src="/assets/images/file.png">
          </div>
          {{this.file ? this.fileName : 'Select your file'}}
      </span>
  </div>
  <div class="actionDiv">
      <button class="uploadButton mr-10" type="button" (click)="downloadTemplate()" matTooltip="Download Template"><mat-icon>download</mat-icon></button>
      <button class="uploadButton" type="button" (click)="uploadFile()">Upload</button>
  </div>
</div>