<mat-dialog-content class="email-popup-content">
  <div class="form-content">
    <button mat-icon-button mat-dialog-close aria-label="Close popup" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
    <p class="label"><strong>Name: </strong>{{name}}</p>
    <form #emailForm="ngForm" class="email-form">
      <div class="input-field">
        <!-- <input type="email" id="email" name="email" placeholder="Enter your email" [(ngModel)]="email"
        #emailModel="ngModel" required email> -->
        <mat-form-field class="example-chip-list">
          <label for="email">Email:</label>
          <input class="inpStyle" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
          (matChipInputTokenEnd)="add($event)" required email >

          <button class="sendToUser" mat-raised-button color="primary" (click)="sendToUser()" [disabled]="this.emailList.length < 1">Send to User</button>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let element of emailList" [selectable]="selectable" [removable]="removable"
              (removed)="remove(element)">
              {{element.name}}
              <mat-icon matChipRemove (click)="remove(element)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="error-messages">
        <p *ngIf="emailFormatError">Please enter a valid email.</p>
        <p *ngIf="emailexistError">Email already exists. Please enter another email.</p>
        <p *ngIf="emailNumbersError">only 5 Emails allowed.</p>

      </div>
    </form>
  </div>

  <!-- <div class="actions">
    <button mat-raised-button color="primary" (click)="sendToUser()" [disabled]="emailForm.invalid">Send to
      User</button>
  </div> -->
</mat-dialog-content>