import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { urls } from '../../url'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class PresaleService {

  constructor(private http: HttpClient) { }
  readonly BaseUrl = environment.ONBOARDDATA;
  readonly preSaleSearchAPI = environment.EsSEARCH;
  readonly BaseCredlixUrl = environment.BASEURL;
  readonly EximURL = environment.EXIMAUTOSALES;


  getLeadFileConfig() {
    let url = this.BaseUrl + urls.leadConfig  + '?type=' + 'LEAD_FILE_UPLOAD'
    return this.http.get(url);
  }
  updateLeadConfig(obj) {
    let url = this.BaseUrl + urls.updateLeadConfig;
    return this.http.put(url, obj);
  }
  uploadLeadConfig(obj) {
    let url = this.BaseUrl + urls.uploadConfig + '?type=' + 'LEAD_FILE_UPLOAD'
    return this.http.post(url, obj);
  }

  getUploadedFiles(obj){
    let url = this.BaseUrl + urls.fileEntries;
    return this.http.post(url, obj);
  }

  getFileLeadStats(obj){
    let url = this.preSaleSearchAPI + urls.leadStats;
    return this.http.post(url, obj);
  }

  getFileDetailsById(obj){
    let url = this.preSaleSearchAPI + urls.getFileDetails;
    return this.http.post(url, obj);
  }
  updateLeadById(obj){
    let url = this.BaseUrl + urls.leadDataSearch
    return this.http.put(url, obj);
  }

  fetchUserList(){
    let url = this.BaseCredlixUrl + urls.getUserManagement + '?authorities=' + ['VIEW_PRESALES_INPROGRESS' ,'MANAGE_PRESALES_INPROGRESS' ,'VIEW_PRESALES_READY', 'MANAGE_PRESALES_READY'];
    return this.http.get(url);
  }
  fetchKanbanUserList(){
    let url = this.BaseCredlixUrl + urls.getUserManagement + '?authorities=' + 'KANBAN';
    return this.http.get(url);
  }

  leadMoveToReadyState(obj){
    let url = this.BaseUrl + urls.leadMove;
    return this.http.post(url, obj);
  }
  leadMoveToLOSState(id,obj){
    let url = this.BaseUrl + urls.LOSMoveLead + "/" + id + "?assigneeId=" + obj;
    return this.http.post(url, obj);
  }
  bulkLeadMoveToLOSState(obj){
    let url = this.BaseUrl + urls.bulkLOSMoveLead;
    return this.http.post(url, obj);
  }
  editLeadColumn(obj){
    let url = this.BaseUrl + urls.editLeadColumn;
    return this.http.post(url, obj);
  }
  leadDetailSearchById(id){
    let url = this.BaseUrl + urls.leadDataSearch + '/' + id;
    return this.http.get(url);

  }
  updateCINKey(obj,id){
    let url = this.BaseUrl + urls.leadDataSearch + '/' + id;
    return this.http.put(url,obj);

  }
  markLeadDispositioned(obj){
    let url = this.BaseUrl + urls.markLeadDispositioned;
    return this.http.post(url, obj);
  }
  getCreditRatingList(obj){
    let url = this.preSaleSearchAPI + urls.CreditRatingList;
    return this.http.post(url, obj);
  }

  LosLeadHistory(id){
    let url = this.BaseUrl + urls.LOSHistory + '?leadId=' + id;
    return this.http.get(url);
  }
  getCinAutoDetails(autoValue,obj){
    let endPoints: string
    if(autoValue == 'cin'){
      endPoints = urls.cinAutoFill
    }
    else if(autoValue == 'ratings'){
      endPoints = urls.ratingAutoFill
    }
    else if(autoValue == 'companyName'){
      endPoints= urls.IECAutoFill
    }
    else if(autoValue == 'paidUpCapital'){
      endPoints = urls.paidUpCapitals
    }
    else if(autoValue == 'phoneIec'){
      endPoints = urls.phoneIec
    }
    let url = this.BaseUrl + endPoints
    return this.http.post(url, obj);
  }
  fetchBulkAutoDetails(obj){
    let endPoints;
    if(obj.key == 'CIN'){
      endPoints = urls.bulkCinAutoFill
    }
    else if(obj.key == 'Rating (Karza)'){
      endPoints = urls.bulkRatingFill
    }
    let url = this.BaseUrl + endPoints + '?fileEntryId=' + obj.id + '&pageNo=' + obj.from + '&pageSize=' + obj.size;
    return this.http.get(url);
  }
  todayLead(businessType: string){
    let url = this.BaseUrl + 'lead/fetch-leads-by-contact-date';
    if (businessType){
      url = url + '?businessType=' + businessType
    }
    return this.http.get(url);
  }
  downloadLosLeads(obj) {
    let url = this.BaseUrl + urls.downloadLOSLead
    return this.http.post(url, obj,{ observe: 'response', responseType: 'blob' as 'json' });
  }
  downloadTemplate(businessType?: string){
    let URl = this.BaseUrl + urls.downloadTemplate;
    if (businessType){
      URl = URl + '?businessType=' + businessType
    }
    return this.http.get(URl, { responseType: 'blob', observe: 'response'});
  }
  getPreQualifiedCount(businessType: string){
    let url = this.BaseUrl + urls.getPreCount;
    if (businessType){
      url = url + '?businessType=' + businessType
    }
    return this.http.get(url)
  }
  updateLeads(obj){
    let url = this.BaseUrl + urls.updateLeads;
    return this.http.put(url,obj)
  }
  addWatchersToPreQualified(obj, id) {
    let params = new HttpParams();
    params = params.append("leadId",id)
    let url = this.BaseUrl + urls.addPreQualWatchers
    return this.http.post(url, obj,{params});
  }
  changePreQualifiedAssignee(obj, id) {
    let params = new HttpParams();
    params = params.append("leadId",id);
    params = params.append("userId",obj)
    let url = this.BaseUrl + urls.changePreAssignee
    return this.http.post(url, obj,{params});
  }
  removePreQualifiedWatchers(obj){
    let params = new HttpParams();
    params = params.append("leadId",obj.laneTaskId);
    params = params.append("watcherIds",obj.watcherIds)
    let url = this.BaseUrl + urls.removePreWatchers;
    return this.http.delete(url,{params});
  }
  getDocList(id){
    let url = this.BaseUrl + urls.getDocList + '?leadId=' + id;
    return this.http.get(url)
  }
  uploadPrequalifiedDoc(obj, id) {
    let params = new HttpParams();
    params = params.append("leadId",id);
    params = params.append("remarks","sampleRemarks");
    let url = this.BaseUrl + urls.uploadPreDoc;
    return this.http.post(url, obj,{params});
  }
  downloadPreDoc(id) {
    let url = this.BaseUrl + urls.downloadPreDOc + '?documentId=' + id
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  getPreQualComment(id) {
    let url = this.BaseUrl + urls.getPreComment + '/' + id;
    return this.http.get(url)
  }
  addPreQualComment(obj) {
    let url = this.BaseUrl + urls.addPreQualComment;
    return this.http.post(url, obj)
  }
  updatePreQualComment(obj) {
    let url = this.BaseUrl + urls.updatePreQualComment;
    return this.http.post(url, obj)
  }
  deletePreQualComment(commentId) {
    let params = new HttpParams();
    params = params.append("commentId",commentId);
    let url = this.BaseUrl + urls.deletePreQualComment;
    return this.http.delete(url, {params})
  }
  getRecords(obj) {
    let url = this.BaseUrl + urls.getRecords;
    return this.http.post(url, obj)
  }
  getLeadIds(obj){
    let url = this.preSaleSearchAPI + urls.getLeadSearch;
    return this.http.post(url, obj);
  }
  getWhatsAppTemplate(){
    let url = this.BaseUrl + urls.getWATemplate;
    return this.http.get(url);
  }
  updateTemplate(obj){
    let params = new HttpParams();
    if(obj.id){
      params = params.append("templateId",obj.id);
    }
    params = params.append("isDefault",obj.isDefault);
    params = params.append("message",obj.message);
    params = params.append("isWhatsAppTemplate",obj.isWhatsAppTemplate);
    let url = this.BaseUrl + urls.updateLead;
    return this.http.post(url,obj,{params});
  }
  markAsPrimary(obj){
    let url = this.BaseUrl + urls.markContactPrimary;
    return this.http.post(url, obj);
  }
  getBuyerList(obj){
    let url = this.preSaleSearchAPI + urls.buyerList;
    return this.http.post(url, obj);
  }
  sendWhatsApp(obj){
    let params = new HttpParams();
    params = params.append("isWhatsAppSelected",obj.isWhatsAppSelected);
    params = params.append("leadId",obj.leadId);
    params = params.append("messageTemplateId",obj.messageTemplateId);
    let url = this.BaseUrl + urls.sendWhatsApp;
    return this.http.post(url, obj,{params});
  }
  deleteBuyer(obj){
    let params = new HttpParams();
    params = params.append("leadId",obj.leadId);
    params = params.append("buyerId",obj.buyerId)
    let url = this.BaseUrl + urls.deleteBuyer;
    return this.http.delete(url,{params});
  }
  deleteContact(obj){
    let params = new HttpParams();
    params = params.append("leadId",obj.leadId);
    params = params.append("contactId",obj.contactId)
    let url = this.BaseUrl + urls.deleteContact;
    return this.http.delete(url,{params});
  }
  markPrimaryTemplate(obj){
    let params = new HttpParams();
    if(obj.templateId){
      params = params.append("templateId",obj.templateId);
    } 
    params = params.append("isDefault",obj.isDefault);
    let url = this.BaseUrl + urls.markTemplatePrimary;
    return this.http.post(url, obj,{params});
  }
  deleteTemplate(obj){
    let params = new HttpParams();
    params = params.append("templateId",obj.templateId);
    let url = this.BaseUrl + urls.deleteTemplate;
    return this.http.delete(url,{params});
  }
  getPreHistory(id){
    let params = new HttpParams();
    params = params.append("leadId",id);
    let url = this.BaseUrl + urls.getPreHistory;
    return this.http.get(url,{params})
  }
  deletePreDoc(id){
    let params = new HttpParams();
    params = params.append("documentId",id);
    let url = this.BaseUrl + urls.deleteTaskDocuments;
    return this.http.delete(url,{params});
  }
  downloadLeadSummary(obj){
    let url = this.BaseUrl + urls.downloadLeadSummary;
    return this.http.post(url,obj,{ observe: 'response', responseType: 'blob' as 'json' });
  }
  getLeadBySearch(obj){
    let url = this.preSaleSearchAPI + urls.getLeadBySearch;
    return this.http.post(url, obj);
  }
  changeBulkAssignee(obj){
    let url = this.BaseUrl + urls.bulkAssignee;
    return this.http.post(url, obj);
  }
  getCampaignList(obj){
    let url = this.preSaleSearchAPI + urls.getCampaignList;
    return this.http.post(url,obj);
  }
}


