<!-- <div class="cmnt">
  <input type="text" [(ngModel)]="leadComment">
  <button type="button" (click)="sendCommentMessage()"> Send</button>

</div> -->

<div class="commentSec">
  <span style="float: right;cursor: pointer;" class="material-icons" (click)="closeDialog()"> close </span>
  <div class="top">
    <label class="bsnesName">{{data.businessName}}</label>
    <hr class="line-break">
  </div>
  <div class="middle-sec">
    <div [ngClass]="currentUser == msg['email'] ? 'chtLeft' : 'chtRight'" *ngFor="let msg of commentList; let i=index">
      <div class="chtbox" id="{{i}}">
        <div class="p-wrp">
          <label>{{msg.userName}}</label>
          <label style="font-size: 12px;float: right;">{{msg.createdAt | date:'dd/MM/yyyy HH:mm a'}}</label>
        </div>
        <div class="msg">{{msg.comment}}</div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="commentDiv">
      <textarea maxlength="2000" class="commentInput" (keyup)="setCharLimit()" type="text" (keyup.enter)="sendCommentMessage()" [(ngModel)]="leadComment" rows="3">
      </textarea>
    </div>
    <!-- <input maxlength="2000" (keyup)="setCharLimit()" type="text" class="commentInput" (keyup.enter)="sendCommentMessage()" [(ngModel)]="leadComment"> -->
    <i class="ri-send-plane-2-fill sendIcn" (click)="sendCommentMessage()"></i>
  </div>
</div>
