import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import * as _ from 'lodash';
import { importConfig } from '../../../constants/import-config-data'
import { RequestFinanceComponent } from '../request-finance/request-finance.component';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from 'src/app/settings/setting.service';
import { SelectionModel } from '@angular/cdk/collections';
import { RequestFinancePdfComponent } from '../request-finance-pdf/request-finance-pdf.component';

@Component({
  selector: 'app-finance-invoice-listing',
  templateUrl: './finance-invoice-listing.component.html',
  styleUrls: ['./finance-invoice-listing.component.scss']
})
export class FinanceInvoiceListingComponent implements OnInit {

  displayedColumns = [];
  buyerInvoices = [];
  headerDisplayColumns = [];
  dataSource = new MatTableDataSource(this.buyerInvoices);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  isAnchor: boolean = false;
  setbuyerID: string;
  isBuyer: boolean = false;
  businessName: string;
  invId: string;
  selection = new SelectionModel(true, []);
  sendinvoiceID: any = [];
  isApproveButtonEnable: boolean = false;
  isLead: boolean = false;
  isDisbursedBtnEnable: boolean = false;
  anchorDisplayName: string;
  buyerId: string;
  searchTextedId: string;

  constructor(private pagesService: PagesService, private activatedRoute: ActivatedRoute,
    private router: Router, public authService: AuthService, public dialog: MatDialog,
    private toastService: ToastrService, private settingService: SettingService) { }

  ngOnInit() {
    this.searchTextedId = sessionStorage.getItem('searchedText');
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.anchorDisplayName = getSessionStorage['accountName'];
    if (getSessionStorage['credlixAnchorList']) {
      getSessionStorage['credlixAnchorList'].map(val => {
        if (val.id == localStorage.getItem(importConfig.masterAccountID)) {
          this.anchorDisplayName = val.displayName;
        }
      })
    }
    this.pagesService.isChangeColor("finance_white");
    this.dataSource.data.length = 0;
    this.paginator.length = 0;
    this.activatedRoute.params.subscribe(params => {
      this.setbuyerID = params['id'];
      this.businessName = params['businessName'];
    })
    if (this.authService.isAnchor()) {
      this.isAnchor = true;
    }
    if (this.authService.isBuyer()) {
      this.isBuyer = true;
    }
    if (this.authService.isLenderLeads()) {
      this.isLead = true;
    }
    if(this.searchTextedId){
      this.getGlobalSearchInvData(this.searchTextedId);
    }else{
      this.getPaginatorData();
    }
  }

  getGlobalSearchInvData(searchText){
    let reqObj = {
      "sort": {
        "field": "updatedAt",
        "order": "desc"
      },
      "from": 0,
      "size": 50
    }
    if (searchText != 'Search Filter Applied') {
      let documentType = sessionStorage.getItem('documentType')
      if (documentType == 'Invoice') {
        reqObj["where"] = {
          "invoiceNumber": {
            "type": "search",
            "operator": "AND",
            "value": [
              {
                "field": "invoiceNumber",
                "value": searchText
              }
            ]
          }
        }
      }
      else {
        reqObj['where'] = {
          "buyerId": {
            "type": "search",
            "field": "buyerId",
            "value": searchText
          }
        }
      }
      reqObj["accessAs"] = "customerGSTN"
    }else{
      let getSessionData = JSON.parse(sessionStorage.getItem('GlobalSearch'));
      reqObj['where'] = getSessionData['where'];
      reqObj['accessAs'] = getSessionData['accessAs'];
    }

    if (localStorage.getItem('credlixUserID')) {
      reqObj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    this.sendRequestToGetInvData(reqObj);
  }

  sendRequestToGetInvData(reqObj){
    this.pagesService.getLoanInvoice(reqObj).subscribe(res => {
      if (res['result'] && res['result'].length > 0) {
        this.buyerInvoices = res['result'];
        this.buyerId = this.buyerInvoices[0]['buyerId'];
        this.businessName = this.buyerInvoices[0]['businessName'];
        this.setbuyerID = this.buyerInvoices[0]['buyerSearchKey'];
        this.buyerInvoices.map(val => {
          if (val['isActive'] == false) {
            val['isActive'] = "Inactive"
          }
          else {
            val['isActive'] = "Active"
          }
        })
        this.dataSource.data = this.buyerInvoices;
        this.totalRecords = res['total'];
        this.createHeaders(res['fieldConfig']);
      }
      else {
        this.dataSource.data.length = 0;
      }
      this.paginator.length = this.totalRecords;
    })
  }

  getPaginatorData() {
    let reqObj = {
      "size": this.pageSize,
      "from": this.paginator.pageIndex * this.pageSize,
      "where": {},
      "accessAs": "buyerGSTN",
      "sort": {
        "field": "updatedAt",
        "order": "desc"
      },
    }
    if (this.authService.isCredlixUser()) {
      reqObj['masterAccountId'] = localStorage.getItem(importConfig.masterAccountID);

    }
    else if (this.isLead) {
      reqObj['masterAccountId'] = "6228a749a2cee5063fd7573c";

    }
    if (!this.isBuyer) {
      reqObj['accessAs'] = "customerGSTN";
      reqObj['where'] = {
        "buyerId": {
          "type": "search",
          "field": "buyerId",
          "value": this.setbuyerID
        }
      }
    }
    this.sendRequestToGetInvData(reqObj);
  }

  //creating columns dynamically for loan invoice table
  createHeaders = (config) => {
    this.headerDisplayColumns = [];
    this.displayedColumns = [];
    config.map((each, id) => {
      if (id == 3) {
        this.headerDisplayColumns.push('image');
      }
      this.headerDisplayColumns.push(each.field);
      each['cell'] = (element: any) => {
        if(each.field == 'status' && element[each.field] == 'PAID'){
          return 'REPAID';
        }
        if (each.field == 'amount') {
          return this.settingService.numDifferentiation(_.get(element, each.field))
        }
        else {
          return _.get(element, each.field);
        }
      }
      if(this.isBuyer && each['field'] == 'businessName'){
        this.headerDisplayColumns.pop();
      }
    })
    this.headerDisplayColumns.unshift('select');
    this.displayedColumns = config;
  }

  navigateToSupplierPage() {
    this.pagesService.getClickEvent('clicked');
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

  breadcrumNavigation() {
    sessionStorage.clear();
    this.router.navigate(['/channelFinance']);
  }

  //setting css
  getClass(status, element) {
    var k = {
      "OPEN": {
        "color": "status early_status1",
        "value": "Open"
      },
      "REQUESTED": {
        "color": "status early_status2",
        "value": "Requested"
      },
      "PAID": {
        "color": "status early_status3",
        "value": "Paid"
      },
      "DISBURSED": {
        "color": "status early_status4",
        "value": "Open"
      },
      "EXPIRED": {
        "color": "status early_status5",
        "value": "Open"
      }
    };
    if (status === "status") {
      let v = k[element[status]];
      if (v)
        return v["color"];
    }
    else if (status === "invoiceNumber") {
      return "overtext";
    } else {
      return {};
    }
  }

  openRequestFinanceDialog(element) {
    let data = {
      multiple: false,
      data: element
    }
    this.openFinanceDialog(data);
  }

  openFinanceDialog(data) {
    const dialogRef = this.dialog.open(RequestFinanceComponent, {
      disableClose: true,
      width: '450px',
      height: '850px',
      data: data,
      panelClass: 'custom-modalbox'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPaginatorData();
      this.sendinvoiceID = [];
      this.selection.clear();
      this.isApproveButtonEnable = false;
      this.isDisbursedBtnEnable = false;
    });
  }

  getStatus(status) {
    if (status === 'invoiceNumber' || status === 'status' || status === 'image' || status === 'invoiceDate')
      return true;
    else
      return false;
  }

  isAllSelected() {
    this.sendinvoiceID = this.selection.selected;
    if (this.sendinvoiceID != null && this.sendinvoiceID.length > 0) {
      this.isApproveButtonEnable = true;
      this.isDisbursedBtnEnable = true;
    }
    const numSelected = this.selection.selected.length;
    const numRowsMinusExcluded = this.dataSource.data
      .filter((row) => {
        if (!this.isLead) {
          return row.status == 'OPEN';
        }
        else {
          return row.status == 'REQUESTED';
        }
      }).length;
    return numSelected === numRowsMinusExcluded;
  }

  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

  }

  checkAll(event) {
    this.sendinvoiceID = [];
    this.selection.clear();
    if (event['checked']) {
      this.dataSource.data.map(data => {
        if (!this.isLead) {
          if (data['status'] == 'OPEN') {
            this.isApproveButtonEnable = true;
            this.sendinvoiceID.push(data);
            this.selection.toggle(data);
          }
        }
        else {
          if (data['status'] == 'REQUESTED') {
            this.isDisbursedBtnEnable = true;
            this.sendinvoiceID.push(data);
            this.selection.toggle(data);
          }
        }
      })
    }
    else {
      this.isApproveButtonEnable = false;
      this.isDisbursedBtnEnable = false;
      this.selection.clear()
    }
  }

  checkSingle(event, row){
    if (event['checked']){
      this.isApproveButtonEnable = true;
      this.isDisbursedBtnEnable = true;
      this.selection.toggle(row);
      this.isAllSelected();
    }
    else{
      this.selection.deselect(row);
      this.isApproveButtonEnable = false;
      this.isDisbursedBtnEnable = false;
      this.isAllSelected();
    }
  }

  viewInvoiceDetails() {
    let data = {
      multiple: true,
      data: this.sendinvoiceID
    }
    this.openFinanceDialog(data);
  }

  requestFinance() {
    let invoiceIds = [];
    this.sendinvoiceID.map(data => {
      invoiceIds.push(data['id']);
    })
    let obj = {
      "id": invoiceIds,
      "multiple": true
    }
    this.pagesService.generateDrawdownPDF(invoiceIds).subscribe((res: any) => {
      const pdfContent = window.URL.createObjectURL(res.body);

      obj['view'] = pdfContent;
      const dialogRef = this.dialog.open(RequestFinancePdfComponent, {
        disableClose: true,
        width: '800px',
        height: '610px',
        data: obj,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.sendinvoiceID = [];
        this.selection.clear();
        this.isApproveButtonEnable = false;
        this.isDisbursedBtnEnable = false;
        this.getPaginatorData();
      });
    })
  }

  markDisbursed() {
    let invoiceIds = [];
    this.sendinvoiceID.map(data => {
      invoiceIds.push(data['id']);
    })
    this.pagesService.markDisbursedMulti(invoiceIds).subscribe(res => {
      if (res['status'] == 200) {
        this.toastService.success("Successfully disbursed");
      }
    })
  }

  viewAccountStatement(){
    this.router.navigate([`/viewStatement/${this.setbuyerID}/${this.businessName}/${this.buyerId}`]);
  }
}
