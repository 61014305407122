import { Injectable } from '@angular/core';
import { environment } from './../../../../../../../environments/environment';
import { urls } from '../../../../../../url'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GstGenerateService {
  readonly BaseUrl;
  totalRecords: any;
  isTabChanageFromTile: boolean = false;
  refreshKboard = new BehaviorSubject<Boolean>(false);
  selectedColdLeadReason: string;
  selectedReVivalReasons: string;
  gstUrl: any;
  constructor(private http: HttpClient, public authService: AuthService) {

  }
  readonly credlixBusinessUrl = environment.bussinessBaseUrl;

  getallGstDetails(obj) {
    const url = this.credlixBusinessUrl + urls.getGstListByPan;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, obj, { headers, });
  }

  getGstReport(obj) {
    const url = this.credlixBusinessUrl + urls.getGstReport;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, obj, { headers, });
  }

  getGstReportDownloadFile(obj: any) {
    const url = this.credlixBusinessUrl + urls.gstDownloadFile;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<Blob>(url, obj, {
      headers: headers,
      responseType: 'blob' as 'json'
    });
  }

  getSingleGstReport(obj) {
    const url = this.credlixBusinessUrl + urls.getSingleGSTReport;
    return this.http.post(url, obj);
  }

}



