import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string): string {
    // Calculating the time difference between now and the given time
    const timeDifference = moment().diff(moment(value));
    const seconds = moment.duration(timeDifference).asSeconds();
    const minutes = moment.duration(timeDifference).asMinutes();
    const hours = moment.duration(timeDifference).asHours();
    const days = moment.duration(timeDifference).asDays();
    const weeks = moment.duration(timeDifference).asWeeks();
    const months = moment.duration(timeDifference).asMonths();
    const years = moment.duration(timeDifference).asYears();

    // Checking and returning the appropriate readable format
    if (seconds < 60) {
      return 'few seconds ago';
    } else if (minutes === 1) {
      return 'just a min ago';
    } else if (minutes < 60) {
      return 'few minutes ago';
    } else if (hours === 1) {
      return 'just an hour ago';
    } else if (hours < 24) {
      return 'few hours ago';
    } else if (days === 1) {
      return 'yesterday';
    } else if (days < 7) {
      return `${Math.floor(days)} days back`;
    } else if (weeks === 1) {
      return '1 week back';
    } else if (weeks < 4) {
      return `${Math.floor(weeks)} weeks back`;
    } else if (months === 1) {
      return '1 month back';
    } else if (months < 12) {
      return `${Math.floor(months)} months back`;
    } else if (years === 1) {
      return '1 year back';
    } else {
      return `${Math.floor(years)} years back`;
    }
  }

}
