import { Component, OnInit, ViewChild } from "@angular/core";
import {
  Form,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material";
import { ThemePalette } from "@angular/material/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EventEmitter } from "protractor";
import { CsvExportService } from "src/app/services/csv-export.service";
import { KanbanService } from "../../../kanban.service";
import { PagesService } from "src/app/pages/pages.service";

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-underwriting-stage',
  templateUrl: './underwriting-stage.component.html',
  styleUrls: ['./underwriting-stage.component.scss']
})
export class UnderwritingStageComponent implements OnInit {

  constructor() {}
  
  ngOnInit(): void {
      
  }
}




