import { Component, OnInit, Inject, HostBinding, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { KanbanService } from '../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator, MatSelect, MatOption } from '@angular/material';
import * as moment from 'moment';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-k-board-filters',
  templateUrl: './k-board-filters.component.html',
  styleUrls: ['./k-board-filters.component.scss']
})
export class KBoardFiltersComponent implements OnInit {
  assigneeList = [];
  boardLane = [];
  allTags = [];
  filterObj = {}
  show: boolean = true;
  pageSize:number=500;
  pageNumber: number = 0;
  showtag: boolean;
  filteredAssignList = [];
  selectedAssigneeList = [];
  filteredLaneList = [];
  selectedLaneList = [];
  selectedtagList = [];
  filteredTagList = [];
  seachAssigneeText: string;
  seachTagsText: string;
  minFromDate;
  maxFromDate:any;
  todayDate = new Date(); 
  @HostBinding('class') class = 'k-board-filter';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isStartDate: boolean;
  startDate: any;
  isEndDate: boolean;
  endDate: any;
  constructor(public _kService: KanbanService, public dialogRef: MatDialogRef<KBoardFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public settingService : SettingService ) {
    console.log(data);
  }

  ngOnInit() {
    if(!this.data.isPreQualified){
      this.getBoardLaneList();
      this.getTags();
    }
    this.getAssigneeList();
  }

  getTags() {
    this._kService.getAllTags(this.data.boardId).subscribe((res: any) => {
      this.allTags = res['result']
      this.allTags.forEach(ele => {
        ele['checked'] = false
      });
      // if (this.data.reqObj['tagList']) {
      //   for (let i = 0; i < this.data.reqObj.tagList.length; i++) {
      //     this.allTags.map(val => {
      //       if (val.id == this.data.reqObj.tagList[i]) {
      //         val['checked'] = true
      //       }
      //     })
      //   }
      // }
    });
  }

  getAssigneeList() {
    this._kService.getUserList().subscribe(list => {
      this.assigneeList = list['result'];
      this.assigneeList.forEach(ele => {
        ele['checked'] = false
      });
    });
  }
  getBoardLaneList() {
    this._kService.getLaneWithUserAccess(this.data.boardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'];
      this.boardLane = this.boardLane.sort((a, b) => a.laneDTO.position - b.laneDTO.position).map((exemple, index, array) => exemple)
      this.boardLane.forEach(ele => {
        ele.laneDTO['checked'] = false
      })
    });
  }


  getSelectedAssigneeList(list) {
    this.selectedAssigneeList.push(list)
  }

  closeDialog() {
    this.filterObj={
      close: true
    }
    this.dialogRef.close(this.filterObj)
  }

  applyFilters(){
    let sendSelectedAssignee = []
    let sendselectedLaneList = [];
    let sendSelectedTagList = [];
    this.filteredAssignList = this.selectedAssigneeList.filter(option => option.checked == true);
    this.filteredAssignList = this.assigneeList.filter(option => option.checked == true);
    this.filteredAssignList.forEach(ele => {
      sendSelectedAssignee.push(ele.userId);
    })

    this.filteredLaneList = this.selectedLaneList.filter(option => option.checked == true);
    this.filteredLaneList = this.boardLane.filter(option => option.laneDTO.checked == true);

    this.filteredLaneList.forEach(ele => {
      sendselectedLaneList.push(ele.laneDTO.id);
    });

    this.filteredTagList = this.selectedtagList.filter(option => option.checked == true);
    this.filteredTagList = this.allTags.filter(option => option.checked == true);
    this.filteredTagList.forEach(ele => {
      sendSelectedTagList.push(ele.id);
    });

    this.filterObj = {
      'assignList': sendSelectedAssignee,
      'laneList': sendselectedLaneList,
      'tagList': sendSelectedTagList,
      'fromDate': this.startDate ? this.startDate : undefined,
      'endDate': this.endDate ? this.endDate : undefined,
    }
    this.dialogRef.close(this.filterObj)
  }

  getSelectedLaneList(list) {
    this.selectedLaneList.push(list);
  }

  getSelectedTags(tag) {
    this.selectedtagList.push(tag);
  }
  resetFilters() {
    this.boardLane.forEach(ele => {
      ele.laneDTO['checked'] = false
    })
    this.assigneeList.forEach(ele => {
      ele['checked'] = false
    });
    this.allTags.forEach(ele => {
      ele['checked'] = false
    });
    this.filterObj={}
    this.dialogRef.close()

  }

  changeFromDate(event) {
    this.isStartDate = false;
    let Start = event.value;
    this.startDate = this.settingService.setDate(Start);
  }
  changeToDate(event) {
    this.isEndDate = false;
    let endValue = event.value
    this.endDate = this.settingService.setDate(endValue);
  }
}
