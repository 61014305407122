import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-bulkactions-dialog',
  templateUrl: './bulkactions-dialog.component.html',
  styleUrls: ['./bulkactions-dialog.component.scss']
})
export class BulkactionsDialogComponent implements OnInit {
  bulkAction: string;
  bulkkSupplierDetailsForm: FormGroup;
  selectedSuppliers: any;
  supplierIDs = [];
  iscloseDialog: boolean = false;
  approval = []
  bulkrequestobj = {}
  credlixFee: any;
  financerobj = {};
  financerType: string;
  credlixMoglixFee: number = 0;
  financerID: any;
  ischeckFinanceName: string;
  approvalListArray: any = [
    { id: '1', approvalName: 'Supply Approval', field: 'supplyApproval', isChecked: false },
    { id: '2', approvalName: 'Finance Approval', field: 'financeApproval', isChecked: false },
  ];
  financerArray = [];

  constructor(public dialogRef: MatDialogRef<BulkactionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    public pagesService: PagesService, public toasterService: ToastrService, public dialog: MatDialog,
    private authService: AuthService) {
    this.bulkAction = data['actionButton'];
    this.selectedSuppliers = data['selectedSuppliers'];
    this.getFinancerList();

  }

  ngOnInit() {
    this.bulkkSupplierDetailsForm = this.fb.group({
      rate: ['', Validators.required],
      eligiblePercentage: ['', Validators.required],
      limit: [''],
      maxDaysDiscount: [''],
      minDaysDiscount: ['', Validators.required],
      approval: [''],
      ischeckFinanceName: ['', Validators.required],
      credlixFee: ['',  Validators.required],
    });
    for (let i = 0; i < this.selectedSuppliers.length; i++) {
      let IDs = this.selectedSuppliers[i].id;
      this.supplierIDs.push(IDs);
    }
  }
  numberOnly(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  editSupplierDetails() {
    console.log(this.bulkkSupplierDetailsForm.controls)
    if (this.bulkkSupplierDetailsForm.value.rate > 100 || this.bulkkSupplierDetailsForm.value.eligiblePercentage > 100) {
      this.toasterService.error("percentage can not be more than 100%")
    }
    else if (this.bulkkSupplierDetailsForm.value.maxDaysDiscount != '' && this.bulkkSupplierDetailsForm.value.maxDaysDiscount != 0 &&
      this.bulkkSupplierDetailsForm.value.maxDaysDiscount < this.bulkkSupplierDetailsForm.value.minDaysDiscount) {
      this.toasterService.error("Maximum days should not be less than minimum days");
    }
    else {
      if (this.bulkAction == 'maxDiscount') {
        if (this.bulkkSupplierDetailsForm.controls['minDaysDiscount'].valid == true) {
          this.confirmationDetails(this.bulkAction);
        }
      }
      else if (this.bulkAction == 'eligible') {
        if (this.bulkkSupplierDetailsForm.controls['eligiblePercentage'].valid == true) {
          this.confirmationDetails(this.bulkAction);
        }
      }
      else if (this.bulkAction == 'rate') {
        if (this.bulkkSupplierDetailsForm.controls['rate'].valid == true) {
          this.confirmationDetails(this.bulkAction);
        }
      }
      else if (this.bulkAction == 'limit') {
        if (this.bulkkSupplierDetailsForm.controls['limit'].valid == true) {
          this.confirmationDetails(this.bulkAction);
        }
      }
      else if (this.bulkAction == 'approval') {
        this.confirmationDetails(this.bulkAction);
      }
      else if (this.bulkAction == 'financer') {
        if (this.bulkkSupplierDetailsForm.controls['credlixFee'].valid == true) {
        this.confirmationDetails(this.bulkAction);
        }
      }
      else {
        // if (this.bulkkSupplierDetailsForm.valid) {
        //   this.confirmationDetails(this.bulkAction);
        // }

      }
    }
  }
  fillFieldValue(field, queryObj) {
    if (queryObj[field] == null || queryObj[field].toString().trim() == "") {
      queryObj[field] = -1
    }
  }
  confirmationDetails(value) {
    if (value == "approval") {
      this.getSelectedApproval();
      this.bulkrequestobj = {
        "supplierIds": this.supplierIDs,
        "approval": this.approval
      }
    }
    else if (value == "financer") {
      this.checkfinanceValue();

    }
    else {
      this.bulkrequestobj = {
        "supplierIds": this.supplierIDs,
        "discountRatePerDay": this.bulkkSupplierDetailsForm.value.rate,
        "maxDisountAllowed": this.bulkkSupplierDetailsForm.value.eligiblePercentage,
        "limit": this.bulkkSupplierDetailsForm.value.limit,
        "maxDaysInterval": this.bulkkSupplierDetailsForm.value.maxDaysDiscount,
        "minDaysInterval": this.bulkkSupplierDetailsForm.value.minDaysDiscount,
      }
    }
    if (value != null) {
      if (value == 'maxDiscount' || value['maxDiscount'] == 0) {
        this.fillFieldValue('maxDaysInterval', this.bulkrequestobj)
        // this.fillFieldValue('minDaysInterval', obj)
      }
      else if (value == 'eligible') {
        // this.fillFieldValue('maxDisountAllowed', obj);
      }
      else if (value == 'rate') {
        // this.fillFieldValue('discountRatePerDay', obj);
      }
      else if (value == 'financer') {

      }
      else {
        this.fillFieldValue(value, this.bulkrequestobj);
      }
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: '350px',
      height: '250px',
      data: this.bulkrequestobj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.iscloseDialog = true
      }
      else {
        this.iscloseDialog = false;
      }
      this.dialogRef.close(this.iscloseDialog)
    });
  }

  getSelectedApproval() {
    this.approval = []
    for (let i = 0; i < this.approvalListArray.length; i++) {
      if (this.approvalListArray[i].isChecked == true) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": true
        })
      }
      else if (this.approvalListArray[i].isChecked == false) {
        this.approval.push({
          "field": this.approvalListArray[i]['field'],
          "name": this.approvalListArray[i]['approvalName'],
          "type": "Boolean",
          "validValues": ["true"],
          "isActive": false
        })
      }
    }

  }
  closeDialog() {
    this.dialogRef.close(this.iscloseDialog);
  }

  OnselectFinancer(value, element) {
    for (let i = 0; i < element.length; i++) {
      if (element[i].name == value) {
        if (value == 'Moglix') {
          this.ischeckFinanceName = element[i].name
          this.financerID = element[i].id;
        }
        else if (value == 'Aditya Birla Finance Limited' || value == 'Cholamandalam Investment and Finance Company') {
          this.ischeckFinanceName = element[i].name
          this.financerID = element[i].id;
        }
      }
    }
    this.financerType = value;
  }

  checkfinanceValue() {
    if ((this.ischeckFinanceName == 'Aditya Birla Finance Limited' || this.ischeckFinanceName == 'Cholamandalam Investment and Finance Company') && this.credlixFee != null) {
      this.bulkrequestobj["financierId"] = this.financerID,
        this.bulkrequestobj["commission"] = this.credlixFee,
        this.bulkrequestobj["supplierIds"] = this.supplierIDs
    }
    if (this.ischeckFinanceName == 'Moglix') {
      this.bulkrequestobj["financierId"] = this.financerID,
        this.bulkrequestobj["commission"] = null,
        this.bulkrequestobj["supplierIds"] = this.supplierIDs

    }
  }

  getFinancerList() {
    let credlixUserID;
    if(this.authService.isCredlixUser()){
      credlixUserID = localStorage.getItem('credlixUserID');
    }
    this.pagesService.fetchFinancer(credlixUserID).subscribe(list => {
      this.financerArray = list['result'];
    })
  }
}
