
import { Component, OnInit, Input , Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatChipInputEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { ConfirmationDialogComponent } from 'src/app/component/suppliers/listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { SidService } from 'src/app/component/sid/services/sid.service';
import { map, startWith } from 'rxjs/operators';
import { LaneMoveComponent } from '../../../lanes-list/lane-move/lane-move.component';
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component';
import { isEmpty, _ } from "lodash";
import { PresaleService } from 'src/app/component/pre-sales/presale.service';

@Component({
  selector: 'app-lane-task-documents-tab',
  templateUrl: './lane-task-documents-tab.component.html',
  styleUrls: ['./lane-task-documents-tab.component.scss']
})
export class LaneTaskDocumentsTabComponent implements OnInit {
  laneTaskIDDetail : any;
  public keyValueForm: FormGroup;
  contactForm: FormGroup;
  selectedlane: string;
  // _: any = Lodash;
  boardLane = [];
  selectedLanePosition: number;
  selectedBoardLane = [];
  toLaneId: any;
  leadName: string;
  contactPerson: string;
  gstin: string;
  platform: string;
  myControl = new FormControl();
  // laneTaskIDDetail: any;
  selectedAssign: string;
  updateTaskReqObj = {}
  setSelectdAssigne: string;
  facilityLimitAmount: any = '';
  facilityLimitCurrency: any = '';
  userProfileName: string;
  filteredOptions: Observable<any>;
  todayDate: any = new Date();
  options = [
  ];
  selectedUserListOptions = [];
  selectedLeadOwnerListOptions = [];
  showError = false;
  errorMessage = '';
  termSheetDocument = [];
  panelCustomOpenState: boolean = false;
  @ViewChild(SelectAutocompleteComponent) multiSelect: SelectAutocompleteComponent;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  //tags
  allTags = [];
  visible = true;
  selectable = true;
  removable = true;
  // separatorKeysCodes: number[] = [ENTER, COMMA];
  tagInputCtrl = new FormControl();
  assigneeInputCtrl = new FormControl();
  leadOwnerInputCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  filteredUserList: Observable<string[]>;
  filteredOwnerList: Observable<string[]>;
  filteredWatcherList: Observable<string[]>;
  allSelectedTagList = [];
  allTagList = [];
  opened = false;
  demo1TabIndex;
  selectedtbs = 2;
  file: any;
  configFile: any;
  buyerConfigUploadFile: any;
  customInfo
  isEditableAccess: boolean = true;
  uploadedDocumentList = [];
  boardId: string;
  laneTaskIdByUrl: string;
  addTaskComment: string;
  isProgressVisible: boolean = false;
  commentlist = []
  fetchSelectedtags = [];
  fetchTaskHistory = [];
  panelOpenState = [];
  countryList = [];
  laneTaskConfig = [];
  exporterArray: Array<any> = [];
  discountRateArray: Array<any> = [];
  bankChargeArray = []
  bankRateSetting = [];
  faciliyFeeAmount = [];
  newDynamic: any = {};
  newDiscountObj = {};
  fundedAmount: number;
  disbursement: any;
  facilityFee: number;
  factoringFee: number;
  validCustomFields: boolean;
  gracePeriod: any;
  isDiscussedWithCreditTeam: boolean = false;
  isPersonalGuaranteeSelected: boolean = false;
  otherConditions: string;
  chargesBeyndGracePeriod: any;
  fileUploadData = []
  aggrementSigningDate: any;
  aggrementEffectiveDate: any;
  getTimepicker: any;
  SupplierName: any;
  profileSaveBtn: string = 'Edit';
  SupplierAddress: string;
  leadExporterName: string;
  doctype: string = 'GSTIN';
  gstinList: any = ['GSTIN', 'CIN', 'PAN'];
  docNumber: string;
  businessState: string;
  businessType: string;
  discountingFee: string;
  fundingRate: string;
  exporterName: string;
  currentUser: string;
  getGSTIN: string;
  termSheetForm: FormGroup;
  eximBuyerInd: string;
  isPrepareTermSheet: boolean = false;
  @ViewChild('tagListInput') tagListInput: ElementRef<HTMLInputElement>;
  @ViewChild('userListInput') userListInput: ElementRef<HTMLInputElement>;
  @ViewChild('userLeadListInput') userLeadListInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoAssignee') matAutoAssigneecomplete: MatAutocomplete;
  @ViewChild('autoLeadOwner') matAutoLeadOwner: MatAutocomplete;
  @ViewChild('autoWatchers') matAutoWatcherComplete: MatAutocomplete;
  @ViewChild('autocompleteTrigger') matACTrigger: MatAutocompleteTrigger;
  @ViewChild('autocompleteAssigneeTrigger') matACAssignnTrigger: MatAutocompleteTrigger;
  @ViewChild('autocompleteLeadOwnerTrigger') matACLeadOwnerTrigger: MatAutocompleteTrigger;

  numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
  supplierList = ['Trader', 'Manufacturer'];
  plantVisit = ['Yes', 'No'];
  creditMemo = ['newClientOnboard', 'existigClient'];

  constructor(private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService , @Inject(MAT_DIALOG_DATA) public data: any, private sidService: SidService,private dailogRef: MatDialogRef<LaneTaskDocumentsTabComponent>, public preSalesService : PresaleService) {
      console.log(data);
      this.laneTaskIDDetail = data.laneTask;

    }

  ngOnInit() {
    // let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    // if (getSessionStorage && getSessionStorage['role']) {
    //   this.currentUser = getSessionStorage['role']['userName'];
    // }
    // let masterAccId = getSessionStorage['role']['masterAccount']['id'];
    // this.pagesService.getHeaderList(masterAccId).subscribe(list => {
    //   this.authService.storageListHeaders(list['result']);
    // })
    if(!this.data.preQualified){
      this.activatedRoute.params.subscribe(params => {
        this.laneTaskIdByUrl =  this.data.laneTask.id ;
        this.getLaneDetailsById(true);
      });
      this.fetchUploadedTaskDocuments();
    } else {
      this.fetchPreQualifiedDocList();
    }
    // this.contactForm = this.formBuilder.group({
    //   items: this.formBuilder.array([this.conatctInfo()])
    // })
    // this.setinitiasValue();
    // if (this.laneTaskIDDetail != null) {

    // }

  }

  closeDocumentTab(){
    this.dailogRef.close();
  }


  checkStringNull(val, type) {
    if (val == null || val.toString().trim() == "") {
      this.toasterService.error("Incomplete Supplier Details");
      this.termSheetForm.controls[type].setErrors({ 'incorrect': true });
    } else {

      this.termSheetForm.controls[type].setErrors(null);
    }
  }

  // setinitiasValue() {
  //   this.termSheetForm = this.formBuilder.group({
  //     gracePeriod: ['', [Validators.required, Validators.pattern(this.numericNumberReg)]],
  //     chargesBeyndGracePeriod: ['', [Validators.required, Validators.pattern(this.numericNumberReg)]],
  //     isDiscussedWithCreditTeam: [],
  //     fundedAmount: ['', [Validators.required, Validators.pattern(this.numericNumberReg)]],
  //     factoringFee: ['', [Validators.required, Validators.pattern(this.numericNumberReg)]],
  //     faciliyFeeAmountSection: [true, [Validators.required]],
  //     bankRateSettingSection: [true, [Validators.required]],
  //     bankChargesSection: [true, [Validators.required]],
  //     discountRateSection: [true, [Validators.required]],
  //     supplierName: [true, [Validators.required]],
  //     supplierAddress: [true, [Validators.required]],
  //     otherConditions: [''],
  //     isPersonalGuaranteeSelected: [],

  //   });

  // }

  boardList = [];
  buyerDataIndexConfig: any;
  getBoardList() {
    this._kService.getBoardList().subscribe(res => {
      this.boardList = res['result'];
      this.boardList.map(val => {
        if (this.boardId == val.id) {
          this.buyerDataIndexConfig = val.buyerDataConfig
        }
      })
    })
  }

  addDiscountRatePercentage() {
    this.newDiscountObj = { paymentTerms: "", minimumDaysCharged: "" };
    this.discountRateArray.push(this.newDiscountObj);
    this.termSheetForm.controls['discountRateSection'].setErrors({ 'incorrect': true });
    return true;
  }
  addBankCharges() {
    let newChargeObj = {}
    newChargeObj = { amount: "", currency: "" };
    this.bankChargeArray.push(newChargeObj);
    this.termSheetForm.controls['bankChargesSection'].setErrors({ 'incorrect': true });
    return true;
  }

  addDiscountRateSetting() {
    let newChargeObj = {}
    newChargeObj = { percentage: "", currency: "" };
    this.bankRateSetting.push(newChargeObj);
    this.termSheetForm.controls['bankRateSettingSection'].setErrors({ 'incorrect': true });
    return true;
  }
  addFacilityFeeAmount() {
    let newChargeObj = {}
    if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
      newChargeObj = { amount_percentage: "", currency: "INR", amount: "" };
    }
    else {
      newChargeObj = { amount_percentage: "", currency: "", amount: "" };
    }
    this.faciliyFeeAmount.push(newChargeObj);
    this.termSheetForm.controls['faciliyFeeAmountSection'].setErrors({ 'incorrect': true });
    return true;
  }

  deleteSheetRow(index, sheetName) {
    if (sheetName == 'Exporter') {
      this.exporterArray.splice(index, 1);
    }
    else if (sheetName == 'discountRate') {
      this.discountRateArray.splice(index, 1)
      this.checkNull(this.discountRateArray, "discountRateSection", null, true);
    }
    else if (sheetName == 'discountRateSetting') {
      this.bankRateSetting.splice(index, 1)
      this.checkNull(this.bankRateSetting, "bankRateSettingSection", null, true);
    }
    else if (sheetName == 'bankCharges') {
      this.bankChargeArray.splice(index, 1)
      this.checkNull(this.bankChargeArray, "bankChargesSection", null, true);
    }
    else if (sheetName == 'faciliyFeeAmount') {
      this.faciliyFeeAmount.splice(index, 1)
      this.checkNull(this.faciliyFeeAmount, "faciliyFeeAmountSection", null, true);
    }
  }

  RPASheetResponse = []
  getRPASheet() {
    this._kService.getRPASheetData(this.laneTaskIDDetail.id).subscribe(res => {
      this.RPASheetResponse = res['result'];
      if (this.RPASheetResponse != null && this.RPASheetResponse.length > 0) {
        this.businessState = this.RPASheetResponse[0].businessState,
          this.businessType = this.RPASheetResponse[0].businessType,
          this.discountingFee = this.RPASheetResponse[0].discountingFees,
          this.fundingRate = this.RPASheetResponse[0].fundingRate,
          this.aggrementEffectiveDate = this.RPASheetResponse[0].aggrementEffectiveDate,
          this.aggrementSigningDate = this.RPASheetResponse[0].aggrementSigningDate
      }
    })
  }

  termSheetResponse = [];
  getPrepareTermSheetData() {
    this._kService.getPrepareTermSheetData(this.laneTaskIDDetail.id).subscribe(res => {
      this.termSheetResponse = res['result'];
      this.termSheetDocument = res['result'];

      if (this.termSheetResponse != null && this.termSheetResponse.length > 0) {
        if (this.termSheetResponse[0]['discountRate'] != null && this.termSheetResponse[0]['discountRate'].length > 0) {
          this.discountRateArray = [];
          for (let i = 0; i < this.termSheetResponse[0]['discountRate'].length; i++) {
            this.discountRateArray.push({
              paymentTerms: this.termSheetResponse[0]['discountRate'][i].paymentTerms,
              minimumDaysCharged: this.termSheetResponse[0]['discountRate'][i].minimumDaysCharged
            })
          }
        }
        if (this.termSheetResponse[0]['bankCharges'] != null && this.termSheetResponse[0]['bankCharges'].length > 0) {
          this.bankChargeArray = []
          for (let i = 0; i < this.termSheetResponse[0]['bankCharges'].length; i++) {
            this.bankChargeArray.push({
              amount: this.termSheetResponse[0]['bankCharges'][i].amount,
              currency: this.termSheetResponse[0]['bankCharges'][i].currency
            })
          }
        }
        if (this.termSheetResponse[0]['facilityFeeAmount'] != null && this.termSheetResponse[0]['facilityFeeAmount'].length > 0) {
          this.faciliyFeeAmount = []
          for (let i = 0; i < this.termSheetResponse[0]['facilityFeeAmount'].length; i++) {
            this.faciliyFeeAmount.push({
              amount: this.termSheetResponse[0]['facilityFeeAmount'][i].amount,
              amount_percentage: this.termSheetResponse[0]['facilityFeeAmount'][i].amount_percentage,
              currency: this.termSheetResponse[0]['facilityFeeAmount'][i].currency
            })
          }
        }

        if (this.termSheetResponse[0]['discountRateSetting'] != null && this.termSheetResponse[0]['discountRateSetting'].length > 0) {
          this.bankRateSetting = []
          for (let i = 0; i < this.termSheetResponse[0]['discountRateSetting'].length; i++) {
            this.bankRateSetting.push({
              percentage: this.termSheetResponse[0]['discountRateSetting'][i].percentage,
              currency: this.termSheetResponse[0]['discountRateSetting'][i].currency
            })
          }
        }

        this.fundedAmount = this.termSheetResponse[0].fundedAmount,
          this.factoringFee = this.termSheetResponse[0].factoringFee,
          this.isDiscussedWithCreditTeam = this.termSheetResponse[0].isDiscussedWithCreditTeam ? this.termSheetResponse[0].isDiscussedWithCreditTeam : false
        this.gracePeriod = this.termSheetResponse[0].overDueFees ? this.termSheetResponse[0].overDueFees.gracePeriod : ''
        this.chargesBeyndGracePeriod = this.termSheetResponse[0].overDueFees ? this.termSheetResponse[0].overDueFees.chargesBeyondGracePeriod : ''
        this.isPersonalGuaranteeSelected = this.termSheetResponse[0].isPersonalGuaranteeSelected ? this.termSheetResponse[0].isPersonalGuaranteeSelected : false
        this.otherConditions = this.termSheetResponse[0].otherConditions ? this.termSheetResponse[0].otherConditions : ''

      }
    })
  }
  checkNull(data, type, keyToCheck, showToast) {
    if (type == "faciliyFeeAmountSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls['faciliyFeeAmountSection'].setErrors({ 'incorrect': true });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keys[k] == "amount") {
              continue;
            }
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (data[i][keys[k]] == null || data[i][keys[k]].toString().trim() == "") {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, "amount")) {
            this.termSheetForm.controls['faciliyFeeAmountSection'].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls['faciliyFeeAmountSection'].setErrors({ 'incorrect': true });
        }
      }
    } else if (type == "bankRateSettingSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls['bankRateSettingSection'].setErrors({ 'incorrect': true });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (data[i][keys[k]] == null || data[i][keys[k]].toString().trim() == "") {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls['bankRateSettingSection'].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls['bankRateSettingSection'].setErrors({ 'incorrect': true });
        }
      }
    } else if (type == "bankChargesSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls['bankChargesSection'].setErrors({ 'incorrect': true });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (data[i][keys[k]] == null || data[i][keys[k]].toString().trim() == "") {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls['bankChargesSection'].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls['bankChargesSection'].setErrors({ 'incorrect': true });
        }
      }
    } else if (type == "discountRateSection") {
      if (data == null || data.length < 1) {
        if (showToast) {
          this.toasterService.error("Please fill all required data*");
        }
        this.termSheetForm.controls['discountRateSection'].setErrors({ 'incorrect': true });
      } else {
        let error = false;
        for (let i = 0; i < data.length; i++) {
          let keys = Object.keys(data[i]);
          for (let k = 0; k < keys.length; k++) {
            if (keyToCheck != null && keyToCheck != keys[k]) {
              continue;
            }
            if (data[i][keys[k]] == null || data[i][keys[k]].toString().trim() == "") {
              error = true;
              break;
            }
          }
          if (error) {
            break;
          }
        }
        if (!error) {
          if (keyToCheck == null || !this.checkAllValues(data, null)) {
            this.termSheetForm.controls['discountRateSection'].setErrors(null);
          }
        } else {
          if (showToast) {
            this.toasterService.error("Please fill all required data*");
          }
          this.termSheetForm.controls['discountRateSection'].setErrors({ 'incorrect': true });
        }
      }
    }
  }
  checkAllValues(data, keyToCheck) {
    let error = false;
    for (let i = 0; i < data.length; i++) {
      let keys = Object.keys(data[i]);
      for (let k = 0; k < keys.length; k++) {
        if (keyToCheck != null && keys[k] == keyToCheck) {
          continue;
        }
        if (data[i][keys[k]] == null || data[i][keys[k]].toString().trim() == "") {
          error = true;
          break;
        }
      }
      if (error) {
        break;
      }
    }
    return error;
  }
  updateBuyerData(isFromTermSheet) {
    this._kService.updateBuyerData(this.laneTaskIDDetail.id, this.fileUploadData).subscribe(res => {
      this.toasterService.success("Buyer Data Update Successfully");
    });
  }

  downloadPrepareTermSheet(res) {
    let blob = new Blob([res], {
      type: 'application/pdf'
    });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'prepareTermSheet.pdf';
    link.click();
    window.URL.revokeObjectURL(link.href);
    this._kService.getPrepareTermSheetData(this.laneTaskIDDetail.id).subscribe(res => {
      this.termSheetDocument = res['result'];
    });

  }


  submitPrepareTermSheet(param) {
    if (this.oldLeadSupplierName != this.leadNameSuplier || this.oldLeadSupplierAddress != this.leadSupplierAddress) {
      this.updateTaskReqObj = {
        id: this.laneTaskIDDetail.id,
        laneId: this.laneTaskIDDetail.lane.id,
        toLaneID: this.toLaneId
      }
      this.updateTaskReqObj['data'] = [
        {
          "field": "supplier_name",
          "type": "string",
          "value": this.leadNameSuplier,
          "optional": false,
          "display": true,
          "displayName": "Supplier Name",
          "values": null,
          "additionalData": null,
          "operationsData": null,
          "operations": null
        },
        {
          "field": "supplier_address",
          "type": "string",
          "value": this.leadSupplierAddress,
          "optional": false,
          "display": true,
          "displayName": "Supplier Address",
          "values": null,
          "additionalData": null,
          "operationsData": null,
          "operations": null
        }
      ]
      this._kService.updateLaneTask(this.updateTaskReqObj).subscribe(res => {
        if (res['status'] == 201) {
          // this.toasterService.success("Data Saved Successfully");
          this.oldLeadSupplierName = this.leadNameSuplier;
          this.oldLeadSupplierAddress = this.leadSupplierAddress;
          this.termSheetGenerate(param, true)
        }
      })

    }
    else {
      this.termSheetGenerate(param, false)
    }
  }

  saveTermSheetData(param) {
    let obj = {
      fundedAmount: this.fundedAmount,
      facilityFeeAmount: this.faciliyFeeAmount,
      discountRateSetting: this.bankRateSetting,
      overDueFees: {
        gracePeriod: this.gracePeriod,
        chargesBeyondGracePeriod: this.chargesBeyndGracePeriod
      },
      isPersonalGuaranteeSelected: this.isPersonalGuaranteeSelected,
      otherConditions: this.otherConditions,
      forceGenerate: true
    }
    obj['buyerDataList'] = this.fileUploadData

    if (this.laneTaskIDDetail.lane.board.name == 'exim') {
      obj['bankCharges'] = this.bankChargeArray
      obj['factoringFee'] = this.factoringFee
      obj['discountRate'] = this.discountRateArray
    }
    else if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
      obj['isDiscussedWithCreditTeam'] = this.isDiscussedWithCreditTeam
    }
    this._kService.saveTermSheetData(obj, this.laneTaskIDDetail.id).subscribe(res => {
      if (res) {
        this.toasterService.success("Saved Successfully");
      }
    })
  }
  termSheetGenerate(param, force) {
    let obj = {
      fundedAmount: this.fundedAmount,
      facilityFeeAmount: this.faciliyFeeAmount,
      discountRateSetting: this.bankRateSetting,
      overDueFees: {
        gracePeriod: this.gracePeriod,
        chargesBeyondGracePeriod: this.chargesBeyndGracePeriod
      },
      isPersonalGuaranteeSelected: this.isPersonalGuaranteeSelected,
      otherConditions: this.otherConditions,
      forceGenerate: true
    }
    obj['buyerDataList'] = this.fileUploadData
    if (this.laneTaskIDDetail.lane.board.name == 'exim') {
      obj['bankCharges'] = this.bankChargeArray
      obj['factoringFee'] = this.factoringFee
      obj['discountRate'] = this.discountRateArray
    }
    else if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
      obj['isDiscussedWithCreditTeam'] = this.isDiscussedWithCreditTeam
    }
    this._kService.ValidateTermSheetPDF(obj, this.laneTaskIDDetail.id).subscribe(res => {
      this._kService.generateTermSheetPDF(obj, this.laneTaskIDDetail.id).subscribe(res => {
        if (res) {
          this.toasterService.success("Saved Successfully");
        }
        if (param) {
          this.downloadPrepareTermSheet(res);
        }
      })
    })


  }

  changeRpaDate(event, type) {
    if (type == 'effectiveDate') {
      let endValue = event.value;
      this.aggrementEffectiveDate = moment(endValue).format('YYYY-MM-DD');
    }
    else if (type == 'signingDate') {
      let value = event.value;
      this.aggrementSigningDate = moment(value).format('YYYY-MM-DD');
    }

  }
  submitRPASheet() {
    let obj = {
      businessState: this.businessState,
      businessType: this.businessType,
      discountingFees: this.discountingFee,
      fundingRate: this.fundingRate,
      aggrementEffectiveDate: this.aggrementEffectiveDate,
      aggrementSigningDate: this.aggrementSigningDate,
    }
    this._kService.generateRPAPDF(obj, this.laneTaskIDDetail.id).subscribe(res => {
      if (res) {
        this.toasterService.success('Data Saved Successfully');
      }
    })
  }
  downloadRPASheet() {
    let obj = {
      businessState: this.businessState,
      businessType: this.businessType,
      discountingFees: this.discountingFee,
      fundingRate: this.fundingRate,
      aggrementEffectiveDate: this.aggrementEffectiveDate,
      aggrementSigningDate: this.aggrementSigningDate,
    }
    this._kService.generateRPAPDF(obj, this.laneTaskIDDetail.id).subscribe(res => {
      let blob = new Blob([res], {
        type: 'application/pdf'
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'RPASent.pdf';
      link.click();
      window.URL.revokeObjectURL(link.href);
      this.fetchRPADocument();
    })
  }

  downloadBuyerTemplate() {
    this.JSONToCSVConvertor(this.templateHeaderList(), 'template', true);
  }

  templateHeaderList() {
    let responseList = [];
    let orderObject = {};
    if (this.laneTaskIDDetail.lane.board.name == 'exim') {
      orderObject['Buyer Legal Name'] = 'Sample Text (text only)';
      orderObject['Buyer Address'] = 'Sample Text (text only)';
      orderObject['Nature of Business'] = 'Service/Product (select only one)';
      orderObject['Currency Of Invoice'] = 'INR (select only one)';
      orderObject['Limit Required'] = '100 (number only)';
      orderObject['Tenor Requested'] = '100 (number only)';
      orderObject['Expected no of Invoices'] = '100 (number only)';
      orderObject['Expected Total Seller Turnover'] = '100 (number only)';
      orderObject['Expected Open Account Turnover'] = '100 (number only)';
      orderObject['Buyer Geography'] = 'India (select only one)';

    }
    else if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
      orderObject['Buyer Name'] = 'Sample Text (text only)';
      orderObject['Buyer Address'] = 'Sample Text (text only)';
      orderObject['Buyer Rating'] = 'Sample Text (text only)';
      orderObject['Buyer Turnover'] = '100 (number only)';
    }

    responseList.push(orderObject);

    return responseList;
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (ShowLabel) {
      var row = "";
      for (var index in arrData[0]) {
        row += index + ',';
      }

      row = row.slice(0, -1);
      CSV += row + '\r\n';
    }

    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    var fileName = "MyReport_";
    fileName += ReportTitle.replace(/ /g, "_");
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    link.setAttribute('visibility', 'hidden');
    link.download = "template_sample" + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getBoardLaneById() {
    this._kService.getLaneWithUserAccess(this.boardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'];
      this.changeLaneTask(this.laneTaskIDDetail.lane.id);
      console.log(this.selectedBoardLane);
    });

  }

  deletePrepareTermDocs(id) {
    this._kService.deleteTermSheet(id).subscribe(res => {
      if (res['status'] == 200) {
        this.toasterService.success('Term Sheet Delete Successfully');
        this._kService.getPrepareTermSheetData(this.laneTaskIDDetail.id).subscribe(res => {
          this.termSheetDocument = res['result'];
        });

      }
    })
  }

  changeLaneTask(laneTaskDetailsId) {
    let currentLaneStatus: string
    currentLaneStatus = this.laneTaskIDDetail.lane.name
    this.selectedBoardLane = [];
    let forwardMove;
    let backMove
    for (let i = 0; i < this.boardLane.length; i++) {
      this.boardLane[i]['laneDTO']['backMove'] = false;
      this.boardLane[i]['laneDTO']['forwardMove'] = false;
      this.boardLane[i]['laneDTO']['selected'] = false;
      this.boardLane[i]['laneDTO']['current'] = false;
      this.boardLane[i]['laneDTO']['cold'] = false;
      if (this.boardLane[i]['laneDTO'].id == laneTaskDetailsId) {
        let laneAccessTypeDTO = this.boardLane[i]['accessType'];
        if (laneAccessTypeDTO.length == 1) {
          if (laneAccessTypeDTO.includes('VIEW')) {
            this.isEditableAccess = false
          }
        }
        this.selectedLanePosition = this.boardLane[i]['laneDTO'].position;
        forwardMove = this.selectedLanePosition + 1;
        backMove = this.selectedLanePosition - 1;
        this.selectedBoardLane.push(this.boardLane[i]['laneDTO']);
        this.boardLane[i]['laneDTO']['current'] = true;
        this.boardLane[i]['laneDTO']['selected'] = true;
        this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
        this.selectedlane = this.boardLane[i]['laneDTO'].name;
      }
    }

    if (currentLaneStatus == 'cold-leads') {
      let laneResultPosition = this.laneTaskIDDetail.fromLane.position - 1
      this.boardLane[laneResultPosition]['laneDTO']['backMove'] = true;
      this.boardLane[laneResultPosition]['laneDTO']['selected'] = false;
      this.selectedBoardLane.push(this.boardLane[laneResultPosition]['laneDTO']);
    } else {
      for (let i = 0; i < this.boardLane.length; i++) {
        if (this.selectedlane != 'cold-leads') {
          if (this.boardLane[i]['laneDTO'].name == 'cold-leads') {
            this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
            this.boardLane[i]['laneDTO']['cold'] = true;
            this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
          }
        }
        if (this.selectedlane != 'cold-leads') {
          if (this.boardLane[i]['laneDTO'].position == forwardMove) {
            if (this.selectedlane != 'buyer-acceptance') {
              this.boardLane[i]['laneDTO']['forwardMove'] = true;
              this.boardLane[i]['laneDTO']['selected'] = false;
              this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
              this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
            }

          }
          else if (this.boardLane[i]['laneDTO'].position == backMove) {
            this.boardLane[i]['laneDTO']['backMove'] = true;
            this.boardLane[i]['laneDTO']['selected'] = false;
            this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
            this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
          }
        }
      }
    }

    console.log('sleecte----', this.selectedBoardLane);
  }


  generateCAMReport() {
    this._kService.generateCAMReport(this.getGSTIN).subscribe(res => {
      console.log(res);
      if (res['status'] == 200) {
        this.toasterService.success("Download Successfully");
        var contentDispositionHeader = res.headers.get('content-disposition');
        let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
        result = result.replace(/"/g, '')
        saveAs(res.body, result);
        return result.replace(/"/g, '');
      }

    })


  }
  getAllTags() {
    this._kService.getAllTags(this.boardId).subscribe((res: any) => {
      this.allTags = res['result'];

      this.allTags.forEach(ele => {
        this.allTagList.push(ele.tagName)
      })
      this.filteredTags = this.tagInputCtrl.valueChanges.pipe(
        startWith(null),
        map((tagValue: string | null) => tagValue ? this._filter(tagValue) : this.allTagList.slice()));
    });
  }

  addTagInput(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.allSelectedTagList.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.tagInputCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.allSelectedTagList.indexOf(tag);

    if (index >= 0) {
      this.allSelectedTagList.splice(index, 1);
    }
  }
  newValue: string;
  selectedTags(event: MatAutocompleteSelectedEvent): void {
    this.newValue = event.option.viewValue;
    let isValPresent = this.allTagList.findIndex(x => { return x == event.option.viewValue });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(0, (event.option.viewValue.length - 10));
      this.newValue = subStr;
    }
    if (this.allSelectedTagList.includes(this.newValue)) {
    } else {
      this.allSelectedTagList.push(this.newValue);
    }
    this.tagListInput.nativeElement.value = '';
    this.tagInputCtrl.setValue(null);
    requestAnimationFrame(() => {
      this.openAuto(this.matACTrigger);
    })
    let tagObj = []
    let reqObj = {}

    tagObj.push({
      "boardId": this.boardId,
      "tagName": this.newValue
    })

    reqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId
    }
    reqObj['tags'] = tagObj;
    this._kService.updateLaneTask(reqObj).subscribe(res => {
      if (res['status'] == 201) {
        this.getLaneTaskHistory();
        this.getLaneDetailsById(false)
      }
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTagList.filter(tag => tag.toLowerCase().indexOf(filterValue) >= 0);
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.tagListInput.nativeElement.focus();
    console.log(trigger);
  }

  selectedAssigneSend
  onAssigneeChange(event) {
    this.selectedAssigneSend = event.option.value.id
  }

  setValue(item) {
    const formArray = new FormArray([]);
    let key = Object.keys(item)
    for (let x of key) {
      formArray.push(this.formBuilder.group({
        key: x,
        value: item[x]
      }));
    }
    this.keyValueForm.setControl('details', formArray);
    this.customInfo = this.keyValueForm.get('details') as FormArray;
  }

  getLaneCofigFields(response) {
    this._kService.getLaneConfigById(this.laneTaskIDDetail.lane.id).subscribe(res => {
      if (res['status'] == 200) {
        this.laneTaskConfig = res['result']['config'];
        if (response['data'] != null && response['data'].length > 0) {
          let checkarrya = response['data']
          for (let i = 0; i < checkarrya.length; i++) {
            this.laneTaskConfig.push(checkarrya[i])
          }
        }
        this.laneTaskConfig = Object.values(this.laneTaskConfig.reduce((acc, cur) => Object.assign(acc, { [cur.field]: cur }), {}));
      }
    })
  }
  laneArrayKeys = [];
  oldLeadSupplierName: string;
  oldLeadSupplierAddress: string;
  leadNameSuplier: string;
  leadSupplierAddress: any;
  getLaneDetailsById(isFromInit) {
    this._kService.detailsByLaneId(this.data.laneTask.id).subscribe(res => {
      this.laneArrayKeys = [];
      this.selectedUserListOptions = [];
      this.selectedLeadOwnerListOptions = []
      this.fruits = [];
      this.allSelectedTagList = [];
      this.laneTaskIDDetail = res['result'];
      this.boardId = res['result'].boardId;
      this.currentTabId = this.laneTaskIDDetail.lane.id;
      this.exporterName = this.laneTaskIDDetail.name;
      this.leadNameSuplier = this.exporterName
      this.leadExporterName = this.laneTaskIDDetail.name
      if (res['result'])
        this.demo1TabIndex = this.laneTaskIDDetail.lane.position
      this.laneTaskConfig = res['result']['laneWiseData'];
      let keys = Object.keys(this.laneTaskConfig);
      let isPresent = keys.findIndex(x => { return x == "prepare-termsheet" })
      if (isPresent != -1) {
        this.isPrepareTermSheet = true;
      }
      let isColdAdded = false
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          keys: keys[i],
          value: this.laneTaskConfig[keys[i]]
        }
        // this.panelOpenState.push({ value: false });
        if (this.laneTaskIDDetail.lane.name == 'cold-leads' && this.laneTaskIDDetail.fromLane.position <= i) {
          if (isColdAdded == false) {
            this.laneArrayKeys.push({
              keys: keys[keys.length - 1],
              value: this.laneTaskConfig[keys[keys.length - 1]]
            })
            isColdAdded = true;
          }
          continue;
        }
        this.laneArrayKeys.push(obj);
      }
      this.laneArrayKeys = this.laneArrayKeys.map((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] == 'new-leads') {
            let detailsArra = [];
            detailsArra = item['value']
            for (let i = 0; i < detailsArra.length; i++) {
              if (detailsArra[i]['field'] == "supplier_address") {
                this.oldLeadSupplierAddress = detailsArra[i].value
                this.leadSupplierAddress = detailsArra[i].value
              }
              if (detailsArra[i]['field'] == "supplier_name") {
                this.oldLeadSupplierName = detailsArra[i].value
                this.leadNameSuplier = detailsArra[i].value
              }
              if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
                if (detailsArra[i]['field'] == "supplier_name") {
                  detailsArra[i].value = this.leadExporterName
                }
                this.leadNameSuplier = this.leadExporterName
              }
            }
          }
          item['value'] = item['value'].map(data => {
            if (data['type'] == 'boolean' && data['value'] != false && data['value'] != true) {
              data['value'] = data['value'] != 'false';
            }
            return data;
          })
        });
        return item;
      });
      console.log("lane", this.laneArrayKeys);
      this.laneArrayKeys = this.laneArrayKeys.map(ele => {
        if (ele.keys == 'new-leads' || ele.keys == 'account-created-sales') {
          for (let i = 0; i < ele.value.length; i++) {
            if (ele.value[i].field == "monthly_shipments_amount") {
              if (ele.value[i]['values'] != null && ele.value[i]['values'].length != 0) {
                this.shipmentAmount = ele.value[i]['values'][0].amount
                this.shipmentCurrency = ele.value[i]['values'][0].currency
              }
            }
            if (ele.value[i].field == "remittance_charge") {
              if (ele.value[i]['values'] != null && ele.value[i]['values'].length != 0) {
                this.remittanceAmount = ele.value[i]['values'][0].amount
                this.remittanceCurrency = ele.value[i]['values'][0].currency
              }
            }
            if (ele.value[i].field == "setup_fee") {
              if (ele.value[i]['values'] != null && ele.value[i]['values'].length != 0) {
                this.setUpFeeAmount = ele.value[i]['values'][0].amount
                this.setUpFeeCurrency = ele.value[i]['values'][0].currency
              }
            }
            if (ele.value[i].field == "identification_number") {
              if (ele.value[i]['values'] != null && ele.value[i]['values'].length != 0) {
                this.docNumber = ele.value[i]['values'][0].number
                this.identificationType = ele.value[i]['values'][0].type
              }
            }
            if (ele.value[i].field == "buyer_region") {
              if (ele.value[i]['dataList'] != null && ele.value[i]['dataList'].length != 0) {
                this.buyerRegionList = []
                this.buyerRegionList = (ele.value[i]['dataList']);
              }
            }
            if (ele.value[i].field == "gstin") {
              this.getGSTIN = (ele.value[i]['value']);

            }

          }
        }
        return ele

      })
      console.log(this.buyerRegionList);
      if (res['result'].customInfo != null) {
        // if (!isEmpty(res['result'].customInfo)) {
        //   this.setValue(res['result'].customInfo)
        // }
      }
      if (res['result'].assignee != null && res['result'].assignee.length > 0) {
        for (let i = 0; i < res['result'].assignee.length; i++) {
          if (res['result'].assignee[i].user) {
            this.selectedUserListOptions.push(res['result'].assignee[i].user['name']);
          }
        }
      }
      if (res['result'].leadOwner != null) {
          this.selectedLeadOwnerListOptions.push(res['result'].leadOwner['name']);

      }

      if (res['result'].watchers != null && res['result'].watchers.length > 0) {
        for (let i = 0; i < res['result'].watchers.length; i++) {
          if (res['result'].watchers[i].user) {
            this.fruits.push(res['result'].watchers[i].user['name']);
          }
        }
      }
      if (res['result'].tags != null && res['result'].tags.length > 0) {
        for (let j = 0; j < res['result']['tags'].length; j++) {
          this.fetchSelectedtags.push(res['result']['tags'][j])
          this.allSelectedTagList.push(res['result']['tags'][j].tagName)
        }
      }

      if (this.laneTaskConfig) {
        this.getUploadedFileData('config', this.laneTaskIDDetail.lane.id);
      }
      if (this.laneTaskIDDetail.lane.name == 'new-leads') {
        // this.getContactDetails();
      }
      if (isFromInit) {
        this.getBoardLaneById();
        // this.getBoardList();
        // this.getAllTags();
        // this.getUserList();
        // this.getLaneTaskHistory();
        // this.getTaskComment();
        // this.getCountryList();
        // this.fetchCountryList();
        this.fetchUploadedTaskDocuments();
        // this.fetchRPADocument();
        // this.getRPASheet();
        // this.getDropdownData();
        // this.getPrepareTermSheetData();
        // setTimeout(() => {
        //   this.checkNull(this.discountRateArray, "discountRateSection", null, false);
        //   this.checkNull(this.bankRateSetting, "bankRateSettingSection", null, false);
        //   this.checkNull(this.bankChargeArray, "bankChargesSection", null, false);
        //   this.checkNull(this.faciliyFeeAmount, "faciliyFeeAmountSection", null, false);
        // }, 1000)
      }
    })
  }
  allUserList = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  totalLaneHistory: number;

  getLaneTaskHistory(reset?) {
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = {
      from: this.pageNumber,
      size: this.pageSize
    }
    this._kService.getLaneTaskHistory(this.laneTaskIDDetail.id, obj).subscribe(res => {
      this.totalLaneHistory = res['total'];
      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber != 0) {
        this.fetchTaskHistory = this.fetchTaskHistory.concat(res['result']);
      }
      else {
        this.fetchTaskHistory = res['result'];
      }
    })
  }
  userlist = []
  getUserList() {
    this._kService.getKanbanUserList().subscribe(list => {
      if (list['status'] == 200) {
        this.options = list['result'];
        this.options.forEach(ele => {
          this.allUserList.push(ele.userName)
          this.userlist = this.allUserList
        })
        this.filteredUserList = this.assigneeInputCtrl.valueChanges.pipe(
          startWith(null),
          map((userValue: string | null) => userValue ? this._filterUserList(userValue) : this.allUserList.slice()));

        this.filteredOwnerList = this.leadOwnerInputCtrl.valueChanges.pipe(
          startWith(null),
          map((userValue: string | null) => userValue ? this._filterUserList(userValue) : this.allUserList.slice()));

        this.filteredWatcherList = this.fruitCtrl.valueChanges.pipe(
          startWith(null),
          map((fruit: string | null) => fruit ? this._filterabc(fruit) : this.allUserList.slice()));
      }

    })

  }

  selectedUserList(event: MatAutocompleteSelectedEvent, type) {
    let newValue = event.option.viewValue;
    let isValPresent = this.allUserList.findIndex(x => { return x == event.option.viewValue });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(0, (event.option.viewValue.length - 15));
      newValue = subStr;
    }
    if (type == 'assignee') {
      if (this.selectedUserListOptions.includes(newValue)) {
        //this.selectedUserListOptions = [this.selectedUserListOptions.filter(tag => tag !== newValue)];
      } else {
        this.selectedUserListOptions.push(newValue);
      }
      this.userListInput.nativeElement.value = '';
      this.assigneeInputCtrl.setValue(null);

      // keep the autocomplete opened after each item is picked.
      requestAnimationFrame(() => {
        this.openAssigneeAuto(this.matACAssignnTrigger, 'assignee');
      })
      this.assignMake(newValue);
    }
    else if (type == 'leadOwner') {
      if (this.selectedLeadOwnerListOptions.includes(newValue)) {
      } else {
        this.selectedLeadOwnerListOptions.push(newValue);
      }
      this.userLeadListInput.nativeElement.value = '';
      this.leadOwnerInputCtrl.setValue(null);

      // keep the autocomplete opened after each item is picked.
      requestAnimationFrame(() => {
        this.openAssigneeAuto(this.matACAssignnTrigger, 'lead');
      })
      this.leadOwnerAssign(newValue);
    }

  }

  openAssigneeAuto(trigger: MatAutocompleteTrigger, value) {
    if(value== 'assignee'){
      trigger.openPanel();
      this.userListInput.nativeElement.focus();
    }
    else{
      trigger.openPanel();
      this.userLeadListInput.nativeElement.focus();
    }

  }
  private _filterUserList(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserList.filter(user => user.toLowerCase().indexOf(filterValue) >= 0);
  }
  addAssigneeInput(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.selectedUserListOptions.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.assigneeInputCtrl.setValue(null);
  }

  addLeadOwnerInput(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.selectedLeadOwnerListOptions.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.leadOwnerInputCtrl.setValue(null);
  }

  getAssigneeName(name) {
    var matches = name.match(/\b(\w)/g);
    if (matches.length > 2) {
      matches.splice(2, matches.length)
    }
    this.userProfileName = matches.join('').toUpperCase()
    return this.userProfileName;
  }
  addLaneTask() {
    this.customInfo = this.keyValueForm.get('details') as FormArray;
    this.customInfo.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      key: [],
      value: []
    });
  }
  conatctInfo(): FormGroup {
    return this.formBuilder.group({
      name: [],
      email: [],
      phone: [],
      designation: []
    });
  }

  contactInfoArray: any;
  addContactInfo() {
    this.contactInfoArray = this.contactForm.get('items') as FormArray;
    this.contactInfoArray.push(this.conatctInfo());
  }
  getColdLaneMoveReason: string;
  laneId: string;
  onLeadChange(event) {
    this.laneId
    this.selectedBoardLane.map(data => {
      if (event == data['name']) {
        this.laneId = data['id'];
      }
    })
    console.log(this.laneTaskIDDetail)
    // if (event != 'cold-leads') {
    //   this.selectedlane = event;
    //   this.assignLaneTaskOneToAnother(this.laneId);
    // }
    // else if (event == 'cold-leads') {
    let configobj = {
      id: this.laneTaskIdByUrl,
      laneId: this.laneTaskIDDetail.lane.id,
      previousLaneName: this.laneTaskIDDetail.lane.name,
      currentLaneName: event,
      assignees: this.laneTaskIDDetail['assignee'],
      boardName: this.laneTaskIDDetail.lane.board.name
    }
    const dialogRef = this.dialog.open(LaneMoveComponent, {
      disableClose: true,
      width: '55%',
      height: '70%',
      data: {
        obj: configobj
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result != false) {
        this.getColdLaneMoveReason = result
      }
      if (result == undefined || result != false) {
        this.selectedlane = event;
        this.assignLaneTaskOneToAnother(this.laneId);
      }
      else if (result == false) {
        this.selectedlane = this.laneTaskIDDetail.lane.name
      }
    });

    // }

  }

  doSubmit() {
  }

  setTagColor(tag) {
    for (let i = 0; i < this.allTags.length; i++) {
      if (this.allTags[i].tagName == tag) {
        return this.allTags[i].tagColor
      }
    }
  }
  checkLaneMovement() {

  }
  currentTabId: any;
  tabName: string;
  // tabChanged(event?) {
  //   if (event.tab.textLabel == 'new-leads') {
  //     this.getContactDetails();
  //   }
  //   this.demo1TabIndex = this.laneTaskIDDetail.lane.position
  //   if (this.boardLane != null && this.boardLane.length != 0) {
  //     this.boardLane.map(val => {
  //       if (val.laneDTO.name == event.tab.textLabel) {
  //         this.currentTabId = val.laneDTO.id
  //       }
  //     })
  //     this.getBuyerData(this.currentTabId)
  //   }
  //   this.tabName = event.tab.textLabel;
  // }
  assignLaneTaskOneToAnother(laneId) {
    for (let j = 0; j < this.selectedBoardLane.length; j++) {
      if (this.selectedlane == this.selectedBoardLane[j].name) {
        this.toLaneId = this.selectedBoardLane[j].id;
      }
    }
    let obj = {
      "id": this.laneTaskIDDetail.id,
      "laneId": this.laneTaskIDDetail.lane.id,
      "toLaneID": this.toLaneId
    }
    if (this.getColdLaneMoveReason != null) {
      obj['coldLeadReasons'] = this.getColdLaneMoveReason
    }
    this._kService.assignLaneTaskMovement(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneDetailsById(true);
      }
    }, (error) => {
      if (error) {
        this.selectedlane = this.laneTaskIDDetail.lane.name
      }

    });
  }
  chaneAssigne() {
    let obj = []
    for (let i = 0; i < this.selectedUserListOptions.length; i++) {
      for (let j = 0; j < this.options.length; j++) {
        if (this.selectedUserListOptions[i] == this.options[j].name) {
          obj.push({
            "remarks": "test",
            "userId": this.options[j].id
          })
          this._kService.addAssigneeToLaneTask(obj, this.laneTaskIDDetail.id).subscribe(res => {
          })
        }
      }
    }
  }



  closeDialog() {
  }

  assignMake(userName) {
    let req = []
    this.options.map(ele => {
      if (userName == ele.userName) {
        req.push({
          "remarks": "test",
          "userId": ele.userId
        })
      }
    })
    this._kService.addAssigneeToLaneTask(req, this.laneTaskIDDetail.id).subscribe(res => {
      if (res['status'] == 201) {
        this.getLaneTaskHistory();
        this.getLaneDetailsById(false)
      }
    })
  }

  leadOwnerAssign(userName) {
    let req;
    this.options.map(ele => {
      if (userName == ele.userName) {
        req = {
          "id": this.laneTaskIDDetail.id,
          "leadOwner": {
            id: ele.userId
          }
        }
      }
    })
    this._kService.addLeadOwnerToLaneTask(req).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneTaskHistory();
        this.getLaneDetailsById(false)
      }
    })

  }



  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  // remove(fruit: string): void {
  //   const index = this.fruits.indexOf(fruit);

  //   if (index >= 0) {
  //     this.fruits.splice(index, 1);
  //   }
  // }

  selectedWatchersList(event: MatAutocompleteSelectedEvent): void {
    let newValue = event.option.viewValue;
    let isValPresent = this.allUserList.findIndex(x => { return x == event.option.viewValue });
    if (isValPresent == -1) {
      let subStr = event.option.viewValue.substring(0, (event.option.viewValue.length - 15));
      newValue = subStr;
    }
    if (this.fruits.includes(newValue)) {
    } else {
      this.fruits.push(newValue);
    }
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);

    requestAnimationFrame(() => {
      this.openAssigneeAuto(this.matACAssignnTrigger ,'');
    })
    this.assignWatchers();

    // this.fruits.push(event.option.viewValue);
    // this.fruitInput.nativeElement.value = '';
    // this.fruitCtrl.setValue(null);
  }


  assignWatchers() {
    let req = []
    for (let i = 0; i < this.options.length; i++) {
      this.fruits.map(val => {
        if (val == this.options[i].userName) {
          req.push({
            "notificationTypes": [
              "EMAIL"
            ],
            "userId": this.options[i].userId
          })
        }
      })
    }
    this._kService.addWatchersToLaneTask(req, this.laneTaskIDDetail.id).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneTaskHistory();
        this.getLaneDetailsById(false)
      }
    })
  }


  private _filterabc(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserList.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }





  submitContactDetails() {
    let result = [];
    result = this.contactForm.value['items'].filter((item) => item.name != null);
    this._kService.saveContactDetails(result, this.laneTaskIDDetail.id)
      .subscribe(res => {
        console.log(res);
      })
  }
  submit() {

  }

  // deleteContactDetails(item, index) {
  //   let contactId = item.value.id
  //   console.log(this.storeContactDetails)
  //   if (this.storeContactDetails != null && this.storeContactDetails.length != 0) {
  //     if (contactId != null && contactId != undefined) {
  //       this._kService.deleteContactDetails(this.laneTaskIDDetail.id, contactId).subscribe(res => {
  //         if (res['status'] == 200) {
  //           var formData = this.contactForm.get('items') as FormArray
  //           formData.reset()
  //           this.toasterService.success("Successfully Deleted");
  //           this.getContactDetails();
  //         }
  //       })
  //     }
  //     else {
  //       this.contactInfoArray.removeAt(index);
  //       this.toasterService.success("Successfully Deleted");
  //     }
  //   }
  // }
  storeContactDetails = []
  // getContactDetails() {
  //   this._kService.getContactDetails(this.laneTaskIDDetail.id).subscribe(res => {
  //     this.storeContactDetails = res['result'];
  //     const formArray = new FormArray([]);
  //     //let key = Object.keys(res['result']);
  //     if ((res['result'] == null || res['result'].length < 1) && this.contactInfoArray == undefined) {
  //       formArray.push(this.formBuilder.group({
  //         name: "",
  //         email: "",
  //         phone: "",
  //         designation: "",
  //         id: ""
  //       }));
  //       this.contactForm.setControl('items', formArray);
  //       this.contactInfoArray = this.contactForm.get('items') as FormArray;

  //     } else if ((res['result'] != null && res['result'].length > 0)) {
  //       for (let x of res['result']) {
  //         formArray.push(this.formBuilder.group({
  //           name: x.name,
  //           email: x.email,
  //           phone: x.phone,
  //           designation: x.designation,
  //           id: x.id
  //         }));
  //       }
  //       this.contactForm.setControl('items', formArray);
  //       this.contactInfoArray = this.contactForm.get('items') as FormArray;

  //     }

  //   })
  // }
  saveBuyerDataRow() {
    if (this.laneTaskIDDetail.lane.board.name == 'exim') {
      this.fileUploadData.map(ele => {
        if (ele.buyerIndustry == 'Others') {
          ele['buyerIndustry'] = this.eximBuyerInd;
        }
      })
    }
    this._kService.createBuyerDataRow(this.fileUploadData, this.laneTaskIDDetail.id).subscribe(res => {
      if (res) {
        this.getBuyerData(this.laneTaskIDDetail.lane.id);
      }

    })
  }

  storeAddedBuyerRow = [];
  shipmentAmount: number;
  shipmentCurrency: string;
  remittanceCurrency: string;
  remittanceAmount: number;
  setUpFeeCurrency: string;
  setUpFeeAmount: number
  submitForm(lane, laneName) {
    this.updateTaskReqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId
    }
    if (lane != undefined || lane != null) {
      this.updateTaskReqObj['data'] = lane['value']
    }
    if (this.keyValueForm.value['details'] != null && this.keyValueForm.value['details'].length > 0) {
      this.keyValueForm.value['details'] = this.keyValueForm.value['details'].filter(item => (item.key != null || item.value != null));
      let formDataFormat = this.keyValueForm.value
      var dataMap = {};
      // get custom info from form
      var customInfo = formDataFormat;
      customInfo['details'].forEach(element => {
        dataMap[element.key] = element.value;
      });
      this.updateTaskReqObj['customInfo'] = dataMap
    }
    if (this.deleteAssignList != null && this.deleteAssignList.length > 0) {
      this.updateTaskReqObj['removeAssignees'] = this.deleteAssignList
    }
    if (laneName == 'new-leads') {
      this.updateTaskReqObj['name'] = this.exporterName;
    }
    let arry = []
    arry.push(lane)
    lane = arry.map(ele => {
      if (ele.keys == 'new-leads' && (this.shipmentAmount != undefined || this.shipmentCurrency != undefined)) {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "monthly_shipments_amount") {
            ele.value[i]['values'] = [{
              amount: this.shipmentAmount,
              currency: this.shipmentCurrency
            }]
          }
          if (ele.value[i].field == "supplier_name") {
            ele.value[i]['value'] = this.exporterName
          }
        }
      }
      if (ele.keys == 'account-created-sales') {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "remittance_charge" && (this.remittanceAmount != undefined || this.remittanceCurrency != undefined)) {
            ele.value[i]['values'] = [{
              amount: this.remittanceAmount,
              currency: this.remittanceCurrency
            }]
          }
          if (ele.value[i].field == "setup_fee" && (this.setUpFeeAmount != undefined || this.setUpFeeCurrency != undefined)) {
            ele.value[i]['values'] = [{
              amount: this.setUpFeeAmount,
              currency: this.setUpFeeCurrency
            }]
          }
          if (ele.value[i].field == "identification_number" && (this.identificationType != undefined || this.docNumber != undefined)) {
            ele.value[i]['values'] = [{
              type: this.identificationType,
              number: this.docNumber
            }]
          }
          if (ele.value[i].field == "supplier_gstin") {
            ele.value[i]['value'] = this.docNumber
          }
        }
      }
      return ele
    })

    if (this.fileUploadData != null && this.fileUploadData.length > 0) {
      this.saveBuyerDataRow();
    }
    this._kService.updateLaneTask(this.updateTaskReqObj).subscribe(res => {
      if (res['status'] == 201) {
        this.getLaneDetailsById(false);
        if (laneName == 'new-leads') {
          lane = arry.map(ele => {
            for (let i = 0; i < ele.value.length; i++) {
              if (ele.value[i].field == "contact_details") {
                if (this.contactForm.value['items'] != null && this.contactForm.value['items'].length != 0) {
                  this.submitContactDetails();
                }
              }
            }
          })
        }
        this.getLaneTaskHistory('reset')
        this.toasterService.success("Data Saved Successfully");
      }
    })
  }


  changeLeadName(leadName) {
    this.updateTaskReqObj = {
      id: this.laneTaskIDDetail.id,
      laneId: this.laneTaskIDDetail.lane.id,
      toLaneID: this.toLaneId,
      name: leadName
    }
    this._kService.updateLaneTask(this.updateTaskReqObj).subscribe(res => {
      if (res['status'] == 201) {
        this.isIconVisible = false;
        this.toasterService.success("Data Saved Successfully");
        this.laneArrayKeys = this.laneArrayKeys.map(ele => {
          if (ele.keys == 'new-leads') {
            for (let i = 0; i < ele.value.length; i++) {
              if (ele.value[i].field == "supplier_name") {
                ele.value[i].value = this.leadExporterName
              }
            }
          }
          this.leadNameSuplier = this.leadExporterName
          return ele

        })
      }
    })

  }

  isIconVisible: boolean = false
  getLeadName(leadname) {
    if (leadname != this.laneTaskIDDetail.name) {
      this.isIconVisible = true;
    }
    else {
      this.isIconVisible = false;
    }
  }
  resetLeadName() {
    this.leadExporterName = this.laneTaskIDDetail.name
    this.isIconVisible = false;
  }

  copyBuyerDataRow(row) {
    //this.fileUploadData.push(row);
    let obj = {
      approvedLimit: row.approvedLimit,
      buyerAddress: '',
      buyerName: '',
      currency: null,
      currencyOfInvoice: row.currencyOfInvoice,
      declineReasonComment: null,
      email: "",
      expectedNoOfInvoices: row.expectedNoOfInvoices,
      expectedOpenAccountTurnover: row.expectedNoOfInvoices,
      expectedTotalSellerTurnover: row.expectedTotalSellerTurnover,
      id: '',
      isSelected: row.isSelected,
      limitRecommended: row.limitRecommended,
      limitRequired: row.limitRequired,
      max_limit: row.max_limit,
      natureOfBusiness: "",
      negotiatedROI: row.negotiatedROI,
      number_of_days: row.number_of_days,
      reasonForDecline: row.reasonForDecline,
      requestedLimit: row.requestedLimit,
      starting_from_invoice: row.starting_from_invoice,
      supplierAddress: row.supplierAddress,
      supplierName: row.supplierName,
      tenor: row.tenor,
      tenorFrom: row.tenorFrom,
      tenorRequested: row.tenorRequested,
      totalLimitAmount: row.totalLimitAmount
    }
    this.fileUploadData.push(obj)
    console.log(this.fileUploadData)
  }
  deleteBuyerData(id, i) {
    if (id) {
      let confObj = {
        data: 'kanbanBoard'
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: '30%',
        height: 'auto',
        data: confObj,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result) {
          this._kService.deleteBuyerData(this.laneTaskIDDetail.id, id).subscribe(res => {
            if (res['status'] == 200) {
              this.fileUploadData = this.fileUploadData.filter(item => item !== id)
              this.getBuyerData(this.laneTaskIDDetail.lane.id);
            }
          })
        }
      });
    }
    else {
      console.log(this.fileUploadData);
      this.fileUploadData.splice(i, 1)
    }


  }
  //upload Documents in lane task
  onFileChanged(event) {
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
    this.fileUpload();
  }
  fileUpload(file?) {
    if (file) {
      this.file = file;
      this.file.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    if(!this.data.preQualified){
      this._kService.uploadTaskDocuments(uploadData, this.laneTaskIDDetail.id).subscribe(file => {
        if (file['status'] == 200) {
          this.isProgressVisible = false;
          this.fetchUploadedTaskDocuments();
          this.getLaneTaskHistory();
          //this.toasterService.success("User Uploaded Successfully");
          // this.dialogRef.close();
        }
      });
    } else {
        this.preSalesService.uploadPrequalifiedDoc(uploadData,this.laneTaskIDDetail.id).subscribe(res =>{
          if (res['status'] == 200) {
            this.isProgressVisible = false;
            this.fetchPreQualifiedDocList();
          }
        })
    }
  }

  checkSupplierDetails() {

  }
  fileUploadedRes = [];
  onConfigFileChanged(event, fieldName, datatype, laneKey) {
    if (event[0]) {
      this.configFile = event[0];
    }
    else {
      this.configFile = event.target.files[0];
    }
    const uploadData = new FormData();
    uploadData.append('file', this.configFile, this.configFile.name);
    uploadData.append('dataType', datatype);
    uploadData.append('remarks', fieldName);
    uploadData.append('laneTaskId', this.laneTaskIDDetail.id);

    if (fieldName == 'buyer_data') {

      this.pagesService.getUploadMappingHeaders(uploadData).subscribe(res => {
        if (res['status'] == 200) {
          const dialogRef = this.dialog.open(UploadMappingComponent, {
            disableClose: true,
            maxWidth: "100vw",
            width: "100vw",
            height: "100vh",
            data: {
              fileName: this.configFile.name, pageName: 'kanban', data: res,
              uploadData: uploadData,
              boardId: this.boardId,
              buyerDataConfig: this.buyerDataIndexConfig
            },
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
              // this._kService.uploadCofigDocuments(uploadData).subscribe(file => {
              this.fileUploadedRes = result['result'];
              //if (result['status'] == 200) {
              this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {

                this.laneTaskIDDetail = res['result'];
                this.boardId = res['result'].boardId;
                if (res['result'])
                  this.laneTaskConfig = res['result']['laneWiseData'];
                let keys = Object.keys(this.laneTaskConfig);
                let arryKeys = [];
                for (let i = 0; i < keys.length; i++) {
                  let obj = {
                    keys: keys[i],
                    value: this.laneTaskConfig[keys[i]]
                  }
                  arryKeys.push(obj)
                }
                let dataIndex = this.laneArrayKeys.findIndex(x => { return x.keys == laneKey })
                let arrIndex = arryKeys.findIndex(x => { return x.keys == laneKey })
                for (let i = 0; i < this.laneArrayKeys[dataIndex]['value'].length; i++) {
                  for (let j = 0; j < arryKeys[arrIndex]['value'].length; j++) {
                    if ((this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'file' || this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'multi_upload') && this.laneArrayKeys[dataIndex]['value'][i]['field'] == fieldName
                      && (arryKeys[arrIndex]['value'][j]['type'] == 'file' || arryKeys[arrIndex]['value'][j]['type'] == 'multi_upload') && arryKeys[arrIndex]['value'][j]['field'] == fieldName) {
                      this.laneArrayKeys[dataIndex]['value'][i] = arryKeys[arrIndex]['value'][j];
                    }
                  }
                }
                this.getUploadedFileData(datatype, this.laneTaskIDDetail.lane.id);
              });
              // }
              //});
            }
          });
        }
      })
    }
    else if (fieldName != 'buyer_data') {
      this._kService.uploadCofigDocuments(uploadData).subscribe(file => {
        this.fileUploadedRes = file['result'];
        if (file['status'] == 200) {
          this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {

            this.laneTaskIDDetail = res['result'];
            this.boardId = res['result'].boardId;
            if (res['result'])
              this.laneTaskConfig = res['result']['laneWiseData'];
            let keys = Object.keys(this.laneTaskConfig);
            let arryKeys = [];
            for (let i = 0; i < keys.length; i++) {
              let obj = {
                keys: keys[i],
                value: this.laneTaskConfig[keys[i]]
              }
              arryKeys.push(obj)
            }
            let dataIndex = this.laneArrayKeys.findIndex(x => { return x.keys == laneKey })
            let arrIndex = arryKeys.findIndex(x => { return x.keys == laneKey })
            // for (let i = 0; i < this.laneArrayKeys[dataIndex]['value'].length; i++) {
            //   for (let j = 0; j < arryKeys[arrIndex]['value'].length; j++) {
            //     if ((this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'file' || this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'multi_upload') && this.laneArrayKeys[dataIndex]['value'][i]['field'] == fieldName
            //       && (arryKeys[arrIndex]['value'][j]['type'] == 'file' || arryKeys[arrIndex]['value'][j]['type'] == 'multi_upload') && arryKeys[arrIndex]['value'][j]['field'] == fieldName) {
            //       this.laneArrayKeys[dataIndex]['value'][i] = arryKeys[arrIndex]['value'][j];
            //     }
            //   }
            // }

            this.getUploadedFileData(datatype, this.laneTaskIDDetail.lane.id);


          });
        }
      });

    }
  }

  removeConfigFile() {
    this.configFile = null;
  }

  clearStack(event) {
    event.target.value = null;
  }

  fileUploadByBuyerId(event, buyerData, type) {
    if (event[0]) {
      this.buyerConfigUploadFile = event[0];
    }
    else {
      this.buyerConfigUploadFile = event.target.files[0];
    }
    const uploadData = new FormData();
    uploadData.append('file', this.buyerConfigUploadFile, this.buyerConfigUploadFile.name);
    console.log('check', uploadData)
    this._kService.uploadBuyerDocumentsById(uploadData, buyerData.id, type).subscribe(file => {
      if (file['status'] == 200) {
        this.getBuyerData(this.laneTaskIDDetail.lane.id);
        this.toasterService.success("File uploaded Successfully");
      }
    })
  }


  autoFilldata() {
    this.sidService.getBuyerDetails(this.doctype, this.docNumber).subscribe(res => {
      if (res['status'] == 200) {
        this.toasterService.success("Details successfully Fetched");
        this.laneArrayKeys = this.laneArrayKeys.map(ele => {
          if (ele.keys == 'account-created-sales') {
            for (let i = 0; i < ele.value.length; i++) {
              if (ele.value[i].field == "gstin") {
                ele.value[i].value = res['result'].gstin
              }
              else if (ele.value[i].field == "incorporated_since") {
                let incrportadDate = moment(res['result'].incorporationDate, 'YYYY-MM-DD HH:mm:ss').format();
                ele.value[i].value = incrportadDate
              }
              if (this.laneTaskIDDetail.lane.board.name == 'domestic') {
                if (ele.value[i].field == "incorporated_since_supplier") {
                  let incrportadDate = moment(res['result'].incorporationDate, 'YYYY-MM-DD HH:mm:ss').format();
                  ele.value[i].value = incrportadDate
                }
              }
            }
          }
          return ele

        })

      }
    })
  }
  docNumberChanged() {

  }
  identificationType: string = 'GSTIN'
  changeList(event) {
    this.docNumber = '';
    this.identificationType = event
    this.laneArrayKeys = this.laneArrayKeys.map(ele => {
      if (ele.keys == 'account-created-sales') {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "gstin") {
            ele.value[i].value = ''
          }
          else if (ele.value[i].field == "incorporated_since") {
            ele.value[i].value = ''
          }

        }
      }
      return ele

    })

  }

  addBuyerDataRow() {
    let tableObj = {
      buyerName: '',
      buyerAddress: '',
      natureOfBusiness: '',
      currencyOfInvoice: '',
      limitRequired: '',
      tenorRequested: '',
      expectedNoOfInvoices: '',
      expectedTotalSellerTurnover: '',
      expectedOpenAccountTurnover: ''
    }
    this.fileUploadData.push(tableObj);
  }

  checkDataTypeValidation(type, value) {
    if (type == 'email') {
      let pattern = /\S+@\S+\.\S+/;
      let email = pattern.test(value);
      if (email == false) {
        this.toasterService.error("Please enter the correct Email ID");
      }
    }

    if (type == 'phone') {
      let patrn = "^((\\+91-?)|0)?[0-9]{10}$"
      let checkPhne = value.match(patrn);
      if (checkPhne == false) {
        this.toasterService.error("Please Enter valid Number")
      }
      console.log(checkPhne);
    }

  }
  onKeyDownInput(event, type) {
    if ((type == 'long' || type == 'int' || type == 'phone') && event.key == '.') {
      event.preventDefault();
    }
  }


  deleteCustomRow(index) {
    this.customInfo.removeAt(index)
  }
  deleteDocument(buyerId, docId) {
    this._kService.deleteBuyer(buyerId, docId).subscribe(res => {
      if (res) {
        this.getBuyerData(this.laneTaskIDDetail.lane.id);
      }

    })

  }
  deleteTaskDocuments(id, field, dataType, laneKey) {
    if(!this.data.preQualified){
      console.log(id, field, dataType, laneKey)
      if (field == 'buyer_data') {
        let confObj = {
          data: 'buyerData'
        }
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          width: '30%',
          height: 'auto',
          data: confObj,
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result == true) {
            this.fileUploadData = [];
            this.configFile = null;
            this._kService.deleteUploadedDocuments(id, field, this.laneTaskIDDetail.id, dataType).subscribe(res => {
              if (field != '') {
                this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {
                  this.laneTaskIDDetail = res['result'];
                  this.boardId = res['result'].boardId;
                  if (res['result'])
                    this.laneTaskConfig = res['result']['laneWiseData'];
                  let keys = Object.keys(this.laneTaskConfig);
                  let arryKeys = [];
                  for (let i = 0; i < keys.length; i++) {
                    let obj = {
                      keys: keys[i],
                      value: this.laneTaskConfig[keys[i]]
                    }
                    arryKeys.push(obj)
                  }
                  let dataIndex = this.laneArrayKeys.findIndex(x => { return x.keys == laneKey })
                  let arrIndex = arryKeys.findIndex(x => { return x.keys == laneKey })
                  for (let i = 0; i < this.laneArrayKeys[dataIndex]['value'].length; i++) {
                    for (let j = 0; j < arryKeys[arrIndex]['value'].length; j++) {
                      if ((this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'file' || this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'multi_upload') && this.laneArrayKeys[dataIndex]['value'][i]['field'] == field
                        && (arryKeys[arrIndex]['value'][j]['type'] == 'file' || arryKeys[arrIndex]['value'][j]['type'] == 'multi_upload') && arryKeys[arrIndex]['value'][j]['field'] == field) {
                        this.laneArrayKeys[dataIndex]['value'][i] = arryKeys[arrIndex]['value'][j];
                      }
                    }
                  }
                });
                this.fetchUploadedTaskDocuments();
              }


            })

          }
        })
      }
      else {
        this._kService.deleteUploadedDocuments(id, field, this.laneTaskIDDetail.id, dataType).subscribe(res => {
          if (field != '') {
            this.configFile = null;
            if (field == 'buyer_data') {
              this.fileUploadData = null;
            }
            this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {
              this.laneTaskIDDetail = res['result'];
              this.boardId = res['result'].boardId;
              if (res['result'])
                this.laneTaskConfig = res['result']['laneWiseData'];
              let keys = Object.keys(this.laneTaskConfig);
              let arryKeys = [];
              for (let i = 0; i < keys.length; i++) {
                let obj = {
                  keys: keys[i],
                  value: this.laneTaskConfig[keys[i]]
                }
                arryKeys.push(obj)
              }
              let dataIndex = this.laneArrayKeys.findIndex(x => { return x.keys == laneKey })
              let arrIndex = arryKeys.findIndex(x => { return x.keys == laneKey })
              // for (let i = 0; i < this.laneArrayKeys[dataIndex]['value'].length; i++) {
              //   for (let j = 0; j < arryKeys[arrIndex]['value'].length; j++) {
              //     if ((this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'file' || this.laneArrayKeys[dataIndex]['value'][i]['type'] == 'multi_upload') && this.laneArrayKeys[dataIndex]['value'][i]['field'] == field
              //       && (arryKeys[arrIndex]['value'][j]['type'] == 'file' || arryKeys[arrIndex]['value'][j]['type'] == 'multi_upload') && arryKeys[arrIndex]['value'][j]['field'] == field) {
              //       this.laneArrayKeys[dataIndex]['value'][i] = arryKeys[arrIndex]['value'][j];
              //     }
              //   }
              // }
            });
            this.fetchUploadedTaskDocuments();
          }
          // if(field == 'ledger-document'){
          //   this.getBuyerData(this.laneTaskIDDetail.lane.id);

          // }

        })
      }
    } else {
      this.preSalesService.deletePreDoc(id).subscribe(res =>{
          if(res['status'] == 200){
            this.toasterService.success("Document Deleted Successfully")
            this.fetchPreQualifiedDocList();
          }
      })
    }
  }

  deleteSelectedTags(tag) {
    let selectedTagId: string
    const index = this.allSelectedTagList.indexOf(tag);
    if (index >= 0) {
      this.allSelectedTagList.splice(index, 1);
    }

    for (let i = 0; i < this.allTags.length; i++) {
      if (tag == this.allTags[i].tagName) {
        selectedTagId = this.allTags[i].id
      }
    }
    this._kService.removeAssignedtags(selectedTagId, this.laneTaskIDDetail.id).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneTaskHistory()

      }
    })
  }
  deleteAssignList = [];

  deleteSelectedAssignee(AssignName) {
    if (this.selectedUserListOptions.includes(AssignName)) {
      for (let i = 0; i < this.options.length; i++) {
        if (AssignName == this.options[i].userName) {
          this.deleteAssignList.push(this.options[i].userId);
          const index = this.selectedUserListOptions.indexOf(AssignName);
          if (index >= 0) {
            this.selectedUserListOptions.splice(index, 1);
          }
        }
      }
    }
    let obj = {
      laneTaskId: this.laneTaskIDDetail.id,
      assigneeIds: this.deleteAssignList
    }
    this._kService.removeAssignee(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneTaskHistory()

      }
    })
  }

  deleteSelectedWatcher(watcherName) {
    if (this.fruits.includes(watcherName)) {
      for (let i = 0; i < this.options.length; i++) {
        if (watcherName == this.options[i].userName) {
          this.deleteAssignList.push(this.options[i].userId);
          const index = this.fruits.indexOf(watcherName);
          if (index >= 0) {
            this.fruits.splice(index, 1);
          }
        }
      }
    }
    let obj = {
      laneTaskId: this.laneTaskIDDetail.id,
      watcherIds: this.deleteAssignList
    }
    this._kService.removeWatchers(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.getLaneTaskHistory()

      }
    })
  }

  commentPageNumber: number = 0;
  totalComments: number;
  getTaskComment(save?, load?) {
    if (save) {
      this.commentPageNumber = 0
    }
    if (load) {
      this.commentPageNumber = this.commentPageNumber + 1;
    }
    let reqObj = {
      "from": this.commentPageNumber,
      "size": 10,
      "where": { "laneTask.$id": { "type": "search", "value": this.laneTaskIDDetail.id } }
    }
    this._kService.getTaskComment(reqObj).subscribe(res => {
      this.totalComments = res['total'];
      // if (!save) {
      //   this.commentPageNumber = this.commentPageNumber + 10;
      // }
      if (this.commentPageNumber != 0) {
        this.commentlist = this.commentlist.concat(res['result']);
      }
      else {
        this.commentlist = res['result'];
      }

    })
  }
  commentUserName: string
  getCommentUserName(name) {
    if (name != null) {
      let userName = name['byUser']['name'];
      if (userName != null && userName != '') {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length)
        }
        this.commentUserName = matches.join('').toUpperCase()
        return this.commentUserName;
      }
    }
  }

  setLoggedUser() {
    if (this.currentUser != null) {
      if (this.currentUser != null && this.currentUser != '') {
        var matches = this.currentUser.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length)
        }
        this.commentUserName = matches.join('').toUpperCase()
        return this.commentUserName;
      }
    }

  }
  getHistoryUserName(name) {
    if (name != null) {
      let userName = name['user']['name'];
      if (userName != null && userName != '') {
        var matches = userName.match(/\b(\w)/g);
        if (matches.length > 2) {
          matches.splice(2, matches.length)
        }
        this.commentUserName = matches.join('').toUpperCase()
        return this.commentUserName;
      }
    }

  }

  saveTaskComment() {
    let obj = {
      "commentId": "string",
      "laneTaskId": this.laneTaskIDDetail.id,
      "message": this.addTaskComment
    }
    this._kService.addTaskComment(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.addTaskComment = ''
        this.getTaskComment("save");
      }

    })
  }
  cancelComment() {
    this.addTaskComment = ''
  }
  fetchUploadedTaskDocuments() {
    let obj = {
      "where": {
        "laneTask.$id": {
          "type": "search",
          "value": this.laneTaskIDDetail.id
        },
        "source": {
          "type": "search",
          "value": "LANETASK"
        },
        "isDeleted": {
          "type": "search",
          "value": false
        }
      }
    }
    this._kService.searchUploadedTaskDocuments(obj).subscribe(res => {
      this.uploadedDocumentList = res['result'];

    })
  }



  RPADocumentList = [];
  fetchRPADocument() {
    let obj = {
      "where": {
        "laneTask.$id": {
          "type": "search",
          "value": this.laneTaskIDDetail.id
        },
        "source": {
          "type": "search",
          "value": "RPA"
        },
        "isDeleted": {
          "type": "search",
          "value": false
        }
      }
    }
    this._kService.searchUploadedTaskDocuments(obj).subscribe(res => {
      this.RPADocumentList = res['result'];

    })
  }

  getCountryList() {
    this._kService.getCountryList().subscribe(res => {
      if (res['result'] != null && res['result'].length > 0) {
        this.countryList = res['result'];
      }
    })
  }

  buyerDataCountryList = [];
  fetchCountryList() {
    this._kService.CountryList().subscribe(res => {
      this.buyerDataCountryList = res['result'];
    })
  }
  getSelectedCountry(event, value) {
    console.log(event, 'valuee----', value);
  }
  buyerRegionList = [];
  getmultipleBuyerRegion(event) {
    this.buyerRegionList = event
  }

  deleteBuyerRegion(region, i) {
    this.buyerRegionList = this.buyerRegionList.filter(e => e !== region);
    this.laneArrayKeys = this.laneArrayKeys.map(ele => {
      if (ele.keys == 'new-leads' || ele.keys == 'account-created-sales') {
        for (let i = 0; i < ele.value.length; i++) {
          if (ele.value[i].field == "buyer_region") {
            if (ele.value[i]['dataList'] != null && ele.value[i]['dataList'].length != 0) {
              ele.value[i]['dataList'] = this.buyerRegionList
            }
          }

        }
      }
      return ele

    })
  }

  removeSelectedRegion(i) {
    this.buyerRegionList.splice(i)
  }

  changeConfigDate(event, value) {
    console.log(event, 'value---', value)
  }
  changePlantVisit(event, value) {

  }

  // formatPaymentType(value) {
  //   return this._.startCase(value);
  // }
  onConfigCheckboxChange(isChekboxCheckd) {
    console.log(isChekboxCheckd);

  }
  resultDownload;
  downloadSingleDocument(id) {
    if(!this.data.preQualified){
      this._kService.downloadSingleUploadDocument(id).subscribe(res => {
        this.downloadSheetCommonMethod(res);
      })
    } else {
      this.preSalesService.downloadPreDoc(id).subscribe(res =>{
        this.downloadSheetCommonMethod(res);
      })
    }
  }
  downloadDocumentsInZip() {
    this._kService.downloadZipDocumentsByLaneId(this.laneTaskIDDetail.id).subscribe(res => {
      this.downloadSheetCommonMethod(res);
    })
  }

  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }

  getOwnerShipList = [];
  getCurrencyList = [];
  getIndustries = [];
  getStates = [];
  getcountryList = [];
  getEximBuyerIndustry = [];
  getInsuranceList = [];
  getColdReasonList = [];
  getDropdownData() {
    this._kService.getDropdowndata().subscribe(res => {
      this.getcountryList = res['countryList'];
      this.getCurrencyList = res['currencies'];
      this.getIndustries = res['industries'];
      this.getStates = res['states_india'];
      this.getInsuranceList = res['insurance'];
      this.getOwnerShipList = res['type_of_ownership'];
      this.getColdReasonList = res['cold-lead-reasons'];
      this.getOwnerShipList.push('Others');
      this.getEximBuyerIndustry = res['buyer_industries'];
      this.getEximBuyerIndustry.push('Others');
    })
  }

  storeUploadedBuyerData = []
  getUploadedFileData(datatype, laneId?) {
    if (datatype == 'config') {
      this.getBuyerData(laneId)
    }
  }

  getBuyerData(laneId) {
    this._kService.getBuyerData(this.laneTaskIDDetail.id, laneId).subscribe(res => {
      if (res['status'] == 200) {
        this.fileUploadData = res['result'];
        this.fileUploadData = this.fileUploadData.map(val => {
          if (val['insuranceInfo'] == null) {
            val['insuranceInfo'] = []
          }
          if (val['currencyOfInvoice'] != null || val['natureOfBusiness'] != null) {
            val['currencyOfInvoice'] = val['currencyOfInvoice'].toUpperCase();
            val['natureOfBusiness'] = (val['natureOfBusiness'] ? val['natureOfBusiness'].toUpperCase() : '')
          }
          if (this.laneTaskIDDetail.lane.board.name == 'exim') {
            this.getEximBuyerIndustry.map(ele => {
              if (val['buyerIndustry'] != ele) {
                this.eximBuyerInd = val['buyerIndustry']
                val['buyerIndustry'] = 'Others'
              }
            })

          }
          if (val)
            return val;
        })
      }
    })

  }

  insuranceInfo = [];
  addInsurance() {
    let obj = {
      'insuranceProvider': '',
      'insuredAmount': ''
    }
    for (let p = 0; p < this.fileUploadData.length; p++) {
      if (this.fileUploadData[p]['insuranceInfo'].length >= 5) {
        alert("Insurance can't add more than 5")
        return
      }
    }
    for (let i = 0; i < this.fileUploadData.length; i++) {
      let clone = JSON.parse(JSON.stringify(obj));
      if (this.fileUploadData[i]['insuranceInfo'].length > 5) {
        alert("please")
      }
      else {
        this.fileUploadData[i]['insuranceInfo'].push(clone);
      }
    }
  }

  deleteInsuranceDetails(index) {
    for (let i = 0; i < this.fileUploadData.length; i++) {
      this.fileUploadData[i]['insuranceInfo'].splice(index, 1)
    }

  }
  getDynamicDropdownList(fieldName) {
    if (fieldName == 'monthly_shipments_currency') {
      return this.getCurrencyList
    }
    else if (fieldName == 'type_of_ownership' || fieldName == 'type_of_company' || fieldName == 'constitution') {
      return this.getOwnerShipList;
    }
    else if (fieldName == 'type_of_supplier') {
      return this.supplierList;
    }
    else if (fieldName == 'plant_visit_done') {
      return this.plantVisit;
    }
    else if (fieldName == 'credit_memo') {
      return this.creditMemo;
    }
    else if (fieldName == 'seller_industry' || fieldName == 'buyer_industry') {
      return this.getIndustries;
    }
    else if (fieldName == 'country') {
      return this.getcountryList;
    }

  }

  getMultipleSelectionList(fieldName) {
    if (fieldName == 'buyer_region') {
      return this.countryList
    }
    else if (fieldName == 'reason_to_move') {
      return this.getColdReasonList;
    }
  }
  getCardDetailsTabChange(event) {
    if (event.index == 1) {
      this.getLaneTaskHistory()
    }
    else if (event.index == 0) {
      this.getTaskComment()
    }
  }
  fetchPreQualifiedDocList(){
    this.preSalesService.getDocList(this.data.laneTask.id).subscribe(res =>{
      this.uploadedDocumentList = res['result'];
    })
  }


}
