<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap">
      <div class="lft">
        <button class="filter back" mat-button (click)="navigateToSupplierPage()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <!-- <div class="rht" *ngIf="authService.isCredlixUser()">
        <div class="cat-1 grouped reqbtn dropdown" *ngIf="anchorDisplayName == 'MSAF'">
          <button mat-raised-button (click)="viewAccountStatement()"> ViewAccount Statement</button>
        </div>
        <div class="cat-1 grouped reqbtn dropdown">
          <button mat-raised-button (click)="viewInvoiceDetails()" [disabled]="isApproveButtonEnable ==false"> View
            Summary</button>
        </div>
      </div> -->
    </section>
    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">
      <div class="breadcrumb">
        <i class="ri-speed-fill first"></i>
        <span (click)="breadcrumNavigation()">Borrowers</span>
        <div style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
          <span (click)="navigateToSupplierPage()" *ngIf="businessName !=undefined"
            class="hyperlinktxt">{{businessName}} ({{setbuyerID}})
          </span>
          <span *ngIf="businessName ==undefined">No Invoices Found for this buyer -({{setbuyerID}})
          </span>
        </div>
        <div style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
          <span *ngIf="businessName !=undefined" class="hyperlinktxt">Account Statement
          </span>
        </div>
      </div>
    </div>

    <div class="mat_table">
      <table class="table_invoice suppliertable" mat-table matSortDisableClear [dataSource]="dataSource"
        style="width: 180%">
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
          <th mat-header-cell *matHeaderCellDef style="width: 200px;"> {{disCol.key}} </th>
          <td [title]="(disCol.field == 'amount' || disCol.field == 'netPayable' || disCol.field == 'disbursementAmount'
          || disCol.field == 'acureInterest' || disCol.field == 'balance' || disCol.field == 'totalDue'
          || disCol.field == 'interestCharged') ? getTitle(element[disCol.field]) : ''" mat-cell *matCellDef="let element">
            <div>
              {{ (disCol.cell(element) || disCol.cell(element) == 0) ? disCol.cell(element) : '-' }}
            </div>
          </td>
        </ng-container>
        <!-- Header row 1st group -->
        <ng-container matColumnDef="header-row-first-group">
          <th style="background-color: #E0E0E0; text-align: center;" mat-header-cell *matHeaderCellDef
            [attr.colspan]="2">Invoice#</th>
        </ng-container>

        <!-- Header row 2nd group -->
        <ng-container matColumnDef="header-row-second-group">
          <th style="background-color: #D4D0F0; text-align: center;" mat-header-cell *matHeaderCellDef
            [attr.colspan]="4"> Invoice Details </th>
        </ng-container>

        <!-- Header row 3rd group -->
        <ng-container matColumnDef="header-row-third-group">
          <th style="background-color: #D0F0D6; text-align: center;" mat-header-cell *matHeaderCellDef
            [attr.colspan]="3"> Disbursement Details </th>
        </ng-container>

        <!-- Header row 4th group -->
        <ng-container matColumnDef="header-row-fourth-group">
          <th style="background-color: #F0D9D0; text-align: center;" mat-header-cell *matHeaderCellDef
            [attr.colspan]="9"> Repayment Details </th>
        </ng-container>

        <tr style="height: 30px !important;" mat-header-row
          *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group','header-row-fourth-group']"></tr>
        <tr mat-header-row *matHeaderRowDef="headerDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: headerDisplayColumns"></tr>
      </table>
    </div>
    <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
      [pageSize]="pageSize" showFirstLastButtons (page)="getAccountSummary()">
    </mat-paginator>

  </div>
</div>