<div class="dash-layout" fxLayout="column" fxFlexFill>
  <div class="scrlbl-area">
    <section class="ctaStripWrap" *ngIf="isAnchor || authService.isCredlixUser() || isBuyer || isLead">
      <div class="lft" *ngIf="!isBuyer">
        <button class="filter back" mat-button (click)="navigateToSupplierPage()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="rht" *ngIf="authService.isCredlixUser()">
        <div class="cat-1 grouped reqbtn dropdown" *ngIf="anchorDisplayName == 'MSAF' && !searchTextedId">
          <button mat-raised-button (click)="viewAccountStatement()"> View Account Statement</button>
        </div>
        <div class="cat-1 grouped reqbtn dropdown">
          <button mat-raised-button (click)="viewInvoiceDetails()" [disabled]="isApproveButtonEnable ==false"> View
            Summary</button>
        </div>
      </div>
      <div class="rht" *ngIf="isBuyer">
        <div class="cat-1 grouped reqbtn dropdown btnallign">
          <button mat-raised-button (click)="requestFinance()" [disabled]="isApproveButtonEnable ==false"> Request
            Finance</button>
        </div>
      </div>
      <div class="rht" *ngIf="isLead">
        <div class="cat-1 grouped reqbtn dropdown">
          <button mat-raised-button (click)="markDisbursed()" [disabled]="isDisbursedBtnEnable ==false"> Mark
            Disbursed</button>
        </div>
      </div>
    </section>
    <div class="topheadr pgheader" fxLayout="row" flexLayoutAlign="center center">
      <div *ngIf="searchTextedId">
        Search Results
      </div>
      <div class="breadcrumb" *ngIf="(isAnchor || authService.isCredlixUser() || isLead) 
      && !searchTextedId">
        <i class="ri-speed-fill first"></i>
        <span (click)="breadcrumNavigation()" class="hyperlinktxt">Borrowers</span>
        <div class="chek" style="display: contents;">
          <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
          <span *ngIf="businessName !=undefined" class="hyperlinktxt">{{businessName}} ({{setbuyerID}})
          </span>
          <span *ngIf="businessName ==undefined">No Invoices Found for this buyer -({{setbuyerID}})
          </span>
        </div>
      </div>
    </div>

    <div class="mat_table">
      <table mat-table matSortDisableClear [dataSource]="dataSource" matSort style="width: 100%;padding-left: 2%;"
        [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef style="width: 60px;">
            <mat-checkbox (change)="checkAll($event)" [checked]="selection.hasValue() && isAllSelected()"
              [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="checkSingle($event, row)" [checked]="selection.isSelected(row)" 
            [aria-label]="checkboxLabel(row)" *ngIf="!isLead? row.status=='OPEN' : row.status=='REQUESTED'">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}"
          [sticky]="getStatus(disCol.field)">
          <th mat-header-cell *matHeaderCellDef style="width: 200px;"> {{disCol.key}} </th>
          <td [title]="disCol.field == 'amount' ? element[disCol.field] : ''" mat-cell *matCellDef="let element">
            <div [ngClass]="getClass(disCol.field, element)">
              {{ disCol.cell(element) ? disCol.cell(element) : '-' }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="image" sticky>
          <th mat-header-cell *matHeaderCellDef style="width: 60px;"> </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="outline" (click)="openRequestFinanceDialog(element)">
              help_outline</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headerDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: headerDisplayColumns"></tr>
      </table>
    </div>
    <div class="noRecords" *ngIf="dataSource.data.length == 0">No records found</div>
    <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
      [pageSize]="pageSize" showFirstLastButtons (page)="getPaginatorData()">
    </mat-paginator>

  </div>
</div>