<div fxFlexFill>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="space-between stretch" class="dash-layout">
    <div class="top" fxLayout="row" fxFlex="8" flexLayoutAlign="center center">
      <div class="search searchBoxtop" fxFlex="20" fxLayout="row">
        <input type="text" fxFlex placeholder="Search Doc ID" [(ngModel)]="searchText" (keyup.enter)="searchDocuments()"
          (keydown.space)="$event.preventDefault();" (keyup)="onSearchChange(searchText)">
        <button class="searchbtn">
          <i class="ri-search-line" (click)="applyFilters()" *ngIf="searchText.trim() == ''"></i>
          <i class="ri-close-line" (click)="resetSearch()" *ngIf="searchText.trim() != ''"></i>
        </button>
      </div>

    </div>
    <div class="dash-content" fxFlex="90" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between" fxLayoutGap="12px">
        <div fxLayout="column" class="left-side" fxFlex="18" fxLayoutGap="5px">
          <mat-accordion class="mataccWrap">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Document Type
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" (change)="changeDocumentType()"
                  [(ngModel)]="selectedTab">
                  <mat-radio-button color="primary" value="invoice">Invoice</mat-radio-button>
                  <!-- <mat-radio-button color="primary" value="purchase_order">Purchase order</mat-radio-button> -->
                </mat-radio-group>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion class="mataccWrap" *ngIf="selectedTab =='invoice'">
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Due Date
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" id="dueDate"
                  (change)="addToCustomDateQuery('dueDate', customDueDateOption, 'customDueDateOption')"
                  [(ngModel)]="customDueDateOption">
                  <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
                  <mat-radio-button color="primary" value="30">Last 30Days</mat-radio-button>
                  <mat-radio-button color="primary" value="90">Last 90Days</mat-radio-button>
                  <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="datebox" *ngIf="customDueDateOption ==-1">
                <div class="dateboxinput">

                  <input type="text" ngxDaterangepickerMd class="form-control" [linkedCalendars]="true"
                    [(ngModel)]="selectedDueDate"
                    (datesUpdated)="addToCustomDateQuery('dueDate',$event, 'customDueDateOption')"
                    [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range" readonly>
                </div>
                <!-- <span (click)="clearDueRange()"><i class="ri-close-line"></i></span> -->

              </div>


            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="mataccWrap">
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  Issue Date
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div classs="chk_type">
                <mat-radio-group aria-label="Select an option" id="issueDate"
                  (change)="addToCustomDateQuery('purchaseDate', customDateOption, 'customDateOption')"
                  [(ngModel)]="customDateOption">
                  <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
                  <mat-radio-button color="primary" value="30">Last 30Days</mat-radio-button>
                  <mat-radio-button color="primary" value="90">Last 90Days</mat-radio-button>
                  <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="datebox" *ngIf="customDateOption ==-1">
                <div class="dateboxinput">
                  <input type="text" ngxDaterangepickerMd class="form-control" [linkedCalendars]="true"
                    [(ngModel)]="selectedIssueDate"
                    (datesUpdated)="addToCustomDateQuery(selectedTab == 'purchase_order'? 'purchaseDate' : 'invoiceDate' ,$event, 'customDateOption')"
                    [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range" readonly>
                </div>
              </div>

            </mat-expansion-panel>
          </mat-accordion>
          <div class="leftfilteraction">
            <button mat-flat-button #button class="appbtn" [disabled]="isDisabled()"
              (click)="applyFilters()">Apply</button>
            <button mat-flat-button #button class="resetbtn" (click)="resetFilters()">Reset</button>
          </div>
        </div>

        <div class="mat_table" fxFlex="82">

          <table mat-table matSortDisableClear [dataSource]="selectedDataSource['documentsSubject']" matSort
            class="mat-elevation-z8 table_invoice" [matSortActive]="sort['active']"
            [matSortDirection]="sort['direction']"
            [hidden]="!(selectedDataSource['total'] > 0 && selectedDataSource['loading']['source']['value'] == false)">

            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef>
                <!-- <div class="chk_type">
                  <mat-checkbox></mat-checkbox>
                </div> -->
              </th>
              <td mat-cell *matCellDef="let element"><img *ngIf="selectedTab=='invoice'"
                  src="/assets/images/inv-icon.svg"><img *ngIf="selectedTab=='purchase_order'"
                  src="/assets/images/po-icon.svg"> </td>
            </ng-container>

            <ng-container matColumnDef="documentType">
              <th mat-header-cell *matHeaderCellDef> Type </th>
              <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceNumber" *ngIf="selectedTab=='invoice'">
              <th mat-header-cell *matHeaderCellDef> Doc ID </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext" title="{{element.searchKey}}"
                  (click)=getRecord(element,selectedTab)>{{element.invoiceNumber}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="searchKey" *ngIf="selectedTab=='purchase_order'">
              <th mat-header-cell *matHeaderCellDef> Doc ID </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext" title="{{element.searchKey}}"
                  (click)=getRecord(element,selectedTab)>{{element.searchKey}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="status" *ngIf="selectedTab=='purchase_order'">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <span class="status open">OPEN</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="status" *ngIf="selectedTab=='invoice'">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <span class="status early_status3" *ngIf="element.status=='PARTIALLY_PAID'">Partially Settled</span>
                <span class="status open" *ngIf="element.status=='OPEN'">Open</span>
                <span class="status early_status3" *ngIf="element.status=='PAID'">Fully Settled</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="supplier_account_name">
              <th mat-header-cell *matHeaderCellDef> Sending Party </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext">{{element.supplier_account_name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="customer_account_name">
              <th mat-header-cell *matHeaderCellDef> Receiving Party </th>
              <td mat-cell *matCellDef="let element"> <span class="overtext">{{element.customer_account_name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Amount</th>
              <td mat-cell *matCellDef="let element"> {{element.amount | currency:'INR':true}} </td>
            </ng-container>
            <ng-container matColumnDef="invoiceDate" *ngIf="selectedTab=='invoice'">
              <th mat-header-cell *matHeaderCellDef (click)=sortData(sort) mat-sort-header> Issue Date </th>
              <td mat-cell *matCellDef="let element"> {{element.invoiceDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="purchaseDate" *ngIf="selectedTab=='purchase_order'">
              <th mat-header-cell *matHeaderCellDef (click)=sortData(sort) mat-sort-header> Issue Date </th>
              <td mat-cell *matCellDef="let element"> {{element.purchaseDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="dueDate" *ngIf="selectedTab=='invoice'">
              <th mat-header-cell *matHeaderCellDef (click)=sortData(sort) mat-sort-header> Due Date </th>
              <td mat-cell *matCellDef="let element"> {{element.dueDate | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="epEligible" *ngIf="selectedTab=='invoice' && customerLogin ==true">
              <th mat-header-cell *matHeaderCellDef> EP Eligible </th>
              <td mat-cell *matCellDef="let element">
                <span matTooltip="Mark as Not Eligile"
                  *ngIf="element.isEligibleForEP && isEligible(element) && element.supplierEligible== true">
                  <mat-icon class="outline eligible" (click)=markInvoiceEPStatus(element)>done</mat-icon>
                </span>
                <span matTooltip="Mark as Eligible"
                  *ngIf="!element.isEligibleForEP && isEligible(element) && element.supplierEligible== true">
                  <mat-icon class="outline noteligible" (click)=markInvoiceEPStatus(element)>done</mat-icon>
                </span>
                <span matTooltip="Due Date Expired" *ngIf="!isEligible(element) && element.supplierEligible== true">
                  <mat-icon class="outline">done</mat-icon>
                </span>
                <span matTooltip="Supplier not Active" *ngIf="element.supplierEligible== false">
                  <mat-icon class="outline">done</mat-icon>
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="selectedDataSource['COLUMNS']"></tr>
            <tr mat-row *matRowDef="let row; columns: selectedDataSource['COLUMNS'];"></tr>
          </table>
          <div class="mat_table"
            *ngIf="(selectedTab=='invoice') && (selectedDataSource['total']==0 && selectedDataSource['loading']['source']['value'] == false)"
            style="text-align:center"><span>NO RECORDS
              FOUND</span></div>
          <div class="mat_table"
            *ngIf="(selectedTab=='purchase_order') &&(selectedDataSource['total']==0 && selectedDataSource['loading']['source']['value'] == false)"
            style="text-align:center"><span>NO RECORDS
              FOUND</span></div>
          <mat-paginator [hidden]="selectedDataSource['total'] < 1" [length]="selectedDataSource['total']"
            [pageIndex]="paginator.pageIndex"
            [pageSize]="(paginator != null &&  paginator.pageSize != null) ? paginator.pageSize : 25"
            [pageSizeOptions]="[25,50,100]" (page)="getPage()" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>