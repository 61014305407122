<div class="otp-wrapper" *ngIf="!showThankYou">
    <div class="card-body signin-button-section h-100 d-flex align-items-center justify-content-center">
      <div class="text-start w-100">
          <h5 class="card-title">Enter 6 digit OTP</h5>
          <div class="d-flex justify-content-between">
              <p class="card-text">OTP sent on your email for verification.</p>
              
          </div>
          

          <div>
              <div class="digit-group">
                  <div class="digit-group otp-not-verified">
                      <input type="text" id="digit-1" maxlength="1" placeholder="-" name="digit-1"
                          (input)="onOtpInputChange(1, $event)"
                          (keydown)="onOtpKeyDown(1, $event)" />
                      <input type="text" id="digit-2" maxlength="1" placeholder="-" name="digit-2"
                          (input)="onOtpInputChange(2, $event)"
                          (keydown)="onOtpKeyDown(2, $event)" />
                      <input type="text" id="digit-3" maxlength="1" placeholder="-" name="digit-3"
                          (input)="onOtpInputChange(3, $event)"
                          (keydown)="onOtpKeyDown(3, $event)" />
                      <input type="text" id="digit-4" maxlength="1" placeholder="-" name="digit-4"
                          (input)="onOtpInputChange(4, $event)"
                          (keydown)="onOtpKeyDown(4, $event)" />
                      <input type="text" id="digit-5" maxlength="1" placeholder="-" name="digit-5"
                          (input)="onOtpInputChange(5, $event)"
                          (keydown)="onOtpKeyDown(5, $event)" />
                      <input type="text" id="digit-6" maxlength="1" placeholder="-" name="digit-6"
                          (input)="onOtpInputChange(6, $event)"
                          (keydown)="onOtpKeyDown(6, $event)" />
                      <div *ngIf="otpIncorrect" class="otp-status">{{otpRes?.message}}</div>
                  </div>
              </div>

              <div class="mb-3 d-flex justify-content-between align-items-start">
                  <div class="otp-coundown d-flex flex-column">
                      <span>Didn’t receive the code?</span>
                      <strong>Resend in 00:{{ counter < 10 ? '0' + counter : counter
                              }}</strong>
                  </div>
                  <button class="blue-button" [disabled]="counter > 0"
                      (click)="resendOtp()">RESEND OTP</button>
              </div>

              <div class="d-flex align-items-center justify-content-center">
                  <button class="btn" [ngClass]="isOtpValid ? 'continue-btn' : 'disable-continue-btn'" [disabled]="!isOtpValid" type="button" (click)="continue()"> Continue</button>
              </div>
          </div>
      </div>
  </div>
</div>



<div class="otp-wrapper" *ngIf="showThankYou">
    <div class="otp-coundown d-flex flex-column">
      <h2>Thank You</h2>
      <h4>Please close the window for security reasons.</h4>
      </div>
  </div>