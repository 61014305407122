import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { urls } from '../../url'
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }
  readonly BaseUrl = environment.CREDLIXKANBAN;

  createUserOkr(obj) {
    let url = this.BaseUrl + urls.createOKR
    return this.http.post(url, obj);
  }

  updateOKR(obj) {
    let url = this.BaseUrl + urls.updateOKRGoal
    return this.http.put(url, obj);
  }
  getAllOkrsList(obj){
    let url = this.BaseUrl + urls.getAllOKR
    return this.http.post(url, obj);
  }
  getOKRGoals(){
    let url = this.BaseUrl + urls.OkRGoals + '?category=' + "LOS" + '&subCategory=' + "EXIM"
    return this.http.get(url);
  }
  deleteOkRMember(id, obj){
    let url = this.BaseUrl + urls.deleteOKRMember + '?okrId=' + id
    return this.http.post(url, obj);
  }
}
