import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PresaleService } from 'src/app/component/pre-sales/presale.service';

@Component({
  selector: 'app-whatsapp-template',
  templateUrl: './whatsapp-template.component.html',
  styleUrls: ['./whatsapp-template.component.scss']
})
export class WhatsappTemplateComponent implements OnInit {

  templateList: any = []
  whatsAppMessage: string;
  isMessageShow: boolean = false;
  selectedIndex: any;
  selectedTemplate: any;
  primary : boolean;
  templateType : string = 'WhatsApp';
  allTemplateList: any = [];
  constructor(public preSaleService: PresaleService, public tostrService : ToastrService) { }

  ngOnInit() {
    this.getTemplate('WhatsApp')
  }

  getTemplate(val) {
    this.templateList = [];
    this.preSaleService.getWhatsAppTemplate().subscribe(res => {
      this.allTemplateList = res['result'];
    if(val == 'WhatsApp'){
      this.allTemplateList.forEach(val =>{
        if(val.whatsAppTemplate == true){
          this.templateList.push(val);
        }
      })
    } else {
      this.allTemplateList.forEach(val =>{
        if(val.whatsAppTemplate == false){
          this.templateList.push(val);
        }
      })
    }
    })
  }
  openMessage(element, index) {
    this.isMessageShow = true;
    this.selectedIndex = index;
    this.selectedTemplate = element;
    this.whatsAppMessage = element.message;
    this.primary = element.default;
  }
  deleteTemplate(element,index) {
    if(element.id != null){
      let obj = {
        templateId : element.id
      }
      this.preSaleService.deleteTemplate(obj).subscribe(res =>{
        this.tostrService.success("Template Deleted Successfully");
        this.templateList.pop(index)
      })
    } else {
        this.tostrService.success("Template Deleted Successfully")
        this.templateList.pop(index)
    }
  }
  saveTemplate() {
    let obj = {
      'message': this.whatsAppMessage,
      'id': this.selectedTemplate.id,
      'isDefault' : this.primary,
      'isWhatsAppTemplate' : this.templateType == 'WhatsApp' ? true : false
    }
    this.preSaleService.updateTemplate(obj).subscribe(res => {
      if(res['status'] =  200){
        this.backToList(this.templateType);
      }
    });
  }
  backToList(val) {
    this.isMessageShow = false;
    this.whatsAppMessage = null;
    this.selectedIndex = null;
    this.selectedTemplate = null;
    this.primary = null;
    this.getTemplate(val);
  }
  addTemplate() {
    let obj = {
      default: false,
      id: null,
      message: "Type Your Message Here",
      userId: null
    }
    this.templateList.push(obj);
  }
  copyMessage(inputElement) {
    this.tostrService.success("Message Copied")
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = inputElement;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  changeTemplate(value){
    this.templateList = []
    if(value == 'WhatsApp'){
      this.allTemplateList.forEach(val =>{
        if(val.whatsAppTemplate == true){
          this.templateList.push(val);
        }
      })
    } else {
      this.allTemplateList.forEach(val =>{
        if(val.whatsAppTemplate == false){
          this.templateList.push(val);
        }
      })
    }
  }

}
