import { Injectable } from '@angular/core';
import { params } from '../param';
import { urls } from '../url';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  private subject = new BehaviorSubject({});
  public subjectBehav = this.subject.asObservable();
  private subjectPO = new BehaviorSubject({});
  public subjectBehavPO = this.subjectPO.asObservable();

  private globalFilterBeh = new BehaviorSubject({});
  public $globalFilterBeh = this.globalFilterBeh.asObservable();

  earlyPaymntBehv: BehaviorSubject<any> = new BehaviorSubject({});
  supplierBeh: BehaviorSubject<any> = new BehaviorSubject({});
  readonly BaseUrl = environment.BASEURL;
  readonly OnBoard = environment.ONBOARDDATA;
  readonly kanbanBaseUrl = environment.CREDLIXKANBAN;
  readonly anchorBaseUrl = environment.CREDLIXANCHOR;
  readonly REPORT_URL = environment.CREDLIX_REPORT_URL;
  // readonly eximURL = environment.EXIMAUTOSALES;
  //readonly credlixSID = environment.credlixSID;
  readonly UploadMappingURL = environment.UploadMappingURL;

  private anchorList = new Subject();
  public $anchorList = this.anchorList.asObservable();

  private isChannelFinancing = new Subject();
  public $isChannelFinancing = this.isChannelFinancing.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) { }
  isSubmitted: boolean = false;
  isDisable: boolean = false;

  isLoading = new Subject<boolean>();
  isLoading$ = this.isLoading.asObservable();

  //added for changing sidebar icon colours
  changeColor = new BehaviorSubject<string>('');
  changeColor$ = this.changeColor.asObservable();

  //added for changing manual-upload-list icon colours
  changeManualColor = new BehaviorSubject<string>('');
  changeManualColor$ = this.changeManualColor.asObservable();

  show() {
    this.isLoading.next(true);
  }
  hide() {
    this.isLoading.next(false);
  }
  offSpinner() {
    const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
    const curtain = <HTMLDivElement>document.getElementById('curtain');
    spinner.style.display = 'none';
    curtain.style.display = 'none';
  }

  refreshInvoiceValidation(invoiceId: string) {
    return this.http.get(`${this.BaseUrl}invoice/sync-invoice-validation?invoiceId=${invoiceId}`)
  }

  uploadEpBucketing(obj) {
    const url = this.REPORT_URL + urls.epBucketUploadCsv;
    return this.http.post(url, obj, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    });
  }
  getAccountDetails() {
    let url = this.BaseUrl + urls.accountInfo
    return this.http.get(url);
  }
  getAnchorFinancingListData() {
    let url = this.anchorBaseUrl + urls.fetchAnchors + "?pageNo=0&pageSize=5";
    return this.http.get(url);
  }
  getPurchaseOrders(obj) {
    let url = this.BaseUrl + urls.getPurchaseOrders
    return this.http.post(url, obj);
  }
  getSuppliersInvoices(obj) {
    let url = this.BaseUrl;
    if (obj.isCustomer == true) {
      url = url + urls.getCustomerInvoice;
    } else {
      url = url + urls.getSuppliersInvoice;
    }
    if (this.authService.isCredlixUser()) {
      obj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    delete obj.isCustomer;
    return this.http.post(url, obj);
  }
  getDiscountDebitNotes(obj) {
    let url = this.BaseUrl + urls.searchDiscountDebitNotes
    return this.http.post(url, obj);
  }
  downloadDiscountDebitNotes(obj) {
    let url = this.BaseUrl + urls.downloadDiscountDebitNotes

    return this.http.post(url, obj, { responseType: 'text' });
  }
  getTransactionHistory(obj) {
    let url = this.BaseUrl + urls.getTransactionHistory + '?utr=' + obj;
    return this.http.get(url);
  }
  getEarlyPaymentInvoice(obj) {
    let url = this.BaseUrl + urls.getEarlyPaymentInvoices
    return this.http.post(url, obj);
  }
  downloadInvoiceResults(obj) {
    let url = this.BaseUrl + urls.downloadEPInvoiceResults
    return this.http.post(url, obj, { responseType: 'text' });
  }

  downloadCustomerInvoices(obj) {
    let url = this.BaseUrl + urls.downloadCustomerInvoices;
    if (localStorage.getItem('credlixUserID')) {
      obj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    return this.http.post(url, obj, { responseType: 'text' });
  }
  downloadSupplierInvoices(obj) {
    let url = this.BaseUrl + urls.downloadSupplierInvoices
    return this.http.post(url, obj, { responseType: 'text' });
  }

  createEarlyPayment(obj) {
    let url = this.BaseUrl + urls.createEarlyPaymentRequest
    return this.http.post(url, obj);
  }

  downloadPaymentSchedule(obj) {
    let url = this.BaseUrl + urls.downloadPaymentSchedule
    return this.http.post(url, obj, { responseType: 'text' });
  }

  downloadEscrowDocs(obj) {
    let url = this.BaseUrl + urls.DownlaodEscrowPaymentDocs + obj
    return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
  }

  setinvoiceRecords(row) {
    this.subject.next(row)
  }

  setPurchaseRecords(row) {
    this.subjectPO.next(row)
  }

  getSearchEPResults(row) {
    this.earlyPaymntBehv.next(row);
  }
  storedSupplierResult(row) {
    this.supplierBeh.next(row);
  }


  getDocumentListByURl(obj) {
    let url = this.BaseUrl + urls.getDocumentListByURl + obj.id
    return this.http.get(url);
  }

  getPoDocumentListByURl(obj) {
    let url = this.BaseUrl + urls.getDoumentPoListByURL + obj.id
    return this.http.get(url);
  }

  updateInvoiceEPEligibility(obj) {
    let url = this.BaseUrl + urls.markEligibility
    return this.http.post(url, obj);
  }
  getSupplierResults(obj) {
    let url = this.BaseUrl + urls.getSupplierLinks
    return this.http.post(url, obj);
  }
  addSuppliers(obj) {
    let url = this.BaseUrl + urls.addSuppliers
    return this.http.post(url, obj);
  }
  customerMarkPaid(obj) {
    let url = this.BaseUrl + urls.customerMarkPaid
    return this.http.post(url, obj);
  }
  editSupplierDetails(obj) {
    let url = this.BaseUrl + urls.editSupplierDetails
    return this.http.post(url, obj);
  }
  editBulkSupplierDetails(obj) {
    let url = this.BaseUrl + urls.editBulkSupplierDetails
    return this.http.post(url, obj);
  }
  usedEPAmount() {
    let url = this.BaseUrl + urls.usedEPAmount
    return this.http.get(url);
  }

  autoCompleteSearch(obj) {
    let url = this.BaseUrl + urls.autoCompleteSearchResult
    return this.http.post(url, obj);
  }
  fetchFinancer(credlixUserID) {
    let url = this.BaseUrl + urls.financerList;
    if (credlixUserID) {
      url = url + "?masterAccountId=" + credlixUserID;
    }
    return this.http.get(url);
  }
  uploadBookingStatus(obj) {
    let url = this.BaseUrl + urls.bookingUploader;
    let credlixID = localStorage.getItem('credlixUserID');
    if (credlixID) {
      url = url + "?masterAccountId=" + credlixID
    }
    return this.http.post(url, obj);
  }

  debitNoteTradeDiscount() {
    let url = this.BaseUrl + urls.tradeDiscountReport
    return this.http.get(url, { responseType: 'text' });
  }
  loadReportJobs(obj) {
    let url = this.BaseUrl + urls.loadJobs + '?pageNo=' + obj.from + '&size=' + obj.size;
    if (obj['masterAccountId'])
      url = url + "&masterAccountId=" + obj['masterAccountId'];
    return this.http.get(url);
  }
  downloadFileReport(id) {
    let url = this.BaseUrl + urls.downloadfile + "/" + id;
    return this.http.get(url, { responseType: 'text' });
  }

  refreshInvoice(id) {
    let url = this.BaseUrl + urls.refreshStatus + "/" + id
    return this.http.get(url);
  }

  supplierRefreshInvoices(id, credlixuserID) {
    let url = this.BaseUrl + urls.supplierRefresh + '?supplierId=' + id;
    if (credlixuserID) {
      url = url + "&masterAccountId=" + credlixuserID;
    }
    return this.http.get(url);
  }
  reconcileSheet(obj) {
    let url = this.BaseUrl + urls.reconcileSheet
    return this.http.post(url, obj, { responseType: 'text' });
  }
  CIDMappingSheetUpload(obj) {
    let url = this.BaseUrl + urls.CIDMappingUploader
    return this.http.post(url, obj);
  }
  ledgerMappingSheetUpload(obj) {
    let url = this.BaseUrl + urls.ledgerMapping
    return this.http.post(url, obj);
  }

  lenderConfigUpdate(obj) {
    let url = this.BaseUrl + urls.lenderConfig
    let credlixID = localStorage.getItem('credlixUserID');
    if (credlixID) {
      url = url + "?masterAccountId=" + credlixID
    }
    return this.http.post(url, obj);
  }

  lenderUTRMap(obj) {
    let url = this.BaseUrl + urls.lenderUTRMap
    let credlixID = localStorage.getItem('credlixUserID');
    if (credlixID) {
      url = url + "?masterAccountId=" + credlixID
    }
    return this.http.post(url, obj);
  }

  // private _clickSubject = new BehaviorSubject('');
  // public _clickSubjectBehv = this._clickSubject.asObservable();
  _clickSubjectBehv: BehaviorSubject<any> = new BehaviorSubject('');


  getClickEvent(event) {
    this._clickSubjectBehv.next(event);
  }

  downloadABRFTemplate() {
    let url = this.BaseUrl + urls.ABRFTemplateDownload
    return this.http.get(url);
  }

  getGlobalFilterData() {
    return this.globalFilterBeh;
  }

  setGlobalFilterData(event) {
    this.globalFilterBeh.next(event);
  }

  bulkUserUploaded(obj, sendEmail) {
    let url = this.BaseUrl + urls.uploadedBulkUser + '?sendEmail=' + sendEmail
    return this.http.post(url, obj);
  }

  refreshTokenWithBusinessID(obj) {
    let url = this.BaseUrl + urls.refreshToken + '?roleId=' + obj.roleId
    return this.http.get(url);
  }

  getHeaderList(credlixUserID) {
    let url = this.BaseUrl + urls.importConfig;
    if (credlixUserID)
      url = url + "?masterAccountId=" + credlixUserID;
    return this.http.get(url);
  }

  getInvoiceEPDetails(id) {
    let url = this.BaseUrl + urls.getRequestPayment + "?invoiceId=" + id;
    return this.http.get(url);
  }
  getmultipleInvoiceEPDetails(obj) {
    let url = this.BaseUrl + urls.getRequestMultiplePayment;
    return this.http.post(url, obj);
  }

  // getBuyersDetails(pageNo, pageSize, isLead) {
  //   let url = this.BaseUrl + urls.getBuyerListing + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
  //   if (localStorage.getItem('credlixUserID')) {
  //     url = url + "&masterAccountId=" + localStorage.getItem('credlixUserID');
  //   }
  //   if(isLead){
  //     url = url + "&masterAccountId=" + "6228a749a2cee5063fd7573c";
  //   }
  //   return this.http.get(url);
  // }

  getLoanInvoiceDetails(buyerId, pageNo, pageSize, lenderID) {
    let url;
    if (!buyerId) {
      url = this.BaseUrl + urls.getBuyerInvoiceListing + "?pageNo=" + pageNo + "&pageSize=" + pageSize + "&lenderId=" + lenderID;
    }
    else {
      url = this.BaseUrl + urls.getBuyerInvoiceListing + "?buyerId=" + buyerId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&lenderId=" + lenderID;
    }
    return this.http.get(url);
  }

  getAnchorList() {
    let url = this.BaseUrl + urls.getAnchorList;
    return this.http.get(url);
  }


  setAnchorList(val) {
    this.anchorList.next(val);
  }

  getLenderList(obj) {
    let url = this.BaseUrl + urls.getSupplierLinks;
    return this.http.post(url, obj);
  }

  fetchLenderList() {
    let url = this.BaseUrl + urls.getLenderAnchorList;
    return this.http.get(url);
  }
  getRepayments(buyerId, pageNo, pageSize, lenderId) {
    let url;
    if (!buyerId) {
      url = this.BaseUrl + urls.getRepayments + "?pageNo=" + pageNo + "&pageSize=" + pageSize + "&lenderId=" + lenderId;
    }
    else {
      url = this.BaseUrl + urls.getRepayments + "?buyerId=" + buyerId + "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&lenderId=" + lenderId;
    }
    return this.http.get(url);
  }

  isChangeColor(val) {
    this.changeColor.next(val);
  }

  isChangeManualColor(val) {
    this.changeManualColor.next(val);
  }

  getLenderLeadsList(obj) {
    let url = this.BaseUrl + urls.getLeadsLists
      + "?pageNo=" + obj.from + "&pageSize=" + obj.size;
    if (this.authService.isCredlixUser()) {
      url = url + "&anchorMasterAccountId=" + obj.masterAccountId
    }
    return this.http.get(url);
  }
  getEPHistory(obj) {
    let url = this.BaseUrl + urls.EPhistory + "?invoiceId=" + obj
    return this.http.get(url);
  }

  uploadStatusUpdate(obj, pageName, anchorDisplayName, supplierId?, buyerId?, paymentType?, businessType?: string) {
    let url = this.BaseUrl;
    let boardUrl = this.OnBoard
    let sidUrl = this.BaseUrl;
    let credlixID = localStorage.getItem('credlixUserID');
    if (pageName == 'booking')
      url = url + urls.bookingUploader;
    else if (pageName == 'kanban')
      url = this.kanbanBaseUrl + urls.configDocumentUpload;
    else if (pageName == 'sid')
      url = url + urls.CIDMappingUploader;
    else if (pageName == 'abfrl')
      url = url + urls.ledgerMapping;
    else if (pageName == 'chola' || pageName == 'data') {
      if (anchorDisplayName && anchorDisplayName == "MSAF") {
        url = url + urls.lenderUTRMapMSAF;
      } else {
        url = url + urls.lenderUTRMap;
      }
    }
    else if (pageName == 'rate')
      url = url + urls.lenderConfig;
    else if (pageName == 'preSale') {
      const type = businessType && businessType == 'Exim' ? 'LEAD_FILE_FOR_EXIM' : 'LEAD_FILE_FOR_DOMESTIC';
      url = boardUrl + urls.uploadConfig + `?type=${type}`;
    } else if (pageName == 'home') {
      if (anchorDisplayName && anchorDisplayName == "MSAF") {
        url = url + urls.buyerUpload;
      }
      else {
        url = url + urls.supplierOnboard;
      }
    }
    else if (pageName == 'anchor') {
      if (anchorDisplayName && anchorDisplayName == "MSAF") {
        url = url + urls.loanInvoiceUpload;
      }
      else {
        url = url + urls.anchorInvoice;
      }
    }
    else if (pageName == 'debit')
      url = url + urls.debitNoteUpload;
    else if (pageName == 'markDelayEP')
      url = url + urls.bulkMarkDelayedEP;
    else if (pageName == 'markCancelEP')
      url = url + urls.bulkCanceledEP;
    else if (pageName == 'GRN')
      url = url + urls.GRNMapping;
    else if (pageName == 'repayment')
      url = url + urls.loanPaymentMarkPaid;
    else if (pageName == 'bulk-upload')
      url = url + urls.uploadLenderConfig;
    else if (pageName == 'disbursement')
      url = url + urls.markDisbursedUpload;
    else if (pageName == 'tasvaMapping') {
      url = url + urls.tasvaMap;
    }
    else if (pageName == 'tasvaLedger') {
      url = url + urls.tasvaLedger;
    }
    if (pageName == 'home' && !anchorDisplayName && anchorDisplayName != "MSAF") {
      url = url + "&lenderId=" + "628bb2b348bce165a7737f86";
    }
    else if (pageName == 'Buyer')
      url = url + urls.uploadBulkBuyer;
    else if (pageName == 'Supplier')
      url = sidUrl + urls.uploadBulkSupplier;
    else if (pageName == 'invoice')
      url = sidUrl + urls.createBulkInvoice;
    else if (pageName == 'sidRepayment' || pageName == 'sidDisbursal') {
      url = `${sidUrl}${urls.paymentsUpload}?anchorMasterAccountId=${credlixID}&paymentType=${paymentType}`;
    }
    if (credlixID && !paymentType && pageName != 'kanban' && pageName != 'preSale') {
      url = url + "?masterAccountId=" + credlixID;
    }
    if (anchorDisplayName && anchorDisplayName == "MSAF") {
      url = url + "&lenderId=" + "628bb2b348bce165a7737f86";
    }

    if (pageName == 'Buyer') {
      url = url + "&lenderId=6278c2d29ad2edd465cfc810";
    }
    if (pageName == 'invoice' && this.authService.isSIDUSER()) {
      url = url + `&customerId=${buyerId}&supplierId=${supplierId}`;
    } else if (pageName == 'invoice' && this.authService.isBorrower()) {
      url = url + `?customerId=${buyerId}`;
    }
    return this.http.post(url, obj);
  }

  saveSupplierBankDetails(obj) {
    let url = this.BaseUrl + urls.getSupplierBankDetails;
    return this.http.post(url, obj);
  }

  getSupplierBankDetails(id) {
    let url = this.BaseUrl + urls.getSupplierBankDetails + "?businessAccountId=" + id;
    return this.http.get(url);
  }

  editSupplierBankDetails(obj) {
    let url = this.BaseUrl + urls.getSupplierBankDetails;
    return this.http.put(url, obj);
  }

  //For lender
  getBuyerStats(id) {
    let url = this.BaseUrl + urls.getBuyerStats;
    if (id)
      url = url + "?businessAccountId=" + id;
    if (localStorage.getItem('credlixUserID')) {
      url = url + "&anchorMasterAccountId=" + localStorage.getItem('credlixUserID');
    }
    return this.http.get(url);
  }

  downloadBuyerSheet(id?) {
    let url = this.BaseUrl + urls.downloadBuyer + "?anchorMasterAccountId=" + id;
    return this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getOTPRequest(obj) {
    let url = this.BaseUrl + urls.getOTPRequest;
    return this.http.post(url, obj);
  }

  setPrimaryBankAccount(obj) {
    let url = this.BaseUrl + urls.setPrimaryBankAccount;
    return this.http.put(url, obj);
  }

  getSuppliersTransactions(obj) {
    let url = this.BaseUrl + urls.supplierTransactions;
    return this.http.post(url, obj);
  }

  downloadLedgerReport(obj) {
    let URl = this.BaseUrl + urls.ledgerReportDownload + "?reportType=" + obj.reportName
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&masterAccountId=" + localStorage.getItem('credlixUserID')
    }
    if (obj.supplierId != null) {
      URl = URl + "&supplierId=" + obj.supplierId
    }
    if (obj.startDate != undefined && obj.endDate != undefined) {
      URl = URl + "&startDate=" + obj.startDate + "&endDate=" + obj.endDate
    }
    if (obj.timePeriod != null && obj.timePeriod == -7) {
      URl = URl + "&timePeriod=" + '7'
    }
    else if (obj.timePeriod != null && obj.timePeriod == -30) {
      URl = URl + "&timePeriod=" + '30'
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  deleteBankAccount(obj) {
    let url = this.BaseUrl + urls.deleteBankAccount;
    return this.http.put(url, obj);
  }

  downloadAnchorPaymentReport(obj) {
    let URl = this.BaseUrl + urls.anchorPaymentReport + "?reportType=" + obj.reportType
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    if (obj.startDate != undefined && obj.endDate != undefined) {
      URl = URl + "&startDate=" + obj.startDate + "&endDate=" + obj.endDate
    }

    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })
  }

  downloadLenderPaymentReport(obj) {
    let URl = this.BaseUrl + urls.lenderPaymentReport + "?reportType=" + obj.reportType
    if (localStorage.getItem('credlixUserID')) {
      URl = URl + "&anchorMasterAccountId=" + localStorage.getItem('credlixUserID')
    }
    if (obj.startDate != undefined && obj.endDate != undefined) {
      URl = URl + "&startDate=" + obj.startDate + "&endDate=" + obj.endDate
    }
    return this.http.get(URl, { observe: 'response', responseType: 'blob' as 'json' })

  }
  getRolesByAccount() {
    let url = this.BaseUrl + urls.getRolesByAccount;
    return this.http.post(url, {});
  }
  getAnchorDashboard(obj) {
    let url = this.BaseUrl + urls.getAnchorDashboard;
    if (this.authService.isCredlixUser()) {
      obj['masterAccountId'] = localStorage.getItem('credlixUserID');
    }
    return this.http.post(url, obj);
  }

  getUploadMappingHeaders(obj) {
    // let formatURL= "https://apiqa.credlixlab.com/get-headers-with-records";
    let url = this.BaseUrl + urls.uploadMappingHeaders;
    return this.http.post(url, obj);
  }

  updateImportConfig(obj) {
    let url = this.BaseUrl + urls.updateImportConfig;
    if (this.authService.isCredlixUser()) {
      url = url + '?masterAccountId=' + localStorage.getItem('credlixUserID');
    }
    return this.http.post(url, obj);
  }

  cholaAddComment(obj) {
    let url = this.BaseUrl + urls.leadComment;
    return this.http.post(url, obj);
  }
  getCholaComment(id) {
    let url = this.BaseUrl + urls.getLeadComment + "/" + id + "?pageNo=0&pageSize=100"
    return this.http.get(url);
  }

  markCommentAsRead(id) {
    let url = this.BaseUrl + urls.getLeadComment + "/" + id + "/read"
    return this.http.get(url);
  }

  //loan invoice
  getLoanInvoice(obj) {
    let url = this.BaseUrl + urls.loanInvoice;
    return this.http.post(url, obj);
  }

  getInvoiceFinanceDetails(invID) {
    let url = this.BaseUrl + urls.invoiceFinanceDetails + "?invoiceId=" + invID;
    return this.http.get(url);
  }

  getMultiInvoiceFinanceDetails(invID) {
    let url = this.BaseUrl + urls.invoiceMultiFinanceDetails;
    return this.http.post(url, invID);
  }

  loanRequest(obj) {
    let url = this.BaseUrl + urls.loanRequest;
    return this.http.post(url, obj);
  }

  multiLoanRequest(obj) {
    let url = this.BaseUrl + urls.multiLoanRequest;
    return this.http.post(url, obj);
  }

  generateDrawdownPDF(obj) {
    let url = this.BaseUrl + urls.generate_drawDown_pdf;
    return this.http.post(url, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  markDisbursed(obj) {
    let url = this.BaseUrl + urls.markDisbursed;
    return this.http.post(url, obj);
  }

  markDisbursedMulti(obj) {
    let url = this.BaseUrl + urls.markDisbursedMulti;
    return this.http.post(url, obj);
  }

  getLenderDetails(buyerId) {
    let url = this.BaseUrl + urls.getLenderDetails + "?buyerId=" + buyerId;
    return this.http.get(url);
  }

  updateLenderDetails(obj) {
    let url = this.BaseUrl + urls.updateLenderDetails;
    return this.http.put(url, obj);
  }

  getLenderAccount(id) {
    let url = this.BaseUrl + urls.getLenderAccount + "?masterAccountId=" + id;
    return this.http.get(url);
  }

  getBasicDetails(id) {
    let url = this.BaseUrl + urls.getBasicDetails + `/${id}`;
    return this.http.get(url);
  }

  getAccountSummary(masterAccountId, buyerId, pageNo, pageSize) {
    let url = this.BaseUrl + urls.getAccountSummary + "?masterAccountId=" + masterAccountId +
      "&buyerId=" + buyerId + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    return this.http.get(url);
  }
  getGstReport(obj) {
    let url = this.BaseUrl + urls.getReports + '?masterAccount=' + obj.masterAccount + '&pageNo=' + obj.from + '&size=' + obj.size;
    // if (obj['masterAccountId'])
    //   url = url + "&masterAccountId=" + obj['masterAccountId'];
    return this.http.get(url);
  }
  downloadGstFile(id) {
    let url = this.BaseUrl + urls.downloadGst + "?gstin=" + id;
    return this.http.get(url, { responseType: 'blob' });
  }

  getBuyersDetails(obj) {
    let url = this.BaseUrl + urls.getBuyerGlobalSearchData;
    return this.http.post(url, obj);
  }

  setChannelFinancing(val) {
    this.isChannelFinancing.next(val);
  }

  autoCompleteSearchVedanta(obj) {
    let url = this.BaseUrl + urls.autoCompleteVedantaSearchResult
    return this.http.post(url, obj);
  }
  downloadReportFile(obj, type, id) {
    let URL;
    if (type == 'system') {
      URL = this.BaseUrl + urls.downloadMisReport + '?supplierId=' + id
    }
    else {
      URL = this.BaseUrl + urls.downloadReport;
    }
    return this.http.post(URL, obj, { observe: 'response', responseType: 'blob' as 'json' })
  }
  downloadDisbursementFile(obj) {
    let URl = this.BaseUrl + urls.downloadDisbursementFile;
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' })
  }

  downloadDomesticlosFile(obj) {
    let URl = this.kanbanBaseUrl + urls.downloadDomesticlosFile;
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }
  getSidNotification(obj) {
    let params = new HttpParams();
    params = params.append("pageNumber", obj.pageNumber);
    params = params.append("pageSize", obj.pageSize);
    let url = this.BaseUrl + urls.getNotification;
    return this.http.get(url, { params });
  }
  dismissNotification(id) {
    let url = this.BaseUrl + urls.dismissNotification + id;
    return this.http.put(url, null);
  }
  templateDownload(obj) {
    let URl = this.BaseUrl + urls.templateDownload;
    return this.http.post(URl, obj, { responseType: 'blob', observe: 'response' });
  }
  getRMList() {
    let url = this.BaseUrl + urls.getRMList;
    return this.http.get(url);
  }
  setBasicDetails(obj) {
    let url = this.BaseUrl + urls.setBasicDetails;
    return this.http.post(url, obj);
  }
  getEPComments(obj) {
    let url = this.BaseUrl + urls.getEPComments + `/${obj.id}?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}`;
    return this.http.get(url);
  }
  setEPComment(obj) {
    let url = this.BaseUrl + urls.leadComment;
    return this.http.post(url, obj);
  }
  syncDebitNotes(obj) {
    let params = new HttpParams();
    params = params.append('skip', obj.pageNo);
    params = params.append('limit', obj.pageSize);
    params = params.append('supplierId', obj.supplierId);
    let url = this.BaseUrl + urls.syncDebitNote;
    return this.http.get(url, { params });
  }
  downloadEPTrackerFile(obj) {
    let URl = this.BaseUrl + urls.getEPTracker;
    return this.http.post(URl, obj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  downloadMISFile(obj) {
    let reqParms = new HttpParams();
    reqParms = reqParms.append('fromDate', obj.fromDate);
    reqParms = reqParms.append('toDate', obj.toDate);
    let URL = this.BaseUrl + urls.getMSPReport;
    return this.http.post(URL, '', {
      params: reqParms,
      observe: 'response',
      responseType: 'blob' as 'json'
    })
  }
  updateDetails: BehaviorSubject<any> = new BehaviorSubject({});
  updateLeadDetailsData(data) {
    this.updateDetails.next(data);
  }

  getEPInvoicesList(obj) {
    let params = new HttpParams();
    params = params.append('startDate', obj.startDate);
    params = params.append('endDate', obj.endDate);
    let URL = this.BaseUrl + urls.getEPInvoicesList;
    return this.http.get(URL, { params });
  }
  markEPApproval(data) {
    let url = this.BaseUrl + urls.markEPFinance;
    return this.http.post(url, data);
  }
  syncGST(data) {
    const startDate = this.formatDateToYYYYMMDD(new Date(data.startDate));
    const endDate = this.formatDateToYYYYMMDD(new Date(data.endDate));
    let url = this.BaseUrl + urls.syncGST + '?startDate=' + startDate + '&endDate=' + endDate;
    return this.http.get(url, data);
  }
  syncPayment(data) {
    const startDate = this.formatDateToDDMMYYYY(new Date(data.startDate));
    const endDate = this.formatDateToDDMMYYYY(new Date(data.endDate));
    let url = this.BaseUrl + urls.syncPayment + '?startDate=' + startDate + '&endDate=' + endDate;
    return this.http.post(url, data);
  }

  invoicesStatus() {
    let url = this.BaseUrl + urls.invoiceDirect;
    return this.http.get(url);
  }
  vendorsStatus() {
    let url = this.BaseUrl + urls.vendorsDirect;
    return this.http.get(url);
  }
  bulkInvoicesSync(array: any) {
    let url = this.BaseUrl + urls.bulkInvoiceSync;
    const fd = new FormData();
    fd.append('ids', array);
    return this.http.post(url, fd);
  }
  refreshEpStatus(ids,searchId) {
    let url = this.BaseUrl + urls.epStatus;
    let reqParams = new HttpParams();
    if(ids){
    reqParams = reqParams.append('id', ids);}
    if(searchId){
      reqParams = reqParams.append('searchKey', searchId);
    }
    return this.http.post(url, {}, {
      params: reqParams
    })
  }
  private formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  private formatDateToDDMMYYYY(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  moveToLostCurrent: BehaviorSubject<any> = new BehaviorSubject(false);
  currentStatusLos(data) {
    this.moveToLostCurrent.next(data);
  }
  stopPositiveNegativeInput(event) {
    if (event.key == "+" || event.key == "-") {
      return false;
    }
  }

  logOutUser(){
    let url = this.BaseUrl + urls.logOutUser;
    return this.http.post(url,{});
  }
}
