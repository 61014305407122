import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import * as Highcharts from "highcharts";
import {
  funnelFuel,
  funnelFuel2,
} from "src/app/component/dashboard/dashboard/ep-graph-data";
import { FunnelCommentComponent } from "../funnel-comment/funnel-comment.component";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/data.service";
import { KanbanService } from "../../kanban.service";

@Component({
  selector: "app-funnnel-dashboard",
  templateUrl: "./funnnel-dashboard.component.html",
  styleUrls: ["./funnnel-dashboard.component.scss"],
})
export class FunnnelDashboardComponent implements OnInit, AfterViewInit {
  filter: FormGroup;
  toDate:any;
  conversionFunel: any = funnelFuel;
  conversionFunel2: any = funnelFuel2;
  rmUserList: any;
  charts: any;
  funnelData: any[] = [];
  graphData1:any[] = [];
  graphData2:any[] = [];
  catArray = [
    "Sales Initiated",
    "Insurance Applied",
    "ROI Approval",
    "TS Issued",
    "TS Signed",
    "In Underwriting",
    "RPA Signed",
    "Live",
    "Dropped",
  ];
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dataService: DataService,
    public tostrService: ToastrService,
    public kanbanService: KanbanService
  ) {
    this.filter = this.fb.group({
      region: [[], [Validators.required]],
      rm: [[], [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    this.getRMList();
  }
  ngAfterViewInit() {
    this.getData();
  }
  continue() {
    this.getData();
  }
  clear() {
    this.filter.reset();
    this.getData();
  }
  openDialogue(startDate,endDate,id, count) {
    const dialogeRef = this.dialog.open(FunnelCommentComponent, {
      panelClass: "funnelDailog",
      width: "850px",
      height: "100%",
      position: { right: "0" }, // Set the width as per your requirements
      data: {where : this.payload.where,
            startDate: startDate,
            endDate: endDate, 
            parentMilestoneSequence: id,
            count: count
      },
    });
  }
  getRMList() {
    this.kanbanService.getRmUserList().subscribe((list) => {
      this.rmUserList = list["result"];
    });
  }

  get payload() {
    let filterValue = this.filter.value;
    let payload: any = {};
    payload.where = {};
    if (filterValue.startDate) {
      payload.startDate = filterValue.startDate
        ? new Date(filterValue.startDate).toISOString()
        : null;
    }
    if (filterValue.endDate) {
      payload.endDate = filterValue.endDate
        ? new Date(filterValue.endDate).toISOString()
        : null;
    }
    payload.where.value = [];
    if (filterValue.region && filterValue.region.length > 0) {
      let obj = {
        field: "leadRegion",
        value: filterValue.region.length > 0 ? filterValue.region : null,
      };
      payload.where.value.push(obj);
    }
    if (filterValue.rm && filterValue.rm.length > 0) {
      let obj = {
        field: "rm.$id",
        value: filterValue.rm.length > 0 ? filterValue.rm : null,
      };
      payload.where.value.push(obj);
    }
    if (payload.where.value.length === 0) delete payload.where.value;
    return payload;
  }

  getData() {
    this.graphData1 = [];
    this.graphData2 = [];
    this.kanbanService.getFunnelData(this.payload).subscribe({
      next: (resp: any) => {
        this.funnelData = resp.result;
        let i = 0;
        let dashData = resp.result[0].dashboardData;
        for(let key in dashData){
          this.graphData1.push(dashData[this.catArray[i]]);
          i++;
        }
        if(resp.result.length>1){
          let j=0;
          let dashData2 = resp.result[1].dashboardData;
          for(let key in dashData2){
            this.graphData2.push(dashData2[this.catArray[j]]);
            j++;
          }
        }
        // setTimeout(() => {
        //   this.conversionFunel["series"][0]["data"] = this.graphData1;
        //   this.conversionFunel.xAxis.categories = this.catArray;
        //   this.charts = Highcharts.chart(
        //     "conversionFunnelGraph0",
        //     this.conversionFunel
        //   );
        //   if (this.funnelData.length > 1) {
        //     this.conversionFunel2["series"][0]["data"] = this.graphData2;
        //     this.conversionFunel2.xAxis.categories = this.catArray;
        //     this.charts = Highcharts.chart(
        //       "conversionFunnelGraph1",
        //       this.conversionFunel2
        //     );
        //   }
        // }, 10);
      },
    });
  }
  funnelMonth(date){
     return (new Date(date).getMonth() == new Date().getMonth()) ?  "As Of Date" : new Date(date).toLocaleString('default', { month: 'long' });
  }
}
