<div class="cntn">
  <div class="right-outer">
    <div class="right">
      <span class="userLable"> {{boardName | titlecase}} </span>
      <span class="material-icons right_ico"> chevron_right </span>
      <span class="userLable"> Bulk Termsheet </span>
    </div>
    <div class="right-2">
      <button class="btnSave" (click)="generateTermSheet()">
        Generate TermSheet
      </button>
    </div>
  </div>
<table mat-table [dataSource]="dataSource" class="payout_table">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element">
       <a style="color: #0052CC;" (click)="downloadfile(element.supplierResponse.id)">  {{element.name}} </a> </td>
  </ng-container>

  <ng-container matColumnDef="supplier">
    <th mat-header-cell *matHeaderCellDef> Buyer Template </th>
    <td mat-cell *matCellDef="let element"> 
      <button class="download" mat-button matTooltip="Download File" *ngIf="element.buyerResposeFile != null">
       <i class="ri-download-line" (click)="downloadfile(element.buyerResposeFile.id)"></i>
    </button>
  </td>
  </ng-container>
  <ng-container matColumnDef="buyer">
    <th mat-header-cell *matHeaderCellDef> Buyer Upload </th>
    <td mat-cell *matCellDef="let element"> 
      <button class="download" mat-button matTooltip="Download File"
        *ngIf="element.buyerFile != null">
        <i class="ri-download-line" (click)="downloadfile(element.buyerFile.id)"></i>
    </button>
    <div *ngIf="element.supplierResponse != null && element.buyerFile == null">
    <input type="file" (change)="onBuyerFileChanged($event)">
  </div>
  </td>
  </ng-container>

  <ng-container matColumnDef="zipFile">
    <th mat-header-cell *matHeaderCellDef> Download TermSheet </th>
    <td mat-cell *matCellDef="let element"> 
      <button class="download" mat-button matTooltip="Download File"  
       *ngIf="element.supplierResponse != null && element.buyerFile != null">
        <i class="ri-download-line" (click)="downloadZipFile(element.id)"></i>
    </button>

  </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="totalRecords" [pageIndex]="pageNumber"
[pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons>
</mat-paginator>
</div>
