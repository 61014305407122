import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from "moment";
import { ConfirmationDialogComponent } from 'src/app/component/suppliers/listing/bulkactions-dialog/confirmation-dialog/confirmation-dialog.component';
import { SettingService } from 'src/app/settings/setting.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-mark-delayed-ep-dialog',
  templateUrl: './mark-delayed-ep-dialog.component.html',
  styleUrls: ['./mark-delayed-ep-dialog.component.scss']
})
export class MarkDelayedEPDialogComponent implements OnInit {
  selectedInvoice = [];
  delayedReason: string;
  delayedDate: any;
  disableDates: any;
  constructor(public dialogRef: MatDialogRef<MarkDelayedEPDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public settingService: SettingService, public toasterService: ToastrService, public dialog: MatDialog) {
    this.selectedInvoice = data;
    let paymntDate = this.selectedInvoice['expectedPaymentDate'];
    let datePymnt = moment(paymntDate).format('YYYY-MM-DD');
    let format = moment(datePymnt).add(1, 'days');
    this.disableDates = moment(format).format('YYYY-MM-DD');
  }

  ngOnInit() {

  }
  getChangedDate(event) {
    let dateValue = event.value
    this.delayedDate = moment(dateValue).format('YYYY-MM-DD');
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  markDelayReq() {
     if (this.delayedDate != null && this.delayedReason != null) {
      let confObj = {
        data: 'delayed',
        invoiceNumber: this.selectedInvoice['invoiceNumber'],
        delayRsn: this.delayedDate,
        expectedDate: this.selectedInvoice['expectedPaymentDate']
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        width: '30%',
        height: 'auto',
        data: confObj,
      });
      let obj = {
        reason: this.delayedReason,
        invoiceIds: [this.selectedInvoice['id']],
        expectedPaymentDate: this.delayedDate,
        isDelayed: true,
        anchorMasterAccountId: localStorage.getItem('credlixUserID')
      }
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == true) {
          this.settingService.invoiceMarkDelayed(obj).subscribe(res => {
            this.dialogRef.close();
            this.toasterService.success("Invoice marked delayed successfully. You will get an email shortly");
          })
        }
      });
    }
  }
}