import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lead-comment-dialog',
  templateUrl: './lead-comment-dialog.component.html',
  styleUrls: ['./lead-comment-dialog.component.scss']
})
export class LeadCommentDialogComponent implements OnInit {
  leadComment: string = '';
  currentUser:string;
  constructor(private pagesService: PagesService, @Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, public dialogRef: MatDialogRef<LeadCommentDialogComponent>,
  public toasterService: ToastrService) {
  }

  ngOnInit() {
    this.getCholaComment();
    this.markCommentAsRead();
    this.currentUser= localStorage.getItem('accountName');
  }

  markCommentAsRead(){
    this.pagesService.markCommentAsRead(this.data.id).subscribe(res=>{})
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
  sendCommentMessage() {
    let obj = {
      "businessAccoutnId": this.data.id,
      "comment": this.leadComment
    }
    if(this.leadComment.trim().length > 0){
      this.pagesService.cholaAddComment(obj).subscribe(res => {
        this.getCholaComment();
      })
    }
  }
  commentList=[];
  getCholaComment() {
    this.pagesService.getCholaComment(this.data.id).subscribe(res => {
      this.commentList= res['result'];
      this.commentList.reverse();
      setTimeout(() => {
        this.scrollToElement();
      }, 0);
      this.leadComment = '';
    })
  }

  setCharLimit(){
    if(this.leadComment && this.leadComment.trim().length == 2000){
      this.toasterService.error("You have reached your maximum limit, allowed characters are 2000 !");
      return false;
    }
  }

  scrollToElement() {
    let id;
    if (this.commentList.length > 0){
      let divId = String(this.commentList.length-1);
      id = document.getElementById(divId);
    } 
    id.scrollIntoView({ behavior: 'auto', block: 'start' })
  }

}
