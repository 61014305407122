<div fxFlexFill>
  <section class="ctaStripWrap">
    <div class="lft">
      <button class="filter back" mat-button (click)="navigateToSupplierPage()">
        <mat-icon *ngIf="!isBuyer">arrow_back</mat-icon>
      </button>
      <!-- <button class="filter fltbtn" mat-button [ngClass]="{'open':!isFiltersShow}" (click)="filtersDisplay()">
        <span class="material-icons">
          filter_list
        </span>
        <span> Filter</span>
      </button> -->
    </div>
    <!-- <div class="rht">
      <button class="download" mat-button (click)="downloadSupplierInvoices()">
        <i class="ri-download-line"></i>
      </button>
      <button class="download" mat-button>
        <i class="ri-restart-line"></i>
      </button>
    </div> -->
  </section>
  <div style="height: 35px;margin-top: 15px;margin-left: 15px;">
    <i style="color: #185FF9;" class="ri-reply-fill"></i>
    Repayments
  </div>
  <div class="date" style="margin-left: 15px;margin-bottom: 10px;" *ngIf="dataSource.data.length">
    {{date}}
  </div>

  <!-- <div class="filters_EP" fxLayout="row" *ngIf="!isFiltersShow">
    <div class="selectdate mataccWrap mataccWrapEp">
      <button mat-button [matMenuTriggerFor]="selectdate">
        <input type="text" class="setinpt" [(ngModel)]="setfilterValue" readonly *ngIf="timeSlot !=null">
        <span *ngIf="timeSlot ==null">Invoice Issue Date</span> <i class="ri-arrow-down-s-line"></i></button>
      <mat-menu #selectdate="matMenu" class="selectdatemenu">
        <div classs="chk_type" (click)="$event.stopPropagation();">
          <mat-radio-group aria-label="Select an option" (change)="getCustomSelectedDate($event.value)"
            [(ngModel)]="customDateOption">
            <mat-radio-button color="primary" value="7">Last Week</mat-radio-button>
            <mat-radio-button color="primary" value="30">Last 30 Days</mat-radio-button>
            <mat-radio-button color="primary" value="90">Last 90 Days</mat-radio-button>
            <mat-radio-button color="primary" value="-1">Custom</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="datebox" *ngIf="customDateOption ==-1" (click)="$event.stopPropagation();">
          <div class="dateboxinput">
            <input type="text" ngxDaterangepickerMd class="form-control" [opens]="right" [drops]="right"
              [linkedCalendars]="true" [(ngModel)]="selectedDate" [locale]="{format: 'DD-MM-YYYY'}"
              placeholder="Select Date range" readonly>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="leftfilteraction">
      <button mat-flat-button class="appbtn" #button [disabled]="isApplyButtonEnable">Apply</button>
      <button mat-flat-button class="resetbtn" #button [disabled]="isClearButtonEnable">Clear</button>
    </div>
  </div> -->

  <div>
    <table mat-table matSortDisableClear [dataSource]="dataSource" matSort class="mat_table"
    style="width: 100%;text-align: center;" [matSortActive]="sort['active']" [matSortDirection]="sort['direction']">
    
    <ng-container matColumnDef="lenderName">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 15%;"> Repayment Party </th>
      <td mat-cell *matCellDef="let element" style="color: #0787dd;">
        <span> {{element.lender.name}} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="expiryDate">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 15%;"> Due Date </th>
      <td mat-cell *matCellDef="let element">
        <span> {{element.expiryDate | date}} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="amountRequested">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 15%;"> Amount Due </th>
      <td mat-cell *matCellDef="let element">
        <span> {{element.amountRequested  | currency:'INR'}} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="overdueDaysBeyondCure">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 15%;"> Days beyond cure </th>
      <td mat-cell *matCellDef="let element">
        <span> {{ _.get(element, element.overdueDaysBeyondCure, "- -")}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="overdue">
      <th mat-header-cell *matHeaderCellDef style="text-align: center"> Status </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.overdue" style="color: red;"> Overdue </span>
        <span *ngIf="!element.overdue"> - - </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  </div>
  <div class="noRecords" *ngIf="!dataSource.data.length || dataSource.data.length == 0">No Repayments available</div>
  <mat-paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
  [pageSize]="pageSize" showFirstLastButtons (page)="getPaginatorData($event)">
</mat-paginator>

</div>