<div class="documentDailog">
      <i class="ri-close-line closeistoryButton" (click)="closeDocumentTab()"></i>
    <section aria-label="documents">
      <div class="d-flex align-items-center">
        <label class="label-1 mb-0">Documents:</label>

        <a class="delete-icon-btn-1" *ngIf="uploadedDocumentList != null && uploadedDocumentList.length>0 && !this.data.preQualified"
          (click)="downloadDocumentsInZip()">Download all <i class="ri-download-line"></i></a>
      </div>

      <div class="document-chip-section d-flex flex-wrap">
        <div class="document-chip w-100" *ngFor="let doc of uploadedDocumentList" style="background-color: aliceblue;">
          <span (click)="downloadSingleDocument(doc.id)">{{doc.name}}</span>
          <a class="download-btn-1"><i class="ri-download-line" (click)="downloadSingleDocument(doc.id)"></i>
          <i class="ri-close-line" (click)="deleteTaskDocuments(doc.id)"></i>
          </a>
        </div>
      </div>

      <div class="file-upload-section" *ngIf="file?.name && isProgressVisible">
        <span class="file-icon"> <i class="ri-file-info-line"></i> </span>
        <div class="d-flex flex-column w-100">
          <div class="file-uploading-status d-flex justify-content-between">
            <span class="file-name">{{file?.name}}</span>
            <a><i class="ri-close-line"></i></a>
          </div>
          <div class="progress-bar-1" *ngIf="isProgressVisible">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
      </div>
      <!-- <div draggable="true" class="dragArea mb-30 w-100" ngFileDragDrop (fileDropped)="onFileChanged($event)">
        <span class="lbl" style="display: flex;">
          <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
          <span (click)="fileInput.click()"><a>Drop Files Here <br> Or Browse</a>
            <mat-icon color="primary" fontSize="large">cloud_upload</mat-icon>
          </span>

        </span>
      </div> -->
      <div class="upload_file" draggable="true" ngFileDragDrop (fileDropped)="onFileChanged($event)" (click)="fileInput.click()">
        <span>Drop files here or Browse</span>
        <input type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
        <mat-icon>cloud_upload</mat-icon>
      </div>
    </section>
</div>
