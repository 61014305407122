<div class="view" fxLayout="column">
  <div class="docdetails breadcrumb" fxLayout="row" fxFlex="10">
    <!-- <a (click)="backtoDocuments()">Early Payments</a> -->
    <a>Early Payments</a>
    <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i>
    <a (click)="breadcrumNavigation()" class="hyperlinktxt" *ngIf="isCustomer"><span>Suppliers</span> </a>
    <i class="ri-arrow-right-s-line" style="font-size: 20px;" *ngIf="isCustomer"></i>
    <a>{{documentList?.supplier_account_name}}</a>
    <i class="ri-arrow-right-s-line" style="font-size: 20px;"></i> <a>{{documentList?.invoiceNumber}} </a>
  </div>
  <div fxLayout="column" class="fieldDetails">
    <div class="full-width top-align" fxLayout="row" fxFlex="20">
      <h5>Invoice</h5>
    </div>
    <div fxLayout="row" class="full-width">
      <div class="halfField add1" fxLayout="column">
        <label>To</label>
        <span><strong>{{documentList?.customer_account_name}}
          </strong><br>{{documentList?.customer_shipping_address}}</span>
      </div>
      <div class="halfField add1" fxLayout="column">
        <label>From</label>
        <span><strong>{{documentList?.supplier_account_name}}
          </strong><br>{{documentList?.supplier_billing_address}}</span>
      </div>
      <div class="halfField" fxLayout="column">
        <label>Invoice No.</label>
        <span>{{documentList?.invoiceNumber}}</span>
      </div>
      <div class="halfField" fxLayout="column">
        <label>Issue Date</label>
        <span>{{documentList?.invoiceDate |date}}</span>
      </div>
      <div class="halfField" fxLayout="column">
        <label>Due Date</label>
        <span>{{documentList?.dueDate |date}}</span>
      </div>
      <div class="halfField" fxLayout="column">
        <label>Currency</label>
        <span>INR</span>
      </div>
    </div>

    <div id="table-wrapper">
      <div id="table-scroll">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product / Service</th>
              <th>MSN</th>
              <th>HSN/SAC</th>
              <th>Qty.</th>
              <th>Rate</th>
              <th>Taxable Amount</th>
              <th>Tax(%)</th>
              <th>Tax Amount</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedRecordsArray; let i = index">
              <td><span *ngIf="item.msn != 'ROUNDOFF'">{{i+1}} </span></td>
              <td>{{item.name}}</td>
              <td><span *ngIf="item.msn != 'ROUNDOFF'">{{item.msn}}</span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.hsn_sac_code}} </span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.quantity}} </span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.rate | number:'1.2-2'}} </span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.amount_excluding_tax | number:'1.2-2'}} </span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.tax_rate | number:'1.2-2'}}</span></td>
              <td> <span *ngIf="item.msn != 'ROUNDOFF'">{{item.tax_amount | number:'1.2-2'}}</span></td>
              <td>{{item.amount | number:'1.2-2'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="amount_details" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1%">
      <label><strong>Total Amount</strong></label>
      <span *ngIf="tdsAmount">{{amountWithTDS | currency:'INR'}}</span>
      <span *ngIf="!tdsAmount">{{documentList?.amount | currency:'INR'}}</span>
    </div>
    <div class="amount_details" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1.1%" *ngIf="tdsAmount">
      <label><strong>TDS Charged</strong></label>
      <span>- {{tdsAmount | currency:'INR'}}</span>
    </div>
    <div class="amount_details" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1.9%" *ngIf="amountWithTDS">
      <label><strong>Net Amount</strong></label>
      <span>{{documentList?.amount | currency:'INR'}}</span>
    </div>
  </div>

</div>