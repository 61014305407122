<div class="paymnt">
  <div class="wrp">
    <h5 *ngIf="!isBankValid">Verify OTP to Send Payments.</h5>
    <h5 *ngIf="isBankValid">Verify OTP to confirm changes.</h5>
    <p *ngIf="!isBankValid">This will initiate {{totalPayment}} payments. Once initiated this can’t be cancelled. Please enter OTP sent on your email
      <strong>{{loggedUserEmail}}</strong> and phone 979*****08</p>

      <p *ngIf="isBankValid">Please enter OTP sent on your email {{userEmail}} and phone {{userPhone}}</p>
    <div fxLayout="row">
      <code-input style="font-size: 11px;width: 200px;" [isCodeHidden]="isCodeHidden"
      #codeInput [codeLength]="4" (codeCompleted)="onCodeCompleted($event)" (codeChanged)="onCodeChanged($event)">
      </code-input>
      <mat-icon class="eyeIcon" (click)="hideOTP()">remove_red_eye</mat-icon>
    </div>
  </div>

  <div class="actionbtn">
    <label (click)="resendOTP()" [ngClass]="isResend == true? 'enableBtn' : 'disableBtn'">
      <mat-icon>refresh</mat-icon>
      Resend OTP <span id="timer"></span>
    </label>
    <button mat-raised-button [ngClass]="isConfirm == true ? 'disabledSaveBtn' : 'savebtn'" 
      [disabled]="isConfirm" (click)="submitBankDetails()">{{sendBtnLabel}}</button>
    <button type="button" (click)="closeDialog()" mat-dialog-close mat-button class="cnclbtn" >Cancel</button>
  </div>
</div>