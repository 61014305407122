import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingService } from 'src/app/settings/setting.service';
import * as moment from "moment";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-manual-creditform-dialog',
  templateUrl: './manual-creditform-dialog.component.html',
  styleUrls: ['./manual-creditform-dialog.component.scss']
})
export class ManualCreditformDialogComponent implements OnInit {

  manualCreditForm: FormGroup;
  date:any;
  modeList=[
    {id:1, name: 'NEFT', value: 'N'},
    {id:2, name: 'Fund transfer', value: 'N'},
    {id:3, name: 'RTGS', value: 'N'},
    {id:4, name: 'IMPS', value: 'N'},
  ]
  constructor(public dialogRef: MatDialogRef<ManualCreditformDialogComponent>,private fb: FormBuilder,
    public toasterService: ToastrService, public dialog: MatDialog, private settingService: SettingService) { }

  ngOnInit() {
    this.manualCreditForm = this.fb.group({
      'client Code': ['', Validators.required],
      'Virtual Account Number': ['', Validators.required],
      'Transaction Amount': [''],
      'Mode':  ['', Validators.required],
      'UTR': ['', Validators.required],
      'Remitter Name': ['', Validators.required],
      'Remitter Account Number': ['', Validators.required],
      'Sender IFSC': ['', Validators.required]
    });
  }

  getDate(event){
    let Start = event.value
    this.date = moment(Start).format('YYYY-MM-DD');
  }
  submitDetails(){
    this.manualCreditForm.value['Date']= this.date
   let obj={
     test:[this.manualCreditForm.value]
   }
   
   this.settingService.manualcreditForm(obj).subscribe(res=>{
    this.toasterService.success("Data Saved");
    this.dialogRef.close();
   })
  }

}
