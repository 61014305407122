import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingService } from 'src/app/settings/setting.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentVerificationDialogComponent } from './review-payout/payment-verification-dialog/payment-verification-dialog.component';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { OtpLoaderComponent } from 'src/app/component/escrow-account/create-payout/payout-creation/review-payout/otp-loader/otp-loader.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-payout-creation',
  templateUrl: './payout-creation.component.html',
  styleUrls: ['./payout-creation.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class PayoutCreationComponent implements OnInit {
  matStepperCount: number = 0;
  isFileUploaded: boolean = true;
  isFirstStepDone: boolean = false;
  escrowId: any;
  ispaymentFailed: boolean = false;
  isPaymentInitiate: boolean = false;
  isStatusPayment;
  getTabIndex: any;
  state:string;
  isSecondStepDone:boolean=false;
  isThirdStepDone:boolean=false;
  completedFinal:boolean= false;
  statefor:string;
  ispayoutFiltersShow: boolean = false;

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private _formBuilder: FormBuilder, public router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog, public settingService: SettingService) {
    this.settingService._reviewTabIndexSubjectBehv.subscribe(res => {
      this.getTabIndex = res;
    })
  }
  urlPaarams: any;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlPaarams = params;
      if (params.id != null) {
        this.escrowId = params.id;
        if (params != null && params.index == 0) {
          this.isFileUploaded = false;
        }
        this.settingService._payoutElementSubjectBehv.subscribe(res => {
          if (Object.keys(res).length) {
            if (res.status == 'PAYMENT_INITIATED' || res.status == 'PAID' || res.status== 'PARTIALLY_SETTLED' || res.status == 'FAILED') {
              this.stepper.selectedIndex = 2;
              this.matStepperCount = 2;
              this.isPaymentInitiate = true;
              this.isFirstStepDone= true;
              this.isSecondStepDone= true;
              this.completedFinal = true;
              this.statefor = 'done';
              localStorage.setItem('Payment', 'false');
            }
            else {
              this.completedFinal = false;
              this.isPaymentInitiate = false;
            }
            if (res.status == 'SUCCESS' || res.status == 'APPROVED') {
              this.stepper.selectedIndex = 2;
              this.matStepperCount = 2;
              this.isFirstStepDone= true;
              this.isSecondStepDone= true;
              localStorage.setItem('Payment', 'true');

            }
            else if (res.status == 'AWAITING_APPROVAL') {
              this.stepper.selectedIndex = 1;
              this.matStepperCount = 1;
              this.isFirstStepDone= true;
            }
          }
          else if (!Object.keys(res).length) {
            this.isStatusPayment = localStorage.getItem('Payment')
            if (this.isStatusPayment == 'true') {
              this.isPaymentInitiate = false;
            }
            else if (this.isStatusPayment == 'false') {
              this.isPaymentInitiate = true;
              this.completedFinal = true;
              this.statefor = 'done';
            }

            if (params.index == 1) {
              this.stepper.selectedIndex = 1;
              this.matStepperCount = 1;
              this.isFirstStepDone= true;
              this.completedFinal= false;
            }
            else if (params.index == 2) {
              this.stepper.selectedIndex = 2;
              this.matStepperCount = 2;
              this.isFirstStepDone= true;
              this.isSecondStepDone= true;
            }
          }
        })
      }
      else if (params == null || params == {}) {
        this.stepper.selectedIndex = 0;
        this.matStepperCount = 0;
        this.completedFinal= false;
      }
    });
  }
  displayCounter(value) {
    if (value != null || this.urlPaarams.index == 0) {
      this.isFileUploaded = false;
    }
  }
  setUrlIndex: number;
  reviewPayment(stepper: MatStepper, value) {
    let obj = {}
    if (value == 'review') {
      this.setUrlIndex = 1;
      if (this.escrowId == null) {
        this.escrowId = localStorage.getItem('escrowFieldID');
      }
      this.settingService.reviewPayoutTransaction(this.escrowId, obj).subscribe(res => {
        if (res['status'] == 200) {
          this.isFileUploaded = true;
          this.router.navigate(['/payouts-creation/', {
            id: this.escrowId, index: 1
          }]);
          this.isFirstStepDone = true;
          this.matStepperCount = 1;
          this.stepper.selectedIndex = 1;
        }
      })
      this.settingService.getPayoutElement({
        status: 'AWAITING_APPROVAL'
      })
    }
    else if (value == 'accept') {
      this.verifyPayment();
    }
    if (this.escrowId == null) {
      this.escrowId = localStorage.getItem('escrowFieldID');
    }
    stepper.next();
    this.settingService.getMatStepperCount(this.stepper.selectedIndex);
  }


  verifyPayment() {
    let obj = {}
    this.settingService.verifyAndAcceptPayment(this.escrowId).subscribe(res => {
      if (res['status'] == 200) {
        this.setUrlIndex = 2
        this.matStepperCount = 2;
        this.ispaymentFailed = true;
        this.router.navigate(['/payouts-creation/', {
          id: this.escrowId, index: 2
        }]);
        this.settingService.getPayoutElement({
          status: 'SUCCESS'
        });
        this.settingService.getPayoutElement(res['result']);
      }
    }, (error) => {
      if (error['status'] == 400) {
        this.setUrlIndex = 1;
        this.matStepperCount = 1;
        this.ispaymentFailed = false;
        this.settingService.getPayoutElement({
          status: 'AWAITING_APPROVAL'
        });
      }

    });
  }

  verifyPaymentOTP() {
    const dialogRef = this.dialog.open(OtpLoaderComponent, {
      width: '550px',
      height: '335px',
      data: {
        value: 'escrow-account',
        id: this.escrowId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const dialogRef = this.dialog.open(PaymentVerificationDialogComponent, {
        width: '550px',
        height: '335px',
        data: {
          value: 'escrow-account',
          id: this.escrowId,
          requestID: result['requestId']
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    });
  }
  
  filtersDisplay() {
    this.ispayoutFiltersShow = !this.ispayoutFiltersShow;
    this.settingService.isFilterClicked(this.ispayoutFiltersShow);
  }
  resultDownload;
  downloadTransactionSheet(){
    let filterStore;
    let reportType: string;
   let tabIndex:number= JSON.parse(localStorage.getItem('tabIndex'))
   if(tabIndex== 0){
    filterStore=JSON.parse(sessionStorage.getItem("reviewFilterState"))
     reportType = 'invoices'
   }
   else if(tabIndex ==1){
     filterStore =JSON.parse(sessionStorage.getItem("supplierFilterState"));
     reportType ='suppliers'
   }
   else if(tabIndex ==2){
    filterStore=JSON.parse(sessionStorage.getItem("paymentFilterState"))
    reportType = 'payments'
   }
   let obj={
     where: filterStore.where
   }
    
    this.settingService.downloadPayoutTransactionSheet(this.escrowId,reportType,obj).subscribe((res:any) =>{
      var contentDispositionHeader = res.headers.get('content-disposition');
      this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      saveAs(res.body, this.resultDownload);
      return this.resultDownload.replace(/"/g, '');
    })
  }
  

  selectionChange(event) {
    this.matStepperCount = event.selectedIndex;
    this.settingService.getMatStepperCount(this.matStepperCount);
  }

  navigateToPayoutPage(){
    this.router.navigate(['/payouts']);
  }
}

