import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption, MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as lodash from 'lodash';
import { RequestEarlyPaymentComponent } from 'src/app/component/early-payments/early-payment-listing/request-early-payment/request-early-payment.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-reviewpayment',
  templateUrl: './invoice-reviewpayment.component.html',
  styleUrls: ['./invoice-reviewpayment.component.scss']
})
export class InvoiceReviewpaymentComponent implements OnInit {
  _: any = lodash;
  invoiceRes = [];
  escrowID: any;
  pageSize: number = 50;
  totalRecords: number;
  pageNumber: number = 0;
  displayedColumns = ['invoiceNumber', 'status', 'image', 'suppID','isEPRequested', 'invoiceAmt', 'deductions', 'amountPaid', 'dueToSupplier', 'dueToLender',
    'dueToCredlix','epAmount','lenderName','paymentType', 'amountReported', 'amountInEscrow', 
    'escrowUTR','payToLender', 'payToSupplier', 'payToCredlix'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  tabIndex: number;
  invoiceSubscribeData: any;
  showSearchIcon: boolean = true;
  showCloseIcon: boolean = false;
  searchInvoiceID: string = '';
  selectedInvoiceStatus=[]
  selectedPaymentType = [];
  isApplyButtonEnable: boolean = true;
  isClearButtonEnable: boolean = true;
  setInvoiceStatus=[];
  isInvoiceFilterVisible: boolean = false;
  getTabIndex: any;
  storeFilterState: any
  setPaymentType=[];
  invoiceStatus: any = [
    { id: '1', name: 'Match', value: 'entryEscrowMatch.matched', key: 'entryEscrowMatch.matched', val: true },
    { id: '2', name: 'Not Found', value: 'entryEscrowMatch.found', key: 'entryEscrowMatch.found', val: false },
    { id: '3', name: 'Mismatch', value: 'entryEscrowMatch.matchedWithWarning', key: 'entryEscrowMatch.matchedWithWarning', val: true }
  ];
  paymentType: any = [
    { id: '1', name: 'Early Payment', value: 'EP_PAYMENT' },
    { id: '2', name: 'Invoice Payment', value: 'INVOICE_PAYMENT' },
    { id: '3', name: 'Margin Payment', value: 'MARGIN_PAYMENT' },
    { id: '4', name: 'Misc Payment', value: 'JOURNAL_PAYMENT' },
    { id: '5', name: 'Lender Repayment', value: 'LENDER_REPAYMENT' }

  ];
  @ViewChild('matRef') matRef: MatSelect;
  @ViewChild('matRef1') matRef1: MatSelect;

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(public settingService: SettingService, public dialog: MatDialog, public router: Router, private activatedRoute: ActivatedRoute,) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.getTabIndex= localStorage.getItem('tabIndex');
    this.settingService._isFilterClickSubjectBehv.subscribe(res => {
      if(res== true){
        if(this.getTabIndex==0){
          this.isInvoiceFilterVisible= true;
        }
      }
      else{
        this.isInvoiceFilterVisible= false;
      }
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id != null) {
        this.escrowID = params.id;
        let getsessionRes= JSON.parse(sessionStorage.getItem("invoiceRes"));
        if(getsessionRes != null || getsessionRes != undefined){
          this.dataSource.data= getsessionRes['result'];
          this.totalRecords= getsessionRes['total'];
          this.storeFilterState = JSON.parse(sessionStorage.getItem('reviewFilterState'));
              if (this.storeFilterState != null || this.storeFilterState != undefined) {
                this.isApplyButtonEnable= false;
                this.isClearButtonEnable= false;
                if (this.storeFilterState['where'].hasOwnProperty('invoiceNumber')) {
                  this.searchInvoiceID = this.storeFilterState['where']['invoiceNumber']['value'];
                  this.showSearchIcon= false;
                  this.showCloseIcon= true;
                }
                if (this.storeFilterState['where'].hasOwnProperty('paymentType')) {
                  this.setPaymentType=(this.storeFilterState['where']['paymentType']['value'])
                  this.selectedPaymentType= this.setPaymentType
                }
                if (this.storeFilterState['where'].hasOwnProperty('statusQuery')) {
                  if(this.storeFilterState['where']['statusQuery']['queries'] != null){
                    let queryObj= this.storeFilterState['where']['statusQuery']['queries']
                    for (let i=0; i<queryObj.length; i++){
                      if(queryObj[i].field== 'entryEscrowMatch.matchedWithWarning'){
                        this.setInvoiceStatus.push(queryObj[i].field)
                        this.selectedInvoiceStatus= this.setInvoiceStatus
                      }
                      else if(queryObj[i].field== 'entryEscrowMatch.found'){
                        this.setInvoiceStatus.push(queryObj[i].field)
                        this.selectedInvoiceStatus= this.setInvoiceStatus
  
                      }
                      else if(queryObj[i].field =='entryEscrowMatch.matched'){
                        this.setInvoiceStatus.push(queryObj[i].field);
                        this.selectedInvoiceStatus= this.setInvoiceStatus
                        
                      }
                    }
                  }
                }
              }
          
        }
        else {
                this.getInvoicesPayment(false);
        }
      }
    });
  }

  getInvoicesPayment(value) {
    if (this.searchInvoiceID != '') {
      this.showSearchIcon = false;
      this.showCloseIcon = true;
    }
    this.checkFilterButtonCondition();

    let reqObj = {};
    if (this.paginator != null && value == false) {
      this.paginator.firstPage();
    }
    reqObj['from'] = this.paginator.pageIndex * 50
    reqObj['size'] = this.pageSize
    reqObj['where'] = {}
    if (this.selectedInvoiceStatus != null && this.selectedInvoiceStatus.length > 0) {
      let queryArr = [];
      for(let i = 0; i < this.selectedInvoiceStatus.length; i++) {
        let key = this.selectedInvoiceStatus[i];
        let keyIndex = this._.findIndex(this.invoiceStatus, function (o) {
          return o.value == key;
        })
        if(keyIndex > -1){
          let keyObj = this.invoiceStatus[keyIndex];
          queryArr.push({
            field: keyObj['key'],
            value: keyObj['val']
          });
        }
      }
      if(queryArr.length > 0){
        reqObj['where']['statusQuery'] = {
          "type": "or",
          "queries": queryArr
        }
      }
    }
    if (this.selectedPaymentType != null && this.selectedPaymentType.length > 0) {
      reqObj['where']['paymentType'] = {
        "type": "in",
        "value": this.selectedPaymentType
      }
    }
    if (this.escrowID != null) {
      reqObj['where']['transactionFile._id'] = {
        "type": "search",
        "value": this.escrowID
      }
    }
    if (this.searchInvoiceID != null && this.searchInvoiceID.trim() != '') {
      reqObj['where']['invoiceNumber'] = {
        "type": "search",
        "value": this.searchInvoiceID.trim()
      }
    }

    if (localStorage.getItem('credlixUserID')) {
   reqObj['masterAccountId'] =localStorage.getItem('credlixUserID')
    }
    sessionStorage.setItem("reviewFilterState", JSON.stringify(reqObj));

    this.settingService.getInvoicesPayout(reqObj).subscribe((res: any) => {
      this.invoiceRes = res;
      this.dataSource.data = this.invoiceRes['result'];
      this.totalRecords = res['total'];
      sessionStorage.setItem("invoiceRes", JSON.stringify(res));
    })
  }
  getPaginatorData() {
    this.getInvoicesPayment(true);
  }
  getStatusColor(element) {
    if (element == 'Match') {
      return 'paidColor'
    }
    else if (element == 'Not Found') {
      return 'notFoundColor'
    }
    else if (element == 'Mismatch') {
      return 'pendingColor'
    }
  }
  getTooltipElement(element) {
    if (element.escrowTransaction != null) {
      return element.escrowTransaction.message
    }
  }

  getRowStatus(element) {
    if (element.entryEscrowMatch != null && element.entryEscrowMatch.found == true && element.entryEscrowMatch.matched == true) {
      return 'Match'
    }
    else if (element.entryEscrowMatch != null && element.entryEscrowMatch.found == true && element.entryEscrowMatch.matchedWithWarning == true) {
      return 'Mismatch'
    }
    else if (element.entryEscrowMatch != null && element.entryEscrowMatch.found == false) {
      return 'Not Found'
    }
  }

  formatPaymentType(value){
    return this._.startCase(value);
  }
  getInvoiceStatus(value) {
    this.selectedInvoiceStatus = value;
    this.checkFilterButtonCondition();
  }
  getPaymentType(value) {
    this.selectedPaymentType = value;
    this.checkFilterButtonCondition();
  }

  requestPayment(element){
     Object.assign(element, {['id']: element['invoiceId'] })['invoiceId'];    
    let obj={
      selectedInvoiceDetails: [element],
      value: 'payout'
    }
    const dialogRef = this.dialog.open(RequestEarlyPaymentComponent, {
      disableClose: true,
      width: '450px',
      height: '100vh',
      data: obj,
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }
  
  resetSearchText(value) {
    this.searchInvoiceID = '';
    this.showCloseIcon = false;
    this.showSearchIcon = true;
    this.getInvoicesPayment(false);
  }
  checkInputText(value){
    if(value==''){
      this.showCloseIcon= false;
      this.showSearchIcon= true;
    }

  }
  checkFilterButtonCondition() {
    if (this.searchInvoiceID != '' || this.selectedPaymentType.length > 0 || this.selectedInvoiceStatus != []) {
      this.isApplyButtonEnable = false;
      this.isClearButtonEnable = false;
    }
    else if (this.searchInvoiceID == '' || this.selectedPaymentType.length == 0 || this.selectedInvoiceStatus == []) {
      this.isApplyButtonEnable = true;
      this.isClearButtonEnable = true;
    }
  }
  clearInvoiceFilters() {
    if (this.matRef1) {
      this.matRef1.options.forEach((data: MatOption) => data.deselect());
    }
    this.showCloseIcon = false;
    this.showSearchIcon = true;
    this.setInvoiceStatus =[];
    this.selectedInvoiceStatus = []
    this.searchInvoiceID = '';
    this.getInvoicesPayment(false);
  }
}
