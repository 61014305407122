<div class="main-container">
  <i class="ri-close-line close-btn" (click)="closeForm()"></i>
  <div class="header-dev">
    <h3>{{buyerDetail && buyerDetail.id ? 'Update Buyer' : 'Add Buyer'}}</h3>
  </div>
  <div>
    <form [formGroup]="supplierDetailsForm" (ngSubmit)="editDetails()">
      <div class="form-details">
        <mat-form-field class="itemlimit" appearance="outline">
          <mat-label>Buyer Name <span class="text-danger">*</span></mat-label>
          <input type="text" matInput [formControl]="stateCtrl" aria-label="ExporterProduct" [matAutocomplete]="auto" (click)="getCall()">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let category of filteredStates | async" [value]="category.buyerName">
            <span>{{category.buyerName}}</span>
          </mat-option>
        </mat-autocomplete>
          <mat-error *ngIf="stateCtrl.errors?.required">This is required.</mat-error>
        </mat-form-field>

        <div fxLayout="row" class="mt-10">
          <!-- <mat-form-field appearance="fill" class="itemdscnt selectearlypaystatus finan">
            <mat-label>Choose Country <span class="text-danger">*</span></mat-label>
            <mat-select formControlName="country">
              <input matInput placeholder="Search country" formControlName="searchText" (keyup)="filterCountries()" />
              <mat-option *ngFor="let name of filteredCountries" [value]="name">{{name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="supplierDetailsForm.controls.country.errors?.required">This is required.
            </mat-error>
          </mat-form-field> -->
          <mat-form-field appearance="fill" class="itemdscnt selectearlypaystatus finan">
            <mat-label>Choose Country <span class="text-danger">*</span></mat-label>
            <input matInput placeholder="Search country" formControlName="country" 
                   [matAutocomplete]="countryauto" (input)="filterCountries()" (blur)="removeInvalid($event)"/>
            <mat-autocomplete #countryauto="matAutocomplete">
              <mat-option *ngFor="let name of filteredCountries" [value]="name">
                {{ name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="supplierDetailsForm.controls.country.errors?.required">
              This is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="itemdscnt selectearlypaystatus finan">
            <mat-label>Choose Currency <span class="text-danger">*</span></mat-label>
            <mat-select formControlName="currency">
              <mat-option value="USD">$ USD</mat-option>
            </mat-select>
            <mat-error *ngIf="supplierDetailsForm.controls.currency.errors?.required">This is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="mt-10">
          <mat-form-field class="itemdscnt" appearance="outline">
            <mat-label>Approved Limit <span class="text-danger">*</span></mat-label>
            <input type="number" matInput formControlName="limitApproved">
            <mat-error *ngIf="supplierDetailsForm.controls.limitApproved.errors?.required">This is required.</mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="fill" class="itemdscnt selectearlypaystatus finan">
            <mat-label>Insurer Name <span class="text-danger">*</span></mat-label>
            <mat-select formControlName="insurer">
              <mat-option *ngFor="let name of insurerName" [value]="name">{{name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="supplierDetailsForm.controls.insurer.errors?.required">This is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" class="mt-10">
          <mat-form-field class="itemdscnt" appearance="outline">
            <mat-label>Tenor (Days)</mat-label>
            <input type="number" matInput formControlName="tenorApprovedDays">
          </mat-form-field>

          <mat-form-field class="itemdscnt" appearance="outline">
            <mat-label>Requested Limit</mat-label>
            <input type="number" matInput formControlName="newRequestedLimitAmount">
          </mat-form-field>
        </div>

        <div fxLayout="row" class="mt-10" *ngIf="buyerDetail && buyerDetail.id">
          <mat-form-field class="itemdscnt" appearance="outline">
            <mat-label>Utlized limit</mat-label>
            <input type="number" matInput formControlName="utilizedAmount">
          </mat-form-field>

          <mat-form-field class="itemdscnt" appearance="outline">
            <mat-label>Available Limit</mat-label>
            <input type="number" matInput formControlName="availableLimit">
          </mat-form-field>
        </div>
      </div>
      <div class="actionbtn">
        <button type="submit" mat-raised-button class="savebtn">{{buyerDetail && buyerDetail.id ? 'Update' : 'Add'}} Buyer</button>
      </div>
    </form>
  </div>
</div>