<div class="dashbrd_sec">
  <div class="dash_wrp" [ngClass]="{'dashbrdWrp': dashboardTabIndex== 1}">
    <div class="top-head-absolute justify-content-end ">
      <!-- <h1>Dashboard</h1> -->
      <div class="right-filters">
        <div class="flts" *ngIf="dashboardTabIndex==0">
        <button class="chart-filter" #menuTrigger="matMenuTrigger" mat-button [matMenuTriggerFor]="supplierFilter">Suppliers <i class="ri-arrow-down-s-line"></i></button>
       
        <mat-menu class="chart-filter-options" #supplierFilter="matMenu" >
          <div (click)="$event.stopPropagation();">
          <mat-form-field appearance="outline">
            <mat-label>Search supplier name</mat-label>
            <mat-icon matSuffix>search</mat-icon>
            <input matInput placeholder="Enter supplier name" [(ngModel)]="searchText" (input)="onSearchChange(searchText)">
         
          </mat-form-field>
          <ul class="checkbox-list " (click)="$event.stopPropagation();"> 
            <li *ngFor="let supplier of supplierLinks; let i=index">
              <mat-checkbox class="example-margin" [(ngModel)]="supplier['isChecked']">{{supplier.businessName}}</mat-checkbox>
            </li>
          </ul>
        <div class="cta-section d-flex">
          <a class="cta-btn-grey" (click)="supplierTrigger.closeMenu()">CANCEL</a>
          <a class="cta-btn" (click)="getSupplierList()">OKAY</a>
        </div>
      </div>
        </mat-menu>
        <button class="chart-filter" #menuLenderTrigger="matMenuTrigger" mat-button [matMenuTriggerFor]="landersFilter">Lenders <i class="ri-arrow-down-s-line"></i></button>
        <mat-menu class="chart-filter-options " #landersFilter="matMenu">
          <div (click)="$event.stopPropagation();">
           
            <ul class="checkbox-list " (click)="$event.stopPropagation();">
              <li *ngFor="let leads of lendersData">
                <mat-checkbox class="example-margin" [(ngModel)]="leads['isChecked']">{{leads.name}}</mat-checkbox>
              </li>
            </ul>
          <div class="cta-section d-flex">
            <a class="cta-btn-grey" (click)="lenderTrigger.closeMenu()">CANCEL</a>
            <a class="cta-btn" (click)="getSupplierList()">OKAY</a>
          </div>
        </div>
        </mat-menu>
      </div>

        <button class="add-cta" (click)="createOkr(true);" mat-raised-button color="primary" *ngIf="dashboardTabIndex==1">
          <mat-icon>add </mat-icon>
          Add OKR
        </button>
      </div>
    </div>
    <mat-tab-group class="tab-1" (selectedTabChange)="getDashboardTabsEvent($event)">
      <mat-tab >
        <ng-template mat-tab-label >
          <span>Dashboard</span>
        </ng-template> 
          <div class="below_sec">
          <div class="custom-row">
            <div class="col-custom w-30">
              <mat-card class="filter-card h-70 mb-5p theme-blue-bg numbersTodaySection">
                <app-eligible-invoice-stats [supplierLinks]="supplierLinks" [data]="eligibleInvoiceStats"></app-eligible-invoice-stats>
              </mat-card>
            </div>
    
            <div class="col-custom w-35">
              <mat-card class="filter-card">
                <app-ep-source-request-stats [lendersData]="lendersData" [supplierLinks]="supplierLinks"
                [data]="requestedBySource"></app-ep-source-request-stats>
              </mat-card>
            </div>
    
            <div class="col-custom w-35">
              <mat-card>
                <app-ep-conversion-funnel [lendersData]="lendersData" [supplierLinks]="supplierLinks"
                 [data]="conversionFunnel"></app-ep-conversion-funnel>
              </mat-card>
            </div>
          </div>
    
          <div class="custom-row">
            <div class="col-custom w-100">
              <mat-card class="filter-card">
                <div class="card-header">
                  <h2>Early Payment Trend</h2>
                  <div class="chart-filter">
                    <button class="duration-filter" mat-button [matMenuTriggerFor]="durationFilter">{{invoiceDuration}}<i
                        class="ri-arrow-down-s-line"></i></button>
                    <mat-menu class="duration-filter-menu" #durationFilter="matMenu">
                      <div>
                        <mat-radio-group aria-label="Select an option" *ngFor="let timeVal of durationArr"
                        [(ngModel)]="invoiceDuration">
                          <ul class="menu-list">
                            <li>
                              <mat-radio-button [value]="timeVal" (click)="durationChange($event, timeVal, 'allInvoiceStats')"
                              >{{timeVal}}</mat-radio-button>
                            </li>
                            <mat-divider></mat-divider>
                          </ul>
                        </mat-radio-group>
                      </div>
                      <div class="datebox" *ngIf="invoiceDuration =='Custom'" (click)="$event.stopPropagation();">
                        <div class="dateboxinput">
                          <input type="text" ngxDaterangepickerMd class="form-control"
                            [linkedCalendars]="true" [(ngModel)]="selectedDate" (datesUpdated)="datesUpdated($event)"
                            [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date range" readonly>
                        </div>
                      </div>
                    </mat-menu>
                    <button class="chartsMoreOption-btn" mat-button [matMenuTriggerFor]="chartsMoreOption"><i class="ri-more-2-fill"></i></button>
                    <mat-menu class="moreOptionMenu" #chartsMoreOption="matMenu">
                      <button mat-menu-item (click)="downloadCSV()"><i class="ri-download-2-line"></i> Download</button>
                    </mat-menu>
                    
                  </div>
                </div>
                    <mat-tab-group class="chars-tab" [@.disabled]="true" (selectedIndexChange)="changeTab($event)">
                      <mat-tab *ngFor="let tab of chartTabs">
                        <ng-template mat-tab-label >
                          <span class="chart-label"> {{tab.name}} </span>
                          <span class="chart-amount"> {{tab.docCount}}  </span>
                          <span class="chart-amount"> ₹{{tab.amount}} </span>
                          <span class="chart-amount text-red">{{tab.precent}}</span>
                        </ng-template >
                      </mat-tab>
                    </mat-tab-group>
                <app-early-payment-trend [data]="chartData" [invoiceDuration]="invoiceDuration"></app-early-payment-trend>
               <a class="view-more" href="">View All Invoices</a>
              </mat-card>
            </div>
           
          </div>       
          </div>  
      </mat-tab>
      <mat-tab >
        <ng-template mat-tab-label >
          <span>OKRs</span>
        </ng-template> 
         <section class="okr-section">
          <h2>Key Results</h2>
          <div class="wrp" style="overflow: scroll; position: relative; min-height: 40px;
          height: 78vh;" infinite-scroll [infiniteScrollDistance]="5"
        [infiniteScrollThrottle]="150" (scrolled)="onScrollDown($event)" [scrollWindow]="false"
        [fromRoot]="true">
          <div class="progress-card mb-15" *ngFor="let list of allOKRData; let i = index" >      
          <ul class="progress-head">
            <li (click)="toggleCard(i)">
              <strong>{{list.okrName | titlecase}}</strong>
              <span>{{list.goalName}}</span>
              <span>{{list.goalDescription}}</span>         
            </li>
            <li (click)="toggleCard(i)">
                <strong>{{list.endDate | date}}</strong>
                <span>{{statusCheck(list.endDate)}}</span>
                <span>{{calculateDays(list)}} </span>
            </li>
            <li class="flex-row">
              <div class="progress-section">               
                <progress class="Progress-main" [max]="list?.goalTarget" [value]="list?.targetAchieved">
                </progress>
                <div class="progress-info">
                  <span title={{list?.targetAchieved}}>Current: {{numDifferentiation(list?.targetAchieved)}}</span>
                  <span>Target: {{list?.goalTarget}}</span>
                </div>
              </div>
              <button class="more-option float-btn" [matMenuTriggerFor]="menu1" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>more_vert</mat-icon>
              </button>
              <button  class="more-option float-btn" mat-icon-button (click)="toggleCard(i)">
                <i class="material-icons" *ngIf="!showMenu[i]">chevron_right</i>
                <i class="material-icons" *ngIf="showMenu[i]">expand_more</i>
              </button>
              <mat-menu #menu1="matMenu">
                <button mat-menu-item (click)="updateOKRGoal(list,true)">Edit</button>
                <button mat-menu-item (click)="updateOKRGoal(list,false)">Duplicate</button>
              </mat-menu>
            </li>
          </ul>
          <ng-container *ngIf="showMenu[i]">
            <ul class="progress-list" *ngFor="let user of list['okrGoalSettings']">
                <li>
                  <strong>{{user?.user?.name | titlecase}}</strong>
                </li>             
                <li>
                  <div class="progress-section">
                    <progress class="Progress-main top-bar" [value]="user?.targetAchieved" [max]="user?.target">
                    </progress>
                    <div class="progress-info">
                      <span title={{user?.targetAchieved}}>Current: {{numDifferentiation(user?.targetAchieved)}}</span>
                      <span>Target: {{user?.target}}</span>
                    </div>
                  </div>
                </li>
            </ul>           
          </ng-container>
        
        </div>
      </div>       
         </section>
      </mat-tab>
    </mat-tab-group>
  
</div>